import { useCallback } from "react";
import { useHistory, useLocation } from "react-router-dom";

type ReturnType = {
  setQuery: (params: { [key: string]: string }) => void;
  getQueryParam: (key: string) => string | null;
};

const createQueryString = (params: { [key: string]: string }): string => {
  const query = Object.keys(params).reduce((acc, key, index) => {
    const separator = index ? "&" : "";

    return `${acc}${separator}${key}=${params[key]}`;
  }, "");

  return `?${query}`;
};

const useQuery = (): ReturnType => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();

  const getQueryParam = useCallback(
    (key) => new URLSearchParams(search).get(key),
    [search]
  );

  const setQuery = useCallback(
    (params) => {
      push({
        pathname,
        search: createQueryString(params),
      });
    },
    [pathname, push]
  );

  return { getQueryParam, setQuery };
};

export default useQuery;
