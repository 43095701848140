import React, {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
} from "react";
import * as fileSaver from "file-saver";
import { useAuthDispatchContext } from "providers/Auth";
import moment from "moment";
import { AxiosError } from "axios";

import {
  State,
  Dispatch,
  ActionTypes,
  DispatchContext,
  ClientLogApi,
} from "./types";
import reducer from "./reducers";
import { instance } from "utils/api";
import { parseJSON, showError } from "utils/helpers";

const initialState: State = {
  isLoading: true,
  clients: [],
  clientPromotions: {
    types: [{ id: "", desc: "" }],
    isLoaded: false,
    list: [],
    count: 0,
  },
  clientsCount: 0,
  clientDetails: null,
  logs: null,
  logsCount: 0,
  tiers: [],
};

const ClientStateContext = createContext<State | undefined>(undefined);
const ClientDispatchContext = createContext<Dispatch | undefined>(undefined);

const ClientProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ClientStateContext.Provider value={state}>
      <ClientDispatchContext.Provider value={dispatch}>
        {children}
      </ClientDispatchContext.Provider>
    </ClientStateContext.Provider>
  );
};

const useClientStateContext = (): State => {
  const context = useContext(ClientStateContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useClientStateContext must be used within a ClientProvider"
    );
  }

  return context;
};

const useClientDispatchContext = (): DispatchContext => {
  const dispatch = useContext(ClientDispatchContext);
  const { signOut } = useAuthDispatchContext();

  if (typeof dispatch === "undefined") {
    throw new Error(
      "useClientDispatchContext must be used within a ClientProvider"
    );
  }

  const getClients = useCallback(
    async (
      page,
      search,
      filter,
      filtersEmail,
      kycVerification,
      dateFrom,
      dateTo,
      checkedOne,
      sorting,
      ordering,
      showClients,
      partnerId
    ) => {
      dispatch({ type: ActionTypes.SET_LOADING, data: true });

      const getToDate = () => {
        if (moment().format("MM-DD") === moment(dateTo)?.format("MM-DD"))
          return undefined;

        return dateTo
          ? `${moment(dateTo).format("YYYY-MM-DD")}T${
              moment(dateTo)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      try {
        const res = await instance.get("/admin/getusers", {
          params: {
            page: search?.trim()?.length ? 1 : page,
            keyword: search === "" ? undefined : search,
            emailStatus:
              filtersEmail?.email_verified === false
                ? "verified"
                : filtersEmail?.email_verified === true
                ? "not_verify"
                : undefined,
            kycs: kycVerification?.kycs,
            fromDate: dateFrom
              ? moment(dateFrom).format("YYYY-MM-DD")
              : undefined,
            toDate: getToDate(),
            isSuspicions: checkedOne === false ? undefined : checkedOne,
            sortBy:
              sorting === "active"
                ? "status"
                : sorting === ""
                ? undefined
                : sorting,
            orderBy: sorting === undefined ? undefined : ordering,
            limit: +showClients,
            clientStatus: filter?.status?.status,
            partnerId,
          },
        });

        dispatch({ type: ActionTypes.SET_LOADING, data: false });
        dispatch({ type: ActionTypes.SET_CLIENTS, data: res.data });
      } catch (err: unknown) {
        dispatch({ type: ActionTypes.SET_LOADING, data: false });

        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const getClientLogs = useCallback(
    async (
      page,
      id,
      dateFrom,
      dateTo,
      visibleClientsCount,
      search,
      clientCountry
    ) => {
      dispatch({ type: ActionTypes.SET_LOADING, data: true });

      const getDate = (date, type = "to") => {
        if (moment().format("MM-DD") === moment(date)?.format("MM-DD"))
          return undefined;

        if (type === "from") {
          return date ? `${moment(date).format("YYYY-MM-DD")}` : undefined;
        }

        return date
          ? `${moment(date).format("YYYY-MM-DD")}T${
              moment(date)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      try {
        const res = await instance.get("/user-logs", {
          params: {
            page,
            keyword: search,
            clientId: id,
            limit: +visibleClientsCount,
            fromDate: getDate(dateFrom, "from"),
            toDate: getDate(dateTo),
            countries: clientCountry?.length ? clientCountry : undefined,
          },
        });

        const { rows, count } = res.data.data;

        const parsedRows = rows.map((row: ClientLogApi) => ({
          ...row,
          action: parseJSON(row.action),
        }));

        dispatch({
          type: ActionTypes.SET_LOGS,
          data: { rows: parsedRows, count },
        });
        dispatch({ type: ActionTypes.SET_LOADING, data: false });
      } catch (err: unknown) {
        dispatch({ type: ActionTypes.SET_LOADING, data: false });

        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const downloadClients = useCallback(
    async (
      showClients,
      sorting,
      ordering,
      exportedClient,
      checkedID,
      clients,
      partnerId,
      arrayChecking,
      search,
      filter,
      filtersEmail,
      kycs,
      dateFrom,
      dateTo
    ) => {
      const getDate = (date, type = "to") => {
        if (moment().format("MM-DD") === moment(date)?.format("MM-DD"))
          return undefined;

        if (type === "from") {
          return date ? `${moment(date).format("YYYY-MM-DD")}` : undefined;
        }

        return date
          ? `${moment(date).format("YYYY-MM-DD")}T${
              moment(date)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      try {
        const res = await instance.get("/admin/getusers/download", {
          params: {
            // page: 1,
            keyword: search === "" ? undefined : search,
            emailStatus:
              filtersEmail?.email_verified === false
                ? "verified"
                : filtersEmail?.email_verified === true
                ? "not_verify"
                : undefined,
            kycs: kycs?.kycs,
            fromDate: getDate(dateFrom, "from"),
            toDate: getDate(dateTo),
            // limit: +showClients,
            clients: clients !== undefined ? exportedClient : undefined,
            sortBy:
              sorting === "active"
                ? "status"
                : sorting === ""
                ? undefined
                : sorting,
            orderBy: sorting === undefined ? undefined : ordering,
            partnerId,
            clientStatus: filter?.status?.status,
          },
          responseType: "blob",
        });

        const date = moment().format("DD-MM-YYY_hh:mm");

        fileSaver.saveAs(res.data as unknown as Blob, `clients-${date}.xlsx`);
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [signOut]
  );

  const downloadClientLogs = useCallback(
    async (
      showClients,
      sorting,
      ordering,
      selectedClient,
      checkedID,
      clients,
      logId,
      arrayChecking,
      dateFrom,
      dateTo,
      search,
      checkedCountry,
      clientCountry
    ) => {
      const getDate = (date, type = "to") => {
        if (moment().format("MM-DD") === moment(date)?.format("MM-DD"))
          return undefined;

        if (type === "from") {
          return date ? `${moment(date).format("YYYY-MM-DD")}` : undefined;
        }

        return date
          ? `${moment(date).format("YYYY-MM-DD")}T${
              moment(date)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      try {
        const res = await instance.get("/user-logs/download", {
          params: {
            page: 1,
            keyword: search,
            fromDate: getDate(dateFrom, "from"),
            toDate: getDate(dateTo),
            countries: clientCountry,
            limit: +showClients,
            logs: clients !== undefined ? selectedClient : undefined,
            sortBy:
              sorting === "kyc"
                ? "kyc_qualification"
                : sorting === "active"
                ? "status"
                : sorting === ""
                ? undefined
                : sorting,
            orderBy: sorting === undefined ? undefined : ordering,
            clientId: logId,
          },
          responseType: "blob",
        });

        const date = moment().format("DD-MM-YYY_hh:mm");

        fileSaver.saveAs(res.data as unknown as Blob, `clients-${date}.xlsx`);
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [signOut]
  );

  const getClientById = useCallback(
    async (id) => {
      try {
        const res = await instance.get(`/user/details/${id}`);

        dispatch({ type: ActionTypes.SET_CLIENT_DETAILS, data: res.data });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const updateClientStatus = useCallback(
    async (id, status) => {
      try {
        await instance.put(`admin/client/status/${id}`, {
          id,
          status,
        });

        dispatch({
          type: ActionTypes.UPDATE_STATUS,
          data: { id, status },
        });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const updateClientTier = useCallback(
    async ({ client_id, tier_id, limit }) => {
      const tierData: {
        client_id: string;
        tier_id: string;
        limit?: number | undefined;
      } = {
        client_id,
        tier_id,
      };

      if (limit) {
        tierData.limit = limit;
      }

      try {
        const { data } = await instance.post(`tiers`, tierData);

        dispatch({ type: ActionTypes.UPDATE_TIER, data: { tier: data.data } });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [signOut, dispatch]
  );

  const getTiers = useCallback(async () => {
    try {
      const { data } = await instance.get("tiers");

      dispatch({ type: ActionTypes.SET_TIERS, data: data.data });
    } catch (err: unknown) {
      if (
        ((err as AxiosError).response?.status === 401 &&
          !localStorage.getItem("refreshToken")) ||
        (err as AxiosError).response?.status === 401
      ) {
        return signOut();
      }

      showError(err);
    }
  }, [signOut, dispatch]);

  const setLoading = (isLoading: boolean) =>
    dispatch({ type: ActionTypes.SET_LOADING, data: isLoading });

  const { clientDetails } = useClientStateContext();
  const clientId = clientDetails?.id || "";

  const getClientPromotions = useCallback(
    async ({ limit, page }) => {
      try {
        const typesPromise = instance.get(`/admin/promotions/types`);

        const listPromise = instance.get(
          `/admin/promotions?client_id=${clientId}&page=${page}&limit=${limit}`
        );

        const [typesResponse, listResponse] = await Promise.all([
          typesPromise,
          listPromise,
        ]);

        dispatch({ type: ActionTypes.SET_PROMOTIONS_IS_LOADED, data: true });
        dispatch({
          type: ActionTypes.SET_PROMOTIONS_TYPES,
          data: typesResponse.data.data,
        });

        dispatch({
          type: ActionTypes.SET_PROMOTIONS_LIST,
          data: listResponse.data.data.rows,
        });
        dispatch({
          type: ActionTypes.SET_PROMOTIONS_COUNT,
          data: listResponse.data.data.count,
        });
      } catch (err: unknown) {
        showError(err);

        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId]
  );

  const reloadPromotionsList = useCallback(() => {
    dispatch({ type: ActionTypes.SET_PROMOTIONS_IS_LOADED, data: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPromotion = useCallback(
    async ({
      promotionId,
      feeReductionBank,
      feeReductionCard,
      startDate,
      endDate,
      description,
      buyFlow,
      sellFlow,
    }) => {
      try {
        const { data } = await instance.post(`/admin/promotions`, {
          promotion_id: promotionId,
          client_id: clientId,
          fee_reduction_instant_bank: feeReductionBank,
          fee_reduction_bank_tr: feeReductionCard,
          buy_flow: buyFlow,
          sell_flow: sellFlow,
          start_date: startDate,
          end_date: endDate,
          desc: description,
        });

        dispatch({
          type: ActionTypes.ADD_PROMOTION,
          data: data.data,
        });
      } catch (err: unknown) {
        showError(err);

        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId]
  );

  const updatePromotion = useCallback(
    async ({ promotionId, startDate, endDate, description, ended }) => {
      try {
        const { data } = await instance.put(
          `/admin/promotions/${promotionId}`,
          {
            client_id: clientId,
            start_date: startDate,
            end_date: endDate,
            desc: description,
            ended,
          }
        );

        dispatch({
          type: ActionTypes.UPDATE_PROMOTIONS_LIST,
          data: data.data[0],
        });
      } catch (err: unknown) {
        showError(err);

        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientId]
  );

  return {
    getClients,
    getClientLogs,
    downloadClients,
    downloadClientLogs,
    getClientById,
    updateClientStatus,
    updateClientTier,
    getTiers,
    setLoading,
    getClientPromotions,
    reloadPromotionsList,
    addPromotion,
    updatePromotion,
  };
};

export default ClientProvider;
export { useClientDispatchContext, useClientStateContext };
