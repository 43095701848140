import {
  useWidgetFiltersState,
  useWidgetFiltesDispatch,
} from "providers/WidgetFilter";
import React, { useMemo } from "react";
import {
  setFiatName,
  setFiatSymbol,
  setFiatSellStatus,
  setTriggerFilter,
  // setMinSellAmount,
  // setMaxSellAmount,
} from "providers/WidgetFilter/widgetFilterAction";
import { useSellFlowDispatch } from "providers/SellFlow";

import FilterList from "components/Filter/FilterList";
import { sellFiatSecondFilterTree } from "utils/constants";

const FiatFiltersWrapper = (): JSX.Element => {
  const dispatch = useWidgetFiltesDispatch();
  const { fiat_name, fiat_symbol, fiat_is_sell_supported } =
    useWidgetFiltersState();

  const { setPage } = useSellFlowDispatch();

  const filterByName = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      setPage(1);
      dispatch(setFiatName(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterBySymbol = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      setPage(1);
      dispatch(setFiatSymbol(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterSellStatus = (e, setError, setIsValid) => {
    if (typeof e.target.value === "undefined") return setError("Enter a value");

    setPage(1);

    if (e.target.value.toLowerCase() === "supported") {
      dispatch(setFiatSellStatus(true));
    }

    if (e.target.value.toLowerCase() === "not supported") {
      dispatch(setFiatSellStatus(false));
    }

    setError("");
    setIsValid(true);
  };

  // const filterMinSellAmount = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   setError: (error: string) => void,
  //   setIsValid: (isValid: boolean) => void
  // ) => {
  //   if (!e.target.value && typeof e.target.value !== "number")
  //     return setError("Enter a value");

  //   if (e.target.value) {
  //     dispatch(setMinSellAmount(parseFloat(e.target.value)));
  //     setIsValid(true);
  //     setError("");

  //     return;
  //   }

  //   setIsValid(false);
  //   setError("");
  // };

  // const filterMaxSellAmount = (
  //   e: React.ChangeEvent<HTMLInputElement>,
  //   setError: (error: string) => void,
  //   setIsValid: (isValid: boolean) => void
  // ) => {
  //   if (!e.target.value && typeof e.target.value !== "number")
  //     return setError("Enter a value");

  //   if (e.target.value) {
  //     dispatch(setMaxSellAmount(parseFloat(e.target.value)));
  //     setIsValid(true);
  //     setError("");

  //     return;
  //   }

  //   setIsValid(false);
  //   setError("");
  // };

  const secondLevelHandlers = [
    filterByName,
    filterBySymbol,
    filterSellStatus,
    // filterMinSellAmount,
    // filterMaxSellAmount,
  ];

  const handleApply = () => {
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);
  };

  const resetFiatName = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFiatName(""));
    }

    setPage(1);
    dispatch(setFiatName(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetFiatSymbol = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFiatSymbol(""));
    }

    setPage(1);
    dispatch(setFiatSymbol(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetFiatSellStatus = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFiatSellStatus(undefined));
    }

    setPage(1);
    dispatch(setFiatSellStatus(undefined));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  // const resetMinSellAmount = () => {
  //   dispatch(setMinSellAmount(undefined));
  //   dispatch(setTriggerFilter(false));
  //   setTimeout(() => {
  //     dispatch(setTriggerFilter(true));
  //   }, 500);
  // };

  // const resetMaxSellAmount = () => {
  //   dispatch(setMaxSellAmount(undefined));
  //   dispatch(setTriggerFilter(false));
  //   setTimeout(() => {
  //     dispatch(setTriggerFilter(true));
  //   }, 500);
  // };

  const resetHandlers = [
    resetFiatName,
    resetFiatSymbol,
    resetFiatSellStatus,
    // resetMinSellAmount,
    // resetMaxSellAmount,
  ];

  const enableClearFilters = useMemo(() => {
    if (
      fiat_name ||
      fiat_symbol ||
      typeof fiat_is_sell_supported !== "undefined"
    ) {
      return true;
    }

    return false;
  }, [fiat_name, fiat_symbol, fiat_is_sell_supported]);

  return (
    <>
      <FilterList
        secondLevelHandlers={secondLevelHandlers}
        secondLevelFilter={sellFiatSecondFilterTree}
        handleApply={handleApply}
        enableClearFilters={enableClearFilters}
        clearSearches={resetHandlers}
        additionalHandlers={[]}
        clearAdditionalSearches={[]}
        clearSelect={() => null}
      />
    </>
  );
};

export default FiatFiltersWrapper;
