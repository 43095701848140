import { FC } from "react";

import { ProfileInfoProps } from "./ProfileItem.props";
import Icon from "../../base/Icon";
import Button from "../../Button";

const ProfileInfo: FC<ProfileInfoProps> = ({ iconName, onClick }) => {
  return (
    <Button type="button" className="button--transparent" onClick={onClick}>
      <Icon name={iconName} />
    </Button>
  );
};

export default ProfileInfo;
