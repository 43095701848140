import { FC, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// pages
import { useAuthStateContext } from "providers/Auth";
// providers
import ClientProvider from "providers/Clients";
import OrderProvider from "providers/Orders";
import PartnerProvider from "providers/Partners";
// page
import ClientFiltersProvider from "providers/ClientFilter";
import PartnerFiltersProvider from "providers/PartnerFilter";
import { SETTING_PANEL_ACCESS } from "services/roleService/setting/setting";
import WidgetConsoleProvider from "providers/WidgetConsole";
import WidgetFilterProvider from "providers/WidgetFilter";
import SellFlowProvider from "providers/SellFlow";
import SellOrderProvider from "providers/SellOrders";
import OrdersFilterProvider from "providers/OrderFilter";
import WebhooksProvider from "providers/Webhooks";
import ReceiveOrderProvider from "providers/ReceiveOrders";
import AdminUsersProvider from "providers/AdminUsers";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import PrivateLayout from "components/PrivateLayout";
import Login from "pages/Login";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import Dashboard from "pages/Dashboard/Dashboard";
import Clients from "pages/Clients";
import Orders from "pages/Orders";
import OrdersReceiveFlow from "pages/Orders/ReceiveFlow";
import OrdersSellFlow from "pages/Orders/SellFlow";
import OrdersSellFlowDetails from "pages/Orders/SellFlow/OrderDetails";
import Partners from "pages/Partners";
import Setting from "pages/Settings/index";
import PartnerDetails from "pages/Partners/PartnerDetails";
import Profile from "./pages/Profile/index";
import PartnerSetting from "./pages/PartnerSettings/index";
import useRole from "hooks/useRole";
import CreatePassword from "pages/Authentication/SetupNewPassword";
import PhoneNumberVerification from "pages/Authentication/PhoneNumberVerification";
import CodeVerification from "pages/Authentication/VerifiedPhoneNumber";
import AddPhone from "pages/Authentication/AddYouPhone";
import AuthTypeSelect from "pages/Authentication/AuthTypeSelect";
import GoogleAuth from "pages/Authentication/GoogleAuth";
import PartnersBlockPage from "pages/PartnersBlockBage";
import OrderDetailsNew from "pages/Orders/OrderDetailsNew";
import ClientDetails from "pages/Clients/ClientDetails/ClientDetails";
import Reports from "pages/Reports";
import WidgetConsole from "pages/WidgetConsole";
import CryptoDetails from "pages/WidgetConsole/CryptoDetails";
import FiatDetails from "pages/WidgetConsole/FiatDetails";
import FireblockDetails from "pages/WidgetConsole/FireblockDetails";
import BalancePage from "pages/Balance";
import Payout from "pages/Payout";
import SellFlow from "pages/WidgetConsole/SellFlow";
import WebhookCreate from "pages/WebhookCreate";
import EventDetails from "pages/PartnerSettings/EventDetails";
import PartnerWebhookCreate from "pages/WebhookCreate/Partner/PartnerWebhookCreate";
import PartnerEventDetails from "pages/PartnerSettings/EventDetails/Partner/PartnerEventDetails";
import ReceiveOrderDetails from "pages/Orders/ReceiveFlow/OrderDetails";
import PromotionDetails from "pages/PromotionDetails";
import AdminUsers from "pages/AdminUsers";

const AppRoutes: FC = () => {
  // context
  const { isLoggedIn } = useAuthStateContext();
  const role = useRole();

  const renderRoutesBaseOnRole = useMemo(() => {
    if (role === "super_admin") {
      return (
        <PrivateLayout>
          <Switch>
            <Route key={"/dashboard"} exact path="/dashboard">
              <OrdersFilterProvider>
                <OrderProvider>
                  <Dashboard />
                </OrderProvider>
              </OrdersFilterProvider>
            </Route>
            <Route key={"/users"} exact path="/users">
              <RoleProvider
                roles={[ROLES.SUPER_ADMIN, ROLES.MARKETING, ROLES.AML]}
              >
                <PartnerProvider>
                  <ClientProvider>
                    <ClientFiltersProvider>
                      <Clients />
                    </ClientFiltersProvider>
                  </ClientProvider>
                </PartnerProvider>
              </RoleProvider>
            </Route>
            <Route key={"/admin-users"} exact path="/admin-users">
              <PartnerProvider>
                <ClientProvider>
                  <ClientFiltersProvider>
                    <AdminUsersProvider>
                      <AdminUsers />
                    </AdminUsersProvider>
                  </ClientFiltersProvider>
                </ClientProvider>
              </PartnerProvider>
            </Route>
            <Route key={"/users/details/:id"} exact path="/users/details/:id">
              <ClientProvider>
                <ClientFiltersProvider>
                  <OrderProvider>
                    <PartnerProvider>
                      <PartnerFiltersProvider>
                        <ClientDetails />
                      </PartnerFiltersProvider>
                    </PartnerProvider>
                  </OrderProvider>
                </ClientFiltersProvider>
              </ClientProvider>
            </Route>
            <Route key={"/partners"} exact path="/partners">
              <PartnerProvider>
                <ClientProvider>
                  <PartnerFiltersProvider>
                    <Partners />
                  </PartnerFiltersProvider>
                </ClientProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/partners/details/:id"}
              exact
              path="/partners/details/:id"
            >
              <PartnerProvider>
                <ClientProvider>
                  <ClientFiltersProvider>
                    <WebhooksProvider>
                      <PartnerDetails />
                    </WebhooksProvider>
                  </ClientFiltersProvider>
                </ClientProvider>
              </PartnerProvider>
            </Route>
            <Route key={"/setting"} exact path="/setting">
              {/* <BitcoinCoreProvider> */}
              {SETTING_PANEL_ACCESS.user[role] && <Setting />}
              {SETTING_PANEL_ACCESS.partner[role] && (
                <PartnerSetting page="events" />
              )}
              {/* </BitcoinCoreProvider> */}
            </Route>
            <Route key={"/profile"} exact path="/profile">
              <ClientProvider>
                <Profile />
              </ClientProvider>
            </Route>
            <Route key={"/orders/buy-flow"} exact path="/orders/buy-flow">
              <PartnerProvider>
                <OrdersFilterProvider>
                  <OrderProvider>
                    <Orders />
                  </OrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>

            <Route
              key={"/orders/receive-flow"}
              exact
              path="/orders/receive-flow"
            >
              <PartnerProvider>
                <OrdersFilterProvider>
                  <SellOrderProvider>
                    <ReceiveOrderProvider>
                      <OrdersReceiveFlow />
                    </ReceiveOrderProvider>
                  </SellOrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route key={"/orders/sell-flow"} exact path="/orders/sell-flow">
              <PartnerProvider>
                <OrdersFilterProvider>
                  <SellOrderProvider>
                    <OrdersSellFlow />
                  </SellOrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/orders/receive-flow/details/:slug"}
              exact
              path="/orders/receive-flow/details/:slug"
            >
              <PartnerProvider>
                <OrdersFilterProvider>
                  <ReceiveOrderProvider>
                    <ReceiveOrderDetails />
                  </ReceiveOrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/orders/sell-flow/details/:slug"}
              exact
              path="/orders/sell-flow/details/:slug"
            >
              <PartnerProvider>
                <OrdersFilterProvider>
                  <SellOrderProvider>
                    <OrdersSellFlowDetails />
                  </SellOrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/orders/buy-flow/details/:slug"}
              exact
              path="/orders/buy-flow/details/:slug"
            >
              <PartnerProvider>
                <OrdersFilterProvider>
                  <OrderProvider>
                    <OrderDetailsNew />
                  </OrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route
              key="/widget-console/buy-flow"
              exact
              path="/widget-console/buy-flow"
            >
              <PartnerProvider>
                <WidgetConsoleProvider>
                  <WidgetFilterProvider>
                    <WidgetConsole />
                  </WidgetFilterProvider>
                </WidgetConsoleProvider>
              </PartnerProvider>
            </Route>
            <Route
              key="/widget-console/buy-flow/crypto/:id"
              exact
              path="/widget-console/buy-flow/crypto/:id"
            >
              <PartnerProvider>
                <OrderProvider>
                  <WidgetConsoleProvider>
                    <CryptoDetails />
                  </WidgetConsoleProvider>
                </OrderProvider>
              </PartnerProvider>
            </Route>
            <Route
              key="/widget-console/sell-flow/crypto/:id"
              exact
              path="/widget-console/sell-flow/crypto/:id"
            >
              <PartnerProvider>
                <OrderProvider>
                  <WidgetConsoleProvider>
                    <CryptoDetails />
                  </WidgetConsoleProvider>
                </OrderProvider>
              </PartnerProvider>
            </Route>
            <Route
              key="/widget-console/buy-flow/fiat/:id"
              exact
              path="/widget-console/buy-flow/fiat/:id"
            >
              <PartnerProvider>
                <OrderProvider>
                  <WidgetConsoleProvider>
                    <FiatDetails />
                  </WidgetConsoleProvider>
                </OrderProvider>
              </PartnerProvider>
            </Route>
            <Route
              key="/widget-console/sell-flow/fiat/:id"
              exact
              path="/widget-console/sell-flow/fiat/:id"
            >
              <PartnerProvider>
                <OrderProvider>
                  <WidgetConsoleProvider>
                    <FiatDetails />
                  </WidgetConsoleProvider>
                </OrderProvider>
              </PartnerProvider>
            </Route>
            <Route
              key="/widget-console/buy-flow/fireblock/:id"
              exact
              path="/widget-console/buy-flow/fireblock/:id"
            >
              <PartnerProvider>
                <OrderProvider>
                  <WidgetConsoleProvider>
                    <FireblockDetails />
                  </WidgetConsoleProvider>
                </OrderProvider>
              </PartnerProvider>
            </Route>
            <Route
              key="/widget-console/sell-flow/fireblock/:id"
              exact
              path="/widget-console/sell-flow/fireblock/:id"
            >
              <PartnerProvider>
                <OrderProvider>
                  <WidgetConsoleProvider>
                    <FireblockDetails />
                  </WidgetConsoleProvider>
                </OrderProvider>
              </PartnerProvider>
            </Route>
            <Route
              key="/widget-console/sell-flow"
              exact
              path="/widget-console/sell-flow"
            >
              <PartnerProvider>
                <OrderProvider>
                  {/* <WidgetConsoleProvider> */}
                  <WidgetFilterProvider>
                    <SellFlowProvider>
                      <SellFlow />
                    </SellFlowProvider>
                  </WidgetFilterProvider>
                  {/* </WidgetConsoleProvider> */}
                </OrderProvider>
              </PartnerProvider>
            </Route>
            <Route key="/reports" path="/reports">
              <Reports />
            </Route>
            <Route
              key="/promotion/:promotionId"
              exact
              path="/promotion/:promotionId"
            >
              <ClientProvider>
                <ClientFiltersProvider>
                  <OrdersFilterProvider>
                    <OrderProvider>
                      <PartnerProvider>
                        <PartnerFiltersProvider>
                          <PromotionDetails />
                        </PartnerFiltersProvider>
                      </PartnerProvider>
                    </OrderProvider>
                  </OrdersFilterProvider>
                </ClientFiltersProvider>
              </ClientProvider>
            </Route>
            <Route key={"/payout"} exact path="/payout">
              <Payout />
            </Route>
            <Route key="/webhooks/create/:id" exact path="/webhooks/create/:id">
              <PartnerProvider>
                <WebhooksProvider>
                  <PartnerWebhookCreate />
                </WebhooksProvider>
              </PartnerProvider>
            </Route>
            <Route key="/webhooks/update/:id" exact path="/webhooks/update/:id">
              <PartnerProvider>
                <WebhooksProvider>
                  <PartnerWebhookCreate />
                </WebhooksProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/setting/events/:id/:partnerId"}
              exact
              path="/setting/events/:id/:partnerId"
            >
              <PartnerProvider>
                <WebhooksProvider>
                  <PartnerEventDetails />
                </WebhooksProvider>
              </PartnerProvider>
            </Route>
            <Redirect to="/dashboard" />
          </Switch>
        </PrivateLayout>
      );
    }

    if (role === "partner") {
      return (
        <PrivateLayout>
          <Switch>
            <Route key={"/dashboard"} exact path="/dashboard">
              <OrdersFilterProvider>
                <OrderProvider>
                  <Dashboard />
                </OrderProvider>
              </OrdersFilterProvider>
            </Route>
            <Route key={"/users"} exact path="/users">
              <PartnerProvider>
                <ClientProvider>
                  <ClientFiltersProvider>
                    <Clients />
                  </ClientFiltersProvider>
                </ClientProvider>
              </PartnerProvider>
            </Route>
            <Route key={"/users/details/:id"} exact path="/users/details/:id">
              <ClientProvider>
                <ClientFiltersProvider>
                  <OrdersFilterProvider>
                    <OrderProvider>
                      <PartnerProvider>
                        <PartnerFiltersProvider>
                          <ClientDetails />
                        </PartnerFiltersProvider>
                      </PartnerProvider>
                    </OrderProvider>
                  </OrdersFilterProvider>
                </ClientFiltersProvider>
              </ClientProvider>
            </Route>
            <Route key={"/partners"} exact path="/partners">
              <PartnerProvider>
                <ClientProvider>
                  <PartnerFiltersProvider>
                    <Partners />
                  </PartnerFiltersProvider>
                </ClientProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/partners/details/:id"}
              exact
              path="/partners/details/:id"
            >
              <PartnerProvider>
                <ClientProvider>
                  <ClientFiltersProvider>
                    <PartnerDetails />
                  </ClientFiltersProvider>
                </ClientProvider>
              </PartnerProvider>
            </Route>
            <Route key={"/setting"} exact path="/setting">
              {/* <BitcoinCoreProvider> */}
              {SETTING_PANEL_ACCESS.user[role] && <Setting />}
              {SETTING_PANEL_ACCESS.partner[role] && (
                <Redirect to="/setting/integration" />
              )}
              {/* </BitcoinCoreProvider> */}
            </Route>
            <Route
              key={"/setting/integration"}
              exact
              path="/setting/integration"
            >
              {/* <BitcoinCoreProvider> */}
              {SETTING_PANEL_ACCESS.user[role] && <Setting />}
              {SETTING_PANEL_ACCESS.partner[role] && (
                <PartnerSetting page="integration" />
              )}
              {/* </BitcoinCoreProvider> */}
            </Route>
            <Route key={"/setting/webhooks"} exact path="/setting/webhooks">
              {/* <BitcoinCoreProvider> */}
              {SETTING_PANEL_ACCESS.user[role] && (
                <PartnerProvider>
                  <Setting />
                </PartnerProvider>
              )}
              {SETTING_PANEL_ACCESS.partner[role] && (
                <PartnerProvider>
                  <WebhooksProvider>
                    <PartnerSetting page="webhooks" />
                  </WebhooksProvider>
                </PartnerProvider>
              )}
              {/* </BitcoinCoreProvider> */}
            </Route>
            <Route key={"/setting/events"} exact path="/setting/events">
              {/* <BitcoinCoreProvider> */}
              {SETTING_PANEL_ACCESS.user[role] && <Setting />}
              {SETTING_PANEL_ACCESS.partner[role] && (
                <WebhooksProvider>
                  <PartnerSetting page="events" />
                </WebhooksProvider>
              )}
              {/* </BitcoinCoreProvider> */}
            </Route>
            <Route key={"/setting/events/:id"} exact path="/setting/events/:id">
              <WebhooksProvider>
                <EventDetails />
              </WebhooksProvider>
            </Route>
            <Route key={"/profile"} exact path="/profile">
              <ClientProvider>
                <Profile />
              </ClientProvider>
            </Route>
            <Route key={"/orders/buy-flow"} exact path="/orders/buy-flow">
              <PartnerProvider>
                <OrdersFilterProvider>
                  <OrderProvider>
                    <Orders />
                  </OrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>

            <Route
              key={"/orders/receive-flow"}
              exact
              path="/orders/receive-flow"
            >
              <PartnerProvider>
                <OrdersFilterProvider>
                  <SellOrderProvider>
                    <ReceiveOrderProvider>
                      <OrdersReceiveFlow />
                    </ReceiveOrderProvider>
                  </SellOrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route key={"/orders/sell-flow"} exact path="/orders/sell-flow">
              <PartnerProvider>
                <OrdersFilterProvider>
                  <SellOrderProvider>
                    <OrdersSellFlow />
                  </SellOrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/orders/receive-flow/details/:slug"}
              exact
              path="/orders/receive-flow/details/:slug"
            >
              <PartnerProvider>
                <OrdersFilterProvider>
                  <ReceiveOrderProvider>
                    <ReceiveOrderDetails />
                  </ReceiveOrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/orders/sell-flow/details/:slug"}
              exact
              path="/orders/sell-flow/details/:slug"
            >
              <PartnerProvider>
                <OrdersFilterProvider>
                  <SellOrderProvider>
                    <OrdersSellFlowDetails />
                  </SellOrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route
              key={"/orders/buy-flow/details/:slug"}
              exact
              path="/orders/buy-flow/details/:slug"
            >
              <PartnerProvider>
                <OrdersFilterProvider>
                  <OrderProvider>
                    <OrderDetailsNew />
                  </OrderProvider>
                </OrdersFilterProvider>
              </PartnerProvider>
            </Route>
            <Route key={"/balance"} exact path="/balance">
              <BalancePage />
            </Route>
            <Route key={"/payout"} exact path="/payout">
              <Payout />
            </Route>
            <Route key="/webhooks/create" exact path="/webhooks/create">
              <WebhooksProvider>
                <WebhookCreate />
              </WebhooksProvider>
            </Route>
            <Route key="/webhooks/update" exact path="/webhooks/update">
              <WebhooksProvider>
                <WebhookCreate />
              </WebhooksProvider>
            </Route>
            <Redirect to="/dashboard" />
          </Switch>
        </PrivateLayout>
      );
    }
  }, [role]);

  if (isLoggedIn && role) {
    return <>{renderRoutesBaseOnRole}</>;
  }

  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route exact path="/admin/password/set/:token">
        <CreatePassword />
      </Route>
      <Route exact path="/admin/password/reset/:token">
        <CreatePassword />
      </Route>
      <Route exact path="/phone-number">
        <AddPhone />
      </Route>
      <Route exact path="/code-verification">
        <PhoneNumberVerification />
      </Route>
      <Route exact path="/account-auth-type">
        <AuthTypeSelect />
      </Route>
      <Route exact path="/account-auth-google">
        <GoogleAuth />
      </Route>

      <Route exact path="/code-verification-success/:type">
        <CodeVerification />
      </Route>
      <Route exact path="/block-partner/:minutes">
        <PartnersBlockPage />
      </Route>
      <Route exact path="/reports">
        <Reports />
      </Route>
      <Redirect exact to="/login" />
    </Switch>
  );
};

export default AppRoutes;
