interface header {
  content?: React.ReactNode;
  empty?: boolean;
  title?: string;
  w?: number | string;
  val?: string;
  light?: string;
  position?: string;
  // handleSorting: (e) => void;
}

export const WIDGET_CONSOLE_TABLE_ACCESS: {
  [key: string]: { [key: string]: boolean };
} = {
  id: { super_admin: true, partner: false },
  id_clipboard: { super_admin: true, partner: false },
  core: { super_admin: true, partner: false },
  code: { super_admin: true, partner: false },
  suspend_status: { super_admin: true, partner: false },
  sell_status: { super_admin: true, partner: false },
  status_edit: { super_admin: true, partner: false },
  sell_status_edit: { super_admin: true, partner: false },
  markup_rate: { super_admin: true, partner: false },
  negative_markup: { super_admin: true, partner: false },
  name: { super_admin: true, partner: false },
  symbol: { super_admin: true, partner: false },
  date: { super_admin: true, partner: false },
  action: { super_admin: true, partner: false },
  precision: { super_admin: true, partner: false },
};

export const filterWidgetConsoleByRole = (
  header: header,
  role: string
): boolean => {
  if (header.val && WIDGET_CONSOLE_TABLE_ACCESS[header.val][role]) return true;

  return false;
};
