import React, { useMemo, FC } from "react";

type PropsType = {
  errors: number;
  success: number;
};
const REQUIRED_STRICT_LEVEL = 4;

const SuccessLine = () => <div className="step ml-0 step-success"></div>;
const ErrorLine = () => <div className="step ml-0 step-error"></div>;
const DefaultLine = () => <div className="step ml-0 "></div>;

const StrictLevel: FC<PropsType> = ({ errors = 0, success = 0 }) => {
  const errorLines = useMemo(() => [...Array(errors)], [errors]);
  const successLines = useMemo(() => [...Array(success)], [success]);
  const defaultLines = useMemo(
    () => [...Array(REQUIRED_STRICT_LEVEL - errors - success)],
    [errors, success]
  );

  errors;
  success;

  return (
    <>
      {successLines.map((_, i) => (
        <SuccessLine key={i} />
      ))}
      {errorLines.map((_, i) => (
        <ErrorLine key={i} />
      ))}
      {defaultLines.map((_, i) => (
        <DefaultLine key={i} />
      ))}
    </>
  );
};

export default StrictLevel;
