import { useHistory } from "react-router-dom";
import "./webhooks-empty.scss";

const WebhooksEmpty = (): JSX.Element => {
  const history = useHistory();

  const addEndpoint = () => history.push("/webhooks/create");

  return (
    <div className="webhooks-empty__container--outer">
      <div className="webhooks-empty__container--inner">
        <h2 className="webhooks-empty__header">Listen to Swaps events</h2>
        <div className="webhooks-empty__text">
          Create webhook endpoints, so that Swaps can notify your integration
          when asynchronous events occur.
        </div>
        <button onClick={addEndpoint} className="webhooks-empty__button">
          Add an endpoint
        </button>
      </div>
    </div>
  );
};

export default WebhooksEmpty;
