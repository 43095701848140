import { FC } from "react";
import { Page, Text, Image, View, Document, Font } from "@react-pdf/renderer";
import moment from "moment";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SF_Pro from "../../fonts/SF-Pro.otf";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SF_Mono from "../../fonts/SFMonoRegular.woff";
import LogoText from "../base/Icon/icons/logo-text.png";
import { InvoiceProps } from "./InvoiceReceive.props";
import { styles } from "./InvoiceReceive.styles";

Font.register({ family: "SF-Pro", src: SF_Pro });
Font.register({ family: "SF-Mono", src: SF_Mono });

const InvoiceReceive: FC<InvoiceProps> = ({ order }): JSX.Element => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.invoice_container}>
          <View wrap={true} style={styles.image_container}>
            <Image style={styles.image} src={LogoText} />
          </View>
          <View style={styles.header_container}>
            <View style={styles.header_left_block}>
              <Text style={styles.personal_info}>{order?.account_name}</Text>
            </View>
            <View style={styles.email_block}>
              <Text style={styles.email_info}>{order?.email}</Text>
            </View>
          </View>
          <View style={styles.main_content_wrapper}>
            <View style={styles.content_keys_wrapper}>
              <Text style={styles.content_key}>Transaction ID</Text>
              <Text style={styles.content_key}>Address</Text>
              <Text style={styles.content_key}>Amount</Text>
              <Text style={styles.content_key}>Asset</Text>
              <Text style={styles.content_key}>Client Id</Text>
              <Text style={styles.content_key}>Comment</Text>
              <Text style={styles.content_key}>Core</Text>
              <Text style={styles.content_key}>Fiat</Text>
              <Text style={styles.content_key}>Fee option</Text>
              <Text style={styles.content_key}>Asset quantity</Text>
              <Text style={styles.content_key}>Cost of service</Text>
              <Text style={styles.content_key}>Network fee</Text>
              <Text style={styles.content_key}>Partner commission</Text>
              <Text style={styles.content_key}>Payer email</Text>
              <Text style={styles.content_key}>Price</Text>
              <Text style={styles.content_key}>Spot price</Text>
              <Text style={styles.content_key}>Payer status</Text>
              <Text style={styles.content_key}>Swaps Revenue</Text>
              <Text style={styles.content_key}>Tr status</Text>
              <Text style={styles.content_key}>Tx id</Text>
              <Text style={styles.content_key}>Partner email</Text>
              <Text style={styles.content_key}>Partner id</Text>
              <Text style={styles.content_key}>Receive status</Text>
              <Text style={styles.content_key}>Date</Text>
            </View>
            <View style={styles.content_values_wrapper}>
              <Text style={styles.content_value}>{order.id ?? "-"}</Text>
              <Text style={styles.content_value}>{order.address ?? "-"}</Text>
              <Text style={styles.content_value}>{order?.amount ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order.asset_name ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order.client_id ?? "-"}</Text>
              <Text style={styles.content_value}>{order.comment ?? "-"}</Text>
              <Text style={styles.content_value}>{order.core ?? "-"}</Text>
              <Text style={styles.content_value}>{order.currency ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order?.fee_option ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.asset_quantity ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.cost_of_service ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.network_fee ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.partner_commission ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.payer_client?.email ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.price ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.spot_price ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.status ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.swaps_profit ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.tr_status ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.order?.tx_id ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.partner?.email ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.partner_id ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order?.status ?? "-"}</Text>
              <Text style={styles.content_value}>
                {moment(order?.createdAt).format("DD MMM yyyy, hh:mm")}
              </Text>
            </View>
          </View>
          <View style={styles.footer_wrapper}>
            <Text style={styles.footer_text}>
              Octo Liquidity OÜ Harju maakond, Tallinn, Kesklinna linnaosa, Oru
              tn 2 – 7, 10127. License FVT 000398. Reg code 14829050.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceReceive;
