import openPayd from "../assets/img/sepa.svg";
import stripe from "../assets/img/stripe.svg";
import astropay from "../assets/img/astropay.svg";
import volt from "../assets/img/volt.svg";
import zimpler from "../assets/img/zimpler.svg";
import ClearJunction from "../assets/img/clear-junction.svg";
import skrill from "../assets/img/skrill.svg";
import paybis from "../assets/img/paybis.svg";
import rapid from "../assets/img/rapid.svg";
import neteller from "../assets/img/neteller.svg";

export const intercomURL =
  "https://app.intercom.com/a/apps/z21s0ruh/users/segments/all-users?searchTerm=";

export const uuid_regex =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export const email_regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const paymentMethodsObj = {
  stripe: {
    name: "Stripe",
    icon: stripe,
  },
  astropay: {
    name: "Astropay",
    icon: astropay,
  },
  zimpler: {
    name: "Zimpler",
    icon: zimpler,
  },
  openPayd: {
    name: "OpenPayd",
    icon: openPayd,
  },
  volt: {
    name: "Volt",
    icon: volt,
  },
  skrill: {
    name: "Skrill",
    icon: skrill,
  },
  paybis: {
    name: "Paybis",
    icon: paybis,
  },
  rapid: {
    name: "Rapid Transfer",
    icon: rapid,
  },
  neteller: {
    name: "Neteller",
    icon: neteller,
  },
  clearJunction: {
    name: "Clear Junction",
    icon: ClearJunction,
  },
};

export const balanceStatus = {
  confirmed: {
    label: "Approved",
    icon: "success_check",
    className: "user-status-active",
  },
  completed: {
    label: "Approved",
    icon: "success_check",
    className: "user-status-active",
  },
  in_progress: {
    label: "In progress",
    icon: "clock",
    className: "user-status-panding",
  },
  approved: {
    label: "Approved",
    icon: "success_check",
    className: "user-status-active",
  },
  in_review: {
    label: "In review",
    icon: "hourglass",
    className: "user-status-info",
  },
  rejected: {
    label: "Rejected",
    icon: "balance_reject",
    className: "user-status-blocked",
  },
};

export const txBuyStatusInfo = {
  canceled: {
    label: "failed",
    icon: "sell_failed",
    className: "user-status-blocked",
  },
  failed: {
    label: "failed",
    icon: "sell_failed",
    className: "user-status-blocked",
  },
  refunded: {
    label: "failed",
    icon: "sell_failed",
    className: "user-status-blocked",
  },
  refund_pending: {
    label: "failed",
    icon: "sell_failed",
    className: "user-status-blocked",
  },
  disputed: {
    label: "insufficient funds",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  incomplete: {
    label: "insufficient funds",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  early_fraud_warning: {
    label: "insufficient funds",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  pending: {
    label: "insufficient funds",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  succeeded: {
    label: "completed bank",
    icon: "sell_success",
    className: "user-status-active",
  },
  on_review: {
    label: "insufficient funds",
    icon: "sell_pending",
    className: "user-status-panding",
  },
};
export const colorsObj = {
  INSUFFICIENT_FUNDS: "#FFD700",
  FAILED: "#FF4500",
  SUBMITTED: "#B22222",
  BLOCKED: "#FFA500",
  CANCELLED: "#8B0000",
  COMPLETED: "#32CD32",
  CONFIRMING: "#FFD700",
  QUEUED: "#FFA500",
  PROCESSING_BANK: "#FF8C00",
  RELEASED: "#98FB98",
  COMPLETED_BANK: "#006400",
  FAILED_BANK: "#FF6347",
  CANCELLED_BANK: "#800000",
  PAYOUT_EXECUTED: "#00FF00",
  PAYOUT_FAILED: "#FF69B4",
  failed: "#B22222",
  successful: "#00FA9A",
  completed: "#228B22",
  pending: "#FFD700",
  CANCELED: "#8B0000",
  REFUNDED: "#1E90FF",
  REFUND_PENDING: "#87CEFA",
  DISPUTED: "#8A2BE2",
  INCOMPLETE: "#FF7F7F",
  EARLY_FRAUD_WARNING: "#FF1493",
  PENDING: "#FFD700",
  SUCCEEDED: "#228B22",
  ON_REVIEW: "#FFFFE0",
};
export const txSellStatusInfo = {
  INSUFFICIENT_FUNDS: {
    label: "insufficient funds",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  FAILED: {
    label: "failed",
    icon: "sell_failed",
    className: "user-status-blocked",
  },
  SUBMITTED: {
    label: "failed",
    icon: "sell_failed",
    className: "user-status-blocked",
  },
  BLOCKED: {
    label: "blocked",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  CANCELLED: {
    label: "canceled",
    icon: "sell_failed",
    className: "user-status-blocked",
  },
  COMPLETED: {
    label: "completed",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  CONFIRMING: {
    label: "confirming",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  QUEUED: {
    label: "queued",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  PROCESSING_BANK: {
    label: "processing bank",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  RELEASED: {
    label: "released",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  COMPLETED_BANK: {
    label: "completed bank",
    icon: "sell_success",
    className: "user-status-active",
  },
  FAILED_BANK: {
    label: "failed bank",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  CANCELLED_BANK: {
    label: "canceled bank",
    icon: "sell_pending",
    className: "user-status-panding",
  },
  PAYOUT_EXECUTED: {
    label: "payout executed",
    icon: "sell_success",
    className: "user-status-active",
  },
  PAYOUT_FAILED: {
    label: "payout failed",
    icon: "sell_pending",
    className: "user-status-panding",
  },
};

export const txStatusInfo = {
  'Payout "In Review"': {
    label: "payout in review",
    icon: "clock",
    className: "user-status-panding",
  },
  "Payout rejected": {
    label: "payout rejected",
    icon: "order_reject",
    className: "user-status-blocked",
  },
  "Payout approved": {
    label: "payout approved",
    icon: "success_check",
    className: "user-status-active",
  },
  "Payout confirmed": {
    label: "payout confirmed",
    icon: "success_check",
    className: "user-status-active",
  },
  "Sanction screening": {
    label: "sanction screening",
    icon: "warning_check",
    className: "user-status-panding",
  },
  "Failed transactions": {
    label: "failed transactions",
    icon: "order_reject",
    className: "user-status-blocked",
  },
  disputed: {
    label: "disputed",
    icon: "warning_check",
    className: "user-status-panding",
  },
  incomplete: {
    label: "incomplete",
    icon: "icon_info",
    className: "user-status-info",
  },
  canceled: {
    label: "canceled",
    icon: "order_reject",
    className: "user-status-blocked",
  },
  failed: {
    label: "failed",
    icon: "order_reject",
    className: "user-status-blocked",
  },
  "early fraud warning": {
    label: "early fraud warning",
    icon: "icon_info",
    className: "user-status-info",
  },
  pending: {
    label: "pending",
    icon: "clock",
    className: "user-status-panding",
  },
  "refund pending": {
    label: "refund pending",
    icon: "clock",
    className: "user-status-info",
  },
  refunded: {
    label: "refunded",
    icon: "icon_info",
    className: "user-status-info",
  },
  succeeded: {
    label: "succeeded",
    icon: "success_check",
    className: "user-status-active",
  },
};

export const addPartnerInputs = [
  {
    label: "Partner name",
    type: "text",
  },
  {
    label: "Contact name",
    type: "text",
  },
  {
    label: "Contact phone",
    type: "text",
  },
  {
    label: "Contact email",
    type: "email",
  },
  {
    label: "Partner commission",
    type: "text",
  },
  {
    label: "Partner markup",
    type: "text",
  },
  {
    label: "Widget placement page URLs",
    type: "text",
  },
  {
    label: "Success webhook",
    type: "text",
  },
  {
    label: "Comments",
    type: "textarea",
  },
];

export const logsSecondFilterTree = [
  {
    label: "Date",
    value: "DATE",
    type: "date_picker",
  },
  {
    label: "Countries",
    value: "COUNTRIES",
    type: "select",
    selectOptions: [],
  },
];

export const balanceFiltersTree = [
  {
    label: "Payouts",
    value: "PAYOUT",
  },
  {
    label: "Payins",
    value: "PAYIN",
  },
];

export const partnersFilterTree = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Active",
    value: "successful",
  },
  {
    label: "Blocked",
    value: "not_integrated",
  },
];

export const partnersSecondFilterTree = [
  {
    label: "Date",
    value: "DATE",
    type: "date_picker",
  },
  {
    label: "Commission",
    value: "MARKUP",
    type: "number_range",
  },
  {
    label: "Processing fee",
    value: "COMMISSION",
    type: "number_range",
  },
];

export const receiveOrdersFilterTree = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Status Completed",
    value: "completed",
  },
  {
    label: "Status Failed",
    value: "failed",
  },
  {
    label: "Status Pending",
    value: "pending",
  },
];

export const sellOrdersFilterTree = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Status Completed",
    value:
      process.env.NODE_ENV === "production"
        ? "04d68383-6d73-43c4-bcfb-c5519d095348"
        : "6e460818-e7bc-4a83-884c-a795cc96cf14",
  },
  {
    label: "Status Failed",
    value:
      process.env.NODE_ENV === "production"
        ? "45a12e42-5590-4175-a499-2ba88ff64f23"
        : "9bf77764-2247-4bef-9185-0b9d0b5071e1",
  },
  {
    label: "Status Completed Bank",
    value:
      process.env.NODE_ENV === "production"
        ? "48ce8d83-4a1d-487a-8012-85ad5acf9a3f"
        : "fd661c40-b92c-4d43-9e36-8886c279571e",
  },
];

export const ordersFilterTree = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "Bank Status Successfull",
    value: "paid",
  },
  {
    label: "Bank Status Pending",
    value: "pending",
  },
  {
    label: "Bank Status Failed",
    value: "failed",
  },
];

export const receiveOrdersSecondFilterTree = [
  {
    label: "Amount",
    value: "AMOUNT",
    type: "number_range",
  },
  {
    label: "Customer Email",
    value: "USER_EMAIL",
    type: "input",
  },
  {
    label: "Payer Email",
    value: "USER_EMAIL",
    type: "input",
  },
  {
    label: "ID",
    value: "ID",
    type: "input",
  },
  {
    label: "Recieve ID",
    value: "RECEIVE_ID",
    type: "input",
  },
];

export const sellOrdersSecondFilterTree = [
  {
    label: "Amount",
    value: "AMOUNT",
    type: "number_range",
  },
  {
    label: "Fiat",
    value: "FIAT",
    type: "select",
    selectOptions: [],
  },
  {
    label: "Asset",
    value: "ASSET",
    type: "select",
    selectOptions: [],
  },
  // {
  //   label: "Withdrawal",
  //   value: "WITHDRAWAL",
  //   type: "select",
  //   selectOptions: [
  //     {
  //       label: "Successful",
  //       value: "successful",
  //     },
  //     {
  //       label: "Sending",
  //       value: "sending",
  //     },
  //     {
  //       label: "Pending",
  //       value: "pending",
  //     },
  //     {
  //       label: "Not Sent",
  //       value: "not_sent",
  //     },
  //   ],
  // },
  {
    label: "Transaction ID",
    value: "ORDER_ID",
    type: "input",
  },
  {
    label: "Billing Country",
    value: "BILLING_COUNTRY",
    type: "input",
  },
  {
    label: "Customer Email",
    value: "USER_EMAIL",
    type: "input",
  },
  {
    label: "Date",
    value: "DATE",
    type: "date_picker",
  },
  {
    label: "Transaction Status",
    value: "TRANSACTION_STATUS",
    type: "select",
    selectOptions: [
      {
        label: "Insufficient funds",
        value: "INSUFFICIENT_FUNDS",
      },
      {
        label: "Submitted",
        value: "SUBMITTED",
      },
      {
        label: "Blocked",
        value: "BLOCKED",
      },
      {
        label: "Cancelled",
        value: "CANCELLED",
      },
      {
        label: "Confirming",
        value: "CONFIRMING",
      },
      {
        label: "Queued",
        value: "QUEUED",
      },
      {
        label: "Processing Bank",
        value: "PROCESSING_BANK",
      },
      {
        label: "Released",
        value: "RELEASED",
      },
      {
        label: "Failed Bank",
        value: "FAILED_BANK",
      },
      {
        label: "Cancelled Bank",
        value: "CANCELLED_BANK",
      },
      {
        label: "Payout Executed",
        value: "PAYOUT_EXECUTED",
      },
      {
        label: "Payout Failed",
        value: "PAYOUT_FAILED",
      },
      {
        label: "Completed",
        value: "COMPLETED",
      },
      {
        label: "Failed",
        value: "FAILED",
      },
      {
        label: "Completed Bank",
        value: "COMPLETED_BANK",
      },
    ],
    super_admin: true,
  },
];

export const sellTransactionStatuses =
  process.env.NODE_ENV === "production"
    ? {
        INSUFFICIENT_FUNDS: "536e6c2a-ad26-4513-90f5-4e5a768b7bc9",
        FAILED: "45a12e42-5590-4175-a499-2ba88ff64f23",
        SUBMITTED: "fd6d32c9-f4e8-4df5-b054-20f49505a64d",
        BLOCKED: "887fc5f5-981f-470b-85b7-7943d146ef55",
        CANCELLED: "30de7d8d-f37d-43b0-8da5-58f55d93e4f2",
        CONFIRMING: "39cb43a0-8bda-4639-9608-2847fca7bfcc",
        COMPLETED: "04d68383-6d73-43c4-bcfb-c5519d095348",
        QUEUED: "7727a459-dc93-4edf-9511-49f6ddbe52bb",
        PROCESSING_BANK: "afe3d97e-aca3-428d-8b2a-6f408714970c",
        RELEASED: "97bc8063-90f6-4597-8268-517088e4a505",
        COMPLETED_BANK: "48ce8d83-4a1d-487a-8012-85ad5acf9a3f",
        FAILED_BANK: "9e787164-9e9e-459d-88e0-cbb5f8655367",
        CANCELLED_BANK: "07a19390-3508-414d-b221-868becb50d8e",
        PAYOUT_EXECUTED: "347411bd-de45-4812-8e40-cc64b8884379",
        PAYOUT_FAILED: "182ec800-66fc-42f8-9824-52302f6ca918",
      }
    : {
        INSUFFICIENT_FUNDS: "0f1f54eb-55cf-4caa-8734-dc3e4b5f97dd",
        FAILED: "9bf77764-2247-4bef-9185-0b9d0b5071e1",
        SUBMITTED: "60517b76-c6ca-47f8-9cae-5a459f2dbba1",
        BLOCKED: "e2c167fc-9cfa-425c-99cb-316a78311422",
        CANCELLED: "66b0f5ea-0bce-4482-80f8-435b843f88ab",
        CONFIRMING: "7261d227-1a05-4c2c-b93c-84fb75ce7cdf",
        COMPLETED: "6e460818-e7bc-4a83-884c-a795cc96cf14",
        QUEUED: "9870db05-1753-474e-bddb-5dca639823e2",
        PROCESSING_BANK: "035dacda-cf12-45bd-9e4e-816aabfc9da1",
        RELEASED: "45b36c9d-ca23-4941-82f7-65946b6d4cb1",
        COMPLETED_BANK: "fd661c40-b92c-4d43-9e36-8886c279571e",
        FAILED_BANK: "8975a867-b633-4dbb-aaad-c8304c15573c",
        CANCELLED_BANK: "43009ac5-59a5-4080-b01e-b75d14a33508",
        PAYOUT_EXECUTED: "b56f68f7-46f6-486c-997f-0286f0f04b5b",
        PAYOUT_FAILED: "35a7da63-ffba-4dc6-8804-11427eabe0a2",
      };

export const ordersSecondFilterTree = [
  {
    label: "Amount",
    value: "AMOUNT",
    type: "number_range",
  },
  {
    label: "Fiat",
    value: "FIAT",
    type: "select",
    selectOptions: [],
  },
  {
    label: "Asset",
    value: "ASSET",
    type: "select",
    selectOptions: [],
  },
  {
    label: "Withdrawal",
    value: "WITHDRAWAL",
    type: "select",
    selectOptions: [
      {
        label: "Successful",
        value: "successful",
      },
      {
        label: "Sending",
        value: "sending",
      },
      {
        label: "Pending",
        value: "pending",
      },
      {
        label: "Not Sent",
        value: "not_sent",
      },
    ],
  },
  {
    label: "Transaction ID",
    value: "ORDER_ID",
    type: "input",
  },
  {
    label: "Billing Country",
    value: "BILLING_COUNTRY",
    type: "input",
  },
  {
    label: "Customer Email",
    value: "USER_EMAIL",
    type: "input",
  },
  {
    label: "Date",
    value: "DATE",
    type: "date_picker",
  },
  {
    label: "Transaction Status",
    value: "TRANSACTION_STATUS",
    type: "select",
    selectOptions: [],
    super_admin: true,
  },
];

export const additionalOrderSecondFilterTree = [
  {
    label: "Rate",
    value: "RATE",
    type: "number_range",
    activate: false,
    index: 0,
  },
  {
    label: "Quantity",
    value: "QUANTITY",
    type: "number_range",
    activate: false,
    index: 1,
  },
  // {
  //   label: "Exchange",
  //   value: "EXCHANGE",
  //   type: "select",
  //   activate: false,
  //   index: 2,
  //   selectOptions: [
  //     {
  //       label: "Select an Exchange",
  //       value: "select_exchange",
  //     },
  //     {
  //       label: "Binance",
  //       value: "binance",
  //     },
  //     {
  //       label: "Bitfinex",
  //       value: "bitfinex",
  //     },
  //   ],
  // },
];

export const fireblockSecondFilterTree = [
  {
    label: "Name",
    value: "NAME",
    type: "input",
  },
  {
    label: "Code",
    value: "CODE",
    type: "input",
  },
  {
    label: "Type",
    value: "TYPE",
    type: "input",
  },
  {
    label: "Native asset",
    value: "NATIVE_ASSET",
    type: "input",
  },
];

export const sellFiatSecondFilterTree = [
  {
    label: "Name",
    value: "NAME",
    type: "input",
  },
  {
    label: "Symbol",
    value: "SYMBOL",
    type: "input",
  },
  {
    label: "Status",
    value: "SELL_STATUS",
    type: "select",
    selectOptions: [
      {
        label: "Supported",
        value: "supported",
      },
      {
        label: "Not supported",
        value: "not supported",
      },
    ],
  },
  // {
  //   label: "Min Sell Amount",
  //   value: "MIN_SELL_AMOUNT",
  //   type: "number_input",
  // },
  // {
  //   label: "Max Sell Amount",
  //   value: "MAX_SELL_AMOUNT",
  //   type: "number_input",
  // },
];

export const fiatSecondFilterTree = [
  {
    label: "Name",
    value: "NAME",
    type: "input",
  },
  {
    label: "Symbol",
    value: "SYMBOL",
    type: "input",
  },
  {
    label: "Status",
    value: "SUSPEND_STATUS",
    type: "select",
    selectOptions: [
      {
        label: "Supported",
        value: "supported",
      },
      {
        label: "Not supported",
        value: "not supported",
      },
    ],
  },
];

export const cryptoSecondFilterTree = [
  {
    label: "Core",
    value: "CORE",
    type: "input",
  },
  {
    label: "Code",
    value: "CODE",
    type: "input",
  },
  {
    label: "Symbol",
    value: "SYMBOL",
    type: "input",
  },
  {
    label: "Markup rate",
    value: "MARKUP_RATE",
    type: "number_input",
  },
  {
    label: "Status",
    value: "SUSPEND_STATUS",
    type: "select",
    selectOptions: [
      {
        label: "Supported",
        value: "supported",
      },
      {
        label: "Not supported",
        value: "not supported",
      },
    ],
  },
];

export const sellCryptoSecondFilterTree = [
  {
    label: "Core",
    value: "CORE",
    type: "input",
  },
  {
    label: "Code",
    value: "CODE",
    type: "input",
  },
  {
    label: "Symbol",
    value: "SYMBOL",
    type: "input",
  },
  {
    label: "Negative markup rate",
    value: "NEGATIVE_MARKUP_RATE",
    type: "number_input",
  },
  {
    label: "Status",
    value: "SELL_SUPPORTED",
    type: "select",
    selectOptions: [
      {
        label: "Supported",
        value: "supported",
      },
      {
        label: "Not supported",
        value: "not supported",
      },
    ],
  },
];

export const usersFilterTree = [
  {
    label: "All",
    value: "ALL",
  },
  {
    label: "KYC Verified",
    value: "VERIFIED",
  },
  {
    label: "KYC Not Verified",
    value: "NOT_VERIFY",
  },
  {
    label: "KYC Declined",
    value: "DECLINED",
  },
  // {
  //   label: "Blocked List",
  //   value: "DECLINED",
  // },
  // {
  //   label: "Allow List",
  //   value: "VERIFIED",
  // },
];

export const usersSecondFilterTree = [
  {
    label: "Email",
    value: "EMAIL",
    type: "input",
  },
  {
    label: "Customer ID",
    value: "USER_ID",
    type: "input",
  },
  {
    label: "Date",
    value: "DATE",
    type: "date_picker",
  },
  {
    label: "User Status",
    value: "USER_STATUS",
    type: "select",
    selectOptions: [
      {
        label: "Active",
        value: "active",
      },
      {
        label: "Blocked",
        value: "blocked",
      },
      {
        label: "Deleted",
        value: "deleted",
      },
    ],
  },
];

export const flagsEndpoint = "https://flagcdn.com";
