import { useCallback, useEffect, useMemo, useState } from "react";

import WebhooksEmpty from "./WebhooksEmpty";
import WebhooksList from "./WebhooksList";
import { instance } from "utils/api";
import { ProfileDataResponse, ProfileTypes } from "./WebhooksPage.props";
import WebhookListSkeleton from "./WebhookListSkeleton";

const WebhooksPage = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState<ProfileTypes | undefined>(
    undefined
  );

  const getProfileData = useCallback(async () => {
    setLoading(true);

    try {
      const response = await instance.get<ProfileDataResponse>(
        "/admin/profile"
      );

      setProfileData(response.data.data);
      setTimeout(() => setLoading(false), 500);

      return;
    } catch (e) {
      console.log(e);
      setLoading(false);

      return;
    }
  }, []);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  const renderContent = useMemo(() => {
    if (loading) {
      return <WebhookListSkeleton />;
    }

    if (profileData?.callback_success) {
      return (
        <WebhooksList
          getProfileData={getProfileData}
          loading={loading}
          profile={profileData as ProfileTypes}
        />
      );
    }

    return <WebhooksEmpty />;
  }, [loading, profileData, getProfileData]);

  return renderContent;
};

export default WebhooksPage;
