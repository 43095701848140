import { useHistory } from "react-router-dom";
import {
  usePartnerDispatchContext,
  usePartnerStateContext,
} from "providers/Partners";
import { removeAdminEndpoint } from "services/webhooks";

import { TableActionButton } from "components/base";

const PartnerWebhookList = (): JSX.Element => {
  const { partnerDetails } = usePartnerStateContext();
  const { getPartnerById } = usePartnerDispatchContext();
  const history = useHistory();

  const partnerData = {
    active: partnerDetails?.active,
    urls: partnerDetails?.urls && partnerDetails?.urls.map((item) => item.url),
    name: partnerDetails?.name,
    contactName: partnerDetails?.contactName,
    phone: partnerDetails?.phone,
    email: partnerDetails?.email,
    partnerCommission: partnerDetails?.partnerCommission,
    partnerMarkup: partnerDetails?.partnerMarkup,
    callback_error: partnerDetails?.callback_error,
    comment: partnerDetails?.comment,
  };

  return (
    <div className="webhooks-list__container">
      <div className="webhooks-list__top-block">
        <h1 className="webhooks-list__header">Hosted endpoints</h1>
        <button
          onClick={() => history.push(`/webhooks/update/${partnerDetails?.id}`)}
        >
          Update endpoint
        </button>
      </div>
      <table className="webhooks-list__table">
        <thead>
          <tr>
            <th>URL</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{partnerDetails?.callback_success}</td>
            <td>
              <div className="user-status-td transaction-status-td user-status-active">
                Active
              </div>
            </td>
            <td>
              <TableActionButton
                isLast={true}
                buttons={[
                  {
                    title: "Delete ",
                    role: "endpoint",
                    descriptionTitle: "Delete the endpoint",
                    onClick: async () => {
                      try {
                        await removeAdminEndpoint({
                          partnerId: partnerDetails?.id as string,
                          partnerData,
                        });
                        await getPartnerById(partnerDetails?.id);
                      } catch (error) {
                        console.log(error);
                      }
                    },
                  },
                ]}
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PartnerWebhookList;
