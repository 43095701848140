import Button from "components/Button";

const DescriptionPopup = ({
  description,
  setDescription,
  updateDescription,
  loading,
  closePopup,
}: {
  description: string | null;
  setDescription: (description: string) => void;
  updateDescription: () => void;
  loading: boolean;
  closePopup: () => void;
}): JSX.Element => {
  return (
    <div className="popover-stats__container">
      <h1 className="popover-stats__title">Description</h1>
      <div className="popover-stats__container--textarea">
        <textarea
          placeholder="Comments..."
          value={description as string}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="radio-buttons__wrapper">
        <Button type="button" className="button-cancel" onClick={closePopup}>
          Cancel
        </Button>
        <Button
          type="button"
          className="button-submit"
          onClick={updateDescription}
          disabled={loading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default DescriptionPopup;
