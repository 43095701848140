import React, {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
} from "react";
import * as fileSaver from "file-saver";
import { useAuthDispatchContext } from "providers/Auth";
import moment from "moment";
import { AxiosError } from "axios";

import { State, Dispatch, ActionTypes, DispatchContext } from "./types";
import reducer from "./reducers";
import { instance, assetInstance, receiveFlowInstance } from "utils/api";
import { showError } from "utils/helpers";

const initialState: State = {
  isLoading: true,
  orders: [],
  ordersCount: 0,
  orderDetails: null,
  totalAmount: [],
  totalCommission: [],
  totalOrders: [],
  transactionStatuses: [],
  updatedStatus: { tr_status: "", order_id: "" },
};

const ReceiveOrderStateContext = createContext<State | undefined>(undefined);
const ReceiveOrderDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

const ReceiveOrderProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ReceiveOrderStateContext.Provider value={state}>
      <ReceiveOrderDispatchContext.Provider value={dispatch}>
        {children}
      </ReceiveOrderDispatchContext.Provider>
    </ReceiveOrderStateContext.Provider>
  );
};

const useReceiveOrderState = (): State => {
  const context = useContext(ReceiveOrderStateContext);

  if (typeof context === "undefined") {
    throw new Error("useOrderStateContext must be used within a OrderProvider");
  }

  return context;
};

const useReceiveOrderDispatchContext = (): DispatchContext => {
  const dispatch = useContext(ReceiveOrderDispatchContext);
  const { signOut } = useAuthDispatchContext();

  if (typeof dispatch === "undefined") {
    throw new Error(
      "useOrderDispatchContext must be used within a OrderProvider"
    );
  }

  const getTrStatuses = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await instance.get("/payment/orders/statuses");

      dispatch({ type: ActionTypes.SET_TR_STATUSES, data });
    } catch (err: unknown) {
      if (
        ((err as AxiosError).response?.status === 401 &&
          !localStorage.getItem("refreshToken")) ||
        (err as AxiosError).response?.status === 401
      ) {
        return signOut();
      }

      showError(err);
    }
  }, [signOut, dispatch]);

  const updateStatus = useCallback(
    async ({ id, tr_status }: { id: string; tr_status: string }) => {
      try {
        const { data: data } = await instance.put(
          `/payment/orders/details/${id}`,
          {
            tr_status,
          }
        );

        return data;
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const getOrders = useCallback(
    async ({
      id,
      receiveId,
      keyword,
      page,
      fromAmount,
      toAmount,
      customerEmail,
      payerEmail,
      receiveStatus,
    }) => {
      const token = localStorage.getItem("token");
      dispatch({ type: ActionTypes.SET_LOADING, data: true });

      try {
        const res = await receiveFlowInstance.post(
          "/receive/orders",
          {
            page: page,
            limit: 10,
            ...(id && { id }),
            ...(receiveId && { receiveId }),
            ...(keyword && { keyword }),
            ...(fromAmount && toAmount && { fromAmount, toAmount }),
            ...(customerEmail && { customerEmail }),
            ...(payerEmail && { payerEmail }),
            ...(receiveStatus && { status: receiveStatus }),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        dispatch({ type: ActionTypes.SET_ORDERS, data: res.data.result });
        dispatch({ type: ActionTypes.SET_LOADING, data: false });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const getClients = useCallback(
    async (page, search = "", filters = {}) => {
      try {
        const res = await instance.get("/payment/orders", {
          params: { page, search, ...filters },
        });

        dispatch({ type: ActionTypes.SET_ORDERS, data: res.data.data });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const getOrdersStatistics = useCallback(async () => {
    try {
      const res = await instance.get("/payment/orders/stats", {
        params: { page: 1, limit: 1 },
      });

      dispatch({
        type: ActionTypes.SET_ORDERS_STATISTICS,
        data: res.data.data,
      });
    } catch (err: unknown) {
      if (
        ((err as AxiosError).response?.status === 401 &&
          !localStorage.getItem("refreshToken")) ||
        (err as AxiosError).response?.status === 401
      ) {
        return signOut();
      }

      showError(err);
    }
  }, [dispatch, signOut]);

  const downloadReceiveOrders = useCallback(
    async ({
      id,
      receiveId,
      keyword,
      fromAmount,
      toAmount,
      customerEmail,
      payerEmail,
      receiveStatus,
      selectedOrder,
    }) => {
      const token = localStorage.getItem("token");

      try {
        const res = await receiveFlowInstance.post(
          "/receive/orders/download/excel",
          {
            limit: 10000,
            ...(id && { id }),
            ...(receiveId && { receiveId }),
            ...(keyword && { keyword }),
            ...(fromAmount && toAmount && { fromAmount, toAmount }),
            ...(customerEmail && { customerEmail }),
            ...(payerEmail && { payerEmail }),
            ...(receiveStatus && { status: receiveStatus }),
            ...(selectedOrder && { orders: selectedOrder }),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        const date = moment().format("DD-MM-YYY_hh:mm");

        fileSaver.saveAs(res.data as unknown as Blob, `orders-${date}.xlsx`);
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [signOut]
  );

  const getOrderById = useCallback(
    async (id) => {
      const token = localStorage.getItem("token");

      try {
        const res = await receiveFlowInstance.get(`/receive/order/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        dispatch({
          type: ActionTypes.SET_ORDER_DETAILS,
          data: res.data.result,
        });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const deleteOrder = useCallback(
    async (id) => {
      try {
        await instance.delete(`/payment/orders/${id}`);

        dispatch({
          type: ActionTypes.DELETE_ORDER,
          data: id,
        });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const setLoading = (isLoading: boolean) =>
    dispatch({ type: ActionTypes.SET_LOADING, data: isLoading });

  const setUpdatedStatus = ({ tr_status, order_id }) => {
    dispatch({
      type: ActionTypes.SET_UPDATES_STATUS,
      data: { tr_status, order_id },
    });
  };

  const getFiatList = async () => {
    try {
      const { data } = await assetInstance.get("/fiat/fiat_currencies");

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAssetList = async () => {
    try {
      const { data } = await assetInstance.get(
        "/crypto/admin_crypto_currencies"
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const resetOrdersCount = () => {
    dispatch({ type: ActionTypes.RESET_ORDERS_COUNT, data: 0 });

    return;
  };

  return {
    getOrders,
    getClients,
    downloadReceiveOrders,
    getOrderById,
    getOrdersStatistics,
    deleteOrder,
    setLoading,
    getTrStatuses,
    updateStatus,
    setUpdatedStatus,
    getFiatList,
    getAssetList,
    resetOrdersCount,
  };
};

export default ReceiveOrderProvider;
export { useReceiveOrderDispatchContext, useReceiveOrderState };
