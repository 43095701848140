import axios, { AxiosResponse } from "axios";
import * as fileSaver from "file-saver";
import moment from "moment";

import { instance } from "utils/api";

export const createOrder = async ({
  asset,
  amount,
  network,
  address,
  code,
}: {
  asset: string;
  amount: number;
  network: string;
  address: string;
  code: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}): Promise<any | undefined> => {
  try {
    const response = await instance.post("/partners/order", {
      asset,
      amount,
      network,
      address,
      code,
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response;
    }
  }
};

export const getPayoutList = async ({
  partnerId,
  address,
  limit = 10,
  page = 1,
  orderBy = "desc",
  nextPage,
  prevPage,
}: {
  partnerId?: string | undefined;
  address?: string | undefined;
  limit: number;
  page: number;
  orderBy: string;
  nextPage?: string | undefined;
  prevPage?: string | undefined;
}): Promise<AxiosResponse | undefined> => {
  try {
    const response = await instance.get("/partners/order/list", {
      params: {
        type: "Payout",
        limit,
        page,
        orderBy,
        address,
        partner_id: partnerId,
        nextPage,
        prevPage,
      },
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response;
    }
  }
};

export const getBalance = async ({
  partnerId,
  partnerName,
  asset,
}: {
  partnerId: string;
  partnerName: string;
  asset: string;
}): Promise<AxiosResponse | undefined> => {
  try {
    const response = await instance.post("/partners/balance", {
      partnerId,
      partnerName,
      asset,
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response;
    }
  }
};

export const getPayoutStat = async (): Promise<AxiosResponse | undefined> => {
  try {
    const response = await instance.get("/partners/order/statuses");

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response;
    }
  }
};

export const updatePayout = async ({
  id,
  status,
  desc,
}: {
  id: string;
  status?: string;
  desc?: string;
}): Promise<AxiosResponse | undefined> => {
  try {
    const response = await instance.post("/partners/change-status", {
      id,
      status,
      desc,
    });

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response;
    }
  }
};

export const exportPayout = async ({
  partnerId,
  payoutOrders,
  payInOrders,
  exportAll,
  address,
  type,
}: {
  partnerId: string;
  payoutOrders?: string[] | undefined;
  payInOrders?: string[] | undefined;
  exportAll?: boolean | undefined;
  address: string;
  type?: string;
}): Promise<AxiosResponse | undefined> => {
  try {
    const response = await instance.post(
      "/partners/order/export",
      {
        partnerId,
        ...(payoutOrders && { payoutOrders }),
        ...(payInOrders && { payInOrders }),
        ...(typeof exportAll !== undefined && { exportAll }),
        address,
        ...(type && { type }),
      },
      {
        responseType: "blob",
      }
    );

    const date = moment().format("DD-MM-YYY_hh:mm");

    fileSaver.saveAs(response.data as unknown as Blob, `payouts-${date}.xlsx`);
    // return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response;
    }
  }
};

export const resendCode = async (): Promise<AxiosResponse | undefined> => {
  try {
    const response = await instance.post("/partners/order/confirm-sms/resend");

    return response;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      return error.response;
    }
  }
};
