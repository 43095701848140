import React, { useContext, useState } from "react";
import Popover from "react-tiny-popover";
import "./styles.scss";
import { AdminUsersDispatchContext } from "providers/AdminUsers";

import deleteUserSvg from "../../assets/img/delete-user.svg";
import { Icon } from "components/base";
import { instance } from "utils/api";

function ActionPopOver({ id }: { id: string }): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch } = useContext(AdminUsersDispatchContext);

  const handleDeleteClick = async () => {
    await instance.delete(`/admin/${id}`);
    dispatch({ type: "DELETE_ADMIN_USER", payload: id });

    setIsOpen(false);
  };

  return (
    <Popover
      containerClassName={"className"}
      isOpen={isOpen}
      onClickOutside={() => {
        setIsOpen(false);
      }}
      position={"bottom"}
      padding={0}
      //   contentLocation={2}
      content={() => (
        <div className="delete-user" onClick={handleDeleteClick}>
          <img src={deleteUserSvg} alt="Delete User" />
          Delete User
        </div>
      )}
    >
      <span
        className="svg-icon svg-icon-5 m-0 cursor-pointer "
        onClick={() => setIsOpen(true)}
      >
        <Icon name="action" />
      </span>
    </Popover>
  );
}

export default ActionPopOver;
