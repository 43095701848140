import { State, Action, ActionTypes, PayoutItem, InitialPayIn } from "./types";

const updatePayout = (state: State, payload: PayoutItem) => {
  const updateIdx = state.payoutList?.findIndex(
    (item) => item.id === payload.id
  );

  if (typeof updateIdx !== "undefined" && updateIdx !== -1) {
    const updatedPayoutList: PayoutItem[] = state.payoutList as PayoutItem[];

    updatedPayoutList[updateIdx] = payload;

    return {
      ...state,
      payoutList: updatedPayoutList,
    };
  }

  return state;
};

const setPayoutExport = (state: State, payload: string) => {
  const payoutIndex = state.payoutToExport?.findIndex(
    (item) => item === payload
  );

  if (typeof payoutIndex === "undefined" || payoutIndex === -1) {
    return {
      ...state,
      payoutToExport: state.payoutToExport?.length
        ? [...(state.payoutToExport as string[]), payload]
        : [payload],
    };
  }

  return {
    ...state,
    payoutToExport: state.payoutToExport?.filter(
      (item) => item !== payload
    ) as string[],
  };
};

const setExportPage = (state: State, payload: number) => {
  const pageIndex = state.exportPages.findIndex((item) => item === payload);

  if (typeof pageIndex === "undefined" || pageIndex === -1) {
    return {
      ...state,
      exportPages: [...state.exportPages, payload],
    };
  }

  return {
    ...state,
    exportPages: state.exportPages.filter((page) => page !== payload),
  };
};

const clearExports = (state: State, payload: PayoutItem[]) => {
  const payoutIds: string[] = [];
  const payInIds: string[] = [];

  payload.map((item: PayoutItem) => {
    // if (item?.type && item.type?.toLowerCase() === "payout") {
    //   payoutIds.push(item.id as string);
    // } else {
    //   payInIds.push(item.id as string);
    // }

    payoutIds.push(item.id as string);
  });

  return {
    ...state,
    payoutToExport: state.payoutToExport?.filter(
      (item) => !payoutIds.includes(item)
    ) as string[],
    payInToExport: state.payInToExport?.filter(
      (item) => !payInIds.includes(item)
    ) as string[],
  };
};

const setAllExport = (state: State, payload: PayoutItem[]) => {
  const payoutIds: string[] = [];
  const payInIds: string[] = [];

  payload.map((item: PayoutItem) => {
    // if (item?.type && item?.type.toLowerCase() === "payout") {
    //   if (!state.payoutToExport?.includes(item.id as string)) {
    //     payoutIds.push(item.id as string);
    //   }
    // } else {
    //   if (!state.payInToExport?.includes(item.id as string)) {
    //     payoutIds.push(item.id as string);
    //   }
    // }

    if (!state.payoutToExport?.includes(item.id as string)) {
      payoutIds.push(item.id as string);
    }
  });

  return {
    ...state,
    payoutToExport: state.payoutToExport?.length
      ? ([...(state.payoutToExport as string[]), ...payoutIds] as string[])
      : payoutIds,
    payInIds: state.payInToExport?.length
      ? ([...(state.payInToExport as string[]), ...payInIds] as string[])
      : payInIds,
  };
};

export const setPayIns = (state: State, payload: InitialPayIn[]): State => {
  const payIns = payload?.map((item) => ({
    id: item.id,
    address: item.destinationAddress,
    admin_desc: null,
    admin_id: null,
    amount: item.amount,
    asset: item.feeCurrency,
    createdAt: item.createdAt,
    desc: null,
    network: null,
    partner_id: null,
    type: "pay-in",
    status: item?.subStatus.toLowerCase(),
    tx_id: item.txHash,
  }));

  return {
    ...state,
    payInList: payIns as PayoutItem[],
  };
};

const resetExport = (state: State) => {
  return {
    ...state,
    exportPages: [],
    payoutToExport: null,
    payInToExport: null,
  };
};

export const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_AMOUNT:
      return {
        ...state,
        amount: payload as number,
      };
    case ActionTypes.SET_AVAILABLE_BALANCE:
      return {
        ...state,
        availableBalance: payload as number,
      };
    case ActionTypes.SET_WALLET_ADDRESS:
      return {
        ...state,
        walletAddress: payload as string,
      };
    case ActionTypes.SET_ONE_TIME_PASSWORD:
      return {
        ...state,
        oneTimePassword: payload as number,
      };
    case ActionTypes.SET_OTP_ATTEMPTS:
      return {
        ...state,
        otpAttempts: payload as number,
      };
    case ActionTypes.SET_PAYOUT:
      return {
        ...state,
        payoutList: payload as PayoutItem[],
      };
    case ActionTypes.SET_PAYIN:
      return setPayIns(state, payload as InitialPayIn[]);
    case ActionTypes.SET_PAYOUT_STATUSES:
      return {
        ...state,
        payoutStatuses: payload as string[],
      };
    case ActionTypes.UPDATE_PAYOUT:
      return updatePayout(state, payload as PayoutItem);
    case ActionTypes.SET_PAYOUT_EXPORT:
      return setPayoutExport(state, payload as string);
    case ActionTypes.SET_PAGINATION_POINTERS:
      return {
        ...state,
        ...(payload as { nextPage: string; prevPage: string }),
      };
    case ActionTypes.SET_PAGE:
      return {
        ...state,
        page: payload as number,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: payload as boolean,
      };
    case ActionTypes.SET_ACTIVE_BALANCE:
      return {
        ...state,
        activeBalance: payload as string,
      };
    case ActionTypes.SET_AVAILABLE_PAYOUT_PAGES:
      return {
        ...state,
        availablePayoutPage: payload as number,
      };
    case ActionTypes.SET_PAYOUT_WALLET:
      return {
        ...state,
        payoutWallet: payload as string,
      };
    case ActionTypes.SET_BALANCE_LOADING:
      return {
        ...state,
        balanceLoading: payload as boolean,
      };
    case ActionTypes.SET_INVALID_OTP_MESSAGE:
      return {
        ...state,
        invalidOtpMessage: payload as string,
      };
    case ActionTypes.SET_EXPORT_PAGE:
      return setExportPage(state, payload as number);
    case ActionTypes.SET_CLEAR_EXPORT:
      return clearExports(state, payload as PayoutItem[]);
    case ActionTypes.SET_ALL_EXPORT:
      return setAllExport(state, payload as PayoutItem[]);
    case ActionTypes.RESET_EXPORT:
      return resetExport(state);
    default:
      return state;
  }
};
