import { v4 as uuid } from "uuid";
import { useWebhookState } from "providers/Webhooks";

import EventAccordion from "./EventAccordion";
import { EventTypes } from "./SelectEvent.props";

const EventList = (): JSX.Element => {
  const { eventTypes } = useWebhookState();

  return (
    <div className="select-event__event-list">
      {Object.keys(eventTypes as EventTypes)?.map((itemKey) => (
        <EventAccordion
          key={uuid()}
          title={itemKey}
          eventItem={(eventTypes as EventTypes)[itemKey]}
        />
      ))}
    </div>
  );
};

export default EventList;
