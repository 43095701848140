import { FC, useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
// import {
//   useWidgetConsoleDispatch,
//   useWidgetConsoleState,
//   // useWidgetConsoleState,
// } from "providers/WidgetConsole";
import { useWidgetFiltersState } from "providers/WidgetFilter";
import { useLocation } from "react-router-dom";

import { AddCryptoProps, FireblockProps } from "./AddCrypto.props";
import { Modal } from "components/base";
import { ReactComponent as CloseModalLogo } from "../../../components/base/Icon/icons/close-modal.svg";

const AddCryptoModal: FC<AddCryptoProps> = ({
  createCrypto,
  updateCryptoStatus,
  setChosenFireblock,
  getCrypto,
  page,
  isEdit,
  isOpen,
  chosenFireblock,
  closeModal,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  const location = useLocation();
  // const { chosenFireblock } = useWidgetConsoleState();
  const [isLoading, setIsLoading] = useState(false);
  // const { createCrypto, updateCryptoStatus, setChosenFireblock, getCrypto } =
  //   useWidgetConsoleDispatch();

  // const { page } = useWidgetConsoleState();
  const {
    core,
    code,
    symbol: cryptoSymbol,
    markup_rate,
    is_suspended,
  } = useWidgetFiltersState();

  const [markupRate, setMarkupRate] = useState(
    chosenFireblock?.markup_rate ?? 0
  );

  const [negativeRate, setNegativeRate] = useState(
    chosenFireblock?.negative_markup ?? 0
  );

  const [addressRegex, setAddressRegex] = useState(
    chosenFireblock?.address_regex ?? ""
  );

  const [symbol, setSymbol] = useState(chosenFireblock?.symbol ?? "");
  const [testnetRegex, setTestnetRegex] = useState(
    chosenFireblock?.testnet_address_regex ?? ""
  );

  const submitData = async () => {
    if (
      !addressRegex ||
      !symbol ||
      !testnetRegex ||
      typeof markupRate === undefined ||
      typeof markupRate === null
    )
      return;

    try {
      setIsLoading(true);

      let data;

      if (isEdit) {
        data = await updateCryptoStatus({
          id: chosenFireblock.id,
          isSuspended: chosenFireblock.is_suspended as boolean,
          is_sell_supported: chosenFireblock.is_sell_supported as boolean,
          address_regex: addressRegex as string,
          testnet_address_regex: testnetRegex as string,
          symbol: symbol as string,
          code: chosenFireblock.code as string,
          core: chosenFireblock.type as string,
          name: chosenFireblock.name as string,
          native_asset: chosenFireblock.nativeAsset as string,
          markup_rate: markupRate as number,
          negative_markup: negativeRate as number,
        });

        if (data) {
          await getCrypto(
            page,
            core,
            code,
            cryptoSymbol,
            markup_rate,
            is_suspended
          );
        }
      } else {
        if (createCrypto) {
          data = await createCrypto({
            address_regex: addressRegex,
            code: chosenFireblock?.code as string,
            name: chosenFireblock?.name as string,
            native_asset: (chosenFireblock as FireblockProps)
              ?.nativeAsset as string,
            symbol: symbol,
            core: (chosenFireblock as FireblockProps)?.type as string,
            testnet_address_regex: testnetRegex,
            markup_rate: markupRate,
          });
        }
      }

      if (data) {
        closeModal();
        setChosenFireblock(null);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useMemo(() => {
    clearErrors();

    if (chosenFireblock?.address_regex) {
      setValue("address_regex", chosenFireblock.address_regex);
    }

    if (chosenFireblock?.testnet_address_regex) {
      setValue("testnet", chosenFireblock.testnet_address_regex);
    }

    if (chosenFireblock?.symbol) {
      setValue("symbol", chosenFireblock.symbol);
    }

    setValue("code", chosenFireblock.code);
    setValue("name", chosenFireblock.name);
    setValue("native_asset", (chosenFireblock as FireblockProps).nativeAsset);
    setValue("core", (chosenFireblock as FireblockProps).type);
    setValue("markup_rate", chosenFireblock?.markup_rate);
    setValue("negative_rate", chosenFireblock?.negative_markup);
  }, [chosenFireblock, setValue, clearErrors]);

  const renderError = useCallback(
    (errorName: string) => {
      if (errors[errorName]) {
        switch (errors[errorName].type) {
          case "minLength":
            return (
              <p className="input-error-message add-crypto-error">
                The length should not be less than 2 characters.
              </p>
            );

          default:
            return (
              <p className="input-error-message add-crypto-error">
                {errors[errorName].message}
              </p>
            );
        }
      }

      return null;
    },
    [errors]
  );

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="modal-content-container add-crypto-modal">
        <form onSubmit={handleSubmit(submitData)}>
          <div className="modal-header" id="kt_modal_add_customer_header">
            <div>
              <h2 className="fw-bolder modal-header-title-m">
                {isEdit ? "Edit" : "Add"} Crypto
              </h2>
            </div>
            <div className="color">
              <span
                className="svg-icon svg-icon-1"
                onClick={() => {
                  closeModal();
                  clearErrors();
                }}
              >
                <CloseModalLogo />
              </span>
            </div>
          </div>
          <div className="modal-body py-10 px-lg-17">
            <div className="modal-body-content">
              <div className="modal-content-row">
                <div className="modal-content-col">
                  <label>Address regex</label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    {...register("address_regex", {
                      required: "Address regex is required",
                      minLength: 2,
                    })}
                    name="address_regex"
                    defaultValue={addressRegex}
                    onChange={(e) => setAddressRegex(e.target.value)}
                  />
                  {renderError("address_regex")}
                </div>
              </div>
              <div className="modal-content-row">
                <div className="modal-content-col">
                  <label>Code</label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    {...register("code", { required: "Code is required" })}
                    name="code"
                    defaultValue={chosenFireblock?.code}
                    readOnly
                  />
                  {errors?.code && (
                    <p className="input-error-message add-crypto-error">
                      {errors.code?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-content-row">
                <div className="modal-content-col">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    {...register("name", { required: "Name is required" })}
                    name="name"
                    defaultValue={chosenFireblock?.name}
                    readOnly
                  />
                  {errors?.name && (
                    <p className="input-error-message add-crypto-error">
                      {errors.name?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-content-row">
                <div className="modal-content-col">
                  <label>Native assets</label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    {...register("native_assets", {
                      required: "Native asset is required",
                    })}
                    name="native_assets"
                    defaultValue={
                      (chosenFireblock as FireblockProps)?.nativeAsset
                    }
                    readOnly
                  />
                  {errors?.native_assets && (
                    <p className="input-error-message">
                      {errors.native_assets?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="modal-content-row">
                <div className="modal-content-col">
                  <label>Symbol</label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    {...register("symbol", {
                      required: "Symbol is required",
                      minLength: 2,
                    })}
                    name="symbol"
                    defaultValue={symbol}
                    onChange={(e) => setSymbol(e.target.value)}
                  />
                  {renderError("symbol")}
                </div>
              </div>
              <div className="modal-content-row">
                <div className="modal-content-col">
                  <label>Core</label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    {...register("core", { required: "Core is required" })}
                    name="core"
                    defaultValue={(chosenFireblock as FireblockProps)?.type}
                    readOnly
                  />
                  {errors?.core && (
                    <p className="input-error-message add-crypto-error">
                      {errors.core?.message}
                    </p>
                  )}
                </div>
              </div>
              {location.pathname.includes("buy-flow") && (
                <div className="modal-content-row">
                  <div className="modal-content-col">
                    <label>Markup rate</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      min="0"
                      className="form-control form-control-solid"
                      {...register("markup_rate", {
                        required: "Markup rate is required",
                      })}
                      name="markup_rate"
                      value={markupRate}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;

                        if (e.target.value === "" || re.test(e.target.value)) {
                          setMarkupRate(parseFloat(e.target.value));
                        }
                      }}
                    />
                    {errors?.markup_rate && (
                      <p className="input-error-message add-crypto-error">
                        {errors?.markup_rate?.message}
                      </p>
                    )}
                  </div>
                </div>
              )}
              {location.pathname.includes("sell-flow") && (
                <div className="modal-content-row">
                  <div className="modal-content-col negative_markup">
                    <label>Negative markup rate</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      min="0"
                      className="form-control form-control-solid"
                      {...register("negative_rate", {
                        required: "Negative markup rate is required",
                      })}
                      name="negative_rate"
                      value={negativeRate}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;

                        if (e.target.value === "" || re.test(e.target.value)) {
                          setNegativeRate(parseFloat(e.target.value));
                        }
                      }}
                    />
                    {errors?.negative_rate && (
                      <p className="input-error-message add-crypto-error">
                        {errors?.negative_rate?.message}
                      </p>
                    )}
                  </div>
                </div>
              )}
              <div className="modal-content-row">
                <div className="modal-content-col">
                  <label>Testnet address regex</label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    {...register("testnet", {
                      required: "Testnet is required",
                      minLength: 2,
                    })}
                    name="testnet"
                    defaultValue={testnetRegex}
                    onChange={(e) => setTestnetRegex(e.target.value)}
                  />
                  {renderError("testnet")}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button
              type="button"
              className="button button-secondary button-cancel"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="button button-primary button-add"
              disabled={isLoading}
            >
              <span className="indicator-label">
                {isEdit ? "Edit" : "Add"} crypto
              </span>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddCryptoModal;
