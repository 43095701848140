import {
  useClientDispatchContext,
  useClientStateContext,
} from "providers/Clients";
import { useClientFilters } from "providers/ClientFilter";

import { Icon } from "components/base";
import Button from "components/Button";

const ClientExportButtons = ({
  partnerId,
}: {
  partnerId: string;
}): JSX.Element => {
  const { downloadClients } = useClientDispatchContext();
  const {
    exportedClient,
    arrayChecking,
    checkedID,
    visibleClientsCount,
    sorting,
    ordering,
    filters,
    filtersEmail,
    kycVerification,
    search,
    dateFrom,
    dateTo,
  } = useClientFilters();

  const { clients } = useClientStateContext();

  return (
    <div className="button-export__wrapper">
      <Button
        type="button"
        className="button--transparent"
        onClick={() => location.reload()}
      >
        <Icon name="rotate" />
      </Button>

      <Button
        type="button"
        className="button--transparent button--export"
        disabled={!exportedClient?.length}
        onClick={() => {
          (exportedClient?.length !== 0 || checkedID) &&
            downloadClients(
              visibleClientsCount,
              sorting,
              ordering,
              exportedClient,
              checkedID,
              clients,
              partnerId,
              arrayChecking,
              search,
              filters,
              filtersEmail,
              kycVerification,
              dateFrom,
              dateTo
            );
        }}
      >
        <Icon name="export_button" />
        Export Selected
      </Button>
      <Button
        type="button"
        className="button--transparent button--export"
        onClick={() =>
          downloadClients(
            visibleClientsCount,
            undefined,
            undefined,
            undefined,
            checkedID,
            null,
            partnerId,
            undefined,
            search,
            filters,
            filtersEmail,
            kycVerification,
            dateFrom,
            dateTo
          )
        }
      >
        <Icon name="export_button" />
        Export
      </Button>
    </div>
  );
};

export default ClientExportButtons;
