import axios, { AxiosResponse, AxiosInstance } from "axios";

import { TokensError } from "./chatHelpers";

const basicAuth = {
  username: "yzBTwC7YZsS?_TSnS3&G5_s",
  password: "hXnzDfWQLXnsA9Ns^zcY?=Qg?++=Fq",
};

export const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const assetInstance = axios.create({
  baseURL: process.env.REACT_APP_ASSET_URL,
  auth: basicAuth,
});

export const balanceInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

export const sellFlowInstance = axios.create({
  baseURL: process.env.REACT_APP_SELL_FLOW_URL,
});

export const receiveFlowInstance = axios.create({
  baseURL: process.env.REACT_APP_RECEIVE_FLOW_URL,
});

const succesIntercepter = (
  res:
    | AxiosResponse<Record<string, unknown>>
    | Promise<AxiosResponse<Record<string, unknown>>>
) => res;

const failIntercepter = (instance) => async (err: TokensError) => {
  if (
    err.response.status === 401 &&
    err.config.url !== "/admin/refresh-token"
  ) {
    try {
      if (!localStorage.getItem("refreshToken")) return Promise.reject(err);
      const tokensVal = await instance.post("/admin/refresh-token", {
        refreshToken: localStorage.getItem("refreshToken"),
      });

      localStorage.setItem("token", tokensVal.data.data.token);
      localStorage.setItem(
        "refreshToken",
        tokensVal.data.data.refreshToken.token
      );

      setAxiosHeader(tokensVal?.data.data.token, instance);

      const res = await instance({
        ...err.config,
        headers: {
          Authorization: `Bearer ${tokensVal?.data.data.token}`,
        },
      });

      return res;
    } catch (error) {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("token");
      window.location.href = "/login";

      return Promise.reject(err);
    }
  }

  return Promise.reject(err);
};

instance.interceptors.response.use(
  succesIntercepter,
  failIntercepter(instance)
);

sellFlowInstance.interceptors.response.use(
  succesIntercepter,
  failIntercepter(sellFlowInstance)
);

export const setAxiosHeader = (
  token: string,
  instance: AxiosInstance
): void => {
  instance.defaults.headers.common.Authorization = `Bearer ${token}`;
  instance.defaults.headers.Authorization = `Bearer ${token}`;
  receiveFlowInstance.defaults.headers.Authorization = `Bearer ${token}`;
  sellFlowInstance.defaults.headers.Authorization = `Bearer ${token}`;
};

export const getPromotionDetails: IPromotionDetilsResponse = async (
  promotionId: string
) => {
  return await instance.get(`admin/promotions/find-one/${promotionId}`);
};
