import { FC, useEffect, useMemo, useRef } from "react";
import {
  set_checked_pages,
  set_page,
} from "providers/ClientFilter/clientsActions";
import {
  set_checkedIDOrder,
  set_email,
  set_stats_filter,
  set_status,
} from "providers/OrderFilter/ordersAction";
import {
  useReceiveOrderDispatchContext,
  useReceiveOrderState,
} from "providers/ReceiveOrders";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import {
  useOrderDispatch,
  useOrderFilters,
} from "../../../providers/OrderFilter";
import {
  set_filteringOrder,
  set_orderSearch,
} from "../../../providers/OrderFilter/ordersAction";
import { Icon, PageWrapper, Table } from "components/base";
import sortIcon from "../../../components/base/Icon/icons/sort-icon.svg";
import OrderDetailsModal, {
  OrderDetailsModalRefType,
} from "components/modals/OrderDetailsModal";
import OrdersColumn from "./OrderColumn";
import NotFound from "pages/NotFound";
import useRole from "hooks/useRole";
import Pagination from "../../../components/Pagination";
import TableSkeleton from "components/TableSkeleton";
import Button from "components/Button";
import useQuery from "hooks/useQuery";
import { sellTransactionStatuses } from "utils/constants";
import ReceiveOrderFiltersWrapper from "../ReceiveOrdersFilterWrapper";

let timer: ReturnType<typeof setTimeout>;

const ReceiveOrders: FC = () => {
  // context
  const { getQueryParam } = useQuery();
  const { getOrders, setLoading, getOrderById, downloadReceiveOrders } =
    useReceiveOrderDispatchContext();

  const { isLoading, ordersCount, orders, orderDetails } =
    useReceiveOrderState();

  const role = useRole();

  const modalRef = useRef<OrderDetailsModalRefType>(null);
  // states

  const orderDispatch = useOrderDispatch();

  const {
    page,
    visibleOrdersCount,
    amountFrom,
    amountTo,
    filterButtonVal,
    email,
    checkedPages,
    checkedIDOrder,
    payerEmail,
    receiveStatus,
    exportedOrder,
    orderSearch,
    id,
    receiveId,
  } = useOrderFilters();

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (filterButtonVal === false) return;

      if (filterButtonVal) {
        getOrders({
          id,
          receiveId,
          keyword: orderSearch,
          page,
          fromAmount: amountFrom,
          toAmount: amountTo,
          customerEmail: email,
          payerEmail,
          receiveStatus,
        });

        return;
      }

      getOrders({
        id,
        receiveId,
        keyword: orderSearch,
        page,
        fromAmount: amountFrom,
        toAmount: amountTo,
        customerEmail: email,
        payerEmail,
        receiveStatus,
      });
    }, 450);

    return () => {
      clearTimeout(timer);
    };
  }, [
    orderSearch,
    page,
    amountFrom,
    amountTo,
    email,
    payerEmail,
    receiveStatus,
    filterButtonVal,
    id,
    receiveId,
    getOrders,
  ]);

  const clearSearch = () => orderDispatch(set_orderSearch(""));

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        orderDispatch(set_page(page + 1));
        orderDispatch(set_filteringOrder(true));

        return;
      case "prev":
        orderDispatch(set_page(page - 1));
        orderDispatch(set_filteringOrder(true));

        return;

      default:
        return;
    }
  };

  useEffect(() => {
    if (role === "super_admin") {
      const user_email = getQueryParam("user_email");
      const trx_status = getQueryParam("trx_status");

      if (user_email && trx_status) {
        orderDispatch(set_filteringOrder(false));
        orderDispatch(set_email(user_email as string));
        orderDispatch(set_status(sellTransactionStatuses[trx_status]));
        orderDispatch(set_stats_filter(true));
        orderDispatch(set_filteringOrder(true));

        return;
      }

      if (user_email && !trx_status) {
        orderDispatch(set_email(user_email as string));
        orderDispatch(set_stats_filter(true));
        orderDispatch(set_filteringOrder(true));

        return;
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleDownloadOrders = () => {
    console.log("Receive id from handle download orders", receiveId);

    return downloadReceiveOrders({
      id,
      receiveId,
      keyword: orderSearch,
      fromAmount: amountFrom,
      toAmount: amountTo,
      customerEmail: email,
      payerEmail,
      receiveStatus,
    });
  };

  const handleExportSelected = () => {
    return (
      (exportedOrder?.length !== 0 || checkedIDOrder) &&
      downloadReceiveOrders({
        selectedOrder: exportedOrder,
      })
    );
  };

  const selectUser = useMemo(() => {
    const ordersID = orders.map((item) => item.id);

    const compoundOrders = [...(exportedOrder as string[]), ...ordersID];

    const handleChange = (e) => {
      if (e.target.checked) {
        orderDispatch(
          set_checkedIDOrder({
            checked: e.target.checked,
            orders: compoundOrders as string[],
          })
        );
        orderDispatch(set_checked_pages([...checkedPages, page]));
      } else {
        orderDispatch(
          set_checkedIDOrder({
            checked: true,
            orders: exportedOrder?.filter((item) => !ordersID.includes(item)),
          })
        );
        orderDispatch(
          set_checked_pages(checkedPages.filter((pg) => pg !== page))
        );
      }
    };

    return (
      <input
        onChange={handleChange}
        type="checkbox"
        className="form-check-input user-list-checkbox"
        checked={(checkedPages as number[]).includes(page)}
      />
    );
  }, [orderDispatch, orders, checkedPages, page, exportedOrder]);

  return role ? (
    <PageWrapper title="Payments list">
      <div
        className="content d-flex flex-column flex-column-fluid order-table order__table"
        id="kt_content"
      >
        <div className="container" id="kt_content_container">
          <div className="card user-list-row">
            <div className="user-list-header header-list">
              <div className="button-export__wrapper">
                <h1 className="page-title">Transaction Receive List</h1>
                <Button
                  type="button"
                  className="button--transparent"
                  onClick={() => location.reload()}
                >
                  <Icon name="rotate" />
                </Button>
                {role !== "partner" ? (
                  <RoleProvider roles={[ROLES.SUPER_ADMIN, ROLES.FINANCE]}>
                    <Button
                      type="button"
                      className="button--transparent button--export"
                      onClick={handleExportSelected}
                      disabled={!exportedOrder?.length}
                    >
                      <Icon name="export_button" />
                      Export Selected
                    </Button>
                    <Button
                      type="button"
                      className="button--transparent button--export"
                      onClick={handleDownloadOrders}
                    >
                      <Icon name="export_button" />
                      Export
                    </Button>
                  </RoleProvider>
                ) : null}
              </div>
              <ReceiveOrderFiltersWrapper setLoading={setLoading} />
            </div>
            <div className="table--row">
              <div className="user-list-body">
                {isLoading ? (
                  <>
                    <TableSkeleton />
                    <Pagination
                      page={page}
                      count={ordersCount}
                      visibleCount={+visibleOrdersCount}
                      clearSearch={clearSearch}
                      setLoading={setLoading}
                      isLoading={isLoading}
                      handlePagination={handlePagination}
                    />
                  </>
                ) : (
                  <>
                    {(orders.length === 0 && <NotFound />) || (
                      <>
                        <div>
                          <Table
                            headers={[
                              {
                                empty: true,
                                val: "checkbox",
                                w: 25,
                                content: selectUser,
                              },
                              {
                                title: "Transaction ID",
                                val: "id",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Address",
                                val: "address",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Amount",
                                val: "amount",
                                w: 70,
                                icon: sortIcon,
                              },
                              {
                                title: "Asset",
                                val: "asset",
                                w: 70,
                                icon: sortIcon,
                              },
                              {
                                title: "Client id",
                                val: "client_id",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Comment",
                                val: "comment",
                                w: "fit-content",
                              },
                              {
                                title: "Core",
                                val: "core",
                                w: "fit-content",
                              },
                              {
                                title: "Fiat",
                                val: "fiat",
                                w: "fit-content",
                              },
                              {
                                title: "Fee option",
                                val: "fee_option",
                                w: "fit-content",
                              },
                              {
                                title: "Asset Quantity",
                                val: "asset_quantity",
                                w: "fit-content",
                              },
                              {
                                title: "Cost of service",
                                val: "cost_of_service",
                                w: "fit-content",
                              },
                              {
                                title: "Network fee",
                                val: "network_fee",
                                w: "fit-content",
                              },
                              {
                                title: "Partner comission",
                                val: "partner_commission",
                                w: "fit-content",
                              },
                              {
                                title: "Price",
                                val: "price",
                                w: "fit-content",
                              },
                              {
                                title: "Spot price",
                                val: "spot_price",
                                w: "fit-content",
                              },
                              {
                                title: "Payer status",
                                val: "payer_status",
                                w: "fit-content",
                              },
                              {
                                title: "Swaps Revenue",
                                val: "swaps_profit",
                                w: "fit-content",
                              },
                              {
                                title: "Tr status",
                                val: "tr_status",
                                w: "fit-content",
                              },
                              {
                                title: "Tx id",
                                val: "tx_status",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Order id",
                                val: "order_id",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Partner id",
                                val: "partner_id",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Payer Email",
                                val: "payer",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Receive id",
                                val: "receive_id",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Recipient Email",
                                val: "recipient",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Status",
                                val: "status",
                                w: "fit-content",
                              },
                              {
                                title: "Link expired",
                                val: "link_expired",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              {
                                title: "Date",
                                val: "createdAt",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              { title: "Action", val: "action", w: 100 },
                              {
                                empty: true,
                                w: 50,
                              },
                            ]}
                            pageName={"order"}
                          >
                            <OrdersColumn modalRef={modalRef} />
                          </Table>
                        </div>
                        <Pagination
                          page={page}
                          count={ordersCount}
                          visibleCount={+visibleOrdersCount}
                          clearSearch={clearSearch}
                          setLoading={setLoading}
                          isLoading={isLoading}
                          handlePagination={handlePagination}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderDetailsModal
        order={orderDetails}
        getOrderById={getOrderById}
        ref={modalRef}
      />
    </PageWrapper>
  ) : (
    <></>
  );
};

export default ReceiveOrders;
