import React from "react";
import ReactDOM from "react-dom";
import { CookiesProvider } from "react-cookie";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import "assets/styles/index.scss";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import * as serviceWorker from "./serviceWorker";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_URL}`,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();
