import ContentLoader from "react-content-loader";

const WebhookListSkeleton = (): JSX.Element => {
  return (
    <ContentLoader
      width="100%"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="15" rx="10" ry="10" width="150" height="19" />
      <rect x="720" y="15" rx="10" ry="10" width="60" height="19" />
      <rect x="1080" y="15" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="55" rx="10" ry="10" width="150" height="19" />
      <rect x="720" y="55" rx="10" ry="10" width="60" height="19" />
      <rect x="1080" y="55" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="95" rx="10" ry="10" width="150" height="19" />
      <rect x="720" y="95" rx="10" ry="10" width="60" height="19" />
      <rect x="1080" y="95" rx="10" ry="10" width="60" height="19" />
    </ContentLoader>
  );
};

export default WebhookListSkeleton;
