import { FC } from "react";
import VerifiedIcon from "assets/img/verified-icon.png";
import { Link, useParams } from "react-router-dom";

import TwoFactorAuthentication from ".";

const messages = {
  set: {
    title: "Thank you very much",
    description: "You have verified your phone number",
  },
  reset: {
    title: "Success",
    description: "You have successfully reset your password",
  },
};

const VerifiedPhoneNumber: FC = () => {
  const { type } = useParams<{ type: string }>();

  return (
    <TwoFactorAuthentication>
      <div className="tfa-card">
        <div className="tfa-card-header">
          <div className="verified-icon">
            <img src={VerifiedIcon} alt="image" width="64px" height="64px" />
          </div>
          <h6 className="tfa-card-header-title">{messages[type].title}</h6>
          <p className="tfa-card-header-description">
            {messages[type].description}
          </p>
        </div>
        <div className="tfa-card-footer mt-0">
          <div className="tfa-card-footer-btn">
            <Link
              to="/login"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-primary w-100"
            >
              <span className="indicator-label">Sign In to Swaps</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </Link>
          </div>
        </div>
      </div>
    </TwoFactorAuthentication>
  );
};

export default VerifiedPhoneNumber;
