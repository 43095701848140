import React, { useMemo } from "react";
import {
  setCodeFilter,
  setCoreFilter,
  setNegativeMarkupRate,
  setSellStatus,
  setSymbol,
  setTriggerFilter,
} from "providers/WidgetFilter/widgetFilterAction";
import {
  useWidgetFiltersState,
  useWidgetFiltesDispatch,
} from "providers/WidgetFilter";
import { useSellFlowDispatch } from "providers/SellFlow";

import FilterList from "components/Filter/FilterList";
import { sellCryptoSecondFilterTree } from "utils/constants";

const CryptoFiltersWrapper = (): JSX.Element => {
  const dispatch = useWidgetFiltesDispatch();
  const { core, code, symbol, negative_markup, is_sell_supported } =
    useWidgetFiltersState();

  const { setPage } = useSellFlowDispatch();

  const filterByCore = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      dispatch(setCoreFilter(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterByCode = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      dispatch(setCodeFilter(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterBySymbol = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      dispatch(setSymbol(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterMarkupRate = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      dispatch(setNegativeMarkupRate(parseFloat(e.target.value)));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterSellStatus = (e, setError, setIsValid) => {
    if (typeof e.target.value === "undefined") return setError("Enter a value");

    if (e.target.value.toLowerCase() === "supported") {
      dispatch(setSellStatus(true));
    }

    if (e.target.value.toLowerCase() === "not supported") {
      dispatch(setSellStatus(false));
    }

    setError("");
    setIsValid(true);
  };

  const secondLevelHandlers = [
    filterByCore,
    filterByCode,
    filterBySymbol,
    filterMarkupRate,
    filterSellStatus,
  ];

  const handleApply = () => {
    setPage(1);

    setTimeout(() => {
      dispatch(setTriggerFilter(false));
    }, 100);
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);
  };

  const resetCore = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setCoreFilter(""));
    }

    setPage(1);
    dispatch(setCoreFilter(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetCode = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setCodeFilter(""));
    }

    setPage(1);
    dispatch(setCodeFilter(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetSymbol = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setSymbol(""));
    }

    setPage(1);
    dispatch(setSymbol(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetMarkup = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setNegativeMarkupRate(undefined));
    }

    setPage(1);
    dispatch(setNegativeMarkupRate(undefined));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetSellStatus = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setSellStatus(undefined));
    }

    setPage(1);
    dispatch(setSellStatus(undefined));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetHandlers = [
    resetCore,
    resetCode,
    resetSymbol,
    resetMarkup,
    resetSellStatus,
  ];

  const enableClearFilters = useMemo(() => {
    if (
      core ||
      code ||
      symbol ||
      negative_markup ||
      typeof is_sell_supported !== "undefined"
    ) {
      return true;
    }

    return false;
  }, [core, code, symbol, negative_markup, is_sell_supported]);

  return (
    <>
      <FilterList
        secondLevelHandlers={secondLevelHandlers}
        secondLevelFilter={sellCryptoSecondFilterTree}
        handleApply={handleApply}
        enableClearFilters={enableClearFilters}
        clearSearches={resetHandlers}
        additionalHandlers={[]}
        clearAdditionalSearches={[]}
        clearSelect={() => null}
      />
    </>
  );
};

export default CryptoFiltersWrapper;
