import { FC } from "react";
import { removeEndpoint } from "services/webhooks";
import { useHistory } from "react-router-dom";

import { TableActionButton } from "components/base";
import "./webhooks-list.scss";
import { WebhookListProps } from "./WebhooksPage.props";
import WebhookListSkeleton from "./WebhookListSkeleton";

const WebhooksList: FC<WebhookListProps> = ({
  profile,
  loading,
  getProfileData,
}): JSX.Element => {
  const history = useHistory();

  return (
    <div className="webhooks-list__container">
      {loading ? (
        <WebhookListSkeleton />
      ) : (
        <>
          <div className="webhooks-list__top-block">
            <h1 className="webhooks-list__header">Hosted endpoints</h1>
            <button onClick={() => history.push("/webhooks/update")}>
              Update endpoint
            </button>
          </div>
          <table className="webhooks-list__table">
            <thead>
              <tr>
                <th>URL</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{profile?.callback_success}</td>
                <td>
                  <div className="user-status-td transaction-status-td user-status-active">
                    Active
                  </div>
                </td>
                <td>
                  <TableActionButton
                    isLast={true}
                    buttons={[
                      {
                        title: "Delete ",
                        role: "endpoint",
                        descriptionTitle: "Delete the endpoint",
                        onClick: async () => {
                          try {
                            await removeEndpoint(profile.id as string);
                            await getProfileData();
                          } catch (error) {
                            console.log(error);
                          }
                        },
                      },
                    ]}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </div>
  );
};

export default WebhooksList;
