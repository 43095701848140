const BalancePopoverItem = ({
  item,
  chosenStatus,
  balanceStatus,
  setChosenStatus,
}: {
  item: string;
  chosenStatus: string;
  balanceStatus: string;
  chooseByDefault: boolean;
  setChosenStatus: (chosenStatus: string) => void;
}): JSX.Element => {
  return (
    <div className="radio__wrapper">
      <label
        className={`radio__label ${
          balanceStatus === item ? "radio-disabled" : ""
        }`}
      >
        <input
          type="radio"
          name="radio"
          value={item}
          disabled={item === balanceStatus}
          checked={item === chosenStatus}
          onChange={(e) => setChosenStatus(e.target.value as string)}
        />
        <span className="radio__checkmark"></span>{" "}
        {item.replace(/(^|\s)\S/g, (l) => l.toUpperCase())}
      </label>
    </div>
  );
};

export default BalancePopoverItem;
