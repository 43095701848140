import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment, { Moment } from "moment";
import Switch from "react-switch";

import { ReactComponent as CloseModalLogo } from "../base/Icon/icons/close-modal.svg";
import { Modal } from "components/base";
import "react-datetime/css/react-datetime.css";
import { IList } from "components/Promotions/types";

interface IPromotionsModal {
  isOpen: boolean;
  promotion: IList;

  updatePromotion: ({
    promotionId,
    startDate,
    endDate,
    description,
    ended,
  }: {
    promotionId: string;
    startDate: string;
    endDate: string;
    description: string;
    ended: boolean;
  }) => void;
  onClose: () => void;
}

function PromotionsModal({
  isOpen,
  onClose,
  updatePromotion,
  promotion,
}: IPromotionsModal): JSX.Element {
  const [description, setDescription] = useState("");
  const [ended, setEnded] = useState(false);

  const [startDate, setStartDate] = useState(new Date());

  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24)
  );

  const handleStartDate = (d: string | Moment) => {
    if (typeof d !== "string") {
      setStartDate(new Date(d.format()));
    }
  };

  const handleEndDate = (d: string | Moment) => {
    if (typeof d !== "string") {
      setEndDate(new Date(d.format()));
    }
  };

  const handleSubmit = () => {
    updatePromotion({
      promotionId: promotion.id,
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
      description: description,
      ended,
    });
    onClose();
  };

  useEffect(() => {
    setDescription(promotion?.desc);
    setStartDate(new Date(promotion?.start_date));
    setEndDate(new Date(promotion?.end_date));
    setEnded(promotion?.ended);
  }, [
    promotion?.desc,
    promotion?.start_date,
    promotion?.end_date,
    promotion?.ended,
  ]);

  const handleVaildEndDate = (date: Moment) => {
    const isAfter =
      moment(date).isAfter(startDate.toISOString()) ||
      moment(date).isAfter(moment(startDate).add(-1, "day").format());

    return isAfter;
  };

  const handleVaildStartDate = (date: Moment) => {
    const isBefore = moment(date).isBefore(endDate.toISOString());

    return isBefore;
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="modal-content-container">
        <div className="modal-header" id="kt_modal_add_customer_header">
          <div>
            <h2 className="fw-bolder modal-header-title-m">Edit Promotion</h2>
          </div>
          <div className="color">
            <span className="svg-icon svg-icon-1" onClick={onClose}>
              <CloseModalLogo />
            </span>
          </div>
        </div>
        <div className="modal-body py-10 px-lg-17">
          <div className="modal-body-content">
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Start Date</label>
                <Datetime
                  value={new Date(startDate)}
                  onChange={handleStartDate}
                  dateFormat="DD MMM YYYY"
                  timeFormat="HH:mm"
                  isValidDate={handleVaildStartDate}
                />
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>End Date</label>
                <Datetime
                  value={endDate}
                  onChange={handleEndDate}
                  timeFormat="HH:mm"
                  dateFormat="DD MMM YYYY"
                  isValidDate={handleVaildEndDate}
                />
              </div>
            </div>

            <div className="modal-content-row">
              <div className="modal-content-col flows-switcher">
                <label>Status</label>
                <Switch
                  onChange={(checked) => setEnded(!checked)}
                  checked={!ended}
                  height={18}
                  width={36}
                  onColor="#2075f5"
                />
              </div>
            </div>

            <div className="modal-content-row">
              <div className="modal-content-col"></div>
            </div>
          </div>
        </div>
        <div className="modal-footer flex-center">
          <button
            type="button"
            className="button button-secondary button-cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button button-primary button-add"
            onClick={handleSubmit}
          >
            <span className="indicator-label">Edit Promotion</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PromotionsModal;
