import { usePartnerStateContext } from "providers/Partners";
import { useMemo } from "react";

import PartnerWebhookList from "./PartnerWebhookList";
import PartnerWebhooksEmpty from "./PartnerWebhookEmpty";

const PartnerWebhooksPage = (): JSX.Element => {
  const { partnerDetails } = usePartnerStateContext();

  const renderContent = useMemo(() => {
    if (partnerDetails?.callback_success) {
      return <PartnerWebhookList />;
    }

    return <PartnerWebhooksEmpty />;
  }, [partnerDetails]);

  return renderContent;
};

export default PartnerWebhooksPage;
