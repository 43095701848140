import { FC, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

import withRoleHoc from "../../../hocs/withRoleHoc";
import { instance } from "utils/api";

const ActiveUsers: FC = () => {
  const [selectedDate, setDate] = useState<Date>(new Date());
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!selectedDate) return;
    instance
      .get("/admin/summary/active-users", {
        params: {
          toDate: selectedDate.toISOString().split("T")[0],
        },
      })
      .then((res) => setCount(res?.data?.data || 0));
  }, [selectedDate]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className="box-header">
        <div className="box-header-summary">{count}</div>
        <div className="box-header-name">Active users</div>
      </div>
      <div className="box-content">
        <div className="box-cols-header-filter">
          <DatePicker
            selected={selectedDate}
            placeholderText="Select date range"
            dateFormat="dd MMM yyyy"
            onChange={(date: Date) => setDate(date)}
            maxDate={moment().toDate()}
            customInput={
              <input
                className="form-control form-control-solid"
                style={{ width: 200, marginRight: 16 }}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

export default withRoleHoc(ActiveUsers);
