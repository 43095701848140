import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";
import {
  set_arrayCheckingOrder,
  set_exportedOrder,
  set_filteringOrder,
  set_selectedOrder,
} from "providers/OrderFilter/ordersAction";
import { useOrderDispatchContext, useOrderState } from "providers/Orders";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import { TableActionButton } from "components/base";
import { ORDER_TABLE_ACCESS } from "../../services/roleService/order/order";
import { Icon } from "../../components/base";
import { copyToClipboard } from "../../utils/helpers";
import Button from "../../components/Button";
import Tooltip from "components/Tooltip";
import useRole from "hooks/useRole";
import Popover from "../../components/Popover";
import OrderStatusPopover from "./OrderStatusPopover";
import { paymentMethodsObj, txBuyStatusInfo } from "utils/constants";

const OrdersColumnFields: React.FC<{
  order;
  modalRef;
  transactionStatuses;
  isLast;
}> = ({ order, modalRef, transactionStatuses, isLast }) => {
  const [trStatus, setTrStatus] = useState(order.tr_status || "");

  const [chosenStatus, setChosenStatus] = useState(order.tr_status);
  const [comments, setComments] = useState("");

  const orderDispatch = useOrderDispatch();
  const { deleteOrder, updateStatus, setUpdatedStatus } =
    useOrderDispatchContext();

  const { updatedStatus } = useOrderState();

  const { exportedOrder } = useOrderFilters();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const role = useRole();

  const deletePressed = (id: string) => {
    const isConfirmed = window.confirm("Are you sure you want to delete order");

    if (isConfirmed) {
      deleteOrder(id);
    }

    modalRef.current?.open("edit");
  };

  const handleCheckClient = (id) => {
    orderDispatch(set_exportedOrder(id));
    orderDispatch(set_selectedOrder(id));

    orderDispatch(set_arrayCheckingOrder(id));
  };

  const updateSts = async (tr_status: string, comments: string) => {
    orderDispatch(set_filteringOrder(false));
    await updateStatus({ id: order.id, tr_status, comments });
    await setUpdatedStatus({ tr_status, order_id: order.id });
    orderDispatch(set_filteringOrder(true));
  };

  useEffect(() => {
    if (!updatedStatus?.tr_status || !updatedStatus?.order_id) return;

    if (updatedStatus.order_id === order.id) {
      setTrStatus(updatedStatus.tr_status);
    }
  }, [updatedStatus, order.id]);
  const orderID = `${order.id.substr(0, 4)}...${order.id.substr(
    order.id.length - 4,
    order.id.length
  )}`;

  return role ? (
    <>
      <td>
        <input
          onClick={() => handleCheckClient(order.id)}
          className={`form-check-input user-list-checkbox`}
          type="checkbox"
          checked={exportedOrder?.includes(order.id)}
          value={order.id}
        />
      </td>
      <td className="order-id">
        <RoleProvider
          roles={[ROLES.SUPER_ADMIN, ROLES.MARKETING, ROLES.AML, ROLES.FINANCE]}
          fallback={<p>{orderID}</p>}
        >
          <Link to={`/orders/buy-flow/details/${order.id}`}>
            {/* {order.id} */}
            {orderID}
          </Link>
        </RoleProvider>
      </td>
      {ORDER_TABLE_ACCESS.id_clipboard[role] && (
        <td className="td-clipboard">
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </td>
      )}
      {ORDER_TABLE_ACCESS.amount[role] && (
        <>
          <td className="td-color-gray">{order.amount}</td>
          <td className="td-color-gray">{order.currency}</td>
        </>
      )}
      {ORDER_TABLE_ACCESS.asset[role] && <td>{order.asset}</td>}
      {ORDER_TABLE_ACCESS.asset[role] && (
        <td>
          <div className="payment-method-provider">
            <img
              src={paymentMethodsObj[order.payment_method]?.icon}
              alt={paymentMethodsObj[order.payment_method]?.name}
              width={20}
              height={20}
            />
            <span>{paymentMethodsObj[order.payment_method]?.name}</span>
          </div>
        </td>
      )}
      {ORDER_TABLE_ACCESS.status[role] && (
        <td className="td-transaction-status">
          <div
            className={`tr-status user-status-td ${
              txBuyStatusInfo[trStatus.toLowerCase().replaceAll(" ", "_")]
                ?.className
            }`}
          >
            {trStatus?.replace(/(^|\s)\S/g, (l) => l.toUpperCase())}
            <Icon
              name={
                txBuyStatusInfo[trStatus.toLowerCase().replaceAll(" ", "_")]
                  ?.icon
              }
            />
          </div>
        </td>
      )}
      <RoleProvider roles={[ROLES.SUPER_ADMIN, ROLES.AML]}>
        {ORDER_TABLE_ACCESS.status_edit[role] && (
          <td className="td-edit-status">
            <Popover
              isPopoverOpen={isPopoverOpen}
              setIsPopoverOpen={setIsPopoverOpen}
              position={["bottom"]}
              className="filter__popover"
              arrowStyle={{ left: "60%" }}
              contentLocation={(popoverState) => {
                return {
                  top: popoverState.nudgedTop,
                  left: popoverState.targetRect.left - 150,
                };
              }}
              component={
                <Button
                  type="button"
                  className="button--transparent button-tr-status"
                  onClick={() => setIsPopoverOpen(true)}
                >
                  <Icon name="edit_pencil" />
                </Button>
              }
            >
              {transactionStatuses && (
                <OrderStatusPopover
                  setIsPopoverOpen={setIsPopoverOpen}
                  setComments={setComments}
                  updateStatus={updateSts}
                  comments={comments}
                  chosenItem={order.tr_status}
                  items={transactionStatuses}
                  id={order.id}
                  updatedStatus={updatedStatus}
                  chosenStatus={chosenStatus}
                  setChosenStatus={setChosenStatus}
                />
              )}
            </Popover>
          </td>
        )}
      </RoleProvider>
      {ORDER_TABLE_ACCESS.bank_status[role] && (
        <td>
          {
            {
              paid: (
                <div className="user-status-td transaction-status-td user-status-active">
                  Successful
                  <Icon name="success_check" />
                </div>
              ),
              failed: (
                <div className="user-status-td transaction-status-td user-status-blocked">
                  Failed
                  <Icon name="order_reject" />
                </div>
              ),
              pending: (
                <div className="user-status-td transaction-status-td user-status-panding">
                  Pending
                  <Icon name="warning_check" />
                </div>
              ),
            }[order.status || "success"]
          }
        </td>
      )}
      {ORDER_TABLE_ACCESS.withdrawal[role] && (
        <td>
          {
            {
              sending: (
                <div className="user-status-td transaction-status-td user-status-process">
                  Sending
                  <Icon name="clock" />
                </div>
              ),
              successful: (
                <div className="user-status-td transaction-status-td user-status-active">
                  Successful
                  <Icon name="success_check" />
                </div>
              ),
              not_sent: (
                <>
                  <div className="user-status-td transaction-status-td user-status-blocked">
                    Not Sent
                    <Icon name="order_reject" />
                  </div>
                </>
              ),
              pending: (
                <div className="user-status-td transaction-status-td user-status-pending">
                  Pending
                  <Icon name="warning_check" />
                </div>
              ),
            }[order.withdrawal || "success"]
          }
        </td>
      )}
      {ORDER_TABLE_ACCESS.risk_level[role] && (
        <td className="td-color-gray">{order.risk_level || "-"}</td>
      )}
      {ORDER_TABLE_ACCESS.spot_price[role] && (
        <td className="td-color-gray">{order.spot_price || "-"}</td>
      )}
      {ORDER_TABLE_ACCESS.price[role] && (
        <td className="td-color-gray partner-status">{order.price || "-"}</td>
      )}
      {ORDER_TABLE_ACCESS.email[role] && (
        <td className="td-color-gray order-email">
          <div className="order-email__inner-container">
            <a href={`mailto:${order.client.email}`}>{order.client.email}</a>
          </div>
        </td>
      )}
      {ORDER_TABLE_ACCESS.email_clipboard[role] && (
        <td className="td-color-gray order-clipboard">
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.client.email);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </td>
      )}
      {ORDER_TABLE_ACCESS.api[role] && (
        <td className="td-color-gray">{order.payment_exchange || "-"}</td>
      )}
      {ORDER_TABLE_ACCESS.commission[role] && (
        <td className="td-color-gray">
          {order.partner_commission || order.commission}
        </td>
      )}
      {ORDER_TABLE_ACCESS.partner_markup[role] && (
        <td>{role === "super_admin" ? order.markup : order.commission}</td>
      )}
      {ORDER_TABLE_ACCESS.network_fee[role] && (
        <td className="td-color-gray">{order.network_fee || "-"}</td>
      )}
      {ORDER_TABLE_ACCESS.cost_of_service[role] && (
        <td className="td-color-gray">{order.cost_of_service || "-"}</td>
      )}
      {ORDER_TABLE_ACCESS.swaps_profit[role] && (
        <td className="td-color-gray">{order.swaps_profit || "-"}</td>
      )}
      {ORDER_TABLE_ACCESS.asset_quantity[role] && (
        <td>{order.asset_quantity}</td>
      )}
      {/* {ORDER_TABLE_ACCESS.asset[role] && <td>{order.asset}</td>} */}
      {ORDER_TABLE_ACCESS.billing_country[role] && (
        <td className="td-color-gray">{order?.client_country}</td>
      )}
      {ORDER_TABLE_ACCESS.partner_name[role] && (
        <td className="td-color-gray">
          {/* <Link to={`/partners/details/${order.partner_id}`}>
            {order?.partner_name ?? "-"}
          </Link> */}
          <Link to={`/partners/details/${order?.partner?.id}`}>
            {order.partner?.name}
          </Link>
        </td>
      )}
      {ORDER_TABLE_ACCESS.createdAt[role] && (
        <td>{moment(order.createdAt).format("DD MMM yyyy, hh:mm")}</td>
      )}
      {ORDER_TABLE_ACCESS.action[role] && (
        <td>
          <TableActionButton
            isLast={isLast}
            viewLink={`/orders/buy-flow/details/${order.id}`}
            ordersId={order.id}
            buttons={[
              {
                fromPage: "orderView",
                onClick: () => deletePressed(order.id),
                role: "order",
              },
            ]}
          />
        </td>
      )}
    </>
  ) : (
    <></>
  );
};

export default OrdersColumnFields;
