import { FC } from "react";

import Icon from "../components/base/Icon";

const NotFound: FC = () => {
  return (
    <div className="not-found__wrapper">
      <div className="not-found-section">
        <div className="not-found__search-container">
          <Icon name="search_icon" />
        </div>

        <div className="not-found__content-container">
          <h2 className="not-found__title">No results found</h2>
          <p className="not-found__text">
            There aren&apos;t any results for that query.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
