import { Partner } from "providers/Partners/types";

export const ActionTypes = {
  SET_ORDERS: "SET_ORDERS",
  SET_ORDERS_STATISTICS: "SET_ORDERS_STATISTICS",
  SET_ORDER_DETAILS: "SET_ORDER_DETAILS",
  DELETE_ORDER: "DELETE_ORDER",
  SET_LOADING: "SET_LOADING",
  SET_TR_STATUSES: "SET_TR_STATUSES",
  SET_UPDATES_STATUS: "SET_UPDATED_STATUS",
  RESET_ORDERS_COUNT: "RESET_ORDERS_COUNT",
};
export interface ILogs {
  admin: {
    name: string;
  };
  id: string;
  createdAt: string;
  event: {
    status: string;
    comment: string;
  };
}
export type Order = {
  id: string;
  address: string;
  amount: number;
  asset: string;
  asset_quantity: number;
  bank: string;
  cl_promotion?: {
    id: string;
  };
  pr_promotion?: {
    id: string;
  };
  client: {
    id: string;
    email: string;
    name?: string | null;
    surname?: string | null;
    billing_address?: {
      countries?: {
        country?: string;
      };
    };
  };
  client_id: string;
  commission: number | null;
  createdAt: string;
  currency: string;
  exchange: string;
  failed_reason: string;
  merchant_id: string;
  network_fee: number | null;
  network?: string;
  partner?: Partner | null;
  partner_commission: number | null;
  partner_id: string;
  partner_markup: number | null;
  risk: number | null;
  spot_price: number | null;
  status?: string | { name: string };
  transaction_id: string;
  tx_id: string | null;
  updatedAt: string;
  withdraw_id: string | null;
  wallet_exchange: null | string;
  elliptic_view?: string;
  wallet_risk_score: null | string;
  payment_method: string;
  client_cards?: {
    id: string;
    schema?: string;
    type: string;
  };
  markup: number | null;
  payment_exchange: string | null;
  withdrawal: string;
  cost_of_service: number;
  swaps_profit: number;
  price: number;
  risk_level: string;
  tr_status: string;
  tr_error_code: string;
  logs: ILogs[];
};

export type Filters = {
  asset?: string;
  status?: string;
  to?: Date | null | string | number;
  from?: Date | null | string | number;
};

interface SetOrders {
  type: typeof ActionTypes.SET_ORDERS;
  data: unknown;
}

interface SetOrderDetails {
  type: typeof ActionTypes.SET_ORDER_DETAILS;
  data: unknown;
}

interface SetOrderStatistics {
  type: typeof ActionTypes.SET_ORDERS_STATISTICS;
  data: unknown;
}

interface DeleteOrder {
  type: typeof ActionTypes.DELETE_ORDER;
  data: unknown;
}

interface SetLoading {
  type: typeof ActionTypes.SET_LOADING;
  data: boolean;
}

export type Action =
  | SetOrders
  | SetOrderDetails
  | SetOrderStatistics
  | DeleteOrder
  | SetLoading;

export type State = {
  isLoading: boolean;
  orders: Order[];
  ordersCount: number;
  orderDetails: Order | null;
  totalAmount: { currency: string; total: number }[];
  totalCommission: { asset: string; total: number }[];
  totalOrders: { status: string; count: string }[];
  transactionStatuses: string[] | [];
  updatedStatus: { tr_status: string; order_id: string };
};

export type DispatchContext = {
  getOrders: (
    page: number,
    orderSearch?: string,
    filters?: string[],
    dateFrom?: Date | null | string,
    dateTo?: Date | null | string,
    amountFrom?: string,
    amountTo?: string,
    rateFrom?: string,
    rateTo?: string,
    quantityFrom?: string,
    quantityTo?: string,
    visibleOrdersCount?: string,
    assetOrder?: string[],
    exchangeOrder?: string[],
    withdrawalOrder?: string[],
    currencyOrder?: string[],
    sorting?: string | undefined,
    ordering2?: string | undefined | ((prev: string) => string),
    partnerOrder?: string[] | string,
    paymentMethods?: undefined | string[],
    trStatus?: string,
    checkedPartner?: boolean,
    billingCountry?: string
  ) => Promise<void>;
  getClients: (
    page: number,
    search?: string,
    filters?: Filters
  ) => Promise<void>;
  downloadOrders: (
    visibleOrdersCount: string,
    sorting2?: string,
    ordering2?: string,
    selectedOrder?: string[] | string,
    checkedIDOrder?: { checked: boolean; orders?: string[] },
    orders?: Order[],
    arrayCheckingOrder?: string[] | [],
    orderSearch?: string,
    filters?: string[],
    dateFrom?: Date | null | string,
    dateTo?: Date | null | string,
    amountFrom?: string,
    amountTo?: string,
    rateFrom?: string,
    rateTo?: string,
    quantityFrom?: string,
    quantityTo?: string,
    assetOrder?: string[],
    exchangeOrder?: string[],
    currencyOrder?: string[],
    partnerOrder?: string[] | string,
    withdrawal?: string[],
    trStatus?: string,
    checkedPartner?: boolean,
    country?: string
  ) => Promise<void>;
  getOrderById: (id: string) => Promise<void>;
  getOrdersStatistics: () => Promise<void>;
  deleteOrder: (id: string) => Promise<void>;
  setLoading: (isLoading: boolean) => void;
  getTrStatuses: () => void;
  updateStatus: ({
    id,
    tr_status,
    comments,
  }: {
    id: string;
    tr_status: string;
    comments: string;
  }) => void;
  setUpdatedStatus: ({
    tr_status,
    order_id,
  }: {
    tr_status: string;
    order_id: string;
  }) => void;
  getFiatList: () => void;
  getAssetList: () => void;
  resetOrdersCount: () => void;
};

export type Dispatch = (action: Action) => void;
