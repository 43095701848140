import { Route } from "react-router-dom";

import { PageWrapper } from "components/base";
import ActiveUsers from "pages/Dashboard/ActiveUsers";
import { FinancialReports } from "./FinancialReports";

const Home = () => (
  <>
    <div className="reports__inner-block">
      <ActiveUsers />
    </div>
  </>
);

const Reports = (): JSX.Element => {
  return (
    <PageWrapper title="Reports" className="dashboard__content">
      <div className="reports-container d-flex flex-row gap-20 ">
        <Route path="/reports" exact component={Home} />
        <Route path="/reports/financial" component={FinancialReports} />
      </div>
    </PageWrapper>
  );
};

export default Reports;
