import ContentLoader from "react-content-loader";

const FilterItemSkeleton = (): JSX.Element => {
  return (
    <ContentLoader width={84} height={26}>
      <rect x="0" y="0" rx="15" ry="15" width="84" height="26" />
    </ContentLoader>
  );
};

export default FilterItemSkeleton;
