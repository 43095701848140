import { ILogs } from "providers/Orders/types";
import { Partner } from "providers/Partners/types";

export const ActionTypes = {
  SET_ORDERS: "SET_ORDERS",
  SET_ORDERS_STATISTICS: "SET_ORDERS_STATISTICS",
  SET_ORDER_DETAILS: "SET_ORDER_DETAILS",
  DELETE_ORDER: "DELETE_ORDER",
  SET_LOADING: "SET_LOADING",
  SET_TR_STATUSES: "SET_TR_STATUSES",
  SET_UPDATES_STATUS: "SET_UPDATED_STATUS",
  RESET_ORDERS_COUNT: "RESET_ORDERS_COUNT",
};

export type Order = {
  id: string;
  address: string;
  amount: number;
  asset: string;
  asset_name?: string;
  asset_quantity: number;
  bank: string;
  comment?: string;
  core?: string;
  client: {
    id: string;
    email: string;
    name?: string | null;
    surname?: string | null;
  };
  client_id: string;
  commission: number | null;
  createdAt: string;
  currency: string;
  exchange: string;
  failed_reason: string;
  merchant_id: string;
  network_fee: number | null;
  partner?: Partner | null;
  partner_commission: number | null;
  partner_id: string;
  partner_markup: number | null;
  partners_rate?: number;
  risk: number | null;
  spot_price: number | null;
  status?: string | { name: string };
  transaction_id: string;
  tx_id: string | null;
  updatedAt: string;
  withdraw_id: string | null;
  wallet_exchange: null | string;
  elliptic_view?: string;
  wallet_risk_score: null | string;
  payment_method: string;
  client_cards?: {
    id: string;
    schema?: string;
    type: string;
  };
  markup: number | null;
  payment_exchange: string | null;
  withdrawal: string;
  cost_of_service: number;
  swaps_profit: number;
  price: number;
  risk_level: string;
  tr_status: string;
  tr_error_code: string;
  account_name?: string;
  failure_reason?: string | null;
  email?: string;
  network?: string;
  profit?: number;
  final_asset_qty?: number;
  account_iban?: string;
  bank_type?: string;
  country?: string;
  receiver_client: {
    email?: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
  };
  payer_client?: {
    email?: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
  };
  order?: {
    asset_quantity?: number;
    cost_of_service?: number;
    network_fee?: number;
    partner_commission?: number;
    payer_client?: {
      email?: string;
      first_name?: string;
      last_name?: string;
      middle_name?: string;
    };
    price?: number;
    spot_price?: number;
    status?: string;
    swaps_profit?: number;
    tr_status?: string;
    tx_id?: string;
  };
  order_id?: string;
  logs: ILogs[];
};

export type Filters = {
  asset?: string;
  status?: string;
  to?: Date | null | string | number;
  from?: Date | null | string | number;
};

interface SetOrders {
  type: typeof ActionTypes.SET_ORDERS;
  data: unknown;
}

interface SetOrderDetails {
  type: typeof ActionTypes.SET_ORDER_DETAILS;
  data: unknown;
}

interface SetOrderStatistics {
  type: typeof ActionTypes.SET_ORDERS_STATISTICS;
  data: unknown;
}

interface DeleteOrder {
  type: typeof ActionTypes.DELETE_ORDER;
  data: unknown;
}

interface SetLoading {
  type: typeof ActionTypes.SET_LOADING;
  data: boolean;
}

export type Action =
  | SetOrders
  | SetOrderDetails
  | SetOrderStatistics
  | DeleteOrder
  | SetLoading;

export type State = {
  isLoading: boolean;
  orders: Order[];
  ordersCount: number;
  orderDetails: Order | null;
  totalAmount: { currency: string; total: number }[];
  totalCommission: { asset: string; total: number }[];
  totalOrders: { status: string; count: string }[];
  transactionStatuses: string[] | [];
  updatedStatus: { tr_status: string; order_id: string };
};

interface OrderArgs {
  id?: string;
  receiveId?: string;
  keyword?: string;
  page: number;
  fromAmount?: string;
  toAmount?: string;
  customerEmail?: string;
  payerEmail?: string;
  receiveStatus?: string;
}

interface ReceieveArgs {
  id?: string;
  receiveId?: string;
  keyword?: string;
  fromAmount?: string;
  toAmount?: string;
  customerEmail?: string;
  payerEmail?: string;
  receiveStatus?: string;
  selectedOrder?: string[] | string;
}

export type DispatchContext = {
  getOrders: (args: OrderArgs) => Promise<void>;
  getClients: (
    page: number,
    search?: string,
    filters?: Filters
  ) => Promise<void>;
  downloadReceiveOrders: (args: ReceieveArgs) => Promise<void>;
  getOrderById: (id: string) => Promise<void>;
  getOrdersStatistics: () => Promise<void>;
  deleteOrder: (id: string) => Promise<void>;
  setLoading: (isLoading: boolean) => void;
  getTrStatuses: () => void;
  updateStatus: ({ id, tr_status }: { id: string; tr_status: string }) => void;
  setUpdatedStatus: ({
    tr_status,
    order_id,
  }: {
    tr_status: string;
    order_id: string;
  }) => void;
  getFiatList: () => void;
  getAssetList: () => void;
  resetOrdersCount: () => void;
};

export type Dispatch = (action: Action) => void;
