import { FC } from "react";

import FiatColumnField from "./FiatColumnField";
import { FiatColumnProps } from "./WidgetConsole.props";

const FiatColumn: FC<FiatColumnProps> = ({
  flow,
  updateFiatStatus,
  getFiatList,
  setChosenFiat,
  page,
  fiatList,
  role,
  setIsOpen,
}): JSX.Element => {
  return (
    <>
      {fiatList.map((fiat) => (
        <tr key={fiat.id} className="user-list-tr">
          <FiatColumnField
            flow={flow}
            updateFiatStatus={updateFiatStatus}
            getFiatList={getFiatList}
            setChosenFiat={setChosenFiat}
            page={page}
            setIsOpen={setIsOpen}
            fiat={fiat}
            role={role}
            isLast={fiatList.length >= 0 && fiatList.length < 3}
          />
        </tr>
      ))}
    </>
  );
};

export default FiatColumn;
