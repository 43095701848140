import { FC, useEffect, useMemo, useRef } from "react";
import { filterHeadersByRole } from "services/roleService/order/order";
import {
  set_checked_pages,
  set_page,
} from "providers/ClientFilter/clientsActions";
import {
  useSellOrderDispatchContext,
  useSellOrderState,
} from "providers/SellOrders";
import {
  set_checkedIDOrder,
  set_email,
  set_stats_filter,
  set_status,
} from "providers/OrderFilter/ordersAction";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import {
  useOrderDispatch,
  useOrderFilters,
} from "../../../providers/OrderFilter";
import {
  set_filteringOrder,
  set_orderSearch,
} from "../../../providers/OrderFilter/ordersAction";
import { Icon, PageWrapper, Table } from "components/base";
import sortIcon from "../../../components/base/Icon/icons/sort-icon.svg";
import OrderDetailsModal, {
  OrderDetailsModalRefType,
} from "components/modals/OrderDetailsModal";
import OrdersColumn from "./OrderColumn";
import NotFound from "pages/NotFound";
import useRole from "hooks/useRole";
import Pagination from "../../../components/Pagination";
import TableSkeleton from "components/TableSkeleton";
import SellOrderFiltersWrapper from "../SellOrdersFilterWrapper";
import Button from "components/Button";
import useQuery from "hooks/useQuery";
import { sellTransactionStatuses } from "utils/constants";

let timer: ReturnType<typeof setTimeout>;

const SellOrders: FC = () => {
  // context
  const { getQueryParam } = useQuery();
  const { getOrders, setLoading, getFiatList, getAssetList, getOrderById } =
    useSellOrderDispatchContext();

  const { isLoading, ordersCount, orders, orderDetails, transactionStatuses } =
    useSellOrderState();

  const role = useRole();

  const modalRef = useRef<OrderDetailsModalRefType>(null);
  // states

  const orderDispatch = useOrderDispatch();
  const { downloadSellOrders } = useSellOrderDispatchContext();
  const {
    page,
    orderSearch,
    visibleOrdersCount,
    ordering2,
    sorting2,
    dateToOrder,
    amountFrom,
    amountTo,
    rateFrom,
    rateTo,
    quantityFrom,
    quantityTo,
    dateFromOrder,
    filters,
    assetOrder,
    exchangeOrder,
    currencyOrder,
    partnerOrder,
    filterButtonVal,
    paymentMethods,
    withdrawalOrder,
    statsFilter,
    trStatus,
    customerId,
    address,
    statusId,
    email,
    orderID,
    billing_country,
    exportedOrder,
    checkedPages,
    checkedIDOrder,
    arrayCheckingOrder,
  } = useOrderFilters();

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (filterButtonVal === false) return;

      if (filterButtonVal) {
        getOrders(
          page,
          orderSearch,
          filters,
          dateFromOrder,
          dateToOrder,
          amountFrom,
          amountTo,
          rateFrom,
          rateTo,
          quantityFrom,
          quantityTo,
          visibleOrdersCount,
          assetOrder,
          exchangeOrder,
          withdrawalOrder,
          currencyOrder,
          ordering2,
          sorting2,
          partnerOrder,
          paymentMethods,
          trStatus,
          undefined,
          customerId as string,
          address as string,
          statusId as string,
          email as string,
          orderID as string,
          billing_country as string
        );

        return;
      }

      getOrders(
        page,
        orderSearch,
        undefined,
        null,
        null,
        "",
        "",
        "",
        "",
        "",
        "",
        visibleOrdersCount,
        undefined,
        undefined,
        undefined,
        undefined,
        ordering2,
        sorting2,
        undefined,
        paymentMethods,
        trStatus,
        undefined,
        customerId as string,
        address as string,
        statusId as string,
        email as string,
        orderID as string,
        billing_country as string
      );
    }, 450);

    return () => {
      clearTimeout(timer);
    };
  }, [
    amountFrom,
    amountTo,
    assetOrder,
    currencyOrder,
    dateFromOrder,
    dateToOrder,
    exchangeOrder,
    filterButtonVal,
    filters,
    getOrders,
    orderSearch,
    ordering2,
    page,
    partnerOrder,
    paymentMethods,
    quantityFrom,
    quantityTo,
    rateFrom,
    rateTo,
    sorting2,
    visibleOrdersCount,
    withdrawalOrder,
    statsFilter,
    trStatus,
    customerId,
    address,
    statusId,
    email,
    orderID,
    billing_country,
  ]);

  const clearSearch = () => orderDispatch(set_orderSearch(""));

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        orderDispatch(set_page(page + 1));
        orderDispatch(set_filteringOrder(true));

        return;
      case "prev":
        orderDispatch(set_page(page - 1));
        orderDispatch(set_filteringOrder(true));

        return;

      default:
        return;
    }
  };

  useEffect(() => {
    if (role === "super_admin") {
      const user_email = getQueryParam("user_email");
      const trx_status = getQueryParam("trx_status");

      if (user_email && trx_status) {
        orderDispatch(set_filteringOrder(false));
        orderDispatch(set_email(user_email as string));
        orderDispatch(set_status(sellTransactionStatuses[trx_status]));
        orderDispatch(set_stats_filter(true));
        orderDispatch(set_filteringOrder(true));

        return;
      }

      if (user_email && !trx_status) {
        orderDispatch(set_email(user_email as string));
        orderDispatch(set_stats_filter(true));
        orderDispatch(set_filteringOrder(true));

        return;
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleDownloadOrders = () =>
    downloadSellOrders(
      visibleOrdersCount,
      sorting2,
      ordering2,
      undefined,
      undefined,
      undefined,
      undefined,
      orderSearch,
      filters,
      dateFromOrder,
      dateToOrder,
      amountFrom,
      amountTo,
      rateFrom,
      rateTo,
      quantityFrom,
      quantityTo,
      assetOrder,
      exchangeOrder,
      currencyOrder,
      partnerOrder,
      withdrawalOrder,
      trStatus,
      undefined,
      10000,
      email as string,
      billing_country
    );

  const handleExportSelected = () => {
    return (
      (exportedOrder?.length !== 0 || checkedIDOrder) &&
      downloadSellOrders(
        "",
        undefined,
        undefined,
        exportedOrder,
        checkedIDOrder,
        orders,
        arrayCheckingOrder,
        orderSearch,
        filters,
        null,
        null,
        "",
        "",
        "",
        "",
        "",
        "",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "",
        false,
        10000
      )
    );
  };

  const selectUser = useMemo(() => {
    const ordersID = orders.map((item) => item.id);

    const compoundOrders = [...(exportedOrder as string[]), ...ordersID];

    const handleChange = (e) => {
      if (e.target.checked) {
        orderDispatch(
          set_checkedIDOrder({
            checked: e.target.checked,
            orders: compoundOrders as string[],
          })
        );
        orderDispatch(set_checked_pages([...checkedPages, page]));
      } else {
        orderDispatch(
          set_checkedIDOrder({
            checked: true,
            orders: exportedOrder?.filter((item) => !ordersID.includes(item)),
          })
        );
        orderDispatch(
          set_checked_pages(checkedPages.filter((pg) => pg !== page))
        );
      }
    };

    return (
      <input
        onChange={handleChange}
        type="checkbox"
        className="form-check-input user-list-checkbox"
        checked={(checkedPages as number[]).includes(page)}
      />
    );
  }, [orderDispatch, orders, checkedPages, page, exportedOrder]);

  return role ? (
    <PageWrapper title="Payments list">
      <div
        className="content d-flex flex-column flex-column-fluid order-table order__table"
        id="kt_content"
      >
        <div className="container" id="kt_content_container">
          <div className="card user-list-row">
            <div className="user-list-header header-list">
              <div className="button-export__wrapper">
                <h1 className="page-title">Transaction Sell List</h1>
                <Button
                  type="button"
                  className="button--transparent"
                  onClick={() => location.reload()}
                >
                  <Icon name="rotate" />
                </Button>
                {role !== "partner" ? (
                  <RoleProvider roles={[ROLES.SUPER_ADMIN, ROLES.FINANCE]}>
                    <Button
                      type="button"
                      className="button--transparent button--export"
                      onClick={handleExportSelected}
                      disabled={!exportedOrder?.length}
                    >
                      <Icon name="export_button" />
                      Export Selected
                    </Button>
                    <Button
                      type="button"
                      className="button--transparent button--export"
                      onClick={handleDownloadOrders}
                    >
                      <Icon name="export_button" />
                      Export
                    </Button>
                  </RoleProvider>
                ) : null}
              </div>
              {/* <OrderFiltersWrapper
                setLoading={setLoading}
                getFiatList={getFiatList}
                getAssetList={getAssetList}
                transactionStatuses={transactionStatuses}
              /> */}
              <SellOrderFiltersWrapper
                transactionStatuses={transactionStatuses}
                setLoading={setLoading}
                getFiatList={getFiatList}
                getAssetList={getAssetList}
              />
            </div>
            <div className="table--row">
              <div className="user-list-body">
                {isLoading ? (
                  <>
                    <TableSkeleton />
                    <Pagination
                      page={page}
                      count={ordersCount}
                      visibleCount={+visibleOrdersCount}
                      clearSearch={clearSearch}
                      setLoading={setLoading}
                      isLoading={isLoading}
                      handlePagination={handlePagination}
                    />
                  </>
                ) : (
                  <>
                    {(orders.length === 0 && <NotFound />) || (
                      <>
                        <div>
                          <Table
                            headers={[
                              {
                                empty: true,
                                val: "checkbox",
                                w: 25,
                                content: selectUser,
                              },
                              {
                                title: "Transaction ID",
                                val: "id",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Amount",
                                val: "amount",
                                w: 70,
                                icon: sortIcon,
                              },
                              {
                                title: "Fiat",
                                val: "fiat",
                                w: 55,
                                icon: sortIcon,
                              },
                              {
                                title: "Asset",
                                val: "asset",
                                w: 70,
                                icon: sortIcon,
                              },
                              {
                                title: "Bank Type",
                                val: "bank_type",
                                w: "fit-content",
                              },
                              {
                                title: "Status",
                                val: "transaction_status",
                                w: "fit-content",
                              },
                              {
                                title: "Withdrawal",
                                val: "withdrawal",
                                w: "fit-content",
                              },
                              {
                                title: "Partner Processing Fee",
                                val: "partner_markup",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Price",
                                val: "price",
                                w: "fit-content",
                              },
                              {
                                title: "Tracking ID",
                                val: "tracking_id",
                                w: "fit-content",
                              },
                              {
                                title: "Customer Email",
                                val: "client_email",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Network",
                                val: "network",
                                w: "fit-content",
                              },
                              {
                                title: "Network Fee",
                                val: "network_fee",
                                w: "fit-content",
                              },
                              {
                                title: "Profit",
                                val: "profit",
                                w: "fit-content",
                              },
                              {
                                title: "Spot Price",
                                val: "spot_price",
                                w: "fit-content",
                              },
                              {
                                title: "Swaps Revenue",
                                val: "swaps_profit",
                                w: "fit-content",
                              },
                              {
                                title: "Partner commission",
                                val: "commission",
                                w: "fit-content",
                              },
                              {
                                title: "Cost of Service",
                                val: "cost_of_service",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              {
                                title: "Quantity",
                                val: "asset_quantity",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Final Asset Quantity",
                                val: "final_asset_qty",
                                w: "fit-content",
                              },
                              {
                                title: "Account IBAN",
                                val: "account_iban",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Failure Reason",
                                val: "failure_reason",
                                w: "fit-content",
                              },
                              {
                                title: "Wallet",
                                val: "address",
                                w: "fit-content",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Merchant Id",
                                val: "merchant_id",
                                w: "fit-content",
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Tx Id",
                                val: "tx_id",
                                w: "fit-content",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Billing Country",
                                val: "billing_country",
                                w: "fit-content",
                              },
                              {
                                title: "PARTNER NAME",
                                val: "partner_name",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Date",
                                val: "createdAt",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              { title: "Action", val: "action", w: 100 },
                              {
                                empty: true,
                                w: 50,
                              },
                            ].filter(
                              (item) => role && filterHeadersByRole(item, role)
                            )}
                            pageName={"order"}
                          >
                            <OrdersColumn modalRef={modalRef} />
                          </Table>
                        </div>
                        <Pagination
                          page={page}
                          count={ordersCount}
                          visibleCount={+visibleOrdersCount}
                          clearSearch={clearSearch}
                          setLoading={setLoading}
                          isLoading={isLoading}
                          handlePagination={handlePagination}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderDetailsModal
        order={orderDetails}
        getOrderById={getOrderById}
        ref={modalRef}
      />
    </PageWrapper>
  ) : (
    <></>
  );
};

export default SellOrders;
