import { useState, FC } from "react";
import { Link } from "react-router-dom";
import { usePartnerDispatch, usePartnerFilters } from "providers/PartnerFilter";
import {
  set_arrayCheckingPartner,
  set_exportedPartner,
  set_selectedPartner,
} from "providers/PartnerFilter/partnerAction";
import moment from "moment";
import { usePartnerDispatchContext } from "providers/Partners";
import { CreatePartner } from "providers/Partners/types";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import Tooltip from "components/Tooltip";
import { PartnerColumnFieldProps } from "./PartnerColumnField.props";
import Button from "components/Button";
import Popover from "components/Popover";
import { Icon, TableActionButton } from "components/base";
import { copyToClipboard } from "utils/helpers";
import { instance } from "utils/api";

const arr: string[] | undefined = [];

const PartnersColumnField: FC<PartnerColumnFieldProps> = ({
  partner,
  editPressed,
  isLast,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tierZero, setTierZero] = useState(partner.tier_zero);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [sellPopoverOpen, setSellPopoverOpen] = useState(false);
  const [receivePopoverOpen, setReceivePopoverOpen] = useState(false);
  const [sepaPopoverOpen, setSepaPopoverOpen] = useState(false);
  const [sellStatus, setSellStatus] = useState(partner.is_sell_supported);
  const [receiveStatus, setReceiveStatus] = useState(partner.is_rec_supported);
  const [sepaStatus, setSepaStatus] = useState(partner.is_sepa_supported);

  const { checkedIDPartner, exportedPartner, selectedPartner } =
    usePartnerFilters();

  const partnerDispatch = usePartnerDispatch();
  const { editPartner } = usePartnerDispatchContext();
  const { pagePartner } = usePartnerFilters();

  const handleCheckPartnerId = (partner) => {
    partnerDispatch(set_exportedPartner(partner.id));
    partnerDispatch(set_selectedPartner(partner.id));

    if (
      checkedIDPartner &&
      exportedPartner?.find((elem) => elem === partner.id) === undefined
    ) {
      const x = arr?.findIndex((elem) => elem === selectedPartner);
      arr?.splice(x as number, 1);
    }

    if (exportedPartner?.find((elem) => elem === partner.id) !== undefined) {
      arr?.push(selectedPartner as string);
    }

    partnerDispatch(set_arrayCheckingPartner(arr));
  };

  const updateStatus = async () => {
    try {
      const data = {
        tier_zero: tierZero,
        name: partner.name,
        contactName: partner.contactName,
        phone: partner.phone,
        email: partner.email,
        partnerCommission: partner.commission,
        partnerMarkup: partner.markup,
        callback_error: partner.callback_error,
        comment: partner.comment,
        urls: partner.urls?.map((item) => item.url),
        is_sell_supported: sellStatus,
        is_sepa_supported: sepaStatus,
        is_rec_supported: receiveStatus,
      };

      await editPartner(
        pagePartner,
        partner?.id,
        data as unknown as CreatePartner
      );

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleResendClick = async () => {
    await instance.post("/partners/resend", {
      id: partner.id,
    });
  };

  return (
    <tr className="user-list-tr">
      <td>
        <input
          onClick={() => handleCheckPartnerId(partner)}
          className={`form-check-input user-list-checkbox`}
          checked={exportedPartner?.includes(partner.id)}
          type="checkbox"
          value={partner.id}
        />
      </td>
      <td className="table-id-link td-partner-id">
        <div>
          <Link to={`/partners/details/${partner.id}`}>{`${partner.id.substr(
            0,
            4
          )}...${partner.id.substr(
            partner.id.length - 4,
            partner.id.length
          )}`}</Link>
        </div>
      </td>
      <td className="partner-clipboard">
        <Tooltip content="Copied">
          {(setShowTooltip) => (
            <Button
              type="button"
              className="clipboard-button"
              onClick={() => {
                copyToClipboard(partner.id);
                setShowTooltip(true);
              }}
            >
              <Icon name="clipboard" />
            </Button>
          )}
        </Tooltip>
      </td>
      <td className="td-color-gray">{partner.name}</td>
      <td className="td-color-gray">{partner.contactName}</td>
      <td className="td-color-black partner-email">
        <a href={`mailto:${partner.email}`}>{partner.email}</a>
      </td>
      <td>
        <Tooltip content="Copied">
          {(setShowTooltip) => (
            <Button
              type="button"
              className="clipboard-button"
              onClick={() => {
                copyToClipboard(partner.email);
                setShowTooltip(true);
              }}
            >
              <Icon name="clipboard" />
            </Button>
          )}
        </Tooltip>
      </td>
      <td className="td-color-black partner-email">
        {!partner?.support_email ? (
          "-"
        ) : (
          <a href={`mailto:${partner.support_email}`}>
            {partner.support_email}
          </a>
        )}
      </td>
      <td>
        {partner?.support_email ? (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(partner.support_email);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        ) : null}
      </td>
      <td>
        {partner.markup}
        {"  %"}
      </td>
      <td className="td-color-gray">
        {partner.commission}
        {"  %"}
      </td>
      <td className="td-color-gray">
        {partner.sellCommission !== null ? `${partner.sellCommission} %` : "-"}
      </td>
      <td className="td-color-gray">
        {partner.sellMarkup !== null ? `${partner.sellMarkup} %` : "-"}
      </td>
      <td className="td-color-gray">
        {partner.sepaCommission !== null ? `${partner.sepaCommission} %` : "-"}
      </td>
      <td className="user-list-td-grey suspend-status">
        <div>
          {partner?.is_sell_supported ? (
            <div className="user-status-td transaction-status-td user-status-active">
              Supported
              <Icon name="success_check" />
            </div>
          ) : (
            <div className="user-status-td transaction-status-td user-status-blocked">
              Not Supported
              <Icon name="order_reject" />
            </div>
          )}
        </div>
      </td>
      <td className="td-edit-status">
        <Popover
          isPopoverOpen={sellPopoverOpen}
          setIsPopoverOpen={setSellPopoverOpen}
          position={["bottom"]}
          className="filter__popover"
          arrowStyle={{ left: "60%" }}
          contentLocation={(popoverState) => {
            return {
              top: popoverState.nudgedTop,
              left: popoverState.targetRect.left - 150,
            };
          }}
          component={
            <Button
              type="button"
              className="button--transparent button-tr-status"
              onClick={() => setSellPopoverOpen(true)}
            >
              <Icon name="edit_pencil" />
            </Button>
          }
        >
          <div className="popover-stats__container">
            <h1 className="popover-stats__title">Status</h1>
            <div className="popover-stats__container--radio">
              <div className="radio__wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setSellStatus(true)}
                    checked={sellStatus}
                  />
                  <span className="radio__checkmark"></span> Supported
                </label>
              </div>
              <div className="radio__wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setSellStatus(false)}
                    checked={!sellStatus}
                  />
                  <span className="radio__checkmark"></span> Not Supported
                </label>
              </div>
            </div>
            <div className="radio-buttons__wrapper">
              <Button
                type="button"
                className="button-cancel"
                onClick={() => setSellPopoverOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="button-submit"
                onClick={updateStatus}
                disabled={partner.is_sell_supported === sellStatus || isLoading}
              >
                Change
              </Button>
            </div>
          </div>
        </Popover>
      </td>
      <td className="user-list-td-grey suspend-status">
        <div>
          {partner?.is_rec_supported ? (
            <div className="user-status-td transaction-status-td user-status-active">
              Supported
              <Icon name="success_check" />
            </div>
          ) : (
            <div className="user-status-td transaction-status-td user-status-blocked">
              Not Supported
              <Icon name="order_reject" />
            </div>
          )}
        </div>
      </td>
      <td className="td-edit-status">
        <Popover
          isPopoverOpen={receivePopoverOpen}
          setIsPopoverOpen={setReceivePopoverOpen}
          position={["bottom"]}
          className="filter__popover"
          arrowStyle={{ left: "60%" }}
          contentLocation={(popoverState) => {
            return {
              top: popoverState.nudgedTop,
              left: popoverState.targetRect.left - 150,
            };
          }}
          component={
            <Button
              type="button"
              className="button--transparent button-tr-status"
              onClick={() => setReceivePopoverOpen(true)}
            >
              <Icon name="edit_pencil" />
            </Button>
          }
        >
          <div className="popover-stats__container">
            <h1 className="popover-stats__title">Status</h1>
            <div className="popover-stats__container--radio">
              <div className="radio__wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setReceiveStatus(true)}
                    checked={receiveStatus}
                  />
                  <span className="radio__checkmark"></span> Supported
                </label>
              </div>
              <div className="radio__wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setReceiveStatus(false)}
                    checked={!receiveStatus}
                  />
                  <span className="radio__checkmark"></span> Not Supported
                </label>
              </div>
            </div>
            <div className="radio-buttons__wrapper">
              <Button
                type="button"
                className="button-cancel"
                onClick={() => setReceivePopoverOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="button-submit"
                onClick={updateStatus}
                disabled={
                  partner.is_rec_supported === receiveStatus || isLoading
                }
              >
                Change
              </Button>
            </div>
          </div>
        </Popover>
      </td>
      <td className="user-list-td-grey suspend-status">
        <div>
          {partner?.is_sepa_supported ? (
            <div className="user-status-td transaction-status-td user-status-active">
              Supported
              <Icon name="success_check" />
            </div>
          ) : (
            <div className="user-status-td transaction-status-td user-status-blocked">
              Not Supported
              <Icon name="order_reject" />
            </div>
          )}
        </div>
      </td>
      <td className="td-edit-status">
        <Popover
          isPopoverOpen={sepaPopoverOpen}
          setIsPopoverOpen={setSepaPopoverOpen}
          position={["bottom"]}
          className="filter__popover"
          arrowStyle={{ left: "60%" }}
          contentLocation={(popoverState) => {
            return {
              top: popoverState.nudgedTop,
              left: popoverState.targetRect.left - 150,
            };
          }}
          component={
            <Button
              type="button"
              className="button--transparent button-tr-status"
              onClick={() => setSepaPopoverOpen(true)}
            >
              <Icon name="edit_pencil" />
            </Button>
          }
        >
          <div className="popover-stats__container">
            <h1 className="popover-stats__title">Status</h1>
            <div className="popover-stats__container--radio">
              <div className="radio__wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setSepaStatus(true)}
                    checked={sepaStatus}
                  />
                  <span className="radio__checkmark"></span> Supported
                </label>
              </div>
              <div className="radio__wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setSepaStatus(false)}
                    checked={!sepaStatus}
                  />
                  <span className="radio__checkmark"></span> Not Supported
                </label>
              </div>
            </div>
            <div className="radio-buttons__wrapper">
              <Button
                type="button"
                className="button-cancel"
                onClick={() => setSepaPopoverOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="button-submit"
                onClick={updateStatus}
                disabled={partner.is_sepa_supported === sepaStatus || isLoading}
              >
                Change
              </Button>
            </div>
          </div>
        </Popover>
      </td>
      {/* )} */}
      <td className="partner-status">
        <div
          className={`${
            partner.status === "Integrated"
              ? "integrated user-status-td user-status-active"
              : "not_integrated user-status-td user-status-blocked"
          }`}
        >
          {partner.status === "Integrated" ? "Active" : "Blocked"}
          <Icon
            name={
              partner.status === "Integrated"
                ? "success_check"
                : "reject_status"
            }
          />
        </div>
      </td>
      <td className="partner-email">{partner.tier_zero ? "ON" : "OFF"}</td>
      <td className="td-edit-status td-clipboard">
        <Popover
          isPopoverOpen={isPopoverOpen}
          setIsPopoverOpen={setIsPopoverOpen}
          position={["bottom"]}
          className="filter__popover"
          arrowStyle={{ left: "60%" }}
          contentLocation={(popoverState) => {
            return {
              top: popoverState.nudgedTop,
              left: popoverState.targetRect.left - 150,
            };
          }}
          component={
            <Button
              type="button"
              className="button--transparent button-tr-status"
              onClick={() => setIsPopoverOpen(true)}
            >
              <Icon name="edit_pencil" />
            </Button>
          }
        >
          <div className="popover-stats__container">
            <h1 className="popover-stats__title">Status</h1>
            <div className="popover-stats__container--radio">
              <div className="radio__wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setTierZero(true)}
                    checked={tierZero}
                  />
                  <span className="radio__checkmark"></span> ON
                </label>
              </div>
              <div className="radio__wrapper">
                <label className="radio__label">
                  <input
                    type="radio"
                    name="radio"
                    onChange={() => setTierZero(false)}
                    checked={!tierZero}
                  />
                  <span className="radio__checkmark"></span> OFF
                </label>
              </div>
            </div>
            <div className="radio-buttons__wrapper">
              <Button
                type="button"
                className="button-cancel"
                onClick={() => setIsPopoverOpen(false)}
              >
                Cancel
              </Button>
              <Button
                type="button"
                className="button-submit"
                onClick={updateStatus}
                disabled={partner.tier_zero === tierZero || isLoading}
              >
                Change
              </Button>
            </div>
          </div>
        </Popover>
      </td>
      <td>
        {moment(partner.integration_request_date).format("DD MMM yyyy, hh:mm")}
      </td>
      <td>
        <RoleProvider roles={[ROLES.SUPER_ADMIN, ROLES.MARKETING]}>
          <TableActionButton
            viewLink={`/partners/details/${partner.id}`}
            isLast={isLast}
            buttons={[
              {
                id: partner.id,
                userName: partner.name ? `${partner.name}` : "-",
                title: "Delete",
                title2: "Edit",
                title3: "Resend",
                onTitle3Click: handleResendClick,
                role: "partner",
                onClick: () => editPressed(partner.id),
                partnerName: partner,
              },
            ]}
          />
        </RoleProvider>
      </td>
    </tr>
  );
};

export default PartnersColumnField;
