import { FC, useEffect, useState } from "react";

import Icon from "components/base/Icon";
import { TooltipProps } from "./Tooltip.props";

const Tooltip: FC<TooltipProps> = ({ children, content, constant = false }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (constant) return;

    if (showTooltip) {
      setTimeout(() => setShowTooltip(false), 700);
    }
  }, [showTooltip, constant]);

  return (
    <div className="tooltip__outer-wrapper">
      {children(setShowTooltip)}
      {showTooltip && (
        <div className="tooltip__inner-container">
          <Icon name="caret" />
          <div className="tooltip__container">
            <p className="tooltip__content">{content}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
