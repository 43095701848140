import * as React from "react";

import { ChildrenForSelector } from "../pages/Settings/settingsTypes";

export const TabSelector: React.FC<ChildrenForSelector> = ({
  isActive,
  children,
  onClick,
}: {
  isActive: boolean;
  children: React.ReactNode;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => (
  <button
    className={`tabs-button ${
      isActive ? "tabs-button-active" : "tabs-button-disabled"
    }`}
    onClick={onClick}
  >
    {children}
  </button>
);
