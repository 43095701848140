import React, { ReactElement, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import TableSkeleton from "components/TableSkeleton";
import { getPromotionDetails } from "utils/api";
import { PageWrapper } from "components/base";
import "./styles.scss";

function PromotionDetails(): ReactElement {
  const { promotionId } = useParams() as { promotionId: string };
  const [promotion, setPromotion] = useState<null | IPromotionDetilsData>(null);
  useEffect(() => {
    (async function () {
      try {
        const { data } = await getPromotionDetails(promotionId);
        setPromotion(data.data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [promotionId]);

  return (
    <PageWrapper title="Promotion Details">
      <div className="container">
        {promotion ? (
          <div className="promotion-wrapper">
            <table>
              <thead>
                <tr>
                  <th>PROMOTION DETAILS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="table-id-link td-partner-id">Customer ID</td>
                  <td className="table-id-link td-partner-id">
                    <div>
                      <Link to={`/users/details/${promotion.client_id}`}>
                        {promotion.client_id}
                      </Link>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Description</td>
                  <td>{promotion.desc}</td>
                </tr>
                <tr>
                  <td>Fee Reduction for bank transfer</td>
                  <td>{promotion.fee_reduction_bank_tr}</td>
                </tr>
                <tr>
                  <td>Fee Reduction for instant banking</td>
                  <td>{promotion.fee_reduction_instant_bank}</td>
                </tr>

                <tr>
                  <td>Start Date</td>
                  <td>{promotion.start_date}</td>
                </tr>

                <tr>
                  <td>End Date</td>
                  <td>{promotion.end_date}</td>
                </tr>
                <tr>
                  <td>Ended</td>
                  <td>{promotion.ended.toString()}</td>
                </tr>
                <tr>
                  <td>Buy Flosw</td>
                  <td>{promotion.buy_flow.toString()}</td>
                </tr>
                <tr>
                  <td>Sell Flow</td>
                  <td>{promotion.sell_flow.toString()}</td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : (
          <TableSkeleton />
        )}
      </div>
    </PageWrapper>
  );
}

export default PromotionDetails;
