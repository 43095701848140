import React, { useCallback, useState } from "react";
import moment from "moment";
import { set_exportedClient } from "providers/ClientFilter/clientsActions";
import { useClientDispatch, useClientFilters } from "providers/ClientFilter";

import { Icon, TableActionButton } from "components/base";
import UserLogsColumnFields from "./UserLogsColumnFields";
import { OrderDetailsModalRefType } from "components/modals/OrderDetailsModal";
import Tooltip from "components/Tooltip";
import { copyToClipboard } from "utils/helpers";
import Button from "components/Button";

export interface UserColumnProps {
  log: {
    comment: string;
    id: string;
    log: string | { action: string };
    ip: string;
    country: string;
    createdAt: string;
    action: { transaction: string };
    orderId: string;
  };
  modalRef: React.RefObject<OrderDetailsModalRefType>;
}

const LogCard: React.FC<UserColumnProps> = ({ log, modalRef }) => {
  const [showLog, setShowLog] = useState(false);
  const dispatch = useClientDispatch();
  const { settingsToggle, exportedClient } = useClientFilters();

  const handleCheckClientId = useCallback(
    (id) => {
      dispatch(set_exportedClient(id));
    },
    [dispatch]
  );

  const IDColumn = () => {
    return (
      <>
        <td>
          <input
            onClick={() => handleCheckClientId(log.id)}
            className="form-check-input user-list-checkbox"
            checked={exportedClient?.includes(log.id)}
            type="checkbox"
            value={log.id}
          />
        </td>
        <td className="text-grey">
          <div>
            {`${log.id.substr(0, 4)}...${log.id.substr(
              log.id.length - 4,
              log.id.length
            )}`}
            <Tooltip content="Copied">
              {(setShowTooltip) => (
                <Button
                  type="button"
                  className="clipboard-button"
                  onClick={() => {
                    copyToClipboard(log.id);
                    setShowTooltip(true);
                  }}
                >
                  <Icon name="clipboard" />
                </Button>
              )}
            </Tooltip>
          </div>
        </td>
      </>
    );
  };

  const LogColumn = () => {
    return (
      <td className="user-list-td-log">
        <span
          onClick={() => {
            setShowLog(!showLog);
          }}
        >
          {typeof log?.log === "string" ? log?.log : log?.log?.action}
        </span>
        {showLog && (
          <div className="log-description">
            Comment: The user is blocked for a week for creating more than 3
            accounts per week from one IP. The user is blocked for a week for
            creating more than 3 accounts per week from one IP. The user is
            blocked for a week for creating more than 3 accounts per week from
            one IP. The user is blocked for a week for creating more than 3
            accounts per week from one IP.
          </div>
        )}
      </td>
    );
  };

  const IPColumn = () => {
    return <td>{log?.ip === "" ? "-" : log?.ip}</td>;
  };

  const CountryColumn = () => {
    return <td>{log?.country === "" ? "-" : log?.country}</td>;
  };

  const ChengedByColumn = () => {
    return <td>Theresa Webb</td>;
  };

  const DateColumn = () => {
    return (
      <>
        <td>{moment(log?.createdAt).format("DD MMM yyyy, hh:mm")}</td>
      </>
    );
  };

  const ActionColumn = () => {
    return (
      <>
        <td className="log-action-btn">
          {log.log === "order_create" && (
            <TableActionButton
              viewLink={`/orders/details/${log?.action?.transaction}`}
            />
          )}
        </td>
      </>
    );
  };

  return (
    <tr key={log.id} className="user-list-tr">
      {(settingsToggle?.length !== 0 &&
        settingsToggle?.map((item) => (
          <>
            {(() => {
              switch (item) {
                case "ID":
                  IDColumn();
                  break;
                case "Log":
                  LogColumn();
                  break;
                case "IP":
                  IPColumn();
                  break;
                case "COUNTRY":
                  CountryColumn();
                  break;
                case "CHANGED BY":
                  ChengedByColumn();
                  break;
                case "Date":
                  DateColumn();
                  break;
                case "Action":
                  ActionColumn();
                  break;
                default:
                  break;
              }
            })()}
          </>
        ))) || <UserLogsColumnFields log={log} modalRef={modalRef} />}
    </tr>
  );
};

export default LogCard;
