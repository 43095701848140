import React, { useEffect, useState } from "react";
import classNames from "classnames";
import DatePicker from "react-datepicker";
import moment from "moment";

import PopoverComponent from "components/Popover";
import { Icon } from "components/base";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.scss";
import Input from "components/Input";
import Button from "components/Button";
// moment(value.date.startDate).format("YYYY-MM-DD")
// handleDateChange
const CustomDatePickerInput: React.ForwardRefExoticComponent<
  {
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: () => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  } & React.RefAttributes<HTMLInputElement>
> = React.forwardRef((props, ref) => {
  return (
    <Input
      type="text"
      value={props?.value}
      onChange={props?.onChange}
      onClick={props?.onClick}
      onBlur={props?.onBlur}
      ref={ref}
      icon={<Icon name="filter_datepicker" />}
    />
  );
});

export default function PresetsDatePicker({
  onDateChange,
  intervalPicker,
  children,
}: {
  onDateChange;
  intervalPicker: boolean;
  children?: React.ReactNode;
}): JSX.Element {
  const [interval, setInterval] = useState("day");

  const allowedTimePeriod = [
    {
      key: "Today",
      secondaryKey: "day",
      date: {
        startDate: moment().utc().startOf("day").toISOString(),
        endDate: moment().toISOString(),
      },
    },
    {
      key: "Last 7 days",
      secondaryKey: "day",
      date: {
        startDate: moment()
          .startOf("day")
          .subtract(7, "days")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
    },
    {
      key: "Last 4 weeks",
      secondaryKey: "day",
      date: {
        startDate: moment()
          .startOf("day")
          .subtract(1, "months")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
    },
    {
      key: "Last 3 month",
      secondaryKey: "day",
      date: {
        startDate: moment()
          .startOf("day")
          .subtract(3, "months")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
    },
    {
      key: "Last 12 month",
      secondaryKey: "day",
      date: {
        startDate: moment()
          .startOf("day")
          .subtract(12, "months")
          .add(1, "day")
          .format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
    },
  ];

  const [isPopOverPresetOpen, setIsPopOverPresetOpen] = useState(false);
  const [isPopOverDatePickerOpen, setIsPopOverDatePickerOpen] = useState(false);

  const [selectedTimePeriod, setSelectedTimePeriod] = useState(
    allowedTimePeriod[1]
  );

  const [date, setDate] = useState<{
    startDate?: Date | null | undefined | string;
    endDate?: Date | null | undefined | string;
  }>(selectedTimePeriod.date);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => onDateChange({ date, interval }), [interval]);

  const handleSubmit = () => {
    if (date?.startDate && date?.endDate) {
      if (
        new Date(date?.endDate).getTime() < new Date(date?.startDate).getTime()
      )
        return setError('The "To" field should not be less the "From" field');

      setError("");
      setIsPopOverDatePickerOpen(!isPopOverDatePickerOpen);
      onDateChange({
        date,
        interval,
      });

      if (
        new Date(date?.endDate).toLocaleDateString() ===
        new Date().toLocaleDateString()
      ) {
        const startDate = new Date(date?.startDate).toLocaleDateString();

        allowedTimePeriod.some((el) => {
          if (new Date(el.date.startDate).toLocaleDateString() === startDate) {
            setSelectedTimePeriod(el);
            onDateChange({ date, interval });

            return true;
          } else {
            setSelectedTimePeriod({
              ...selectedTimePeriod,
              key: "Custom",
            });
          }
        });
      }
    } else {
      setError("Enter a value");
    }

    return;
  };

  const [error, setError] = useState("");

  return (
    <>
      <div className="d-flex">
        <PopoverComponent
          isPopoverOpen={isPopOverPresetOpen}
          setIsPopoverOpen={setIsPopOverPresetOpen}
          position={["bottom"]}
          className="profile__popover"
          component={
            <div className="d-flex max-width">
              <li
                className={classNames("navbar__item flex date-filter-left", {
                  clicked: isPopOverPresetOpen,
                })}
              >
                {selectedTimePeriod.key} <Icon name="chevron_down" />
              </li>
            </div>
          }
        >
          <>
            <div className="nav-dropdown__container">
              <ul className="nav-dropdown__list">
                {allowedTimePeriod.map((time) => (
                  <li className={`nav-dropdown__list-item`} key={time.key}>
                    <button
                      onClick={() => {
                        setIsPopOverPresetOpen(!isPopOverPresetOpen);
                        setSelectedTimePeriod(time);
                        setDate(time.date);
                        onDateChange({
                          date: time.date,
                          interval,
                        });
                      }}
                    >
                      {time.key}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </>
        </PopoverComponent>
        <PopoverComponent
          isPopoverOpen={isPopOverDatePickerOpen}
          setIsPopoverOpen={setIsPopOverDatePickerOpen}
          position={["bottom"]}
          className="profile__popover overflow-visible"
          component={
            <li
              className={classNames(
                "navbar__item flex date-filter-right max-width",
                {
                  clicked: isPopOverDatePickerOpen,
                }
              )}
            >
              {moment(date.startDate).format("ll")}-
              {moment(date.endDate).format("ll")}
            </li>
          }
        >
          <>
            <div className="nav-dropdown__container">
              <div className="filter-dropdown__container">
                <div className="filter-dropdown__headerline">
                  <h1 className="filter-dropdown__header">Filter by Date</h1>
                </div>
                <div className="filter-dropdown__input-wrapper">
                  {date?.startDate && date?.endDate && (
                    <div className="datepicker__wrapper">
                      <div className="datepicker__inner-block">
                        <h1 className="datepicker__header">From</h1>
                        <DatePicker
                          selected={new Date(date?.startDate)}
                          onSelect={(_, e) => {
                            e?.stopPropagation();
                          }}
                          onChange={(dateValue, e) => {
                            e?.stopPropagation();
                            setDate({
                              ...date,
                              startDate: moment(dateValue as Date).format(
                                "YYYY-MM-DD"
                              ),
                            });
                          }}
                          customInput={<CustomDatePickerInput />}
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="datepicker__inner-block">
                        <h1 className="datepicker__header">to</h1>
                        <DatePicker
                          autoFocus
                          selected={new Date(date?.endDate)}
                          onSelect={(_, e) => {
                            e?.stopPropagation();
                          }}
                          onChange={(dateValue, e) => {
                            e?.stopPropagation();
                            setDate({
                              ...date,
                              endDate: moment(dateValue as Date).format(
                                "YYYY-MM-DD"
                              ),
                            });

                            if (
                              date?.startDate &&
                              dateValue &&
                              new Date(dateValue as Date).getTime() <
                                new Date(date?.startDate)?.getTime()
                            ) {
                              return setError(
                                "The 'To' field should not be less than or equal the 'From' field"
                              );
                            }

                            if (dateValue) {
                              setError("");
                            }
                          }}
                          customInput={<CustomDatePickerInput />}
                          maxDate={new Date()}
                          popperPlacement="bottom"
                        />
                      </div>
                    </div>
                  )}
                  {error && (
                    <div className="filter-dropdown__filter-error">
                      <Icon name="filter_error_arrow" />
                      {error}
                    </div>
                  )}
                </div>
                <Button
                  type="button"
                  className="filter--apply"
                  onClick={handleSubmit}
                >
                  Apply
                </Button>
              </div>
            </div>
          </>
        </PopoverComponent>
      </div>
      {children}
      {intervalPicker ? (
        <div className="filter-option">
          <span
            className={classNames({ active: interval === "day" })}
            onClick={() => setInterval("day")}
          >
            Daily
          </span>
          <span
            className={classNames({ active: interval === "hour" })}
            onClick={() => setInterval("hour")}
          >
            Hourly
          </span>
        </div>
      ) : null}
    </>
  );
}
