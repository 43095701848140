import { useState } from "react";

import PayoutOTPHeader from "./Header";
import PayoutOTPForm from "./PayoutOTPForm";
import SuccessMessage from "./SuccessMessage";

const PayoutOTP = (): JSX.Element => {
  const [orderCreated, setOrderCreated] = useState(false);

  return (
    <div className="payout-otp__container">
      <PayoutOTPHeader />
      {orderCreated ? (
        <SuccessMessage />
      ) : (
        <PayoutOTPForm setOrderCreated={setOrderCreated} />
      )}
    </div>
  );
};

export default PayoutOTP;
