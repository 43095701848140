import { AxiosResponse } from "axios";

export const ActionTypes = {
  SET_BALANCE_LOADING: "SET_BALANCE_LOADING",
  SET_AMOUNT: "SET_AMOUNT",
  SET_WALLET_ADDRESS: "SET_WALLET_ADDRESS",
  SET_ONE_TIME_PASSWORD: "SET_ONE_TIME_PASSWORD",
  SET_OTP_ATTEMPTS: "SET_OTP_ATTEMPTS",
  SET_PAYOUT: "SET_PAYOUT",
  SET_PAYIN: "SET_PAYIN",
  SET_PAYOUT_STATUSES: "SET_PAYOUT_STATUSES",
  SET_PAYOUT_EXPORT: "SET_PAYOUT_EXPORT",
  SET_PAYIN_EXPORT: "SET_PAYIN_EXPORT",
  SET_ALL_EXPORT: "SET_ALL_EXPORT",
  SET_PAGINATION_POINTERS: "SET_PAGINATION_POINTERS",
  SET_PAGE: "SET_PAGE",
  SET_LOADING: "SET_LOADING",
  SET_EXPORT_PAGE: "SET_EXPORT_PAGE",
  SET_CLEAR_EXPORT: "SET_CLEAR_EXPORT",
  SET_ACTIVE_BALANCE: "SET_ACTIVE_BALANCE",
  SET_AVAILABLE_PAYOUT_PAGES: "SET_AVAILABLE_PAGES",
  SET_AVAILABLE_BALANCE: "SET_AVAILABLE_BALANCE",
  SET_PAYOUT_WALLET: "SET_PAYOUT_WALLET",
  SET_INVALID_OTP_MESSAGE: "SET_INVALID_OTP_MESSAGE",
  RESET_EXPORT: "RESET_EXPORT",
  UPDATE_PAYOUT: "UPDATE_PAYOUT",
};

export interface InitialPayIn {
  id: string;
  destinationAddress: string;
  amount: number;
  feeCurrency: string;
  createdAt: string;
  subStatus: string;
  txHash: string;
}

export interface PayoutItem {
  address: string;
  admin_desc: string | null;
  admin_id: string | null;
  amount: number | null;
  asset: string | null;
  createdAt: string | null;
  desc: string | null;
  id: string | null;
  network: string | null;
  partner_id: string | null;
  status: string | null;
  type: string | null;
  updatedAt?: string | null;
  tx_id: string | null;
}

export interface State {
  amount: number | null;
  walletAddress: string | undefined;
  payoutWallet: string | undefined;
  oneTimePassword: number | null;
  otpAttempts: number | null;
  payoutList: PayoutItem[] | null;
  payInList: PayoutItem[] | null;
  payoutStatuses: string[] | null;
  payoutToExport: string[] | null;
  payInToExport: string[] | null;
  exportAll: boolean;
  page: number;
  nextPage: string | undefined;
  prevPage: string | undefined;
  loading: boolean;
  exportPages: number[] | [];
  activeBalance?: string;
  availablePayoutPage?: number;
  availableBalance?: number | null;
  balanceLoading: boolean;
  invalidOtpMessage: string | null;
}

export type Action = {
  type: string;
  payload?:
    | boolean
    | string
    | number
    | PayoutItem[]
    | InitialPayIn[]
    | string[]
    | PayoutItem
    | { nextPage: string; prevPage: string }
    | null;
};

export type Dispatch = (action: Action) => void;

export interface DispatchContext {
  setAmount: (amount: number | null) => void;
  setWalletAddress: (walletAddress: string | null) => void;
  setOneTimePassword: (oneTimePassword: number) => void;
  setOtpAttempts: (otpAttempts: number) => void;
  getPartnerBalance: ({
    partnerId,
    partnerName,
    asset,
  }: {
    partnerId: string;
    partnerName: string;
    asset: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }) => Promise<AxiosResponse<any> | undefined>;
  getPayout: ({
    partnerId,
    address,
    limit,
    page,
    orderBy,
    nextPage,
    prevPage,
  }: {
    partnerId?: string | undefined;
    address?: string | undefined;
    limit: number;
    page: number;
    orderBy: string;
    nextPage?: string | undefined;
    prevPage?: string | undefined;
  }) => Promise<void>;
  getPayoutStatuses: () => Promise<void>;
  updateBalancePayout: ({
    id,
    status,
    desc,
  }: {
    id: string;
    status?: string;
    desc?: string;
  }) => Promise<void>;
  setPayoutExport: (payoutId: string) => void;
  setPayInExport: (payIn: string) => void;
  setBalanceLoading: (isLoading: boolean) => void;
  paginateBalance: ({
    type,
    page,
    partnerId,
    walletAddress,
    nextPage,
    prevPage,
  }: {
    type: string;
    page: number;
    partnerId: string;
    walletAddress: string;
    nextPage?: string | undefined;
    prevPage?: string | undefined;
  }) => void;
  setExportAll: ({
    checked,
    payoutList,
    page,
  }: {
    checked: boolean;
    payoutList: PayoutItem[];
    page: number;
  }) => void;
  setActiveBalance: (activeBalance: string) => void;
  setAvailablePayoutPages: (availablePayoutPages: number) => void;
  clearExports: () => void;
  setAvailableBalance: (availableBalance: number) => void;
  setPayoutWallet: (payoutWallet: string | null) => void;
  setLoading: (isLoading: boolean) => void;
  setInvalidOtpMessage: (invalidOtpMessage: string) => void;
}
