import { FC } from "react";
import classnames from "classnames";

import { InputProps } from "./Input.props";

const Input: FC<InputProps> = ({
  className,
  inputMode,
  type,
  name,
  value,
  icon,
  placeholder,
  ref,
  pattern,
  onChange,
  onBlur,
  onFocus,
  onClick,
}) => {
  return (
    <div className="input__wrapper">
      <input
        className={classnames("input", className)}
        inputMode={inputMode}
        pattern={pattern}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
      />
      {icon}
    </div>
  );
};

export default Input;
