import React, { createContext, useContext, useReducer } from "react";
import {
  getBalance,
  getPayoutList,
  getPayoutStat,
  updatePayout,
} from "services/balance";
import { AxiosResponse } from "axios";

import {
  State,
  Dispatch,
  ActionTypes,
  DispatchContext,
  PayoutItem,
} from "./types";
import { reducer } from "./reducers";

const initialState = {
  balanceLoading: false,
  amount: null,
  walletAddress: undefined,
  payoutWallet: undefined,
  oneTimePassword: null,
  otpAttempts: 3,
  payoutList: null,
  payInList: null,
  payoutStatuses: null,
  payoutToExport: null,
  payInToExport: null,
  exportAll: false,
  checkedPages: null,
  page: 1,
  nextPage: undefined,
  prevPage: undefined,
  availableBalance: null,
  loading: false,
  exportPages: [],
  activeBalance: "payout",
  availablePayoutPages: 0,
  invalidOtpMessage: null,
};

const BalanceStateContext = createContext<State>(initialState);
const BalanceDispatchContext = createContext<Dispatch>(() => null);

const BalanceProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <BalanceStateContext.Provider value={state}>
      <BalanceDispatchContext.Provider value={dispatch}>
        {children}
      </BalanceDispatchContext.Provider>
    </BalanceStateContext.Provider>
  );
};

export const useBalanceState = (): State => {
  const context = useContext(BalanceStateContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useClientStateContext must be used within a ClientProvider"
    );
  }

  return context;
};

export const useBalanceDispatchContext = (): DispatchContext => {
  const dispatch = useContext(BalanceDispatchContext);

  const setAmount = (amount: number | null) =>
    dispatch({ type: ActionTypes.SET_AMOUNT, payload: amount });

  const setWalletAddress = (walletAddress: string | null) =>
    dispatch({ type: ActionTypes.SET_WALLET_ADDRESS, payload: walletAddress });

  const setPayoutWallet = (payoutWallet: string | null) =>
    dispatch({ type: ActionTypes.SET_PAYOUT_WALLET, payload: payoutWallet });

  const setOneTimePassword = (oneTimePassword: number) =>
    dispatch({
      type: ActionTypes.SET_ONE_TIME_PASSWORD,
      payload: oneTimePassword,
    });

  const setOtpAttempts = (otpAttempts: number) =>
    dispatch({ type: ActionTypes.SET_OTP_ATTEMPTS, payload: otpAttempts });

  const getPartnerBalance = async ({
    partnerId,
    partnerName,
    asset,
  }: {
    partnerId: string;
    partnerName: string;
    asset: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }): Promise<AxiosResponse<any> | undefined> => {
    try {
      const response = await getBalance({ partnerId, partnerName, asset });

      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const getPayout = async ({
    partnerId,
    address,
    limit = 10,
    page = 1,
    orderBy = "desc",
    nextPage,
    prevPage,
  }: {
    partnerId?: string | undefined;
    address?: string | undefined;
    limit: number;
    page: number;
    orderBy: string;
    nextPage?: string | undefined;
    prevPage?: string | undefined;
  }): Promise<void> => {
    dispatch({ type: ActionTypes.SET_LOADING, payload: true });

    try {
      const response = await getPayoutList({
        partnerId,
        address,
        limit,
        page,
        orderBy,
        nextPage,
        prevPage,
      });

      const availablePages = Math.ceil(response?.data.data.payoutCount / 10);

      dispatch({
        type: ActionTypes.SET_AVAILABLE_PAYOUT_PAGES,
        payload: availablePages,
      });
      dispatch({
        type: ActionTypes.SET_PAYIN,
        payload: response?.data.data.pay_in,
      });
      dispatch({
        type: ActionTypes.SET_PAYOUT,
        payload: response?.data.data.payoutOrders,
      });
      dispatch({
        type: ActionTypes.SET_PAGINATION_POINTERS,
        payload: {
          nextPage: response?.data.data.nextPage,
          prevPage: response?.data.data.prevPage,
        },
      });
      dispatch({ type: ActionTypes.SET_LOADING, payload: false });
    } catch (error) {
      console.log(error);
      dispatch({ type: ActionTypes.SET_LOADING, payload: false });
    }
  };

  const getPayoutStatuses = async (): Promise<void> => {
    try {
      const response = await getPayoutStat();

      const statuses: string[] = Object.values(response?.data.data);

      dispatch({
        type: ActionTypes.SET_PAYOUT_STATUSES,
        payload: statuses,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateBalancePayout = async ({
    id,
    status,
    desc,
  }: {
    id: string;
    status?: string;
    desc?: string;
  }) => {
    try {
      const response = await updatePayout({ id, status, desc });

      if (response) {
        dispatch({
          type: ActionTypes.UPDATE_PAYOUT,
          payload: response.data.data,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setPayoutExport = (payoutId: string) => {
    dispatch({ type: ActionTypes.SET_PAYOUT_EXPORT, payload: payoutId });
  };

  const setPayInExport = (payIn: string) => {
    dispatch({ type: ActionTypes.SET_PAYIN_EXPORT, payload: payIn });
  };

  const setBalanceLoading = (isLoading: boolean) =>
    dispatch({ type: ActionTypes.SET_BALANCE_LOADING, payload: isLoading });

  const paginateBalance = async ({
    type,
    page,
    partnerId,
    walletAddress,
    nextPage,
    prevPage,
  }: {
    type: string;
    page: number;
    partnerId: string;
    walletAddress: string;
    nextPage?: string | undefined;
    prevPage?: string | undefined;
  }): Promise<void> => {
    dispatch({
      type: ActionTypes.SET_PAGE,
      payload: type === "next" ? page + 1 : page - 1,
    });
    await getPayout({
      partnerId: partnerId,
      address: walletAddress,
      limit: 10,
      page: type === "next" ? page + 1 : page - 1,
      orderBy: "desc",
      ...(type === "next" && { nextPage }),
      ...(type === "prev" && { prevPage }),
    });
  };

  const setExportAll = ({
    checked,
    payoutList,
    page,
  }: {
    checked: boolean;
    payoutList: PayoutItem[];
    page: number;
  }) => {
    dispatch({ type: ActionTypes.SET_EXPORT_PAGE, payload: page });

    if (checked) {
      dispatch({ type: ActionTypes.SET_ALL_EXPORT, payload: payoutList });
    } else {
      dispatch({ type: ActionTypes.SET_CLEAR_EXPORT, payload: payoutList });
    }
  };

  const setActiveBalance = (activeBalance: string) =>
    dispatch({ type: ActionTypes.SET_ACTIVE_BALANCE, payload: activeBalance });

  const setAvailablePayoutPages = (availablePayoutPages: number) =>
    dispatch({
      type: ActionTypes.SET_AVAILABLE_PAYOUT_PAGES,
      payload: availablePayoutPages,
    });

  const clearExports = () => dispatch({ type: ActionTypes.RESET_EXPORT });

  const setAvailableBalance = (availableBalance: number) =>
    dispatch({
      type: ActionTypes.SET_AVAILABLE_BALANCE,
      payload: availableBalance,
    });

  const setLoading = (isLoading: boolean) =>
    dispatch({ type: ActionTypes.SET_LOADING, payload: isLoading });

  const setInvalidOtpMessage = (invalidOtpMessage: string) =>
    dispatch({
      type: ActionTypes.SET_INVALID_OTP_MESSAGE,
      payload: invalidOtpMessage,
    });

  return {
    setAmount,
    setWalletAddress,
    setOneTimePassword,
    setOtpAttempts,
    getPartnerBalance,
    getPayout,
    getPayoutStatuses,
    updateBalancePayout,
    setPayoutExport,
    setPayInExport,
    setBalanceLoading,
    paginateBalance,
    setExportAll,
    setActiveBalance,
    setAvailablePayoutPages,
    clearExports,
    setAvailableBalance,
    setPayoutWallet,
    setLoading,
    setInvalidOtpMessage,
  };
};

export default BalanceProvider;
