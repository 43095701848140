import { FC } from "react";

import { ReactComponent as Dashboard } from "./icons/dashboard.svg";
import { ReactComponent as Orders } from "./icons/orders.svg";
import { ReactComponent as Partners } from "./icons/partners.svg";
import { ReactComponent as Users } from "./icons/users.svg";
import { ReactComponent as RestrictedCountries } from "./icons/restricted-countries.svg";
import { ReactComponent as Export } from "./icons/export.svg";
import { ReactComponent as Search } from "./icons/search.svg";
import { ReactComponent as Arrow } from "./icons/arrow.svg";
import { ReactComponent as Spin } from "./icons/spin.svg";
import { ReactComponent as Filter } from "./icons/filter.svg";
import { ReactComponent as Plus } from "./icons/plus.svg";
import { ReactComponent as Action } from "./icons/action-icon.svg";
import { ReactComponent as SucessCheck } from "./icons/success-check.svg";
import { ReactComponent as WarningCheck } from "./icons/warning-check.svg";
import { ReactComponent as SuccessStatus } from "./icons/success-status.svg";
import { ReactComponent as RejectStatus } from "./icons/reject-status.svg";
import { ReactComponent as OrderReject } from "./icons/order-reject.svg";
import { ReactComponent as Clock } from "./icons/clock.svg";
import { ReactComponent as ClipBoard } from "./icons/clipboard.svg";
import { ReactComponent as SearchIcon } from "./icons/search-icon.svg";
import { ReactComponent as GearIcon } from "./icons/gear.svg";
import { ReactComponent as ProfileIcon } from "./icons/profile-icon.svg";
import { ReactComponent as FilterRemove } from "./icons/filter-remove.svg";
import { ReactComponent as FilterAdd } from "./icons/filter-add.svg";
import { ReactComponent as FilterArrow } from "./icons/filter-arrow.svg";
import { ReactComponent as DropdownIcon } from "./icons/dropdown-icon.svg";
import { ReactComponent as FilterDatepicker } from "./icons/filter-datepicker.svg";
import { ReactComponent as FilterErrorArrow } from "./icons/filter-error-arrow.svg";
import { ReactComponent as FilterChevron } from "./icons/filter-chevron.svg";
import { ReactComponent as FilterRemoveText } from "./icons/filter-remove-text.svg";
import { ReactComponent as ClearFilter } from "./icons/clear-filter.svg";
import { ReactComponent as ExportButton } from "./icons/export-button.svg";
import { ReactComponent as AddFilter } from "./icons/add-filter.svg";
import { ReactComponent as LogoText } from "./icons/logo-text.svg";
import { ReactComponent as Logo } from "./icons/logo.svg";
import { ReactComponent as Caret } from "./icons/caret.svg";
import { ReactComponent as Close } from "./icons/close.svg";
import { ReactComponent as Eye } from "./icons/eye.svg";
import { ReactComponent as InfoIcon } from "./icons/info-icon.svg";
import { ReactComponent as RotateIcon } from "./icons/rotate-icon.svg";
import { ReactComponent as EditPencil } from "./icons/edit-pencil.svg";
import { ReactComponent as IconInfo } from "./icons/icon-info.svg";
import { ReactComponent as NotificationIcon } from "./icons/notification-icon.svg";
import { ReactComponent as HourGlass } from "./icons/hourglass.svg";
import { ReactComponent as BalanceReject } from "./icons/balance-reject.svg";
import { ReactComponent as BalanceBitcoin } from "./icons/balance-bitcoin.svg";
import { ReactComponent as BalancePayout } from "./icons/balance-payout.svg";
import { ReactComponent as PayoutFunds } from "./icons/payout-funds.svg";
import { ReactComponent as BannerInfo } from "./icons/banner-info.svg";
import { ReactComponent as PayoutRefresh } from "./icons/payout-refresh.svg";
import { ReactComponent as PayoutSuccess } from "./icons/payout-success.svg";
import { ReactComponent as BalanceEmpty } from "./icons/balance-empty-state.svg";
import { ReactComponent as BalanceError } from "./icons/balance-error.svg";
import { ReactComponent as ChevronDown } from "./icons/chevronDown.svg";
import { ReactComponent as SellFailed } from "./icons/sell-failed.svg";
import { ReactComponent as SellPending } from "./icons/sell-pending.svg";
import { ReactComponent as SellSuccess } from "./icons/sell-success.svg";
import { ReactComponent as BackIcon } from "./icons/back.svg";
import { ReactComponent as CodeChevron } from "./icons/code_chevron.svg";
import { ReactComponent as EventsChevron } from "./icons/events-chevron.svg";

const icons = {
  dashboard: <Dashboard />,
  orders: <Orders />,
  partners: <Partners />,
  users: <Users />,
  restricted_countries: <RestrictedCountries />,
  export: <Export />,
  search: <Search />,
  arrow: <Arrow />,
  spin: <Spin />,
  filter: <Filter />,
  plus: <Plus />,
  action: <Action />,
  success_check: <SucessCheck />,
  warning_check: <WarningCheck />,
  success_status: <SuccessStatus />,
  reject_status: <RejectStatus />,
  order_reject: <OrderReject />,
  clock: <Clock />,
  clipboard: <ClipBoard />,
  search_icon: <SearchIcon />,
  gear: <GearIcon />,
  profile_icon: <ProfileIcon />,
  filter_remove: <FilterRemove />,
  filter_add: <FilterAdd />,
  filter_arrow: <FilterArrow />,
  filter_datepicker: <FilterDatepicker />,
  dropdown_icon: <DropdownIcon />,
  filter_error_arrow: <FilterErrorArrow />,
  filter_chevron: <FilterChevron />,
  filter_remove_text: <FilterRemoveText />,
  clear_filter: <ClearFilter />,
  export_button: <ExportButton />,
  add_filter: <AddFilter />,
  logo_text: <LogoText />,
  logo: <Logo />,
  caret: <Caret />,
  close: <Close />,
  eye: <Eye />,
  info: <InfoIcon />,
  rotate: <RotateIcon />,
  edit_pencil: <EditPencil />,
  icon_info: <IconInfo />,
  notification_icon: <NotificationIcon />,
  hourglass: <HourGlass />,
  balance_reject: <BalanceReject />,
  balance_bitcoin: <BalanceBitcoin />,
  balance_payout: <BalancePayout />,
  payout_funds: <PayoutFunds />,
  banner_info: <BannerInfo />,
  payout_refresh: <PayoutRefresh />,
  payout_success: <PayoutSuccess />,
  balance_empty: <BalanceEmpty />,
  balance_error: <BalanceError />,
  chevron_down: <ChevronDown />,
  sell_failed: <SellFailed />,
  sell_pending: <SellPending />,
  sell_success: <SellSuccess />,
  back: <BackIcon />,
  code_chevron: <CodeChevron />,
  events_chevron: <EventsChevron />,
};

interface Props {
  name: keyof typeof icons;
}

const Icon: FC<Props> = ({ name }) => {
  return icons[name];
};

export default Icon;
