import React, { FC } from "react";
import QRCode from "react-qr-code";

import { QRPopupProps } from "./QRPopup.props";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { Icon } from "components/base";
import { copyToClipboard } from "utils/helpers";

const QRPopup: FC<QRPopupProps> = ({
  walletAddress,
  isOpen,
  setIsOpen,
}): JSX.Element => {
  const handleClickOutside = () => {
    setIsOpen(false);
  };

  const stopPr = (e: React.MouseEvent) => e.stopPropagation();

  return (
    <div
      onClick={handleClickOutside}
      className={`qr-popup__container ${isOpen ? "active" : ""}`}
    >
      <div className="qr-popup__wrapper" onClick={stopPr}>
        <div className="qr-popup__headline">
          <span>QR-code for receive</span>
          <Button
            className="qr-popup__close-button"
            type="button"
            onClick={() => setIsOpen(false)}
          >
            <span></span>
            <span></span>
          </Button>
        </div>
        <div className="qr-popup__inner-wrapper">
          {walletAddress && <QRCode size={180} value={walletAddress} />}
        </div>
        <div className="qr-popup__content-wrapper">
          <h1>Your wallet address</h1>
          <div className="qr-popup__address-wrapper">
            <span>{walletAddress}</span>
            <Tooltip content="Copied">
              {(setShowTooltip) => (
                <Button
                  type="button"
                  className="clipboard-button clipboard-gray"
                  onClick={() => {
                    copyToClipboard(walletAddress as string);
                    setShowTooltip(true);
                  }}
                >
                  <Icon name="clipboard" />
                </Button>
              )}
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QRPopup;
