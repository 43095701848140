const ZeroNotification = (): JSX.Element => {
  return (
    <div className="zero-notification__wrapper">
      <p className="zero-notification__content">
        Not notifications yet <br /> Messages and alerts about your Swaps <br />
        account will show up here
      </p>
    </div>
  );
};

export default ZeroNotification;
