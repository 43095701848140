import { FC, useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { FIAT_TABLE_ACCESS } from "services/roleService/widgetConsole/fiat";
import { useWidgetFiltersState } from "providers/WidgetFilter";
import moment from "moment";

import Popover from "components/Popover";
import { Icon, TableActionButton } from "components/base";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { copyToClipboard } from "utils/helpers";
import { FiatColumnFieldProps } from "./WidgetConsole.props";

const FiatColumnField: FC<FiatColumnFieldProps> = ({
  flow,
  updateFiatStatus,
  getFiatList,
  setChosenFiat,
  page,
  setIsOpen,
  fiat,
  role,
  isLast,
}): JSX.Element => {
  const location = useLocation();
  const { fiat_is_suspended, fiat_name, fiat_symbol, fiat_is_sell_supported } =
    useWidgetFiltersState();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [suspendStatus, setSuspendStatus] = useState(fiat?.is_suspended);
  const [sellStatus, setSellStatus] = useState(fiat?.is_sell_supported);
  const [isLoading, setIsLoading] = useState(false);

  const updateStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await updateFiatStatus({
        id: fiat.id,
        isSuspended: suspendStatus,
        is_sell_supported: sellStatus as boolean,
        max_amount: fiat.max_amount ?? 0,
        max_buy_amount: fiat.max_buy_amount ?? 0,
        min_amount: fiat.min_amount ?? 0,
        min_buy_amount: fiat.min_buy_amount ?? 0,
      });

      if (data) {
        if (location.pathname.includes("sell-flow")) {
          getFiatList(page, fiat_name, fiat_symbol, fiat_is_sell_supported);
        }

        if (location.pathname.includes("buy-flow")) {
          getFiatList(page, fiat_name, fiat_symbol, fiat_is_suspended);
        }
      }

      return setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [
    fiat,
    fiat_is_suspended,
    fiat_name,
    fiat_symbol,
    getFiatList,
    page,
    sellStatus,
    suspendStatus,
    updateFiatStatus,
    fiat_is_sell_supported,
    location.pathname,
  ]);

  const onActionClick = () => {
    setChosenFiat(fiat);
    setIsOpen(true);
  };

  const renderStatus = useMemo(() => {
    if (flow === "buy" && role) {
      return (
        <>
          {FIAT_TABLE_ACCESS.suspend_status[role] && (
            <td className="user-list-td-grey suspend-status">
              <div>
                {fiat?.is_suspended ? (
                  <div className="user-status-td transaction-status-td user-status-blocked">
                    Not Supported
                    <Icon name="order_reject" />
                  </div>
                ) : (
                  <div className="user-status-td transaction-status-td user-status-active">
                    Supported
                    <Icon name="success_check" />
                  </div>
                )}
              </div>
            </td>
          )}
          {FIAT_TABLE_ACCESS.status_edit[role] && (
            <td className="td-edit-status">
              <Popover
                isPopoverOpen={isPopoverOpen}
                setIsPopoverOpen={setIsPopoverOpen}
                position={["bottom"]}
                className="filter__popover"
                arrowStyle={{ left: "60%" }}
                contentLocation={(popoverState) => {
                  return {
                    top: popoverState.nudgedTop,
                    left: popoverState.targetRect.left - 150,
                  };
                }}
                component={
                  <Button
                    type="button"
                    className="button--transparent button-tr-status"
                    onClick={() => setIsPopoverOpen(true)}
                  >
                    <Icon name="edit_pencil" />
                  </Button>
                }
              >
                <div className="popover-stats__container">
                  <h1 className="popover-stats__title">Status</h1>
                  <div className="popover-stats__container--radio">
                    <div className="radio__wrapper">
                      <label className="radio__label">
                        <input
                          type="radio"
                          name="radio"
                          onChange={() => setSuspendStatus(true)}
                          checked={suspendStatus}
                        />
                        <span className="radio__checkmark"></span>Not Supported
                      </label>
                    </div>
                    <div className="radio__wrapper">
                      <label className="radio__label">
                        <input
                          type="radio"
                          name="radio"
                          onChange={() => setSuspendStatus(false)}
                          checked={!suspendStatus}
                        />
                        <span className="radio__checkmark"></span>Supported
                      </label>
                    </div>
                  </div>
                  <div className="radio-buttons__wrapper">
                    <Button
                      type="button"
                      className="button-cancel"
                      onClick={() => setIsPopoverOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      className="button-submit"
                      onClick={updateStatus}
                      disabled={
                        fiat.is_suspended === suspendStatus || isLoading
                      }
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </Popover>
            </td>
          )}
        </>
      );
    }

    if (flow === "sell" && role) {
      return (
        <>
          {FIAT_TABLE_ACCESS.suspend_status[role] && (
            <td className="user-list-td-grey suspend-status">
              <div>
                {fiat?.is_sell_supported ? (
                  <div className="user-status-td transaction-status-td user-status-active">
                    Supported
                    <Icon name="success_check" />
                  </div>
                ) : (
                  <div className="user-status-td transaction-status-td user-status-blocked">
                    Not Supported
                    <Icon name="order_reject" />
                  </div>
                )}
              </div>
            </td>
          )}
          {FIAT_TABLE_ACCESS.status_edit[role] && (
            <td className="td-edit-status">
              <Popover
                isPopoverOpen={isPopoverOpen}
                setIsPopoverOpen={setIsPopoverOpen}
                position={["bottom"]}
                className="filter__popover"
                arrowStyle={{ left: "60%" }}
                contentLocation={(popoverState) => {
                  return {
                    top: popoverState.nudgedTop,
                    left: popoverState.targetRect.left - 150,
                  };
                }}
                component={
                  <Button
                    type="button"
                    className="button--transparent button-tr-status"
                    onClick={() => setIsPopoverOpen(true)}
                  >
                    <Icon name="edit_pencil" />
                  </Button>
                }
              >
                <div className="popover-stats__container">
                  <h1 className="popover-stats__title">Status</h1>
                  <div className="popover-stats__container--radio">
                    <div className="radio__wrapper">
                      <label className="radio__label">
                        <input
                          type="radio"
                          name="radio"
                          onChange={() => setSellStatus(true)}
                          checked={sellStatus}
                        />
                        <span className="radio__checkmark"></span> Supported
                      </label>
                    </div>
                    <div className="radio__wrapper">
                      <label className="radio__label">
                        <input
                          type="radio"
                          name="radio"
                          onChange={() => setSellStatus(false)}
                          checked={!sellStatus}
                        />
                        <span className="radio__checkmark"></span> Not Supported
                      </label>
                    </div>
                  </div>
                  <div className="radio-buttons__wrapper">
                    <Button
                      type="button"
                      className="button-cancel"
                      onClick={() => setIsPopoverOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      className="button-submit"
                      onClick={updateStatus}
                      disabled={
                        fiat.is_sell_supported === sellStatus || isLoading
                      }
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </Popover>
            </td>
          )}
        </>
      );
    }
  }, [
    flow,
    role,
    fiat,
    isLoading,
    isPopoverOpen,
    suspendStatus,
    sellStatus,
    updateStatus,
  ]);

  const renderMaxAmount = useMemo(() => {
    if (location.pathname.includes("sell-flow")) {
      return (
        <td className="user-list-td-grey">{fiat?.max_sell_amount ?? "-"}</td>
      );
    }

    if (location.pathname.includes("buy-flow") && role) {
      return (
        <>
          {FIAT_TABLE_ACCESS.max_amount[role] && (
            <td className="user-list-td-grey">{fiat?.max_amount ?? "-"}</td>
          )}
          <td className="user-list-td-grey">{fiat?.max_buy_amount ?? "-"}</td>
        </>
      );
    }
  }, [location, fiat, role]);

  const renderMinAmount = useMemo(() => {
    if (location.pathname.includes("sell-flow")) {
      return (
        <td className="user-list-td-grey">{fiat?.min_sell_amount ?? "-"}</td>
      );
    }

    if (location.pathname.includes("buy-flow") && role) {
      return (
        <>
          {FIAT_TABLE_ACCESS.min_amount[role] && (
            <td className="user-list-td-grey">{fiat?.min_amount ?? "-"}</td>
          )}
          <td className="user-list-td-grey">{fiat?.min_buy_amount ?? "-"}</td>
        </>
      );
    }
  }, [location, fiat, role]);

  return role ? (
    <>
      {FIAT_TABLE_ACCESS.id[role] && (
        <td className="table-id-link">
          <div>
            <Link
              to={`${location.pathname}/fiat/${fiat?.id}`}
            >{`${fiat?.id.substr(0, 4)}...${fiat?.id.substr(
              fiat?.id.length - 4,
              fiat?.id.length
            )}`}</Link>
          </div>
        </td>
      )}
      {FIAT_TABLE_ACCESS.id_clipboard[role] && (
        <td className="td-clipboard">
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(fiat.id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </td>
      )}
      {renderStatus}
      {renderMaxAmount}
      {renderMinAmount}
      {FIAT_TABLE_ACCESS.name[role] && (
        <td className="user-list-td-grey">{fiat?.name ?? "-"}</td>
      )}
      {FIAT_TABLE_ACCESS.sign[role] && (
        <td className="user-list-td-grey">{fiat?.sign ?? "-"}</td>
      )}
      {FIAT_TABLE_ACCESS.symbol[role] && (
        <td className="user-list-td-grey">{fiat?.symbol ?? "-"}</td>
      )}
      <td>
        <div>{moment(fiat?.createdAt).format("DD MMM yyyy, hh:mm")}</div>
      </td>
      <td>
        <TableActionButton
          isLast={isLast}
          buttons={[
            {
              id: fiat.id,
              title: "Edit",
              role: "super_admin",
              onClick: onActionClick,
            },
          ]}
        />
      </td>
    </>
  ) : (
    <></>
  );
};

export default FiatColumnField;
