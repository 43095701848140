import React, { useEffect } from "react";
import { usePartnerStateContext } from "providers/Partners";

import PartnerColumnField from "./PartnerColumnField";

let arr: string[] | undefined = [];

const PartnersColumn: React.FC<{ editPressed: (id: string) => void }> = ({
  editPressed,
}) => {
  const { partners } = usePartnerStateContext();

  useEffect(() => {
    arr = partners.map((item) => item.id);
  }, [partners]);

  return (
    <>
      {partners.map((partner, index) => (
        <PartnerColumnField
          key={index}
          partner={partner}
          editPressed={editPressed}
          arr={arr}
          isLast={index >= 0 && index < 2}
        />
      ))}
    </>
  );
};

export default PartnersColumn;
