import { ACTION_RESET_CLIENT_FILTERS } from "providers/ClientFilter/types";

import { FilterType } from ".";
import { actionType } from "./partnerAction";
import {
  ACTION_SET_COMMISSION_FROM,
  ACTION_SET_COMMISSION_TO,
  ACTION_SET_DATEFROM,
  ACTION_SET_DATETO,
  ACTION_SET_EXPORT_PARTNER,
  ACTION_SET_FILTERS,
  ACTION_SET_MARKUP_FROM,
  ACTION_SET_MARKUP_TO,
  ACTION_SET_ORDERING,
  ACTION_SET_PAGE,
  ACTION_SET_SEARCH,
  ACTION_SET_SETTINGS_TOGGLE,
  ACTION_SET_SORTING,
  ACTION_SET_PARTNER_COUNT,
  ACTION_SET_CHECKED_ID_PARTNER,
  ACTION_SET_SELECTED_ID_PARTNER,
  ACTION_SET_ARRAY_CHECK_PARTNER,
  ACTION_SET_PARTNER_ID,
  ACTION_SET_FILTER_PARTNER,
  ACTION_SET_MODAL_OPEN,
  ACTION_SET_PARTNER_OPTION,
  ACTION_SET_CHECKED_PAGES,
} from "./types";

const toggleHandler = (state, action) => {
  const newState = { ...state };
  const index = newState?.settingsToggle?.findIndex(
    (item) => item === action?.payload
  );

  if (index > -1) {
    newState?.settingsToggle.splice(index, 1);

    return { ...newState };
  }

  newState?.settingsToggle.push(action.payload);

  return { ...newState };
};

const exportedPartnersHandler = (state, action) => {
  let filteredPartners = state.exportedPartner;

  if (state.exportedPartner.includes(action?.payload)) {
    filteredPartners = state.exportedPartner.filter(
      (item) => item !== action?.payload
    );
  } else {
    filteredPartners = [...state.exportedPartner, action.payload];
  }

  return {
    ...state,
    exportedPartner: filteredPartners,
  };
};

const setPartners = (
  state,
  payload: { checked: boolean; partners: string[] }
) => {
  return {
    ...state,
    checkedIDPartner: { checked: payload.checked, partners: payload?.partners },
    exportedPartner: !payload?.checked ? [] : payload?.partners,
  };
};

const resetClientFilters = (state) => {
  return {
    ...state,
    pagePartner: 1,
    partnerSearch: "",
    partnerFilter: "",
    dateFromPartner: null,
    dateToPartner: null,
    markupFrom: "",
    markupTo: "",
    commissionFrom: "",
    commissionTo: "",
    sortingPartner: "",
    orderingPartner: undefined,
    settingsToggle: [],
    exportedPartner: [],
    checkedIDPartner: { checked: false, partners: [] },
    selectedPartner: "",
    arrayCheckingPartner: [],
    partnerID: undefined,
    filteringPartner: undefined,
    modalOpen: false,
    partnerOption: undefined,
    checkedPages: [],
  };
};

const partnerReducer = (
  state: FilterType,
  action: actionType
): Record<string, unknown> => {
  switch (action.type) {
    case ACTION_SET_PAGE:
      return {
        ...state,
        pagePartner: action.payload,
      };
    case ACTION_SET_SEARCH:
      return {
        ...state,
        partnerSearch: action.payload,
      };
    case ACTION_SET_FILTERS:
      return {
        ...state,
        partnerFilter: action.payload,
      };
    case ACTION_SET_DATEFROM:
      return {
        ...state,
        dateFromPartner: action.payload,
      };
    case ACTION_SET_DATETO:
      return {
        ...state,
        dateToPartner: action.payload,
      };
    case ACTION_SET_SORTING:
      return {
        ...state,
        sortingPartner: action.payload,
      };
    case ACTION_SET_ORDERING:
      return {
        ...state,
        ordering: action.payload,
      };
    case ACTION_SET_MARKUP_FROM:
      return {
        ...state,
        markupFrom: action.payload,
      };
    case ACTION_SET_MARKUP_TO:
      return {
        ...state,
        markupTo: action.payload,
      };
    case ACTION_SET_COMMISSION_FROM:
      return {
        ...state,
        commissionFrom: action.payload,
      };
    case ACTION_SET_COMMISSION_TO:
      return {
        ...state,
        commissionTo: action.payload,
      };
    case ACTION_SET_CHECKED_ID_PARTNER:
      return setPartners(
        state,
        action.payload as { checked: boolean; partners: string[] }
      );
    case ACTION_SET_PARTNER_OPTION:
      return {
        ...state,
        partnerOption: action.payload,
      };
    case ACTION_SET_PARTNER_COUNT:
      return {
        ...state,
        visiblePartnersCount: action.payload,
      };
    case ACTION_SET_SELECTED_ID_PARTNER:
      return {
        ...state,
        selectedPartner: action.payload,
      };
    case ACTION_SET_FILTER_PARTNER:
      return {
        ...state,
        filteringPartner: action.payload,
      };
    case ACTION_SET_PARTNER_ID:
      return {
        ...state,
        partnerID: action.payload,
      };
    case ACTION_SET_ARRAY_CHECK_PARTNER:
      return {
        ...state,
        arrayCheckingPartner: action.payload,
      };
    case ACTION_SET_MODAL_OPEN:
      return {
        ...state,
        modalOpen: action.payload,
      };
    case ACTION_SET_CHECKED_PAGES:
      return {
        ...state,
        checkedPages: action.payload,
      };
    case ACTION_SET_SETTINGS_TOGGLE:
      return toggleHandler(state, action);
    case ACTION_SET_EXPORT_PARTNER:
      return exportedPartnersHandler(state, action);
    case ACTION_RESET_CLIENT_FILTERS:
      return resetClientFilters(state);
    default:
      return state;
  }
};

export default partnerReducer;
