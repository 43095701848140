/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const createQuerySearchParams = (params) => {
  const keys = Object.keys(params);
  const querySearchParams = new URLSearchParams();

  keys.forEach((key) => {
    querySearchParams.append(key, params[key]);
  });

  return `?${querySearchParams.toString()}`;
};

export const getQuerySearchParams = (): { [key: string]: string } => {
  const queryString = window.location.search;
  const searchParams = new URLSearchParams(queryString);

  const result = {};
  const keys = [...searchParams.keys()];

  keys.forEach((key) => {
    result[key] = searchParams.get(key);
  });

  return result;
};
