import { useBalanceState } from "providers/Balance";

import { Icon } from "components/base";

const BalanceInfo = (): JSX.Element => {
  const { availableBalance } = useBalanceState();

  return (
    <div className="balance-info__container">
      <Icon name="balance_bitcoin" />
      <div className="balance-info__content">
        <h1>Balance</h1>
        <span>
          <span>{availableBalance}</span>
          <span>BTC</span>
        </span>
      </div>
    </div>
  );
};

export default BalanceInfo;
