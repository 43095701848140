import { FC, useEffect, useMemo, useRef } from "react";
import {
  useClientDispatchContext,
  useClientStateContext,
} from "providers/Clients";
import { useClientDispatch, useClientFilters } from "providers/ClientFilter";
import {
  set_checkedID,
  set_filteringButton,
  set_page,
  set_checked_pages,
  set_search,
} from "providers/ClientFilter/clientsActions";
import { useOrderDispatch } from "providers/OrderFilter";
import { set_pageName } from "providers/OrderFilter/ordersAction";
import { useOrderDispatchContext, useOrderState } from "providers/Orders";

import { Table, Icon } from "components/base";
import LogCard from "./UserLogsColumn";
import NotFound from "pages/NotFound";
import { OrderDetailsModal } from "components/modals";
import { OrderDetailsModalRefType } from "components/modals/OrderDetailsModal";
import LogsFiltersWrapper from "./LogsFiltersWrappers";
import Button from "components/Button";
import Pagination from "components/Pagination";
import TableSkeleton from "components/TableSkeleton";

interface Props {
  id: string;
}

let timer: ReturnType<typeof setTimeout>;

const LogsTable: FC<Props> = ({ id }) => {
  const { getClientLogs, downloadClientLogs, setLoading } =
    useClientDispatchContext();

  const modalRef = useRef<OrderDetailsModalRefType>(null);
  // context

  const { logs, isLoading, clientsCount, logsCount } = useClientStateContext();
  const {
    logSearch,
    page,
    visibleClientsCount,
    checkedID,
    dateFrom,
    dateTo,
    checkedCountry,
    clientCountry,
    filterButtonVal,
    exportedClient,
    arrayChecking,
    sorting,
    ordering,
    checkedPages,
  } = useClientFilters();

  const dispatch = useClientDispatch();
  const orderDispatch = useOrderDispatch();
  const { orderDetails } = useOrderState();
  const { getOrderById } = useOrderDispatchContext();

  // states

  const selectUser = useMemo(() => {
    if (!logs?.length) return;
    const logsID = logs?.map((item) => item.id);

    const compoundID = [
      ...(exportedClient as string[]),
      ...(logsID as string[]),
    ];

    return (
      <>
        <input
          onChange={(e) => {
            if (e.target.checked) {
              dispatch(
                set_checkedID({
                  checked: e.target.checked,
                  clients: compoundID,
                })
              );

              dispatch(set_checked_pages([...checkedPages, page]));
            } else {
              dispatch(
                set_checkedID({
                  checked: true,
                  clients: exportedClient?.filter(
                    (item) => !(logsID as string[]).includes(item)
                  ) as string[],
                })
              );
              dispatch(
                set_checked_pages(checkedPages.filter((pg) => pg !== page))
              );
            }
          }}
          type="checkbox"
          className="form-check-input user-list-checkbox"
          checked={(checkedPages as number[])?.includes(page)}
        />
      </>
    );
  }, [dispatch, exportedClient, checkedPages, page, logs]);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (filterButtonVal === false) return;

      if (filterButtonVal) {
        getClientLogs(
          page,
          id,
          dateFrom,
          dateTo,
          visibleClientsCount,
          logSearch,
          clientCountry,
          checkedCountry
        );

        return;
      }

      getClientLogs(
        page,
        id,
        null,
        null,
        visibleClientsCount,
        logSearch,
        undefined,
        undefined
      );
    }, 450);

    orderDispatch(set_pageName("logs"));

    return () => {
      clearTimeout(timer);
    };
  }, [
    checkedCountry,
    clientCountry,
    dateFrom,
    dateTo,
    filterButtonVal,
    getClientLogs,
    id,
    orderDispatch,
    page,
    visibleClientsCount,
    logSearch,
  ]);

  const clearSearch = () => dispatch(set_search(""));

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        dispatch(set_page(page + 1));
        dispatch(set_filteringButton(true));

        return;
      case "prev":
        dispatch(set_page(page - 1));
        dispatch(set_filteringButton(true));

        return;

      default:
        return;
    }
  };

  return (
    <>
      <div
        className="dataTables_wrapper dt-bootstrap4 no-footer"
        id="kt_content"
      >
        <div className="button-export__wrapper">
          <Button
            type="button"
            className="button--transparent"
            onClick={() => location.reload()}
          >
            <Icon name="rotate" />
          </Button>

          <Button
            type="button"
            className="button--transparent button--export"
            disabled={!exportedClient?.length}
            onClick={() => {
              exportedClient?.length &&
                downloadClientLogs(
                  visibleClientsCount,
                  sorting,
                  ordering,
                  exportedClient,
                  checkedID,
                  logs,
                  id,
                  arrayChecking,
                  dateFrom,
                  dateTo,
                  logSearch,
                  checkedCountry,
                  clientCountry
                );
            }}
          >
            <Icon name="export_button" />
            Export Selected
          </Button>
          <Button
            type="button"
            className="button--transparent button--export"
            onClick={() =>
              downloadClientLogs(
                visibleClientsCount,
                sorting,
                ordering,
                exportedClient,
                checkedID,
                null,
                id,
                undefined,
                dateFrom,
                dateTo,
                logSearch,
                checkedCountry,
                clientCountry
              )
            }
          >
            <Icon name="export_button" />
            Export
          </Button>
        </div>
        <div className="card user-list-row  log-list-row">
          <LogsFiltersWrapper />
          <div className="user-list-body">
            {isLoading ? (
              <>
                <TableSkeleton />
                <Pagination
                  page={page}
                  count={clientsCount}
                  visibleCount={+visibleClientsCount}
                  clearSearch={clearSearch}
                  setLoading={setLoading}
                  isLoading={isLoading}
                  handlePagination={handlePagination}
                />
              </>
            ) : (
              (logs?.length === 0 && <NotFound />) || (
                <>
                  <Table
                    headers={[
                      { empty: true, w: "fit-content", content: selectUser },
                      { title: "ID", w: "fit-content" },
                      { title: "Log", w: "fit-content" },
                      { title: "IP", w: "fit-content" },
                      { title: "COUNTRY", w: "fit-content" },
                      { title: "Date", w: "fit-content" },
                    ]}
                  >
                    {logs?.map((log) => (
                      <LogCard
                        key={log.id}
                        log={log as never}
                        modalRef={modalRef}
                      />
                    ))}
                  </Table>
                  <Pagination
                    page={page}
                    count={logsCount}
                    visibleCount={+visibleClientsCount}
                    clearSearch={clearSearch}
                    setLoading={setLoading}
                    isLoading={isLoading}
                    handlePagination={handlePagination}
                  />
                </>
              )
            )}
          </div>
        </div>
      </div>
      <OrderDetailsModal
        order={orderDetails}
        getOrderById={getOrderById}
        ref={modalRef}
      />
    </>
  );
};

export default LogsTable;
