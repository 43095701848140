import { FC } from "react";

import { PartnerDetailsItemProps } from "./PartnerDetails.props";

const PartnerDetailsItem: FC<PartnerDetailsItemProps> = ({ title, value }) => {
  return (
    <div className="dashboard-item">
      <h1 className="dashboard-item--title">{title}</h1>
      <span className="dashboard-item--value">{value}</span>
    </div>
  );
};

export default PartnerDetailsItem;
