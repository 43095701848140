import { useMemo } from "react";
import { useSellFlowDispatch, useSellFlowState } from "providers/SellFlow";

import { PageWrapper } from "components/base";
import CryptoList from "./Crypto/CryptoList";
import CryptoFiltersWrapper from "./Crypto/CryptoFiltersWrapper";
import FiatList from "./Fiat/FiatList";
import FiatFiltersWrapper from "./Fiat/FiatFiltersWrapper";
import FireblockList from "./Fireblock/FireblockList";
import FireblockFiltersWrapper from "../FireblockFiltersWrapper";
import SellFlowTabs from "./SellFlowTabs";

const SellFlow = (): JSX.Element => {
  const { activeTab } = useSellFlowState();
  const { setPage } = useSellFlowDispatch();

  const renderTable = useMemo(() => {
    switch (activeTab) {
      case "crypto_list":
        return <CryptoList />;
      case "fireblock_list":
        return <FireblockList />;
      case "fiat_list":
        return <FiatList />;
      default:
        return <div>Not found</div>;
    }
  }, [activeTab]);

  const renderFilter = useMemo(() => {
    switch (activeTab) {
      case "crypto_list":
        return <CryptoFiltersWrapper />;
      case "fireblock_list":
        return <FireblockFiltersWrapper setPage={setPage} />;
      case "fiat_list":
        return <FiatFiltersWrapper />;
      default:
        return <div>Not found</div>;
    }
  }, [activeTab, setPage]);

  return (
    <PageWrapper title="Widget Console">
      <div
        className="content d-flex flex-column flex-column-fluid user-table"
        id="kt_content"
      >
        <div className="button-export__wrapper">
          <h1 className="page-title">Sell Flow</h1>
        </div>
        <SellFlowTabs />
        {renderFilter}
        <div className="container" id="kt_content_container">
          <div id="card-user-list-row" className="card user-list-row">
            {renderTable}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default SellFlow;
