import React from "react";
import classnames from "classnames";

import { ButtonProps } from "./Button.props";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    { children, type, className, disabled, onClick, onMouseOver, onMouseLeave },
    ref = null
  ) => {
    return (
      <button
        ref={ref}
        className={classnames("button", className)}
        type={type}
        onClick={onClick}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
        disabled={disabled}
      >
        {children}
      </button>
    );
  }
);

export default Button;
