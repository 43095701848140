export const ORDER_DETAILS_TABLE_ACCESS: {
  [key: string]: { [key: string]: boolean };
} = {
  orderId: { super_admin: true, partner: true },
  clientId: { super_admin: true, partner: true },
  partnerName: { super_admin: true, partner: false },
  partnerId: { super_admin: true, partner: false },
  partnerEmail: { super_admin: true, partner: false },
  wallet: { super_admin: true, partner: true },
  wallet_exchange: { super_admin: true, partner: false },
  elliptic_risk_score: { super_admin: true, partner: false },
  elliptic_view: { super_admin: true, partner: false },
  amount: { super_admin: true, partner: true },
  payment_exchange: { super_admin: true, partner: false },
  failed_reason: { super_admin: true, partner: true },
  amountByAsset: { super_admin: true, partner: true },
  commission: { super_admin: true, partner: true },
  markup: { super_admin: true, partner: false },
  network_commission: { super_admin: true, partner: true },
  rate: { super_admin: true, partner: true },
  status: { super_admin: true, partner: true },
  transaction_id: { super_admin: true, partner: false },
  tx_id: { super_admin: true, partner: true },
  withdraw_id: { super_admin: true, partner: true },
  withdrawal: { super_admin: true, partner: true },
  payment_method: { super_admin: true, partner: true },
  cost_of_service: { super_admin: true, partner: false },
  swaps_profit: { super_admin: true, partner: false },
  price: { super_admin: true, partner: false },
  risk_level: { super_admin: true, partner: false },
  tr_status: {
    super_admin: true,
    partner: false,
  },
};
