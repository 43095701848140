import { FC, useState } from "react";

import { ClientLimitPopover } from "./ClientLimitPopover.props";
import Button from "components/Button";

const ClientTierPopover: FC<ClientLimitPopover> = ({
  limit,
  updateLimit,
  setIsPopoverOpen,
}): JSX.Element => {
  const [limitValue, setLimitValue] = useState<number>(limit);

  return (
    <div className="popover-stats__container">
      <h1 className="popover-stats__title">Limits</h1>
      <div className="popover-stats__container--radio">
        <input
          type="number"
          value={limitValue}
          onChange={(e) => setLimitValue(parseInt(e.target.value))}
        />
      </div>
      <div className="radio-buttons__wrapper">
        <Button
          type="button"
          className="button-cancel"
          onClick={() => setIsPopoverOpen(false)}
        >
          Cancel
        </Button>
        <Button
          onClick={() => updateLimit(limitValue)}
          type="button"
          className="button-submit"
        >
          Change
        </Button>
      </div>
    </div>
  );
};

export default ClientTierPopover;
