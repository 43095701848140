import { useState, useEffect } from "react";
import {
  useWidgetConsoleDispatch,
  useWidgetConsoleState,
} from "providers/WidgetConsole";
import { filterFireblockByRole } from "services/roleService/widgetConsole/fireblock";
import { useWidgetFiltersState } from "providers/WidgetFilter";

import useRole from "hooks/useRole";
import NotFound from "pages/NotFound";
import TableSkeleton from "components/TableSkeleton";
import { Table } from "components/base";
import FireblockColumn from "./FireblockColumn";
import Pagination from "components/Pagination";
import AddCryptoModal from "./AddCryptoModal";

const FireblockList = (): JSX.Element => {
  const role = useRole();
  const {
    getFireblockList,
    paginate,
    setLoading,
    setChosenFireblock,
    createCrypto,
    updateCryptoStatus,
    getCrypto,
  } = useWidgetConsoleDispatch();

  const { isLoading, fireblockList, page, fireblockCount, chosenFireblock } =
    useWidgetConsoleState();

  const {
    trigger_filter,
    fireblock_name,
    fireblock_code,
    fireblock_type,
    fireblock_native_asset,
  } = useWidgetFiltersState();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (trigger_filter) {
      getFireblockList(
        page,
        fireblock_name,
        fireblock_code,
        fireblock_type,
        fireblock_native_asset
      );
    }

    // eslint-disable-next-line
  }, [trigger_filter]);

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        paginate({ page: page + 1, type: "fireblock" });

        return;
      case "prev":
        paginate({ page: page - 1, type: "fireblock" });

        return;

      default:
        return;
    }
  };

  return (
    <div className="table--row">
      {(fireblockList.length === 0 && !isLoading && <NotFound />) || (
        <div className="user-list-body">
          <button
            className="button button-primary add-crypto-button"
            onClick={() => setIsOpen(true)}
            disabled={!chosenFireblock}
          >
            Add Crypto
          </button>
          <div>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                headers={[
                  {
                    empty: true,
                    val: "checkbox",
                    w: "fit-content",
                  },
                  {
                    title: "ID",
                    val: "id",
                    w: "fit-content",
                    className: "th-email",
                  },
                  {
                    title: "",
                    val: "id_clipboard",
                    w: 120,
                    className: "th-crypto-id",
                  },
                  {
                    title: "Code",
                    val: "code",
                    w: "fit-content",
                  },
                  {
                    title: "Native Asset",
                    val: "native_asset",
                    w: "fit-content",
                  },
                  {
                    title: "Decimals",
                    val: "decimals",
                    w: "fit-content",
                  },
                  {
                    title: "Contract Address",
                    val: "contract_address",
                    w: "fit-content",
                  },
                  {
                    title: "Issuer Address",
                    val: "issuer_address",
                    w: "fit-content",
                  },
                  {
                    title: "Name",
                    val: "name",
                    w: "fit-content",
                  },
                  {
                    title: "Type",
                    val: "type",
                    w: "fit-content",
                  },
                  {
                    title: "Date",
                    val: "date",
                    w: "fit-content",
                  },
                ].filter((item) => role && filterFireblockByRole(item, role))}
              >
                <FireblockColumn
                  flow="buy"
                  setChosenFireblock={setChosenFireblock}
                  chosenFireblock={chosenFireblock}
                  fireblockList={fireblockList}
                  role={role}
                />
              </Table>
            )}
          </div>
          <Pagination
            page={page}
            count={fireblockCount as number}
            visibleCount={10}
            clearSearch={() => null}
            setLoading={setLoading}
            isLoading={isLoading}
            handlePagination={handlePagination}
          />
        </div>
      )}
      {chosenFireblock && (
        <AddCryptoModal
          createCrypto={createCrypto}
          updateCryptoStatus={updateCryptoStatus}
          setChosenFireblock={setChosenFireblock}
          getCrypto={getCrypto}
          page={page}
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          chosenFireblock={chosenFireblock}
        />
      )}
    </div>
  );
};

export default FireblockList;
