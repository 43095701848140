import {
  useWidgetFiltersState,
  useWidgetFiltesDispatch,
} from "providers/WidgetFilter";
import React, { useMemo } from "react";
import {
  setFiatName,
  setFiatSymbol,
  setFiatSuspendStatus,
  setTriggerFilter,
} from "providers/WidgetFilter/widgetFilterAction";

import FilterList from "components/Filter/FilterList";
import { fiatSecondFilterTree } from "utils/constants";

const FiatFiltersWrapper = (): JSX.Element => {
  const dispatch = useWidgetFiltesDispatch();
  const { fiat_name, fiat_symbol, fiat_is_suspended } = useWidgetFiltersState();

  const filterByName = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      dispatch(setFiatName(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterBySymbol = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      dispatch(setFiatSymbol(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterSuspendStatus = (e, setError, setIsValid) => {
    if (typeof e.target.value === "undefined") return setError("Enter a value");

    if (e.target.value.toLowerCase() === "supported") {
      dispatch(setFiatSuspendStatus(false));
    }

    if (e.target.value.toLowerCase() === "not supported") {
      dispatch(setFiatSuspendStatus(true));
    }

    setError("");
    setIsValid(true);
  };

  const secondLevelHandlers = [
    filterByName,
    filterBySymbol,
    filterSuspendStatus,
  ];

  const handleApply = () => {
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);
  };

  const resetFiatName = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFiatName(""));
    }

    dispatch(setFiatName(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetFiatSymbol = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFiatSymbol(""));
    }

    dispatch(setFiatSymbol(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetFiatSuspendStatus = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFiatSuspendStatus(undefined));
    }

    dispatch(setFiatSuspendStatus(undefined));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetHandlers = [
    resetFiatName,
    resetFiatSymbol,
    resetFiatSuspendStatus,
  ];

  const enableClearFilters = useMemo(() => {
    if (fiat_name || fiat_symbol || typeof fiat_is_suspended !== "undefined") {
      return true;
    }

    return false;
  }, [fiat_name, fiat_symbol, fiat_is_suspended]);

  return (
    <>
      <FilterList
        secondLevelHandlers={secondLevelHandlers}
        secondLevelFilter={fiatSecondFilterTree}
        handleApply={handleApply}
        enableClearFilters={enableClearFilters}
        clearSearches={resetHandlers}
        additionalHandlers={[]}
        clearAdditionalSearches={[]}
        clearSelect={() => null}
      />
    </>
  );
};

export default FiatFiltersWrapper;
