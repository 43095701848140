import { FC } from "react";
import Popover, { ArrowContainer } from "react-tiny-popover";

import { PopoverProps } from "./Popover.props";
import Button from "../Button";

const PopoverComponent: FC<PopoverProps> = ({
  position,
  component,
  children,
  className,
  arrowStyle,
  contentLocation,
  isPopoverOpen,
  setIsPopoverOpen,
  onClickOutside,
  onClick,
}) => {
  return (
    <Popover
      containerClassName={className}
      isOpen={isPopoverOpen}
      onClickOutside={() => {
        if (onClickOutside) {
          return onClickOutside();
        }

        setIsPopoverOpen(false);
      }}
      position={position}
      padding={0}
      contentLocation={contentLocation}
      content={({ position, targetRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          targetRect={targetRect}
          popoverRect={popoverRect}
          arrowSize={7}
          arrowColor={"white"}
          arrowStyle={arrowStyle}
        >
          <div className="popover__content">{children}</div>
        </ArrowContainer>
      )}
    >
      <Button
        type="button"
        className="button--transparent"
        onClick={() => {
          setIsPopoverOpen(!isPopoverOpen);
          onClick && onClick();
        }}
      >
        {component}
      </Button>
    </Popover>
  );
};

export default PopoverComponent;
