import React, { useEffect, useState, useCallback, useContext } from "react";

import {
  BitcoinConfig,
  UpdateConfig,
  FetchConfigResult,
  UpdateResult,
  ContextTypes,
} from "./types";
import { instance } from "utils/api";
import useRole from "hooks/useRole";

const BitcoinCoreContext = React.createContext<ContextTypes>({
  bitcoinState: {},
  loading: false,
  error: "",
  updateConfig: () => {
    return;
  },
});

const BitcoinCoreProvider: React.FC = ({ children }) => {
  const role = useRole();
  const [bitcoinState, setBitcoinState] = useState<BitcoinConfig>({});

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const updateConfig = useCallback<UpdateConfig>(
    (bcore, fee_rate_btc) => {
      const settings = {
        feeRate: fee_rate_btc
          ? fee_rate_btc
          : bitcoinState?.settings?.find((i) => i.name === "fee_rate_btc")
              ?.value,
        bcore: bcore
          ? bcore
          : bitcoinState?.settings?.find((i) => i.name === "bcore")?.value,
      };

      setLoading(false);

      instance
        .patch<UpdateResult>("settings/update_bitcoin_core", settings)
        .then((res) => {
          setLoading(false);
          setBitcoinState(
            bitcoinState &&
              res.data && {
                ...bitcoinState,
                settings: res.data.result,
              }
          );
        })
        .catch((e) => setError(e.message));
    },

    [bitcoinState]
  );

  useEffect(() => {
    setLoading(true);

    if (role === "super_admin") {
      instance
        .get<FetchConfigResult>("settings/get_bcore")
        .then((res) => {
          setLoading(false);
          setBitcoinState(res.data.result);
        })
        .catch((e) => setError(e.message));
    }
  }, [role]);

  return (
    <BitcoinCoreContext.Provider
      value={{
        bitcoinState,
        loading,
        error,
        updateConfig,
      }}
    >
      {children}
    </BitcoinCoreContext.Provider>
  );
};

export default BitcoinCoreProvider;

export const useBitcoinCore: () => ContextTypes = () =>
  useContext(BitcoinCoreContext);
