import { ReactNode } from "react";
import { useAuthStateContext } from "providers/Auth";

export const ROLES = {
  SUPER_ADMIN: "super_admin",
  AML: "aml",
  MARKETING: "marketing",
  FINANCE: "finance",
  READT_ONLY: "read_only",
  PARTNER: "partner",
};

function RoleProvider({
  children,
  roles,
  fallback,
}: {
  children: ReactNode;
  roles: string[];
  fallback?: ReactNode;
}): JSX.Element {
  const { user } = useAuthStateContext();

  const role = user?.role || "";

  if (role === ROLES.PARTNER) {
    return <>{children}</>;
  }

  return <>{roles.includes(role) ? children : fallback ? fallback : <></>}</>;
}

export default RoleProvider;
