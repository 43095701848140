import { StyleSheet } from "@react-pdf/renderer";

export const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  page: {
    width: "100%",
    height: "100%",
  },
  invoice_container: {
    width: "100%",
    height: "100%",
    padding: "30",
  },
  image_container: {
    width: 90,
    height: 24,
    marginBottom: 35,
  },
  image: {
    width: 90,
    height: 24,
  },
  header_container: {
    width: "100%",
    borderBottom: "1px solid #E6E6E6",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: 20,
  },
  header_left_block: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  personal_info: {
    fontSize: 20,
    fontWeight: 700,
    color: "#30313D",
    letterSpacing: "0.340402px",
    fontFamily: "SF-Pro",
  },
  address_info: {
    fontWeight: 400,
    fontSize: 10,
    color: "#30313D",
  },
  email_block: {
    display: "flex",
    alignItems: "center",
  },
  email_info: {
    fontSize: 10,
    fontWeight: 400,
    color: "#30313D",
  },
  main_content_wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  content_keys_wrapper: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
  },
  content_key: {
    fontSize: 10,
    fontWeight: 400,
    color: "#8C979F",
    fontFamily: "SF-Mono",
    marginBottom: 15,
  },
  content_values_wrapper: {
    paddingTop: 20,
    display: "flex",
    flexDirection: "column",
    marginLeft: 30,
  },
  content_value: {
    fontSize: 10,
    fontWeight: 400,
    color: "#30313D",
    fontFamily: "SF-Mono",
    marginBottom: 15,
  },
  footer_wrapper: {
    marginTop: "auto",
    borderTop: "1px solid #E6E6E6",
    paddingTop: 10,
  },
  footer_text: {
    fontSize: 10,
    fontWeight: 400,
    color: "#A3ACBA",
    lineHeight: 1.6,
  },
  payment_method_wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  payment_method_text: {
    fontSize: 10,
    fontWeight: 400,
    color: "#30313D",
    marginLeft: 5,
  },
  payment_method_icon: {
    width: 18,
    height: 12,
  },
  status_success: {
    color: "#05690D",
    fontSize: 10,
    fontWeight: 500,
  },
  status_pending: {
    color: "#A82C00",
    fontSize: 10,
    fontWeight: 500,
  },
  status_failed: {
    color: "#B3063D",
    fontSize: 10,
    fontWeight: 500,
  },
  status_info: {
    color: "#0055BC",
    fontSize: 10,
    fontWeight: 500,
  },
});
