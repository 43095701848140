import { FC } from "react";

import FireblockColumnField from "./FireblockColumnField";
import { FireblockColumnProps } from "./WidgetConsole.props";

const FireblockColumn: FC<FireblockColumnProps> = ({
  flow,
  fireblockList,
  role,
  setChosenFireblock,
  chosenFireblock,
}) => {
  return (
    <>
      {fireblockList.map((fireblock) => (
        <tr key={fireblock.id} className="user-list-tr">
          <FireblockColumnField
            flow={flow}
            setChosenFireblock={setChosenFireblock}
            chosenFireblock={chosenFireblock}
            role={role}
            fireblock={fireblock}
          />
        </tr>
      ))}
    </>
  );
};

export default FireblockColumn;
