import { FC } from "react";

import Button from "components/Button";
import BalancePopoverItem from "./BalancePopoverItem";

const BalancePopover: FC<{
  payoutStatuses: string[];
  chosenStatus: string;
  balanceStatus: string;
  setChosenStatus: (status: string) => void;
  handleSubmit: () => void;
  closePopover: () => void;
  loading: boolean;
}> = ({
  payoutStatuses,
  chosenStatus,
  balanceStatus,
  setChosenStatus,
  handleSubmit,
  closePopover,
  loading,
}): JSX.Element => {
  return (
    <div className="popover-stats__container">
      <h1 className="popover-stats__title">Status</h1>
      <div className="popover-stats__container--radio">
        {payoutStatuses?.map((item, index) => (
          <BalancePopoverItem
            key={index}
            item={item}
            balanceStatus={balanceStatus}
            chosenStatus={chosenStatus}
            setChosenStatus={setChosenStatus}
            chooseByDefault={chosenStatus !== "in review" && index === 0}
          />
        ))}
      </div>
      <div className="radio-buttons__wrapper">
        <Button
          type="button"
          disabled={loading}
          className="button-cancel"
          onClick={closePopover}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className="button-submit"
          onClick={handleSubmit}
          disabled={
            loading ||
            (balanceStatus === chosenStatus && balanceStatus !== "in review")
          }
        >
          Change
        </Button>
      </div>
    </div>
  );
};

export default BalancePopover;
