import { FilterType } from ".";
import {
  ACTION_SET_ACTION_COMMENT,
  ACTION_SET_ACTION_ID,
  ACTION_SET_ARRAY_CHECKING,
  ACTION_SET_CHECKEDONE,
  ACTION_SET_CHECKED_COUNTRY,
  ACTION_SET_CHECKED_ID,
  ACTION_SET_CLIENT_COUNT,
  ACTION_SET_CLIENT_COUNTRY,
  ACTION_SET_COUNTRY_SELECTION,
  ACTION_SET_DATEFROM,
  ACTION_SET_DATETO,
  ACTION_SET_EXPORT_CLIENT,
  ACTION_SET_FILTERS,
  ACTION_SET_FILTERS_EMAIL,
  ACTION_SET_FILTER_BUTTON,
  ACTION_SET_KYC,
  ACTION_SET_ORDERING,
  ACTION_SET_PAGE,
  ACTION_SET_SEARCH,
  ACTION_SET_LOG_SEARCH,
  ACTION_SET_SELECT_CLIENT,
  ACTION_SET_SETTINGS_TOGGLE,
  ACTION_SET_SORTING,
  ACTION_SET_CHECKED_PAGE,
  ACTION_RESET_CLIENT_FILTERS,
} from "./types";

export type actionType = {
  type: string;
  payload?: FilterType[keyof FilterType];
};

export const set_page = (clientPage: FilterType["page"]): actionType => {
  return {
    type: ACTION_SET_PAGE,
    payload: clientPage,
  };
};

export const set_search = (clientSearch: FilterType["search"]): actionType => {
  return {
    type: ACTION_SET_SEARCH,
    payload: clientSearch,
  };
};

export const set_log_search = (
  logSearch: FilterType["logSearch"]
): actionType => {
  return {
    type: ACTION_SET_LOG_SEARCH,
    payload: logSearch,
  };
};

export const set_filters = (
  clientFilter: FilterType["filters"]
): actionType => {
  return {
    type: ACTION_SET_FILTERS,
    payload: clientFilter,
  };
};

export const set_dateFrom = (
  clientDate: FilterType["dateFrom"]
): actionType => {
  return {
    type: ACTION_SET_DATEFROM,
    payload: clientDate,
  };
};

export const set_dateTo = (clientDate: FilterType["dateTo"]): actionType => {
  return {
    type: ACTION_SET_DATETO,
    payload: clientDate,
  };
};

export const set_kyc = (
  clientKYC: FilterType["kycVerification"]
): actionType => {
  return {
    type: ACTION_SET_KYC,
    payload: clientKYC,
  };
};

export const set_filtersEmail = (
  clientEmail: FilterType["filtersEmail"]
): actionType => {
  return {
    type: ACTION_SET_FILTERS_EMAIL,
    payload: clientEmail,
  };
};

export const set_checkedOne = (
  clientChecked: FilterType["checkedOne"]
): actionType => {
  return {
    type: ACTION_SET_CHECKEDONE,
    payload: clientChecked,
  };
};

export const set_sorting = (clientSort: FilterType["sorting"]): actionType => {
  return {
    type: ACTION_SET_SORTING,
    payload: clientSort,
  };
};

export const set_ordering = (
  clientOrder: FilterType["ordering"]
): actionType => {
  return {
    type: ACTION_SET_ORDERING,
    payload: clientOrder,
  };
};

export const set_clientCount = (
  visibleClientsCount: FilterType["visibleClientsCount"]
): actionType => {
  return {
    type: ACTION_SET_CLIENT_COUNT,
    payload: visibleClientsCount,
  };
};

export const set_settingsToggle = (
  settingsToggle: FilterType["settingsToggle"]
): actionType => {
  return {
    type: ACTION_SET_SETTINGS_TOGGLE,
    payload: settingsToggle,
  };
};

export const set_exportedClient = (
  exportedClient: FilterType["exportedClient"]
): actionType => {
  return {
    type: ACTION_SET_EXPORT_CLIENT,
    payload: exportedClient,
  };
};

export const set_clientCountry = (
  clientCountry: FilterType["clientCountry"]
): actionType => {
  return {
    type: ACTION_SET_CLIENT_COUNTRY,
    payload: clientCountry,
  };
};

export const set_selectedClient = (
  selectedClient: FilterType["selectedClient"]
): actionType => {
  return {
    type: ACTION_SET_SELECT_CLIENT,
    payload: selectedClient,
  };
};

export const set_arrayChecking = (
  arrayChecking: FilterType["arrayChecking"]
): actionType => {
  return {
    type: ACTION_SET_ARRAY_CHECKING,
    payload: arrayChecking,
  };
};

export const set_filteringButton = (
  filterButtonVal: FilterType["filterButtonVal"]
): actionType => {
  return {
    type: ACTION_SET_FILTER_BUTTON,
    payload: filterButtonVal,
  };
};

export const set_checkedID = ({
  checked,
  clients,
}: FilterType["checkedID"]): actionType => {
  return {
    type: ACTION_SET_CHECKED_ID,
    payload: { checked, clients },
  };
};

export const set_checkedCountry = (
  checkedCountry: FilterType["checkedCountry"]
): actionType => {
  return {
    type: ACTION_SET_CHECKED_COUNTRY,
    payload: checkedCountry,
  };
};

export const set_countrySelection = (
  countrySelection: FilterType["countrySelection"]
): actionType => {
  return {
    type: ACTION_SET_COUNTRY_SELECTION,
    payload: countrySelection,
  };
};

export const set_actionComment = (
  actionComment: FilterType["actionComment"]
): actionType => {
  return {
    type: ACTION_SET_ACTION_COMMENT,
    payload: actionComment,
  };
};

export const set_clientIdAction = (
  clientIdAction: FilterType["clientIdAction"]
): actionType => {
  return {
    type: ACTION_SET_ACTION_ID,
    payload: clientIdAction,
  };
};

export const set_checked_pages = (pages: number[] | []): actionType => {
  return {
    type: ACTION_SET_CHECKED_PAGE,
    payload: pages,
  };
};

export const reset_client_filters = (): { type: string } => ({
  type: ACTION_RESET_CLIENT_FILTERS,
});
