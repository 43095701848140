import React, { FC, ReactNode, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import { useClientDispatch } from "providers/ClientFilter";
import {
  set_filteringButton,
  set_log_search,
  set_search,
} from "providers/ClientFilter/clientsActions";
import {
  set_filteringPartner,
  set_search as set_partner_search,
} from "providers/PartnerFilter/partnerAction";
import {
  set_filteringOrder,
  set_orderSearch,
} from "providers/OrderFilter/ordersAction";
import { useOrderDispatch } from "providers/OrderFilter";
import { usePartnerDispatch } from "providers/PartnerFilter";
import NotificationsProvider from "providers/Notifications";

import { Loading } from ".";
import Input from "../Input";
import Icon from "../base/Icon";
import Navbar from "../Navbar";
import ProfileInfo from "../ProfileInfo";

interface Props {
  title: string;
  isLoading?: boolean;
  className?: string;
  signOut?: ReactNode;
  rigthButton?: ReactNode;
  action?: React.ReactNode;
}

const PageWrapper: FC<Props> = ({ isLoading, className, children, action }) => {
  const location = useLocation();
  const clientDispatch = useClientDispatch();
  const orderDispatch = useOrderDispatch();
  const partnerDispatch = usePartnerDispatch();
  const [displayNone, setDisplayNone] = useState(false);

  const changeDisplay2 = () => {
    if (!displayNone) {
      return displayNone;
    } else {
      return setDisplayNone(!displayNone);
    }
  };

  <input
    type="text"
    data-kt-user-table-filter="search"
    className="form-control form-control-solid w-250px ps-14"
    placeholder="Search user"
    onChange={(e) => {
      clientDispatch(set_search(e.target.value.toLowerCase()));
      clientDispatch(set_filteringButton(true));
    }}
  />;

  const renderSearchInput = useMemo(() => {
    const handleOrdersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_orderSearch(e.target.value));
      orderDispatch(set_filteringOrder(true));
    };

    const handleClientsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      clientDispatch(set_search(e.target.value.toLowerCase()));
      clientDispatch(set_filteringButton(true));
    };

    const handlePartnersChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      partnerDispatch(set_partner_search(e.target.value.toLowerCase()));
      partnerDispatch(set_filteringPartner(true));
    };

    const handleClientLogChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      clientDispatch(set_log_search(e.target.value.toLowerCase()));
      clientDispatch(set_filteringButton(true));
    };

    switch (true) {
      case location.pathname.includes("/users/details"):
        return (
          <Input
            placeholder="Search..."
            className="input__search"
            type="text"
            onChange={debounce(handleClientLogChange, 200)}
            icon={<Icon name="search_icon" />}
          />
        );
      case location.pathname.includes("users"):
      case location.pathname.includes("/partners/details"):
        return (
          <Input
            placeholder="Search..."
            className="input__search"
            type="text"
            onChange={debounce(handleClientsChange, 200)}
            icon={<Icon name="search_icon" />}
          />
        );
      case location.pathname.includes("/orders/buy-flow"):
      case location.pathname.includes("/orders/sell-flow"):
      case location.pathname.includes("/orders/receive-flow"):
        return (
          <Input
            placeholder="Search..."
            className="input__search"
            type="text"
            onChange={debounce(handleOrdersChange, 200)}
            icon={<Icon name="search_icon" />}
          />
        );
      case location.pathname.includes("partners"):
        return (
          <Input
            placeholder="Search..."
            className="input__search"
            type="text"
            onChange={debounce(handlePartnersChange, 200)}
            icon={<Icon name="search_icon" />}
          />
        );
      default:
        break;
    }
  }, [location, clientDispatch, orderDispatch, partnerDispatch]);

  return (
    <div>
      {/*New*/}
      <div className={`wrapper d-flex flex-column flex-row-fluid ${className}`}>
        <div
          onClick={changeDisplay2}
          style={{
            opacity: "",
          }}
        >
          <div
            id="kt_header"
            className="header dashboard__content"
            data-kt-sticky="true"
            data-kt-sticky-name="header"
            data-kt-sticky-offset="{default: '200px', lg: '300px'}"
          >
            <div
              className="container d-flex align-items-center justify-content-between"
              id="kt_header_container"
            >
              <div className="header_line">
                <div className="logo__wrapper">
                  <Icon name="logo" />
                  <span className="logo__text">Swaps</span>
                </div>
                {renderSearchInput}
                <NotificationsProvider>
                  <ProfileInfo />
                </NotificationsProvider>
                {action}
              </div>
            </div>
            <Navbar />
          </div>
          {isLoading ? <Loading /> : children}
          <div className="footer py-4 d-flex flex-lg-column" id="kt_footer">
            <div className="container d-flex flex-column flex-md-row flex-stack">
              <div className="text-dark order-2 order-md-1">
                <span className="text-gray-400 fw-bold me-1">CREATED BY</span>
                <a
                  href="https://keenthemes.com"
                  target="_blank"
                  className="text-muted text-hover-primary fw-bold me-2 fs-6"
                  rel="noreferrer"
                >
                  SWAPS
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageWrapper;
