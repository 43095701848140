import {
  forwardRef,
  ForwardRefRenderFunction,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
// import { useOrderDispatchContext, useOrderState } from "providers/Orders";
import userIcon from "assets/img/user-icon.png";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";
import { set_orderID } from "providers/OrderFilter/ordersAction";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Order } from "providers/Orders/types";

import { getTransferUrl, getWallBlockchair } from "utils/helpers";
import { Loading, Modal } from "components/base";
import useOrderEmail from "hooks/useOrderEmail";
import { ORDER_DETAILS_TABLE_ACCESS } from "../../services/roleService/order/orderDetails";
import useRole from "hooks/useRole";
import TransactionLink from "./TransactionLink";

export type OrderDetailsModalRefType = {
  open: (type: "edit" | "create") => void;
};

interface OrderDetailsModalProps {
  getOrderById: (id: string) => Promise<void>;
  order: Order | null;
}

const OrderDetailsModal: ForwardRefRenderFunction<
  OrderDetailsModalRefType,
  OrderDetailsModalProps
> = ({ getOrderById, order }, ref) => {
  // const { getOrderById } = useOrderDispatchContext();
  const orderDispatch = useOrderDispatch();
  // const { orderDetails: order } = useOrderState();
  const [isOpen, setIsOpen] = useState(false);
  const [sendEmail, setSendingEmail] = useState(false);
  const { orderID, pageName } = useOrderFilters();
  const history = useHistory();
  const { slug } = useParams<{ slug: string }>();
  const role = useRole();
  const ditailsRef = useRef<HTMLDivElement>(null);

  const closeModal = () => {
    setSendingEmail(false);
    setIsOpen(false);
    orderDispatch(set_orderID(undefined));

    if (pageName === "logs") return;

    history.push("/orders");
  };

  useImperativeHandle(ref, () => ({
    open: () => {
      setIsOpen(true);
    },
  }));

  useEffect(() => {
    if (slug) {
      getOrderById(slug);
      setIsOpen(true);
      orderDispatch(set_orderID(slug));
    }
  }, [getOrderById, slug, orderDispatch]);

  const transferUrl = order?.tx_id && getTransferUrl(order.tx_id, order.asset);

  useOrderEmail(sendEmail && order !== null ? order.id : undefined);

  const exportPDF = async () => {
    const pdf = new jsPDF("portrait", "pt", "a4");
    const data = await html2canvas(ditailsRef.current as HTMLElement);
    const img = data.toDataURL("image/png");
    const imgProperties = pdf.getImageProperties(img);
    const pdfWidth = pdf.internal.pageSize.getWidth() * 2.5;
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save(`${slug}.pdf`);
  };

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="modal-content-container order-details-modal">
        <div
          className="content d-flex flex-column flex-column-fluid"
          id="kt_content_user"
          ref={ditailsRef}
        >
          <div className="container" id="kt_content_container">
            <div className="d-flex flex-column">
              <div className="flex-column">
                {order !== null && order.id === orderID ? (
                  <div className="card card-order">
                    <div className="order--header">
                      <div className="d-flex  flex-row card-details-header">
                        <div className="symbol symbol-circle">
                          <img
                            src={userIcon}
                            alt="image"
                            width="64px"
                            height="64px"
                          />
                        </div>
                        <div className="card-details-header-content">
                          <div className="card-details-header-content-body">
                            <span className="card-details-header-content-body-name">
                              {order?.client.name
                                ? `${order.client.name} ${order.client.surname}`
                                : "-"}
                            </span>
                          </div>
                          <div className="card-details-header-content-id">
                            <Link to={`/users/details/${order?.client.id}`}>
                              {order?.client.email}
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ display: "flex" }}
                        className="order--header-button"
                      >
                        {role === "super_admin" && (
                          <button onClick={() => exportPDF()}>
                            Download PDF
                          </button>
                        )}
                        <button
                          style={{ marginLeft: "10px" }}
                          onClick={() => setSendingEmail(true)}
                        >
                          <a href={`mailto:${order?.partner?.email}`}>
                            Send via E-mail
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade show active"
                        id="kt_customer_view_overview_tab"
                        role="tabpanel"
                      >
                        <div className="card-description">
                          <div
                            id="kt_customer_view_payment_method"
                            className="card-body p-0"
                          >
                            <div
                              className="py-0"
                              data-kt-customer-payment-method="row"
                            >
                              <div
                                id="kt_customer_view_payment_method_1"
                                className="collapse show fs-6"
                                data-bs-parent="#kt_customer_view_payment_method"
                              >
                                <div className="d-flex orders__view_table">
                                  <div className="flex-equal-order">
                                    <div className="table-responsive">
                                      <table className="table table-flush fw-bold gy-1 order-table">
                                        {role && (
                                          <tbody>
                                            {ORDER_DETAILS_TABLE_ACCESS.orderId[
                                              role
                                            ] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Transaction ID
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.id}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .clientId[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  User ID
                                                </td>
                                                <td className="text-gray-600">
                                                  <Link
                                                    to={`/users/details/${order?.client?.id}`}
                                                    style={{ padding: 0 }}
                                                  >
                                                    {order?.client?.id}
                                                  </Link>
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .partnerName[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Partner Name
                                                </td>
                                                <td className="text-gray-600">
                                                  <Link
                                                    to={`/partners/details/${order?.partner?.id}`}
                                                    style={{ padding: 0 }}
                                                  >
                                                    {order?.partner?.name}
                                                  </Link>
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .partnerId[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Partner ID
                                                </td>
                                                <td className="text-gray-600">
                                                  <Link
                                                    to={`/partners/details/${order?.partner?.id}`}
                                                    style={{ padding: 0 }}
                                                  >
                                                    {order?.partner?.id}
                                                  </Link>
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .partnerEmail[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Partner Email
                                                </td>
                                                <td className="text-gray-600">
                                                  <a
                                                    href={`mailto:${order?.partner?.email}`}
                                                    style={{ padding: 0 }}
                                                  >
                                                    {order?.partner?.email}
                                                  </a>
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS.wallet[
                                              role
                                            ] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Wallet
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.asset &&
                                                    order.address && (
                                                      <a
                                                        href={getWallBlockchair(
                                                          order.asset,
                                                          order.address
                                                        )}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        style={{ padding: 0 }}
                                                      >
                                                        {order?.address}
                                                      </a>
                                                    )}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS.amount[
                                              role
                                            ] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Amount in {order?.currency}
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.amount}{" "}
                                                  {order?.currency || "-"}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .amountByAsset[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Amount in{" "}
                                                  {order?.asset === "TRC" ||
                                                  order?.asset === "ERC"
                                                    ? "USDT"
                                                    : order?.asset}
                                                </td>
                                                <td className="text-gray-600">
                                                  {`${order?.asset_quantity}  ${order?.asset}`}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .commission[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Commission
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.commission || "-"}{" "}
                                                  {order?.currency}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS.markup[
                                              role
                                            ] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Markup
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.markup}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .network_commission[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Network commission
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.network_fee || "-"}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS.rate[
                                              role
                                            ] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Rate
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.spot_price || "-"}{" "}
                                                  {order?.currency}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .wallet_exchange[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Wallet Exchange
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.wallet_exchange ||
                                                    "-"}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS.status[
                                              role
                                            ] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Status
                                                </td>
                                                <td className="text-gray-600">
                                                  {
                                                    {
                                                      paid: (
                                                        <div className="order-status-td order-status-td-success">
                                                          Successful
                                                        </div>
                                                      ),
                                                      failed: (
                                                        <div className="order-status-td order-status-td-failed">
                                                          Failed
                                                        </div>
                                                      ),
                                                      pending: (
                                                        <div className="order-status-td order-status-td-panding">
                                                          Pending
                                                        </div>
                                                      ),
                                                    }[
                                                      (order?.status as string) ||
                                                        "success"
                                                    ]
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .failed_reason[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Reason
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.failed_reason || "-"}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .payment_exchange[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Payment Exchange
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.payment_exchange ||
                                                    "-"}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .elliptic_risk_score[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Elliptic Risk score
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.wallet_risk_score ||
                                                    "-"}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .elliptic_view[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Elliptic View
                                                </td>
                                                <td className="text-gray-600">
                                                  <a
                                                    target="_blank"
                                                    href={`${order?.elliptic_view}`}
                                                    style={{ padding: 0 }}
                                                    rel="noreferrer"
                                                  >
                                                    {order?.elliptic_view}
                                                  </a>
                                                </td>
                                              </tr>
                                            )}

                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .transaction_id[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Transaction ID
                                                </td>
                                                <td className="text-gray-600">
                                                  <TransactionLink
                                                    trxId={order.transaction_id}
                                                    bank={order.bank}
                                                    id={order?.transaction_id}
                                                  />
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS.tx_id[
                                              role
                                            ] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  TX ID
                                                  <br />
                                                  {transferUrl && (
                                                    <a
                                                      href={transferUrl}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      style={{ padding: 0 }}
                                                    >
                                                      Open in Blockchair
                                                    </a>
                                                  )}
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.tx_id || "-"}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .withdrawal[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Withdrawal
                                                </td>
                                                <td className="user-status-td">
                                                  {
                                                    {
                                                      sending: (
                                                        <div className="user-status-td transaction-status-td user-status-sending">
                                                          Sending
                                                        </div>
                                                      ),
                                                      successful: (
                                                        <div className="user-status-td transaction-status-td user-status-success">
                                                          Successful
                                                        </div>
                                                      ),
                                                      not_sent: (
                                                        <div className="user-status-td transaction-status-td user-status-not-send">
                                                          Not Sent
                                                        </div>
                                                      ),
                                                      pending: (
                                                        <div className="user-status-td transaction-status-td user-status-pending">
                                                          Pending
                                                        </div>
                                                      ),
                                                    }[
                                                      order.withdrawal ||
                                                        "success"
                                                    ]
                                                  }
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .withdraw_id[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Withdrawal ID
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.withdraw_id || "-"}
                                                </td>
                                              </tr>
                                            )}
                                            {ORDER_DETAILS_TABLE_ACCESS
                                              .payment_method[role] && (
                                              <tr>
                                                <td className="text-gray-800 min-w-200px w-125px">
                                                  Payment Method
                                                </td>
                                                <td className="text-gray-600">
                                                  {order?.payment_method &&
                                                    ((order?.payment_method ===
                                                      "google" &&
                                                      "Google Pay") ||
                                                      (order?.payment_method ===
                                                        "apple" &&
                                                        "Apple Pay") ||
                                                      (order?.client_cards
                                                        ?.schema === "visa"
                                                        ? "Visa"
                                                        : "Master Card") ||
                                                      "-")}
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        )}
                                      </table>
                                    </div>
                                    <div className="flex-equal">
                                      <table className="table table-flush fw-bold gy-1"></table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Loading />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(OrderDetailsModal);
