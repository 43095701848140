/* eslint-disable @typescript-eslint/no-explicit-any */
export const ActionType = {
  SET_CRYPTO: "SET_CRYPTO",
  SET_FIREBLOCK: "SET_FIREBLOCK",
  SET_FIAT: "SET_FIAT",
  SET_LOADING: "SET_LOADING",
  SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
  SET_CRYPTO_COUNT: "SET_CRYPTO_COUNT",
  SET_FIAT_COUNT: "SET_FIAT_COUNT",
  SET_FIREBLOCK_COUNT: "SET_FIREBLOCK_COUNT",
  SET_PAGE: "SET_PAGE",
  SET_CHOSEN_FIREBLOCK: "SET_CHOSEN_FIREBLOCK",
  SET_CHOSEN_FIAT: "SET_CHOSEN_FIAT",
  SET_DATA_FETCHED: "SET_DATA_FETCHED",
  RESET_WIDGET_FILTERS: "RESET_WIDGET_FILTERS",
};

export interface FireblockProps {
  id: string;
  code: string;
  address_regex?: string;
  testnet_address_regex?: string;
  is_suspended?: boolean;
  symbol?: string;
  contractAddress?: string;
  decimals?: number;
  issuerAddress?: string;
  name: string;
  type: string;
  createdAt?: string;
  nativeAsset: string;
  markup_rate: number;
}

export interface FiatProps {
  id: string;
  is_suspended: boolean;
  max_amount: number | null;
  max_buy_amount: number | null;
  min_amount: number | null;
  min_buy_amount: number | null;
  name: string;
  sign: string;
  symbol: string;
}

export interface State {
  crypto: any[];
  dataFetched: boolean;
  fireblockList: any[];
  fiatList: any[];
  isLoading: boolean;
  activeTab: string;
  cryptoCount: number | null;
  fireblockCount: number | null;
  fiatCount: number | null;
  chosenFireblock: FireblockProps | null;
  chosenFiat: FiatProps | null;
  page: number;
}

export interface Action {
  type: string;
  payload?: any;
}

export type Dispatch = (action: Action) => void;

export type GetCryptoType = (
  page: number,
  core?: string,
  code?: string,
  symbol?: string,
  negative_markup?: number,
  is_sell_supported?: boolean
) => void;

export type GetFireblockType = (
  page: number,
  fireblock_name?: string,
  fireblock_code?: string,
  fireblock_type?: string,
  fireblock_native_asset?: string
) => void;

export type GetFiatType = (
  page: number,
  fiat_name?: string,
  fiat_symbol?: string,
  fiat_is_sell_supported?: boolean,
  min_sell_amount?: number,
  max_sell_amount?: number
) => void;

export interface CryptoProps {
  id?: string;
  address_regex: string;
  address_tag_regex?: string;
  confirmations_required?: boolean;
  is_sell_supported?: boolean;
  is_suspended?: boolean;
  markup_rate: number;
  precision?: number;
  code: string;
  name: string;
  native_asset: string;
  symbol: string;
  core: string;
  testnet_address_regex: string;
}

export interface DispatchContext {
  getCrypto: GetCryptoType;
  getFireblockList: GetFireblockType;
  getFiatList: GetFiatType;
  setActiveTab: (value: string) => void;
  resetCount: (countType: string) => void;
  setLoading: (isLoading: boolean) => void;
  paginate: ({ page, type }: { page: number; type: string }) => void;
  setChosenFireblock: (fireblock: FireblockProps | null) => void;
  updateCryptoStatus: ({
    id,
    isSuspended,
    address_regex,
    symbol,
    testnet_address_regex,
    code,
    core,
    native_asset,
    name,
    markup_rate,
    negative_markup,
  }: {
    id: string;
    isSuspended: boolean;
    address_regex: string;
    symbol: string;
    testnet_address_regex: string;
    name: string;
    code: string;
    core: string;
    native_asset: string;
    markup_rate: number;
    negative_markup?: number;
  }) => Promise<CryptoProps>;
  updateFiatStatus: ({
    id,
    isSuspended,
    is_sell_supported,
    max_amount,
    max_sell_amount,
    max_buy_amount,
    min_amount,
    min_sell_amount,
    min_buy_amount,
  }: {
    id: string;
    isSuspended: boolean;
    is_sell_supported?: boolean;
    max_amount: number;
    max_sell_amount?: number;
    max_buy_amount: number;
    min_amount: number;
    min_sell_amount?: number;
    min_buy_amount;
  }) => Promise<FiatProps>;
  setChosenFiat: (fiat: FiatProps | null) => void;
  setPage: (page: number) => void;
}
