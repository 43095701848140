import { useClientFilters } from "providers/ClientFilter";
import { FC, ReactNode } from "react";

import useRole from "hooks/useRole";

// import SortIcon from "../../../components/base/Icon/icons/sort-icon.svg";

interface Props {
  headers: {
    empty?: boolean;
    title?: string;
    w?: number | string;
    val?: string;
    content?: ReactNode;
    icon?: ReactNode;
    iconArrow?: ReactNode;
    light?: string;
    position?: string;
    sortIcon?: ReactNode;
    sorting?: ReactNode;
    className?: string;
    super_admin?: boolean;
  }[];
  pageName?: string;
  className?: string;
}

const Table: FC<Props> = ({ headers, children, className }) => {
  const { settingsToggle } = useClientFilters();
  const role = useRole();

  return (
    <div className="table-scrolling">
      <table
        className={`table align-middle table__orders dataTable no-footer ${className}`}
        id="kt_table_users"
      >
        <thead>
          <tr className="text-start user-list-header-tr">
            {headers
              .filter((header) => {
                if (settingsToggle?.length === 0) return true;

                const exists = settingsToggle?.find(
                  (element) => element === header.title
                );

                if (exists || header.empty) return true;

                return false;
              })
              .map((header, index) => {
                if (header.super_admin && role !== "super_admin") return;

                return (
                  <th key={index} className={`${header?.className as string}`}>
                    <div className="table-title-border">
                      {header.content}
                      {header.title}
                      {header?.sortIcon || header?.sorting}
                    </div>
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="text-gray-600 fw-bold">{children}</tbody>
      </table>
    </div>
  );
};

export default Table;
