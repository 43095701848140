import {
  usePartnerDispatchContext,
  usePartnerStateContext,
} from "providers/Partners";
import { useEffect, useState } from "react";

import Promotions from "components/Promotions";
import Pagination from "components/Pagination";

const limit = 10;

export default function (): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const { partnerPromotions } = usePartnerStateContext();
  const {
    getPartnerPromotions,
    reloadPromotionsList,
    addPromotion,
    updatePromotion,
  } = usePartnerDispatchContext();

  useEffect(() => {
    partnerPromotions.isLoaded || getPartnerPromotions({ limit, page });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partnerPromotions.isLoaded]);

  const handlePagination = (to: string) => {
    switch (to) {
      case "next":
        setPage(page + 1);
        reloadPromotionsList();
        setIsLoading(false);

        return;
      case "prev":
        setPage(page - 1);
        reloadPromotionsList();
        setIsLoading(false);

        return;

      default:
        return;
    }
  };

  return (
    <div>
      <Promotions
        allowNegativeNumbers={true}
        updatePromotion={updatePromotion}
        addPromotion={addPromotion}
        reloadList={reloadPromotionsList}
        list={partnerPromotions.list}
        isLoaded={partnerPromotions.isLoaded}
        types={partnerPromotions.types}
      />
      {partnerPromotions.count ? (
        <Pagination
          page={page}
          count={partnerPromotions.count}
          visibleCount={limit}
          setLoading={setIsLoading}
          isLoading={isLoading}
          handlePagination={handlePagination}
        />
      ) : null}
    </div>
  );
}
