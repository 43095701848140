import Chart from "chart.js/auto";
import moment from "moment";

import "chartjs-adapter-moment";
import { IGenerateChart } from "./types";

function generateChart({
  element,
  data,
  chartRef,
  progress,
  unit,
  aspectRatio,
}: IGenerateChart): void {
  if (!element) return;
  element.style.width = "100%";

  const ctx = element.getContext("2d") as CanvasRenderingContext2D;

  if (chartRef.current) {
    chartRef.current.destroy();
  }

  const myChart = new Chart(ctx, {
    type: "line",
    data: {
      labels: data.x,
      datasets: [
        {
          label: "Count",
          backgroundColor: "rgba(0, 0, 0, 0.0)",
          borderColor: progress >= 0 ? "#2075F5" : "#DF1B41",
          borderWidth: 1.5,
          data: data.y,
          tension: 0.1,
          pointRadius: data.x.length === 1 ? 1 : 0,
          pointHoverRadius: 3,
          fill: true,
        },
      ],
    },
    options: {
      aspectRatio: aspectRatio || 2,
      responsive: true,
      animation: {
        duration: 200,
      },
      hover: {
        mode: "index",
        intersect: false,
      },

      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0,
        },
      },

      plugins: {
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            drag: {
              enabled: true,
            },
            pinch: {
              enabled: true,
            },
            mode: "x",
          },
        },
        tooltip: {
          callbacks: {
            title: function (tooltipItem) {
              const title = tooltipItem[0].label;

              return unit === "day"
                ? moment(title).format("LL")
                : moment(title).format("LLL");
            },
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          type: "time", // Specifies the type of axis as time
          time: {
            unit: unit, // The time unit to display (can be 'day', 'week', 'month', 'year', etc.)
            displayFormats: {
              day: "MMM YYYY", // Custom display format for the time unit
              hour: "MMM D, h A",
            },
          },

          grid: {
            drawBorder: false,
            display: false, // Disable the grid lines
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,
            callback: function (value, index, arr) {
              if (index === 0 || index === arr.length - 1) {
                return value;
              }

              return "";
            },
          },
        },
        y: {
          grid: {
            display: false, // Disable the grid lines
            drawBorder: false,
          },
          ticks: {
            display: false, // This will disable the y-axis ticks
          },
          beginAtZero: false,
        },
      },
    },
  });

  chartRef.current = myChart;
}

export default generateChart;
