import { useEffect, FC, useState } from "react";
import Select from "react-select";

import { BankOptions, OptionsType } from "./types";
import useGetBank from "hooks/useBanks";

const BankSelect: FC = () => {
  const { bankResponse, changeBank, defaultVal } = useGetBank();
  const [value, setValue] = useState();

  const handleChange = async (e: BankOptions) => {
    if (!e || !e.value) return;
    const newValue = await changeBank(e.value);
    defaultVal(e.value);

    const newBank = bankResponse?.types?.find(
      (bank) => bank.value === newValue
    );

    if (!newBank) {
      setValue(newBank);
    }
  };

  useEffect(() => {
    setValue(bankResponse?.bank);
  }, [bankResponse]);

  return (
    <Select
      defaultValue={defaultVal}
      className="global-select-container"
      classNamePrefix="global-select"
      value={value}
      onChange={(e) => handleChange(e as BankOptions)}
      options={bankResponse?.types as OptionsType}
      placeholder="Select Bank"
    />
  );
};

export default BankSelect;
