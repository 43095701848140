export const SET_CORE_FILTER = "SET_CORE_FILTER";
export const SET_CODE_FILTER = "SET_CODE_FILTER";
export const SET_SYMBOL_FILTER = "SET_SYMBOL_FILTER";
export const SET_MARKUP_RATE = "SET_MARKUP_RATE";
export const SET_NEGATIVE_MARKUP_RATE = "SET_NEGATIVE_MARKUP_RATE";
export const SET_SUSPEND_STATUS = "SET_SUSPEND_STATUS";
export const SET_TRIGGER_FILTER = "SET_TRIGGER_FILTER";
export const SET_FIREBLOCK_NAME = "SET_FIREBLOCK_NAME";
export const SET_FIREBLOCK_TYPE = "SET_FIREBLOCK_TYPE";
export const SET_FIREBLOCK_NATIVE_ASSET = "SET_FIREBLOCK_NATIVE_ASSET";
export const SET_FIREBLOCK_CODE = "SET_FIREBLOCK_CODE";
export const SET_FIAT_NAME = "SET_FIAT_NAME";
export const SET_FIAT_SYMBOL = "SET_FIAT_SYMBOL";
export const SET_FIAT_SUSPEND_STATUS = "SET_FIAT_SUSPEND_STATUS";
export const SET_FIAT_SELL_STATUS = "SET_FIAT_SELL_STATUS";
export const SET_SELL_STATUS = "SET_SELL_STATUS";
export const SET_MIN_SELL_AMOUNT = "SET_MIN_SELL_AMOUNT";
export const SET_MAX_SELL_AMOUNT = "SET_MAX_SELL_AMOUNT";
export const RESET_FILTERS = "RESET_FILTERS";

export type FilterType = {
  core: string;
  code: string;
  symbol: string;
  markup_rate: number | undefined;
  negative_markup: number | undefined;
  is_suspended: boolean | undefined;
  is_sell_supported: boolean | undefined;
  trigger_filter: boolean;
  fireblock_name: string;
  fireblock_native_asset: string;
  fireblock_code: string;
  fireblock_type: string;
  fiat_name: string;
  fiat_symbol: string;
  fiat_is_suspended: boolean | undefined;
  fiat_is_sell_supported: boolean | undefined;
  min_sell_amount: number | undefined;
  max_sell_amount: number | undefined;
};

export type actionType = {
  type: string;
  payload: FilterType[keyof FilterType];
};
