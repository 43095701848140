import React, { FC, useEffect, useMemo, useRef } from "react";
import {
  usePartnerDispatchContext,
  usePartnerStateContext,
} from "providers/Partners";
import { usePartnerDispatch, usePartnerFilters } from "providers/PartnerFilter";
import {
  set_checkedIDPartner,
  set_checked_pages,
  set_filteringPartner,
  set_page,
  set_search,
} from "providers/PartnerFilter/partnerAction";

import { Icon, PageWrapper, Table } from "components/base";
import PartnersModal, {
  PartnersModalRefType,
} from "components/modals/PartnersModal";
import SortIcon from "../../components/base/Icon/icons/sort-icon.svg";
import PartnersColumn from "./PartnersColumn";
import NotFound from "pages/NotFound";
import PartnerFiltersWrapper from "./PartnerFiltersWrapper";
import Pagination from "components/Pagination";
import Button from "components/Button";
import TableSkeleton from "components/TableSkeleton";

let timer: ReturnType<typeof setTimeout>;

const Partners: FC = () => {
  const { getPartners, getPartnerById, downloadPartners, setLoading } =
    usePartnerDispatchContext();

  const partnerDispatch = usePartnerDispatch();
  const {
    partnerSearch,
    pagePartner,
    visiblePartnersCount,
    sortingPartner,
    orderingPartner,
    exportedPartner,
    checkedIDPartner,
    markupFrom,
    markupTo,
    commissionFrom,
    commissionTo,
    arrayCheckingPartner,
    dateFromPartner,
    dateToPartner,
    partnerFilter,
    filteringPartner,
    checkedPages,
  } = usePartnerFilters();

  const { isLoading, partnersCount, partners } = usePartnerStateContext();

  // refs
  const modalRef = useRef<PartnersModalRefType>(null);
  // states

  const selectUser = useMemo(() => {
    const partnerIDS = partners?.map((partner) => partner.id);

    const compoundID = [...(exportedPartner as string[]), ...partnerIDS];

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        partnerDispatch(
          set_checkedIDPartner({
            checked: e.target.checked,
            partners: compoundID,
          })
        );

        partnerDispatch(set_checked_pages([...checkedPages, pagePartner]));

        return;
      }

      partnerDispatch(
        set_checkedIDPartner({
          checked: e.target.checked,
          partners: exportedPartner?.filter(
            (item) => !partnerIDS.includes(item)
          ) as string[],
        })
      );

      partnerDispatch(
        set_checked_pages(checkedPages.filter((page) => page !== pagePartner))
      );
    };

    return (
      <input
        onChange={handleChange}
        type="checkbox"
        className="form-check-input user-list-checkbox"
        checked={(checkedPages as number[])?.includes(pagePartner)}
      />
    );
  }, [partnerDispatch, checkedPages, exportedPartner, pagePartner, partners]);

  const sortIcon = useMemo(() => {
    return (
      <label className="user-list-sort">
        <img src={SortIcon} alt="sort user list" width="8px" height="12px" />
      </label>
    );
  }, []);

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (filteringPartner === false) return;

      if (filteringPartner) {
        getPartners(
          pagePartner,
          partnerSearch,
          markupFrom,
          markupTo,
          commissionFrom,
          commissionTo,
          dateFromPartner,
          dateToPartner,
          partnerFilter,
          visiblePartnersCount,
          sortingPartner,
          orderingPartner
        );

        return;
      }

      getPartners(
        pagePartner,
        partnerSearch,
        undefined,
        undefined,
        undefined,
        undefined,
        null,
        null,
        undefined,
        visiblePartnersCount,
        sortingPartner,
        orderingPartner
      );
    }, 450);

    return () => {
      clearTimeout(timer);
    };
  }, [
    commissionFrom,
    commissionTo,
    dateFromPartner,
    dateToPartner,
    filteringPartner,
    getPartners,
    markupFrom,
    markupTo,
    orderingPartner,
    pagePartner,
    partnerFilter,
    partnerSearch,
    sortingPartner,
    visiblePartnersCount,
  ]);

  const editPressed = (id: string) => {
    modalRef.current?.open("edit");

    if (id) {
      getPartnerById(id);
    }
  };

  const clearSearch = () => partnerDispatch(set_search(""));

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        partnerDispatch(set_filteringPartner(true));
        partnerDispatch(set_page(pagePartner + 1));

        return;
      case "prev":
        partnerDispatch(set_filteringPartner(true));
        partnerDispatch(set_page(pagePartner - 1));

        return;
      default:
        return;
    }
  };

  return (
    <PageWrapper
      // action={AddPartnerButton}
      title="Partners list"
    >
      <div
        className="content d-flex flex-column flex-column-fluid order-table"
        id="kt_content"
      >
        <div className="container" id="kt_content_container">
          <div className="button-export__wrapper">
            <h1 className="page-title">Partner List</h1>
            <Button
              type="button"
              className="button--transparent"
              onClick={() => location.reload()}
            >
              <Icon name="rotate" />
            </Button>

            <Button
              type="button"
              className="button--transparent button--export"
              disabled={!exportedPartner?.length}
              onClick={() => {
                exportedPartner?.length &&
                  downloadPartners(
                    visiblePartnersCount,
                    sortingPartner,
                    orderingPartner,
                    exportedPartner,
                    checkedIDPartner,
                    partners,
                    arrayCheckingPartner,
                    partnerSearch,
                    dateFromPartner,
                    dateToPartner,
                    markupFrom,
                    markupTo,
                    commissionFrom,
                    commissionTo,
                    partnerFilter
                  );
              }}
            >
              <Icon name="export_button" />
              Export Selected
            </Button>
            <Button
              type="button"
              className="button--transparent button--export"
              onClick={() => {
                downloadPartners(
                  visiblePartnersCount,
                  "",
                  undefined,
                  undefined,
                  checkedIDPartner,
                  undefined,
                  undefined,
                  partnerSearch,
                  dateFromPartner,
                  dateToPartner,
                  markupFrom,
                  markupTo,
                  commissionFrom,
                  commissionTo,
                  partnerFilter
                );
              }}
            >
              <Icon name="export_button" />
              Export
            </Button>
            <Button
              type="button"
              onClick={() => modalRef.current?.open("create")}
              className="button-primary button-add-partner"
            >
              Add partner
            </Button>
          </div>
          <div className="card user-list-row">
            <PartnerFiltersWrapper />
            {partners.length === 0 && !isLoading ? (
              <NotFound />
            ) : (
              <>
                <div className="table--row">
                  <div className="user-list-body partner--table">
                    {isLoading ? (
                      <TableSkeleton />
                    ) : (
                      <Table
                        headers={[
                          {
                            empty: true,
                            w: "fit-content",
                            content: selectUser,
                          },
                          {
                            title: "Partner Id",
                            w: "fit-content",
                            icon: sortIcon,
                            className: "th-partner-id",
                          },
                          {
                            title: "",
                            val: "id_clipboard",
                            icon: sortIcon,
                          },
                          {
                            title: "Partner name",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Contact Name",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "E-mail",
                            w: "fit-content",
                            icon: sortIcon,
                            className: "partner-email",
                          },
                          {
                            title: "",
                            val: "email_clipboard",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Support email",
                            w: "fit-content",
                            icon: sortIcon,
                            className: "partner-email",
                          },
                          {
                            title: "",
                            val: "email_clipboard",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Commission",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Processing Fee",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Sell Process Fee",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Sell Commission",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Sepa Commission",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Sell Status",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            empty: true,
                            w: "fit-content",
                          },
                          {
                            title: "Receive Status",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            empty: true,
                            w: "fit-content",
                          },
                          {
                            title: "Sepa Status",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            empty: true,
                            w: "fit-content",
                          },
                          { title: "STATUS", w: "fit-content", icon: sortIcon },
                          {
                            title: "Tier Zero",
                            w: "fit-content",
                            className: "partner-email",
                            icon: sortIcon,
                          },
                          {
                            title: "",
                            w: "fit-content",
                          },
                          {
                            title: "INTEGRATION REQUEST DATE",
                            w: 275,
                            icon: sortIcon,
                          },
                          { title: "Action", w: 100 },
                          {
                            empty: true,
                            w: 50,
                          },
                        ]}
                      >
                        <PartnersColumn editPressed={editPressed} />
                      </Table>
                    )}
                  </div>
                </div>
                <Pagination
                  page={pagePartner}
                  count={partnersCount}
                  visibleCount={+visiblePartnersCount}
                  clearSearch={clearSearch}
                  setLoading={setLoading}
                  isLoading={isLoading}
                  handlePagination={handlePagination}
                />
              </>
            )}
          </div>
        </div>
      </div>
      <PartnersModal ref={modalRef} />
    </PageWrapper>
  );
};

export default Partners;
