import { EventTypes } from "pages/WebhookCreate/SelectEvent/SelectEvent.props";

export const ActionType = {
  SET_EVENT: "SET_EVENT",
  SET_EVENT_TYPES: "SET_EVENT_TYPES",
  SET_ACTIVE_ACCORDIONS: "SET_ACTIVE_ACCORDIONS",
  SET_SEARCH_TERM: "SET_SEARCH_TERM",
  SET_WEBHOOK_ENDPOINT: "SET_WEBHOOK_ENDPOINT",
};

export interface State {
  selectedEvents: string[];
  eventTypes: EventTypes | undefined;
  activeAccordions: string[];
  searchTerm: string;
  webhookEndpoint: string;
}

export interface Action {
  type: string;
  payload?: string | string[] | EventTypes;
}

export type Dispatch = (action: Action) => void;
