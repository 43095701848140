import { FC, useState, useMemo, useCallback, useEffect } from "react";
import moment from "moment";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";

import Popover from "../../Popover";
import { FilterItemProps } from "./FilterItem.props";
import Button from "../../Button";
import Icon from "../../base/Icon";
import FilterDropDown from "../FilterDropDown";
import { sellTransactionStatuses } from "utils/constants";

const FilterItem: FC<FilterItemProps> = ({
  filterItem,
  clearSelect,
  secondLevelHandler,
  handleApply,
  resetSearch,
  initialValue,
  isDisabled,
}) => {
  const { statsFilter, orderSearch, statusId, email } = useOrderFilters();
  const orderDispatch = useOrderDispatch();
  const [localValue, setLocalValue] = useState<
    string | { [key: string]: string | number }
  >(initialValue || "");

  const [assetValue, setAssetValue] = useState<string>("");

  const [isValid, setIsValid] = useState(false);

  const [numberRange, setNumberRange] = useState<{
    numberFrom: string;
    numberTo: string;
  }>({
    numberFrom: "",
    numberTo: "",
  });

  const [valueCompleted, setValueCompleted] = useState(false);
  const [date, setDate] = useState<{
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
  }>({
    startDate: null,
    endDate: null,
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const handleClick = () => {
    if (
      isPopoverOpen &&
      (localValue ||
        Object.values(numberRange).filter((item) => item)?.length) &&
      !valueCompleted
    ) {
      setTimeout(() => {
        if (!isDisabled) {
          setLocalValue("");
          setAssetValue("");
          setDate({
            startDate: null,
            endDate: null,
          });
        }
      }, 400);
    }

    setIsPopoverOpen(!isPopoverOpen);
  };

  const onClickOutsideCheck = useCallback(() => {
    if (filterItem?.type === "number_range") {
      if (!numberRange?.numberFrom || !numberRange?.numberTo || !isValid) {
        resetSearch(true, true);
        setNumberRange({
          numberFrom: "",
          numberTo: "",
        });

        return;
      }
    }

    if (filterItem?.type === "input") {
      if (!localValue) {
        resetSearch(true, true);
        setLocalValue("");
        setAssetValue("");

        return;
      }

      setIsPopoverOpen(false);
    }

    if (filterItem?.type === "date_picker") {
      if (
        !date?.startDate ||
        !date?.endDate ||
        date?.endDate < date?.startDate
      ) {
        resetSearch(true, true);

        setDate({
          startDate: null,
          endDate: null,
        });

        return;
      }

      setIsPopoverOpen(false);
    }
  }, [filterItem?.type, date, numberRange, localValue, resetSearch, isValid]);

  const handleClickOutside = () => {
    onClickOutsideCheck();
    if (valueCompleted) return setIsPopoverOpen(false);
    setTimeout(() => {
      if (!isDisabled) {
        setLocalValue("");
        setAssetValue("");
        setDate({
          startDate: null,
          endDate: null,
        });
        resetSearch(false, true);
        setNumberRange({
          numberFrom: "",
          numberTo: "",
        });
      }
    }, 400);

    setIsPopoverOpen(false);
  };

  const renderIcon = useMemo(() => {
    switch (filterItem?.type) {
      case "date_picker":
        return !!date?.startDate;
      case "input":
      case "number_input":
        return !!localValue;
      case "select":
        return !!localValue;
      case "number_range":
        return Object.values(numberRange).filter((item) => item)?.length;
    }
  }, [filterItem?.type, localValue, date, numberRange]);

  const renderLocalValue = useMemo(() => {
    if (filterItem?.type === "number_range") {
      return localValue as { [key: string]: string };
    }

    return localValue as string;
  }, [filterItem, localValue]);

  useEffect(() => {
    if (statsFilter && filterItem?.value === "USER_EMAIL") {
      if (email) {
        setLocalValue(email);
      } else {
        setLocalValue(orderSearch);
      }
    }

    if (statsFilter && filterItem?.value === "TRANSACTION_STATUS") {
      const transactionStatus = Object.entries(sellTransactionStatuses).find(
        ([, value]) => value === statusId
      );

      if (transactionStatus?.length) {
        setLocalValue(transactionStatus[0] as string);
      }
    }
  }, [statsFilter, orderDispatch, orderSearch, filterItem, statusId, email]);

  useEffect(() => {
    if (
      !isPopoverOpen &&
      parseFloat(numberRange.numberTo) < parseFloat(numberRange.numberFrom)
    ) {
      setNumberRange({
        numberFrom: "",
        numberTo: "",
      });
      resetSearch();
    }
  }, [isPopoverOpen, numberRange, resetSearch]);

  return (
    <Popover
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      position={["bottom"]}
      className="filter__popover"
      arrowStyle={{ left: "20px" }}
      onClickOutside={handleClickOutside}
      component={
        <Button
          type="button"
          className={`filter__button ${
            (localValue || date?.startDate || numberRange?.numberFrom) &&
            "active"
          }`}
          onClick={handleClick}
        >
          {!renderIcon ? (
            <Icon name="filter_add" />
          ) : (
            <Button
              disabled={isDisabled}
              type="button"
              className="button--transparent filter__button--remove"
              onClick={(e) => {
                e?.stopPropagation();
                setLocalValue("");
                setAssetValue("");
                setDate({
                  startDate: null,
                  endDate: null,
                });
                setNumberRange({
                  numberFrom: "",
                  numberTo: "",
                });

                resetSearch();
                setTimeout(() => {
                  clearSelect();
                }, 500);
              }}
            >
              <Icon name="filter_remove_text" />
            </Button>
          )}

          {filterItem?.label}
          {filterItem?.type === "date_picker" && date?.startDate && (
            <>
              <div className="filter__button-separator"></div>
              <span className="filter__button-text--inner">
                {moment(date?.startDate).format("MM/DD/YYYY")}
                {date?.endDate &&
                  " - " + moment(date?.endDate).format("MM/DD/YYYY")}
              </span>
              <Icon name="filter_chevron" />
            </>
          )}
          {filterItem?.type !== "date_picker" &&
            filterItem?.type !== "number_range" &&
            localValue && (
              <>
                <div className="filter__button-separator"></div>
                <span className="filter__button-text--inner">{localValue}</span>
                <Icon name="filter_chevron" />
              </>
            )}
          {filterItem?.type === "number_range" && numberRange?.numberFrom && (
            <>
              <div className="filter__button-separator"></div>
              <span className="filter__button-text--inner">
                {numberRange?.numberFrom}
                {numberRange?.numberTo && " - " + numberRange?.numberTo}
              </span>
              <Icon name="filter_chevron" />
            </>
          )}
        </Button>
      }
      contentLocation={(popoverState) => {
        return {
          top: popoverState.nudgedTop,
          left: popoverState.targetRect.left,
        };
      }}
    >
      <FilterDropDown
        isValid={isValid}
        setIsValid={setIsValid}
        type={filterItem.type}
        value={filterItem.value}
        header={`Filter by ${filterItem.label}`}
        selectOptions={filterItem?.selectOptions || []}
        secondLevelHandler={secondLevelHandler}
        handleApply={(type?: string) => {
          handleApply(type);
          setIsPopoverOpen(false);
        }}
        localValue={renderLocalValue}
        setLocalValue={setLocalValue}
        setNumberRange={setNumberRange}
        numberRange={numberRange}
        date={date}
        setDate={setDate}
        setIsPopoverOpen={setIsPopoverOpen}
        setValueCompleted={setValueCompleted}
        assetValue={assetValue}
        setAssetValue={setAssetValue}
      />
    </Popover>
  );
};

export default FilterItem;
