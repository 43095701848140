import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import PromotionsModal from "components/modals/PromotionsAddModal";
import PromotionUpdateModal from "components/modals/PromotionUpdateModal";
import { Icon } from "components/base";
// import SortIcon from "components/base/Icon/icons/sort-icon.svg";
import NotFound from "pages/NotFound";
import Button from "components/Button";
import TableSkeleton from "components/TableSkeleton";
import { IPromotions } from "./types";

import "./styles.scss";

const StatusSpecifier = ({ active }: { active: boolean }) => (
  <p className={active ? "promotion-active" : "promotion-inactive"}>
    {active ? "Active" : "Inactive"}
  </p>
);

const Promotions = ({
  list,
  isLoaded,
  reloadList,
  types,
  addPromotion,
  updatePromotion,
  allowNegativeNumbers,
}: IPromotions): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editablePromotion, setEditablePromotion] = useState("");

  return (
    <>
      <PromotionUpdateModal
        promotion={list.find((el) => el.id === editablePromotion) || list[0]}
        updatePromotion={updatePromotion}
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
      />
      {isLoaded && types[0].id && (
        <PromotionsModal
          allowNegativeNumbers={allowNegativeNumbers}
          addPromotion={addPromotion}
          isOpen={isModalOpen}
          promotionTypes={types.map((el) => ({
            value: el.id,
            label: el.desc,
          }))}
          onClose={() => setIsModalOpen(false)}
        />
      )}
      <div
        className="content d-flex flex-column flex-column-fluid order-table"
        id="kt_content"
      >
        <div className="button-export__wrapper">
          <h1 className="page-title">Promotions List</h1>
          <Button
            type="button"
            className="button--transparent"
            onClick={reloadList}
          >
            <Icon name="rotate" />
          </Button>
          <RoleProvider roles={[ROLES.SUPER_ADMIN, ROLES.MARKETING]}>
            <Button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className="button-primary button-add-partner"
            >
              Add Promotion
            </Button>
          </RoleProvider>
        </div>

        {list.length === 0 && isLoaded ? (
          <NotFound />
        ) : (
          <>
            <div className="promotions-container">
              {!isLoaded ? (
                <TableSkeleton />
              ) : (
                <table className="promotions-table">
                  <thead>
                    <tr>
                      <th>Promotion ID</th>
                      <th>Promotion Description</th>
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Fee Reduction(CARD)</th>
                      <th>Fee Reduction(BANK)</th>
                      <th>Buy Flow</th>
                      <th>Sell Flow</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list.map((el) => (
                      <tr key={el.id}>
                        <td className="table-id-link td-partner-id">
                          <div>
                            <Link to={`/promotion/${el.id}`}>{el.id}</Link>
                          </div>
                        </td>
                        <td>{el.desc}</td>
                        <td>
                          {moment(el.start_date).format(" DD MMM YYYY HH:mm")}
                        </td>
                        <td>
                          {moment(el.end_date).format(" DD MMM YYYY HH:mm")}
                        </td>
                        <td>{el.fee_reduction_bank_tr}</td>
                        <td>{el.fee_reduction_instant_bank}</td>
                        <td>
                          <StatusSpecifier active={el.buy_flow} />
                        </td>
                        <td>
                          <StatusSpecifier active={el.sell_flow} />
                        </td>
                        <td>
                          <StatusSpecifier active={!el.ended} />
                        </td>
                        <td>
                          <p
                            className={`promotion-edit ${
                              el.ended ? "promotion-disabled" : ""
                            }`}
                            onClick={() => {
                              setIsEditModalOpen(true);
                              setEditablePromotion(el.id);
                            }}
                          >
                            Edit
                          </p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Promotions;
