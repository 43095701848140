export const ACTION_SET_PAGE = "ACTION_SET_PAGE";
export const ACTION_SET_SEARCH = "ACTION_SET_SEARCH";
export const ACTION_SET_FILTERS = "ACTION_SET_FILTERS";
export const ACTION_SET_DATEFROM = "ACTION_SET_DATEFROM";
export const ACTION_SET_DATETO = "ACTION_SET_DATETO";
export const ACTION_SET_MARKUP_FROM = "ACTION_SET_MARKUP_FROM";
export const ACTION_SET_MARKUP_TO = "ACTION_SET_MARKUP_TO";
export const ACTION_SET_COMMISSION_FROM = "ACTION_SET_COMMISSION_FROM";
export const ACTION_SET_COMMISSION_TO = "ACTION_SET_COMMISSION_TO";
export const ACTION_SET_SORTING = "ACTION_SET_SORTING";
export const ACTION_SET_ORDERING = "ACTION_SET_ORDERING";
export const ACTION_SET_PARTNER_COUNT = "ACTION_SET_PARTNER_COUNT";
export const ACTION_SET_SETTINGS_TOGGLE = "ACTION_SET_SETTINGS_TOGGLE";
export const ACTION_SET_EXPORT_PARTNER = "ACTION_SET_EXPORT_PARTNER";
export const ACTION_SET_CHECKED_ID_PARTNER = "ACTION_SET_CHECKED_ID_PARTNER";
export const ACTION_SET_SELECTED_ID_PARTNER = "ACTION_SET_SELECTED_ID_PARTNER";
export const ACTION_SET_ARRAY_CHECK_PARTNER = "ACTION_SET_ARRAY_CHECK_PARTNER";
export const ACTION_SET_PARTNER_ID = "ACTION_SET_PARTNER_ID";
export const ACTION_SET_FILTER_PARTNER = "ACTION_SET_FILTER_PARTNER";
export const ACTION_SET_MODAL_OPEN = "ACTION_SET_MODAL_OPEN";
export const ACTION_SET_PARTNER_OPTION = "ACTION_SET_PARTNER_OPTION";
export const ACTION_SET_CHECKED_PAGES = "ACTION_SET_CHECKED_PAGES";
export const ACTION_RESET_PARTNER_FILTERS = "ACTION_RESET_PARTNER_FILTERS";
