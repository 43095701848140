import { ACTION_SET_CHECKED_PAGE } from "providers/OrderFilter/types";

import { FilterType } from ".";
import { actionType } from "./clientsActions";
import {
  ACTION_SET_ACTION_COMMENT,
  ACTION_SET_ACTION_ID,
  ACTION_SET_ARRAY_CHECKING,
  ACTION_SET_CHECKEDONE,
  ACTION_SET_CHECKED_COUNTRY,
  ACTION_SET_CHECKED_ID,
  ACTION_SET_CLIENT_COUNT,
  ACTION_SET_CLIENT_COUNTRY,
  ACTION_SET_COUNTRY_SELECTION,
  ACTION_SET_DATEFROM,
  ACTION_SET_DATETO,
  ACTION_SET_EXPORT_CLIENT,
  ACTION_SET_FILTERS,
  ACTION_SET_FILTERS_EMAIL,
  ACTION_SET_FILTER_BUTTON,
  ACTION_SET_KYC,
  ACTION_SET_LOG_SEARCH,
  ACTION_SET_ORDERING,
  ACTION_SET_PAGE,
  ACTION_SET_SEARCH,
  ACTION_SET_SELECT_CLIENT,
  ACTION_SET_SETTINGS_TOGGLE,
  ACTION_SET_SORTING,
  ACTION_RESET_CLIENT_FILTERS,
} from "./types";

const toggleHandler = (state, action) => {
  const newState = { ...state };
  const index = newState?.settingsToggle?.findIndex(
    (item) => item === action?.payload
  );

  if (index > -1) {
    newState?.settingsToggle.splice(index, 1);

    return { ...newState };
  }

  newState?.settingsToggle.push(action.payload);

  return { ...newState };
};

const exportedClientsHandler = (state, action) => {
  let filteredClients = state.exportedClient;

  if (state.exportedClient.includes(action?.payload)) {
    filteredClients = state.exportedClient.filter(
      (item) => item !== action?.payload
    );
  } else {
    filteredClients = [...state.exportedClient, action.payload];
  }

  return {
    ...state,
    exportedClient: filteredClients,
  };
};

const handleArrayChecking = (state, payload) => {
  return {
    ...state,
    arrayChecking: state.arrayChecking?.includes(payload)
      ? state.arrayChecking
      : [...state.arrayChecking, payload],
  };
};

const countryHandler = (state, action) => {
  const newState = { ...state };
  const array = [...newState.countrySelection];

  newState.countrySelection = array;
  const index = newState?.countrySelection?.findIndex(
    (item) => item === action.payload
  );

  if (index > -1) {
    newState?.countrySelection?.splice(index, 1);

    return { ...newState };
  }

  newState?.countrySelection.push(action.payload);

  return { ...newState };
};

const setClients = (
  state,
  payload: { checked: boolean; clients: string[] }
) => {
  return {
    ...state,
    checkedID: { checked: payload?.checked, clients: payload?.clients },
    exportedClient: payload?.checked ? payload.clients : [],
    // arrayChecking: payload?.checked ? payload.clients : [],
  };
};

const resetFilters = (state) => {
  return {
    ...state,
    page: 1,
    search: "",
    logSearch: "",
    filters: undefined,
    dateFrom: null,
    dateTo: "",
    kycVerification: null,
    filtersEmail: undefined,
    checkedOne: false,
    sorting: "",
    ordering: undefined,
    clientCountry: [],
    settingsToggle: [],
    exportedClient: [],
    checkedID: { checked: false, clients: [] },
    checkedCountry: [],
    actionComment: "",
    clientIdAction: "",
    selectedClient: "",
    arrayChecking: [],
    filterButtonVal: undefined,
    countrySelection: [],
    checkedPages: [],
  };
};

const clientReducer = (
  state: FilterType,
  action: actionType
): Record<string, unknown> => {
  switch (action.type) {
    case ACTION_SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case ACTION_SET_SEARCH:
      return {
        ...state,
        search: action.payload,
      };
    case ACTION_SET_LOG_SEARCH:
      return {
        ...state,
        logSearch: action.payload,
      };
    case ACTION_SET_FILTERS:
      return {
        ...state,
        filters: {
          status: action.payload,
        },
      };
    case ACTION_SET_DATEFROM:
      return {
        ...state,
        dateFrom: action.payload,
      };
    case ACTION_SET_DATETO:
      return {
        ...state,
        dateTo: action.payload,
      };
    case ACTION_SET_KYC:
      return {
        ...state,
        kycVerification: action.payload,
      };
    case ACTION_SET_FILTERS_EMAIL:
      return {
        ...state,
        filtersEmail: action.payload,
      };
    case ACTION_SET_CHECKEDONE:
      return {
        ...state,
        checkedOne: action.payload,
      };
    case ACTION_SET_CHECKED_ID:
      return setClients(
        state,
        action.payload as { checked: boolean; clients: string[] }
      );
    case ACTION_SET_CHECKED_COUNTRY:
      return {
        ...state,
        checkedCountry: action.payload,
      };
    case ACTION_SET_SORTING:
      return {
        ...state,
        sorting: action.payload,
      };
    case ACTION_SET_ORDERING:
      return {
        ...state,
        ordering: action.payload,
      };
    case ACTION_SET_CLIENT_COUNT:
      return {
        ...state,
        visibleClientsCount: action.payload,
      };
    case ACTION_SET_SELECT_CLIENT:
      return {
        ...state,
        selectedClient: action.payload,
      };
    case ACTION_SET_ARRAY_CHECKING:
      return handleArrayChecking(state, action.payload as string);
    case ACTION_SET_FILTER_BUTTON:
      return {
        ...state,
        filterButtonVal: action.payload,
      };
    case ACTION_SET_ACTION_COMMENT:
      return {
        ...state,
        actionComment: action.payload,
      };
    case ACTION_SET_ACTION_ID:
      return {
        ...state,
        clientIdAction: action.payload,
      };
    case ACTION_SET_CHECKED_PAGE:
      return {
        ...state,
        checkedPages: action.payload,
      };
    case ACTION_SET_SETTINGS_TOGGLE:
      return toggleHandler(state, action);
    case ACTION_SET_COUNTRY_SELECTION:
      return countryHandler(state, action);
    case ACTION_SET_EXPORT_CLIENT:
      return exportedClientsHandler(state, action);
    case ACTION_SET_CLIENT_COUNTRY:
      return {
        ...state,
        clientCountry: (action.payload as string[])?.length
          ? [action.payload]
          : [],
      };
    case ACTION_RESET_CLIENT_FILTERS:
      return resetFilters(state);
    default:
      return state;
  }
};

export default clientReducer;
