import { State, Action, ActionType } from "./types";

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SET_CRYPTO:
      return {
        ...state,
        crypto: payload,
      };
    case ActionType.SET_FIREBLOCK:
      return {
        ...state,
        fireblockList: payload,
      };
    case ActionType.SET_FIAT:
      return {
        ...state,
        fiatList: payload,
      };
    case ActionType.SET_CRYPTO_COUNT:
      return {
        ...state,
        cryptoCount: payload,
      };
    case ActionType.SET_FIAT_COUNT:
      return {
        ...state,
        fiatCount: payload,
      };
    case ActionType.SET_FIREBLOCK_COUNT:
      return {
        ...state,
        fireblockCount: payload,
      };
    case ActionType.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: payload,
      };
    case ActionType.SET_PAGE:
      return {
        ...state,
        page: payload,
      };
    case ActionType.SET_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case ActionType.SET_CHOSEN_FIREBLOCK:
      return {
        ...state,
        chosenFireblock: payload,
      };
    case ActionType.SET_CHOSEN_FIAT:
      return {
        ...state,
        chosenFiat: payload,
      };
    case ActionType.RESET_WIDGET_FILTERS:
      return {
        ...state,
        isLoading: false,
        activeTab: "crypto_list",
        crypto: [],
        fireblockList: [],
        fiatList: [],
        cryptoCount: null,
        fiatCount: null,
        fireblockCount: null,
        chosenFireblock: null,
        chosenFiat: null,
        page: 1,
      };
    default:
      return state;
  }
};

export default reducer;
