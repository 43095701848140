import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { duotoneLight } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useState } from "react";

import { Icon } from "components/base";

const CodeBlock = ({ code }: { code: unknown }): JSX.Element => {
  const [expandCode, setExpandCode] = useState(false);
  const lines = JSON.stringify(code, null, 5).split("\n")?.length;

  const toggle = () => setExpandCode((prev) => !prev);

  return (
    <div className="event-details__code-wrapper">
      <div
        className={`event-details__code-block--inner ${
          expandCode ? "active" : ""
        }`}
      >
        <SyntaxHighlighter
          language="json"
          style={duotoneLight}
          showLineNumbers
          wrapLongLines={true}
        >
          {JSON.stringify(code, null, 5)}
        </SyntaxHighlighter>
        <div className="event-details__code-blur"></div>
      </div>
      <button className={expandCode ? "active" : ""} onClick={toggle}>
        <Icon name="code_chevron" />
        {expandCode ? "Hide Lines" : `See all the ${lines} lines`}
      </button>
    </div>
  );
};

export default CodeBlock;
