import { useAuthDispatchContext } from "providers/Auth";
import { FC, useEffect, useState, useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";

import TwoFactorAuthentication from ".";

const AddYouPhone: FC = () => {
  const history = useHistory();
  const [error, setError] = useState(false);
  const [phone, setPhone] = useState("");
  const { setPhone: setPhoneNumber } = useAuthDispatchContext();

  useEffect(() => {
    if (!phone) return;
    const re = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/;
    setError(!re.test(phone));
  }, [phone]);

  const onSaveNumber = useCallback(async () => {
    if (!phone) return setError(true);
    await setPhoneNumber(phone);
    history.push("/code-verification");
  }, [history, phone, setPhoneNumber]);

  const renderError = useMemo(() => {
    if (error) {
      if (!phone) {
        return "Phone is required";
      }

      return "Not valid phone";
    }

    return;
  }, [error, phone]);

  return (
    <TwoFactorAuthentication>
      <div className="tfa-card">
        <div className="tfa-card-header">
          <h6 className="tfa-card-header-title">Add your phone</h6>
          <p className="tfa-card-header-description">
            Enter your phone to log into the admin panel for security.
          </p>
        </div>
        <div className="tfa-card-body">
          <div className="tfa-card-row">
            <label className="tfa-card-row-title">Phone</label>
            <input
              inputMode="numeric"
              type="tel"
              pattern="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value.replace(/[^\d.+]/g, ""))}
              className={`form-control form-control-lg form-control-solid tfa-input ${
                error && "tfa-input-error"
              }`}
              autoComplete="off"
              placeholder="Enter phone"
              // {...register("password", { required: true })}
            />
            <p className="input-error-message tfa-card-row-error-messege">
              {/* {errors.password && "Password is required"} */}
              {renderError}
            </p>
          </div>
        </div>
        <div className="tfa-card-footer">
          <div className="tfa-card-footer-btn">
            <button
              disabled={error}
              onClick={onSaveNumber}
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-primary w-100"
            >
              <span className="indicator-label">Continue</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </TwoFactorAuthentication>
  );
};

export default AddYouPhone;
