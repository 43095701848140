import { FC } from "react";
import { withRouter } from "react-router-dom";

const PrivateLayout: FC = ({ children }) => {
  return (
    <div
      id="kt_body"
      className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-enabled"
    >
      <div className="d-flex flex-column flex-root">
        <div
          className="wrapper d-flex flex-column flex-row-fluid"
          id="kt_wrapper"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default withRouter(PrivateLayout);
