import ContentLoader from "react-content-loader";

const ClientDetailsSkeleton = (): JSX.Element => {
  return (
    <ContentLoader
      width={1200}
      height={628}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="24" y="24" rx="4" ry="4" width="300" height="22" />
      <rect x="24" y="64" rx="4" ry="4" width="100" height="15" />
      <rect x="24" y="94" rx="4" ry="4" width="70" height="15" />
      <rect x="114" y="94" rx="4" ry="4" width="40" height="15" />
      <rect x="24" y="124" rx="4" ry="4" width="100" height="15" />
      <rect x="24" y="154" rx="4" ry="4" width="300" height="1" />
      <rect x="354" y="24" rx="4" ry="4" width="70" height="10" />
      <rect x="440" y="24" rx="4" ry="4" width="70" height="10" />
      <rect x="354" y="44" rx="4" ry="4" width="100%" height="1" />
      <rect x="354" y="154" rx="4" ry="4" width="60" height="15" />
      <rect x="424" y="154" rx="4" ry="4" width="60" height="15" />
      <rect x="354" y="184" rx="4" ry="4" width="100%" height="1" />
      <rect x="354" y="224" rx="4" ry="4" width="80" height="15" />
      <rect x="354" y="254" rx="4" ry="4" width="100%" height="1" />
      <rect x="354" y="284" rx="4" ry="4" width="120" height="10" />
      <rect x="494" y="284" rx="4" ry="4" width="150" height="10" />
      <rect x="354" y="314" rx="4" ry="4" width="120" height="10" />
      <rect x="494" y="314" rx="4" ry="4" width="150" height="10" />
      <rect x="354" y="344" rx="4" ry="4" width="120" height="10" />
      <rect x="494" y="344" rx="4" ry="4" width="150" height="10" />
      <rect x="354" y="374" rx="4" ry="4" width="120" height="10" />
      <rect x="494" y="374" rx="4" ry="4" width="150" height="10" />

      <rect x="354" y="64" rx="4" ry="4" width="120" height="45" />
      <rect x="484" y="64" rx="4" ry="4" width="120" height="45" />
      <rect x="614" y="64" rx="4" ry="4" width="120" height="45" />
      <rect x="744" y="64" rx="4" ry="4" width="120" height="45" />
      <rect x="24" y="174" rx="4" ry="4" width="80" height="15" />
      <rect x="24" y="204" rx="4" ry="4" width="150" height="15" />
      <rect x="204" y="204" rx="4" ry="4" width="70" height="15" />
      <rect x="24" y="234" rx="4" ry="4" width="60" height="15" />
      <rect x="24" y="264" rx="4" ry="4" width="80" height="15" />
      <rect x="24" y="294" rx="4" ry="4" width="120" height="15" />
      <rect x="24" y="324" rx="4" ry="4" width="90" height="15" />
      <rect x="24" y="354" rx="4" ry="4" width="150" height="15" />
      <rect x="24" y="384" rx="4" ry="4" width="60" height="15" />
      <rect x="24" y="414" rx="4" ry="4" width="90" height="15" />
      <rect x="24" y="444" rx="4" ry="4" width="120" height="15" />
      <rect x="24" y="474" rx="4" ry="4" width="60" height="15" />
      <rect x="24" y="504" rx="4" ry="4" width="140" height="15" />
      <rect x="24" y="534" rx="4" ry="4" width="140" height="15" />
      <rect x="24" y="564" rx="4" ry="4" width="70" height="15" />
    </ContentLoader>
  );
};

export default ClientDetailsSkeleton;
