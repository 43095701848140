import { useEffect, useState } from "react";

const PayoutOTPCountdown = ({
  setTimeExpired,
  isResendDisabled,
  disableResend,
  setResendCounter,
  resendCounter,
  otpAttempts,
}: {
  setTimeExpired: (isExpired: boolean) => void;
  isResendDisabled: boolean;
  disableResend: (disable: boolean) => void;
  setResendCounter: (resendCounter: boolean) => void;
  resendCounter: boolean;
  otpAttempts: number;
}): JSX.Element => {
  const [counter, setCounter] = useState<number>(60);

  useEffect(() => {
    if (resendCounter) {
      setCounter(60);
      setResendCounter(false);
      disableResend(true);
    }

    if (counter === 0 && !resendCounter) {
      setTimeExpired(true);

      if (otpAttempts > 0) {
        disableResend(false);
      }
    }

    counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
  }, [
    counter,
    setTimeExpired,
    disableResend,
    resendCounter,
    isResendDisabled,
    setResendCounter,
    otpAttempts,
  ]);

  return <>The code is valid for {counter} seconds.</>;
};

export default PayoutOTPCountdown;
