import { IPromotionType, Partner } from "providers/Partners/types";

import { IList } from "components/Promotions/types";

export const ActionTypes = {
  SET_LOADING: "SET_LOADING",
  SET_CLIENTS: "SET_CLIENTS",
  SET_LOGS: "SET_LOGS",
  SET_CLIENT_DETAILS: "SET_CLIENT_DETAILS",
  SET_TIERS: "SET_TIERS",
  UPDATE_STATUS: "UPDATE_STATUS",
  UPDATE_TIER: "UPDATE_TIER",
  UPDATE_LIMIT: "UPDATE_LIMIT",
  SET_PROMOTIONS_IS_LOADED: "SET_PROMOTIONS_IS_LOADED",
  SET_PROMOTIONS_LIST: "SET_PROMOTIONS_LIST",
  UPDATE_PROMOTIONS_LIST: "UPDATE_PROMOTIONS_LIST",
  ADD_PROMOTION: "ADD_PROMOTION",
  SET_PROMOTIONS_TYPES: "SET_PROMOTIONS_TYPES",
  SET_PROMOTIONS_COUNT: "SET_PROMOTIONS_COUNT",
};

export type ButtonProp = {
  id?: string;
  userName?: string;
  title?: string;
  title2?: string;
  title3?: string;
  onTitle3Click?: () => void;
  descriptionTitle?: string;
  role?: string;
  partnerName?: Partner;
  onClick: () => void;
};

export type ClTier = {
  client_id: string;
  tier_id: string;
  limit: number;
  createdAt: string;
  updatedAt: string;
};

export type Tier = {
  id: string;
  type: string;
  qualification: string;
  client_id?: string;
  limit?: number;
  ClientTiers: ClTier;
};

export type Client = {
  active: boolean;
  createdAt: string;
  email: string;
  id: string;
  name: string | null;
  partner_id: string;
  partner_name: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  date_of_birth: string | null;
  billing_address?: {
    address: string | null;
    city: string | null;
    client_id: string | null;
    country_id: string | null;
    createdAt: string | null;
    id: string | null;
    post_code: string | null;
    state: string | null;
    updatedAt: string | null;
    countries: {
      country: string | null;
    };
  };
  residence_address?: {
    address: string | null;
    city: string | null;
    client_id: string | null;
    country_id: string | null;
    createdAt: string | null;
    id: string | null;
    post_code: string | null;
    state: string | null;
    updatedAt: string | null;
    countries: {
      country: string | null;
    };
  };
  patronymic?: string | null;
  sumsub_url: string | null;
  surname: string | null;
  updatedAt: string | null;
  verified: boolean;
  email_verified: boolean;
  card_name?: string | null;
  failed_count?: number | null;
  orders_count?: {
    paid: number;
    failed: number;
    total: number;
    pending: number;
  };
  kyc: string;
  partner?: Partner;
  regstatus?: number;
  status?: string | null;
  ip?: string | null;
  country?: string | null;
  risk_score?: string;
  tiers: Tier[];
  delete_email: boolean;
};

export type LogAction = {
  action: string;
  transaction?: string | null;
  ip?: string | null;
  country?: string | null;
};

type Log = {
  id: string;
  client_id: string;
  createdAt: string;
  updatedAt: string;
};

export type ClientLogApi = Log & {
  action: string;
};

export type ClientLog = Log & {
  action: LogAction;
  client: {
    partner_id: string | null;
    partner?: {
      name: string | null;
    };
  };
};

interface SetClients {
  type: typeof ActionTypes.SET_CLIENTS;
  data: unknown;
}

interface SetClients {
  type: typeof ActionTypes.SET_LOGS;
  data: unknown;
}

interface SetClientDetails {
  type: typeof ActionTypes.SET_CLIENT_DETAILS;
  data: unknown;
}

interface UpdateStatus {
  type: typeof ActionTypes.UPDATE_STATUS;
  data: unknown;
}

export type Action = SetClients | SetClientDetails | UpdateStatus;

export type ClientTier = {
  createdAt: string;
  updatedAt: string;
  id: string;
  limit: number;
  qualification: string;
  type: string;
  description: string;
};

export type State = {
  isLoading: boolean;
  clients: Client[];
  clientsCount: number;
  clientPromotions: {
    isLoaded: boolean;
    types: IPromotionType[];
    list: IList[];
    count: number;
  };
  clientDetails: Client | null;
  logs: ClientLog[] | null;
  logsCount: number;
  tiers: ClientTier[] | [];
};

export type DispatchContext = {
  getClients: (
    page: number | undefined,
    search: string,
    filters: undefined | { status: string },
    filtersEmail: { email_verified?: boolean } | undefined,
    kycs: string[] | null,
    dateFrom: Date | null | string,
    dateTo: Date | null | string | undefined,
    checkedOne: boolean | undefined,
    sorting: string | undefined,
    ordering: string | undefined | ((prev: string) => string),
    showClients: string | undefined,
    partnerId: string | undefined
  ) => Promise<void>;
  getClientLogs: (
    page: number,
    id: string,
    dateFrom: string | Date | null,
    dateTo: string | Date | null | undefined,
    shwoClients: string,
    search: string | undefined,
    clientCountry: undefined | string[] | string,
    checkedCountry: string[] | undefined
  ) => Promise<void>;
  downloadClients: (
    limit: string,
    sorting: string | undefined,
    ordering: string | undefined | ((prev: string) => string),
    exportedClient: string[] | undefined,
    checkedID: { checked: boolean; clients: string[] },
    clients: Client[] | ClientLog[] | null,
    partnerId: string | undefined,
    arrayChecking: string[] | [] | undefined,
    search: string,
    filters: undefined | { status: string },
    filtersEmail: { email_verified?: boolean } | undefined,
    kycs: string[] | null,
    dateFrom: Date | null | string,
    dateTo: Date | null | string | undefined
  ) => Promise<void>;
  downloadClientLogs: (
    limit: string,
    sorting: string | undefined,
    ordering: string | undefined | ((prev: string) => string),
    selectedClient: string[] | undefined,
    checkedID: { checked: boolean; clients: string[] },
    clients: Client[] | ClientLog[] | null,
    logId: string | undefined,
    arrayChecking: string[] | [] | undefined,
    dateFrom: string | Date | null,
    dateTo: string | Date | null | undefined,
    search: string | undefined,
    checkedCountry: string[] | undefined,
    clientCountry: undefined | string[] | string
  ) => Promise<void>;
  getClientById: (id: string) => Promise<void>;
  updateClientStatus: (
    id: string | undefined,
    status: string,
    comment: string
  ) => Promise<void>;
  updateClientTier: ({
    client_id,
    tier_id,
    limit,
  }: {
    client_id: string;
    tier_id: string;
    limit?: number | undefined;
  }) => void;
  getTiers: () => void;
  setLoading: (isLoading: boolean) => void;
  getClientPromotions: ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => Promise<void>;
  reloadPromotionsList: () => void;
  updatePromotion: ({
    startDate,
    endDate,
    description,
    ended,
  }: {
    startDate: string;
    endDate: string;
    description: string;
    ended: boolean;
  }) => void;
  addPromotion: ({
    promotionId,
    feeReductionBank,
    feeReductionCard,
    startDate,
    endDate,
    description,
    buyFlow,
    sellFlow,
  }: {
    promotionId: string;
    feeReductionBank: number;
    feeReductionCard: number;
    startDate: string;
    endDate: string;
    description: string;
    buyFlow?: boolean;
    sellFlow?: boolean;
  }) => void;
};

export type Dispatch = (action: Action) => void;
