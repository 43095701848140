import ContentLoader from "react-content-loader";

const WebhookCreateFormSkeleton = (): JSX.Element => {
  return (
    <ContentLoader
      width="400px"
      height="600px"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="100" height="20" />
      <rect x="0" y="30" rx="10" ry="10" width="400" height="20" />
      <rect x="0" y="70" rx="10" ry="10" width="170" height="20" />
      <rect x="0" y="100" rx="10" ry="10" width="100" height="30" />
      <rect x="0" y="140" rx="10" ry="10" width="120" height="30" />
      <rect x="0" y="180" rx="10" ry="10" width="140" height="30" />
      <rect x="0" y="220" rx="10" ry="10" width="90" height="30" />
      <rect x="0" y="280" rx="10" ry="10" width="80" height="25" />
      <rect x="100" y="280" rx="10" ry="10" width="80" height="25" />
    </ContentLoader>
  );
};

export default WebhookCreateFormSkeleton;
