import React, { useContext, useState } from "react";
import {
  AdminUsersContext,
  AdminUsersDispatchContext,
} from "providers/AdminUsers";

import { Icon, PageWrapper } from "components/base";
import Button from "components/Button";
import TableSkeleton from "components/TableSkeleton";
import EditPencilPopOver from "./EditPencilPopOver";
import ActionPopOver from "./ActionPopover";
import CreateAdminUserModal from "./CreateAdminUserModal";
import Pagination from "components/Pagination";

export default function AdminUsers(): JSX.Element {
  const { adminUsers, roles } = useContext(AdminUsersContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { dispatch } = useContext(AdminUsersDispatchContext);

  const handleCreateClick = () => {
    setIsModalOpen(true);
  };

  const handlePagination = async (stage: string) => {
    const count = stage === "next" ? 1 : -1;

    dispatch({ type: "SET_PAGE", payload: adminUsers.page + count });
  };

  const list = adminUsers.list;

  return (
    <PageWrapper title="Customers list">
      <div
        className="content d-flex flex-column flex-column-fluid user-table"
        id="kt_content"
      >
        <div className="container" id="kt_content_container">
          <div id="card-user-list-row" className="card user-list-row">
            <div className="user-list-header header-list">
              <div className="button-export__wrapper">
                <h1 className="page-title">Admin Users</h1>
                <Button
                  type="button"
                  className="button--transparent"
                  onClick={() => location.reload()}
                >
                  <Icon name="rotate" />
                </Button>
                <Button
                  type="button"
                  className="button-primary button-add-partner"
                  onClick={handleCreateClick}
                >
                  Create
                </Button>
              </div>
            </div>
            <div className="table--row">
              <div className="user-list-body">
                <div className="table-scrolling">
                  {adminUsers.isLoading ? (
                    <TableSkeleton />
                  ) : (
                    <table
                      className={`table align-middle table__orders dataTable no-footer`}
                      id="kt_table_users"
                    >
                      <thead>
                        <tr className="user-list-header-tr">
                          <th className="w-fit d-flex  align-items-sm-center">
                            Name
                          </th>
                          <th className="w-fit">Email</th>
                          <th className="w-fit">Permissions</th>
                          <th className="w-fit">Action</th>
                        </tr>
                      </thead>
                      <tbody className="text-gray-600 fw-bold">
                        {list.map((user) => (
                          <tr key={user.id} className="user-list-tr">
                            <td className="w-fit d-flex menu-center  align-items-sm-center ">
                              {user.name}
                            </td>
                            <td className="w-fit">
                              <div>{user.email}</div>
                            </td>
                            <td className="td-tier w-fit d-flex  align-items-sm-center gap-5 ">
                              <div className="tier-badge w-fit text-uppercase">
                                {user.roles.name}
                              </div>

                              {user.roles.name !== "super_admin" ? (
                                <EditPencilPopOver
                                  id={user.id}
                                  userRole={user.role}
                                />
                              ) : null}
                            </td>
                            {user.roles.name !== "super_admin" ? (
                              <td className="td-tier w-fit  ">
                                <ActionPopOver id={user.id} />
                              </td>
                            ) : null}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
                <Pagination
                  page={adminUsers.page}
                  count={adminUsers.count}
                  visibleCount={10}
                  setLoading={(value) =>
                    dispatch({ type: "SET_IS_LOADING", payload: value })
                  }
                  isLoading={adminUsers.isLoading}
                  handlePagination={handlePagination}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {roles.length ? (
        <CreateAdminUserModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </PageWrapper>
  );
}
