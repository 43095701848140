import { useEffect } from "react";
import { filterFireblockByRole } from "services/roleService/widgetConsole/fireblock";
import { useWidgetFiltersState } from "providers/WidgetFilter";
import { useSellFlowDispatch, useSellFlowState } from "providers/SellFlow";

import useRole from "hooks/useRole";
import NotFound from "pages/NotFound";
import TableSkeleton from "components/TableSkeleton";
import { Table } from "components/base";
import FireblockColumn from "pages/WidgetConsole/FireblockColumn";
import Pagination from "components/Pagination";

const FireblockList = (): JSX.Element => {
  const role = useRole();
  // const { getFireblockList, paginate, setLoading } = useWidgetConsoleDispatch();
  const { getFireblockList, paginate, setLoading, setChosenFireblock } =
    useSellFlowDispatch();

  // const { isLoading, fireblockList, page, fireblockCount, chosenFireblock } =
  //   useWidgetConsoleState();
  const {
    page,
    isLoading,
    fireblockList,
    fireblockCount,
    chosenFireblock,
    dataFetched,
  } = useSellFlowState();

  const {
    trigger_filter,
    fireblock_name,
    fireblock_code,
    fireblock_type,
    fireblock_native_asset,
  } = useWidgetFiltersState();

  useEffect(() => {
    if (trigger_filter) {
      getFireblockList(
        page,
        fireblock_name,
        fireblock_code,
        fireblock_type,
        fireblock_native_asset
      );
    }

    // eslint-disable-next-line
  }, [trigger_filter]);

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        paginate({ page: page + 1, type: "fireblock" });

        return;
      case "prev":
        paginate({ page: page - 1, type: "fireblock" });

        return;

      default:
        return;
    }
  };

  return (
    <div className="table--row">
      {(fireblockList.length === 0 && dataFetched && !isLoading && (
        <NotFound />
      )) || (
        <div className="user-list-body">
          <div>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                headers={[
                  // {
                  //   empty: true,
                  //   val: "checkbox",
                  //   w: "fit-content",
                  // },
                  {
                    title: "ID",
                    val: "id",
                    w: "fit-content",
                    className: "th-email",
                  },
                  {
                    title: "",
                    val: "id_clipboard",
                    w: 120,
                    className: "th-crypto-id",
                  },
                  {
                    title: "Code",
                    val: "code",
                    w: "fit-content",
                  },
                  {
                    title: "Native Asset",
                    val: "native_asset",
                    w: "fit-content",
                  },
                  {
                    title: "Decimals",
                    val: "decimals",
                    w: "fit-content",
                  },
                  {
                    title: "Contract Address",
                    val: "contract_address",
                    w: "fit-content",
                  },
                  {
                    title: "Issuer Address",
                    val: "issuer_address",
                    w: "fit-content",
                  },
                  {
                    title: "Name",
                    val: "name",
                    w: "fit-content",
                  },
                  {
                    title: "Type",
                    val: "type",
                    w: "fit-content",
                  },
                  {
                    title: "Date",
                    val: "date",
                    w: "fit-content",
                  },
                ].filter((item) => role && filterFireblockByRole(item, role))}
              >
                <FireblockColumn
                  flow="sell"
                  setChosenFireblock={setChosenFireblock}
                  chosenFireblock={chosenFireblock}
                  fireblockList={fireblockList}
                  role={role}
                />
              </Table>
            )}
          </div>
          <Pagination
            page={page}
            count={fireblockCount as number}
            visibleCount={10}
            clearSearch={() => null}
            setLoading={setLoading}
            isLoading={isLoading}
            handlePagination={handlePagination}
          />
        </div>
      )}
    </div>
  );
};

export default FireblockList;
