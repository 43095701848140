import { ReactNode } from "react";
import { Socket } from "socket.io-client";

export const ActionTypes = {
  SET_TRANSACTION_NOTIFICATIONS: "SET_TRANSACTION_NOTIFICATIONS",
  SET_NEW_CREATED_ENTITY: "SET_NEW_CREATED_ENTITY",
  SET_NOTIFICATION_COUNT: "SET_NOTIFICATION_COUNT",
  SET_RENDERED_COUNT: "SET_RENDERED_COUNT",
  SET_UNWATCHED_COUNT: "SET_UNWATCHED_COUNT",
  SET_LOADING: "SET_LOADING",
  SET_PAGE: "SET_PAGE",
  SET_NOTIFICATIONS_WATCHED: "SET_NOTIFICATIONS_WATCHED",
  SET_SOCKET: "SET_SOCKET",
};

export interface TransactionNotification {
  id: string;
  status: string;
  title: string;
  isWatched: boolean;
}

export interface State {
  isLoading: boolean;
  transactionNotifications: Set<TransactionNotification | unknown>;
  notificationCount: number;
  renderedCount: number;
  unwatchedCount: number;
  page: number;
  socket: Socket | undefined;
  id: string | null;
}

interface SetTransactionNotifications {
  type: typeof ActionTypes.SET_TRANSACTION_NOTIFICATIONS;
  payload: NotificationsType;
}

interface SetNotificationCount {
  type: typeof ActionTypes.SET_NOTIFICATION_COUNT;
  payload: number;
}

interface SetLoading {
  type: typeof ActionTypes.SET_LOADING;
  payload: boolean;
}

interface SetNewCreatedEntity {
  type: typeof ActionTypes.SET_NEW_CREATED_ENTITY;
  payload: {
    entityId: string;
    entityType: string;
    entityStatus: string;
    watchedAt?: string | undefined;
  };
}

interface SetSocket {
  type: typeof ActionTypes.SET_SOCKET;
  payload: Socket;
}

export type Action =
  | SetTransactionNotifications
  | SetNotificationCount
  | SetLoading
  | SetNewCreatedEntity
  | SetSocket;

export type Dispatch = (action: Action) => void;

export interface NotificationProviderProps {
  children: ReactNode;
}

export interface DispatchContext {
  setAsWatched: (id: string, socket) => void;
  setWatched: () => void;
  paginate: () => void;
}

export interface NotificationType {
  entityId: string;
  entityType: string;
  entityStatus: string;
  entityTitle?: string;
  watchedAt?: string | undefined;
  isWatched?: boolean;
  entityPartnerId?: string;
}

export interface NotificationsType {
  counts: {
    total: number;
    unwatched: number;
  };

  data: {
    data: {
      entityId: string;
      entityType: string;
      entityStatus: string;
      entityTitle?: string;
      watchedAt?: string | undefined;
      isWatched?: boolean;
      entityPartnerId?: string;
    };
    watchedAt: string | undefined;
  }[];
}
