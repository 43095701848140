import { FC, useState } from "react";
import { useLocation } from "react-router-dom";

import { FilterListProps } from "./FilterList.props";
import FilterItem from "../FilterItem";
import Button from "../../Button";
import Icon from "../../base/Icon";
import Popover from "../../Popover";
import AddFilterDropDown from "../FilterDropDown/AddFilterDropDown";
import { additionalOrderSecondFilterTree } from "utils/constants";
import useRole from "hooks/useRole";

const FilterList: FC<FilterListProps> = ({
  secondLevelHandlers,
  secondLevelFilter,
  handleApply,
  clearSearches,
  clearAdditionalSearches,
  enableClearFilters,
  additionalFilters,
  setAdditionalFilters,
  hideClearFilters,
  additionalHandlers,
  clearSelect,
}) => {
  const role = useRole();
  const location = useLocation();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <div className="filter-list__container">
      <div className="filter-list__items-wrapper">
        {secondLevelFilter?.map((filterItem, index) => {
          if (filterItem?.super_admin && role !== "super_admin") {
            return;
          }

          return (
            <FilterItem
              initialValue={filterItem?.initialValue}
              secondLevelHandler={secondLevelHandlers[index]}
              handleApply={handleApply}
              key={index}
              filterItem={filterItem}
              resetSearch={clearSearches[index]}
              clearSelect={clearSelect}
              isDisabled={filterItem?.isDisabled}
            />
          );
        })}
        {additionalFilters?.map((filterItem, index) => {
          if (filterItem?.super_admin && role !== "super_admin") {
            return;
          }

          return (
            <FilterItem
              secondLevelHandler={additionalHandlers[filterItem.index]}
              handleApply={handleApply}
              key={index}
              filterItem={filterItem}
              resetSearch={clearAdditionalSearches[filterItem.index]}
              clearSelect={clearSelect}
            />
          );
        })}
      </div>
      <div className="filter-buttons__container">
        {!hideClearFilters ? (
          <Button
            type="button"
            className="button--transparent button__filter--clear"
            onClick={() => {
              window.location.href = window.location.pathname.split("?")[0];
            }}
            disabled={!enableClearFilters}
          >
            <Icon name="clear_filter" />
            Clear Filters
          </Button>
        ) : null}

        {location.pathname === "/orders" && (
          <Popover
            isPopoverOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            position={["bottom"]}
            className="filter__popover"
            arrowStyle={{ left: "60%" }}
            contentLocation={(popoverState) => {
              return {
                top: popoverState.nudgedTop,
                left: popoverState.targetRect.left - 150,
              };
            }}
            component={
              <Button
                type="button"
                className="button--transparent button__filter--add"
                onClick={() => setIsPopoverOpen(true)}
              >
                <Icon name="add_filter" />
                Add Filters
              </Button>
            }
          >
            <AddFilterDropDown
              role={role}
              setAdditionalFilters={setAdditionalFilters}
              additionalFilters={additionalFilters}
              filterItems={additionalOrderSecondFilterTree}
              setIsPopoverOpen={setIsPopoverOpen}
            />
          </Popover>
        )}
      </div>
    </div>
  );
};

export default FilterList;
