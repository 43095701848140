import { ButtonProp } from "providers/Clients/types";
import { usePartnerDispatch, usePartnerFilters } from "providers/PartnerFilter";
import {
  set_modalOpen,
  set_partnerOption,
} from "providers/PartnerFilter/partnerAction";
import { usePartnerDispatchContext } from "providers/Partners";
import { FC } from "react";
import { onConfirm } from "react-confirm-pro";

import "./confirm-styles.scss";

const ReactConfirmChangeStatus: FC<{
  options: ButtonProp;
  setVisibility: (isVisible: boolean) => void;
}> = ({ options, setVisibility }) => {
  // const dispatch = useClientDispatch();
  const partnerDispatch = usePartnerDispatch();
  const { deletePartner } = usePartnerDispatchContext();
  const {
    pagePartner,
    partnerSearch,
    markupFrom,
    markupTo,
    commissionFrom,
    commissionTo,
    dateFromPartner,
    dateToPartner,
    partnerFilter,
    visiblePartnersCount,
    sortingPartner,
    orderingPartner,
  } = usePartnerFilters();

  const handleEdit = () => {
    deletePartner(
      options.id,
      pagePartner,
      partnerSearch,
      markupFrom,
      markupTo,
      commissionFrom,
      commissionTo,
      dateFromPartner,
      dateToPartner,
      partnerFilter,
      visiblePartnersCount,
      sortingPartner,
      orderingPartner
    );
  };

  const onClickBlock = () => {
    onConfirm({
      title: (
        <h3 className="status-modal-title">
          {options.title} {options.role}
        </h3>
      ),
      description: (
        <div className="status-modal-content">
          <div className="status-modal-description">
            <p>
              Are you sure you want to{" "}
              {options?.descriptionTitle ?? options.title}
            </p>
            <p> {options.userName} </p>
          </div>
          {/*
          {options.title !== "Delete" && (
            <div className="status-modal-textarea">
              <textarea
                name={options.title + " user"}
                id={options.title + "__user"}
                cols={65}
                rows={3}
                onChange={(e) => {
                  dispatch(set_actionComment(e.target.value.toLowerCase()));
                }}
              ></textarea>
            </div>
          )} */}
        </div>
      ),
      btnCancel: "Cancel",
      btnSubmit: options.title,
      onSubmit: () => {
        if (options.title === "Delete") {
          handleEdit();

          return;
        }

        options.onClick();

        return;
      },
      className: `confirm-status-${
        options.title === "Unblock" ? "unblock" : "block"
      }-modal confirm-status-modal`,
    });
  };

  return (
    <div>
      {options.title && (
        <div className="menu-item">
          <button
            className="confirm-change-status-btn menu-link btn-blcok"
            type="button"
            onClick={onClickBlock}
          >
            {options.title}
          </button>
        </div>
      )}
      {options.title2 && (
        <div className="menu-item">
          <button
            className="confirm-change-status-btn menu-link btn-blcok"
            type="button"
            onClick={() => {
              partnerDispatch(set_modalOpen(true));
              partnerDispatch(set_partnerOption(options.partnerName));
              setVisibility(false);
            }}
          >
            {options.title2}
          </button>
        </div>
      )}
      {options.title3 && (
        <div className="menu-item">
          <button
            className="confirm-change-status-btn menu-link btn-blcok"
            type="button"
            onClick={() => {
              options.onTitle3Click && options.onTitle3Click();
              setVisibility(false);
            }}
          >
            {options.title3}
          </button>
        </div>
      )}
    </div>
  );
};

export default ReactConfirmChangeStatus;
