import {
  createContext,
  FC,
  ReducerWithoutAction,
  useContext,
  useReducer,
} from "react";
import { Dispatch } from "providers/Auth/types";

import { FilterType } from "./types";
import { widgetFilterReducer } from "./widgetFilterReducers";

export const initialCryptoFilters: FilterType = {
  core: "",
  code: "",
  symbol: "",
  markup_rate: undefined,
  negative_markup: undefined,
  is_suspended: undefined,
  is_sell_supported: undefined,
  trigger_filter: true,
  fireblock_name: "",
  fireblock_code: "",
  fireblock_type: "",
  fireblock_native_asset: "",
  fiat_name: "",
  fiat_symbol: "",
  fiat_is_suspended: undefined,
  fiat_is_sell_supported: undefined,
  min_sell_amount: undefined,
  max_sell_amount: undefined,
};

const WidgetFiltersContext = createContext(initialCryptoFilters);
const WidgetFiltersDispatcher = createContext<Dispatch>(() => "");

const WidgetFilterProvider: FC = ({ children }) => {
  const [filters, filtersDispatcher] = useReducer<
    ReducerWithoutAction<FilterType>
  >(widgetFilterReducer as never, initialCryptoFilters);

  return (
    <WidgetFiltersContext.Provider value={filters as never}>
      <WidgetFiltersDispatcher.Provider value={filtersDispatcher}>
        {children}
      </WidgetFiltersDispatcher.Provider>
    </WidgetFiltersContext.Provider>
  );
};

export const useWidgetFiltersState: () => FilterType = () => {
  const widgetFilters = useContext(WidgetFiltersContext);

  return widgetFilters;
};

export const useWidgetFiltesDispatch = (): Dispatch => {
  const widgetDispatch = useContext(WidgetFiltersDispatcher);

  return widgetDispatch;
};

export default WidgetFilterProvider;
