import {
  useClientDispatchContext,
  useClientStateContext,
} from "providers/Clients";
import React, { useEffect, useState } from "react";

import Pagination from "components/Pagination";
import Promotions from "components/Promotions";

const limit = 10;

function ClientPromotions(): JSX.Element {
  const {
    getClientPromotions,
    reloadPromotionsList,
    updatePromotion,
    addPromotion,
  } = useClientDispatchContext();

  const { clientPromotions } = useClientStateContext();

  useEffect(() => {
    clientPromotions.isLoaded || getClientPromotions({ limit, page });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientPromotions.isLoaded]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);

  const handlePagination = (to: string) => {
    switch (to) {
      case "next":
        setPage(page + 1);
        reloadPromotionsList();
        setIsLoading(false);

        return;
      case "prev":
        setPage(page - 1);
        reloadPromotionsList();
        setIsLoading(false);

        return;

      default:
        return;
    }
  };

  return (
    <div>
      <Promotions
        allowNegativeNumbers={false}
        updatePromotion={updatePromotion}
        addPromotion={addPromotion}
        reloadList={reloadPromotionsList}
        list={clientPromotions.list}
        isLoaded={clientPromotions.isLoaded}
        types={clientPromotions.types}
      />
      {clientPromotions.count ? (
        <Pagination
          page={page}
          count={clientPromotions.count}
          visibleCount={limit}
          setLoading={setIsLoading}
          isLoading={isLoading}
          handlePagination={handlePagination}
        />
      ) : null}
    </div>
  );
}

export default ClientPromotions;
