import { FC } from "react";
import classnames from "classnames";

import { TabItemProps } from "./TabItem.props";
import Button from "../../Button";

const TabItem: FC<TabItemProps> = ({ children, onClick, className }) => {
  return (
    <li className={classnames("tab-item__wrapper", className)}>
      <Button type="button" className="button--transparent" onClick={onClick}>
        <span className="tab-item">{children}</span>
      </Button>
    </li>
  );
};

export default TabItem;
