import { useHistory } from "react-router-dom";
// import { useState } from "react";

import SettingsTabItem from "./SettingsTabItem";

const settingRoutes = [
  {
    label: "Integration",
    value: "/setting/integration",
  },
  {
    label: "Webhooks",
    value: "/setting/webhooks",
  },
  {
    label: "Events",
    value: "/setting/events",
  },
];

const SettingTabs = (): JSX.Element => {
  // const [activeTab, setActiveTab] = useState(settingRoutes[0]?.value);
  const history = useHistory();

  return (
    <div className="tab-list__wrapper">
      <ul className="tab-list--list">
        {settingRoutes?.map((filterItem, index) => (
          <SettingsTabItem
            key={index}
            onClick={() => {
              history.push(filterItem?.value);
            }}
            className={
              history.location.pathname === filterItem?.value ? "active" : ""
            }
          >
            {filterItem?.label}
          </SettingsTabItem>
        ))}
      </ul>
    </div>
  );
};

export default SettingTabs;
