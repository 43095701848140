import { useAuthDispatchContext } from "providers/Auth";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import TwoFactorAuthentication from ".";

export type SignInData = {
  email: string;
};

const ForgotPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();

  const { forgotPass } = useAuthDispatchContext();

  const onSubmit = async (data: SignInData) => {
    try {
      await forgotPass(data.email);
      toast.info(
        "If your email is registered, you will receive reset password instructions on your email."
      );
      history.push("/login");
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <TwoFactorAuthentication>
      <div className="tfa-card">
        <div className="tfa-card-header">
          <h6 className="tfa-card-header-title">Forgot Password?</h6>
          <p className="tfa-card-header-description">
            Enter your email to reset your password.
          </p>
        </div>
        <div className="tfa-card-body">
          <div className="tfa-card-row">
            <label className="tfa-card-row-title">Email</label>
            <input
              className={`form-control form-control-lg form-control-solid tfa-input ${
                errors.email && "tfa-input-error"
              }`}
              type="email"
              autoComplete="off"
              placeholder="Enter your email"
              {...register("email", {
                required: true,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />

            <p className="tfa-card-row-error-messege input-error-message ">
              {errors.email && "Please enter valid email"}
            </p>
          </div>
        </div>
        <div className="tfa-card-footer">
          <div className="tfa-card-footer-btn">
            <button
              onClick={handleSubmit(onSubmit)}
              type="submit"
              id="kt_sign_in_submit"
              className={`btn btn-lg btn-primary w-100 ${
                errors?.email && "invalid-button"
              }`}
            >
              <span className="indicator-label">Continue</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </TwoFactorAuthentication>
  );
};

export default ForgotPassword;
