import { FC } from "react";
import {
  setActiveAccordions,
  setSelectedEvents,
} from "providers/Webhooks/actions";
import { useWebhookDispatch, useWebhookState } from "providers/Webhooks";

import { Icon } from "components/base";
import { EventAccordionProps } from "./SelectEvent.props";

const EventAccordion: FC<EventAccordionProps> = ({
  eventItem,
  title,
}): JSX.Element => {
  const { selectedEvents, activeAccordions } = useWebhookState();
  const webhookDispatch = useWebhookDispatch();

  const active = activeAccordions.includes(title);

  const selectEvent = (id: string) => {
    if (selectedEvents.includes(id)) {
      const filteredEvents = selectedEvents.filter((item) => item !== id);
      webhookDispatch(setSelectedEvents(filteredEvents));
    } else {
      webhookDispatch(setSelectedEvents([...selectedEvents, id]));
    }

    return;
  };

  const setAccordions = () => {
    if (activeAccordions.includes(title)) {
      const filteredAccordions = activeAccordions.filter(
        (item) => item !== title
      );

      webhookDispatch(setActiveAccordions(filteredAccordions));
    } else {
      webhookDispatch(setActiveAccordions([...activeAccordions, title]));
    }

    return;
  };

  const eventsCount = eventItem?.items.reduce((acc, curr) => {
    if (selectedEvents.includes(curr.id)) {
      acc += 1;
    }

    return acc;
  }, 0);

  return (
    <div className="select-event__accordion-container">
      <button
        onClick={setAccordions}
        className={`select-event__accordion-header ${active ? "active" : ""}`}
      >
        <span>{`${title.substring(0, 1).toUpperCase()}${title
          .substring(1)
          .toLowerCase()}`}</span>
        {eventsCount > 0 && (
          <span className="select-event__accordion-badge user-status-td transaction-status-td user-status-active">
            {eventsCount} selected
          </span>
        )}

        <span>{eventItem?.count} events</span>
        <Icon name="chevron_down" />
      </button>
      <div className={`select-event__accordion-body ${active ? "active" : ""}`}>
        <ul>
          {eventItem?.items?.map((item) => (
            <li key={item.id}>
              <input
                onChange={() => selectEvent(item.id)}
                className="form-check-input user-list-checkbox"
                type="checkbox"
                id={`account-${item.id}`}
                checked={selectedEvents.includes(item.id)}
              />
              <div>
                <label htmlFor={`account-${item.id}`}>{item.slug}</label>
                <span>{item.name}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EventAccordion;
