import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

import { instance } from "utils/api";
import { IDonutChartsData, IParams } from "./types";
import DonutChart from "components/Charts/Donut";
import { colorsObj } from "utils/constants";

export function TransactionBuy(params: Omit<IParams, "interval">): JSX.Element {
  const [data, setData] = useState<IDonutChartsData[]>([]);
  const [colors, setColors] = useState<string[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const { data } = await instance.get(`/admin/summary/tr-buy`, {
        params: {
          start: params.start,
          end: params.end,
        },
      });

      const newColors: string[] = [];

      const newData = data.map((el) => {
        newColors.push(colorsObj[el.tr_status.toUpperCase()]);

        return {
          label: `${el?.tr_status.replace(/(^|\s)\S/g, (l) =>
            l.toUpperCase()
          )}`,
          count: el.entries,
        };
      });

      setData(newData);
      setColors(newColors);
      setIsLoading(false);
    };

    if (params.start && params.end) getData();
  }, [params.start, params.end]);

  return isLoading ? (
    <ContentLoader
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      height="400px"
      width="100%"
    >
      <circle cx="200" cy="200" r="130" />
    </ContentLoader>
  ) : (
    <DonutChart
      title="Transactions Buy"
      infoContenet="Transactions Buy"
      progress={-34}
      data={data}
      colors={colors}
    />
  );
}
