import { ReactElement, useEffect, useState } from "react";
import { AxiosResponse } from "axios";
import "./styles.scss";
import moment from "moment";

import TableSkeleton from "components/TableSkeleton";
import FilterList from "components/Filter/FilterList";
import { filterTreeStatic, format } from "./utils";
import { IAsset, IDate, IFinancialReports } from "./types";
import { assetInstance, instance } from "utils/api";
import NotFound from "pages/NotFound";
import { FilterTreeType } from "pages/Clients/ClientDetails/LogsFiltersWrappers/LogsFiltersWrapper.props";

const extractDate = (date) => moment(date).format("YYYY-MM-DD");

export const FinancialReports = (): ReactElement => {
  const clearSearches = [
    () => setPaymentMethod(""),
    () => setPartnerId(""),
    () => setFiat(""),
    () => setDate(""),
  ];

  const [financialReports, setFinancialReports] = useState<
    null | IFinancialReports[]
  >(null);

  const [filterTree, setFilterTree] = useState<FilterTreeType[] | []>([]);
  const [assetNames, setAssetNames] = useState<{
    [key: string]: IAsset;
  } | null>(null);

  const [paymentMethod, setPaymentMethod] = useState("openPayd");
  const [fiat, setFiat] = useState("EUR");
  const [partnerId, setPartnerId] = useState("");
  const [date, setDate] = useState<IDate | "">("");

  const handleApply = () => {
    console.log();
  };

  useEffect(() => {
    (async function () {
      const [{ data: assetList }, fiatList, partnerList] = await Promise.all([
        assetInstance.get(`crypto/admin_crypto_currencies`),
        assetInstance.get(`fiat/fiat_currencies`),
        instance.get("/partners?page=1&limit=100"),
      ]);

      const { data: result } = partnerList;
      const partnerFilterData = result.data.rows.map((el) => ({
        label: el.name,
        value: el.id,
      }));

      const finalAssetNames = {};
      assetList.rows.forEach((el) => {
        finalAssetNames[el.code] = el;
      });
      setAssetNames(finalAssetNames);

      const assetsOptions = assetList.rows.map((el) => ({
        label: el.symbol,
        value: el.symbol,
      }));

      const fiatsOptions = fiatList.data.rows.map((el) => ({
        label: el.symbol,
        value: el.symbol,
      }));

      const finalFilterTree = filterTreeStatic;
      finalFilterTree.forEach((el) => {
        if (el.label === "Fiat") {
          el.selectOptions = fiatsOptions;
        } else if (el.label === "Asset") {
          el.selectOptions = assetsOptions;
        } else if (el.label === "Partner") {
          el.selectOptions = partnerFilterData;
        }
      });
      setFilterTree(finalFilterTree);
    })();
  }, []);
  useEffect(() => {
    (async function () {
      try {
        const baseApi = `/admin/summary/fin-report?&currency=${fiat}`;
        const paymentMethodParams = paymentMethod
          ? `&pay_method=${paymentMethod}`
          : "";

        const partnerIdParams = partnerId ? `&partner_id=${partnerId}` : "";

        const dateParams =
          date && date.dateFrom && date.dateTo
            ? `&fromDate=${extractDate(date.dateFrom)}&toDate=${extractDate(
                date.dateTo
              )}`
            : "";

        const { data: data }: AxiosResponse<IFinancialReports[]> =
          await instance.get(
            `${baseApi}${paymentMethodParams}${dateParams}${partnerIdParams}`
          );

        setFinancialReports(data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [date, paymentMethod, partnerId, fiat]);
  const enableClearFilters = !!(paymentMethod || fiat || partnerId || date);

  const secondLevelHandlers = [
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPaymentMethod(e.target.value);
    },
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPartnerId(e.target.value);
    },
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFiat(e.target.value);
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (e: any) => {
      setDate(e);
    },
  ];

  const overRall =
    financialReports &&
    financialReports.reduce(
      (sum, el) => {
        const minusFee =
          paymentMethod === "skrill"
            ? el.charge_fee_skrill
            : paymentMethod === "rapid"
            ? el.charge_fee_rapid
            : paymentMethod === "neteller"
            ? el.charge_fee_neteller
            : paymentMethod === "astropay"
            ? el.charge_fee_astropay
            : paymentMethod === "volt"
            ? el.charge_fee_volt
            : paymentMethod === "stripe"
            ? el.charge_fee_stripe
            : paymentMethod === "openPayd"
            ? el.charge_fee_openPayd
            : 0;

        const networkFeeValue =
          paymentMethod === "xpate" ||
          partnerId === "9d74527a-6cb0-4970-9b2e-455449569971"
            ? 6
            : el.network_fee;

        const chargeExchangeFeeValue =
          paymentMethod === "xpate" ||
          partnerId === "9d74527a-6cb0-4970-9b2e-455449569971"
            ? el.rocketbit
            : el.charge_exchange_fee;

        return {
          count: Number(sum.count) + Number(el.count),
          total_amount: Number(sum.total_amount) + Number(el.total_amount),
          quantity: Number(sum.quantity) + Number(el.quantity),
          charge_fee_openPayd:
            Number(sum.charge_fee_openPayd) + Number(el.charge_fee_openPayd),
          charge_fee_stripe:
            Number(sum.charge_fee_stripe) + Number(el.charge_fee_stripe),
          charge_fee_volt:
            Number(sum.charge_fee_volt) + Number(el.charge_fee_volt),
          charge_fee_rapid:
            Number(sum.charge_fee_rapid) + Number(el.charge_fee_rapid),
          charge_fee_neteller:
            Number(sum.charge_fee_neteller) + Number(el.charge_fee_neteller),
          charge_fee_skrill:
            Number(sum.charge_fee_skrill) + Number(el.charge_fee_skrill),
          charge_fee_astropay:
            Number(sum.charge_fee_astropay) + Number(el.charge_fee_astropay),
          network_fee: Number(sum.network_fee) + Number(networkFeeValue),
          partners_revenue:
            Number(sum.partners_revenue) + Number(el.partners_revenue),
          revenue_from_partners:
            Number(sum.revenue_from_partners) +
            Number(el.revenue_from_partners),
          charge_processing_fee:
            Number(el.charge_processing_fee) +
            Number(sum.charge_processing_fee) -
            Number(minusFee),
          swaps_profit:
            Number(el.swaps_profit) +
            Number(sum.swaps_profit) -
            Number(minusFee),
          charge_fee_xpate:
            Number(el.charge_fee_xpate) + Number(sum.charge_fee_xpate),
          swaps_revenue: Number(el.swaps_revenue) + Number(sum.swaps_revenue),
          charge_exchange_fee:
            Number(chargeExchangeFeeValue) + Number(sum.charge_exchange_fee),
        };
      },
      {
        count: 0,
        total_amount: 0,
        quantity: 0,
        charge_fee_openPayd: 0,
        charge_fee_stripe: 0,
        charge_fee_volt: 0,
        charge_fee_rapid: 0,
        charge_fee_neteller: 0,
        charge_fee_skrill: 0,
        charge_fee_astropay: 0,
        network_fee: 0,
        partners_revenue: 0,
        revenue_from_partners: 0,
        charge_processing_fee: 0,
        swaps_profit: 0,
        charge_fee_xpate: 0,
        swaps_revenue: 0,
        charge_exchange_fee: 0,
      }
    );

  return (
    <div className="d-flex  flex-column">
      <div className="container">
        <FilterList
          secondLevelHandlers={secondLevelHandlers}
          secondLevelFilter={filterTree}
          handleApply={handleApply}
          enableClearFilters={enableClearFilters}
          clearSearches={clearSearches}
          additionalHandlers={[]}
          clearAdditionalSearches={[]}
          clearSelect={() => null}
        />
        <div className="financial-reports ">
          {financialReports !== null && assetNames ? (
            financialReports.length ? (
              <table>
                <thead>
                  <tr>
                    <th>ASSET</th>
                    <th>RECORD COUNT</th>
                    <th>Amount</th>
                    <th>Quantity</th>
                    {paymentMethod === "openPayd" && (
                      <th>OpenPaid charge fee</th>
                    )}
                    {paymentMethod === "stripe" && <th>Stripe charge fee</th>}
                    {paymentMethod === "xpate" && <th>Xpate charge fee</th>}
                    {paymentMethod === "volt" && <th>Volt charge fee</th>}
                    {paymentMethod === "neteller" && (
                      <th>Neteller charge fee</th>
                    )}
                    {paymentMethod === "rapid" && (
                      <th>Rapid Transfer charge fee</th>
                    )}
                    {paymentMethod === "skrill" && <th>Skrill charge fee</th>}

                    {paymentMethod === "astropay" && (
                      <th> AstroPay charge fee</th>
                    )}

                    <th>Charge exchange fee</th>
                    <th>Charge processing fee</th>
                    <th>Network Commission</th>
                    <th>Partners revenue (Allocated expenses)</th>
                    <th>Revenue from Partners (Second Activity)</th>
                    <th>Swaps revenue from processing fee</th>
                    <th>Revenue from partners + (Charge Processing Fee)</th>
                  </tr>
                </thead>
                <tbody>
                  {financialReports.map((el, i) => {
                    const minusFee =
                      paymentMethod === "stripe"
                        ? el.charge_fee_stripe
                        : paymentMethod === "openPayd"
                        ? el.charge_fee_openPayd
                        : 0;

                    const networkFeeValue =
                      paymentMethod === "xpate" ||
                      partnerId === "9d74527a-6cb0-4970-9b2e-455449569971"
                        ? 6
                        : el.network_fee;

                    const chargeExchangeFeeValue =
                      paymentMethod === "xpate" ||
                      partnerId === "9d74527a-6cb0-4970-9b2e-455449569971"
                        ? el.rocketbit
                        : el.charge_exchange_fee;

                    return (
                      <tr key={i}>
                        <td className="no-wrap">
                          {(assetNames[el.asset] &&
                            `${assetNames[el.asset].symbol} ${
                              assetNames[el.asset].name
                            } (${assetNames[el.asset].core})`) ||
                            el.asset}
                        </td>
                        <td>{el.count}</td>
                        <td>{format(el.total_amount)}</td>
                        <td>{el.quantity}</td>
                        {paymentMethod === "openPayd" && (
                          <td>{format(el.charge_fee_openPayd)}</td>
                        )}
                        {paymentMethod === "stripe" && (
                          <td>{format(el.charge_fee_stripe)}</td>
                        )}
                        {paymentMethod === "xpate" && (
                          <td>{format(el.charge_fee_xpate)}</td>
                        )}
                        {paymentMethod === "volt" && (
                          <td>{format(el.charge_fee_volt)}</td>
                        )}
                        {paymentMethod === "astropay" && (
                          <td>{format(el.charge_fee_astropay)}</td>
                        )}
                        <td>{format(chargeExchangeFeeValue)}</td>
                        <td>{format(el.charge_processing_fee - minusFee)}</td>
                        <td>{format(networkFeeValue, true)}</td>
                        <td>{format(el.partners_revenue)}</td>
                        <td>{format(el.revenue_from_partners)}</td>
                        <td>{format(el.swaps_revenue)}</td>
                        <td>{format(el.swaps_profit - minusFee)}</td>
                      </tr>
                    );
                  })}
                  <tr className="reports-overrall">
                    <td>OVERRALL</td>
                    <td>{format(overRall?.count)}</td>
                    <td>{format(overRall?.total_amount)}</td>
                    <td>{overRall?.quantity}</td>
                    {paymentMethod === "openPayd" && (
                      <td>{format(overRall?.charge_fee_openPayd)}</td>
                    )}
                    {paymentMethod === "stripe" && (
                      <td>{format(overRall?.charge_fee_stripe)}</td>
                    )}
                    {paymentMethod === "xpate" && (
                      <td>{format(overRall?.charge_fee_xpate)}</td>
                    )}
                    <td>{format(overRall?.charge_exchange_fee)}</td>

                    <td>{format(overRall?.charge_processing_fee)}</td>
                    <td>{format(overRall?.network_fee, true)}</td>
                    <td>{format(overRall?.partners_revenue)}</td>
                    <td>{format(overRall?.revenue_from_partners)}</td>
                    <td>{format(overRall?.swaps_revenue)}</td>
                    <td>{format(overRall?.swaps_profit)}</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <NotFound />
            )
          ) : (
            <TableSkeleton />
          )}
        </div>
      </div>
    </div>
  );
};
