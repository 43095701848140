import { useEffect, useRef, FC } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useOrderDispatch } from "providers/OrderFilter";
import moment from "moment";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Order } from "providers/Orders/types";
import {
  useReceiveOrderDispatchContext,
  useReceiveOrderState,
} from "providers/ReceiveOrders";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import useRole from "../../../hooks/useRole";
import { set_orderID } from "../../../providers/OrderFilter/ordersAction";
import { Icon, PageWrapper } from "components/base";
import "../style.scss";
import OrderDetailsSkeleton from "../OrderDetailsSkeleton";
import plusSvg from "../../../assets/img/plus.svg";
import { txBuyStatusInfo } from "utils/constants";
import InvoiceReceive from "components/InvoiceReceive";

const ReceiveOrderDetails: FC = () => {
  const { getOrderById } = useReceiveOrderDispatchContext();
  const orderDispatch = useOrderDispatch();
  const { orderDetails: order } = useReceiveOrderState();

  // const [sendEmail, setSendingEmail] = useState(false);

  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();

  const role = useRole();
  const ditailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (slug) {
      getOrderById(slug);
      orderDispatch(set_orderID(slug));
    }
  }, [getOrderById, slug, orderDispatch]);

  // const transferUrl = order?.tx_id && getTransferUrl(order.tx_id, order.asset);

  const country = new URLSearchParams(location.search).get("country");

  if (country && order) {
    order.country = country.trim();
  }

  console.log("Order details is", order);

  return (
    <PageWrapper title={"Transaction Details"}>
      <div
        className="content d-flex flex-column flex-column-fluid"
        // ref={ditailsRef}
      >
        {!order ? (
          <OrderDetailsSkeleton />
        ) : (
          <div className="container" id="kt_content_container">
            <div className="d-flex flex-column">
              <div className="flex-column">
                <div className="card card-order">
                  <div className="order--header">
                    <div className="d-flex  flex-row card-details-header">
                      <div className="card-details-header-content">
                        <div className="card-details-header-content-body">
                          <span className="card-details-header-content-body-name">
                            {order?.receiver_client?.first_name &&
                              order?.receiver_client?.last_name &&
                              `${order?.receiver_client?.first_name} ${order?.receiver_client?.last_name}`}
                          </span>
                        </div>
                        <div className="card-details-header-content-id">
                          <Link to={`/users/details/${order?.client_id}`}>
                            {order?.receiver_client?.email}
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <InvoiceSell order={order as Order} /> */}
                    <div
                      style={{ display: "flex" }}
                      className="order--header-button sell-flow"
                    >
                      {role === "super_admin" && (
                        <RoleProvider roles={[ROLES.SUPER_ADMIN]}>
                          <PDFDownloadLink
                            document={<InvoiceReceive order={order as Order} />}
                          >
                            <button>
                              <img src={plusSvg} />
                              Download pdf
                            </button>
                          </PDFDownloadLink>
                        </RoleProvider>
                      )}
                    </div>
                    <div
                      style={{ display: "flex" }}
                      className="order--header-button"
                    ></div>
                  </div>
                  <div className="table-content" ref={ditailsRef}>
                    <table className="main-table">
                      {role && order && (
                        <tbody>
                          <tr className="order-details-tr">
                            <td className="td-definition">Transaction ID</td>
                            <td className="td-answer">{order?.order_id}</td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Address</td>
                            <td className="td-answer">{order?.address}</td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Amount</td>
                            <td className="td-answer">{order?.amount}</td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Asset</td>
                            <td className="td-answer">{order?.asset_name}</td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Client Id</td>
                            <td className="td-answer">{order?.client_id}</td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Comment</td>
                            <td className="td-answer">
                              {order?.comment ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Core</td>
                            <td className="td-answer">{order?.core ?? "-"}</td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Fiat</td>
                            <td className="td-answer">
                              {order?.currency ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Fee option</td>
                            <td className="td-answer">
                              {order?.currency ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Asset quantity</td>
                            <td className="td-answer">
                              {order?.order?.asset_quantity ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Cost of service</td>
                            <td className="td-answer">
                              {order?.order?.cost_of_service ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Network fee</td>
                            <td className="td-answer">
                              {order?.order?.network_fee ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">
                              Partner commission
                            </td>
                            <td className="td-answer">
                              {order?.order?.partner_commission ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Payer email</td>
                            <td className="td-answer">
                              {order?.order?.payer_client?.email ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Price</td>
                            <td className="td-answer">
                              {order?.order?.price ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Spot price</td>
                            <td className="td-answer">
                              {order?.order?.spot_price ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Payer status</td>
                            <td className="td-answer">
                              {order?.order?.spot_price ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Swaps Revenue</td>
                            <td className="td-answer">
                              {order?.order?.swaps_profit ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Tr Status</td>
                            {/* <td className="td-answer">
                              {order?.order?.tr_status ?? "-"}
                            </td> */}
                            <td className="td-answer">
                              {order?.order?.tr_status ? (
                                <div
                                  className={`tr-status user-status-td ${
                                    txBuyStatusInfo[
                                      (order?.order?.tr_status as string)
                                        ?.toLowerCase()
                                        ?.replaceAll(" ", "_")
                                    ]?.className
                                  }`}
                                >
                                  {(order?.order?.tr_status as string)?.replace(
                                    /(^|\s)\S/g,
                                    (l) => l.toUpperCase()
                                  )}
                                  <Icon
                                    name={
                                      txBuyStatusInfo[
                                        (order?.order?.tr_status as string)
                                          ?.toLowerCase()
                                          ?.replaceAll(" ", "_")
                                      ]?.icon
                                    }
                                  />
                                </div>
                              ) : (
                                "-"
                              )}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Tx Id</td>
                            <td className="td-answer">
                              {order?.order?.tx_id ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Partner email</td>
                            <td className="td-answer">
                              {order?.partner?.email ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Partner email</td>
                            <td className="td-answer">
                              {order?.partner_id ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Receive Status</td>
                            <td className="td-answer">
                              {
                                {
                                  completed: (
                                    <div className="order-status-td order-status-td-success">
                                      Completed
                                    </div>
                                  ),
                                  failed: (
                                    <div className="order-status-td order-status-td-failed">
                                      Failed
                                    </div>
                                  ),
                                  pending: (
                                    <div className="order-status-td order-status-td-panding">
                                      Pending
                                    </div>
                                  ),
                                }[order?.status as string]
                              }
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Date</td>
                            <td className="td-answer">
                              {moment(order?.createdAt).format(
                                "DD MMM yyyy, hh:mm"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default ReceiveOrderDetails;
