import { useEffect, useMemo, useState } from "react";
import { useWidgetConsoleDispatch } from "providers/WidgetConsole";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";

import { Icon, PageWrapper } from "components/base";
import CryptoDetailsSkeleton from "../DetailsSkeleton";
import { FiatProps } from "../WidgetConsole.props";

const FiatDetails = (): JSX.Element => {
  const [fiatDetails, setFiatDetails] = useState<FiatProps | null>(null);
  const location = useLocation();
  const params: { id: string } = useParams();
  const { getFiatById } = useWidgetConsoleDispatch();

  useEffect(() => {
    if (params?.id) {
      (async () => {
        try {
          const data = await getFiatById(
            params?.id,
            location.pathname.includes("sell-flow") ? "sell-flow" : "buy-flow"
          );

          if (data) {
            setFiatDetails(data as FiatProps);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  const renderMaxAmount = useMemo(() => {
    if (location.pathname.includes("sell-flow")) {
      return (
        <tr className="order-details-tr">
          <td className="td-definition">Max Sell Amount</td>
          <td className="td-answer">{fiatDetails?.max_sell_amount ?? "-"}</td>
        </tr>
      );
    }

    if (location.pathname.includes("buy-flow")) {
      return (
        <>
          <tr className="order-details-tr">
            <td className="td-definition">Max Amount</td>
            <td className="td-answer">{fiatDetails?.max_amount ?? "-"}</td>
          </tr>
          <tr className="order-details-tr">
            <td className="td-definition">Max Buy Amount</td>
            <td className="td-answer">{fiatDetails?.max_buy_amount ?? "-"}</td>
          </tr>
        </>
      );
    }
  }, [location, fiatDetails]);

  const renderMinAmount = useMemo(() => {
    if (location.pathname.includes("sell-flow")) {
      return (
        <tr className="order-details-tr">
          <td className="td-definition">Min Sell Amount</td>
          <td className="td-answer">{fiatDetails?.min_sell_amount ?? "-"}</td>
        </tr>
      );
    }

    if (location.pathname.includes("buy-flow")) {
      return (
        <>
          <tr className="order-details-tr">
            <td className="td-definition">Min Amount</td>
            <td className="td-answer">{fiatDetails?.min_amount ?? "-"}</td>
          </tr>
          <tr className="order-details-tr">
            <td className="td-definition">Min Buy Amount</td>
            <td className="td-answer">{fiatDetails?.min_buy_amount ?? "-"}</td>
          </tr>
        </>
      );
    }
  }, [location, fiatDetails]);

  const renderStatus = useMemo(() => {
    if (location.pathname.includes("sell-flow")) {
      return (
        <tr className="order-details-tr">
          <td className="td-definition">Status</td>
          <td className="td-answer user-list-td-grey suspend-status">
            <div>
              {fiatDetails?.is_sell_supported ? (
                <div className="user-status-td transaction-status-td user-status-active">
                  Supported
                  <Icon name="success_check" />
                </div>
              ) : (
                <div className="user-status-td transaction-status-td user-status-blocked">
                  Not Supported
                  <Icon name="order_reject" />
                </div>
              )}
            </div>
          </td>
        </tr>
      );
    }

    if (location.pathname.includes("buy-flow")) {
      return (
        <tr className="order-details-tr">
          <td className="td-definition">Status</td>
          <td className="td-answer user-list-td-grey suspend-status">
            <div>
              {fiatDetails?.is_suspended ? (
                <div className="user-status-td transaction-status-td user-status-blocked">
                  Not Supported
                  <Icon name="order_reject" />
                </div>
              ) : (
                <div className="user-status-td transaction-status-td user-status-active">
                  Supported
                  <Icon name="success_check" />
                </div>
              )}
            </div>
          </td>
        </tr>
      );
    }
  }, [location, fiatDetails]);

  return (
    <PageWrapper title={"Transaction Details"}>
      <div className="content d-flex flex-column flex-column-fluid">
        {!fiatDetails ? (
          <CryptoDetailsSkeleton />
        ) : (
          <div className="container" id="kt_content_container">
            <div className="d-flex flex-column">
              <div className="flex-column">
                <div className="card card-order">
                  <div className="order--header">
                    <div
                      style={{ display: "flex" }}
                      className="order--header-button"
                    >
                      <span className="card-details-header-content-body-name">
                        {fiatDetails.name}
                      </span>
                    </div>
                  </div>
                  <div className="table-content">
                    <table className="main-table">
                      <tbody>
                        <tr className="order-details-tr">
                          <td className="td-definition">ID</td>
                          <td className="td-answer">{fiatDetails?.id}</td>
                        </tr>
                        {renderStatus}
                        {renderMaxAmount}
                        {renderMinAmount}
                        <tr className="order-details-tr">
                          <td className="td-definition">Name</td>
                          <td className="td-answer">{fiatDetails?.name}</td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Sign</td>
                          <td className="td-answer">{fiatDetails?.sign}</td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Symbol</td>
                          <td className="td-answer">{fiatDetails?.symbol}</td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Created At</td>
                          <td className="td-answer">
                            {moment(fiatDetails?.createdAt).format(
                              "DD MMM yyyy, hh:mm"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default FiatDetails;
