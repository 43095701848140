import React, {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
} from "react";
import * as fileSaver from "file-saver";
import { useAuthDispatchContext } from "providers/Auth";
import moment from "moment";
import { AxiosError } from "axios";

import { State, Dispatch, ActionTypes, DispatchContext } from "./types";
import reducer from "./reducers";
import { instance, assetInstance } from "utils/api";
import { showError } from "utils/helpers";

const initialState: State = {
  isLoading: true,
  orders: [],
  ordersCount: 0,
  orderDetails: null,
  totalAmount: [],
  totalCommission: [],
  totalOrders: [],
  transactionStatuses: [],
  updatedStatus: { tr_status: "", order_id: "" },
};

const OrderStateContext = createContext<State | undefined>(undefined);
const OrderDispatchContext = createContext<Dispatch | undefined>(undefined);

const OrderProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <OrderStateContext.Provider value={state}>
      <OrderDispatchContext.Provider value={dispatch}>
        {children}
      </OrderDispatchContext.Provider>
    </OrderStateContext.Provider>
  );
};

const useOrderState = (): State => {
  const context = useContext(OrderStateContext);

  if (typeof context === "undefined") {
    throw new Error("useOrderStateContext must be used within a OrderProvider");
  }

  return context;
};

const useOrderDispatchContext = (): DispatchContext => {
  const dispatch = useContext(OrderDispatchContext);
  const { signOut } = useAuthDispatchContext();

  if (typeof dispatch === "undefined") {
    throw new Error(
      "useOrderDispatchContext must be used within a OrderProvider"
    );
  }

  const getTrStatuses = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await instance.get("/payment/orders/statuses");

      dispatch({ type: ActionTypes.SET_TR_STATUSES, data });
    } catch (err: unknown) {
      if (
        ((err as AxiosError).response?.status === 401 &&
          !localStorage.getItem("refreshToken")) ||
        (err as AxiosError).response?.status === 401
      ) {
        return signOut();
      }

      showError(err);
    }
  }, [signOut, dispatch]);

  const updateStatus = useCallback(
    async ({
      id,
      tr_status,
      comments,
    }: {
      id: string;
      tr_status: string;
      comments: string;
    }) => {
      try {
        const { data: data } = await instance.put(
          `/payment/orders/details/${id}`,
          {
            tr_status,
            comment: comments,
          }
        );

        return data;
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const getOrders = useCallback(
    async (
      page,
      orderSearch,
      filters,
      dateFrom,
      dateTo,
      amountFrom,
      amountTo,
      rateFrom,
      rateTo,
      quantityFrom,
      quantityTo,
      visibleOrdersCount,
      assetOrder,
      exchangeOrder,
      withdrawalOrder,
      currencyOrder,
      ordering2,
      sorting2,
      partnerOrder,
      paymentMethods,
      trStatus,
      _,
      billingCountry
    ) => {
      dispatch({ type: ActionTypes.SET_LOADING, data: true });

      const getToDate = () => {
        if (moment().format("MM-DD") === moment(dateTo)?.format("MM-DD"))
          return undefined;

        return dateTo ? moment(dateTo).format("YYYY-MM-DD") : undefined;
      };

      try {
        const res = await instance.get("/payment/orders", {
          params: {
            page: page,
            keyword: orderSearch === "" ? undefined : orderSearch,
            statuses: filters,
            fromDate: dateFrom
              ? moment(dateFrom).format("YYYY-MM-DD")
              : undefined,
            toDate: getToDate(),
            fromAmount: amountFrom !== "" ? +amountFrom : undefined,
            toAmount:
              +amountTo < +amountFrom || amountTo === ""
                ? undefined
                : +amountTo,
            fromRate: rateFrom !== "" ? +rateFrom : undefined,
            toRate: +rateTo < +rateFrom || rateTo === "" ? undefined : +rateTo,
            fromQuantity: quantityFrom === "" ? undefined : +quantityFrom,
            toQuantity:
              quantityTo === ""
                ? undefined
                : +quantityTo < +quantityFrom
                ? undefined
                : +quantityTo,
            limit:
              visibleOrdersCount === "" || visibleOrdersCount === undefined
                ? "10"
                : +visibleOrdersCount,
            assets: assetOrder,
            currencies: currencyOrder,
            exchanges: exchangeOrder,
            sortBy: sorting2,
            orderBy: sorting2 === undefined ? undefined : ordering2,
            partners: partnerOrder,
            paymentMethods,
            withdrawals: withdrawalOrder,
            ...(trStatus && { trStatus }),
            ...(billingCountry && { country: billingCountry }),
          },
        });

        dispatch({ type: ActionTypes.SET_ORDERS, data: res.data.data });
        dispatch({ type: ActionTypes.SET_LOADING, data: false });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const getClients = useCallback(
    async (page, search = "", filters = {}) => {
      try {
        const res = await instance.get("/payment/orders", {
          params: { page, search, ...filters },
        });

        dispatch({ type: ActionTypes.SET_ORDERS, data: res.data.data });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const getOrdersStatistics = useCallback(async () => {
    try {
      const res = await instance.get("/payment/orders/stats", {
        params: { page: 1, limit: 1 },
      });

      dispatch({
        type: ActionTypes.SET_ORDERS_STATISTICS,
        data: res.data.data,
      });
    } catch (err: unknown) {
      if (
        ((err as AxiosError).response?.status === 401 &&
          !localStorage.getItem("refreshToken")) ||
        (err as AxiosError).response?.status === 401
      ) {
        return signOut();
      }

      showError(err);
    }
  }, [dispatch, signOut]);

  const downloadOrders = useCallback(
    async (
      visibleOrdersCount,
      sorting2,
      ordering2,
      selectedOrder,
      checkedIDOrder,
      orders,
      arrayCheckingOrder,
      orderSearch,
      filters,
      dateFrom,
      dateTo,
      amountFrom,
      amountTo,
      rateFrom,
      rateTo,
      quantityFrom,
      quantityTo,
      assetOrder,
      exchangeOrder,
      currencyOrder,
      partnerOrder,
      withdrawalOrder,
      trStatus,
      _,
      country
    ) => {
      const getDate = (date, type = "to") => {
        if (moment().format("MM-DD") === moment(date)?.format("MM-DD"))
          return undefined;

        if (type === "from") {
          return date ? `${moment(date).format("YYYY-MM-DD")}` : undefined;
        }

        return date
          ? `${moment(date).format("YYYY-MM-DD")}T${
              moment(date)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      try {
        const res = await instance.get("/payment/orders/download", {
          params: {
            sortBy: sorting2,
            orderBy: sorting2 === undefined ? undefined : ordering2,
            orders: orders !== undefined ? selectedOrder : undefined,
            keyword: orderSearch === "" ? undefined : orderSearch,
            statuses: filters,
            fromDate: getDate(dateFrom, "from"),
            toDate: moment(getDate(dateTo)).format("YYYY-MM-DD"),
            fromAmount: amountFrom ? +amountFrom : undefined,
            toAmount:
              !amountTo || +amountTo < +amountFrom ? undefined : +amountTo,
            fromRate: rateFrom ? +rateFrom : undefined,
            toRate: !rateTo || +rateTo < +rateFrom ? undefined : +rateTo,
            fromQuantity: !quantityFrom ? undefined : +quantityFrom,
            toQuantity: !quantityTo
              ? undefined
              : +quantityTo < +quantityFrom
              ? undefined
              : +quantityTo,
            assets: assetOrder,
            currencies: currencyOrder,
            exchanges: exchangeOrder,
            partners: partnerOrder,
            ...(withdrawalOrder && { withdrawals: withdrawalOrder }),
            ...(trStatus && { trStatus }),
            ...(country && { country }),
          },
          responseType: "blob",
        });

        const date = moment().format("DD-MM-YYY_hh:mm");

        fileSaver.saveAs(res.data as unknown as Blob, `orders-${date}.xlsx`);
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [signOut]
  );

  const getOrderById = useCallback(
    async (id) => {
      try {
        const res = await instance.get(`/payment/orders/details/${id}`);

        dispatch({
          type: ActionTypes.SET_ORDER_DETAILS,
          data: res.data.data.result,
        });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const deleteOrder = useCallback(
    async (id) => {
      try {
        await instance.delete(`/payment/orders/${id}`);

        dispatch({
          type: ActionTypes.DELETE_ORDER,
          data: id,
        });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const setLoading = (isLoading: boolean) =>
    dispatch({ type: ActionTypes.SET_LOADING, data: isLoading });

  const setUpdatedStatus = ({ tr_status, order_id }) => {
    dispatch({
      type: ActionTypes.SET_UPDATES_STATUS,
      data: { tr_status, order_id },
    });
  };

  const getFiatList = async () => {
    try {
      const { data } = await assetInstance.get("/fiat/fiat_currencies");

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAssetList = async () => {
    try {
      const { data } = await assetInstance.get(
        "/crypto/admin_crypto_currencies"
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const resetOrdersCount = () => {
    dispatch({ type: ActionTypes.RESET_ORDERS_COUNT, data: 0 });

    return;
  };

  return {
    getOrders,
    getClients,
    downloadOrders,
    getOrderById,
    getOrdersStatistics,
    deleteOrder,
    setLoading,
    getTrStatuses,
    updateStatus,
    setUpdatedStatus,
    getFiatList,
    getAssetList,
    resetOrdersCount,
  };
};

export default OrderProvider;
export { useOrderDispatchContext, useOrderState };
