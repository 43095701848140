import { useMemo } from "react";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";
import {
  set_amountFrom,
  set_amountTo,
  set_checkedIDOrder,
  set_email,
  set_filteringOrder,
  set_id,
  set_orderFilters,
  set_orderPage,
  set_payer_email,
  set_receive_id,
  set_receive_status,
} from "providers/OrderFilter/ordersAction";
import { set_checked_pages } from "providers/ClientFilter/clientsActions";

import FilterList from "components/Filter/FilterList";
import { OrderFilterWrapper } from "./OrderFilterWrapper.props";
import {
  email_regex,
  receiveOrdersFilterTree,
  receiveOrdersSecondFilterTree,
} from "utils/constants";
import FilterListSkeleton from "components/FilterListSkeleton";
import Filter from "components/Filter";

const ReceiveOrderFiltersWrapper = ({
  setLoading,
}: OrderFilterWrapper): JSX.Element => {
  const orderDispatch = useOrderDispatch();

  const {
    email,
    amountFrom,
    amountTo,
    filters,
    payerEmail,
    receiveStatus,
    id,
    receiveId,
  } = useOrderFilters();

  const handleFirstLevelFilter = (selectedOption) => {
    setLoading(true);

    if (selectedOption === "ALL") {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_receive_status(undefined));
      orderDispatch(set_orderFilters([]));
      orderDispatch(set_orderPage(1));
      orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
      orderDispatch(set_checked_pages([]));
      orderDispatch(set_filteringOrder(true));

      return;
    }

    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_receive_status(selectedOption));
    orderDispatch(set_orderPage(1));
    orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
    orderDispatch(set_checked_pages([]));
    orderDispatch(set_filteringOrder(true));
  };

  const handleAmount = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void,
    amountType?: string | undefined
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (amountType === "from") {
      orderDispatch(
        set_amountFrom((e.target.value === "" && undefined) || e.target.value)
      );
    }

    if (amountType === "to") {
      orderDispatch(
        set_amountTo((e.target.value === "" && undefined) || e.target.value)
      );
    }

    orderDispatch(set_orderPage(1));
    setIsValid(true);
    orderDispatch(set_filteringOrder(false));

    return;
  };

  const handleEmail = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim().match(email_regex)?.length) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_email(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("Email is invalid");

    return;
  };

  const handlePayerEmail = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim().match(email_regex)?.length) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_payer_email(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("Email is invalid");

    return;
  };

  const handleID = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim()) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_id(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("User id is invalid");
  };

  const handleReceiveID = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim()) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_receive_id(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("User id is invalid");
  };

  const clearOrder = () => {
    orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
    orderDispatch(set_checked_pages([]));
  };

  const secondLevelHandlers = [
    handleAmount,
    handleEmail,
    handlePayerEmail,
    handleID,
    handleReceiveID,
  ];

  const handleApply = (type?: string) => {
    orderDispatch(set_filteringOrder(true));

    if (type === "date_picker" || type === "number_range") {
      setTimeout(() => {
        clearOrder();
      }, 500);
    }
  };

  const enableClearFilters = useMemo(() => {
    if (email || amountFrom || amountTo || receiveStatus || payerEmail) {
      return true;
    }

    return false;
  }, [payerEmail, email, amountFrom, amountTo, receiveStatus]);

  const additionaHandlers = [];

  const clearAdditionalSearches = [];

  const resetAmount = (clearReset = false, context = false) => {
    if (context) {
      orderDispatch(set_amountFrom(""));
      orderDispatch(set_amountTo(""));
      orderDispatch(set_orderPage(1));

      return;
    }

    if (clearReset && !amountFrom && !amountTo) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_amountFrom(""));
    orderDispatch(set_amountTo(""));
    orderDispatch(set_orderPage(1));
    handleApply();

    return;
  };

  const resetOrderEmail = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_email(undefined));
    }

    if (clearReset && !email) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_email(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetPayerEmail = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_payer_email(undefined));
    }

    if (clearReset && !email) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_payer_email(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetId = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_id(""));
    }

    if (clearReset && !id) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_id(""));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetReceiveId = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_receive_id(""));
    }

    if (clearReset && !receiveId) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_receive_id(""));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const clearSearches = [
    resetAmount,
    resetOrderEmail,
    resetPayerEmail,
    resetId,
    resetReceiveId,
  ];

  return (
    <>
      <Filter
        handleFirstLevelFilter={handleFirstLevelFilter}
        filterValues={receiveOrdersFilterTree}
        filters={filters}
      />
      {receiveOrdersSecondFilterTree?.length ? (
        <FilterList
          secondLevelHandlers={secondLevelHandlers}
          secondLevelFilter={receiveOrdersSecondFilterTree}
          handleApply={handleApply}
          enableClearFilters={enableClearFilters}
          clearSearches={clearSearches}
          additionalHandlers={additionaHandlers}
          clearAdditionalSearches={clearAdditionalSearches}
          clearSelect={clearOrder}
        />
      ) : (
        <FilterListSkeleton />
      )}
    </>
  );
};

export default ReceiveOrderFiltersWrapper;
