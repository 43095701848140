import { useCallback, useEffect, useMemo, useState } from "react";
import "../events.scss";
import { getEventList } from "services/webhooks";
import { EventItem, EventsResponse } from "services/webhooks/types";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { usePartnerStateContext } from "providers/Partners";

import EventRowSkeleton from "../EventRowSkeleton";
import Button from "components/Button";

const PartnerEventsPage = (): JSX.Element => {
  const { partnerDetails } = usePartnerStateContext();
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<EventItem[]>([]);
  const [count, setCount] = useState(0);
  const [eventStatus, setEventStatus] = useState<"failed" | "success" | "all">(
    "all"
  );

  const fetchEvents = useCallback(async () => {
    try {
      setLoading(true);
      const response: EventsResponse = await getEventList({
        page,
        partnerId: partnerDetails?.id,
        ...(eventStatus !== "all" && { status: eventStatus }),
      });

      setEvents(response.rows);
      setCount(response.count);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, [page, partnerDetails, eventStatus]);

  useEffect(() => {
    fetchEvents();
  }, [fetchEvents]);

  const renderEvents = useMemo(() => {
    if (events?.length) {
      return events?.map((eventItem) => (
        <tr
          onClick={() =>
            history.push(
              `/setting/events/${eventItem.id}/${partnerDetails?.id}`
            )
          }
          key={eventItem.id}
        >
          <td>{eventItem.event.name}</td>
          <td>{eventItem.id}</td>
          <td>{moment(eventItem.createdAt).format("DD MMM yyyy, hh:mm")}</td>
        </tr>
      ));
    }

    return (
      <tr style={{ pointerEvents: "none" }}>
        <td colSpan={4} style={{ textAlign: "center" }}>
          <span>The partner doesn&apos;t have events created.</span>
        </td>
      </tr>
    );
  }, [events, history, partnerDetails]);

  return (
    <div className="events__container--outer">
      <div className="events__container">
        <div className="events__status-wrapper">
          <button
            onClick={() => setEventStatus("all")}
            className={eventStatus === "all" ? "active" : ""}
          >
            all
          </button>
          <button
            className={eventStatus === "success" ? "active" : ""}
            onClick={() => setEventStatus("success")}
          >
            success
          </button>
          <button
            className={eventStatus === "failed" ? "active" : ""}
            onClick={() => setEventStatus("failed")}
          >
            failed
          </button>
        </div>
        <table className="events__table">
          <thead>
            <tr>
              <th>Event</th>
              <th>ID</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan={3}>
                  <EventRowSkeleton />
                </td>
              </tr>
            ) : (
              renderEvents
            )}
          </tbody>
        </table>
      </div>
      <div className="table--pagination">
        <span className="table--result">{count} results</span>
        <div className="pagination--buttons-wrapper">
          <Button
            onClick={() => setPage((prev) => prev - 1)}
            type="button"
            className="button-secondary"
            disabled={page === 1}
          >
            Previous
          </Button>
          <Button
            onClick={() => setPage((prev) => prev + 1)}
            type="button"
            className="button-secondary"
            disabled={page >= Math.ceil(count / 10)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PartnerEventsPage;
