/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import {
  useNotificationDispatchContext,
  useNotificationStateContext,
} from "providers/Notifications";

import NotificationSkeleton from "./NotificationSekeleton";
import Popover from "../../../components/Popover";
import ProfileItem from "../ProfileItem";
import NotificationCount from "./NotificationCount";
import NotificationItem from "./NotificationItem";
import ZeroNotification from "./ZeroNotification";
import { TransactionNotificationType } from "./Notification.props";
import useRole from "hooks/useRole";

const Notification = (): JSX.Element => {
  const role = useRole();
  const { setAsWatched, setWatched, paginate } =
    useNotificationDispatchContext();

  const {
    socket,
    transactionNotifications,
    notificationCount,
    unwatchedCount,
    isLoading,
  } = useNotificationStateContext();

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [wasPopupOpened, setWasPopupOpened] = useState(false);

  const id = localStorage.getItem("user-id");

  useEffect(() => {
    if (inView) {
      paginate();
    }
    // eslint-disable-next-line
  }, [inView]);

  useEffect(() => {
    if (!isPopoverOpen && wasPopupOpened) {
      setWatched();
    }

    if (isPopoverOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    // eslint-disable-next-line
  }, [isPopoverOpen, wasPopupOpened]);

  return (
    <Popover
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      onClick={() => {
        setWasPopupOpened(true);

        if (unwatchedCount) {
          setAsWatched(id as string, socket);
        }
      }}
      position={["bottom"]}
      className="profile__popover"
      component={
        <div className="notification-button__wrapper">
          {unwatchedCount > 0 && <NotificationCount count={unwatchedCount} />}

          <ProfileItem iconName="notification_icon" onClick={() => null} />
        </div>
      }
    >
      <div className="notifications__wrapper">
        <div className="notifications__header-wrapper">
          <h1 className="notifications__header-content">
            Notifications{" "}
            <span className="notification-count">{notificationCount}</span>
          </h1>
        </div>
        {transactionNotifications?.size > 0 ? (
          <ul className="notifications__list">
            {Array.from(
              transactionNotifications as Set<TransactionNotificationType>
            )
              .sort((a, b) => {
                const aJson = JSON.parse(a as unknown as string);
                const bJson = JSON.parse(b as unknown as string);

                return aJson.isWatched === bJson.isWatched
                  ? 0
                  : bJson.isWatched
                  ? -1
                  : 1;
              })
              .map((item) => {
                const transaction = JSON.parse(item as unknown as string);

                return (
                  <NotificationItem
                    key={transaction.id + Math.random() * 9}
                    transaction={transaction}
                    socket={socket}
                    role={role}
                  />
                );
              })}
            <li ref={ref} className="intersection__block">
              {isLoading && <NotificationSkeleton />}
            </li>
          </ul>
        ) : (
          <ZeroNotification />
        )}
      </div>
    </Popover>
  );
};

export default Notification;
