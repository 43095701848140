import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

import { instance } from "utils/api";
import { IDonutChartsData, IParams } from "./types";
import DonutChart from "components/Charts/Donut";

export function Countries(params: Omit<IParams, "interval">): JSX.Element {
  const [data, setData] = useState<IDonutChartsData[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);
      const {
        data: {
          data: { rows: data },
        },
      } = await instance.get(`/admin/summary/users-countries?limit=1000`, {
        params: {
          start: params.start,
          end: params.end,
        },
      });

      const newArr = data.map((el) => ({
        label: el.country,
        count: el.count,
      }));

      const firstArrr = newArr.slice(0, 9);
      const othersItem = newArr.slice(9).reduce(
        function (sum, current) {
          return {
            label: "Others",
            count: Number(sum.count) + Number(current.count),
          };
        },
        { label: "Others", count: 0 }
      );

      othersItem.count && firstArrr.push(othersItem);
      setData(firstArrr);

      setIsLoading(false);
    };

    if (params.start && params.end) getData();
  }, [params.start, params.end]);

  return isLoading ? (
    <ContentLoader
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      height="400px"
      width="100%"
    >
      <circle cx="200" cy="200" r="130" />
    </ContentLoader>
  ) : (
    <DonutChart
      title="Countries"
      infoContenet="Countries"
      progress={-34}
      total={data.length}
      data={data}
    />
  );
}
