import { FC, useEffect, useMemo, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import moment from "moment";
import { v4 as uuid } from "uuid";
import {
  useClientDispatchContext,
  useClientStateContext,
} from "providers/Clients";
import Switch from "react-switch";
import { Link, useRouteMatch } from "react-router-dom";
import { useClientFilters } from "providers/ClientFilter";
import { USER_TAB_PANEL_ACCESS } from "services/roleService/user/userDetails";
import { SWITCH_ACCESS } from "services/roleService/user/user";
import { useOrderDispatch } from "providers/OrderFilter";
import {
  set_filteringOrder,
  set_orderFilters,
  set_orderSearch,
  set_stats_filter,
} from "providers/OrderFilter/ordersAction";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import { TabSelector } from "./TabSelector";
import "./details.scss";
import RiskScoreTable from "./RiskScoreTable";
import useRole from "hooks/useRole";
import { createQuerySearchParams } from "utils/browserAPI";
import "./style.scss";
// import ClientDetailsLog from "./ClientDetailsLog";
import { Icon, PageWrapper } from "components/base";
import LogsTable from "./LogsTable";
import ClientDetailsSkeleton from "./ClientDetailsSkeleton";
import Tooltip from "components/Tooltip";
import Button from "components/Button";
import { copyToClipboard } from "utils/helpers";
import { intercomURL } from "utils/constants";
import TransactionStatistics from "./TransactionStatistics";
import { sellFlowInstance } from "utils/api";
import ClientPromotions from "./ClientPromotions";

const tabsRoles = {
  overview: [ROLES.SUPER_ADMIN, ROLES.AML, ROLES.MARKETING],
  logs: [ROLES.SUPER_ADMIN, ROLES.AML],
  promotions: [ROLES.SUPER_ADMIN, ROLES.MARKETING],
};

interface SellOrder {
  count: string;
  status: {
    id: string;
    name: string;
  };
}

const ClientDetails: FC = () => {
  const orderDispatch = useOrderDispatch();
  const role = useRole() as string;
  const tabs = Object.entries(tabsRoles)
    .filter((el) => el[1].includes(role))
    .map((el) => el[0]);

  const [selectedTab, setSelectedTab] = useTabs(tabs);
  // route

  const {
    params: { id, status },
  } = useRouteMatch<{ id: string; status: "active" | "blocked" }>();

  // context
  const { getClientById, updateClientStatus } = useClientDispatchContext();

  const { clientDetails: client } = useClientStateContext();
  const [sellOrders, setSellOrders] = useState<SellOrder[] | []>([]);

  const [load, setLoad] = useState(false);
  const [checked, setChecked] = useState(false);
  const [disableActivation, setDisableActivation] = useState(false);
  const { actionComment } = useClientFilters();

  useEffect(() => {
    const getSellTransaction = async () => {
      const token = localStorage.getItem("token");

      try {
        const { data } = await sellFlowInstance.get(
          `sell/orders/customers/${id}/orders_count`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (data?.result) {
          setSellOrders(data?.result);
        }

        await getClientById(id);
      } catch (error) {
        console.log(error);
        await getClientById(id);
      }
    };

    getSellTransaction();

    setLoad(false);
  }, [getClientById, status, id, load, updateClientStatus]);

  useEffect(() => {
    client?.status !== undefined && client?.status === "blocked"
      ? setChecked(false)
      : setChecked(true);
  }, [getClientById, client?.status, id]);

  const handleChange = async (nextChecked: boolean) => {
    setChecked(nextChecked);
    setDisableActivation(true);
    await updateClientStatus(
      id,
      nextChecked ? "active" : "blocked",
      actionComment
    );
    setDisableActivation(false);
  };

  const triggerStatsFilter = () => {
    orderDispatch(set_orderSearch(client?.email as string));
    orderDispatch(set_stats_filter(true));
    orderDispatch(set_filteringOrder(true));
  };

  const handleStatus = (status: string) => {
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_orderSearch(client?.email as string));
    orderDispatch(set_orderFilters([status]));
    orderDispatch(set_stats_filter(true));
    orderDispatch(set_filteringOrder(true));
  };

  const renderKYCStatus = useMemo(() => {
    if (!client) return;

    switch (client.kyc) {
      case "VERIFIED":
        return (
          <a
            href={client.sumsub_url as string}
            target="_blank"
            rel="noreferrer"
          >
            <div className="col-kyc-verification col-kyc-verifid">Verified</div>
          </a>
        );
      case "NOT_VERIFY":
        return (
          <div className="col-kyc-verification col-kyc-no-verifid">
            <span>Not verified</span>
          </div>
        );
      case "DECLINED":
        return (
          <a
            href={client.sumsub_url as string}
            target="_blank"
            rel="noreferrer"
          >
            <div className="col-kyc-verification col-kyc-no-verifid">
              <span>Declined</span>
            </div>
          </a>
        );
    }
  }, [client]);

  return role ? (
    <PageWrapper title="Customer Details">
      <div className="container">
        {!client ? (
          <ClientDetailsSkeleton />
        ) : (
          <div className="card--client-details">
            <div className="row-cd">
              <div className="col-cd cd--info">
                <div className="cd--info-header">
                  <h3>
                    {client?.name ? `${client.name} ${client.surname}` : "-"}
                  </h3>
                  <div className="cd--info-body">
                    <div className="cd--info-body-content">
                      <div className="cd--info-body-row">
                        <div className="cd--info-body-col cd--info-body-col-title">
                          Partner Name
                        </div>
                        <div className="cd--info-body-col cd--info-body-col-value">
                          <p>{client?.partner?.name || "-"}</p>
                        </div>
                      </div>
                      <div className="cd--info-body-row">
                        <div className="cd--info-body-col cd--info-body-col-title">
                          Customer ID
                        </div>
                        <div className="cd--info-body-col cd--info-body-col-value id-wrapper">
                          <p>{client?.id || "-"}</p>
                          <Tooltip content="Copied">
                            {(setShowTooltip) => (
                              <Button
                                type="button"
                                className="clipboard-button"
                                onClick={() => {
                                  copyToClipboard(client.id);
                                  setShowTooltip(true);
                                }}
                              >
                                <Icon name="clipboard" />
                              </Button>
                            )}
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                  </div>
                  {SWITCH_ACCESS.switch[role] && !client.delete_email ? (
                    <RoleProvider roles={[ROLES.SUPER_ADMIN, ROLES.AML]}>
                      <div className="cd--info-row">
                        <div className="cd--info-row-title">Activation</div>
                        <div className="cd--info-row-value">
                          <Switch
                            onColor={"#0570DE"}
                            offColor={"#EFF2F5"}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onChange={handleChange}
                            disabled={disableActivation}
                            checked={checked}
                            className={
                              checked
                                ? "react-switch active"
                                : "react-switch false"
                            }
                          />
                        </div>
                      </div>
                    </RoleProvider>
                  ) : (
                    ""
                  )}
                </div>
                <div className="cd--info-body">
                  <h4>Details</h4>
                  <div className="line--border"></div>
                  <div className="cd--info-body-content">
                    <div className="cd--info-body-row">
                      <div className="cd--info-body-col cd--info-body-col-title">
                        Email
                      </div>
                      <div className="cd--info-body-col cd--info-body-col-value">
                        <p>
                          <a
                            href={`${intercomURL}${client.email}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {client?.email}
                          </a>
                          <span className="cd--info-body-col-value-badge">
                            {(!client?.email_verified && "Not Verified") || (
                              <>
                                <span>Verified</span>
                              </>
                            )}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="cd--info-body-row">
                      <div className="cd--info-body-col cd--info-body-col-title">
                        First name
                      </div>
                      <div className="cd--info-body-col cd--info-body-col-value">
                        <p>{client?.first_name || "-"}</p>
                      </div>
                    </div>
                    <div className="cd--info-body-row">
                      <div className="cd--info-body-col cd--info-body-col-title">
                        Middle name
                      </div>
                      <div className="cd--info-body-col cd--info-body-col-value">
                        <p>{client?.middle_name || "-"}</p>
                      </div>
                    </div>
                    <div className="cd--info-body-row">
                      <div className="cd--info-body-col cd--info-body-col-title">
                        Last name
                      </div>
                      <div className="cd--info-body-col cd--info-body-col-value">
                        <p>{client?.last_name || "-"}</p>
                      </div>
                    </div>
                    <div className="cd--info-body-row">
                      <div className="cd--info-body-col cd--info-body-col-title">
                        Date of Birth
                      </div>
                      <div className="cd--info-body-col cd--info-body-col-value">
                        <p>
                          {client?.date_of_birth
                            ? moment(client?.date_of_birth).format("DD/MM/yyyy")
                            : ""}
                        </p>
                      </div>
                    </div>
                    <div className="cd--info-body-row">
                      <div className="cd--info-body-col cd--info-body-col-title">
                        Billing address
                      </div>
                      <div className="cd--info-body-col cd--info-body-col-value">
                        <p>
                          {client?.billing_address
                            ? `
                        ${
                          client?.billing_address?.address
                            ? client.billing_address.address + ","
                            : ""
                        }
                        ${
                          client.billing_address?.city
                            ? client.billing_address.city + ","
                            : ""
                        }
                        ${
                          client.billing_address?.state
                            ? client.billing_address.state + " "
                            : ""
                        }
                        ${
                          client.billing_address?.post_code
                            ? client.billing_address.post_code
                            : ""
                        }
                    `
                            : "-"}
                        </p>
                      </div>
                    </div>
                    <div className="cd--info-body-row">
                      <div className="cd--info-body-col cd--info-body-col-title">
                        Residence address
                      </div>
                      <div className="cd--info-body-col cd--info-body-col-value">
                        <p>
                          {client?.residence_address
                            ? `
                            ${
                              client.residence_address?.address
                                ? client.residence_address.address + ","
                                : ""
                            }
                            ${
                              client.residence_address?.city
                                ? client.residence_address.city + ","
                                : ""
                            }
                            ${
                              client.residence_address?.state
                                ? client.residence_address.state + " "
                                : ""
                            }
                            ${
                              client.residence_address?.post_code
                                ? client.residence_address.post_code
                                : ""
                            }
                        `
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-cd cd--tabs">
                {role && (
                  <nav className="cd--tabs-navbar">
                    {USER_TAB_PANEL_ACCESS.overview[role] && (
                      <RoleProvider roles={tabsRoles.overview}>
                        <TabSelector
                          isActive={selectedTab === "overview"}
                          onClick={() => setSelectedTab("overview")}
                        >
                          Overview
                        </TabSelector>
                      </RoleProvider>
                    )}
                    {USER_TAB_PANEL_ACCESS.log[role] && (
                      <RoleProvider roles={tabsRoles.logs}>
                        <TabSelector
                          isActive={selectedTab === "logs"}
                          onClick={() => setSelectedTab("logs")}
                        >
                          Logs
                        </TabSelector>
                      </RoleProvider>
                    )}
                    {USER_TAB_PANEL_ACCESS.risk_score[role] && (
                      <TabSelector
                        isActive={selectedTab === "risk_score"}
                        onClick={() => setSelectedTab("risk_score")}
                      >
                        Risk Score
                      </TabSelector>
                    )}
                    {USER_TAB_PANEL_ACCESS.overview[role] && (
                      <RoleProvider roles={tabsRoles.promotions}>
                        <TabSelector
                          isActive={selectedTab === "promotions"}
                          onClick={() => setSelectedTab("promotions")}
                        >
                          Promotions
                        </TabSelector>
                      </RoleProvider>
                    )}
                  </nav>
                )}

                <div>
                  <TabPanel
                    hidden={selectedTab !== "overview"}
                    className="overview--tabs"
                  >
                    <div className="overview--row">
                      <TransactionStatistics
                        flow="buy-flow"
                        title="Buy transactions"
                        triggerStatsFilter={triggerStatsFilter}
                        handleStatus={handleStatus}
                        createQuerySearchParams={createQuerySearchParams}
                        totalPayments={
                          (client?.orders_count ? client?.orders_count : {})
                            .total as number
                        }
                        successfulPayments={
                          client?.orders_count &&
                          (client?.orders_count.paid as number)
                        }
                        failedPayments={
                          client?.orders_count &&
                          (client?.orders_count.failed as number)
                        }
                        pendingPayments={
                          client?.orders_count &&
                          (client?.orders_count.pending as number)
                        }
                        email={client?.email}
                      />
                      {sellOrders?.length ? (
                        <div>
                          <div className="statistics-title__box">
                            <span>Sell transactions</span>
                          </div>
                          <div className="col-statistics__wrapper">
                            <div className="col-statistics">
                              {sellOrders?.map((sellOrder) => (
                                <div key={uuid()} className="statistics-box">
                                  <Link
                                    onClick={triggerStatsFilter}
                                    to={`/orders/sell-flow/${createQuerySearchParams(
                                      {
                                        user_email: client.email,
                                        trx_status: sellOrder.status.name,
                                      }
                                    )}`}
                                  >
                                    {sellOrder?.status.name
                                      .substr(0, 1)
                                      .toUpperCase() +
                                      sellOrder?.status.name
                                        .substr(1)
                                        .toLowerCase()}{" "}
                                    payments
                                  </Link>
                                  <b>{sellOrder?.count ?? 0}</b>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : null}
                      <div className="col-kyc">
                        <h4>KYC</h4>
                        {renderKYCStatus}
                      </div>
                      <div className="col-location">
                        <h4>Location</h4>
                        <div className="line--border"></div>
                        <div className="card-location-row">
                          <div className="card-location-row-title">
                            Registration IP
                          </div>
                          <div className="card-location-row-value">
                            <p>{client?.ip || "-"}</p>
                          </div>
                        </div>
                        <div className="card-location-row">
                          <div className="card-location-row-title">
                            Registration country
                          </div>
                          <div className="card-location-row-value">
                            <p>{client?.country || "-"}</p>
                          </div>
                        </div>
                        <div className="card-location-row">
                          <div className="card-location-row-title">
                            Residence country
                          </div>
                          <div className="card-location-row-value">
                            <p>
                              {client?.residence_address?.countries.country ||
                                "-"}
                            </p>
                          </div>
                        </div>
                        <div className="card-location-row">
                          <div className="card-location-row-title">
                            Billing country
                          </div>
                          <div className="card-location-row-value">
                            <p>
                              {client?.billing_address?.countries.country ||
                                "-"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    hidden={selectedTab !== "logs"}
                    className="logs--tabs"
                  >
                    <LogsTable id={id} />
                  </TabPanel>
                  <TabPanel
                    hidden={selectedTab !== "risk_score"}
                    className="risk-score--tabs"
                  >
                    <RiskScoreTable />
                  </TabPanel>
                  <TabPanel hidden={selectedTab !== "promotions"}>
                    <ClientPromotions />
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  ) : (
    <></>
  );
};

export default ClientDetails;
