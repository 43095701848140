import { FC } from "react";

import { PartnerDetailsGridProps } from "./PartnerDetails.props";
import PartnerDetailsItem from "./PartnerDetailsItem";

const PartnerDetailsGrid: FC<PartnerDetailsGridProps> = ({ items }) => {
  return (
    <div className="dashboard__grid-container partners">
      <div className="dashboard__grid">
        {items?.map((item, index) => (
          <PartnerDetailsItem
            key={index}
            title={item.title}
            value={item.value}
          />
        ))}
      </div>
    </div>
  );
};

export default PartnerDetailsGrid;
