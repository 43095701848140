import { CopyToClipboard } from "react-copy-to-clipboard";
import { useAuthStateContext } from "providers/Auth";
import { toast } from "react-toastify";

const IntegrationPage = (): JSX.Element => {
  const { user } = useAuthStateContext();

  const url = `${process.env.REACT_APP_INTEGRATION_URL}/checkout/${user?.name}/buy-crypto`;
  const iFrameString = `<iframe width="450" height="650" src="${process.env.REACT_APP_INTEGRATION_URL}/iframe/checkout/${user?.name}/buy-crypto" title=${user?.name}> </iframe>`;

  return (
    <>
      <p className="setting-text">
        Copy the code and paste it into your site to integrate the Swaps widget.
        For complete control of the widget, check out the{" "}
        <a href="https://apidoc.swaps.app/" target={"_blank"} rel="noreferrer">
          API documentation
        </a>
        .
      </p>
      <div>
        <CopyToClipboard
          options={{ message: "" }}
          text={url}
          onCopy={() => toast.success("Copied to the clipboard")}
        >
          <div className="clip-card cursor-pointer">
            <div className="iframe_sw">
              <p className="iframe_title">Checkout URL</p>
              <p>{url}</p>
            </div>
          </div>
        </CopyToClipboard>
      </div>
      <div>
        <CopyToClipboard
          options={{ message: "" }}
          text={iFrameString}
          onCopy={() => toast.success("Copied to the clipboard")}
        >
          <div className="clip-card cursor-pointer">
            <div className="iframe_sw">
              <p className="iframe_title">
                # Swaps.app Widget Integration iframe
              </p>
              <p>{iFrameString}</p>
            </div>
          </div>
        </CopyToClipboard>
      </div>
    </>
  );
};

export default IntegrationPage;
