import ContentLoader from "react-content-loader";

const NotificationSkeleton = (): JSX.Element => {
  return (
    <ContentLoader width="100%" height="50px">
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="40" />
    </ContentLoader>
  );
};

export default NotificationSkeleton;
