import { FC, useEffect, useMemo, useState, useLayoutEffect } from "react";
import {
  usePartnerDispatchContext,
  usePartnerStateContext,
} from "providers/Partners";
import { TabPanel, useTabs } from "react-headless-tabs";
import { Link, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { toast } from "react-toastify";
import { useBalanceDispatchContext, useBalanceState } from "providers/Balance";
import { PayoutItem } from "providers/Balance/types";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import { Icon, PageWrapper } from "components/base";
import PartnerClientsTable from "../PartnerClientsTable";
import { TabSelector } from "../../Clients/ClientDetails/TabSelector";
import { PartnerDetailsItemProps } from "./PartnerDetails.props";
import PartnerDetailsGrid from "./PartnerDetailsGrid";
import Button from "components/Button";
import PartnerDetailsSkeleton from "./PartnerDetailsSkeleton";
import BalanceBanner from "pages/Balance/BalanceBanner";
import BalanceFiltersWrapper from "pages/Balance/BalanceFiltersWrapper";
import BalanceTable from "pages/Balance/BalanceTable";
import ClientExportButtons from "./ClientsExportButtons";
import BalanceExportButtons from "./BalanceExportButtons";
import BalanceBannerSkeleton from "pages/Balance/BalanceBannerSkeleton";
import PartnerWebhooksPage from "pages/PartnerSettings/WebhooksPage/Partner/PartnerWebhooksPage";
import PartnerEventsPage from "pages/PartnerSettings/EventsPage/Partner/PartnerEventPage";
import Promotions from "pages/PartnerSettings/Promotions";
import useRole from "hooks/useRole";

const tabsRoles = {
  overview: [ROLES.SUPER_ADMIN, ROLES.MARKETING],
  clients: [ROLES.SUPER_ADMIN, ROLES.MARKETING],
  balance: [ROLES.SUPER_ADMIN, ROLES.MARKETING],
  webhooks: [ROLES.SUPER_ADMIN],
  events: [ROLES.SUPER_ADMIN, ROLES.MARKETING],
  promotions: [ROLES.SUPER_ADMIN, ROLES.MARKETING],
};

const PartnerDetails: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const {
    prevPage,
    nextPage,
    walletAddress,
    page,
    loading,
    payInList,
    payoutList,
    activeBalance,
    balanceLoading,
    availableBalance,
    availablePayoutPage,
  } = useBalanceState();

  const {
    setWalletAddress,
    paginateBalance,
    setAvailableBalance,
    setBalanceLoading,
    setLoading,
  } = useBalanceDispatchContext();

  const role = useRole() as string;
  const tabs = Object.entries(tabsRoles)
    .filter((el) => el[1].includes(role))
    .map((el) => el[0]);

  const [selectedTab, setSelectedTab] = useTabs(tabs);

  const [partnerDetails, setPartnerDetails] = useState<
    PartnerDetailsItemProps[] | []
  >([]);

  // route
  const {
    params: { id },
  } = useRouteMatch<{ id: string }>();

  // context
  const { getPartnerById, clearPartnerId } = usePartnerDispatchContext();
  const { getPartnerBalance, getPayout, getPayoutStatuses } =
    useBalanceDispatchContext();

  const { partnerDetails: partner } = usePartnerStateContext();

  useEffect(() => {
    if (id) {
      getPartnerById(id);
    }

    return () => clearPartnerId();
  }, [clearPartnerId, getPartnerById, id]);

  const handleClickWidget = () => {
    navigator.clipboard.writeText(
      `<iframe src="https://swaps.app/widget?partner=${partner?.id}" allow="camera" frameborder="0" style="width: 404px; height: 600px"></iframe>`
    );
    toast.success("Copied to the clipboard");
  };

  useEffect(() => {
    if (!partner) return;

    const details = [
      {
        title: "Total payments",
        value: partner?.orders_count?.total as number,
      },
      {
        title: "Successfull payments",
        value: partner?.orders_count?.paid as number,
      },
      {
        title: "Failed payments",
        value: partner?.orders_count?.failed as number,
      },
      {
        title: "Pending payments",
        value: partner?.orders_count?.pending as number,
      },
      {
        title: "Number of registration",
        value: partner.clients_count.total as number,
      },
      {
        title: "Number of views",
        value: partner.logs_count?.view as number,
      },
      {
        title: "Number of verified users",
        value: partner?.clients_count?.verify as number,
      },
      {
        title: "Number of unverified users",
        value: partner?.clients_count?.notVerify as number,
      },
    ];

    (async () => {
      try {
        setLoading(true);
        setBalanceLoading(true);
        const balanceResponse = await getPartnerBalance({
          partnerId: partner.id as string,
          partnerName: partner.name as string,
          asset: process.env.REACT_APP_BALANCE_ASSET as string,
        });

        if (balanceResponse?.data.data) {
          setWalletAddress(balanceResponse.data.data.address);
          setAvailableBalance(balanceResponse.data.data.available);
          setTimeout(() => setBalanceLoading(false), 1200);
          await getPayoutStatuses();
          await getPayout({
            partnerId: partner.id,
            address: balanceResponse.data.data.address,
            limit: 10,
            page: 1,
            orderBy: "desc",
          });
        }
      } catch (error) {
        console.log(error);
      }
    })();

    setPartnerDetails(details);
    // eslint-disable-next-line
  }, [partner]);

  const renderExportButtons = useMemo(() => {
    switch (selectedTab) {
      case "clients":
        return <ClientExportButtons partnerId={partner?.id as string} />;
      case "balance":
        return <BalanceExportButtons partnerId={partner?.id as string} />;
      default:
        return null;
    }
  }, [selectedTab, partner]);

  const isNextButtonDisabled = useMemo(() => {
    if (activeBalance === "payout") {
      return page === availablePayoutPage || loading;
    }

    return !nextPage || (payInList as PayoutItem[]).length < 10 || loading;
  }, [activeBalance, availablePayoutPage, page, loading, nextPage, payInList]);

  const isPrevButtonDisabled = useMemo(() => {
    if (activeBalance === "payout") {
      return page === 1 || loading;
    }

    return !prevPage || loading;
  }, [activeBalance, page, loading, prevPage]);

  const renderPaginate = useMemo(() => {
    if (
      (activeBalance === "payout" && payoutList?.length) ||
      (activeBalance === "payin" && payInList?.length)
    ) {
      return (
        <div className="table--pagination">
          <span className="table--result"></span>
          <div className="pagination--buttons-wrapper">
            <Button
              onClick={() =>
                paginateBalance({
                  type: "prev",
                  page,
                  partnerId: partner?.id as string,
                  walletAddress: walletAddress as string,
                  ...(activeBalance === "payin" && { prevPage }),
                })
              }
              type="button"
              className="button-secondary"
              disabled={isPrevButtonDisabled}
            >
              Previous
            </Button>
            <Button
              onClick={() =>
                paginateBalance({
                  type: "next",
                  page,
                  partnerId: partner?.id as string,
                  walletAddress: walletAddress as string,
                  ...(activeBalance === "payin" && { nextPage }),
                })
              }
              type="button"
              className="button-secondary"
              disabled={isNextButtonDisabled}
            >
              Next
            </Button>
          </div>
        </div>
      );
    }
  }, [
    activeBalance,
    nextPage,
    page,
    paginateBalance,
    partner?.id,
    payInList,
    payoutList,
    prevPage,
    walletAddress,
    isNextButtonDisabled,
    isPrevButtonDisabled,
  ]);

  const renderBalanceBanner = useMemo(() => {
    if (balanceLoading) {
      return <BalanceBannerSkeleton />;
    }

    if (
      !balanceLoading &&
      walletAddress &&
      typeof availableBalance !== "undefined"
    ) {
      return <BalanceBanner />;
    }

    if (
      !balanceLoading &&
      !walletAddress &&
      typeof availableBalance === "undefined"
    )
      return;
  }, [availableBalance, walletAddress, balanceLoading]);

  useLayoutEffect(() => {
    if (
      location?.search &&
      location?.search.includes("balance=true") &&
      selectedTab !== "balance"
    ) {
      setSelectedTab("balance");
    }
  }, [location, setSelectedTab, selectedTab]);

  return (
    <PageWrapper title="Partner Details">
      <div
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content_user"
      >
        <div className="container" id="kt_content_container">
          {!partner ? (
            <PartnerDetailsSkeleton />
          ) : (
            <div className="d-flex flex-column">
              <div className="flex-column flex-lg-row-auto w-100">
                <div className="card">
                  <div className="card-details">
                    <div className="d-flex  flex-row card-details-header">
                      <div className="card-details-header-content">
                        <div className="card-details-header-content-body">
                          <span className="card-details-header-content-body-name">
                            {partner?.name ? `${partner.name}` : "-"}
                          </span>
                          <div
                            className={`card-details-header-content-body-status 
                            ${partner?.active === true ? "active" : "blocked"}`}
                          >
                            {partner?.active === true ? "Active" : "Blocked"}
                          </div>
                        </div>
                        <div className="card-details-header-content-id">
                          <Link
                            to={`/partners/details/${partner?.id}`}
                            style={{ padding: 0, marginLeft: 0 }}
                          >
                            {partner?.id}
                          </Link>
                        </div>
                      </div>
                      {renderExportButtons}
                    </div>
                    <nav className="flex card-details-tabs">
                      <RoleProvider roles={tabsRoles.overview}>
                        <TabSelector
                          isActive={selectedTab === "overview"}
                          onClick={() => {
                            if (location?.search.includes("balance=true")) {
                              history.replace(location.pathname);
                              setSelectedTab("overview");

                              return;
                            }

                            setSelectedTab("overview");
                          }}
                        >
                          Overview
                        </TabSelector>
                      </RoleProvider>
                      <RoleProvider roles={tabsRoles.clients}>
                        <TabSelector
                          isActive={selectedTab === "clients"}
                          onClick={() => {
                            if (location?.search.includes("balance=true")) {
                              history.replace(location.pathname);
                              setSelectedTab("clients");

                              return;
                            }

                            setSelectedTab("clients");
                          }}
                        >
                          Customers
                        </TabSelector>
                      </RoleProvider>
                      <RoleProvider roles={tabsRoles.balance}>
                        <TabSelector
                          isActive={selectedTab === "balance"}
                          onClick={() => {
                            if (location?.search.includes("balance=true")) {
                              history.replace(location.pathname);
                              setSelectedTab("balance");

                              return;
                            }

                            setSelectedTab("balance");
                          }}
                        >
                          Balance
                        </TabSelector>
                      </RoleProvider>
                      <RoleProvider roles={tabsRoles.webhooks}>
                        <TabSelector
                          isActive={selectedTab === "webhooks"}
                          onClick={() => {
                            if (location?.search.includes("balance=true")) {
                              history.replace(location.pathname);
                              setSelectedTab("webhooks");

                              return;
                            }

                            setSelectedTab("webhooks");
                          }}
                        >
                          Webhooks
                        </TabSelector>
                      </RoleProvider>
                      <RoleProvider roles={tabsRoles.events}>
                        <TabSelector
                          isActive={selectedTab === "events"}
                          onClick={() => {
                            if (location?.search.includes("balance=true")) {
                              history.replace(location.pathname);
                              setSelectedTab("webhooks");

                              return;
                            }

                            setSelectedTab("events");
                          }}
                        >
                          Events
                        </TabSelector>
                      </RoleProvider>
                      <RoleProvider roles={tabsRoles.promotions}>
                        <TabSelector
                          isActive={selectedTab === "promotions"}
                          onClick={() => setSelectedTab("promotions")}
                        >
                          Promotions
                        </TabSelector>
                      </RoleProvider>
                    </nav>
                  </div>
                </div>
              </div>
              <div className="flex-lg-row-fluid">
                {partnerDetails && selectedTab === "overview" && (
                  <PartnerDetailsGrid items={partnerDetails} />
                )}

                <div className="tab-content" id="myTabContent">
                  <div className="mt-8">
                    <TabPanel
                      hidden={selectedTab !== "overview"}
                      className="overview--tabs"
                    >
                      <div className="card partner--card">
                        <div className="card-list">
                          <div className="card-list-content">
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Contact Name
                              </div>
                              <div className="card-list-content-row-value">
                                <p>{partner?.contactName}</p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Contact E-Mail
                              </div>
                              <div className="card-list-content-row-value blue-text">
                                <p>
                                  <a
                                    target="_blank"
                                    href={`mailto:${partner?.email}`}
                                    rel="noreferrer"
                                  >
                                    {partner?.email}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Contact Phone
                              </div>
                              <div className="card-list-content-row-value black-text">
                                <p>
                                  <a
                                    href={`tel:${partner?.phone}`}
                                    style={{ padding: 0 }}
                                  >
                                    {partner?.phone}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Support email
                              </div>
                              <div className="card-list-content-row-value blue-text">
                                <p>
                                  <a
                                    target="_blank"
                                    href={`mailto:${partner?.support_email}`}
                                    rel="noreferrer"
                                  >
                                    {partner?.support_email ?? "-"}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Commission
                              </div>
                              <div className="card-list-content-row-value">
                                <p>{partner?.partnerCommission} %</p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Markup
                              </div>
                              <div className="card-list-content-row-value">
                                <p>{partner?.partnerMarkup} %</p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Sell process fee
                              </div>
                              <div className="card-list-content-row-value">
                                <p>
                                  {partner?.sellCommission !== null
                                    ? `${partner.sellCommission} %`
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Sell Commission
                              </div>
                              <div className="card-list-content-row-value">
                                <p>
                                  {partner?.sellMarkup !== null
                                    ? `${partner.sellMarkup} %`
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Sepa Commission
                              </div>
                              <div className="card-list-content-row-value">
                                <p>
                                  {partner?.sepaCommission !== null
                                    ? `${partner.sepaCommission} %`
                                    : "-"}
                                </p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Sell Status
                              </div>
                              <div className="card-list-content-row-value">
                                <div>
                                  {!partner?.is_sell_supported ? (
                                    <div className="user-status-td transaction-status-td user-status-blocked">
                                      Not Supported
                                      <Icon name="order_reject" />
                                    </div>
                                  ) : (
                                    <div className="user-status-td transaction-status-td user-status-active">
                                      Supported
                                      <Icon name="success_check" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Sepa Status
                              </div>
                              <div className="card-list-content-row-value">
                                <div>
                                  {!partner?.is_sepa_supported ? (
                                    <div className="user-status-td transaction-status-td user-status-blocked">
                                      Not Supported
                                      <Icon name="order_reject" />
                                    </div>
                                  ) : (
                                    <div className="user-status-td transaction-status-td user-status-active">
                                      Supported
                                      <Icon name="success_check" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Success webhook
                              </div>
                              <div
                                className={`card-list-content-row-value ${
                                  partner?.callback_success && "blue-text"
                                }`}
                              >
                                <p>{partner?.callback_success || "-"}</p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Fail webhook
                              </div>
                              <div
                                className={`card-list-content-row-value ${
                                  partner?.callback_error && "blue-text"
                                } `}
                              >
                                <p>{partner?.callback_error || "-"}</p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Company website
                              </div>
                              <div className="card-list-content-row-value blue-text">
                                <div>
                                  {partner?.urls?.map((url, index) => (
                                    <div key={index}>
                                      <a
                                        href={`${url.url}`}
                                        target={"_blank"}
                                        rel="noreferrer"
                                      >
                                        {url.url}
                                      </a>
                                      <br />
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Widget link
                              </div>
                              <div className="card-list-content-row-value">
                                <p className="card-list-content-iframe">
                                  <a
                                    onClick={handleClickWidget}
                                    style={{ padding: 0 }}
                                  >
                                    {`<iframe src="https://swaps.app/widget?partner=${partner?.id}" allow="camera" frameborder="0" style="width: 404px; height: 600px"></iframe>`}
                                  </a>
                                </p>
                              </div>
                            </div>
                            <div className="card-list-content-row">
                              <div className="card-list-content-row-title">
                                Comments
                              </div>
                              <div className="card-list-content-row-value">
                                <p>{partner?.comment || "No comments"}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel
                      hidden={selectedTab !== "clients"}
                      className="clients--tabs"
                    >
                      <PartnerClientsTable id={id} />
                    </TabPanel>
                    <TabPanel
                      hidden={selectedTab !== "balance"}
                      className="balance--tabs"
                    >
                      {renderBalanceBanner}
                      <BalanceFiltersWrapper />
                      <div className="table--row">
                        <div className="user-list-body">
                          <BalanceTable
                            partnerId={partner.id}
                            partnerName={partner.name}
                          />
                          {renderPaginate}
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel
                      hidden={selectedTab !== "webhooks"}
                      className="webhook--tabs"
                    >
                      <PartnerWebhooksPage />
                    </TabPanel>
                    <TabPanel
                      hidden={selectedTab !== "events"}
                      className="events--tabs"
                    >
                      <PartnerEventsPage />
                    </TabPanel>
                    <TabPanel
                      hidden={selectedTab !== "promotions"}
                      className="events--tabs"
                    >
                      <Promotions />
                    </TabPanel>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};

export default PartnerDetails;
