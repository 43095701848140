import { useBalanceDispatchContext, useBalanceState } from "providers/Balance";
import { useEffect, useMemo } from "react";
import { useAuthStateContext } from "providers/Auth";
import { PayoutItem } from "providers/Balance/types";
// import { useHistory } from "react-router-dom";

import { PageWrapper } from "components/base";
import BalanceBanner from "./BalanceBanner";
import BalanceFiltersWrapper from "./BalanceFiltersWrapper";
import BalanceHeadline from "./BalanceHeadline";
import BalanceTable from "./BalanceTable";
import Button from "components/Button";
import BalanceBannerSkeleton from "./BalanceBannerSkeleton";

const BalancePage = (): JSX.Element => {
  // const history = useHistory();
  const { user } = useAuthStateContext();
  const {
    prevPage,
    nextPage,
    walletAddress,
    page,
    loading,
    availablePayoutPage,
    activeBalance,
    payInList,
    payoutList,
    balanceLoading,
    availableBalance,
  } = useBalanceState();

  const {
    getPartnerBalance,
    getPayout,
    paginateBalance,
    setWalletAddress,
    setAvailableBalance,
    setBalanceLoading,
    setLoading,
  } = useBalanceDispatchContext();

  const partnerId = localStorage.getItem("user-id");

  useEffect(() => {
    setBalanceLoading(true);
    setLoading(true);
    getPartnerBalance({
      partnerId: partnerId as string,
      partnerName: user?.name as string,
      asset: process.env.REACT_APP_BALANCE_ASSET as string,
    })
      .then((response) => {
        if (response?.data?.data) {
          setWalletAddress(response?.data.data.address);
          setAvailableBalance(response?.data.data?.available);
          setTimeout(() => setBalanceLoading(false), 1200);
          getPayout({
            partnerId: user?.id,
            address: response?.data.data.address,
            limit: 10,
            page: 1,
            orderBy: "desc",
          });
        }
      })
      .catch(() => setBalanceLoading(false));

    return;
    // eslint-disable-next-line
  }, []);

  const isNextButtonDisabled = useMemo(() => {
    if (activeBalance === "payout") {
      return page === availablePayoutPage || loading;
    }

    return !nextPage || (payInList as PayoutItem[]).length < 10 || loading;
  }, [activeBalance, availablePayoutPage, page, loading, nextPage, payInList]);

  const isPrevButtonDisabled = useMemo(() => {
    if (activeBalance === "payout") {
      return page === 1 || loading;
    }

    return !prevPage || loading;
  }, [activeBalance, page, loading, prevPage]);

  const renderPaginate = useMemo(() => {
    if (
      (activeBalance === "payout" && payoutList?.length) ||
      (activeBalance === "payin" && payInList?.length)
    ) {
      return (
        <div className="table--pagination">
          <span className="table--result"></span>
          <div className="pagination--buttons-wrapper">
            <Button
              onClick={() =>
                paginateBalance({
                  type: "prev",
                  page,
                  partnerId: user?.id as string,
                  walletAddress: walletAddress as string,
                  ...(activeBalance === "payin" && { prevPage }),
                })
              }
              type="button"
              className="button-secondary"
              disabled={isPrevButtonDisabled}
            >
              Previous
            </Button>
            <Button
              onClick={() =>
                paginateBalance({
                  type: "next",
                  page,
                  partnerId: user?.id as string,
                  walletAddress: walletAddress as string,
                  ...(activeBalance === "payin" && { nextPage }),
                })
              }
              type="button"
              className="button-secondary"
              disabled={isNextButtonDisabled}
            >
              Next
            </Button>
          </div>
        </div>
      );
    }
  }, [
    activeBalance,
    nextPage,
    page,
    paginateBalance,
    user?.id,
    payInList,
    payoutList,
    prevPage,
    walletAddress,
    isPrevButtonDisabled,
    isNextButtonDisabled,
  ]);

  const renderBalanceBanner = useMemo(() => {
    if (balanceLoading) {
      return <BalanceBannerSkeleton />;
    }

    if (
      !balanceLoading &&
      walletAddress &&
      typeof availableBalance !== "undefined"
    ) {
      return <BalanceBanner />;
    }

    if (
      !balanceLoading &&
      !walletAddress &&
      typeof availableBalance === "undefined"
    )
      return;
  }, [availableBalance, walletAddress, balanceLoading]);

  return (
    <PageWrapper title="Balance">
      <div
        className="content d-flex flex-column flex-column-fluid order-table"
        id="kt_content"
      >
        <div className="container" id="kt_content_container">
          <BalanceHeadline />
          {renderBalanceBanner}
          <BalanceFiltersWrapper />
          <div className="table--row">
            <div className="user-list-body">
              <BalanceTable />
              {renderPaginate}
            </div>
          </div>
          {/* <BalanceEmptyState /> */}
        </div>
      </div>
    </PageWrapper>
  );
};

export default BalancePage;
