import { Dispatch } from "providers/Auth/types";
import React, { ReducerWithoutAction, useContext, useReducer } from "react";

import clientReducer from "./clientReducer";

export type FilterType = {
  page: number;
  search: string;
  logSearch: string;
  filters: undefined | { status: string };
  dateFrom: null;
  dateTo: null | string;
  kycVerification: string[] | null;
  filtersEmail?:
    | {
        email_verified?: boolean;
      }
    | undefined;
  checkedOne: boolean;
  sorting: string;
  ordering: undefined | string | ((prev: string) => string);
  visibleClientsCount: string;
  settingsToggle: undefined | string[];
  exportedClient: undefined | string[];
  clientCountry?: string[] | string;
  checkedID: { checked: boolean; clients: string[] };
  checkedCountry: string[] | [];
  clientIdAction: string | undefined;
  actionComment: string;
  selectedClient: string | undefined;
  arrayChecking: string[] | undefined | [];
  filterButtonVal?: boolean;
  countrySelection: string[] | string;
  checkedPages: number[] | [];
};

const initilaClientFilters = {
  page: 1,
  search: "",
  logSearch: "",
  filters: undefined,
  dateFrom: null,
  dateTo: "",
  kycVerification: null,
  filtersEmail: undefined,
  checkedOne: false,
  sorting: "",
  ordering: undefined,
  visibleClientsCount: "10",
  clientCountry: [],
  settingsToggle: [],
  exportedClient: [],
  checkedID: { checked: false, clients: [] },
  checkedCountry: [],
  actionComment: "",
  clientIdAction: "",
  selectedClient: "",
  arrayChecking: [],
  filterButtonVal: undefined,
  countrySelection: [],
  checkedPages: [],
};

const ClientFiltersContext = React.createContext(initilaClientFilters);
const ClientFiltersDispatcher = React.createContext<Dispatch>(() => "");

const ClientFiltersProvider: React.FC = ({ children }) => {
  const [filters, filtersDispatcher] = useReducer<
    ReducerWithoutAction<FilterType>
  >(clientReducer as never, initilaClientFilters);

  return (
    <ClientFiltersContext.Provider value={filters as never}>
      <ClientFiltersDispatcher.Provider value={filtersDispatcher}>
        {children}
      </ClientFiltersDispatcher.Provider>
    </ClientFiltersContext.Provider>
  );
};

export const useClientFilters: () => FilterType = () => {
  const clientFilters = useContext(ClientFiltersContext);

  return clientFilters;
};

export const useClientDispatch = (): Dispatch => {
  const filterDispatch = useContext(ClientFiltersDispatcher);

  return filterDispatch;
};

export default ClientFiltersProvider;
