interface header {
  content?: React.ReactNode;
  empty?: boolean;
  title?: string;
  w?: number | string;
  val?: string;
  light?: string;
  position?: string;
  // handleSorting: (e) => void;
}
[];
export const USER_TABLE_ACCESS: {
  [key: string]: { [key: string]: boolean };
} = {
  checkbox: { super_admin: true, partner: true },
  id: { super_admin: true, partner: true },
  id_clipboard: { super_admin: true, partner: true },
  full_name: { super_admin: true, partner: true },
  email: { super_admin: true, partner: true },
  email_clipboard: { super_admin: true, partner: true },
  first_name: { super_admin: true, partner: true },
  last_name: { super_admin: true, partner: true },
  middle_name: { super_admin: true, partner: true },
  date_of_birth: { super_admin: true, partner: true },
  billing_address: { super_admin: true, partner: true },
  residence_address: { super_admin: true, partner: true },
  partner_name: { super_admin: true, partner: false },
  kyc: { super_admin: true, partner: true },
  tier: { super_admin: true, partner: false },
  edit_tier: { super_admin: true, partner: false },
  limit: { super_admin: true, partner: false },
  edit_limit: { super_admin: true, partner: false },
  status: { super_admin: true, partner: true },
  createdAt: { super_admin: true, partner: true },
  action: { super_admin: true, partner: true },
  registration_country: { super_admin: true, partner: true },
  residence_country: { super_admin: true, partner: true },
  billing_country: { super_admin: true, partner: true },
};

export const filterHeadersUserByRole = (
  header: header,
  role: string
): boolean => {
  if (header.val && USER_TABLE_ACCESS[header.val][role]) return true;

  return false;
};

export const SWITCH_ACCESS: {
  [key: string]: { [key: string]: boolean };
} = {
  switch: { super_admin: true, partner: false },
};
