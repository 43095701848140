import { useHistory } from "react-router-dom";

import { Icon } from "components/base";
import Button from "components/Button";

const SuccessMessage = (): JSX.Element => {
  const history = useHistory();

  return (
    <div className="payout__message-wrapper">
      <Icon name="payout_success" />
      <p>
        The withdrawal request has been accepted for processing. We will keep
        you updated about the status of the transaction by email. On average,
        the verification process takes up to 24 hours.
      </p>
      <Button type="button" onClick={() => history.push("/balance")}>
        Go back to balance
      </Button>
    </div>
  );
};

export default SuccessMessage;
