import "./styles.scss";
import { useState } from "react";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import PresetsDatePicker from "components/PresetsDatePicker";
import { ValidatedLeads } from "./ValidatedLeads";
import { InValidatedLeads } from "./InValidatedLeads";
import { VerifiedUsers } from "./VerifiedUsers";
import { ProspectsUsers } from "./ProspectsUsers";
import { DropOffsUsers } from "./DropOffsUsers";
import { FirstTimeBuyers } from "./FirstTimeBuyers";
import { RepeatBuyers } from "./RepeatBuyers";
import { TransactionBuy } from "./TransactionBuy";
import { TransactionSell } from "./TransactionSell";
import { PaymentLinks } from "./PaymentLinks";
import { Countries } from "./Countries";

export default function UsersCharts(): JSX.Element {
  const [date, setDate] = useState({
    interval: "",
    start: "",
    end: "",
  });

  const handleDateChange = (data) =>
    setDate({
      interval: data.interval,
      start: data.date.startDate,
      end: data.date.endDate,
    });

  return (
    <div className="users-charts-container">
      <RoleProvider
        roles={[
          ROLES.SUPER_ADMIN,
          ROLES.MARKETING,
          ROLES.FINANCE,
          ROLES.PARTNER,
        ]}
      >
        <div className="top-filter">
          <PresetsDatePicker
            onDateChange={handleDateChange}
            intervalPicker={true}
          />
        </div>
        <div className="charts-container">
          <ValidatedLeads
            start={date.start}
            end={date.end}
            interval={date.interval}
          />
          <InValidatedLeads
            start={date.start}
            end={date.end}
            interval={date.interval}
          />
          <VerifiedUsers
            start={date.start}
            end={date.end}
            interval={date.interval}
          />
          <ProspectsUsers
            start={date.start}
            end={date.end}
            interval={date.interval}
          />
          <DropOffsUsers
            start={date.start}
            end={date.end}
            interval={date.interval}
          />
          <FirstTimeBuyers
            start={date.start}
            end={date.end}
            interval={date.interval}
          />
          <RepeatBuyers
            start={date.start}
            end={date.end}
            interval={date.interval}
          />
        </div>
      </RoleProvider>
      <div className="donut-charts">
        <RoleProvider
          roles={[
            ROLES.SUPER_ADMIN,
            ROLES.MARKETING,
            ROLES.FINANCE,
            ROLES.PARTNER,
          ]}
        >
          <>
            <TransactionBuy start={date.start} end={date.end} />
            <TransactionSell start={date.start} end={date.end} />
            <PaymentLinks start={date.start} end={date.end} />
          </>
        </RoleProvider>
        <Countries start={date.start} end={date.end} />
      </div>
    </div>
  );
}
