import React, { useContext, useState } from "react";
import Select from "react-select";
import {
  AdminUsersContext,
  AdminUsersDispatchContext,
} from "providers/AdminUsers";

import { Modal } from "components/base";
import { ReactComponent as CloseModalLogo } from "../../components/base/Icon/icons/close-modal.svg";
import { instance } from "utils/api";

const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

function CreateAdminUserModal({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element | null {
  const { dispatch } = useContext(AdminUsersDispatchContext);

  const handleSubmit = async () => {
    try {
      let errorsObj = { ...errors };
      const isValidMail = emailRegEx.test(email);

      if (firstName.length < 2) {
        errorsObj = { ...errorsObj, firstName: true };
      } else {
        errorsObj = { ...errorsObj, firstName: false };
      }

      if (lastName.length < 2) {
        errorsObj = { ...errorsObj, lastName: true };
      } else {
        errorsObj = { ...errorsObj, lastName: false };
      }

      if (!isValidMail) {
        errorsObj = { ...errorsObj, email: true };
      } else {
        errorsObj = { ...errorsObj, email: false };
      }

      setErrors(errorsObj);

      if (Object.values(errorsObj).find((el) => el)) {
        return;
      } else {
        const {
          data: { user: newAdminUser },
        } = await instance.post("/admin/create", {
          name: firstName,
          surname: lastName,
          roleId: permission,
          email: email,
        });

        dispatch({ type: "ADD_ADMIN_USER", payload: newAdminUser });
        setIsModalOpen(false);
        setFirstName("");
        setLastName("");
        setEmail("");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onClose = () => {
    setIsModalOpen(false);
  };

  const { roles } = useContext(AdminUsersContext);

  const options = roles.map((role) => ({
    value: role.id,
    label: role.name.toLocaleUpperCase(),
  }));

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [permission, setPermission] = useState(options[1]?.value);
  const [errors, setErrors] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  return isModalOpen ? (
    <Modal isOpen={isModalOpen} closeModal={onClose}>
      <div className="modal-content-container">
        <div className="modal-header" id="kt_modal_add_customer_header">
          <div>
            <h2 className="fw-bolder modal-header-title-m">Add User</h2>
          </div>
          <div className="color">
            <span className="svg-icon svg-icon-1" onClick={onClose}>
              <CloseModalLogo />
            </span>
          </div>
        </div>
        <div className="modal-body py-10 px-lg-17">
          <div className="modal-body-content">
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Permissions</label>
                <Select
                  className="react-select-status"
                  classNamePrefix="react-select-status-styles"
                  defaultValue={options[1]}
                  closeMenuOnSelect={true}
                  // components={{ MultiValueLabel, DropdownIndicator }}
                  onChange={(option) => setPermission(option?.value || "")}
                  options={options}
                  // styles={colourStyles}
                />
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>First Name</label>
                <input
                  onChange={(e) => setFirstName(e.target.value)}
                  type="text"
                  className="form-control form-control-solid"
                  name="input"
                  value={firstName}
                />
                {errors.firstName ? (
                  <p className="input-error-message">
                    Must be at least 2 characters
                  </p>
                ) : null}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Last Name</label>
                <input
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  className="form-control form-control-solid"
                  name="input"
                  value={lastName}
                />
                {errors.lastName ? (
                  <p className="input-error-message">
                    Must be at least 2 characters
                  </p>
                ) : null}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Email</label>
                <input
                  onChange={(e) => setEmail(e.target.value)}
                  type="text"
                  className="form-control form-control-solid"
                  name="input"
                  value={email}
                />
                {errors.email ? (
                  <p className="input-error-message">
                    Must be a valid e-mail address
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer flex-center">
          <button
            type="button"
            className="button button-secondary button-cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button button-primary button-add"
            onClick={handleSubmit}
          >
            <span className="indicator-label">Add User</span>
          </button>
        </div>
      </div>
    </Modal>
  ) : null;
}

export default CreateAdminUserModal;
