import {
  FilterType,
  actionType,
  SET_CORE_FILTER,
  SET_CODE_FILTER,
  SET_SUSPEND_STATUS,
  SET_SYMBOL_FILTER,
  SET_MARKUP_RATE,
  SET_TRIGGER_FILTER,
  SET_FIREBLOCK_NAME,
  SET_FIREBLOCK_CODE,
  SET_FIREBLOCK_NATIVE_ASSET,
  SET_FIREBLOCK_TYPE,
  SET_FIAT_NAME,
  SET_FIAT_SYMBOL,
  SET_FIAT_SUSPEND_STATUS,
  RESET_FILTERS,
  SET_SELL_STATUS,
  SET_FIAT_SELL_STATUS,
  SET_MIN_SELL_AMOUNT,
  SET_MAX_SELL_AMOUNT,
  SET_NEGATIVE_MARKUP_RATE,
} from "./types";

export const widgetFilterReducer = (
  state: FilterType,
  action: actionType
): FilterType => {
  const { type, payload } = action;

  switch (type) {
    case SET_TRIGGER_FILTER:
      return {
        ...state,
        trigger_filter: payload as boolean,
      };
    case SET_CORE_FILTER:
      return {
        ...state,
        core: payload as string,
      };
    case SET_CODE_FILTER:
      return {
        ...state,
        code: payload as string,
      };
    case SET_MARKUP_RATE:
      return {
        ...state,
        markup_rate: payload as number,
      };
    case SET_NEGATIVE_MARKUP_RATE:
      return {
        ...state,
        negative_markup: payload as number,
      };
    case SET_SUSPEND_STATUS:
      return {
        ...state,
        is_suspended: payload as boolean,
      };
    case SET_FIAT_SELL_STATUS:
      return {
        ...state,
        fiat_is_sell_supported: payload as boolean,
      };
    case SET_SYMBOL_FILTER:
      return {
        ...state,
        symbol: payload as string,
      };
    case SET_FIREBLOCK_NAME:
      return {
        ...state,
        fireblock_name: payload as string,
      };
    case SET_FIREBLOCK_CODE:
      return {
        ...state,
        fireblock_code: payload as string,
      };
    case SET_FIREBLOCK_NATIVE_ASSET:
      return {
        ...state,
        fireblock_native_asset: payload as string,
      };
    case SET_FIREBLOCK_TYPE:
      return {
        ...state,
        fireblock_type: payload as string,
      };
    case SET_FIAT_NAME:
      return {
        ...state,
        fiat_name: payload as string,
      };
    case SET_FIAT_SYMBOL:
      return {
        ...state,
        fiat_symbol: payload as string,
      };
    case SET_FIAT_SUSPEND_STATUS:
      return {
        ...state,
        fiat_is_suspended: payload as boolean,
      };
    case SET_SELL_STATUS:
      return {
        ...state,
        is_sell_supported: payload as boolean,
      };
    case SET_MIN_SELL_AMOUNT:
      return {
        ...state,
        min_sell_amount: payload as number,
      };
    case SET_MAX_SELL_AMOUNT:
      return {
        ...state,
        max_sell_amount: payload as number,
      };
    case RESET_FILTERS:
      return {
        ...state,
        core: "",
        code: "",
        symbol: "",
        markup_rate: undefined,
        is_suspended: undefined,
        is_sell_supported: undefined,
        trigger_filter: true,
        fireblock_name: "",
        fireblock_code: "",
        fireblock_type: "",
        fireblock_native_asset: "",
        fiat_name: "",
        fiat_symbol: "",
        fiat_is_suspended: undefined,
        fiat_is_sell_supported: undefined,
        min_sell_amount: undefined,
        max_sell_amount: undefined,
        negative_markup: undefined,
      };
    default:
      return state;
  }
};
