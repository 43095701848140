import React, { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";

import Line from "components/Charts/Line";
import { instance } from "utils/api";
import { IChartsData, IParams } from "./types";

export function FirstTimeBuyers(params: IParams): JSX.Element {
  const [data, setData] = useState<IChartsData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const getData = async () => {
      setIsLoading(true);

      const {
        data: { entryData, growthPercentage },
      } = await instance.get(`/admin/summary/first-time-buyers`, {
        params: {
          start: params.start ? params.start : undefined,
          end: params.end ? params.end : undefined,
          interval: params.interval ? params.interval : undefined,
        },
      });

      setProgress(
        (
          (growthPercentage[growthPercentage.length - 1] || [])
            .entry_growth_percentage || 0
        ).toFixed()
      );
      setData(entryData);
      setIsLoading(false);
    };

    if (params.start && params.end && params.interval) getData();
  }, [params.start, params.end, params.interval]);
  const yData = data.map((el) => String(el?.entries || 0)) || [];
  const xData = data.map((el) => el.date) || [];

  const totalValue = data.reduce((sum, el) => sum + (el?.entries || 0), 0);
  const unit =
    params.interval === "hour" || params.interval === "day"
      ? params.interval
      : "day";

  return isLoading ? (
    <ContentLoader
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
      height="220px"
    >
      <rect x="50" y="10" rx="4" ry="4" width="70%" height="10" />
      <rect x="50" y="50" rx="4" ry="4" width="70%" height="10" />
      <rect x="50" y="100" rx="4" ry="4" width="70%" height="10" />
      <rect x="50" y="150" rx="4" ry="4" width="70%" height="10" />
      <rect x="50" y="200" rx="4" ry="4" width="70%" height="10" />
    </ContentLoader>
  ) : (
    <Line
      aspectRatio={1.3}
      unit={unit}
      title="First Time Buyers"
      progress={progress}
      infoContenet="Verified customers with single transaction"
      count={totalValue}
      data={{
        x: xData,
        y: yData,
      }}
    />
  );
}
