import React, {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
} from "react";
import * as fileSaver from "file-saver";
import { useAuthDispatchContext } from "providers/Auth";
import moment from "moment";
import { AxiosError } from "axios";

import { State, Dispatch, ActionTypes, DispatchContext } from "./types";
import reducer from "./reducers";
import { instance, assetInstance, sellFlowInstance } from "utils/api";
import { showError } from "utils/helpers";

const initialState: State = {
  isLoading: true,
  orders: [],
  ordersCount: 0,
  orderDetails: null,
  totalAmount: [],
  totalCommission: [],
  totalOrders: [],
  transactionStatuses: [],
  updatedStatus: { tr_status: "", order_id: "" },
};

const SellOrderStateContext = createContext<State | undefined>(undefined);
const SellOrderDispatchContext = createContext<Dispatch | undefined>(undefined);

const SellOrderProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SellOrderStateContext.Provider value={state}>
      <SellOrderDispatchContext.Provider value={dispatch}>
        {children}
      </SellOrderDispatchContext.Provider>
    </SellOrderStateContext.Provider>
  );
};

const useSellOrderState = (): State => {
  const context = useContext(SellOrderStateContext);

  if (typeof context === "undefined") {
    throw new Error("useOrderStateContext must be used within a OrderProvider");
  }

  return context;
};

const useSellOrderDispatchContext = (): DispatchContext => {
  const dispatch = useContext(SellOrderDispatchContext);
  const { signOut } = useAuthDispatchContext();

  if (typeof dispatch === "undefined") {
    throw new Error(
      "useOrderDispatchContext must be used within a OrderProvider"
    );
  }

  const getTrStatuses = useCallback(async () => {
    try {
      const {
        data: { data },
      } = await instance.get("/payment/orders/statuses");

      dispatch({ type: ActionTypes.SET_TR_STATUSES, data });
    } catch (err: unknown) {
      if (
        ((err as AxiosError).response?.status === 401 &&
          !localStorage.getItem("refreshToken")) ||
        (err as AxiosError).response?.status === 401
      ) {
        return signOut();
      }

      showError(err);
    }
  }, [signOut, dispatch]);

  const updateStatus = useCallback(
    async ({ id, tr_status }: { id: string; tr_status: string }) => {
      try {
        const { data: data } = await instance.put(
          `/payment/orders/details/${id}`,
          {
            tr_status,
          }
        );

        return data;
      } catch (error) {
        console.log(error);
      }
    },
    []
  );

  const getOrders = useCallback(
    async (
      page,
      orderSearch,
      filters,
      dateFrom,
      dateTo,
      amountFrom,
      amountTo,
      rateFrom,
      rateTo,
      quantityFrom,
      quantityTo,
      visibleOrdersCount,
      assetOrder,
      exchangeOrder,
      withdrawalOrder,
      currencyOrder,
      ordering2,
      sorting2,
      partnerOrder,
      paymentMethods,
      trStatus,
      checkedPartner,
      customerId,
      address,
      statusId,
      email,
      orderId,
      country
    ) => {
      const token = localStorage.getItem("token");
      dispatch({ type: ActionTypes.SET_LOADING, data: true });

      const getToDate = () => {
        return dateTo
          ? `${moment(dateTo).format("YYYY-MM-DD")}T${
              moment(dateTo)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      try {
        const res = await sellFlowInstance.post(
          "/sell/orders",
          {
            page: page,
            limit:
              visibleOrdersCount === "" || visibleOrdersCount === undefined
                ? "10"
                : +visibleOrdersCount,
            ...(trStatus && { statusId: trStatus }),
            ...(dateFrom && {
              fromDate: moment(dateFrom).format("YYYY-MM-DD"),
            }),
            ...(dateTo && { toDate: getToDate() }),
            ...(email && { customer_email: email }),
            ...(orderSearch && { keyword: orderSearch }),
            ...(orderId && { id: orderId }),
            ...(amountFrom && { fromAmount: amountFrom }),
            ...(amountTo && { toAmount: amountTo }),
            ...(customerId && { clientId: customerId }),
            ...(address && { address: address.trim() }),
            ...(statusId && { statusId }),
            ...(currencyOrder?.length && { currency: currencyOrder[0] }),
            ...(assetOrder?.length && { asset: assetOrder[0] }),
            ...(country && { keyword: country }),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        dispatch({ type: ActionTypes.SET_ORDERS, data: res.data.result });
        dispatch({ type: ActionTypes.SET_LOADING, data: false });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const getClients = useCallback(
    async (page, search = "", filters = {}) => {
      try {
        const res = await instance.get("/payment/orders", {
          params: { page, search, ...filters },
        });

        dispatch({ type: ActionTypes.SET_ORDERS, data: res.data.data });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const getOrdersStatistics = useCallback(async () => {
    try {
      const res = await instance.get("/payment/orders/stats", {
        params: { page: 1, limit: 1 },
      });

      dispatch({
        type: ActionTypes.SET_ORDERS_STATISTICS,
        data: res.data.data,
      });
    } catch (err: unknown) {
      if (
        ((err as AxiosError).response?.status === 401 &&
          !localStorage.getItem("refreshToken")) ||
        (err as AxiosError).response?.status === 401
      ) {
        return signOut();
      }

      showError(err);
    }
  }, [dispatch, signOut]);

  const downloadSellOrders = useCallback(
    async (
      visibleOrdersCount,
      sorting2,
      ordering2,
      selectedOrder,
      checkedIDOrder,
      orders,
      arrayCheckingOrder,
      orderSearch,
      filters,
      dateFrom,
      dateTo,
      amountFrom,
      amountTo,
      rateFrom,
      rateTo,
      quantityFrom,
      quantityTo,
      assetOrder,
      exchangeOrder,
      currencyOrder,
      partnerOrder,
      withdrawalOrder,
      trStatus,
      _,
      limit,
      email,
      country
    ) => {
      const getDate = (date, type = "to") => {
        if (moment().format("MM-DD") === moment(date)?.format("MM-DD"))
          return undefined;

        if (type === "from") {
          return date ? `${moment(date).format("YYYY-MM-DD")}` : undefined;
        }

        return date
          ? `${moment(date).format("YYYY-MM-DD")}T${
              moment(date)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      const token = localStorage.getItem("token");

      try {
        const res = await sellFlowInstance.post(
          "/sell/orders/download/excel",
          {
            sortBy: sorting2,
            orderBy: sorting2 === undefined ? undefined : ordering2,
            orders: orders !== undefined ? selectedOrder : undefined,
            keyword: orderSearch === "" ? undefined : orderSearch,
            fromDate: getDate(dateFrom, "from"),
            toDate: getDate(dateTo),
            fromAmount: amountFrom ? +amountFrom : undefined,
            toAmount:
              !amountTo || +amountTo < +amountFrom ? undefined : +amountTo,
            fromRate: rateFrom ? +rateFrom : undefined,
            toRate: !rateTo || +rateTo < +rateFrom ? undefined : +rateTo,
            fromQuantity: !quantityFrom ? undefined : +quantityFrom,
            toQuantity: !quantityTo
              ? undefined
              : +quantityTo < +quantityFrom
              ? undefined
              : +quantityTo,
            exchanges: exchangeOrder,
            ...(currencyOrder?.length && { currency: currencyOrder[0] }),
            ...(assetOrder?.length && { asset: assetOrder[0] }),
            ...(limit && { limit }),
            ...(filters?.length && { statuses: filters }),
            ...(partnerOrder?.length && { partners: partnerOrder }),
            ...(withdrawalOrder && { withdrawals: withdrawalOrder }),
            ...(trStatus && { trStatus }),
            ...(email && { customer_email: email }),
            ...(country && { keyword: country }),
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          }
        );

        const date = moment().format("DD-MM-YYY_hh:mm");

        fileSaver.saveAs(res.data as unknown as Blob, `orders-${date}.xlsx`);
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [signOut]
  );

  const getOrderById = useCallback(
    async (id) => {
      const token = localStorage.getItem("token");

      try {
        const res = await sellFlowInstance.get(`/sell/order/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        dispatch({
          type: ActionTypes.SET_ORDER_DETAILS,
          data: res.data.result,
        });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const deleteOrder = useCallback(
    async (id) => {
      try {
        await instance.delete(`/payment/orders/${id}`);

        dispatch({
          type: ActionTypes.DELETE_ORDER,
          data: id,
        });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const setLoading = (isLoading: boolean) =>
    dispatch({ type: ActionTypes.SET_LOADING, data: isLoading });

  const setUpdatedStatus = ({ tr_status, order_id }) => {
    dispatch({
      type: ActionTypes.SET_UPDATES_STATUS,
      data: { tr_status, order_id },
    });
  };

  const getFiatList = async () => {
    try {
      const { data } = await assetInstance.get("/fiat/fiat_currencies");

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getAssetList = async () => {
    try {
      const { data } = await assetInstance.get(
        "/crypto/admin_crypto_currencies"
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const resetOrdersCount = () => {
    dispatch({ type: ActionTypes.RESET_ORDERS_COUNT, data: 0 });

    return;
  };

  return {
    getOrders,
    getClients,
    downloadSellOrders,
    getOrderById,
    getOrdersStatistics,
    deleteOrder,
    setLoading,
    getTrStatuses,
    updateStatus,
    setUpdatedStatus,
    getFiatList,
    getAssetList,
    resetOrdersCount,
  };
};

export default SellOrderProvider;
export { useSellOrderDispatchContext, useSellOrderState };
