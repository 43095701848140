import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useCookies } from "react-cookie";
import ReactCodeInput from "react-verification-code-input";
import { useBalanceDispatchContext, useBalanceState } from "providers/Balance";
import { createOrder, resendCode } from "services/balance";
import axios from "axios";
import { useAuthStateContext } from "providers/Auth";

import { Icon } from "components/base";
import Button from "components/Button";
import PayoutOTPCountdown from "./PayoutOTPCoundown";

const PayoutOTPForm = ({
  setOrderCreated,
}: {
  setOrderCreated: (orderCreated: boolean) => void;
}): JSX.Element => {
  const [cookie, setCookie, removeCookie] = useCookies(["otp-attempts"]);
  const history = useHistory();
  const [timeExpired, setTimeExpired] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState(null);
  const { amount, walletAddress } = useBalanceState();
  const [resendCounter, setResendCounter] = useState(false);
  const [disableResend, setDisableResend] = useState(true);
  const [otpAttempts, setOtpAttempts] = useState<number>(
    parseInt(cookie["otp-attempts"])
  );

  const [resendClicked, setResendClicked] = useState(false);

  const {
    setOneTimePassword,
    setAmount,
    setPayoutWallet,
    setInvalidOtpMessage,
    getPayout,
    getPartnerBalance,
    setWalletAddress,
    setAvailableBalance,
    setBalanceLoading,
  } = useBalanceDispatchContext();

  const { invalidOtpMessage } = useBalanceState();

  const { user } = useAuthStateContext();

  useEffect(() => {
    if (!amount || !walletAddress) {
      history.goBack();
    }
  }, [amount, walletAddress, history]);

  const verifyCode = async (code) => {
    if (timeExpired || isInvalid) return;

    try {
      const orderResponse = await createOrder({
        asset: "BTC",
        amount: amount as number,
        network: "BTC",
        address: walletAddress as string,
        code: String(code),
      });

      if (
        orderResponse?.data.status === 401 ||
        orderResponse?.data.status === 403 ||
        orderResponse?.data.status === 400
      ) {
        if (orderResponse?.data.message === "Can try after 30 minutes") {
          setInvalidOtpMessage(orderResponse?.data.message);

          return;
        }

        setIsInvalid(true);
        setInvalidMessage(orderResponse?.data.message);

        return;
      }

      removeCookie("otp-attempts");
      await getPartnerBalance({
        partnerId: user?.id as string,
        partnerName: user?.name as string,
        asset: process.env.REACT_APP_BALANCE_ASSET as string,
      }).then((response) => {
        if (response?.data?.data) {
          setWalletAddress(response?.data.data.address);
          setAvailableBalance(response?.data.data?.available);
          setTimeout(() => setBalanceLoading(false), 1200);
          getPayout({
            partnerId: user?.id,
            address: response?.data.data.address,
            limit: 10,
            page: 1,
            orderBy: "desc",
          });
        }
      });
      setOrderCreated(true);
      setAmount(null);
      setPayoutWallet(null);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        return error.response;
      }
    }
  };

  const renderMessage = useMemo(() => {
    if (timeExpired) {
      return (
        <p className="payout-otp__error-message">The code has timed out</p>
      );
    }

    if (isInvalid) {
      return <p className="payout-otp__error-message">{invalidMessage}</p>;
    }

    if (invalidOtpMessage) {
      return <p className="payout-otp__error-message">{invalidOtpMessage}</p>;
    }
  }, [timeExpired, isInvalid, invalidOtpMessage, invalidMessage]);

  const renderAttemptsMessages = useMemo(() => {
    if (otpAttempts === 0 && resendClicked) {
      return (
        <div className="otp-attempts">
          You have tried all attempts. Try again in 30 minutes.
        </div>
      );
    }

    if (
      typeof otpAttempts !== "undefined" &&
      otpAttempts < 3 &&
      otpAttempts >= 1 &&
      resendClicked
    ) {
      return (
        <div className="otp-attempts">
          We sent you the code again. You have {otpAttempts} attempts left.
        </div>
      );
    }
  }, [otpAttempts, resendClicked]);

  return (
    <div className="payout-otp__form-wrapper">
      <h1>Confirm the request</h1>
      <div className="payout-otp__form-outer-block">
        <ReactCodeInput
          disabled={timeExpired || !!invalidOtpMessage}
          placeholder={["*", "*", "*", "*", "*", "*"]}
          className={`payout-opt__form ${isInvalid ? "invalid-code" : ""}`}
          onChange={(data) => {
            setIsInvalid(false);
            setOneTimePassword(parseInt(data));
          }}
          onComplete={verifyCode}
        />
        {renderMessage}
      </div>

      <div className="payout-otp__form-content">
        {renderAttemptsMessages}
        {!invalidOtpMessage && (
          <>
            <p>
              Please enter 6-digit verification code that was sent to
              <br />
              <span>{user?.twoFa_phone}</span>
              <br />
              {otpAttempts > 0 && (
                <PayoutOTPCountdown
                  setTimeExpired={setTimeExpired}
                  isResendDisabled={disableResend}
                  disableResend={setDisableResend}
                  setResendCounter={setResendCounter}
                  resendCounter={resendCounter}
                  otpAttempts={otpAttempts}
                />
              )}
            </p>
            <Button
              type="button"
              onClick={() => {
                setResendClicked(true);
                resendCode();
                setResendCounter(true);
                setTimeExpired(false);
                setOtpAttempts(parseInt(cookie["otp-attempts"]) - 1);
                setCookie(
                  "otp-attempts",
                  parseInt(cookie["otp-attempts"]) - 1,
                  { maxAge: 1800 }
                );
              }}
              disabled={disableResend || otpAttempts <= 0}
            >
              <Icon name="payout_refresh" />
              Resend the code
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default PayoutOTPForm;
