import { FC } from "react";
import { useHistory } from "react-router-dom";

import TwoFactorAuthentication from ".";

const AuthTypeSelect: FC = () => {
  const history = useHistory();

  //   const { setPhone: setPhoneNumber } = useAuthDispatchContext();

  const onSMSButtonClick = () => history.push("/phone-number");
  const onGoogleAuthButtonClick = () => history.push("/account-auth-google");

  return (
    <TwoFactorAuthentication>
      <div className="tfa-card">
        <div className="tfa-card-header">
          <h6 className="tfa-card-header-title">Account Login</h6>
        </div>
        <div className="tfa-card-footer">
          <div className="tfa-card-footer-btn">
            <button
              onClick={onSMSButtonClick}
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-primary w-100"
            >
              <span className="indicator-label">SMS</span>
            </button>
          </div>
        </div>
        <div className="tfa-card-footer">
          <div className="tfa-card-footer-btn">
            <button
              onClick={onGoogleAuthButtonClick}
              type="submit"
              id="kt_sign_in_submit"
              className="btn  btn-tertiary btn-lg w-100"
            >
              <span className="indicator-label">
                Auth with Google Authenticator
              </span>
            </button>
          </div>
        </div>
      </div>
    </TwoFactorAuthentication>
  );
};

export default AuthTypeSelect;
