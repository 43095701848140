import React, { Fragment } from "react";

import useRole from "hooks/useRole";

function withRoleHoc<P>(MyComponent: React.ElementType): React.ElementType {
  const ComponentWithExtraInfo = (props: P) => {
    const isAdmin = useRole() === "super_admin";

    return isAdmin ? <MyComponent {...props} /> : <Fragment></Fragment>;
  };

  return ComponentWithExtraInfo;
}

export default withRoleHoc;

// role : "super_admin" || "partner"
