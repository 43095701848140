import { FC, useState } from "react";
import { TabPanel, useTabs } from "react-headless-tabs";
import Switch from "react-switch";
import { useBitcoinCore } from "providers/BitcoinCore";

import BitcoinSwitcher from "./BitcoinSwitcher";
import { PageWrapper } from "components/base";
import { TabSelector } from "../../hooks/TabSelector";
import EditFeeRate from "./EditFeeRate";
import BankSelect from "./Bank";

import "./settings.scss";

const Setting: FC = () => {
  const [selectedTab, setSelectedTab] = useTabs([
    "global",
    "widget",
    "landing_page",
    "bitcoin_core",
  ]);

  const [checkedActiveWidget, setCheckedActiveWidget] = useState(false);
  const [checkedActiveLanding, setCheckedActiveLanding] = useState(false);

  const handleChangeWidget = (
    nextCheckedActive: boolean | ((prevState: boolean) => boolean)
  ) => {
    setCheckedActiveWidget(nextCheckedActive);
  };

  const handleChangeLandingPage = (
    nextCheckedActive: boolean | ((prevState: boolean) => boolean)
  ) => {
    setCheckedActiveLanding(nextCheckedActive);
  };

  const { bitcoinState } = useBitcoinCore();

  return (
    <PageWrapper title="Settings">
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="container" id="kt_content_container">
          <div className="d-flex flex-column">
            <div className="flex-column flex-lg-row-auto w-100">
              <div className="card  setting-card">
                <nav className="flex card-details-tabs">
                  <TabSelector
                    isActive={selectedTab === "global"}
                    onClick={() => setSelectedTab("global")}
                  >
                    Global
                  </TabSelector>
                  {/* <TabSelector
                    isActive={selectedTab === "bitcoin_core"}
                    onClick={() => setSelectedTab("bitcoin_core")}
                  >
                    Bitcoin Core
                  </TabSelector> */}
                </nav>
              </div>
            </div>
            <div className="flex-lg-row-fluid setting-content">
              <div className="card">
                <div>
                  <TabPanel
                    hidden={selectedTab !== "widget"}
                    className="widget--tabs"
                  >
                    <div className="card-list-content-row">
                      <div className="card-list-content-row-title">
                        Widget Engineering works
                      </div>
                      <div className="card-list-content-row-value">
                        <Switch
                          onColor={"#0570DE"}
                          offColor={"#EFF2F5"}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onChange={handleChangeWidget}
                          checked={checkedActiveWidget}
                          className={
                            checkedActiveWidget
                              ? "react-switch active"
                              : "react-switch false"
                          }
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    hidden={selectedTab !== "landing_page"}
                    className="landing-page--tabs"
                  >
                    <div className="card-list-content-row">
                      <div className="card-list-content-row-title">
                        Landing Page Engineering works
                      </div>
                      <div className="card-list-content-row-value">
                        <Switch
                          onColor={"#0570DE"}
                          offColor={"#EFF2F5"}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          onChange={handleChangeLandingPage}
                          checked={checkedActiveLanding}
                          className={
                            checkedActiveLanding
                              ? "react-switch active"
                              : "react-switch false"
                          }
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    hidden={selectedTab !== "global"}
                    className="global--tabs"
                  >
                    <div className="card-list-setting">
                      <div className="card-list-content-row">
                        <div className="card-list-content-row-title">Bank</div>
                        <div className="card-list-content-row-value">
                          <BankSelect />
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    hidden={selectedTab !== "bitcoin_core"}
                    className="bitcoin-core--tabs"
                  >
                    <div className="card-list__titles-wrapper">
                      <div className="card-list-content-row">
                        <div className="card-list-content-row-header">
                          Bitcoin core
                        </div>
                      </div>
                      <div className="card-list-content-row">
                        <div className="card-list-content-row-header">
                          Fee rate
                        </div>
                      </div>
                      <div className="card-list-content-row">
                        <div className="card-list-content-row-header">
                          Balance
                        </div>
                      </div>
                    </div>
                    <div className="card-list__titles-wrapper">
                      <div className="card-list-content-row">
                        <BitcoinSwitcher />
                      </div>
                      <div className="card-list-content-row">
                        <div className="card-list-content-row-body">
                          <EditFeeRate />
                        </div>
                      </div>
                      <div className="card-list-content-row">
                        <div className="card-list-content-row-body">
                          {bitcoinState?.balance} BTC
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Setting;
