import React, { useState, FC, useMemo, useEffect } from "react";

import PasswordInput from "components/PasswordInput";
import StrictLevel from "./strictLevel";
import * as validators from "./validatorApi";

const validationArray = Object.values(validators);

type PropsType = {
  onChange: (value: string) => void;
};

const NewPassword: FC<PropsType> = ({ onChange }) => {
  const [password, setPassword] = useState("");
  const [passwordRep, setPasswordRep] = useState("");
  const [passMismatch, setPassMismatch] = useState(password !== passwordRep);

  const strictLevel = useMemo(() => {
    const value = { errors: 0, success: 0, defaults: 0 };
    if (!password) return value;

    validationArray.forEach((validator) => {
      validator(password) ? value.success++ : value.defaults++;
    });

    return value;
  }, [password]);

  const error = useMemo(
    () => passMismatch || strictLevel.defaults > 0,
    [passMismatch, strictLevel.defaults]
  );

  useEffect(() => {
    if (!password && !passwordRep) return;
    setPassMismatch(password !== passwordRep);
  }, [password, passwordRep]);

  useEffect(() => {
    if (!passMismatch && passwordRep && passwordRep === password && !error) {
      return onChange(password);
    }

    return onChange("");
  }, [error, onChange, passMismatch, password, passwordRep]);

  return (
    <div className="tfa-card-body">
      <div className="tfa-card-row">
        <label className="tfa-card-row-title">Password</label>
        <PasswordInput
          className={`form-control form-control-lg form-control-solid tfa-input password-input ${
            strictLevel.errors > 0 && "tfa-input-error"
          }`}
          type="password"
          autoComplete="off"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          // {...register("password", { required: true })}
        />
        <div className="steps-validity">
          <StrictLevel {...strictLevel} />
        </div>
        <small className="tfa-card-row-validity">
          Use 8 or more characters with a mix of uppercase and lowercase
          letters, numbers & symbols.
        </small>
        <p className="input-error-message tfa-card-row-error-messege">
          {strictLevel.errors > 0 ? (
            "Please enter valid password"
          ) : (
            <span style={{ opacity: 0 }}>.</span>
          )}
        </p>
      </div>
      <div className="tfa-card-row mt-40">
        <label className="tfa-card-row-title">Confirm Password</label>
        <PasswordInput
          className={`form-control form-control-lg form-control-solid tfa-input password-input ${
            passMismatch && passwordRep && "tfa-input-error"
          }`}
          type="password"
          autoComplete="off"
          value={passwordRep}
          onChange={(e) => {
            setPasswordRep(e.target.value);
          }}
        />
        <p className="input-error-message tfa-card-row-error-messege">
          {passMismatch && passwordRep ? (
            "Password mismatch"
          ) : (
            <span style={{ opacity: 0 }}>.</span>
          )}
        </p>
      </div>
    </div>
  );
};

export default NewPassword;
