import { FC, LegacyRef, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useCookies } from "react-cookie";
import { useBalanceDispatchContext, useBalanceState } from "providers/Balance";

import Button from "components/Button";
import { PayoutFormProps } from "./Payout.props";
import PayoutBanner from "./PayoutBanner";
import { instance } from "utils/api";
import { Icon } from "components/base";

const PayoutForm: FC<PayoutFormProps> = ({
  submitPayout,
  setEnableSubmit,
  setRenderFlow,
}): JSX.Element => {
  const [cookie, setCookie] = useCookies(["otp-attempts"]);
  const history = useHistory();
  const submitRef = useRef<HTMLButtonElement | undefined>(null);
  const { amount, availableBalance, payoutWallet, walletAddress } =
    useBalanceState();

  const { setAmount, setPayoutWallet, setInvalidOtpMessage } =
    useBalanceDispatchContext();

  const [showBanner, setShowBanner] = useState(true);
  const [validationError, setValidationError] = useState<string | null>(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: {
      errors: { amount: amountError, address: addressError },
    },
  } = useForm({
    mode: "onChange",
  });

  const amountField = register("amount", {
    required: "Amount is required",
    pattern: {
      value: /^\d*\.?\d*$/,
      message: "Invalid amount",
    },
    validate: (amount) => {
      const isValid =
        amount > 0 &&
        (availableBalance as number) > 0 &&
        amount <= (availableBalance as number);

      if (amount > (availableBalance as number)) {
        setValidationError("Amount exceeds the wallet balance.");

        return false;
      }

      if (amount <= 0) {
        setValidationError("Invalid value");

        return false;
      }

      return isValid;
    },
  });

  const addressField = register("address", {
    required: "Address is required",
    // pattern: {
    //   value: /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^(bc1)[0-9A-Za-z]{39,59}$/,
    //   message: "Wallet address is incorrect",
    // },
  });

  useEffect(() => {
    if (submitPayout && submitRef.current) {
      submitRef.current.click();
    }
  }, [submitRef, submitPayout]);

  useEffect(() => {
    if (amount && payoutWallet && !amountError && !addressError) {
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
    }
  }, [amount, payoutWallet, setEnableSubmit, amountError, addressError]);

  const onSubmit = (data) => {
    const { amount, address } = data;

    if (amount && address) {
      instance
        .post("/partners/order/confirm-sms")
        .then((data) => {
          if (data.data.message === "Success") {
            if (!cookie["otp-attempts"]) {
              setCookie("otp-attempts", 3, { path: "/", maxAge: 1800 });
            }

            setRenderFlow("otp");
            // history.push("/otp");
          }
        })
        .catch((error) => {
          setInvalidOtpMessage(error.response.data.message);
          setRenderFlow("otp");
        });
    }
  };

  useEffect(() => {
    if (
      !walletAddress &&
      (typeof availableBalance === "undefined" ||
        typeof availableBalance === "object")
    ) {
      history.go(-1);
    }
  }, [walletAddress, availableBalance, history]);

  return (
    <div className="payout-form__wrapper">
      <h1>Send BTC</h1>
      {showBanner && <PayoutBanner closeBanner={() => setShowBanner(false)} />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label htmlFor="amount">Amount BTC</label>
          <input
            id="amount"
            type="number"
            placeholder={String(availableBalance)}
            formNoValidate
            {...amountField}
            onChange={(e) => {
              setAmount(parseFloat(e.target.value));
              amountField.onChange(e);
            }}
            value={amount as number}
          />
          <Button
            className="payout-button"
            type="button"
            onClick={() => {
              setValue("amount", availableBalance);
              setAmount(availableBalance as number);
              setValue("amount", availableBalance as number, {
                shouldValidate: true,
              });
            }}
          >
            MAX
          </Button>
          {amountError && amountError.type === "validate" && (
            <p className="input-error-message">
              <Icon name="balance_error" /> {validationError}
            </p>
          )}
          {amountError && amountError.message && (
            <p className="input-error-message">
              <Icon name="balance_error" /> {amountError.message}
            </p>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="address">Wallet address</label>
          <input
            type="text"
            placeholder="Your BTC wallet address"
            {...addressField}
            onChange={(e) => {
              addressField.onChange(e);
              setPayoutWallet(e.target.value);
            }}
            value={payoutWallet}
          />
          {addressError && (
            <p className="input-error-message">
              <Icon name="balance_error" /> {addressError.message}
            </p>
          )}
        </div>
        <button
          ref={submitRef as LegacyRef<HTMLButtonElement>}
          type="submit"
          hidden
          formNoValidate
        ></button>
      </form>
      <div className="payout-form__total-amount">
        <span>
          You receive:{" "}
          <p>
            {new Intl.NumberFormat("de-DE", {
              maximumSignificantDigits: 10,
            }).format(amount as number)}{" "}
            BTC
          </p>
        </span>
      </div>
    </div>
  );
};

export default PayoutForm;
