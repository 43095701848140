import BalanceInfo from "./BalanceInfo";
import BalanceWallet from "./BalanceWallet";

const BalanceBanner = (): JSX.Element => {
  return (
    <div className="balance-banner__container">
      <BalanceInfo />
      <BalanceWallet />
    </div>
  );
};

export default BalanceBanner;
