import Chart from "chart.js/auto";

import { IGenerateChart } from "./types";

function generateColors(numColors) {
  const colors: string[] = [];

  for (let i = 0; i < numColors; i++) {
    const r = Math.floor(Math.random() * 255);
    const g = Math.floor(Math.random() * 255);
    const b = Math.floor(Math.random() * 255);
    colors.push(`rgba(${r}, ${g}, ${b}, 1)`);
  }

  return colors;
}

function generateChart({
  element,
  data,
  colors,
  chartRef,
}: IGenerateChart): void {
  if (!element) return;

  if (chartRef.current) {
    chartRef.current.destroy();
  }

  element.style.width = "100%";
  element.style.minHeight = "125px";
  const ctx = element.getContext("2d") as CanvasRenderingContext2D;

  const myChart = new Chart(ctx, {
    type: "doughnut",
    data: {
      labels: data.map((el) => el.label),
      datasets: [
        {
          label: "",
          data: data.map((el) => el.count),
          backgroundColor: colors ? colors : generateColors(data.length),
          // cutout: "10%",
        },
      ],
    },
    options: {
      // layout: {
      //   padding: {
      //     left: -70,
      //     right: 0,
      //     top: 0,
      //     bottom: 0,
      //   },
      // },
      responsive: true,
      aspectRatio: 2,
      plugins: {
        legend: {
          display: true,
          position: "right",
        },
        tooltip: {
          mode: "index",
          intersect: false,
        },
      },
    },
  });

  chartRef.current = myChart;
}

export default generateChart;
