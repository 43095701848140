import { FC } from "react";
import { useHistory } from "react-router-dom";
import { useBalanceDispatchContext } from "providers/Balance";

import { Icon } from "components/base";
import Button from "components/Button";
import { PayoutHeaderProps } from "./Payout.props";

const PayoutHeader: FC<PayoutHeaderProps> = ({
  setSubmitPayout,
  enableSubmit,
}): JSX.Element => {
  const history = useHistory();
  const { setAmount, setPayoutWallet } = useBalanceDispatchContext();

  return (
    <div className="payout-header__container">
      <Button
        type="button"
        onClick={() => {
          setAmount(null);
          setPayoutWallet(null);
          history.goBack();
        }}
      >
        <span></span>
        <span></span>
      </Button>
      <span className="payout-header__text">
        <Icon name="payout_funds" />
        <span>Payout funds</span>
      </span>
      <Button
        type="button"
        className="button button-primary"
        onClick={() => {
          setSubmitPayout(true);
          setTimeout(() => setSubmitPayout(false), 300);
        }}
        disabled={!enableSubmit}
      >
        Confirm transaction
      </Button>
    </div>
  );
};

export default PayoutHeader;
