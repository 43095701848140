import { IAction, IRole, IState } from "./types";

function adminUsersReducer(state, action) {
  switch (action.type) {
    case "SET_ADMIN_USERS": {
      return {
        ...state,
        list: action.payload.rows,
        count: action.payload.count,
        isLoading: false,
      };
    }

    case "SET_IS_LOADING": {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case "SET_PAGE": {
      return {
        ...state,
        page: action.payload,
      };
    }

    case "ADD_ADMIN_USER": {
      const newUsers = [...state.list, action.payload];

      return { ...state, list: newUsers, count: state.count + 1 };
    }

    case "DELETE_ADMIN_USER": {
      const newUsers = state.list.filter((user) => user.id !== action.payload);

      return { ...state, list: newUsers };
    }

    case "UPDATE_USER_ROLE": {
      const { userId, roles } = action.payload;

      const newList = state.list.map((el) => {
        if (el.id === userId) {
          return {
            ...el,
            role: roles.name,
            roles,
          };
        } else {
          return el;
        }
      });

      return { ...state, list: newList };
    }

    default:
      return state;
  }
}

function rolesReducer(state: IRole[], action: IAction) {
  switch (action.type) {
    case "SET_ROLES":
      return action.payload;

    default:
      return state;
  }
}

export function appReducer(state: IState, action: IAction): IState {
  return {
    adminUsers: adminUsersReducer(state.adminUsers, action),
    roles: rolesReducer(state.roles, action),
  };
}
