import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

import { instance } from "utils/api";

type FilterType = {
  getCountry: AxiosResponse | undefined;
  loading: boolean;
  error: unknown;
};

const useClientCountry: () => FilterType = () => {
  const [getCountry, setGetCountry] = useState<AxiosResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();

  useEffect(() => {
    try {
      instance
        .get("/user-logs/page-options")
        .then((date) => setGetCountry(date));
    } catch (err) {
      setError(err);
    } finally {
      setLoading(true);
    }
  }, []);

  return { getCountry, loading, error };
};

export default useClientCountry;
