import { set_filters } from "providers/ClientFilter/clientsActions";
import { usePartnerDispatch, usePartnerFilters } from "providers/PartnerFilter";
import {
  set_filteringPartner,
  set_dateFromPartner,
  set_dateToPartner,
  set_markupFrom,
  set_markupTo,
  set_commissionFrom,
  set_commissionTo,
  set_page,
  set_exportedPartner,
  set_checkedIDPartner,
  set_checked_pages,
} from "providers/PartnerFilter/partnerAction";
import React, { useCallback, useMemo } from "react";
import { usePartnerDispatchContext } from "providers/Partners";

import Filter from "components/Filter";
import { partnersFilterTree, partnersSecondFilterTree } from "utils/constants";
import FilterList from "components/Filter/FilterList";

const PartnerFiltersWrapper = (): JSX.Element => {
  const partnerDispatch = usePartnerDispatch();
  const { setLoading } = usePartnerDispatchContext();
  const {
    partnerSearch,
    dateFromPartner,
    dateToPartner,
    markupFrom,
    markupTo,
    commissionFrom,
    commissionTo,
  } = usePartnerFilters();

  const handleFirstLevelFilter = (selectOption) => {
    setLoading(true);
    partnerDispatch(set_filteringPartner(false));

    if (selectOption === "ALL") {
      partnerDispatch(set_filters(undefined));
      partnerDispatch(set_page(1));
      partnerDispatch(set_exportedPartner(undefined));
      partnerDispatch(set_checkedIDPartner({ checked: false, partners: [] }));
      partnerDispatch(set_checked_pages([]));
      partnerDispatch(set_filteringPartner(true));

      return;
    }

    partnerDispatch(set_filters(selectOption));
    partnerDispatch(set_page(1));
    partnerDispatch(set_exportedPartner(undefined));
    partnerDispatch(set_checkedIDPartner({ checked: false, partners: [] }));
    partnerDispatch(set_checked_pages([]));
    partnerDispatch(set_filteringPartner(true));
  };

  const clearPartner = () => {
    partnerDispatch(
      set_checkedIDPartner({
        checked: false,
        partners: [],
      })
    );
    partnerDispatch(set_checked_pages([]));
  };

  const handleDate = useCallback(
    ({ dateFrom, dateTo }) => {
      partnerDispatch(set_filteringPartner(false));
      partnerDispatch(set_dateFromPartner(dateFrom));
      partnerDispatch(set_dateToPartner(dateTo));
      partnerDispatch(set_page(1));
    },
    [partnerDispatch]
  );

  const handleMarkup = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void,
    markupType?: string | undefined
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (markupType === "from") {
      partnerDispatch(set_filteringPartner(false));
      partnerDispatch(
        set_markupFrom((e.target.value === "" && undefined) || e.target.value)
      );
    }

    if (markupType === "to") {
      partnerDispatch(set_filteringPartner(false));
      partnerDispatch(
        set_markupTo((e.target.value === "" && undefined) || e.target.value)
      );
    }

    partnerDispatch(set_page(1));
    setIsValid(true);

    return;
  };

  const handleCommission = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void,
    commissionType?: string | undefined
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (commissionType === "from") {
      partnerDispatch(set_filteringPartner(false));
      partnerDispatch(
        set_commissionFrom(
          (e.target.value === "" && undefined) || e.target.value
        )
      );
    }

    if (commissionType === "to") {
      partnerDispatch(set_filteringPartner(false));
      partnerDispatch(
        set_commissionTo((e.target.value === "" && undefined) || e.target.value)
      );
    }

    partnerDispatch(set_page(1));
    setIsValid(true);

    return;
  };

  const secondLevelHandlers = [handleDate, handleMarkup, handleCommission];

  const handleApply = (type?: string) => {
    partnerDispatch(set_filteringPartner(true));

    if (type === "date_picker" || type === "number_range") {
      setTimeout(() => {
        clearPartner();
      }, 500);
    }
  };

  const enableClearFilters = useMemo(() => {
    if (
      partnerSearch ||
      dateFromPartner ||
      dateToPartner ||
      markupFrom ||
      markupTo ||
      commissionFrom ||
      commissionTo
    ) {
      return true;
    }

    return false;
  }, [
    partnerSearch,
    dateFromPartner,
    dateToPartner,
    markupFrom,
    markupTo,
    commissionFrom,
    commissionTo,
  ]);

  const resetDate = (clearReset = false, context = false) => {
    if (context) {
      return;
    }

    if (clearReset && !dateFromPartner && !dateToPartner) return;
    partnerDispatch(set_filteringPartner(false));
    partnerDispatch(set_dateFromPartner(null));
    partnerDispatch(set_dateToPartner(null));

    handleApply();

    return;
  };

  const resetMarkup = (clearReset = false, context = false) => {
    if (context) {
      return;
    }

    if (clearReset && !markupFrom && !markupTo) return;
    partnerDispatch(set_filteringPartner(false));
    partnerDispatch(set_markupFrom(""));
    partnerDispatch(set_markupTo(""));

    handleApply();

    return;
  };

  const resetCommission = (clearReset = false, context = false) => {
    if (context) {
      return;
    }

    if (clearReset && !commissionFrom && !commissionTo) return;
    partnerDispatch(set_filteringPartner(false));
    partnerDispatch(set_commissionFrom(""));
    partnerDispatch(set_commissionTo(""));

    handleApply();

    return;
  };

  const clearSearches = [resetDate, resetMarkup, resetCommission];

  return (
    <>
      <Filter
        handleFirstLevelFilter={handleFirstLevelFilter}
        filterValues={partnersFilterTree}
      />
      <FilterList
        secondLevelHandlers={secondLevelHandlers}
        secondLevelFilter={partnersSecondFilterTree}
        handleApply={handleApply}
        enableClearFilters={enableClearFilters}
        clearSearches={clearSearches}
        additionalHandlers={[]}
        clearAdditionalSearches={[]}
        clearSelect={clearPartner}
      />
    </>
  );
};

export default PartnerFiltersWrapper;
