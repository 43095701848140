import { Icon } from "components/base";
import Button from "components/Button";

const PayoutBanner = ({
  closeBanner,
}: {
  closeBanner: () => void;
}): JSX.Element => {
  return (
    <div className="payout-banner__wrapper">
      <Icon name="banner_info" />
      <div className="payout-banner__content">
        <h1>Warning</h1>
        <p>
          Please carefully check the accuracy of the details, as in case of any
          error, we will not be able to make a refund.
        </p>
      </div>
      <Button type="button" onClick={closeBanner}>
        <span></span>
        <span></span>
      </Button>
    </div>
  );
};

export default PayoutBanner;
