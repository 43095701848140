import { Icon } from "components/base";

const BalanceEmptyState = (): JSX.Element => {
  return (
    <div className="balance-empty__container">
      <div className="balance-empty__logo-wrapper">
        <Icon name="balance_empty" />
      </div>
      <h1>No records</h1>
      <p>
        Payouts/Payins will show up here, along with other detailed information.
      </p>
    </div>
  );
};

export default BalanceEmptyState;
