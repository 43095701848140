import { EventTypes } from "pages/WebhookCreate/SelectEvent/SelectEvent.props";
import { State, Action, ActionType } from "./types";

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionType.SET_EVENT:
      return {
        ...state,
        selectedEvents: payload as string[],
      };
    case ActionType.SET_EVENT_TYPES:
      return {
        ...state,
        eventTypes: payload as EventTypes,
      };
    case ActionType.SET_ACTIVE_ACCORDIONS:
      return {
        ...state,
        activeAccordions: payload as string[],
      };
    case ActionType.SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: payload as string,
      };
    case ActionType.SET_WEBHOOK_ENDPOINT:
      return {
        ...state,
        webhookEndpoint: payload as string,
      };
    default:
      return state;
  }
};

export default reducer;
