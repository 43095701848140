import { FilterType } from ".";
import {
  ACTION_SET_ARRAY_CHECK_PARTNER,
  ACTION_SET_CHECKED_ID_PARTNER,
  ACTION_SET_COMMISSION_FROM,
  ACTION_SET_COMMISSION_TO,
  ACTION_SET_DATEFROM,
  ACTION_SET_DATETO,
  ACTION_SET_EXPORT_PARTNER,
  ACTION_SET_FILTERS,
  ACTION_SET_FILTER_PARTNER,
  ACTION_SET_MARKUP_FROM,
  ACTION_SET_MARKUP_TO,
  ACTION_SET_MODAL_OPEN,
  ACTION_SET_ORDERING,
  ACTION_SET_PAGE,
  ACTION_SET_PARTNER_COUNT,
  ACTION_SET_PARTNER_ID,
  ACTION_SET_PARTNER_OPTION,
  ACTION_SET_SEARCH,
  ACTION_SET_SELECTED_ID_PARTNER,
  ACTION_SET_SETTINGS_TOGGLE,
  ACTION_SET_SORTING,
  ACTION_SET_CHECKED_PAGES,
  ACTION_RESET_PARTNER_FILTERS,
} from "./types";

export type actionType = {
  type: string;
  payload: FilterType[keyof FilterType];
};

export const set_page = (
  pagePartner: FilterType["pagePartner"]
): actionType => {
  return {
    type: ACTION_SET_PAGE,
    payload: pagePartner,
  };
};

export const set_search = (
  partnerSearch: FilterType["partnerSearch"]
): actionType => {
  return {
    type: ACTION_SET_SEARCH,
    payload: partnerSearch,
  };
};

export const set_filters = (
  partnerFilter: FilterType["partnerFilter"]
): actionType => {
  return {
    type: ACTION_SET_FILTERS,
    payload: partnerFilter,
  };
};

export const set_partnerOption = (
  partnerOption: FilterType["partnerOption"]
): actionType => {
  return {
    type: ACTION_SET_PARTNER_OPTION,
    payload: partnerOption,
  };
};

export const set_dateFromPartner = (
  dateFromPartner: FilterType["dateFromPartner"]
): actionType => {
  return {
    type: ACTION_SET_DATEFROM,
    payload: dateFromPartner,
  };
};

export const set_filteringPartner = (
  filteringPartner: FilterType["filteringPartner"]
): actionType => {
  return {
    type: ACTION_SET_FILTER_PARTNER,
    payload: filteringPartner,
  };
};

export const set_arrayCheckingPartner = (
  arrayCheckingPartner: FilterType["arrayCheckingPartner"]
): actionType => {
  return {
    type: ACTION_SET_ARRAY_CHECK_PARTNER,
    payload: arrayCheckingPartner,
  };
};

export const set_selectedPartner = (
  selectedPartner: FilterType["selectedPartner"]
): actionType => {
  return {
    type: ACTION_SET_SELECTED_ID_PARTNER,
    payload: selectedPartner,
  };
};

export const set_partnerID = (
  partnerID: FilterType["partnerID"]
): actionType => {
  return {
    type: ACTION_SET_PARTNER_ID,
    payload: partnerID,
  };
};

export const set_dateToPartner = (
  dateToPartner: FilterType["dateToPartner"]
): actionType => {
  return {
    type: ACTION_SET_DATETO,
    payload: dateToPartner,
  };
};

export const set_modalOpen = (
  modalOpen: FilterType["modalOpen"]
): actionType => {
  return {
    type: ACTION_SET_MODAL_OPEN,
    payload: modalOpen,
  };
};

export const set_sortingPartner = (
  sortingPartner: FilterType["sortingPartner"]
): actionType => {
  return {
    type: ACTION_SET_SORTING,
    payload: sortingPartner,
  };
};

export const set_orderingPartner = (
  orderingPartner: FilterType["orderingPartner"]
): actionType => {
  return {
    type: ACTION_SET_ORDERING,
    payload: orderingPartner,
  };
};

export const set_partnerCount = (
  visiblePartnersCount: FilterType["visiblePartnersCount"]
): actionType => {
  return {
    type: ACTION_SET_PARTNER_COUNT,
    payload: visiblePartnersCount,
  };
};

export const set_settingsPartner = (
  settingsToggle: FilterType["settingsToggle"]
): actionType => {
  return {
    type: ACTION_SET_SETTINGS_TOGGLE,
    payload: settingsToggle,
  };
};

export const set_exportedPartner = (
  exportedPartner: FilterType["exportedPartner"]
): actionType => {
  return {
    type: ACTION_SET_EXPORT_PARTNER,
    payload: exportedPartner,
  };
};

export const set_checkedIDPartner = ({
  checked,
  partners,
}: FilterType["checkedIDPartner"]): actionType => {
  return {
    type: ACTION_SET_CHECKED_ID_PARTNER,
    payload: { checked, partners },
  };
};

export const set_markupFrom = (
  markupFrom: FilterType["markupFrom"]
): actionType => {
  return {
    type: ACTION_SET_MARKUP_FROM,
    payload: markupFrom,
  };
};

export const set_markupTo = (markupTo: FilterType["markupTo"]): actionType => {
  return {
    type: ACTION_SET_MARKUP_TO,
    payload: markupTo,
  };
};

export const set_commissionFrom = (
  commissionFrom: FilterType["commissionFrom"]
): actionType => {
  return {
    type: ACTION_SET_COMMISSION_FROM,
    payload: commissionFrom,
  };
};

export const set_commissionTo = (
  commissionTo: FilterType["commissionTo"]
): actionType => {
  return {
    type: ACTION_SET_COMMISSION_TO,
    payload: commissionTo,
  };
};

export const set_checked_pages = (pages: number[] | []): actionType => {
  return {
    type: ACTION_SET_CHECKED_PAGES,
    payload: pages,
  };
};

export const reset_partner_filters = (): { type: string } => ({
  type: ACTION_RESET_PARTNER_FILTERS,
});
