import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { ReactComponent as Logo } from "assets/img/logo.svg";
import moment from "moment";

import useCodeValidSec from "hooks/useCodeValidSecs";
import WaitIcon from "../../assets/img/waitIcon.svg";

const PartnersBlockPage: FC = () => {
  const { minutes } = useParams<{ minutes: string }>();
  const { seconds } = useCodeValidSec(Number(minutes) * 60);

  return (
    <div id="kt_body" className="bg-white login">
      <div className="d-flex flex-column flex-root">
        <div className="tfa-wrapper">
          <div className="tfa-content">
            <div>
              <div className="tfa-header">
                <Logo className="h-70px" />
              </div>
              <div className="tfa-body">
                <div className="tfa-card">
                  <div className="tfa-card-header">
                    <h3 className="tfa-card-header-title">
                      You can`t login now
                    </h3>
                  </div>

                  <div className="tfa-card-image-error">
                    <img alt="Logo" src={WaitIcon} />
                  </div>
                  <p className="tfa-card-timer">
                    Try again in {Math.floor(seconds / 60)}:
                    {seconds - Math.floor(seconds / 60) * 60} minutes
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="tfa-footer">
            <p className="tfa-footer-text">
              © 2019 - {moment().format("YYYY")} Swaps.app. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnersBlockPage;
