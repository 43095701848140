import { FC, useEffect, useMemo } from "react";
import {
  useClientDispatchContext,
  useClientStateContext,
} from "providers/Clients";
import "react-datepicker/dist/react-datepicker.css";
import {
  set_checkedID,
  set_checked_pages,
  set_filteringButton,
  set_page,
  set_search,
} from "providers/ClientFilter/clientsActions";
import { filterHeadersUserByRole } from "services/roleService/user/user";
import { useAuthStateContext } from "providers/Auth";

import useRole from "hooks/useRole";
import { Icon, PageWrapper, Table } from "components/base";
import SortIcon from "../../components/base/Icon/icons/sort-icon.svg";
import {
  useClientFilters,
  useClientDispatch,
} from "../../providers/ClientFilter/index";
import ClientsColumn from "./ClientsColumn";
import NotFound from "pages/NotFound";
import Button from "../../components/Button";
import ClientsFiltersWrapper from "./ClientsFiltersWrapper";
import Pagination from "components/Pagination";
import TableSkeleton from "../../components/TableSkeleton";

let timer: ReturnType<typeof setTimeout>;

const Clients: FC = () => {
  const { getClients, downloadClients, getTiers, setLoading } =
    useClientDispatchContext();

  const { isLoading, clients, clientsCount } = useClientStateContext();

  const dispatch = useClientDispatch();

  const {
    page,
    search,
    sorting,
    ordering,
    visibleClientsCount,
    exportedClient,
    settingsToggle,
    checkedID,
    dateTo,
    filterButtonVal,
    dateFrom,
    filters,
    filtersEmail,
    kycVerification,
    checkedOne,
    arrayChecking,
    checkedPages,
  } = useClientFilters();

  const role = useRole();
  const { user } = useAuthStateContext();

  const adminUserRole = user?.role || "";
  const selectUser = useMemo(() => {
    const clientsID = clients.map((item) => item.id);

    const compoundID = [...(exportedClient as string[]), ...clientsID];

    return (
      <>
        <input
          onChange={(e) => {
            if (e.target.checked) {
              dispatch(
                set_checkedID({
                  checked: e.target.checked,
                  clients: compoundID,
                })
              );

              dispatch(set_checked_pages([...checkedPages, page]));
            } else {
              dispatch(
                set_checkedID({
                  checked: true,
                  clients: exportedClient?.filter(
                    (item) => !clientsID.includes(item)
                  ) as string[],
                })
              );
              dispatch(
                set_checked_pages(checkedPages.filter((pg) => pg !== page))
              );
            }
          }}
          type="checkbox"
          className="form-check-input user-list-checkbox"
          checked={(checkedPages as number[])?.includes(page)}
        />
      </>
    );
  }, [dispatch, clients, exportedClient, checkedPages, page]);

  const sortIcon = useMemo(() => {
    return (
      <label className="user-list-sort">
        <img src={SortIcon} alt="sort user list" width="8px" height="12px" />
      </label>
    );
  }, []);

  const headers = [
    { val: "checkbox", w: 25, content: selectUser },
    {
      title: "User ID",
      val: "id",
      w: "fit-content",
      icon: sortIcon,
    },
    {
      title: "Name",
      val: "full_name",
      w: "fit-content",
      icon: sortIcon,
    },
    {
      title: "E-Mail",
      val: "email",
      w: "fit-content",
      icon: sortIcon,
    },
    {
      title: "Registration Country",
      w: 175,
      val: "country",
      icon: sortIcon,
    },
    {
      title: "Partner NAME",
      val: "partner_name",
      w: "fit-content",
      icon: sortIcon,
    },
    { title: "First name", w: 125, val: "first_name", icon: sortIcon },
    { title: "Last name", w: 125, val: "last_name", icon: sortIcon },
    { title: "Middle name", w: 125, val: "middle_name", icon: sortIcon },
    { title: "Date of birth", w: 125, val: "date_of_birth", icon: sortIcon },
    {
      title: "Residence address",
      w: 125,
      val: "residence_address",
      icon: sortIcon,
    },
    {
      title: "Billing address",
      w: 125,
      val: "billing_address",
      icon: sortIcon,
    },
    { title: "KYC", w: 125, val: "kyc", icon: sortIcon },
    {
      title: "Risk Score",
      val: "risk_score",
      w: "fit-content",
      icon: sortIcon,
    },
    {
      title: "Status",
      val: "status",
      w: "fit-content",
      icon: sortIcon,
    },
    {
      title: "Registration Date",
      val: "createdAt",
      w: "fit-content",
      icon: sortIcon,
    },
    { title: "Action", val: "action", w: "fit-content" },
    {
      title: "",
      w: "fit-content",
      // content: <SettingDropdown pageName={"user"} />,
    },
  ];

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (filterButtonVal === false) return;

      if (filterButtonVal) {
        getClients(
          page,
          search,
          filters,
          filtersEmail,
          kycVerification,
          dateFrom,
          dateTo,
          checkedOne,
          sorting,
          ordering,
          visibleClientsCount,
          undefined
        );

        return;
      }

      getClients(
        page,
        search,
        undefined,
        undefined,
        null,
        null,
        null,
        undefined,
        sorting,
        ordering,
        visibleClientsCount,
        undefined
      );
    }, 450);

    return () => {
      clearTimeout(timer);
    };
  }, [
    checkedOne,
    dateFrom,
    dateTo,
    filterButtonVal,
    filters,
    filtersEmail,
    getClients,
    kycVerification,
    ordering,
    page,
    search,
    sorting,
    visibleClientsCount,
  ]);

  useMemo(() => {
    if (adminUserRole === "super_admin") {
      getTiers();
    }
    // eslint-disable-next-line
  }, [role]);

  const clearSearch = () => dispatch(set_search(""));

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        dispatch(set_page(page + 1));
        dispatch(set_filteringButton(true));

        return;
      case "prev":
        dispatch(set_page(page - 1));
        dispatch(set_filteringButton(true));

        return;

      default:
        return;
    }
  };

  return (
    <PageWrapper title="Customers list">
      <div
        className="content d-flex flex-column flex-column-fluid user-table"
        id="kt_content"
      >
        <div className="container" id="kt_content_container">
          <div id="card-user-list-row" className="card user-list-row">
            <div className="user-list-header header-list">
              <div className="button-export__wrapper">
                <h1 className="page-title">Customer List</h1>
                <Button
                  type="button"
                  className="button--transparent"
                  onClick={() => location.reload()}
                >
                  <Icon name="rotate" />
                </Button>

                <Button
                  type="button"
                  className="button--transparent button--export"
                  disabled={!exportedClient?.length}
                  onClick={() => {
                    (exportedClient?.length !== 0 || checkedID) &&
                      downloadClients(
                        visibleClientsCount,
                        sorting,
                        ordering,
                        exportedClient,
                        checkedID,
                        clients,
                        undefined,
                        arrayChecking,
                        search,
                        filters,
                        filtersEmail,
                        kycVerification,
                        dateFrom,
                        dateTo
                      );
                  }}
                >
                  <Icon name="export_button" />
                  Export Selected
                </Button>
                <Button
                  type="button"
                  className="button--transparent button--export"
                  onClick={() =>
                    downloadClients(
                      visibleClientsCount,
                      undefined,
                      undefined,
                      undefined,
                      checkedID,
                      null,
                      undefined,
                      undefined,
                      search,
                      filters,
                      filtersEmail,
                      kycVerification,
                      dateFrom,
                      dateTo
                    )
                  }
                >
                  <Icon name="export_button" />
                  Export
                </Button>
              </div>
              <ClientsFiltersWrapper />
            </div>
            <div className="table--row">
              {(clients.length === 0 && !isLoading && <NotFound />) || (
                <div className="user-list-body">
                  <div>
                    {isLoading ? (
                      <TableSkeleton />
                    ) : (
                      <Table
                        headers={[
                          {
                            empty: true,
                            val: "checkbox",
                            w: "fit-content",
                            content: selectUser,
                          },
                          {
                            title: "E-Mail",
                            val: "email",
                            w: "fit-content",
                            icon: sortIcon,
                            className: "th-email",
                          },
                          {
                            title: "",
                            val: "email_clipboard",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Registration Country",
                            val: "registration_country",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Name",
                            val: "full_name",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Customer ID",
                            val: "id",
                            w: "fit-content",
                            icon: sortIcon,
                            className: "th-customer-id",
                          },
                          {
                            title: "",
                            val: "id_clipboard",
                            w: 120,
                            icon: sortIcon,
                          },
                          {
                            title: "KYC",
                            w: 125,
                            val: "kyc",
                            icon: sortIcon,
                          },
                          {
                            title: "Tier",
                            val: "tier",
                            w: "fit-content",
                            icon: sortIcon,
                            className: "th-tier",
                          },
                          {
                            title: "",
                            val: "edit_tier",
                            w: 120,
                            icon: sortIcon,
                          },
                          {
                            title: "Limit",
                            val: "limit",
                            w: "fit-content",
                            icon: sortIcon,
                            className: "th-limit",
                          },
                          {
                            title: "",
                            val: "edit_limit",
                            w: 120,
                            icon: sortIcon,
                          },
                          {
                            title: "Partner NAME",
                            val: "partner_name",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "First Name",
                            val: "first_name",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Last Name",
                            val: "last_name",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Middle Name",
                            val: "middle_name",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Date of birth",
                            val: "date_of_birth",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Residence Country",
                            val: "residence_country",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Residence Address",
                            val: "residence_address",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Billing Country",
                            val: "billing_country",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Billing Address",
                            val: "billing_address",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Status",
                            val: "status",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Registration Date",
                            val: "createdAt",
                            w: "fit-content",
                            icon: sortIcon,
                          },
                          {
                            title: "Action",
                            val: "action",
                            w: "fit-content",
                          },
                          {
                            empty: true,
                            w: "fit-content",
                            val: "action",
                          },
                        ].filter(
                          (item) => role && filterHeadersUserByRole(item, role)
                        )}
                      >
                        <ClientsColumn
                          headers={headers}
                          settingsToggle={settingsToggle}
                          clients={clients}
                          isLast={clients.length >= 0 && clients.length < 2}
                        />
                      </Table>
                    )}
                  </div>
                  <Pagination
                    page={page}
                    count={clientsCount}
                    visibleCount={+visibleClientsCount}
                    clearSearch={clearSearch}
                    setLoading={setLoading}
                    isLoading={isLoading}
                    handlePagination={handlePagination}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default Clients;
