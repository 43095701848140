import { ReactElement, useMemo } from "react";
import { v4 as uuid } from "uuid";
import { useBalanceDispatchContext, useBalanceState } from "providers/Balance";
import { PayoutItem } from "providers/Balance/types";

import { Table } from "components/base";
import BalanceRow from "./BalanceRow";
import useRole from "hooks/useRole";
import TableSkeleton from "components/TableSkeleton";
import BalanceEmptyState from "./BalanceEmptyState";

const BalanceTable = ({
  partnerId,
  partnerName,
}: {
  partnerId?: string;
  partnerName?: string;
}): JSX.Element => {
  const role = useRole();
  const {
    payoutList,
    payInList,
    payoutStatuses,
    loading,
    page,
    exportPages,
    activeBalance,
  } = useBalanceState();

  const { setExportAll } = useBalanceDispatchContext();

  const selectBalance = useMemo(() => {
    return (
      <input
        onChange={(e) =>
          setExportAll({
            checked: e.target.checked,
            payoutList:
              activeBalance === "payout"
                ? (payoutList as PayoutItem[])
                : (payInList as PayoutItem[]),
            page,
          })
        }
        type="checkbox"
        className="form-check-input user-list-checkbox"
        checked={(exportPages as number[]).includes(page)}
      />
    );
  }, [payoutList, setExportAll, page, exportPages, payInList, activeBalance]);

  const renderBalanceTable = () => {
    switch (activeBalance) {
      case "payout":
        return payoutList?.length ? (
          <Table
            headers={[
              {
                empty: true,
                w: "fit-content",
                content: selectBalance,
              },
              {
                title: "ID",
                w: "fit-content",
              },
              {
                title: "Date",
                val: "id_clipboard",
              },
              {
                title: "Type",
                w: "fit-content",
              },
              {
                title: "Amount",
                w: "fit-content",
              },
              {
                title: "Asset",
                w: "fit-content",
              },
              {
                title: "Status",
                w: "fit-content",
              },
              ...(role === "super_admin" && activeBalance === "payout"
                ? [
                    {
                      empty: true,
                      w: "fit-content",
                    },
                  ]
                : []),
              {
                title: "Wallet Address",
                w: "fit-content",
              },
              {
                title: "Action",
                w: "fit-content",
              },
              {
                title: "Description",
                w: "fit-content",
              },
              ...(role === "super_admin" && activeBalance === "payout"
                ? [
                    {
                      empty: true,
                      w: "fit-content",
                    },
                  ]
                : []),
            ]}
          >
            {payoutList?.map((balanceItem) => (
              <BalanceRow
                key={uuid()}
                balanceItem={balanceItem}
                payoutStatuses={payoutStatuses as string[]}
                partnerId={partnerId}
                partnerName={partnerName}
              />
            ))}
          </Table>
        ) : (
          <BalanceEmptyState />
        );
      case "payin":
        return payInList?.length ? (
          <Table
            headers={[
              {
                empty: true,
                w: "fit-content",
                content: selectBalance,
              },
              {
                title: "ID",
                w: "fit-content",
              },
              {
                title: "Date",
                val: "id_clipboard",
              },
              {
                title: "Type",
                w: "fit-content",
              },
              {
                title: "Amount",
                w: "fit-content",
              },
              {
                title: "Currency",
                w: "fit-content",
              },
              {
                title: "Status",
                w: "fit-content",
              },
              {
                title: "Wallet Address",
                w: "fit-content",
              },
              {
                title: "Action",
                w: "fit-content",
              },
              {
                title: "Description",
                w: "fit-content",
              },
            ]}
          >
            {payInList?.map((balanceItem) => (
              <BalanceRow
                key={uuid()}
                balanceItem={balanceItem}
                payoutStatuses={payoutStatuses as string[]}
                partnerId={partnerId}
                partnerName={partnerName}
              />
            ))}
          </Table>
        ) : (
          <BalanceEmptyState />
        );
    }
  };

  return !loading ? (renderBalanceTable() as ReactElement) : <TableSkeleton />;
};

export default BalanceTable;
