import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useClientDispatch, useClientFilters } from "providers/ClientFilter";
import {
  set_checkedCountry,
  set_clientCountry,
  set_dateFrom,
  set_dateTo,
  set_filteringButton,
} from "providers/ClientFilter/clientsActions";

import { FilterTreeType } from "./LogsFiltersWrapper.props";
import useClientCountry from "hooks/useClientCountry";
import FilterList from "components/Filter/FilterList";
import { logsSecondFilterTree } from "utils/constants";
import FilterSkeleton from "components/FiltersSkeleton";

const LogsFiltersWrapper = (): JSX.Element => {
  const [filterTree, setFilterTree] = useState<FilterTreeType[] | []>([]);
  const { getCountry } = useClientCountry();
  const dispatch = useClientDispatch();
  const { dateFrom, dateTo, countrySelection } = useClientFilters();

  const handleDate = useCallback(
    ({ dateFrom, dateTo }) => {
      dispatch(set_filteringButton(false));
      dispatch(set_dateFrom(dateFrom));
      dispatch(set_dateTo(dateTo));
    },
    [dispatch]
  );

  const handleCountry = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    dispatch(set_filteringButton(false));
    dispatch(set_clientCountry(e.target.value));
    setIsValid(true);

    return;
  };

  useEffect(() => {
    dispatch(set_checkedCountry(getCountry?.data.countries));
  }, [dispatch, getCountry?.data.countries]);

  const secondLevelHandlers = [handleDate, handleCountry];

  useEffect(() => {
    if (!getCountry || filterTree?.length) return;

    const reducedTree: FilterTreeType[] = [
      logsSecondFilterTree[0],
      {
        ...logsSecondFilterTree[1],
        selectOptions: [
          ...(logsSecondFilterTree[1].selectOptions as {
            value: string;
            label: string;
          }[]),
          ...getCountry?.data?.countries.map((item) => ({
            label: item?.country,
            value: item?.iso2,
          })),
        ],
      },
    ];

    setFilterTree(reducedTree);
  }, [getCountry, filterTree]);

  const handleApply = () => dispatch(set_filteringButton(true));

  const enableClearFilters = useMemo(() => {
    if (dateFrom || dateTo || countrySelection) {
      return true;
    }

    return false;
  }, [dateFrom, dateTo, countrySelection]);

  const resetDate = (clearReset = false) => {
    if (clearReset && !dateFrom && !dateTo) return;
    dispatch(set_filteringButton(true));
    dispatch(set_dateFrom(null));
    dispatch(set_dateTo(null));

    handleApply();
  };

  const resetCountry = () => {
    dispatch(set_filteringButton(false));
    dispatch(set_clientCountry([]));

    handleApply();

    return;
  };

  const clearSearches = [resetDate, resetCountry];

  return (
    <>
      {!filterTree?.length ? (
        <FilterSkeleton qty={2} />
      ) : (
        <>
          <FilterList
            secondLevelHandlers={secondLevelHandlers}
            secondLevelFilter={filterTree}
            handleApply={handleApply}
            enableClearFilters={enableClearFilters}
            clearSearches={clearSearches}
            additionalHandlers={[]}
            clearAdditionalSearches={[]}
            clearSelect={() => null}
          />
        </>
      )}
    </>
  );
};

export default LogsFiltersWrapper;
