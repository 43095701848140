import { FC, useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";
import { useAuthDispatchContext } from "providers/Auth";

import { PageWrapper } from "components/base";
import "./company-profile.scss";
import { instance } from "utils/api";
import { ProfileTypes, ProfileDataResponse } from "./type";

const CompanyProfile: FC = () => {
  const { signOut } = useAuthDispatchContext();

  const [stateProfile, setStateProfile] = useState<ProfileTypes>();
  const [domainUrl, setDomainUrl] = useState<string>("");

  const getProfileData = useCallback(async () => {
    try {
      const response = await instance.get<ProfileDataResponse>(
        "/admin/profile"
      );

      return setStateProfile(response.data.data);
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  useEffect(() => {
    stateProfile?.urls &&
      stateProfile?.urls.map((item) =>
        item?.url.substring(0, 3) == "http" ||
        item?.url.substring(0, 4) == "https"
          ? setDomainUrl(item?.url)
          : setDomainUrl(`https://${item?.url}`)
      );
  }, [stateProfile?.urls]);

  const signOutProfile = useMemo(() => {
    return (
      <div className="card-toolbar">
        <div className="d-flex justify-content-end">
          <a className="profile-sign-out" onClick={signOut}>
            Sign Out
          </a>
        </div>
      </div>
    );
  }, [signOut]);

  return (
    <PageWrapper title="My Profile" signOut={signOutProfile}>
      <div className="content d-flex flex-colsumn flex-colsumn-fluid">
        <div className="container" id="kt_content_container">
          <div className="section-profile">
            <div className="rows rows-line">
              <div className="card card-item">
                <div className="rows">
                  <div className="card-data">
                    <h6>{stateProfile?.name}</h6>
                    <span>{stateProfile?.id}</span>
                  </div>
                </div>
                <div className="line--border"></div>
                <div className="rows information">
                  <div className="cols">
                    <b>E-Mail</b>
                    <span>
                      <a href={`mailto:${stateProfile?.email}`}>
                        {stateProfile?.email}
                      </a>
                    </span>
                  </div>
                  <div className="cols">
                    <b>Registration Date</b>
                    <span>
                      {moment(stateProfile?.createdAt).format("DD.MMM.yyyy")}
                    </span>
                  </div>
                </div>
                <div className="card-content-body">
                  <div className="card-title">
                    <h6>Company Profile</h6>
                  </div>
                  <div className="card-content">
                    <div className="rows">
                      <div className="cols cols-3">
                        <span>Contact name</span>
                      </div>
                      <div className="cols cols-9">
                        <b>{stateProfile?.contactName}</b>
                      </div>
                    </div>
                    <div className="rows">
                      <div className="cols cols-3">
                        <span>Partner name</span>
                      </div>
                      <div className="cols cols-9">
                        <b>{stateProfile?.name}</b>
                      </div>
                    </div>
                    <div className="rows">
                      <div className="cols cols-3">
                        <span>Contact phone</span>
                      </div>
                      <div className="cols cols-9">
                        <a href={`tel:${stateProfile?.phone}`}>
                          {stateProfile?.phone}
                        </a>
                      </div>
                    </div>
                    <div className="rows">
                      <div className="cols cols-3">
                        <span>Contact email</span>
                      </div>
                      <div className="cols cols-9">
                        <a href={`mailto:${stateProfile?.email}`}>
                          {stateProfile?.email}
                        </a>
                      </div>
                    </div>
                    <div className="rows align-top">
                      <div className="cols cols-3">
                        <span>Company website</span>
                      </div>
                      <div className="cols cols-9 cols-url">
                        {stateProfile?.urls?.map((item, key) => (
                          <a
                            key={key}
                            href={domainUrl}
                            target="_blank"
                            rel="canonical noreferrer"
                            className="link-urls"
                          >
                            {item?.url}
                          </a>
                        ))}
                      </div>
                    </div>
                    <div className="rows  align-top">
                      <div className="cols cols-3">
                        <span>Success webhook</span>
                      </div>
                      <div className="cols cols-9">
                        <p className="cols-links">
                          {stateProfile?.callback_success || "-"}
                        </p>
                      </div>
                    </div>
                    <div className="rows align-top">
                      <div className="cols cols-3">
                        <span>Fail webhook</span>
                      </div>
                      <div className="cols cols-9">
                        <p className="cols-links">
                          {stateProfile?.callback_error || "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section-summary"></div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default CompanyProfile;
