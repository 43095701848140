import React from "react";
import moment from "moment";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";
import { set_exportedOrder } from "providers/OrderFilter/ordersAction";
import { Link } from "react-router-dom";
import { useOrderDispatchContext, useOrderState } from "providers/Orders";
import withRoleHoc from "hocs/withRoleHoc";

import { TableActionButton } from "components/base";
import { OrderDetailsModalRefType } from "components/modals/OrderDetailsModal";
import OrdersColumnFields from "./OrdersColumnFields";

const OrdersColumn: React.FC<{
  modalRef: React.RefObject<OrderDetailsModalRefType>;
}> = ({ modalRef }) => {
  const orderDispatch = useOrderDispatch();
  const { deleteOrder } = useOrderDispatchContext();
  const { orders, transactionStatuses } = useOrderState();
  const { orderSettingsToggle, exportedOrder } = useOrderFilters();

  const { getOrderById } = useOrderDispatchContext();

  const deletePressed = (id: string) => {
    const isConfirmed = window.confirm("Are you sure you want to delete order");

    if (isConfirmed) {
      deleteOrder(id);
    }

    modalRef.current?.open("edit");
  };

  const IDColumn = (order) => {
    return (
      <>
        <td>
          <input
            onClick={() => {
              getOrderById(order.id);
              orderDispatch(set_exportedOrder(order.id));
            }}
            className="form-check-input user-list-checkbox"
            checked={exportedOrder?.includes(order.id)}
            type="checkbox"
            value={order.id}
          />
        </td>
        <td>
          <Link to={`/orders/details/${order.id}`}>{order.id}</Link>
        </td>
      </>
    );
  };

  const AmountColumn = withRoleHoc((order) => {
    return (
      <td className="td-color-gray">
        {order.currency === "USD" ? "$" : order.currency === "EUR" ? "€" : "₽"}
        {order.amount}
      </td>
    );
  });

  const RateColumn = (order) => {
    return <td className="td-color-gray">{order.spot_price || "-"}</td>;
  };

  const ExchangeColumn = (order) => {
    return <td className="td-color-gray">{order.wallet_exchange || "-"}</td>;
  };

  const CommissionColumn = (order) => {
    return (
      <td className="td-color-gray">
        {order.partner_commission || order.commission}
      </td>
    );
  };

  const MarkupColumn = (order) => {
    return <td>{order.partner_markup || "-"}</td>;
  };

  const NetworkColumn = (order) => {
    return <td className="td-color-gray">{order.network_fee || "-"}</td>;
  };

  const QuantityColumn = withRoleHoc((order) => {
    return <td>{order.asset_quantity}</td>;
  });

  const AssetColumn = (order) => {
    return <td>{order.asset}</td>;
  };

  const StatusColumn = (order) => {
    return (
      <td>
        {
          {
            paid: (
              <div className="user-status-td user-status-active">
                Successful
              </div>
            ),
            failed: (
              <div className="user-status-td user-status-blocked">Failed</div>
            ),
            pending: (
              <div className="user-status-td user-status-panding">Pending</div>
            ),
          }[order.status || "success"]
        }
      </td>
    );
  };

  const PartnerColumn = (order) => {
    return (
      <td className="td-color-gray">
        <Link to={`partners/details/${order.partner_id}`}>
          {order.partner?.name}
        </Link>
      </td>
    );
  };

  const DateColumn = (order) => {
    return (
      <>
        <td>{moment(order.createdAt).format("DD MMM yyyy, hh:mm")}</td>
      </>
    );
  };

  const ActionColumn = (order) => {
    return (
      <>
        <td>
          <TableActionButton
            viewLink={`orders/${order.id}`}
            openModal={() => modalRef.current?.open("create")}
            buttons={[
              {
                title: "Delete",
                onClick: () => deletePressed(order.id),
              },
            ]}
          />
        </td>
      </>
    );
  };

  return (
    <>
      {orders.map((order, index) => (
        <tr key={index} className="user-list-tr">
          {(orderSettingsToggle?.length !== 0 &&
            orderSettingsToggle?.map((item) => (
              <>
                {(() => {
                  switch (item) {
                    case "ORDER Id":
                      IDColumn(order);
                      break;
                    case "Amount":
                      <AmountColumn order={order} />;
                      break;
                    case "Rate":
                      RateColumn(order);
                      break;
                    case "Exchange":
                      ExchangeColumn(order);
                      break;
                    case "Commission":
                      CommissionColumn(order);
                      break;
                    case "Markup":
                      MarkupColumn(order);
                      break;
                    case "Network Commission":
                      NetworkColumn(order);
                      break;
                    case "Quantity":
                      <QuantityColumn order={order} />;
                      break;
                    case "Asset":
                      AssetColumn(order);
                      break;
                    case "Status":
                      StatusColumn(order);
                      break;
                    case "Partner Name":
                      PartnerColumn(order);
                      break;
                    case "Date":
                      DateColumn(order);
                      break;
                    case "Action":
                      ActionColumn(order);
                      break;
                    default:
                      break;
                  }
                })()}
              </>
            ))) || (
            <OrdersColumnFields
              transactionStatuses={transactionStatuses}
              order={order}
              modalRef={modalRef}
              isLast={orders.length >= 0 && orders.length < 2}
            />
          )}
        </tr>
      ))}
    </>
  );
};

export default OrdersColumn;
