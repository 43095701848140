import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useClientDispatchContext } from "providers/Clients";
import { useClientDispatch, useClientFilters } from "providers/ClientFilter";
import { set_exportedClient } from "providers/ClientFilter/clientsActions";

// import WarningIcon from "../../components/base/Icon/icons/warning.svg";
import VerifiedIcon from "../../components/base/Icon/icons/verify.png";
import NoVerifiedIcon from "../../components/base/Icon/icons/no-verify.png";
import { TableActionButton } from "components/base";
import ClientsColumnFields from "./ClientsColumnFields";
import { intercomURL } from "../../utils/constants";
import { Icon } from "../../components/base";
import { copyToClipboard } from "../../utils/helpers";
import Button from "../../components/Button";

const ClientsColumn: React.FC<{
  headers;
  settingsToggle?: string[];
  clients;
  isLast;
}> = ({ headers, settingsToggle, clients, isLast }) => {
  const { updateClientStatus } = useClientDispatchContext();
  const dispatch = useClientDispatch();
  const { actionComment } = useClientFilters();

  const handleCheckClientId = (id) => {
    dispatch(set_exportedClient(id));
  };

  return clients.map((client) => (
    <tr key={client.id} className="user-list-tr">
      {settingsToggle?.length !== 0 ? (
        headers?.map((item) => (
          <>
            {item.title === "User ID" ? (
              <>
                <td>
                  <div>
                    <input
                      onClick={() => handleCheckClientId(client.id)}
                      className="form-check-input user-list-checkbox checked"
                      type="checkbox"
                      value={client.id}
                    />
                  </div>
                </td>
                <td>
                  <div>
                    <Link to={`/users/details/${client.id}`}>{client.id}</Link>
                  </div>
                </td>
              </>
            ) : item.title === "Name" ? (
              <td className="user-list-td-grey">
                <div>
                  {client.name ? `${client.name} ${client.surname}` : "-"}
                </div>
              </td>
            ) : item.title === "E-Mail" ? (
              <td className="user-list-td-black">
                {client.email_verified === false ? (
                  <div className="verification-col user-email-no-verifid">
                    <img src={NoVerifiedIcon} alt="No Verified Email" />
                    <a
                      href={`${intercomURL}${client.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {client.email}
                    </a>
                  </div>
                ) : (
                  <div className="verification-col user-email-verifid">
                    <img src={VerifiedIcon} alt="Verified Email" />
                    <a
                      href={`${intercomURL}${client.email}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {client.email}
                    </a>
                  </div>
                )}
              </td>
            ) : item.title === "Registration Country" ? (
              <td className="user-list-td-grey">
                {client.country ? `${client.country}` : "-"}
              </td>
            ) : item.title === "Partner" ? (
              <td className="user-list-td-grey table-id-link">
                <div>
                  <Link to={`partners/details/${client.partner_id}`}>
                    {client.partner?.name}
                  </Link>
                  <Button
                    type="button"
                    className="clipboard-button"
                    onClick={() => copyToClipboard(client.partner_id)}
                  >
                    <Icon name="clipboard" />
                  </Button>
                </div>
              </td>
            ) : item.title === "First name" ? (
              <td className="user-list-td-grey">
                {client.first_name ? `${client.first_name}` : "-"}
              </td>
            ) : item.title === "Last name" ? (
              <td className="user-list-td-grey">
                {client.last_name ? `${client.last_name}` : "-"}
              </td>
            ) : item.title === "Middle name" ? (
              <td className="user-list-td-grey">
                {client.middle_name ? `${client.middle_name}` : "-"}
              </td>
            ) : item.title === "Date of birth" ? (
              <td className="user-list-td-grey">
                {client.date_of_birth ? `${client.date_of_birth}` : "-"}
              </td>
            ) : item.title === "Residence country" ? (
              <td className="user-list-td-grey">
                {client?.residence_address
                  ? `${client.residence_address?.countries?.country}`
                  : "-"}
              </td>
            ) : item.title === "Residence address" ? (
              <td className="user-list-td-grey">
                {client?.residence_address
                  ? `${client.residence_address.address},${client.residence_address.city},${client.residence_address.state} ${client.residence_address.post_code}`
                  : "-"}
              </td>
            ) : item.title === "Billing country" ? (
              <td className="user-list-td-grey">
                {client.billing_address
                  ? `${client.billing_address?.countries?.country}`
                  : "-"}
              </td>
            ) : item.title === "Billing address" ? (
              <td className="user-list-td-grey">
                {client.billing_address
                  ? `${client.billing_address.address},${client.billing_address.city},${client.billing_address.state} ${client.billing_address.post_code}`
                  : "-"}
              </td>
            ) : item.title === "KYC" ? (
              <td>
                {client.verified === false ? (
                  <div className="verification-col user-kyc-no-verifid">
                    <img src={NoVerifiedIcon} alt="No Verified KYC" />
                    <span>Not Verified</span>
                  </div>
                ) : (
                  <div className="verification-col user-kyc-verifid">
                    <img src={VerifiedIcon} alt="Verified KYC" />
                    <span>Verified</span>
                  </div>
                )}
              </td>
            ) : // : item.title === "Risk Score" ? (
            //   <td>
            //     <div className="risk--score-num">
            //       25.50%
            //       <img src={WarningIcon} alt="Warning" width={20} height={20} />
            //     </div>
            //   </td>
            // )
            item.title === "Status" ? (
              <td>
                {
                  {
                    active: (
                      <div className="user-status-td user-status-active">
                        Active
                      </div>
                    ),
                    blocked: (
                      <div className="user-status-td user-status-blocked">
                        Blocked
                      </div>
                    ),
                    panding: (
                      <div className="user-status-td user-status-panding">
                        Panding
                      </div>
                    ),
                  }[client.status || "active"]
                }
              </td>
            ) : item.title === "Registration Date" ? (
              <>
                <td>
                  <div>
                    {moment(client.createdAt).format("DD MMM yyyy, hh:mm")}
                  </div>
                </td>
                <td>
                  <TableActionButton
                    isLast={isLast}
                    viewLink={`/users/details/${client.id}`}
                    buttons={[
                      {
                        userName: client.name
                          ? `${client.name} ${client.surname}`
                          : "-",
                        title:
                          client.status === "blocked" ? "Unblock" : "Block",
                        onClick: () =>
                          updateClientStatus(
                            client.id,
                            client.status === "blocked" ? "active" : "blocked",
                            actionComment
                          ),
                      },
                    ]}
                  />
                </td>
              </>
            ) : (
              <ClientsColumnFields
                client={client}
                isLast={clients.length >= 1 && clients.length < 3}
              />
            )}
          </>
        ))
      ) : (
        <ClientsColumnFields
          isLast={clients.length >= 1 && clients.length < 3}
          client={client}
        />
      )}
    </tr>
  ));
};

export default ClientsColumn;
