import { Client } from "providers/Clients/types";

import { ProfileEventType } from "pages/Profile/type";
import { IList } from "components/Promotions/types";

export const ActionTypes = {
  SET_PARTNERS: "SET_PARTNERS",
  SET_PARTNER_DETAILS: "SET_PARTNER_DETAILS",
  SET_PARTNER_CLIENTS: "SET_PARTNER_CLIENTS",
  SET_LOADING: "SET_LOADING",
  SET_PROMOTIONS_IS_LOADED: "SET_PROMOTIONS_IS_LOADED",
  SET_PROMOTIONS_LIST: "SET_PROMOTIONS_LIST",
  UPDATE_PROMOTIONS_LIST: "UPDATE_PROMOTIONS_LIST",
  ADD_PROMOTION: "ADD_PROMOTION",
  SET_PROMOTIONS_TYPES: "SET_PROMOTIONS_TYPES",
  SET_PROMOTIONS_COUNT: "SET_PROMOTIONS_COUNT",
};

export type CreatePartner = {
  active?: boolean;
  name: string;
  contactName: string;
  phone: string;
  email: string;
  urls: string[];
  partnerCommission: string;
  partnerMarkup: string;
  callback_success?: string | null;
  callback_error: string | null;
  comment: string;
};

export interface Partner {
  active: boolean;
  comment: string;
  contactName: string;
  createdAt: string;
  email: string;
  support_email: string;
  first_name: string;
  last_name: string;
  middle_name: string;
  date_of_birth: string | null;
  billing_address?: {
    address: string | null;
    city: string | null;
    client_id: string | null;
    country_id: string | null;
    createdAt: string | null;
    id: string | null;
    post_code: string | null;
    state: string | null;
    updatedAt: string | null;
  };
  residence_address?: {
    address: string | null;
    city: string | null;
    client_id: string | null;
    country_id: string | null;
    createdAt: string | null;
    id: string | null;
    post_code: string | null;
    state: string | null;
    updatedAt: string | null;
  };
  id: string;
  name: string;
  partnerCommission: number;
  partnerMarkup: number;
  phone: string;
  updatedAt: string;
  urls?: { url: string }[];
  status: string;
  integration_request_date: string | Date;
  markup: number | null;
  commission: number | null;
  sellCommission: number | null;
  sellMarkup: number | null;
  is_sell_supported: boolean;
  is_rec_supported: boolean;
  orders_count: {
    total: number;
    failed: number;
    paid: number;
    pending: number;
  };
  logs_count: {
    view: number;
    registration: number;
    order: number;
    succeed: number;
    total: number;
  };
  clients_count: {
    verify: number;
    notVerify: number;
    total: number;
  };
  callback_success: string;
  callback_error: string;
  tier_zero: boolean;
  events: ProfileEventType[];
  sepaCommission: number;
  is_sepa_supported: boolean;
}

export type Statistics = {
  order: number;
  registration: number;
  succeed: number;
  view: number;
};

export type LogAction = {
  action: string;
  transaction: string | null;
  ip: string | null;
};
interface SetPartners {
  type: typeof ActionTypes.SET_PARTNERS;
  data: unknown;
}

interface SetPartnerDetails {
  type: typeof ActionTypes.SET_PARTNER_DETAILS;
  data: unknown;
}

interface SetPartnerClients {
  type: typeof ActionTypes.SET_PARTNER_CLIENTS;
  data: unknown;
}

export type PartnerClientsFilters = {
  to?: Date | null;
  from?: Date | null;
};

export type Action = SetPartners | SetPartnerDetails | SetPartnerClients;
export interface IPromotionType {
  id: string;
  desc: string;
}
export type State = {
  isLoading: boolean;
  partners: Partner[];
  partnersCount: number;
  partnerDetails: Partner | null;
  statistics: Statistics;
  partnerClients: Client[];
  partnerClientsCount: number;
  partnerPromotions: {
    isLoaded: boolean;
    types: IPromotionType[];
    list: IList[];
    count: number;
  };
  partnerStatistics: {
    verifiedUsers: number;
    unVerifiedUsers: number;
    amount: { currency: string; total: number }[];
  };
};

export type DispatchContext = {
  clearPartnerId: () => void;
  deletePartner: (
    id?: string,
    page?: number,
    search?: string,
    markupFrom?: string,
    markupTo?: string,
    commissionFrom?: string,
    commissionTo?: string,
    dateFromPartner?: null,
    dateToPartner?: null,
    partnerFilter?: string,
    visiblePartnersCount?: string,
    sorting?: string,
    orderingPartner?: undefined | string | ((prev: string) => string)
  ) => Promise<void>;
  getPartners: (
    page?: number,
    search?: string,
    markupFrom?: string,
    markupTo?: string,
    commissionFrom?: string,
    commissionTo?: string,
    dateFromPartner?: null,
    dateToPartner?: null,
    partnerFilter?: string,
    visiblePartnersCount?: string,
    sorting?: string,
    orderingPartner?: undefined | string | ((prev: string) => string)
  ) => Promise<void>;
  downloadPartners: (
    visiblePartnersCount: string,
    sorting: string,
    orderingPartner?: undefined | string | ((prev: string) => string),
    exportedPartner?: string[] | string,
    checkedID?: { checked: boolean; partners: string[] },
    partner?: Partner[],
    arrayCheckingPartner?: string[] | [],
    partnerSearch?: string,
    dateFromPartner?: null,
    dateToPartner?: null,
    markupFrom?: string,
    markupTo?: string,
    commissionFrom?: string,
    commissionTo?: string,
    partnerFilter?: string
  ) => Promise<void>;
  getPartnerById: (id?: string) => Promise<void>;
  getPartnerClients: (
    page: number,
    id: string,
    filters: PartnerClientsFilters
  ) => Promise<void>;
  createPartner: (
    data: CreatePartner,
    page?: number,
    search?: string,
    markupFrom?: string,
    markupTo?: string,
    commissionFrom?: string,
    commissionTo?: string,
    dateFromPartner?: null,
    dateToPartner?: null,
    partnerFilter?: string,
    visiblePartnersCount?: string,
    sorting?: string,
    orderingPartner?: undefined | string | ((prev: string) => string)
  ) => Promise<void>;
  editPartner: (
    page: number,
    id: string,
    data: CreatePartner,
    search?: string,
    markupFrom?: string,
    markupTo?: string,
    commissionFrom?: string,
    commissionTo?: string,
    dateFromPartner?: null,
    dateToPartner?: null,
    partnerFilter?: string,
    visiblePartnersCount?: string,
    orderingPartner?: undefined | string | ((prev: string) => string),
    is_sell_supported?: boolean
  ) => Promise<void>;
  getPartnerPromotions: ({
    limit,
    page,
  }: {
    limit: number;
    page: number;
  }) => Promise<void>;
  setLoading: (isLoading: boolean) => void;
  reloadPromotionsList: () => void;
  updatePromotion: ({
    startDate,
    endDate,
    description,
    ended,
  }: {
    startDate: string;
    endDate: string;
    description: string;
    ended: boolean;
  }) => void;
  addPromotion: ({
    promotionId,
    feeReductionBank,
    feeReductionCard,
    startDate,
    endDate,
    description,
    buyFlow,
    sellFlow,
  }: {
    promotionId: string;
    feeReductionBank: number;
    feeReductionCard: number;
    startDate: string;
    endDate: string;
    description: string;
    buyFlow?: boolean;
    sellFlow?: boolean;
  }) => void;
};

export type Dispatch = (action: Action) => void;
