import { FC } from "react";
import moment from "moment";

import Icon from "../../components/base/Icon";

const TwoFactorAuthentication: FC = ({ children }) => {
  return (
    <div id="kt_body" className="bg-white login">
      <div className="d-flex flex-column flex-root">
        <div className="tfa-wrapper">
          <div className="tfa-content">
            <div>
              <div className="tfa-header auth-logo__container">
                <Icon name="logo_text" />
              </div>
              <div className="tfa-body">{children}</div>
            </div>
          </div>
          <div className="tfa-footer">
            <p className="tfa-footer-text">
              © 2019 - {moment().format("YYYY")} Swaps.app. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
