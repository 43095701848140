import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
// import { useOrderDispatch } from "providers/OrderFilter";
// import {
//   set_arrayCheckingOrder,
//   set_exportedOrder,
//   set_selectedOrder,
// } from "providers/OrderFilter/ordersAction";
import {
  useSellOrderDispatchContext,
  // useSellOrderState,
} from "providers/SellOrders";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";
import {
  set_arrayCheckingOrder,
  set_exportedOrder,
  set_selectedOrder,
} from "providers/OrderFilter/ordersAction";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import { TableActionButton } from "components/base";
import { ORDER_TABLE_ACCESS } from "../../../services/roleService/order/order";
import { Icon } from "../../../components/base";
import { copyToClipboard } from "../../../utils/helpers";
import Button from "../../../components/Button";
import Tooltip from "components/Tooltip";
import useRole from "hooks/useRole";
import { txSellStatusInfo } from "utils/constants";
// import Popover from "../../../components/Popover";
// import OrderStatusPopover from "../OrderStatusPopover";
// import { txStatusInfo } from "utils/constants";

const OrdersColumnFields: React.FC<{
  order;
  modalRef;
  transactionStatuses;
  isLast;
}> = ({ order, modalRef, isLast }) => {
  // const [trStatus, setTrStatus] = useState(order.tr_status || "");
  // const [chosenStatus, setChosenStatus] = useState(order.tr_status);
  const orderDispatch = useOrderDispatch();
  const { deleteOrder } = useSellOrderDispatchContext();

  // const { updatedStatus } = useSellOrderState();

  const { exportedOrder } = useOrderFilters();
  // const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const role = useRole();

  const deletePressed = (id: string) => {
    const isConfirmed = window.confirm("Are you sure you want to delete order");

    if (isConfirmed) {
      deleteOrder(id);
    }

    modalRef.current?.open("edit");
  };

  const handleCheckClient = (id) => {
    orderDispatch(set_exportedOrder(id));
    orderDispatch(set_selectedOrder(id));

    orderDispatch(set_arrayCheckingOrder(id));
  };

  // const updateSts = async (tr_status: string) => {
  //   orderDispatch(set_filteringOrder(false));
  //   await updateStatus({ id: order.id, tr_status });
  //   await setUpdatedStatus({ tr_status, order_id: order.id });
  //   orderDispatch(set_filteringOrder(true));
  // };

  // useEffect(() => {
  //   if (!updatedStatus?.tr_status || !updatedStatus?.order_id) return;

  //   if (updatedStatus.order_id === order.id) {
  //     setTrStatus(updatedStatus.tr_status);
  //   }
  // }, [updatedStatus, order.id]);
  const orderID = `${order.id.substr(0, 4)}...${order.id.substr(
    order.id.length - 4,
    order.id.length
  )}`;

  return role ? (
    <>
      <td>
        <input
          onClick={() => handleCheckClient(order.id)}
          className={`form-check-input user-list-checkbox`}
          type="checkbox"
          checked={exportedOrder?.includes(order.id)}
          value={order.id}
        />
      </td>
      <td className="td-color-gray order-id">
        {
          <RoleProvider
            roles={[
              ROLES.SUPER_ADMIN,
              ROLES.MARKETING,
              ROLES.AML,
              ROLES.FINANCE,
            ]}
            fallback={<p>{orderID}</p>}
          >
            <Link
              to={`/orders/sell-flow/details/${order.id}${
                order?.country ? `?country=${order?.country}` : ""
              }`}
            >
              {/* {order.id} */}
              {orderID}
            </Link>
          </RoleProvider>
        }
      </td>
      <td className="td-clipboard">
        <Tooltip content="Copied">
          {(setShowTooltip) => (
            <Button
              type="button"
              className="clipboard-button"
              onClick={() => {
                copyToClipboard(order.id);
                setShowTooltip(true);
              }}
            >
              <Icon name="clipboard" />
            </Button>
          )}
        </Tooltip>
      </td>
      {ORDER_TABLE_ACCESS.amount[role] && (
        <>
          <td className="td-color-gray">{order.amount}</td>
          <td className="td-color-gray">{order.currency}</td>
        </>
      )}
      {ORDER_TABLE_ACCESS.asset[role] && <td>{order.asset}</td>}
      <td className="td-color-gray">{order?.bank_type ?? "-"}</td>
      {/* <td className="td-color-gray">{order?.status_name ?? "-"}</td> */}
      <td className="td-color-gray">
        <div
          className={`tr-status user-status-td ${
            txSellStatusInfo[order?.status_name]?.className
          }`}
        >
          {order.status_name?.replace(/(^|\s)\S/g, (l) => l.toUpperCase())}
          <Icon name={txSellStatusInfo[order?.status_name]?.icon} />
        </div>
      </td>
      <td className="td-color-gray">{order?.withdrawal ?? "-"}</td>
      <td className="td-color-gray">{order?.partners_rate ?? "-"}</td>
      <td className="td-color-gray">{order?.price ?? "-"}</td>
      <td className="td-color-gray partner-status">
        {order?.transaction_id
          ? `${order.transaction_id.substr(
              0,
              4
            )}...${order.transaction_id.substr(
              order.transaction_id.length - 4,
              order.transaction_id.length
            )}`
          : "-"}
      </td>
      <td className="td-color-gray order-id">
        {order?.email ? (
          <a href={`mailto:${order?.email}`}>{order?.email}</a>
        ) : (
          "-"
        )}
      </td>
      <td className="td-clipboard">
        {order?.email && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order?.email.trim());
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray">{order.network}</td>
      <td className="td-color-gray">{order.network_fee}</td>
      <td className="td-color-gray">{order.profit}</td>
      <td className="td-color-gray">{order.spot_price}</td>
      <td className="td-color-gray">{order.swaps_profit}</td>
      <td className="td-color-gray">{order.commission}</td>
      <td className="td-color-gray">{order.cost_of_service}</td>
      <td className="td-color-gray">{order.asset_quantity}</td>
      <td className="td-color-gray">{order.final_asset_qty ?? "-"}</td>
      <td className="td-color-gray order-id">{order.account_iban}</td>
      <td className="td-clipboard">
        {order.account_iban && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.account_iban);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray">{order.failure_reason ?? "-"}</td>
      <td className="td-color-gray order-id">{order.address ?? "-"}</td>
      <td className="td-clipboard">
        {order.address && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.address);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray order-id">
        {order.merchant_id
          ? `${order.merchant_id.substr(0, 4)}...${order.merchant_id.substr(
              order.merchant_id.length - 4,
              order.merchant_id.length
            )}`
          : "-"}
      </td>
      <td className="td-clipboard">
        {order.merchant_id && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.merchant_id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray order-id">
        {order?.tx_id ? (
          <a
            href={`https://www.blockchain.com/explorer/search?search=${order.tx_id}`}
            target="_blank"
            rel="noreferrer"
          >
            {`${order.tx_id.substr(0, 4)}...${order.tx_id.substr(
              order.tx_id.length - 4,
              order.tx_id.length
            )}`}
          </a>
        ) : (
          "-"
        )}
      </td>
      <td className="td-clipboard">
        {order?.tx_id && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order?.tx_id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray">{order?.country ?? "-"}</td>
      <td className="td-color-gray">
        {order?.partner_name ? (
          <Link to={`/partners/details/${order.partner_id}`}>
            {order?.partner_name ?? "-"}
          </Link>
        ) : (
          "-"
        )}
      </td>
      {ORDER_TABLE_ACCESS.createdAt[role] && (
        <td>{moment(order.createdAt).format("DD MMM yyyy, hh:mm")}</td>
      )}
      {ORDER_TABLE_ACCESS.action[role] && (
        <td>
          <TableActionButton
            isLast={isLast}
            //  to={`/orders/details/${order.id}`}
            viewLink={`/orders/sell-flow/details/${order.id}`}
            // openModal={() => {
            //   // `/orders/details/${order.id}`;
            //   getOrderById(order.id);
            //   orderDispatch(set_orderID(order.id));
            // }}
            ordersId={order.id}
            buttons={[
              {
                fromPage: "orderView",
                onClick: () => deletePressed(order.id),
                role: "order",
              },
            ]}
          />
        </td>
      )}
    </>
  ) : (
    <></>
  );
};

export default OrdersColumnFields;
