import { FC } from "react";

import "./company-profile.scss";
import CompanyProfile from "./CompanyProfile";

const Profile: FC = () => {
  return (
    <div className="page-company">
      <div className="company-profile">
        <CompanyProfile />
      </div>
    </div>
  );
};

export default Profile;
