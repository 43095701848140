import { FC, useState, useCallback } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { useAuthDispatchContext } from "providers/Auth";

import { showError } from "utils/helpers";
import NewPassword from "components/NewPassword";
import TwoFactorAuthentication from ".";

type RouteParams = {
  token: string;
};

const SetupNewPassword: FC = () => {
  const [password, setPassword] = useState("");
  const [agree, setAgree] = useState(false);
  const { token } = useParams<RouteParams>();
  const { createPassword, resetPassword } = useAuthDispatchContext();
  const history = useHistory();

  const onSendPassword = useCallback(async () => {
    try {
      if (history.location.pathname.includes("password/reset/")) {
        await resetPassword({ password: password, resetPasswordToken: token });

        return history.push("/code-verification-success/reset");
      }

      await createPassword({
        password: password,
        resetPasswordToken: token,
      });
      history.push("/account-auth-type");
    } catch {
      showError("something went wrong");
    }
  }, [createPassword, history, password, resetPassword, token]);

  return (
    <TwoFactorAuthentication>
      <div className="tfa-card">
        <div className="tfa-card-header">
          <h6 className="tfa-card-header-title">Setup New Password</h6>
          <p className="tfa-card-header-description">
            Do you have a password?
            <Link to="/login">Sign in here</Link>
          </p>
        </div>
        <NewPassword onChange={setPassword} />
        <div className="tfa-card-footer">
          <div className="tfa-card-footer-link">
            <label className="checkbox-2fa">
              <input
                checked={agree}
                onChange={(e) => setAgree(e.target.checked)}
                type="checkbox"
                className="form-check-input user-list-checkbox"
              />
              <span>
                I Agree &{" "}
                <a
                  href="https://swaps.app/terms-and-conditions"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms and conditions.
                </a>
              </span>
            </label>
          </div>
          <div className="tfa-card-footer-btn">
            <button
              onClick={onSendPassword}
              disabled={!agree || !password}
              // type="submit"
              id="kt_sign_in_submit"
              className={`btn btn-lg w-100 ${
                !agree || !password ? "btn-gray" : "btn-primary"
              }`}
            >
              <span className="indicator-label">Continue</span>
              <span className="indicator-progress">
                Please wait...
                <span className="spinner-border spinner-border-sm align-middle ms-2" />
              </span>
            </button>
          </div>
        </div>
      </div>
    </TwoFactorAuthentication>
  );
};

export default SetupNewPassword;
