import ContentLoader from "react-content-loader";

const PartnerDetailsSkeleton = (): JSX.Element => {
  return (
    <ContentLoader
      width={1200}
      height={670}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" ry="4" width="300" height="22" />
      <rect x="320" y="0" rx="4" ry="4" width="50" height="22" />
      <rect x="0" y="35" rx="4" ry="4" width="300" height="10" />
      <rect x="0" y="65" rx="4" ry="4" width="90" height="15" />
      <rect x="110" y="65" rx="4" ry="4" width="90" height="15" />
      <rect x="0" y="95" rx="4" ry="4" width="100%" height="1" />
      <rect x="0" y="125" rx="4" ry="4" width="24%" height="100" />
      <rect x="25%" y="125" rx="4" ry="4" width="24%" height="100" />
      <rect x="50%" y="125" rx="4" ry="4" width="24%" height="100" />
      <rect x="75%" y="125" rx="4" ry="4" width="24%" height="100" />
      <rect x="0" y="245" rx="4" ry="4" width="24%" height="100" />
      <rect x="25%" y="245" rx="4" ry="4" width="24%" height="100" />
      <rect x="50%" y="245" rx="4" ry="4" width="24%" height="100" />
      <rect x="75%" y="245" rx="4" ry="4" width="24%" height="100" />
      <rect x="0" y="365" rx="4" ry="4" width="100%" height="1" />
      <rect x="0" y="390" rx="4" ry="4" width="150" height="15" />
      <rect x="190" y="390" rx="4" ry="4" width="250" height="15" />
      <rect x="0" y="430" rx="4" ry="4" width="150" height="15" />
      <rect x="190" y="430" rx="4" ry="4" width="250" height="15" />
      <rect x="0" y="470" rx="4" ry="4" width="150" height="15" />
      <rect x="190" y="470" rx="4" ry="4" width="250" height="15" />
      <rect x="0" y="510" rx="4" ry="4" width="150" height="15" />
      <rect x="190" y="510" rx="4" ry="4" width="250" height="15" />
      <rect x="0" y="550" rx="4" ry="4" width="150" height="15" />
      <rect x="190" y="550" rx="4" ry="4" width="250" height="15" />
      <rect x="0" y="590" rx="4" ry="4" width="150" height="15" />
      <rect x="190" y="590" rx="4" ry="4" width="250" height="15" />
      <rect x="0" y="630" rx="4" ry="4" width="150" height="15" />
      <rect x="190" y="630" rx="4" ry="4" width="250" height="15" />
    </ContentLoader>
  );
};

export default PartnerDetailsSkeleton;
