import * as React from "react";

interface Props {
  isActive: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const TabSelector: React.FC<Props> = ({
  isActive,
  children,
  onClick,
}) => {
  return (
    <button
      className={`tabs-button ${
        isActive ? "tabs-button-active" : "tabs-button-disabled"
      }`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
