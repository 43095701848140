import { useEffect, useRef, FC } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import { useOrderDispatchContext, useOrderState } from "providers/Orders";
import { useOrderDispatch } from "providers/OrderFilter";
// import { Order } from "providers/Orders/types";
import moment from "moment";
import {
  useSellOrderDispatchContext,
  useSellOrderState,
} from "providers/SellOrders";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { Order } from "providers/Orders/types";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import useRole from "../../../hooks/useRole";
import { set_orderID } from "../../../providers/OrderFilter/ordersAction";
import { getTransferUrl, getWallBlockchair } from "utils/helpers";
// import useOrderEmail from "../../../hooks/useOrderEmail";
import { Icon, PageWrapper } from "components/base";
import { ORDER_DETAILS_TABLE_ACCESS } from "../../../services/roleService/order/orderDetails";
// import { txStatusInfo } from "../../../utils/constants";
// import TransactionLink from "../../../components/modals/TransactionLink";
// import plusSvg from "../../../assets/img/plus.svg";
// import masterSvg from "../../assets/img/payment-methods-master.svg";
// import trueLayerSvg from "../../assets/img/true-layer.svg";
// import appleSvg from "../../assets/img/payment-methods -apple.svg";
// import visaSvg from "../../assets/img/payment-methods-visa.svg";
import "../style.scss";
// import Invoice from "components/Invoice";
import OrderDetailsSkeleton from "../OrderDetailsSkeleton";
import { txSellStatusInfo } from "utils/constants";
import InvoiceSell from "components/InvoiceSell";
import plusSvg from "../../../assets/img/plus.svg";

const OrderDetailsNew: FC = () => {
  const { getOrderById } = useSellOrderDispatchContext();
  const orderDispatch = useOrderDispatch();
  const { orderDetails: order } = useSellOrderState();

  // const [sendEmail, setSendingEmail] = useState(false);

  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();

  const role = useRole();
  const ditailsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (slug) {
      getOrderById(slug);
      orderDispatch(set_orderID(slug));
    }
  }, [getOrderById, slug, orderDispatch]);

  const transferUrl = order?.tx_id && getTransferUrl(order.tx_id, order.asset);

  const country = new URLSearchParams(location.search).get("country");

  if (country && order) {
    order.country = country.trim();
  }

  return (
    <PageWrapper title={"Transaction Details"}>
      <div
        className="content d-flex flex-column flex-column-fluid"
        // ref={ditailsRef}
      >
        {!order ? (
          <OrderDetailsSkeleton />
        ) : (
          <div className="container" id="kt_content_container">
            <div className="d-flex flex-column">
              <div className="flex-column">
                <div className="card card-order">
                  <div className="order--header">
                    <div className="d-flex  flex-row card-details-header">
                      <div className="card-details-header-content">
                        <div className="card-details-header-content-body">
                          <span className="card-details-header-content-body-name">
                            {order?.account_name
                              ? `${order.account_name}`
                              : "-"}
                          </span>
                        </div>
                        <div className="card-details-header-content-id">
                          <Link to={`/users/details/${order?.id}`}>
                            {order?.email}
                          </Link>
                        </div>
                      </div>
                    </div>
                    {/* <InvoiceSell order={order as Order} /> */}
                    <div
                      style={{ display: "flex" }}
                      className="order--header-button sell-flow"
                    >
                      {role === "super_admin" && (
                        <RoleProvider roles={[ROLES.SUPER_ADMIN]}>
                          <PDFDownloadLink
                            document={<InvoiceSell order={order as Order} />}
                          >
                            <button>
                              <img src={plusSvg} />
                              Download pdf
                            </button>
                          </PDFDownloadLink>
                        </RoleProvider>
                      )}
                      {/* <button onClick={() => setSendingEmail(true)}>
                        <img src={plusSvg} />
                        <a
                          href={`mailto:${order?.client?.email}?subject=Orders Details`}
                        >
                          Send via E-mail
                        </a>
                      </button> */}
                    </div>
                    <div
                      style={{ display: "flex" }}
                      className="order--header-button"
                    ></div>
                  </div>
                  <div className="table-content" ref={ditailsRef}>
                    <table className="main-table">
                      {role && order && (
                        <tbody>
                          {ORDER_DETAILS_TABLE_ACCESS.orderId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Transaction ID</td>
                              <td className="td-answer">{order?.id}</td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.clientId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Customer ID</td>
                              <td className="td-answer with-color">
                                <Link
                                  to={`/users/details/${order?.client_id}`}
                                  style={{ padding: 0 }}
                                >
                                  {order?.client_id}
                                </Link>
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.partnerId[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Partner ID</td>
                              <td className="td-answer with-color">
                                <Link
                                  to={`/partners/details/${order?.partner_id}`}
                                  style={{ padding: 0 }}
                                >
                                  {order?.partner_id}
                                </Link>
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.wallet[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Wallet</td>
                              <td
                                className={`td-answer ${
                                  order?.address ? "with-color" : ""
                                }`}
                              >
                                {order?.address ? (
                                  <a
                                    href={getWallBlockchair(
                                      order?.asset,
                                      order?.address
                                    )}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ padding: 0 }}
                                  >
                                    {order?.address ?? "-"}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )}
                          <tr className="order-details-tr">
                            <td className="td-definition">Amount</td>
                            <td className="td-answer">
                              {order?.amount && order?.amount > 0
                                ? order?.amount
                                : "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Fiat</td>
                            <td className="td-answer">
                              {order?.currency ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Asset</td>
                            <td className="td-answer">{order?.asset ?? "-"}</td>
                          </tr>
                          {ORDER_DETAILS_TABLE_ACCESS.commission[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">
                                Partner Processing Fee
                              </td>
                              <td className="td-answer">
                                {order?.partners_rate &&
                                order?.partners_rate > 0
                                  ? order?.partners_rate
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.price[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Price</td>
                              <td className="td-answer">
                                {order?.price && order?.price > 0
                                  ? order?.price
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          <tr className="order-details-tr">
                            <td className="td-definition">Tracking ID</td>
                            <td className="td-answer">
                              {order?.transaction_id ?? "-"}
                            </td>
                          </tr>
                          {ORDER_DETAILS_TABLE_ACCESS.network_commission[
                            role
                          ] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Network</td>
                              <td className="td-answer">
                                {order?.network || "-"}
                              </td>
                            </tr>
                          )}
                          {ORDER_DETAILS_TABLE_ACCESS.network_commission[
                            role
                          ] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Network Fee</td>
                              <td className="td-answer">
                                {order?.network_fee && order?.network_fee > 0
                                  ? order?.network_fee
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          <tr className="order-details-tr">
                            <td className="td-definition">Profit</td>
                            <td className="td-answer">
                              {order?.profit && order?.profit > 0
                                ? order?.profit
                                : "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Spot Price</td>
                            <td className="td-answer">
                              {order?.spot_price && order?.spot_price > 0
                                ? order?.spot_price
                                : "-"}
                            </td>
                          </tr>
                          {ORDER_DETAILS_TABLE_ACCESS.swaps_profit[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Swaps Revenue</td>
                              <td className="td-answer">
                                {order?.swaps_profit && order?.swaps_profit > 0
                                  ? order?.swaps_profit
                                  : "-"}
                              </td>
                            </tr>
                          )}
                          {/* {ORDER_DETAILS_TABLE_ACCESS.rate[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Rate</td>
                              <td className="td-answer">
                                {order?.partners_rate || "-"} {order?.currency}
                              </td>
                            </tr>
                          )} */}
                          <tr className="order-details-tr">
                            <td className="td-definition">
                              Partner Commission
                            </td>
                            <td className="td-answer">
                              {order?.commission && order?.commission > 0
                                ? order?.commission
                                : "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Cost Of Service</td>
                            <td className="td-answer">
                              {order?.cost_of_service &&
                              order?.cost_of_service > 0
                                ? order?.cost_of_service
                                : "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Quantity</td>
                            <td className="td-answer">
                              {order?.asset_quantity &&
                              order?.asset_quantity > 0
                                ? order?.asset_quantity
                                : "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">
                              Final Asset Quantity
                            </td>
                            <td className="td-answer">
                              {order?.final_asset_qty &&
                              order?.final_asset_qty > 0
                                ? order?.final_asset_qty
                                : "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Account IBAN</td>
                            <td className="td-answer">
                              {order?.account_iban ?? "-"}
                            </td>
                          </tr>
                          {ORDER_DETAILS_TABLE_ACCESS.failed_reason[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition">Failure Reason</td>
                              <td className="td-answer">
                                {order?.failure_reason ?? "-"}
                              </td>
                            </tr>
                          )}

                          <tr className="order-details-tr">
                            <td className="td-definition">Merchant ID</td>
                            <td className="td-answer">
                              {order?.merchant_id ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Bank Type</td>
                            <td className="td-answer">
                              {order?.bank_type ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Status</td>
                            {/* <td className="td-answer">
                              {(order?.status as { name: string })?.name ?? "-"}
                            </td> */}
                            <td className="td-color-gray">
                              <div
                                className={`tr-status user-status-td ${
                                  txSellStatusInfo[
                                    (order?.status as { name: string })?.name
                                  ]?.className
                                }`}
                              >
                                {(
                                  order?.status as { name: string }
                                )?.name.replace(/(^|\s)\S/g, (l) =>
                                  l.toUpperCase()
                                )}
                                <Icon
                                  name={
                                    txSellStatusInfo[
                                      (order?.status as { name: string })?.name
                                    ]?.icon
                                  }
                                />
                              </div>
                            </td>
                          </tr>
                          {ORDER_DETAILS_TABLE_ACCESS.tx_id[role] && (
                            <tr className="order-details-tr">
                              <td className="td-definition with color">
                                <span className="td-without-hover">
                                  {" "}
                                  TX ID{" "}
                                </span>
                              </td>
                              <td
                                className={`td-answer ${
                                  order?.tx_id && "with-color"
                                }`}
                              >
                                {order?.tx_id && transferUrl ? (
                                  <a
                                    href={transferUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {order?.tx_id || "-"}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                            </tr>
                          )}
                          <tr className="order-details-tr">
                            <td className="td-definition">Billing Country</td>
                            <td className="td-answer">
                              {order.country ?? "-"}
                            </td>
                          </tr>
                          <tr className="order-details-tr">
                            <td className="td-definition">Date</td>
                            <td className="td-answer">
                              {moment(order?.createdAt).format(
                                "DD MMM yyyy, hh:mm"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default OrderDetailsNew;
