import { FC, useCallback } from "react";

import { ClientTierProps } from "./ClientTier.props";
import Button from "components/Button";

const tiersId = {
  TIER_1: "7f652f68-82e4-4aee-a172-7c0a5eb9578d",
  TIER_2: "dd6a5484-e9de-4a58-80f3-caa643492219",
};

const tiersQualifications = {
  TIER_0: "Tier 0",
  TIER_1: "Tier 1",
  TIER_2: "Tier 2",
};

const ClientTierPopover: FC<ClientTierProps> = ({
  tierId,
  defaultId,
  setTierId,
  items,
  updateTier,
  setIsPopupOpen,
}): JSX.Element => {
  const isDisabled = useCallback((index) => {
    if (
      index === 0 &&
      (tierId === tiersId.TIER_1 || tierId === tiersId.TIER_2)
    ) {
      return true;
    }

    if (index === 1 && tierId === tiersId.TIER_2) {
      return true;
    }

    if (index === 2) {
      return false;
    }

    return false;
    // eslint-disable-next-line
  }, []);

  return (
    <div className="popover-stats__container">
      <h1 className="popover-stats__title">Status</h1>
      <div className="popover-stats__container--radio">
        {items?.map((item, index) => (
          <div key={index} className="radio__wrapper">
            <label
              className={`radio__label ${
                isDisabled(index) ? "radio-disabled" : ""
              }`}
            >
              <input
                type="radio"
                name="radio"
                value={item.qualification}
                checked={tierId === item.id}
                disabled={isDisabled(index)}
                onChange={(e) => {
                  e.stopPropagation();
                  setTierId(item.id);
                }}
              />
              <span className="radio__checkmark"></span>{" "}
              {tiersQualifications[item.qualification]}
            </label>
          </div>
        ))}
      </div>
      <div className="radio-buttons__wrapper">
        <Button
          type="button"
          className="button-cancel"
          onClick={() => {
            setTierId(defaultId);
            setIsPopupOpen(false);
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={() => updateTier(tierId)}
          type="button"
          className="button-submit"
        >
          Change
        </Button>
      </div>
    </div>
  );
};

export default ClientTierPopover;
