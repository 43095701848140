import { useAuthStateContext } from "providers/Auth";
import { ROLES } from "providers/RoleProvider";

const useRole = (): string | undefined => {
  const { user } = useAuthStateContext();

  if (user?.role === ROLES.PARTNER) {
    return ROLES.PARTNER;
  } else {
    return ROLES.SUPER_ADMIN;
  }
};

export default useRole;

// return user?.role;
