import { FC } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { SignInData } from "providers/Auth/types";
import { useAuthDispatchContext, useAuthStateContext } from "providers/Auth";

import Icon from "../components/base/Icon";
import { email_regex } from "utils/constants";
import PasswordInput from "components/PasswordInput";

const Login: FC = () => {
  // context
  const { signIn } = useAuthDispatchContext();
  const { error } = useAuthStateContext();
  // form
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = (data: SignInData) => signIn(data);

  return (
    <div id="kt_body" className="bg-white login">
      {/* begin::Main */}
      <div className="d-flex flex-column flex-root">
        {/* begin::Authentication - Sign-in  */}
        <div className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed auth__container">
          {/* begin::Content */}
          <div className="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20 auth-content__wrapper">
            {/* begin::Logo */}
            <a href="index.html" className="mb-12 auth-logo__container">
              <Icon name="logo_text" />
            </a>
            {/* end::Logo */}
            {/* begin::Wrapper */}
            <div className="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto auth-form__container">
              {/* begin::Form */}
              <form
                className="form auth__form w-100"
                id="kt_sign_in_form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div>
                  <h1 className="text-dark mb-3">Account Login</h1>
                </div>
                <div className="mb-10">
                  <p className="text-left text-welcome mb-3">
                    Welcome back! Log In with your Email
                  </p>
                  {error && <p className="error-message">{error}</p>}
                </div>
                {/* begin::Heading */}
                {/* begin::Input group */}
                <div className="fv-row mb-5">
                  {/* begin::Label */}
                  <label className="form-label fs-6 fw-bolder text-dark">
                    Email
                  </label>
                  {/* end::Label */}
                  {/* begin::Input */}
                  <input
                    className={`form-control form-control-lg form-control-solid tfa-input ${
                      (error || errors.email) && "error"
                    }`}
                    type="text"
                    autoComplete="off"
                    placeholder="Enter your email"
                    {...register("email", {
                      required: true,
                      pattern: email_regex,
                    })}
                  />
                  <p className="input-error-message">
                    {errors.email && "Please enter valid email"}
                  </p>
                  {/* end::Input */}
                </div>
                {/* end::Input group */}
                {/* begin::Input group */}
                <div className="fv-row mb-5">
                  {/* begin::Wrapper */}
                  <div className="d-flex flex-stack mb-2">
                    {/* begin::Label */}
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                      Password
                    </label>
                    <Link to="/forgot-password" className="form-label mb-0">
                      Forgot Password?
                    </Link>
                    {/* end::Label */}
                    {/* begin::Link */}

                    {/* end::Link */}
                  </div>
                  {/* end::Wrapper */}
                  {/* begin::Input */}
                  <Controller
                    control={control}
                    name="password"
                    render={({ field }) => (
                      <PasswordInput
                        className={`form-control form-control-lg form-control-solid tfa-input password-input ${
                          (error || errors.password) && "error"
                        }`}
                        type="password"
                        autoComplete="off"
                        placeholder="Enter your password"
                        {...field}
                      />
                    )}
                  />

                  <p className="input-error-message">
                    {errors.password && "Password is required"}
                  </p>
                  {/* end::Input */}
                </div>
                {/* end::Input group */}
                {/* begin::Actions */}
                <div className="text-center">
                  {/* begin::Submit button */}
                  <button
                    type="submit"
                    id="kt_sign_in_submit"
                    className={`btn btn-lg btn-primary w-100 mb-5 ${
                      (errors.email || errors.password) && "invalid-button"
                    }`}
                  >
                    <span className="indicator-label">Continue</span>
                    <span className="indicator-progress">
                      Please wait...
                      <span className="spinner-border spinner-border-sm align-middle ms-2" />
                    </span>
                  </button>
                </div>
                {/* end::Actions */}
              </form>
              {/* end::Form */}
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Content */}
        </div>
        {/* end::Authentication - Sign-in */}
      </div>
      {/* end::Main */}
    </div>
  );
};

export default Login;
