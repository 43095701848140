import { FC, useRef, useState } from "react";
import { useWebhookDispatch, useWebhookState } from "providers/Webhooks";
import {
  setSelectedEvents,
  setWebhookEndpoint,
} from "providers/Webhooks/actions";
import { addPartnerEndpoint } from "services/webhooks";
import { useHistory } from "react-router-dom";
import { usePartnerStateContext } from "providers/Partners";

import { Icon } from "components/base";
import { EventType, EventTypes } from "../SelectEvent/SelectEvent.props";
import { isValidURL } from "utils/helpers";
import { PartnerWebhookFormProps } from "./PartnerWebhook.props";

const PartnerWebhookCreateForm: FC<PartnerWebhookFormProps> = ({
  activateSelectEvent,
}): JSX.Element => {
  const { partnerDetails } = usePartnerStateContext();
  const history = useHistory();
  const webhookRef = useRef(null);
  const { selectedEvents, eventTypes, webhookEndpoint } = useWebhookState();
  const [errorMessage, setErrorMessage] = useState("");
  const [eventErrorMessage, setEventErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const webhookDispatch = useWebhookDispatch();

  let chosenEvents;

  if (selectedEvents?.length && Object.keys(eventTypes as EventTypes)?.length) {
    chosenEvents = Object.keys(eventTypes as EventTypes)?.reduce(
      (acc1: EventType[], curr1) => {
        const events = (eventTypes as EventTypes)[curr1].items.reduce(
          (acc2: EventType[], curr2: EventType) => {
            if (selectedEvents?.includes(curr2.id)) {
              acc2 = [...acc2, curr2];
            }

            return acc2;
          },
          []
        );

        if (events?.length) {
          acc1 = [...acc1, ...events];
        }

        return acc1;
      },
      []
    );
  }

  const deleteEvent = (id: string) => {
    const filteredEvents = selectedEvents.filter((item) => item !== id);

    webhookDispatch(setSelectedEvents(filteredEvents));
  };

  const handleChange = (e) =>
    webhookDispatch(setWebhookEndpoint(e.target.value));

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!webhookEndpoint) {
      setErrorMessage("Add a valid URL");

      return;
    }

    if (isValidURL(webhookEndpoint)) {
      setErrorMessage("");

      if (selectedEvents?.length) {
        setLoading(true);
        const partnerData = {
          active: partnerDetails?.active,
          urls:
            partnerDetails?.urls &&
            partnerDetails?.urls.map((item) => item.url),
          name: partnerDetails?.name,
          contactName: partnerDetails?.contactName,
          phone: partnerDetails?.phone,
          email: partnerDetails?.email,
          partnerCommission: partnerDetails?.partnerCommission,
          partnerMarkup: partnerDetails?.partnerMarkup,
          callback_error: partnerDetails?.callback_error,
          comment: partnerDetails?.comment,
        };

        setEventErrorMessage("");
        await addPartnerEndpoint({
          callback_success: webhookEndpoint,
          partnerData,
          events: selectedEvents,
          partnerId: partnerDetails?.id as string,
        });
        setLoading(false);
        history.goBack();

        return;
      } else {
        setEventErrorMessage("An event must be selected");

        return;
      }
    } else {
      setErrorMessage("Not a valid URL");

      return;
    }
  };

  const goBack = () => history.goBack();

  return (
    <form className="webhook-create__form" onSubmit={handleSubmit}>
      <div className="webhook-create__form-control">
        <label htmlFor="endpoint">Endpoint URL</label>
        <input
          ref={webhookRef}
          value={webhookEndpoint}
          onChange={handleChange}
          id="endpoint"
          type="text"
          placeholder="https://"
        />
        {errorMessage && (
          <span className="webhook-create__error-message input-error-message">
            <Icon name="balance_error" /> {errorMessage}
          </span>
        )}
      </div>
      <div className="webhook-create__form-select-event">
        <span>Select events to listen to</span>
        {chosenEvents?.length ? (
          <>
            {chosenEvents.map((event) => (
              <button
                className="webhook-create__event-button"
                key={event.id}
                type="button"
                onClick={() => deleteEvent(event.id)}
              >
                {event.slug}
                <Icon name="close" />
              </button>
            ))}
            <button
              onClick={activateSelectEvent}
              className="webhook-create__button-edit"
            >
              {" "}
              <Icon name="edit_pencil" /> Change events
            </button>
          </>
        ) : (
          <>
            <button type="button" onClick={activateSelectEvent}>
              <Icon name="plus" />
              Select events
            </button>
            {eventErrorMessage && (
              <span className="webhook-create__error-message input-error-message">
                <Icon name="balance_error" /> {eventErrorMessage}
              </span>
            )}
          </>
        )}
      </div>
      <div className="webhook-create__form-button-group">
        <button disabled={loading} type="submit">
          {loading && <Icon name="spin" />}
          {history.location.pathname.includes("create") ? "Add" : "Update"}{" "}
          endpoint
        </button>
        <button type="button" onClick={goBack} disabled={loading}>
          Cancel
        </button>
      </div>
    </form>
  );
};

export default PartnerWebhookCreateForm;
