export const ACTION_SET_PAGE = "ACTION_SET_PAGE";
export const ACTION_SET_SEARCH = "ACTION_SET_SEARCH";
export const ACTION_SET_FILTERS = "ACTION_SET_FILTERS";
export const ACTION_SET_DATEFROM = "ACTION_SET_DATEFROM";
export const ACTION_SET_DATETO = "ACTION_SET_DATETO";
export const ACTION_SET_ORDER_SORTING = "ACTION_SET_ORDER_SORTING";
export const ACTION_SET_ORDERING = "ACTION_SET_ORDERING";
export const ACTION_SET_ORDER_COUNT = "ACTION_SET_CLIENT_COUNT";
export const ACTION_SET_SETTINGS_TOGGLE = "ACTION_SET_SETTINGS_TOGGLE";
export const ACTION_SET_EXPORT_ORDER = "ACTION_SET_EXPORT_CLIENT";
export const ACTION_SET_SELECT_ORDER = "ACTION_SET_SELECT_CLIENT";
export const ACTION_SET_ORDER_PARTNER = "ACTION_SET_ORDER_PARTNER";
export const ACTION_SET_AMOUNT_FROM = "ACTION_SET_AMOUNT_FROM";
export const ACTION_SET_AMOUNT_TO = "ACTION_SET_AMOUNT_TO";
export const ACTION_SET_RATE_FROM = "ACTION_SET_RATE_FROM";
export const ACTION_SET_RATE_TO = "ACTION_SET_RATE_TO";
export const ACTION_SET_QUANTITY_FROM = "ACTION_SET_QUANTITY_FROM";
export const ACTION_SET_QUANTITY_TO = "ACTION_SET_QUANTITY_TO";
export const ACTION_SET_ORDER_ASSET = "ACTION_SET_ORDER_ASSET";
export const ACTION_SET_ORDER_CURRENCY = "ACTION_SET_ORDER_CURRENCY";
export const ACTION_SET_ORDER_EXCHANGE = "ACTION_SET_ORDER_EXCHANGE";
export const ACTION_SET_ORDER_WITHDRAWAL = "ACTION_SET_ORDER_WITHDRAWAL";
export const ACTION_SET_CHECKED_PARTNER = "ACTION_SET_CHECKED_PARTNER";
export const ACTION_SET_CHECKED_ID_ORDER = "ACTION_SET_CHECKED_ID_ORDER";
export const ACTION_SET_ARRAY_CHECK_ORDER = "ACTION_SET_ARRAY_CHECK_ORDER";
export const ACTION_SET_ORDER_ID = "ACTION_SET_ORDER_ID";
export const ACTION_SET_PARTNER_NAME = "ACTION_SET_PARTNER_NAME";
export const ACTION_SET_PAGE_NAME = "ACTION_SET_PAGE_NAME";
export const ACTION_SET_FILTER_BUTTON_VAL = "ACTION_SET_FILTER_BUTTON_VAL";
export const ACTION_SET_PAYMENT_METHOD = "ACTION_SET_PAYMENT_METHOD";
export const ACTION_SET_STATS = "ACTION_SET_STATS";
export const ACTION_SET_CHECKED_PAGE = "ACTION_SET_CHECKED_PAGE";
export const ACTION_SET_LOADING = "ACTION_SET_LOADING";
export const ACTION_SET_TR_STATUS = "ACTION_SET_TR_STATUS";
export const ACTION_RESET_FILTERS = "ACTION_RESET_FILTERS";
export const ACTION_SET_STATUS = "ACTION_SET_STATUS";
