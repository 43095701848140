import { AxiosResponse } from "axios";
import { useEffect, useState } from "react";

import { instance } from "utils/api";

interface EmailType {
  getOrdersEmail?: AxiosResponse;
  loading: boolean;
  error: unknown;
}

const useOrderEmail: (id: string | undefined) => EmailType = (id) => {
  const [getOrdersEmail, setGetOrders] = useState<AxiosResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<unknown>();
  useEffect(() => {
    if (id === undefined) return;

    try {
      instance
        .get(`/payment/orders/send-mail/${id}`)
        .then((item) => setGetOrders(item));
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  return { getOrdersEmail, loading, error };
};

export default useOrderEmail;
