import React, { FC, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// import { useOrderDispatchContext } from "providers/Orders";

import Button from "../../Button";
import Icon from "../../base/Icon";
import Input from "../../Input";
import { FilterDropDownProps } from "./FilterDropDown.props";

const CustomDatePickerInput: React.ForwardRefExoticComponent<
  {
    value?: string | number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onClick?: () => void;
    onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  } & React.RefAttributes<HTMLInputElement>
> = React.forwardRef((props, ref) => {
  return (
    <Input
      type="text"
      value={props?.value}
      onChange={props?.onChange}
      onClick={props?.onClick}
      onBlur={props?.onBlur}
      ref={ref}
      icon={<Icon name="filter_datepicker" />}
    />
  );
});

const FilterDropDown: FC<FilterDropDownProps> = ({
  header,
  type,
  localValue,
  date,
  numberRange,
  selectOptions,
  setNumberRange,
  setDate,
  setLocalValue,
  secondLevelHandler,
  handleApply,
  setValueCompleted,
  isValid,
  setIsValid,
  value,
  assetValue,
  setAssetValue,
}) => {
  const [error, setError] = useState<string>("");

  const renderType = useMemo(() => {
    switch (type) {
      case "input":
        return (
          <Input
            type="text"
            className="filter__dropdown--input"
            placeholder="Search"
            value={localValue as string}
            onChange={(e) => {
              setLocalValue(e.target.value);
            }}
            onBlur={(e) => {
              secondLevelHandler(e, setError, setIsValid);
            }}
          />
        );
      case "number_input":
        return (
          <Input
            type="number"
            inputMode="numeric"
            pattern="\d*"
            className="filter__dropdown--input"
            placeholder="Search"
            value={localValue as string}
            onChange={(e) => {
              setLocalValue(e.target.value);
            }}
            onBlur={(e) => {
              secondLevelHandler(e, setError, setIsValid);
            }}
            onFocus={(e) =>
              e.target.addEventListener(
                "wheel",
                function (e) {
                  e.preventDefault();
                },
                { passive: false }
              )
            }
          />
        );
      case "select":
        return (
          <div className="select__wrapper--pressable">
            <div className="select__container">
              <select
                className="select__container--select"
                value={value === "ASSET" ? assetValue : (localValue as string)}
                onChange={function (e) {
                  if (value === "ASSET") {
                    setAssetValue(e.target.value);
                    const index = e.target.selectedIndex;
                    setLocalValue(e.target[index].innerText);

                    return;
                  }

                  setLocalValue(e.target.value);
                }}
                onBlur={(e) => secondLevelHandler(e, setError, setIsValid)}
                defaultValue={
                  selectOptions && (selectOptions[0]?.value as string)
                }
              >
                {selectOptions?.map((selectOption, index) => (
                  <option
                    key={index + Math.random() * 5 + 7 * 9}
                    value={selectOption?.value as string}
                    selected={index === 0}
                  >
                    {selectOption?.label}
                  </option>
                ))}
              </select>
              <Icon name="dropdown_icon" />
            </div>
            <div className="select__pointer-overlay"></div>
          </div>
        );
      case "date_picker":
        return (
          <div className="datepicker__wrapper">
            <div className="datepicker__inner-block">
              <h1 className="datepicker__header">From</h1>
              <DatePicker
                selected={date?.startDate}
                onSelect={(_, e) => {
                  e?.stopPropagation();
                }}
                onChange={(dateValue, e) => {
                  e?.stopPropagation();
                  setDate({
                    ...date,
                    startDate: dateValue as Date | null | undefined,
                  });
                  secondLevelHandler({ dateFrom: dateValue });
                }}
                customInput={<CustomDatePickerInput />}
                maxDate={new Date()}
              />
            </div>
            <div className="datepicker__inner-block">
              <h1 className="datepicker__header">to</h1>
              <DatePicker
                autoFocus
                selected={date?.endDate}
                onSelect={(_, e) => {
                  e?.stopPropagation();
                }}
                onChange={(dateValue, e) => {
                  e?.stopPropagation();
                  setDate({
                    ...date,
                    endDate: dateValue as Date | null | undefined,
                  });

                  if (
                    date?.startDate &&
                    dateValue &&
                    new Date(dateValue as Date).getTime() <
                      date?.startDate?.getTime()
                  ) {
                    return setError(
                      "The 'To' field should not be less than or equal the 'From' field"
                    );
                  }

                  if (dateValue) {
                    setError("");
                    secondLevelHandler({ dateTo: dateValue });
                  }
                }}
                customInput={<CustomDatePickerInput />}
                maxDate={new Date()}
                popperPlacement="bottom"
              />
            </div>
          </div>
        );
      case "number_range":
        return (
          <div className="number-range__wrapper">
            <div className="number-range__inner-block">
              <h1 className="number-range__header">From</h1>
              <input
                type="number"
                className="number-range__input"
                inputMode="numeric"
                pattern="\d*"
                value={numberRange?.numberFrom}
                onChange={(e) => {
                  if (typeof numberRange === "object" && setNumberRange) {
                    setNumberRange({
                      ...numberRange,
                      numberFrom: e.target.value,
                    });
                  }
                }}
                onBlur={(e) => {
                  secondLevelHandler(e, setError, setIsValid, "from");
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
            </div>
            <div className="number-range__inner-block">
              <h1 className="number-range__header">to</h1>
              <input
                type="number"
                className="number-range__input"
                inputMode="numeric"
                pattern="\d*"
                value={numberRange?.numberTo}
                onChange={(e) => {
                  if (typeof numberRange === "object" && setNumberRange) {
                    if (
                      e.target.value &&
                      parseFloat(e.target.value) <
                        parseFloat(numberRange?.numberFrom)
                    ) {
                      setError(
                        'The "To" field should not be less the "From" field'
                      );
                    } else {
                      setError("");
                    }

                    setNumberRange({
                      ...numberRange,
                      numberTo: e.target.value,
                    });
                  }
                }}
                onBlur={(e) =>
                  secondLevelHandler(e, setError, setIsValid, "to")
                }
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
              />
            </div>
          </div>
        );
      default:
        return;
    }
  }, [
    type,
    date,
    setDate,
    secondLevelHandler,
    localValue,
    setLocalValue,
    numberRange,
    setNumberRange,
    setIsValid,
    selectOptions,
    value,
    setAssetValue,
    assetValue,
  ]);

  const handleSubmit = () => {
    if (type === "select" && selectOptions?.length && !localValue) {
      setLocalValue(selectOptions[0]?.value as string);
      secondLevelHandler(
        { target: { value: selectOptions[0]?.value } },
        setError,
        setIsValid
      );
    }

    if (error) {
      return;
    }

    if (
      !["select", "date_picker", "number_range"].includes(type) &&
      !localValue
    ) {
      setError("Enter a value");

      return;
    }

    if (type === "number_range") {
      if (!numberRange?.numberFrom || !numberRange?.numberTo)
        return setError("Enter a value");

      if (
        parseFloat(numberRange?.numberTo) < parseFloat(numberRange?.numberFrom)
      ) {
        setError('The "To" field should not be less the "From" field');
        setIsValid(false);

        return;
      }
    }

    if (type === "date_picker") {
      if (date?.startDate && date?.endDate) {
        if (date?.endDate.getTime() < date?.startDate.getTime())
          return setError('The "To" field should not be less the "From" field');

        secondLevelHandler({
          dateFrom: date?.startDate,
          dateTo: date?.endDate,
        });

        setError("");
        setValueCompleted(true);
        handleApply("date_picker");
      } else {
        setError("Enter a value");
      }

      return;
    }

    if (isValid) {
      setError("");
      setValueCompleted(true);
      handleApply(type);

      return;
    }

    setValueCompleted(true);
    handleApply(type);
  };

  return (
    <div className="filter-dropdown__container">
      <div className="filter-dropdown__headerline">
        <h1 className="filter-dropdown__header">{header}</h1>
      </div>
      <div className="filter-dropdown__input-wrapper">
        {renderType}
        {error && (
          <div className="filter-dropdown__filter-error">
            <Icon name="filter_error_arrow" />
            {error}
          </div>
        )}
      </div>
      <Button type="button" className="filter--apply" onClick={handleSubmit}>
        Apply
      </Button>
    </div>
  );
};

export default FilterDropDown;
