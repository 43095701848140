import { useMemo, useState } from "react";

import PayoutOTP from "pages/PayoutOTP";
import PayoutFunds from "./PayoutFunds";

const Payout = (): JSX.Element => {
  const [renderFlow, setRenderFlow] = useState("payout");

  const renderPayoutFlow = useMemo(() => {
    switch (renderFlow) {
      case "payout":
        return <PayoutFunds setRenderFlow={setRenderFlow} />;
      case "otp":
        return <PayoutOTP />;
      default:
        return null;
    }
  }, [renderFlow]);

  return <>{renderPayoutFlow}</>;
};

export default Payout;
