import { ActionTypes, State, Action, User } from "./types";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SIGN_IN:
      return {
        ...state,
        isLoggedIn: true,
        user: {
          ...state.user,
          ...(action.data as User),
        },
      };
    case ActionTypes.SET_USER:
      return {
        ...state,
        user: action.data as User,
      };
    case ActionTypes.SIGN_OUT:
      return { ...state, isLoggedIn: false, user: null };
    case ActionTypes.ADD_PHONE:
      return {
        ...state,
        user: { ...state.user, phoneNumber: action.data } as User,
      };
    case ActionTypes.SET_ERROR:
      return {
        ...state,
        error: action.data as string,
      };
    default:
      return state;
  }
};

export default reducer;
