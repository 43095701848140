import { useBalanceDispatchContext } from "providers/Balance";
import { useEffect } from "react";

import Filter from "components/Filter";
import { balanceFiltersTree } from "utils/constants";

const BalanceFiltersWrapper = (): JSX.Element => {
  const { setActiveBalance, clearExports } = useBalanceDispatchContext();

  const firstLevelHandlers = (selectedOption) => {
    clearExports();
    setActiveBalance(selectedOption.toLowerCase());
  };

  useEffect(() => {
    setActiveBalance("payout");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Filter
        handleFirstLevelFilter={firstLevelHandlers}
        filterValues={balanceFiltersTree}
      />
    </>
  );
};

export default BalanceFiltersWrapper;
