import { IList } from "components/Promotions/types";
import {
  ActionTypes,
  State,
  Action,
  Client,
  ClientLog,
  Tier,
  ClientTier,
} from "./types";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: action.data as boolean,
      };
    }

    case ActionTypes.SET_CLIENTS: {
      const { rows, count } = action.data as { rows: Client[]; count: number };

      return {
        ...state,
        isLoading: false,
        clients: rows,
        clientsCount: count,
      };
    }

    case ActionTypes.SET_TIERS: {
      const tiers = action.data as ClientTier[];

      return {
        ...state,
        tiers,
      };
    }

    case ActionTypes.UPDATE_TIER: {
      const { tier } = action.data as { tier: Tier };
      const updatedClients = state.clients;

      const clientIdx = state.clients.findIndex(
        (client) => client.id === tier.client_id
      );

      if (clientIdx > -1) {
        let tiers = state.clients[clientIdx].tiers;

        const tierIdx = tiers.findIndex((item) => item.id === tier.id);

        if (tierIdx > -1) {
          tiers[tierIdx] = {
            ...tiers[tierIdx],
            ClientTiers: {
              ...tiers[tierIdx].ClientTiers,
              limit: tier.limit as number,
            },
          };
        } else {
          tiers = [
            {
              ...tier,
              ClientTiers: {
                limit: tier.limit as number,
                client_id: "",
                tier_id: "",
                createdAt: "",
                updatedAt: "",
              },
            },
            ...tiers,
          ];
        }

        updatedClients[clientIdx].tiers = tiers;

        return {
          ...state,
          clients: updatedClients,
        };
      }

      return state;
    }

    case ActionTypes.SET_LOGS: {
      const { rows, count } = action.data as {
        rows: ClientLog[];
        count: number;
      };

      return {
        ...state,
        isLoading: false,
        logs: rows,
        logsCount: count,
      };
    }

    case ActionTypes.SET_CLIENT_DETAILS:
      return { ...state, clientDetails: action.data as Client };

    case ActionTypes.UPDATE_STATUS: {
      const { id, status } = action.data as {
        id: string;
        status: string;
        comment: string;
      };

      const clients = state.clients.map((client) => {
        if (client.id === id) {
          return { ...client, status };
        }

        return client;
      });

      return { ...state, clients };
    }

    case ActionTypes.SET_PROMOTIONS_IS_LOADED: {
      return {
        ...state,
        clientPromotions: {
          ...state.clientPromotions,
          isLoaded: action.data as boolean,
        },
      };
    }

    case ActionTypes.SET_PROMOTIONS_LIST: {
      return {
        ...state,
        clientPromotions: {
          ...state.clientPromotions,
          list: action.data as IList[],
        },
      };
    }

    case ActionTypes.UPDATE_PROMOTIONS_LIST: {
      const updatedPromotion = action.data as IList;
      const newList = state.clientPromotions.list.filter(
        (el) => el.id !== updatedPromotion.id
      );

      newList.push(updatedPromotion);

      return {
        ...state,
        clientPromotions: {
          ...state.clientPromotions,
          list: newList,
        },
      };
    }

    case ActionTypes.ADD_PROMOTION: {
      const newPromotion = action.data as IList;
      const newList = state.clientPromotions.list;

      newList.push(newPromotion);

      return {
        ...state,
        clientPromotions: {
          ...state.clientPromotions,
          list: newList,
        },
      };
    }

    case ActionTypes.SET_PROMOTIONS_TYPES: {
      return {
        ...state,
        clientPromotions: {
          ...state.clientPromotions,
          types: action.data as [],
        },
      };
    }

    case ActionTypes.SET_PROMOTIONS_COUNT: {
      return {
        ...state,
        clientPromotions: {
          ...state.clientPromotions,
          count: action.data as number,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
