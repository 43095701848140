import { FC } from "react";
import classnames from "classnames";

import Button from "components/Button";
import { SettingsTabItemProps } from "./SettingsTabItem.props";
// import { SettingsTabItemProps } from "./SettingsTabItem.props";

const SettingsTabItem: FC<SettingsTabItemProps> = ({
  children,
  onClick,
  className,
}): JSX.Element => {
  return (
    <li className={classnames("tab-item__wrapper", className)}>
      <Button type="button" className="button--transparent" onClick={onClick}>
        <span className="tab-item">{children}</span>
      </Button>
    </li>
  );
};

export default SettingsTabItem;
