import { FC } from "react";
import QRCode from "react-qr-code";

import { BalanceQRProps } from "./Balance.props";

const BalanceQR: FC<BalanceQRProps> = ({ walletAddress }): JSX.Element => {
  return (
    <div className="balance-qr__container">
      {walletAddress && <QRCode size={38} value={walletAddress} />}
    </div>
  );
};

export default BalanceQR;
