import { useHistory, useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { getEventById } from "services/webhooks";
import { EventItem, EventResponseItem } from "services/webhooks/types";
import moment from "moment";

import { PageWrapper } from "components/base";
import "./event-details.scss";
import CodeBlock from "./CodeBlock";
import ExpandableRow from "./ExpandableRow";
import EventDetailsSkeleton from "./EventDetailsSkeleton";

const EventDetails = (): JSX.Element => {
  const history = useHistory();
  const [eventDetails, setEventDetails] = useState<EventItem | undefined>(
    undefined
  );

  const [eventCode, setEventCode] = useState<EventResponseItem | undefined>(
    undefined
  );

  const { id }: { id: string } = useParams();

  const fetchEventById = useCallback(async () => {
    if (id) {
      const evt = await getEventById({ id });

      if (evt) {
        setEventDetails(evt);
        // eslint-disable-next-line
        const { webhook_log, event, ...reducedEvent } = evt as EventItem;

        return setEventCode(reducedEvent);
      }
    }

    return;
  }, [id]);

  useEffect(() => {
    fetchEventById();
  }, [fetchEventById]);

  return (
    <PageWrapper title="Payments list">
      <div className="event-details__container">
        {!eventDetails || !eventCode ? (
          <EventDetailsSkeleton />
        ) : (
          <>
            <div className="event-details__banner">
              <button onClick={() => history.push("/setting/events")}>
                Events
              </button>
              {/* <span>{eventDetails?.event_id}</span> */}
            </div>
            <div className="event-details__header">
              <h1>{eventDetails?.event.slug}</h1>
            </div>
            <div className="event-details__additional-info">
              <div>
                <span>Date</span>
                <span>
                  {moment(eventDetails?.createdAt).format("DD MMM yyyy, hh:mm")}
                </span>
              </div>
            </div>
            <div className="event-details__code-block">
              <h2>Event data</h2>
              {eventCode && <CodeBlock code={eventCode} />}
            </div>

            <div className="event-details__webhook-attempts">
              <div className="event-details__attempts-banner">
                <div>
                  <span>Webhook attempts</span>
                  <span>
                    Attempts to send this event to your webhook endpoints
                  </span>
                </div>
              </div>
              <table className="event-details__table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Webhook Url</th>
                    <th>Date</th>
                    {/* <th></th> */}
                  </tr>
                </thead>
                <tbody>
                  {eventDetails?.webhook_log?.length ? (
                    eventDetails?.webhook_log?.map((item) => (
                      <ExpandableRow
                        eventCode={eventCode as EventItem}
                        eventDetails={eventDetails}
                        webhook_log={item}
                        key={item.id}
                      />
                    ))
                  ) : (
                    <td colSpan={4} style={{ textAlign: "center" }}>
                      <span>No recent webhook attempts</span>
                    </td>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  );
};

export default EventDetails;
