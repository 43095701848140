import { FilterType } from ".";
import { actionType } from "./ordersAction";
import {
  ACTION_SET_ORDER_COUNT,
  ACTION_SET_DATEFROM,
  ACTION_SET_DATETO,
  ACTION_SET_EXPORT_ORDER,
  ACTION_SET_FILTERS,
  ACTION_SET_ORDERING,
  ACTION_SET_ORDER_PARTNER,
  ACTION_SET_PAGE,
  ACTION_SET_SEARCH,
  ACTION_SET_SETTINGS_TOGGLE,
  ACTION_SET_ORDER_SORTING,
  ACTION_SET_AMOUNT_FROM,
  ACTION_SET_AMOUNT_TO,
  ACTION_SET_QUANTITY_TO,
  ACTION_SET_QUANTITY_FROM,
  ACTION_SET_RATE_TO,
  ACTION_SET_RATE_FROM,
  ACTION_SET_ORDER_ASSET,
  ACTION_SET_ORDER_CURRENCY,
  ACTION_SET_ORDER_EXCHANGE,
  ACTION_SET_SELECT_ORDER,
  ACTION_SET_CHECKED_PARTNER,
  ACTION_SET_CHECKED_ID_ORDER,
  ACTION_SET_ARRAY_CHECK_ORDER,
  ACTION_SET_ORDER_ID,
  ACTION_SET_PARTNER_NAME,
  ACTION_SET_PAGE_NAME,
  ACTION_SET_FILTER_BUTTON_VAL,
  ACTION_SET_PAYMENT_METHOD,
  ACTION_SET_ORDER_WITHDRAWAL,
  ACTION_SET_STATS,
  ACTION_SET_CHECKED_PAGE,
  ACTION_SET_LOADING,
  ACTION_SET_TR_STATUS,
  ACTION_RESET_FILTERS,
  ACTION_SET_CUSTOMER_ID,
  ACTION_SET_ADDRESS,
  ACTION_SET_STATUS,
  ACTION_SET_EMAIL,
  ACTION_SET_BILLING_COUNTRY,
  ACTION_SET_PAYER_EMAIL,
  ACTION_SET_RECEIVE_STATUS,
  ACTION_SET_ID,
  ACTION_SET_RECEIVE_ID,
} from "./types";

const ordersReducer = (
  state: FilterType,
  action: actionType
): Record<string, unknown> => {
  const resetFilters = () => {
    return {
      ...state,
      page: 1,
      orderSearch: "",
      filters: [],
      dateFromOrder: "",
      dateToOrder: "",
      amountFrom: "",
      amountTo: "",
      rateFrom: "",
      rateTo: "",
      quantityFrom: "",
      quantityTo: "",
      sorting2: undefined,
      ordering2: undefined,
      exportedOrder: [],
      selectedOrder: "",
      partnerOrder: [],
      assetOrder: undefined,
      currencyOrder: undefined,
      exchangeOrder: undefined,
      withdrawalOrder: undefined,
      checkedPartner: [],
      checkedIDOrder: { checked: false, orders: [] },
      arrayCheckingOrder: [],
      orderID: undefined,
      partnerSelection: [],
      pageName: undefined,
      filterButtonVal: undefined,
      paymentMethods: undefined,
      statsFilter: false,
      checkedPages: [],
      loading: false,
      payerEmail: undefined,
      trStatus: "",
      receiveStatus: undefined,
      id: undefined,
      receiveId: undefined,
    };
  };

  const partnersNameHandler = (state, action) => {
    const newState = { ...state };
    const array = [...newState.partnerOrder];

    newState.partnerOrder = array;
    const index = newState?.partnerOrder?.findIndex(
      (item) => item === action.payload
    );

    if (index > -1) {
      newState?.partnerOrder?.splice(index, 1);

      return { ...newState };
    }

    newState?.partnerOrder?.push(action.payload);

    const deselectAll = newState?.partnerOrder?.find(
      (elem) => elem === "false" || elem === "true"
    );

    switch (deselectAll) {
      case "false":
        newState.partnerOrder = [];
        newState.partnerSelection = [];
        break;
      case "true":
        newState.partnerOrder = newState.checkedPartner.map((item) => item.id);
        newState.partnerSelection = newState.checkedPartner.map(
          (item) => item.name
        );
        break;
      default:
        break;
    }

    return { ...newState };
  };

  const exportOrderHandler = (state, action) => {
    let filteredOrders = state.exportedOrder;

    if (action.payload && state.exportedOrder.includes(action?.payload)) {
      filteredOrders = state.exportedOrder.filter(
        (item) => item !== action?.payload
      );
    }

    if (action.payload && !state.exportedOrder.includes(action?.payload)) {
      filteredOrders = [...state.exportedOrder, action.payload];
    }

    return {
      ...state,
      exportedOrder: filteredOrders,
    };
  };

  const handlArrayCheckingOrders = (state, payload) => {
    return {
      ...state,
      arrayCheckingOrder: state?.arrayCheckingOrder?.includes(payload)
        ? state.arrayChecking
        : state?.arrayCheckingOrder?.length
        ? [...state?.arrayCheckingOrder, payload]
        : [payload],
    };
  };

  const settingToggleHandler = (state, action) => {
    const newState = { ...state };
    const array = [...newState.orderSettingsToggle];

    newState.orderSettingsToggle = array;
    const index = newState?.orderSettingsToggle?.findIndex(
      (item) => item === action.payload
    );

    if (index > -1) {
      newState?.orderSettingsToggle?.splice(index, 1);

      return { ...newState };
    }

    newState?.orderSettingsToggle.push(action.payload);

    return { ...newState };
  };

  const partnerNameHandler = (state, action) => {
    const newState = { ...state };
    const array = [...newState.partnerSelection];

    newState.partnerSelection = array;
    const index = newState?.partnerSelection?.findIndex(
      (item) => item === action.payload
    );

    if (index > -1) {
      newState?.partnerSelection?.splice(index, 1);

      return { ...newState };
    }

    newState?.partnerSelection.push(action.payload);

    return { ...newState };
  };

  const setOrders = (
    state,
    payload: { checked: boolean; orders: string[] }
  ) => {
    return {
      ...state,
      checkedIDOrder: { checked: payload.checked, orders: payload?.orders },
      exportedOrder: !payload?.checked ? [] : payload?.orders,
    };
  };

  switch (action.type) {
    case ACTION_SET_PAGE:
      return {
        ...state,
        page: action.payload,
      };
    case ACTION_SET_SEARCH:
      return {
        ...state,
        orderSearch: action.payload,
      };
    case ACTION_SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case ACTION_SET_DATEFROM:
      return {
        ...state,
        dateFromOrder: action.payload,
      };
    case ACTION_SET_DATETO:
      return {
        ...state,
        dateToOrder: action.payload,
      };
    case ACTION_SET_AMOUNT_FROM:
      return {
        ...state,
        amountFrom: action.payload,
      };
    case ACTION_SET_AMOUNT_TO:
      return {
        ...state,
        amountTo: action.payload,
      };
    case ACTION_SET_RATE_FROM:
      return {
        ...state,
        rateFrom: action.payload,
      };
    case ACTION_SET_FILTER_BUTTON_VAL:
      return {
        ...state,
        filterButtonVal: action.payload,
      };
    case ACTION_SET_RATE_TO:
      return {
        ...state,
        rateTo: action.payload,
      };
    case ACTION_SET_QUANTITY_FROM:
      return {
        ...state,
        quantityFrom: action.payload,
      };
    case ACTION_SET_QUANTITY_TO:
      return {
        ...state,
        quantityTo: action.payload,
      };
    case ACTION_SET_ORDER_ASSET:
      return {
        ...state,
        assetOrder: action.payload,
      };
    case ACTION_SET_ORDER_CURRENCY:
      return {
        ...state,
        currencyOrder: action.payload,
      };
    case ACTION_SET_CHECKED_ID_ORDER:
      return setOrders(
        state,
        action.payload as { checked: boolean; orders: string[] }
      );
    case ACTION_SET_ORDER_EXCHANGE:
      return {
        ...state,
        exchangeOrder: action.payload,
      };
    case ACTION_SET_ORDER_WITHDRAWAL:
      return {
        ...state,
        withdrawalOrder: action.payload,
      };
    case ACTION_SET_ORDER_SORTING:
      return {
        ...state,
        sorting2: action.payload,
      };
    case ACTION_SET_ORDERING:
      return {
        ...state,
        ordering2: action.payload,
      };
    case ACTION_SET_ORDER_COUNT:
      return {
        ...state,
        visibleOrdersCount: action.payload,
      };
    case ACTION_SET_EMAIL:
      return {
        ...state,
        email: action.payload,
      };
    case ACTION_SET_ARRAY_CHECK_ORDER:
      return handlArrayCheckingOrders(state, action.payload as string);
    case ACTION_SET_ORDER_ID:
      return {
        ...state,
        orderID: action.payload,
      };
    case ACTION_SET_STATUS:
      return {
        ...state,
        statusId: action.payload,
      };
    case ACTION_SET_SELECT_ORDER:
      return {
        ...state,
        selectedOrder: action.payload,
      };
    case ACTION_SET_PAGE_NAME:
      return {
        ...state,
        pageName: action.payload,
      };
    case ACTION_SET_PARTNER_NAME:
      return partnerNameHandler(state, action);
    case ACTION_SET_CHECKED_PARTNER:
      return {
        ...state,
        checkedPartner: action.payload,
      };
    case ACTION_SET_SETTINGS_TOGGLE:
      return settingToggleHandler(state, action);
    case ACTION_SET_ORDER_PARTNER:
      return partnersNameHandler(state, action);
    case ACTION_SET_EXPORT_ORDER:
      return exportOrderHandler(state, action);
    case ACTION_SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethods: action.payload,
      };
    case ACTION_SET_STATS:
      return {
        ...state,
        statsFilter: action.payload,
      };
    case ACTION_SET_CHECKED_PAGE:
      return {
        ...state,
        checkedPages: action.payload,
      };
    case ACTION_SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case ACTION_SET_TR_STATUS:
      return {
        ...state,
        trStatus: action.payload,
      };
    case ACTION_SET_CUSTOMER_ID:
      return {
        ...state,
        customerId: action.payload,
      };
    case ACTION_SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case ACTION_SET_BILLING_COUNTRY:
      return {
        ...state,
        billing_country: action.payload,
      };
    case ACTION_SET_PAYER_EMAIL:
      return {
        ...state,
        payerEmail: action.payload,
      };
    case ACTION_SET_RECEIVE_STATUS:
      return {
        ...state,
        receiveStatus: action.payload,
      };
    case ACTION_SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case ACTION_SET_RECEIVE_ID:
      return {
        ...state,
        receiveId: action.payload,
      };
    case ACTION_RESET_FILTERS:
      return resetFilters();
    default:
      return state;
  }
};

export default ordersReducer;
