import { FC, useCallback, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { WIDGET_CONSOLE_TABLE_ACCESS } from "services/roleService/widgetConsole";
import moment from "moment";
import { useWidgetFiltersState } from "providers/WidgetFilter";

import Popover from "components/Popover";
import { Icon, TableActionButton } from "components/base";
import { WidgetConsoleColumnFieldProps } from "./WidgetConsole.props";
import Tooltip from "components/Tooltip";
import Button from "components/Button";
import { copyToClipboard } from "utils/helpers";

const WidgetConsoleColumnField: FC<WidgetConsoleColumnFieldProps> = ({
  flow,
  updateCryptoStatus,
  getCrypto,
  setChosenFireblock,
  page,
  setIsOpen,
  cryptoItem,
  role,
  isLast,
}): JSX.Element => {
  const location = useLocation();
  const {
    core,
    code,
    symbol,
    markup_rate,
    is_suspended,
    is_sell_supported,
    negative_markup,
  } = useWidgetFiltersState();

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [suspendStatus, setSuspendStatus] = useState(cryptoItem.is_suspended);
  const [sellStatus, setSellStatus] = useState(cryptoItem.is_sell_supported);
  const [isLoading, setIsLoading] = useState(false);

  const updateStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await updateCryptoStatus({
        id: cryptoItem.id as string,
        isSuspended: suspendStatus,
        is_sell_supported: sellStatus,
        address_regex: cryptoItem.address_regex,
        symbol: cryptoItem.symbol,
        testnet_address_regex: cryptoItem.testnet_address_regex,
        code: cryptoItem.code,
        core: cryptoItem.core,
        name: cryptoItem.name,
        native_asset: cryptoItem.native_asset,
        markup_rate: cryptoItem.markup_rate,
      });

      if (data) {
        if (location.pathname.includes("sell-flow")) {
          getCrypto(
            page,
            core,
            code,
            symbol,
            negative_markup,
            is_sell_supported
          );
        }

        if (location.pathname.includes("buy-flow")) {
          getCrypto(page, core, code, symbol, markup_rate, is_suspended);
        }
      }

      return setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  }, [
    code,
    core,
    cryptoItem,
    getCrypto,
    is_suspended,
    markup_rate,
    page,
    sellStatus,
    suspendStatus,
    symbol,
    updateCryptoStatus,
    negative_markup,
    is_sell_supported,
    location.pathname,
  ]);

  const onActionClick = () => {
    const fireblock = {
      id: cryptoItem.id,
      address_regex: cryptoItem.address_regex,
      testnet_address_regex: cryptoItem.testnet_address_regex,
      symbol: cryptoItem.symbol,
      code: cryptoItem.code,
      name: cryptoItem.name,
      type: cryptoItem.core,
      nativeAsset: cryptoItem.native_asset,
      is_suspended: cryptoItem.is_suspended,
      is_sell_supported: cryptoItem.is_sell_supported,
      markup_rate: cryptoItem?.markup_rate,
      negative_markup: cryptoItem?.negative_markup,
    };

    setChosenFireblock(fireblock);
    setIsOpen(true);
  };

  const renderStatus = useMemo(() => {
    if (flow === "buy" && role) {
      return (
        <>
          {WIDGET_CONSOLE_TABLE_ACCESS.suspend_status[role] && (
            <td className="user-list-td-grey suspend-status">
              <div>
                {cryptoItem?.is_suspended ? (
                  <div className="user-status-td transaction-status-td user-status-blocked">
                    Not Supported
                    <Icon name="order_reject" />
                  </div>
                ) : (
                  <div className="user-status-td transaction-status-td user-status-active">
                    Supported
                    <Icon name="success_check" />
                  </div>
                )}
              </div>
            </td>
          )}
          {WIDGET_CONSOLE_TABLE_ACCESS.status_edit[role] && (
            <td className="td-edit-status">
              <Popover
                isPopoverOpen={isPopoverOpen}
                setIsPopoverOpen={setIsPopoverOpen}
                position={["bottom"]}
                className="filter__popover"
                arrowStyle={{ left: "60%" }}
                contentLocation={(popoverState) => {
                  return {
                    top: popoverState.nudgedTop,
                    left: popoverState.targetRect.left - 150,
                  };
                }}
                component={
                  <Button
                    type="button"
                    className="button--transparent button-tr-status"
                    onClick={() => setIsPopoverOpen(true)}
                  >
                    <Icon name="edit_pencil" />
                  </Button>
                }
              >
                <div className="popover-stats__container">
                  <h1 className="popover-stats__title">Status</h1>
                  <div className="popover-stats__container--radio">
                    <div className="radio__wrapper">
                      <label className="radio__label">
                        <input
                          type="radio"
                          name="radio"
                          onChange={() => setSuspendStatus(true)}
                          checked={suspendStatus}
                        />
                        <span className="radio__checkmark"></span>Not Supported
                      </label>
                    </div>
                    <div className="radio__wrapper">
                      <label className="radio__label">
                        <input
                          type="radio"
                          name="radio"
                          onChange={() => setSuspendStatus(false)}
                          checked={!suspendStatus}
                        />
                        <span className="radio__checkmark"></span>Supported
                      </label>
                    </div>
                  </div>
                  <div className="radio-buttons__wrapper">
                    <Button
                      type="button"
                      className="button-cancel"
                      onClick={() => setIsPopoverOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      className="button-submit"
                      onClick={updateStatus}
                      disabled={
                        cryptoItem.is_suspended === suspendStatus || isLoading
                      }
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </Popover>
            </td>
          )}
          {WIDGET_CONSOLE_TABLE_ACCESS.markup_rate[role] && (
            <td className="user-list-td-grey">{cryptoItem?.markup_rate}</td>
          )}
        </>
      );
    }

    if (flow === "sell" && role) {
      return (
        <>
          {WIDGET_CONSOLE_TABLE_ACCESS.sell_status[role] && (
            <td className="user-list-td-grey suspend-status">
              <div>
                {cryptoItem?.is_sell_supported ? (
                  <div className="user-status-td transaction-status-td user-status-active">
                    Supported
                    <Icon name="success_check" />
                  </div>
                ) : (
                  <div className="user-status-td transaction-status-td user-status-blocked">
                    Not Supported
                    <Icon name="order_reject" />
                  </div>
                )}
              </div>
            </td>
          )}
          {WIDGET_CONSOLE_TABLE_ACCESS.sell_status_edit[role] && (
            <td className="td-edit-status">
              <Popover
                isPopoverOpen={isPopoverOpen}
                setIsPopoverOpen={setIsPopoverOpen}
                position={["bottom"]}
                className="filter__popover"
                arrowStyle={{ left: "60%" }}
                contentLocation={(popoverState) => {
                  return {
                    top: popoverState.nudgedTop,
                    left: popoverState.targetRect.left - 150,
                  };
                }}
                component={
                  <Button
                    type="button"
                    className="button--transparent button-tr-status"
                    onClick={() => setIsPopoverOpen(true)}
                  >
                    <Icon name="edit_pencil" />
                  </Button>
                }
              >
                <div className="popover-stats__container">
                  <h1 className="popover-stats__title">Status</h1>
                  <div className="popover-stats__container--radio">
                    <div className="radio__wrapper">
                      <label className="radio__label">
                        <input
                          type="radio"
                          name="radio"
                          onChange={() => setSellStatus(true)}
                          checked={sellStatus}
                        />
                        <span className="radio__checkmark"></span> Supported
                      </label>
                    </div>
                    <div className="radio__wrapper">
                      <label className="radio__label">
                        <input
                          type="radio"
                          name="radio"
                          onChange={() => setSellStatus(false)}
                          checked={!sellStatus}
                        />
                        <span className="radio__checkmark"></span> Not Supported
                      </label>
                    </div>
                  </div>
                  <div className="radio-buttons__wrapper">
                    <Button
                      type="button"
                      className="button-cancel"
                      onClick={() => setIsPopoverOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      className="button-submit"
                      onClick={updateStatus}
                      disabled={
                        cryptoItem.is_sell_supported === sellStatus || isLoading
                      }
                    >
                      Change
                    </Button>
                  </div>
                </div>
              </Popover>
            </td>
          )}
          {WIDGET_CONSOLE_TABLE_ACCESS.negative_markup[role] && (
            <td className="user-list-td-grey">{cryptoItem?.negative_markup}</td>
          )}
        </>
      );
    }
  }, [
    role,
    flow,
    cryptoItem,
    isLoading,
    isPopoverOpen,
    suspendStatus,
    updateStatus,
    sellStatus,
  ]);

  return role ? (
    <>
      {WIDGET_CONSOLE_TABLE_ACCESS.id[role] && (
        <td className="table-id-link">
          <div>
            <Link
              to={`${location.pathname}/crypto/${cryptoItem?.id}`}
            >{`${cryptoItem?.id.substr(0, 4)}...${cryptoItem?.id.substr(
              cryptoItem?.id.length - 4,
              cryptoItem?.id.length
            )}`}</Link>
          </div>
        </td>
      )}
      {WIDGET_CONSOLE_TABLE_ACCESS.id_clipboard[role] && (
        <td className="td-clipboard">
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(cryptoItem.id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </td>
      )}
      {WIDGET_CONSOLE_TABLE_ACCESS.core[role] && (
        <td className="user-list-td-grey">
          <div>
            {cryptoItem?.core && typeof cryptoItem?.core === "string"
              ? cryptoItem?.core
              : "-"}
          </div>
        </td>
      )}
      {WIDGET_CONSOLE_TABLE_ACCESS.code[role] && (
        <td className="user-list-td-grey">
          <div>{cryptoItem?.code ?? "-"}</div>
        </td>
      )}
      {/* {WIDGET_CONSOLE_TABLE_ACCESS.suspend_status[role] && (
        <td className="user-list-td-grey suspend-status">
          <div>
            {cryptoItem?.is_suspended ? (
              <div className="user-status-td transaction-status-td user-status-blocked">
                Suspended
                <Icon name="order_reject" />
              </div>
            ) : (
              <div className="user-status-td transaction-status-td user-status-active">
                Not Suspended
                <Icon name="success_check" />
              </div>
            )}
          </div>
        </td>
      )}
      {WIDGET_CONSOLE_TABLE_ACCESS.status_edit[role] && (
        <td className="td-edit-status">
          <Popover
            isPopoverOpen={isPopoverOpen}
            setIsPopoverOpen={setIsPopoverOpen}
            position={["bottom"]}
            className="filter__popover"
            arrowStyle={{ left: "60%" }}
            contentLocation={(popoverState) => {
              return {
                top: popoverState.nudgedTop,
                left: popoverState.targetRect.left - 150,
              };
            }}
            component={
              <Button
                type="button"
                className="button--transparent button-tr-status"
                onClick={() => setIsPopoverOpen(true)}
              >
                <Icon name="edit_pencil" />
              </Button>
            }
          >
            <div className="popover-stats__container">
              <h1 className="popover-stats__title">Status</h1>
              <div className="popover-stats__container--radio">
                <div className="radio__wrapper">
                  <label className="radio__label">
                    <input
                      type="radio"
                      name="radio"
                      onChange={() => setSuspendStatus(true)}
                      checked={suspendStatus}
                    />
                    <span className="radio__checkmark"></span> Suspended
                  </label>
                </div>
                <div className="radio__wrapper">
                  <label className="radio__label">
                    <input
                      type="radio"
                      name="radio"
                      onChange={() => setSuspendStatus(false)}
                      checked={!suspendStatus}
                    />
                    <span className="radio__checkmark"></span> Not Suspended
                  </label>
                </div>
              </div>
              <div className="radio-buttons__wrapper">
                <Button
                  type="button"
                  className="button-cancel"
                  onClick={() => setIsPopoverOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="button-submit"
                  onClick={updateStatus}
                  disabled={
                    cryptoItem.is_suspended === suspendStatus || isLoading
                  }
                >
                  Change Status
                </Button>
              </div>
            </div>
          </Popover>
        </td>
      )} */}
      {renderStatus}
      {/* {WIDGET_CONSOLE_TABLE_ACCESS.markup_rate[role] && (
        <td className="user-list-td-grey">{cryptoItem?.markup_rate}</td>
      )} */}
      {WIDGET_CONSOLE_TABLE_ACCESS.name[role] && (
        <td className="user-list-td-grey">{cryptoItem?.name}</td>
      )}
      {WIDGET_CONSOLE_TABLE_ACCESS.symbol[role] && (
        <td className="user-list-td-grey">{cryptoItem?.symbol}</td>
      )}
      {WIDGET_CONSOLE_TABLE_ACCESS.date[role] && (
        <td>
          <div>{cryptoItem?.precision}</div>
        </td>
      )}
      {WIDGET_CONSOLE_TABLE_ACCESS.date[role] && (
        <td>
          <div>
            {moment(cryptoItem?.createdAt).format("DD MMM yyyy, hh:mm")}
          </div>
        </td>
      )}
      <td>
        <TableActionButton
          isLast={isLast}
          buttons={[
            {
              id: cryptoItem.id,
              title: "Edit",
              role: "super_admin",
              onClick: onActionClick,
            },
          ]}
        />
      </td>
    </>
  ) : (
    <></>
  );
};

export default WidgetConsoleColumnField;
