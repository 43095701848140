import { useWidgetFiltersState } from "providers/WidgetFilter";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useReducer,
} from "react";

import { sellFlowInstance } from "utils/api";
import reducer from "./reducers";
import {
  State,
  Dispatch,
  GetCryptoType,
  ActionType,
  DispatchContext,
  GetFireblockType,
  GetFiatType,
} from "./types";

const initialState = {
  isLoading: false,
  dataFetched: false,
  activeTab: "crypto_list",
  crypto: [],
  fireblockList: [],
  fiatList: [],
  cryptoCount: null,
  fiatCount: null,
  fireblockCount: null,
  chosenFireblock: null,
  chosenFiat: null,
  page: 1,
};

const SellFlowStateContext = createContext<State>(initialState);
const SellFlowDispatchContext = createContext<Dispatch | undefined>(undefined);

const SellFlowProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <SellFlowStateContext.Provider value={state}>
      <SellFlowDispatchContext.Provider value={dispatch}>
        {children}
      </SellFlowDispatchContext.Provider>
    </SellFlowStateContext.Provider>
  );
};

export const useSellFlowState = (): State => {
  const context = useContext(SellFlowStateContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useSellFlowStateContext must be used within a SellFlowProvider"
    );
  }

  return context;
};

export const useSellFlowDispatch = (): DispatchContext => {
  const dispatch = useContext(SellFlowDispatchContext);
  const {
    core,
    code,
    symbol,
    negative_markup,
    is_sell_supported,
    fireblock_name,
    fireblock_code,
    fireblock_native_asset,
    fireblock_type,
    fiat_is_sell_supported,
    min_sell_amount,
    max_sell_amount,
    fiat_name,
    fiat_symbol,
  } = useWidgetFiltersState();

  const token = localStorage.getItem("token");

  if (typeof dispatch === "undefined") {
    throw new Error(
      "useClientDispatchContext must be used within a ClientProvider"
    );
  }

  const getCrypto: GetCryptoType = useCallback(
    async (
      page = 1,
      core,
      code,
      symbol,
      negative_markup,
      is_sell_supported
    ) => {
      dispatch({ type: ActionType.SET_DATA_FETCHED, payload: false });
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      const params: {
        page: number;
        limit: number;
        core?: string;
        code?: string;
        symbol?: string;
        negative_markup?: number;
        is_sell_supported?: boolean | undefined;
      } = {
        page,
        limit: 10,
      };

      if (core) params.core = core;
      if (code) params.code = code;
      if (symbol) params.symbol = symbol;
      if (typeof negative_markup !== "undefined")
        params.negative_markup = negative_markup;
      if (typeof is_sell_supported !== "undefined")
        params.is_sell_supported = is_sell_supported;

      try {
        const { data } = await sellFlowInstance.get(`/crypto/list`, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        dispatch({ type: ActionType.SET_CRYPTO, payload: data?.rows });
        dispatch({ type: ActionType.SET_CRYPTO_COUNT, payload: data?.count });
        setTimeout(() => {
          dispatch({ type: ActionType.SET_LOADING, payload: false });
          dispatch({ type: ActionType.SET_DATA_FETCHED, payload: true });
        }, 500);

        return;
      } catch (error) {
        console.log(error);
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    },
    [dispatch, token]
  );

  const setActiveTab = (value: string) =>
    dispatch({ type: ActionType.SET_ACTIVE_TAB, payload: value });

  const resetCount = (countType: string) => {
    switch (countType) {
      case "crypto_list":
        dispatch({ type: ActionType.SET_CRYPTO_COUNT, payload: 0 });

        return;
      case "fireblock_list":
        dispatch({ type: ActionType.SET_FIREBLOCK_COUNT, payload: 0 });

        return;
      case "fiat_list":
        dispatch({ type: ActionType.SET_FIAT_COUNT, payload: 0 });

        return;
      default:
        return null;
    }
  };

  const updateCryptoStatus = async ({
    id,
    isSuspended,
    is_sell_supported,
    address_regex,
    symbol,
    testnet_address_regex,
    code,
    core,
    name,
    native_asset,
    markup_rate,
    negative_markup,
  }: {
    id: string;
    isSuspended: boolean;
    is_sell_supported?: boolean;
    address_regex: string;
    symbol: string;
    testnet_address_regex: string;
    code: string;
    core: string;
    name: string;
    native_asset: string;
    markup_rate: number;
    negative_markup?: number;
  }) => {
    try {
      const { data } = await sellFlowInstance.patch(
        "/crypto/update_status",
        {
          id,
          is_suspended: isSuspended,
          is_sell_supported: is_sell_supported,
          address_regex,
          symbol,
          testnet_address_regex,
          code,
          core: core ?? "",
          name,
          native_asset,
          markup_rate,
          negative_markup,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const setLoading = (isLoading: boolean) =>
    dispatch({ type: ActionType.SET_LOADING, payload: isLoading });

  const getFireblockList: GetFireblockType = useCallback(
    async (
      page = 1,
      fireblock_name,
      fireblock_code,
      fireblock_type,
      fireblock_native_asset
    ) => {
      dispatch({ type: ActionType.SET_DATA_FETCHED, payload: false });
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      const params: {
        page: number;
        limit: number;
        name?: string;
        code?: string;
        type?: string;
        nativeAsset?: string;
      } = {
        page,
        limit: 10,
      };

      if (fireblock_name) params.name = fireblock_name;
      if (fireblock_code) params.code = fireblock_code;
      if (fireblock_type) params.type = fireblock_type;
      if (fireblock_native_asset) params.nativeAsset = fireblock_native_asset;

      try {
        const { data } = await sellFlowInstance.get(`/fireblock/list`, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        dispatch({ type: ActionType.SET_FIREBLOCK, payload: data?.rows });
        dispatch({
          type: ActionType.SET_FIREBLOCK_COUNT,
          payload: data?.count,
        });
        setTimeout(() => {
          dispatch({ type: ActionType.SET_DATA_FETCHED, payload: true });
          dispatch({ type: ActionType.SET_LOADING, payload: false });
        }, 500);

        return;
      } catch (error) {
        console.log(error);
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    },
    [dispatch, token]
  );

  const getFiatList: GetFiatType = useCallback(
    async (
      page = 1,
      fiat_name,
      fiat_symbol,
      fiat_is_sell_supported,
      min_sell_amount,
      max_sell_amount
    ) => {
      dispatch({ type: ActionType.SET_DATA_FETCHED, payload: false });
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      const params: {
        page: number;
        limit: number;
        name?: string;
        symbol?: string;
        is_sell_supported?: boolean;
        min_sell_amount?: number;
        max_sell_amount?: number;
      } = {
        page,
        limit: 10,
      };

      if (fiat_name) params.name = fiat_name;
      if (fiat_symbol) params.symbol = fiat_symbol;
      if (typeof fiat_is_sell_supported !== "undefined")
        params.is_sell_supported = fiat_is_sell_supported;
      if (min_sell_amount) params.min_sell_amount = min_sell_amount;
      if (max_sell_amount) params.max_sell_amount = max_sell_amount;

      try {
        const { data } = await sellFlowInstance.get(`/fiat/list`, {
          params,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        dispatch({ type: ActionType.SET_FIAT, payload: data?.rows });
        dispatch({ type: ActionType.SET_FIAT_COUNT, payload: data?.count });
        setTimeout(() => {
          dispatch({ type: ActionType.SET_DATA_FETCHED, payload: true });
          dispatch({ type: ActionType.SET_LOADING, payload: false });
        }, 500);

        return;
      } catch (error) {
        console.log(error);
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    },
    [dispatch, token]
  );

  const paginate = ({ page, type }: { page: number; type: string }) => {
    switch (type) {
      case "crypto":
        getCrypto(page, core, code, symbol, negative_markup, is_sell_supported);
        dispatch({ type: ActionType.SET_PAGE, payload: page });

        return;
      case "fireblock":
        getFireblockList(
          page,
          fireblock_name,
          fireblock_code,
          fireblock_type,
          fireblock_native_asset
        );
        dispatch({ type: ActionType.SET_PAGE, payload: page });

        return;
      case "fiat":
        getFiatList(
          page,
          fiat_name,
          fiat_symbol,
          fiat_is_sell_supported,
          min_sell_amount,
          max_sell_amount
        );

        dispatch({ type: ActionType.SET_PAGE, payload: page });

        return;
      default:
        return;
    }
  };

  const setChosenFireblock = (fireblock) =>
    dispatch({ type: ActionType.SET_CHOSEN_FIREBLOCK, payload: fireblock });

  const updateFiatStatus = async ({
    id,
    isSuspended,
    max_amount,
    max_sell_amount,
    max_buy_amount,
    min_amount,
    min_sell_amount,
    min_buy_amount,
    is_sell_supported,
  }: {
    id: string;
    isSuspended: boolean;
    max_amount: number;
    max_sell_amount?: number;
    max_buy_amount: number;
    min_amount: number;
    min_sell_amount?: number;
    min_buy_amount: number;
    is_sell_supported?: boolean;
  }) => {
    try {
      const { data } = await sellFlowInstance.patch(
        "/fiat/update_status",
        {
          id,
          is_suspended: isSuspended,
          max_amount,
          max_sell_amount,
          max_buy_amount,
          min_amount,
          min_sell_amount,
          min_buy_amount,
          is_sell_supported,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const setChosenFiat = (fiat) => {
    dispatch({ type: ActionType.SET_CHOSEN_FIAT, payload: fiat });
  };

  const setPage = (page: number) =>
    dispatch({ type: ActionType.SET_PAGE, payload: page });

  return {
    getCrypto,
    setActiveTab,
    resetCount,
    setLoading,
    paginate,
    updateCryptoStatus,
    setChosenFireblock,
    getFireblockList,
    getFiatList,
    updateFiatStatus,
    setChosenFiat,
    setPage,
  };
};

export default SellFlowProvider;
