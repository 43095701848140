import { useEffect, useState } from "react";
import { filterWidgetConsoleByRole } from "services/roleService/widgetConsole";
import { useWidgetFiltersState } from "providers/WidgetFilter";
import { useSellFlowDispatch, useSellFlowState } from "providers/SellFlow";

import { Table } from "components/base";
import TableSkeleton from "components/TableSkeleton";
import NotFound from "pages/NotFound";
import WidgetConsoleColumn from "pages/WidgetConsole/WidgetConsoleColumn";
import AddCryptoModal from "pages/WidgetConsole/AddCryptoModal";
import useRole from "hooks/useRole";
import Pagination from "components/Pagination";

const CryptoList = (): JSX.Element => {
  const role = useRole();

  const { crypto, isLoading, cryptoCount, page, chosenFireblock, dataFetched } =
    useSellFlowState();

  const {
    getCrypto,
    setLoading,
    paginate,
    updateCryptoStatus,
    setChosenFireblock,
  } = useSellFlowDispatch();

  const {
    core,
    code,
    symbol,
    negative_markup,
    is_sell_supported,
    trigger_filter,
  } = useWidgetFiltersState();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (trigger_filter) {
      getCrypto(page, core, code, symbol, negative_markup, is_sell_supported);
    }

    // eslint-disable-next-line
  }, [trigger_filter]);

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        paginate({ page: page + 1, type: "crypto" });

        return;
      case "prev":
        paginate({ page: page - 1, type: "crypto" });

        return;

      default:
        return;
    }
  };

  return (
    <div className="table--row">
      {(crypto.length === 0 && dataFetched && !isLoading && <NotFound />) || (
        <div className="user-list-body">
          <div>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                headers={[
                  {
                    title: "ID",
                    val: "id",
                    w: "fit-content",
                    className: "th-email",
                  },
                  {
                    title: "",
                    val: "id_clipboard",
                    w: "",
                    className: "th-crypto-id",
                  },
                  {
                    title: "Core",
                    val: "core",
                    w: "fit-content",
                  },
                  {
                    title: "Code",
                    val: "code",
                    w: "fit-content",
                  },
                  {
                    title: "Status",
                    val: "suspend_status",
                    w: "fit-content",
                    className: "suspend-status",
                  },
                  {
                    title: "",
                    val: "status_edit",
                    w: "fit-content",
                  },
                  {
                    title: "Negative markup rate",
                    val: "markup_rate",
                    w: "fit-content",
                  },
                  {
                    title: "Name",
                    val: "name",
                    w: "fit-content",
                  },
                  {
                    title: "Symbol",
                    val: "symbol",
                    w: "fit-content",
                  },
                  {
                    title: "Date",
                    val: "date",
                    w: "fit-content",
                  },
                  { title: "Action", val: "action", w: "fit-content" },
                ].filter(
                  (item) => role && filterWidgetConsoleByRole(item, role)
                )}
              >
                <WidgetConsoleColumn
                  flow="sell"
                  updateCryptoStatus={updateCryptoStatus}
                  getCrypto={getCrypto}
                  setChosenFireblock={setChosenFireblock}
                  page={page}
                  setIsOpen={setIsOpen}
                  crypto={crypto}
                />
              </Table>
            )}
          </div>
          <Pagination
            page={page}
            count={cryptoCount as number}
            visibleCount={10}
            setLoading={setLoading}
            isLoading={isLoading}
            clearSearch={() => null}
            handlePagination={handlePagination}
          />
        </div>
      )}
      {chosenFireblock && (
        <AddCryptoModal
          updateCryptoStatus={updateCryptoStatus}
          setChosenFireblock={setChosenFireblock}
          getCrypto={getCrypto}
          page={page}
          isEdit={true}
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
          chosenFireblock={chosenFireblock}
        />
      )}
    </div>
  );
};

export default CryptoList;
