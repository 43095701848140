import FilterItemSkeleton from "components/FiltersSkeleton/FilterItemSkeleton";

FilterItemSkeleton;

const FilterListSkeleton = (): JSX.Element => {
  return (
    <div className="filter-list-skeleton__wrapper">
      {Array.from({ length: 7 }).map((_, idx) => (
        <div key={idx}>
          <FilterItemSkeleton />
        </div>
      ))}
    </div>
  );
};

export default FilterListSkeleton;
