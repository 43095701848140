import { useBalanceState } from "providers/Balance";
import { exportPayout } from "services/balance";

import { Icon } from "components/base";
import Button from "components/Button";

const BalanceExportButtons = ({
  partnerId,
}: {
  partnerId: string;
}): JSX.Element => {
  const { walletAddress, payoutToExport, payInToExport, activeBalance } =
    useBalanceState();

  const exportAll = () =>
    exportPayout({
      partnerId,
      exportAll: true,
      address: walletAddress as string,
      type:
        activeBalance &&
        activeBalance?.charAt(0).toUpperCase() + activeBalance?.slice(1),
    });

  const exportSelected = () =>
    exportPayout({
      partnerId,
      ...(activeBalance === "payout" && {
        payoutOrders: payoutToExport as string[],
      }),
      ...(activeBalance === "payin" && {
        payInOrders: payoutToExport as string[],
      }),
      address: walletAddress as string,
    });

  return (
    <div className="button-export__wrapper">
      <Button
        type="button"
        className="button--transparent"
        onClick={() => location.reload()}
      >
        <Icon name="rotate" />
      </Button>

      <Button
        type="button"
        className="button--transparent button--export"
        disabled={!payoutToExport?.length && !payInToExport?.length}
        onClick={exportSelected}
      >
        <Icon name="export_button" />
        Export Selected
      </Button>
      <Button
        type="button"
        className="button--transparent button--export"
        onClick={exportAll}
      >
        <Icon name="export_button" />
        Export
      </Button>
    </div>
  );
};

export default BalanceExportButtons;
