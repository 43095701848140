import { useEffect, useState } from "react";
import { useWidgetConsoleDispatch } from "providers/WidgetConsole";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment";

import { PageWrapper } from "components/base";
import CryptoDetailsSkeleton from "../DetailsSkeleton";
import { FireblockProps } from "../WidgetConsole.props";

const FireblockDetails = (): JSX.Element => {
  const [fireblockDetails, setFireblockDetails] =
    useState<FireblockProps | null>(null);

  const location = useLocation();
  const params: { id: string } = useParams();
  const { getFireblockById } = useWidgetConsoleDispatch();

  useEffect(() => {
    if (params?.id) {
      (async () => {
        try {
          const data = await getFireblockById(
            params?.id,
            location.pathname.includes("sell-flow") ? "sell-flow" : "buy-flow"
          );

          if (data) {
            setFireblockDetails(data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <PageWrapper title={"Transaction Details"}>
      <div className="content d-flex flex-column flex-column-fluid">
        {!fireblockDetails ? (
          <CryptoDetailsSkeleton />
        ) : (
          <div className="container" id="kt_content_container">
            <div className="d-flex flex-column">
              <div className="flex-column">
                <div className="card card-order">
                  <div className="order--header">
                    <div
                      style={{ display: "flex" }}
                      className="order--header-button"
                    >
                      <span className="card-details-header-content-body-name">
                        Fireblock Details
                      </span>
                    </div>
                  </div>
                  <div className="table-content">
                    <table className="main-table">
                      <tbody>
                        <tr className="order-details-tr">
                          <td className="td-definition">ID</td>
                          <td className="td-answer">{fireblockDetails?.id}</td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Code</td>
                          <td className="td-answer">
                            {fireblockDetails?.code ?? "-"}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Native Asset</td>
                          <td className="td-answer">
                            {fireblockDetails?.nativeAsset ?? "-"}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Decimals</td>
                          <td className="td-answer">
                            {fireblockDetails?.decimals ?? "-"}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Contract Address</td>
                          <td className="td-answer">
                            {fireblockDetails?.contractAddress?.length
                              ? fireblockDetails?.contractAddress
                              : "-"}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Issuer Address</td>
                          <td className="td-answer">
                            {fireblockDetails?.issuerAddress ?? "-"}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Name</td>
                          <td className="td-answer">
                            {fireblockDetails?.name ?? "-"}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Type</td>
                          <td className="td-answer">
                            {fireblockDetails?.type ?? "-"}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Created At</td>
                          <td className="td-answer">
                            {moment(fireblockDetails?.createdAt).format(
                              "DD MMM yyyy, hh:mm"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default FireblockDetails;
