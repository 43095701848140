import { useHistory, useLocation } from "react-router-dom";
import { useClientDispatch } from "providers/ClientFilter";
import { useOrderDispatch } from "providers/OrderFilter";
import { usePartnerDispatch } from "providers/PartnerFilter";
import { set_search } from "providers/ClientFilter/clientsActions";
import { set_search as set_partner_search } from "providers/PartnerFilter/partnerAction";
import { set_orderSearch } from "providers/OrderFilter/ordersAction";
import { useEffect, useState } from "react";
import classNames from "classnames";
import RoleProvider, { ROLES } from "providers/RoleProvider";
import { useAuthStateContext } from "providers/Auth";

import NavItem from "./NavItem";
import useRole from "hooks/useRole";
import Popover from "components/Popover";
import { Icon } from "components/base";

const Navbar = (): JSX.Element => {
  const location = useLocation();
  const role = useRole();

  const clientDispatch = useClientDispatch();
  const orderDispatch = useOrderDispatch();
  const partnerDispatch = usePartnerDispatch();

  const clearSearches = () => {
    clientDispatch(set_search(""));
    orderDispatch(set_orderSearch(""));
    partnerDispatch(set_partner_search(""));
  };

  const { user } = useAuthStateContext();

  const adminUsersRole = user?.role || "";
  const routes = [
    {
      to: "/dashboard",
      title: "Overview",
    },
    {
      to: "/users",
      title: "Customers",
      roles: [ROLES.SUPER_ADMIN, ROLES.AML, ROLES.MARKETING],
    },
    {
      to: "/orders/receive-flow",
      title: "Payment links",
      roles: [ROLES.SUPER_ADMIN, ROLES.MARKETING, ROLES.AML, ROLES.FINANCE],
    },
    {
      to: "/orders",
      title: "Transaction",
      dropdown: true,
      roles: [ROLES.SUPER_ADMIN, ROLES.MARKETING, ROLES.AML, ROLES.FINANCE],

      subItems: [
        {
          to: "/orders/buy-flow",
          title: "Transactions Buy List",
          name: "Buy Flow",
        },
        {
          to: "/orders/sell-flow",
          title: "Transactions Sell List",
          name: "Sell Flow",
        },
      ],
    },
    {
      to: "/balance",
      title: "Balance",
      partner: true,
    },

    {
      to: "/partners",
      title: "Partners",
      super_admin: true,
      roles: [ROLES.SUPER_ADMIN, ROLES.FINANCE, ROLES.MARKETING],
    },
    {
      to: "/reports",
      title: "Reports",
      super_admin: true,
      roles: [ROLES.SUPER_ADMIN, ROLES.FINANCE, ROLES.MARKETING, ROLES.AML],
      subItems: [
        {
          roles: [ROLES.SUPER_ADMIN, ROLES.AML, ROLES.MARKETING],
          to: "/reports",
          title: "Active users",
          name: "Active users",
        },
        {
          roles: [ROLES.SUPER_ADMIN, ROLES.FINANCE],
          to: "/reports/financial",
          title: "Financial reports",
          name: "Financial reports",
        },
      ].filter((el) => el.roles.includes(adminUsersRole)),
    },
    {
      to: "/admin-users",
      title: "Team",
      super_admin: true,
      roles: [ROLES.SUPER_ADMIN],
    },
    {
      to: "/widget-console",
      title: "Widget Console",
      super_admin: true,
      roles: [ROLES.SUPER_ADMIN],
      dropdown: true,
      subItems: [
        {
          to: "/widget-console/buy-flow",
          title: "Buy Flow",
          name: "Buy Flow",
        },
        {
          to: "/widget-console/sell-flow",
          title: "Sell Flow",
          name: "Sell Flow",
        },
        {
          to: "/widget-console/receive-flow",
          title: "Receive Flow",
          name: "Receive Flow",
        },
      ],
    },
  ];

  return (
    <div className="navbar__wrapper">
      <ul className="navbar__list">
        {routes.map((route, index) => {
          if (route?.super_admin && role !== "super_admin") return;
          if (route?.partner && role !== "partner") return;

          return route?.subItems?.length ? (
            route.roles ? (
              <RoleProvider roles={route.roles} key={index}>
                <NavDropdown route={route} />
              </RoleProvider>
            ) : (
              <NavDropdown route={route} key={index} />
            )
          ) : route.roles ? (
            <RoleProvider roles={route.roles} key={index}>
              <NavItem
                to={route.to}
                activePath={location.pathname}
                onClick={clearSearches}
              >
                {route.title}
              </NavItem>
            </RoleProvider>
          ) : (
            <NavItem
              key={index}
              to={route.to}
              activePath={location.pathname}
              onClick={clearSearches}
            >
              {route.title}
            </NavItem>
          );
        })}
      </ul>
    </div>
  );
};

const NavDropdown = ({ route }) => {
  const history = useHistory();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const routeButton = (
    <li
      className={classNames("navbar__item flex", {
        active: isActive,
        clicked: isPopoverOpen,
      })}
    >
      {route.title} <Icon name="chevron_down" />
    </li>
  );

  useEffect(() => {
    const subRoutes = route.subItems.map((item) => item.to);

    if (subRoutes.includes(history.location.pathname)) {
      setIsActive(true);
    }
  }, [route, history]);

  const checkoutRoute = (routeName: string) => {
    return (
      history.location.pathname.startsWith(routeName) &&
      history.location.pathname.endsWith(routeName)
    );
  };

  return (
    <Popover
      isPopoverOpen={isPopoverOpen}
      setIsPopoverOpen={setIsPopoverOpen}
      position={["bottom"]}
      className="profile__popover"
      component={routeButton}
    >
      {/* {USER_MENU_PANEL_ACCESS.header[role] && ( */}
      <>
        <div className="nav-dropdown__container">
          <ul className="nav-dropdown__list">
            {route?.subItems?.map((el: { to: string; name: string }) => {
              return (
                <li
                  key={el?.to}
                  className={`nav-dropdown__list-item ${
                    checkoutRoute(el?.to) && "active"
                  }`}
                >
                  <button
                    onClick={() => {
                      history.push(el.to);
                      setIsPopoverOpen(false);
                    }}
                  >
                    {el.name}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </>
      {/* )} */}
    </Popover>
  );
};

export default Navbar;
