import { useState } from "react";

import PayoutHeader from "./Header";
import PayoutForm from "./PayoutForm";

const PayoutFunds = ({
  setRenderFlow,
}: {
  setRenderFlow: (value: string) => void;
}): JSX.Element => {
  const [submitPayout, setSubmitPayout] = useState(false);
  const [enableSubmit, setEnableSubmit] = useState(false);

  return (
    <div className="payout__container">
      <PayoutHeader
        setSubmitPayout={setSubmitPayout}
        enableSubmit={enableSubmit}
      />
      <PayoutForm
        submitPayout={submitPayout}
        setEnableSubmit={setEnableSubmit}
        setRenderFlow={setRenderFlow}
      />
    </div>
  );
};

export default PayoutFunds;
