import { ActionTypes, State, Action, Order } from "./types";

const handleUpdatedStatus = (
  state,
  data: { tr_status: string; order_id: string }
) => {
  const orderIndex = state.orders.findIndex(
    (order) => order.id === data.order_id
  );

  state.orders[orderIndex].tr_status = data.tr_status;

  return {
    ...state,
    orders: state.orders,
    updatedStatus: { tr_status: data.tr_status, order_id: data.order_id },
  };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: action.data as boolean,
      };
    }

    case ActionTypes.SET_ORDERS: {
      const { rows, count } = action.data as { rows: Order[]; count: number };

      return {
        ...state,
        isLoading: false,
        orders: rows as Order[],
        ordersCount: count,
      };
    }

    case ActionTypes.SET_ORDERS_STATISTICS: {
      const { status_count, total_amount, total_commission } = action.data as {
        status_count: { status: string; count: string }[];
        total_amount: { currency: string; total: number }[];
        total_commission: { asset: string; total: number }[];
      };

      return {
        ...state,
        totalAmount: total_amount,
        totalCommission: total_commission,
        totalOrders: status_count,
      };
    }

    case ActionTypes.DELETE_ORDER:
      return {
        ...state,
        orders: state.orders.filter((order) => order.id !== action.data),
        ordersCount: state.ordersCount - 1,
      };
    case ActionTypes.SET_ORDER_DETAILS:
      return { ...state, orderDetails: action.data as Order };
    case ActionTypes.SET_TR_STATUSES:
      return { ...state, transactionStatuses: action.data as string[] };
    case ActionTypes.SET_UPDATES_STATUS:
      return handleUpdatedStatus(
        state,
        action.data as { tr_status: string; order_id: string }
      );
    case ActionTypes.RESET_ORDERS_COUNT:
      return {
        ...state,
        ordersCount: 0,
      };
    default:
      return state;
  }
};

export default reducer;
