import { FC, useState, useEffect, useRef } from "react";
import { useBitcoinCore } from "providers/BitcoinCore";

import EditOnIcon from "../../components/base/Icon/icons/edit-off.svg";
import { Icon } from "components/base";

const EditFeeRate: FC = () => {
  const { bitcoinState, updateConfig } = useBitcoinCore();
  const inputFeeRate = useRef<HTMLInputElement | null>(null);
  const [reade, setReade] = useState<boolean>(false);
  const [fee, setFee] = useState<string>(
    bitcoinState?.settings?.find((i) => i.name === "fee_rate_btc")?.value || "0"
  );

  const [width, setWidth] = useState(2);

  function onEdit() {
    if (fee === "") {
      setFee("0");
    }

    setReade((e) => !e);
    if (reade) updateConfig(undefined, fee);
  }

  useEffect(() => {
    inputFeeRate.current?.focus();
  }, [reade]);

  useEffect(() => {
    const bitcoinValue = bitcoinState?.settings?.find(
      (i) => i.name === "fee_rate_btc"
    )?.value;

    setWidth(
      !bitcoinValue?.length || bitcoinValue?.length <= 1
        ? 2
        : bitcoinValue?.length + 1
    );
    setFee(bitcoinValue || "0");
  }, [bitcoinState]);

  const onValidateFee = (numFee) => {
    const validated = numFee.match(/^\d*\d*$/);

    if (!numFee?.length || numFee?.length <= 1) {
      setWidth(2);
    } else {
      setWidth(numFee.length + 1);
    }

    if (validated) {
      setFee(numFee);
    }
  };

  return (
    <div className={`card-fee-rate ${reade && "disabled"}`}>
      <input
        maxLength={3}
        ref={inputFeeRate}
        type="tel"
        value={fee}
        onChange={(e) => onValidateFee(e.target.value)}
        readOnly={reade ? false : true}
        style={{ width: `${width}ch` }}
      />
      <button onClick={() => onEdit()}>
        {reade ? (
          <img src={EditOnIcon} alt="" width={16} height={16} />
        ) : (
          <Icon name="edit_pencil" />
        )}
      </button>
    </div>
  );
};

export default EditFeeRate;
