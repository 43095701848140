import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import Tooltip from "@atlaskit/tooltip";
import { Chart } from "chart.js";

import info from "../../../assets/img/info.svg";
import generateChart from "./generateChart";
import { IChart } from "./types";

import "./styles.scss";

export default function DonutChart({
  progress,
  title,
  infoContenet,
  total,
  data,
  colors,
}: IChart): JSX.Element {
  const canvasRef = useRef<null | HTMLCanvasElement>(null);
  const chartRef = useRef<null | Chart<"doughnut", number[], string>>(null);

  useEffect(() => {
    generateChart({
      element: canvasRef.current,
      data: data,
      colors,
      chartRef,
    });
  }, [canvasRef, data, colors]);

  return (
    <div className="donut-chart-container">
      <div className="donut-chart-header">
        <div className="donut-chart-descripton">
          <p className="donut-chart-descripton-title">{title}</p>
          <Tooltip content={infoContenet}>
            <span>
              <img src={info} alt="image" />
            </span>
          </Tooltip>
          <p
            className={classNames("donut-chart-descripton-progress d-none", {
              green: progress >= 0,
              red: progress < 0,
            })}
          >
            {String(progress) !== "0" ? `${Number(progress)}%` : `N/A`}
          </p>
        </div>
        <p className="donut-chart-count">
          {total ||
            data.reduce((sum, current) => sum + Number(current.count), 0)}
        </p>
      </div>

      <canvas ref={canvasRef} />
    </div>
  );
}
