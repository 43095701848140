import { FC, useEffect, useState, useMemo } from "react";

import { TabsProps } from "./Tabs.props";
import TabItem from "./TabItem";

const Tabs: FC<TabsProps> = ({
  handleFirstLevelFilter,
  filterValues,
  filters,
}): JSX.Element => {
  const [activeFilter, setActiveFilter] = useState<{
    label: string;
    value: string;
  }>({
    label: "All",
    value: "ALL",
  });

  useEffect(() => {
    if (!filterValues?.length) return;

    setActiveFilter(filterValues[0]);
    document.addEventListener("reset", () => {
      setActiveFilter({ label: "All", value: "ALL" });
    });

    return () => {
      document.removeEventListener("reset", () => {
        setActiveFilter({ label: "All", value: "ALL" });
      });
    };
  }, [filterValues]);

  const isActive = useMemo(
    () => (item) => {
      if (filters?.length) {
        return filters?.includes(item.value) && "active";
      }

      return (
        activeFilter.label === item.label &&
        activeFilter.value === item.value &&
        "active"
      );
    },
    [filters, activeFilter]
  );

  return (
    <div className="tab-list__wrapper">
      <ul className="tab-list--list">
        {filterValues?.map((filterItem, index) => (
          <TabItem
            className={isActive(filterItem) as string}
            key={index}
            onClick={() => {
              setActiveFilter(filterItem);
              handleFirstLevelFilter(filterItem?.value);
            }}
          >
            {filterItem?.label}
          </TabItem>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
