import React, { useMemo, FC } from "react";

interface LinkProps {
  bank: string;
  trxId: string;
  id: string;
}

const TransactionLink: FC<LinkProps> = ({ bank, trxId, id }) => {
  const link = useMemo(() => {
    switch (bank) {
      case "trust":
        return (
          <a
            target="_blank"
            href={`https://myst.securetrading.net/transactions/singletransaction?transactionreference=${trxId}`}
            rel="noreferrer"
          >
            {id}
          </a>
        );
      case "xpate":
        return (
          <a
            target="_blank"
            href={` https://sandbox.sg.xpate.com/paynet-ui/order/id/${trxId}`}
            rel="noreferrer"
          >
            {id}
          </a>
        );
      case "transactPro":
        return <span>{id}</span>;

      default:
        return <span>{id}</span>;
    }
  }, [bank, id, trxId]);

  return <>{link}</>;
};

export default TransactionLink;
