import { FC } from "react";
import { FIREBLOCK_TABLE_ACCESS } from "services/roleService/widgetConsole/fireblock";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
// import {
//   useWidgetConsoleDispatch,
//   useWidgetConsoleState,
// } from "providers/WidgetConsole";

import { Icon } from "components/base";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { copyToClipboard } from "utils/helpers";
import { FireblockColumnFieldProps } from "./WidgetConsole.props";

const FireblockColumnField: FC<FireblockColumnFieldProps> = ({
  flow,
  setChosenFireblock,
  chosenFireblock,
  fireblock,
  role,
}): JSX.Element => {
  const location = useLocation();

  return role ? (
    <>
      {flow === "buy" && (
        <td>
          <div>
            <input
              className={`form-check-input user-list-checkbox`}
              type="checkbox"
              checked={chosenFireblock?.id === fireblock.id}
              onChange={(e) => {
                if (e.target.checked) {
                  setChosenFireblock(fireblock);

                  return;
                }

                return setChosenFireblock(null);
              }}
            />
          </div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.id[role] && (
        <td className="table-id-link">
          <div>
            <Link
              to={`${location.pathname}/fireblock/${fireblock?.id}`}
            >{`${fireblock?.id.substr(0, 4)}...${fireblock?.id.substr(
              fireblock?.id.length - 4,
              fireblock?.id.length
            )}`}</Link>
          </div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.id_clipboard[role] && (
        <td className="td-clipboard">
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(fireblock.id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.code[role] && (
        <td className="user-list-td-grey">
          <div>{fireblock?.code ?? "-"}</div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.native_asset[role] && (
        <td className="user-list-td-grey">
          <div>{fireblock?.nativeAsset ?? "-"}</div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.decimals[role] && (
        <td className="user-list-td-grey">
          <div>{fireblock?.decimals ?? "-"}</div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.contract_address[role] && (
        <td className="user-list-td-grey">
          <div>
            {fireblock?.contractAddress?.length
              ? fireblock?.contractAddress
              : "-"}
          </div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.issuer_address[role] && (
        <td className="user-list-td-grey">
          <div>{fireblock?.issuerAddress ?? "-"}</div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.name[role] && (
        <td className="user-list-td-grey">
          <div>{fireblock?.name ?? "-"}</div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.type[role] && (
        <td className="user-list-td-grey">
          <div>{fireblock?.type ?? "-"}</div>
        </td>
      )}
      {FIREBLOCK_TABLE_ACCESS.date[role] && (
        <td>
          <div>{moment(fireblock?.createdAt).format("DD MMM yyyy, hh:mm")}</div>
        </td>
      )}
    </>
  ) : (
    <></>
  );
};

export default FireblockColumnField;
