import { FC, useMemo, useRef, RefObject } from "react";
import { useWebhookDispatch, useWebhookState } from "providers/Webhooks";
import { setSelectedEvents } from "providers/Webhooks/actions";

import { Icon } from "components/base";
import "./select-event.scss";
import EventList from "./EventList";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { EventTypes, SelectEventProps } from "./SelectEvent.props";

interface EventsRef {
  events: string[];
}

const SelectEvent: FC<SelectEventProps> = ({
  setShowSelectEvent,
}): JSX.Element => {
  const { selectedEvents, eventTypes } = useWebhookState();
  const webhookDispatch = useWebhookDispatch();
  const prevEvents: RefObject<EventsRef> = useRef({
    events: [],
  });

  if (prevEvents?.current && !prevEvents?.current?.events?.length) {
    prevEvents.current.events = selectedEvents;
  }

  const selectAll = () => {
    const ids = Object.keys(eventTypes as EventTypes).reduce(
      (acc: Array<string>, curr) => {
        const mappedIds = (eventTypes as EventTypes)[curr]?.items.map(
          (item) => item.id
        );

        acc = [...acc, ...mappedIds];

        return acc;
      },
      []
    );

    webhookDispatch(setSelectedEvents(ids));
  };

  const deselectAll = () => webhookDispatch(setSelectedEvents([]));

  const selectAllChecked = useMemo(() => {
    const eventsLength = Object.keys(eventTypes as EventTypes).reduce(
      (acc, curr) => {
        acc += (eventTypes as EventTypes)[curr].items.length;

        return acc;
      },
      0
    );

    if (selectedEvents?.length && selectedEvents.length === eventsLength) {
      return true;
    }

    return false;
  }, [selectedEvents, eventTypes]);

  const cancelEvents = () => {
    setShowSelectEvent(false);
    webhookDispatch(setSelectedEvents(prevEvents?.current?.events as string[]));
  };

  return (
    <div className="select-event__container">
      {/* <button
        onClick={() => {
          setShowSelectEvent(false);
        }}
        className="select-event__back-button"
      >
        <Icon name="back" />
        Back
      </button> */}
      <h1 className="select-event__header">Select event to send</h1>
      <div className="select-event__select-all">
        <input
          onChange={(e) => {
            if (e.target.checked) {
              selectAll();

              return;
            }

            return deselectAll();
          }}
          checked={selectAllChecked}
          className={`form-check-input user-list-checkbox`}
          type="checkbox"
        />
        <label htmlFor="event-checkbox">Select all events</label>
        <Tooltip
          content="Your endpoint will listen for all event types that currently exist, but it will not automatically listen for new event types that we may add in the future"
          constant={true}
        >
          {(setShowTooltip) => (
            <Button
              type="button"
              className="clipboard-button"
              onMouseOver={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              onClick={() => null}
            >
              <Icon name="info" />
            </Button>
          )}
        </Tooltip>
      </div>
      <EventList />
      <div className="select-event__button-group">
        <button onClick={() => setShowSelectEvent(false)}>Add endpoint</button>
        <button onClick={cancelEvents}>Cancel</button>
      </div>
    </div>
  );
};

export default SelectEvent;
