import { Dispatch } from "providers/Auth/types";
import { Partner } from "providers/Partners/types";
import React, { ReducerWithoutAction, useContext, useReducer } from "react";

import partnerReducer from "./partnerReducer";

export type FilterType = {
  pagePartner: number;
  partnerSearch: string;
  partnerFilter: string;
  dateFromPartner: null;
  dateToPartner: null;
  sortingPartner: string;
  orderingPartner: undefined | string | ((prev: string) => string);
  visiblePartnersCount: string;
  settingsToggle: undefined | string[];
  exportedPartner: undefined | string[];
  markupFrom: string;
  markupTo: string;
  commissionFrom: string;
  commissionTo: string;
  checkedIDPartner: { checked: boolean; partners: string[] };
  selectedPartner: string;
  arrayCheckingPartner: string[] | [] | undefined;
  partnerID?: string;
  filteringPartner?: boolean;
  modalOpen?: boolean;
  partnerOption?: Partner;
  checkedPages: number[] | [];
};

const initilaPartnerFilters = {
  pagePartner: 1,
  partnerSearch: "",
  partnerFilter: "",
  dateFromPartner: null,
  dateToPartner: null,
  markupFrom: "",
  markupTo: "",
  commissionFrom: "",
  commissionTo: "",
  sortingPartner: "",
  orderingPartner: undefined,
  visiblePartnersCount: "10",
  settingsToggle: [],
  exportedPartner: [],
  checkedIDPartner: { checked: false, partners: [] },
  selectedPartner: "",
  arrayCheckingPartner: [],
  partnerID: undefined,
  filteringPartner: undefined,
  modalOpen: false,
  partnerOption: undefined,
  checkedPages: [],
};

const PartnerFiltersContext = React.createContext(initilaPartnerFilters);
const PartnerFiltersDispatcher = React.createContext<Dispatch>(() => "");

const PartnerFiltersProvider: React.FC = ({ children }) => {
  const [filters, filtersDispatcher] = useReducer<
    ReducerWithoutAction<FilterType>
  >(partnerReducer as never, initilaPartnerFilters);

  return (
    <PartnerFiltersContext.Provider value={filters as never}>
      <PartnerFiltersDispatcher.Provider value={filtersDispatcher}>
        {children}
      </PartnerFiltersDispatcher.Provider>
    </PartnerFiltersContext.Provider>
  );
};

export const usePartnerFilters: () => FilterType = () => {
  const partnerFilters = useContext(PartnerFiltersContext);

  return partnerFilters;
};

export const usePartnerDispatch = (): Dispatch => {
  const filterDispatch = useContext(PartnerFiltersDispatcher);

  return filterDispatch;
};

export default PartnerFiltersProvider;
