import { toast } from "react-toastify";
import { createDecipheriv } from "browser-crypto";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import moment from "moment";
import CryptoJS from "crypto-js";
import { AxiosError } from "axios";
import { KeyInPie, PieDate, ResultItem } from "ApexCharts/chartTypes/pieType";

type ErrorType = {
  response: {
    data: {
      message: string;
    };
  };
};

export const getTotals = (data: []): PieDate => {
  const result: KeyInPie = {};

  data.forEach((item: ResultItem) => {
    result[item.asset] = item.total || 0;
  });

  return result;
};

export const showError = (err: unknown): void => {
  let message = "Something went wrong";

  if ((err as AxiosError)?.response?.data?.message) {
    message = (err as ErrorType).response.data.message;
  } else if ((err as AxiosError)?.message) {
    message = (err as AxiosError).message;
  }

  toast.error(message);
};

type DecoderEncrypt = {
  result: {
    active: boolean;
    urls: {
      url: string;
    }[];
  };
  statistics: {
    view?: number;
  };
};

export const decoder = (encrypt: string): DecoderEncrypt => {
  const decrypted = CryptoJS.AES.decrypt(
    encrypt,
    process.env.REACT_APP_CRYPTO_KEY as string
  );

  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

export const socketDecoder = (encrypt: string): unknown => {
  const algorithm = "aes-256-cbc";
  const initVector = process.env.REACT_APP_CRYPTO_IV;
  const securityKey = process.env.REACT_APP_CRYPTO_KEY;

  const decipher = createDecipheriv(algorithm, securityKey, initVector);
  let decrypted = decipher.update(encrypt, "hex", "utf-8");

  decrypted += decipher.final();

  return JSON.parse(decrypted.toString("utf8"));
};

export const getWallBlockchair = (asset: string, address: string): string => {
  const network = asset === "BTC" ? "bitcoin" : "ethereum";

  return `https://blockchair.com/ru/${network}/address/${address}`;
};

export const getTransferUrl = (txId: string, asset: string): string => {
  if (txId.includes("Internal transfer")) {
    return "";
  }

  let network = asset === "BTC" ? "bitcoin" : "ethereum";

  if (asset === "BSV") {
    network = "bitcoin-sv";
  }

  if (asset === "TRC") {
    return `https://tronscan.org/#/transaction/${txId}`;
  } else {
    return `https://blockchair.com/ru/${network}/transaction/${txId}`;
  }
};

export const exportToXlsx = (apiData: unknown[], fileName: string): void => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

  const fileExtension = ".xlsx";
  const ws = XLSX.utils.json_to_sheet(apiData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });

  const date = moment().format("DD-MM-YYY_hh:mm");

  FileSaver.saveAs(data, `${fileName}-${date}${fileExtension}`);
};

export const parseJSON = (str: string): string => {
  try {
    const parsed = JSON.parse(str);

    return parsed;
  } catch (_) {
    return str;
  }
};

export const isValidURL = (str: string): boolean => {
  const pattern = new RegExp(
    "^(https:\\/\\/)" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  ); // fragment locator

  return !!pattern.test(str);
};

export const copyToClipboard = (text: string): void => {
  navigator.clipboard.writeText(text);
};

export const decodeQuery = (queryString: string): { [key: string]: string } =>
  JSON.parse(
    '{"' + queryString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',

    function (key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );

export const eventBus = {
  on(event: string, callback: (id: string) => void): void {
    document.addEventListener(event, (e) => callback((<CustomEvent>e).detail));
  },
  dispatch(event: string, data: string): void {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event: string, callback: () => void): void {
    document.removeEventListener(event, callback);
  },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const validateNumberInput = ({
  value,
  allowNegativeNumbers,
}: {
  value: string;
  allowNegativeNumbers: boolean;
}) => {
  const matchedString = allowNegativeNumbers
    ? value.match(/^[-?1-9]\d*\.?\d*/) ||
      value.match(/^-?0\.\d*/) ||
      value.match(/^0/)
    : value.match(/^[1-9]\d*\.?\d*/) ||
      value.match(/^0\.\d*/) ||
      value.match(/^0/);

  if (value === "") {
    return { value: value, error: null };
  }

  if (matchedString && matchedString[0] === value) {
    return { value: value, error: null };
  } else {
    return { value: null, error: "Must be number" };
  }
};
