interface header {
  content?: React.ReactNode;
  empty?: boolean;
  title?: string;
  w?: number | string;
  val?: string;
  light?: string;
  position?: string;
  // handleSorting: (e) => void;
}
[];
export const ORDER_TABLE_ACCESS: {
  [key: string]: { [key: string]: boolean };
} = {
  checkbox: { super_admin: true, partner: true },
  payment_method: { super_admin: true, partner: true },
  id: { super_admin: true, partner: true },
  id_clipboard: { super_admin: true, partner: true },
  amount: { super_admin: true, partner: true },
  api: { super_admin: true, partner: false },
  spot_price: { super_admin: true, partner: true },
  commission: { super_admin: true, partner: true },
  partner_markup: { super_admin: true, partner: false },
  price: { super_admin: true, partner: false },
  cost_of_service: { super_admin: true, partner: false },
  swaps_profit: { super_admin: true, partner: false },
  network_fee: { super_admin: true, partner: false },
  asset_quantity: { super_admin: true, partner: true },
  asset: { super_admin: true, partner: true },
  status: { super_admin: true, partner: false },
  status_edit: { super_admin: true, partner: false },
  bank_status: { super_admin: true, partner: true },
  partner_name: { super_admin: true, partner: false },
  createdAt: { super_admin: true, partner: true },
  email: { super_admin: true, partner: true },
  email_clipboard: { super_admin: true, partner: true },
  withdrawal: { super_admin: true, partner: true },
  action: { super_admin: true, partner: true },
  fiat: { super_admin: true, partner: true },
  risk_level: { super_admin: true, partner: false },
  bank_type: { super_admin: true, partner: false },
  network: { super_admin: true, partner: false },
  client_id: { super_admin: true, partner: false },
  client_email: { super_admin: true, partner: false },
  partner_id: { super_admin: true, partner: false },
  merchant_id: { super_admin: true, partner: false },
  transaction_id: { super_admin: true, partner: false },
  transaction_status: { super_admin: true, partner: false },
  tx_id: { super_admin: true, partner: false },
  profit: { super_admin: true, partner: false },
  account_iban: { super_admin: true, partner: false },
  failure_reason: { super_admin: true, partner: false },
  final_asset_qty: { super_admin: true, partner: false },
  address: { super_admin: true, partner: false },
  billing_country: { super_admin: true, partner: false },
  tracking_id: {
    super_admin: true,
    partner: false,
  },
};

export const filterHeadersByRole = (header: header, role: string): boolean => {
  if (header.val && ORDER_TABLE_ACCESS[header.val][role]) return true;

  return false;
};

export const POSITION_PANEL_ACCESS: {
  [key: string]: { [key: string]: boolean };
} = {
  cols: { super_admin: true, partner: false },
};
