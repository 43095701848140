import { FC } from "react";

import { FilterSkeletonProps } from "./FilterSkeleton.props";
import FilterItemSkeleton from "./FilterItemSkeleton";

const FilterSkeleton: FC<FilterSkeletonProps> = ({ qty }): JSX.Element => {
  return (
    <div className="filter-skeleton__wrapper">
      {Array.from({ length: qty }).map((_, index) => (
        <div className="filter-item__skeleton" key={index}>
          <FilterItemSkeleton />
        </div>
      ))}
    </div>
  );
};

export default FilterSkeleton;
