import { useCallback, useEffect, useState } from "react";
import { useWebhookDispatch, useWebhookState } from "providers/Webhooks";
import {
  setEventTypes,
  setSelectedEvents,
  setWebhookEndpoint,
} from "providers/Webhooks/actions";
import {
  usePartnerDispatchContext,
  usePartnerStateContext,
} from "providers/Partners";
import { useParams } from "react-router-dom";

// import Header from "../WebhookCreateHeader";
import "../webhook-create.scss";
import SelectEvent from "../SelectEvent";
import { instance } from "utils/api";
import { EventTypes } from "../SelectEvent/SelectEvent.props";
import { ProfileEventType } from "pages/Profile/type";
import WebhookCreateFormSkeleton from "../WebhookCreateFormSkeleton";
import PartnerWebhookCreateForm from "./PartnerWebhookCreateForm";

const PartnerWebhookCreate = (): JSX.Element => {
  const { id }: { id: string } = useParams();
  const { partnerDetails: partner } = usePartnerStateContext();
  const { getPartnerById } = usePartnerDispatchContext();
  const [loading, setLoading] = useState(false);
  const [showSelectEvent, setShowSelectEvent] = useState(false);
  const { eventTypes } = useWebhookState();
  const webhookDispatch = useWebhookDispatch();

  const activateSelectEvent = () => setShowSelectEvent(true);

  const getProfileData = useCallback(
    async (partnerDetails) => {
      if (!partnerDetails) return;

      if (partnerDetails?.events?.length && partnerDetails?.callback_success) {
        const reducedEvents = partnerDetails?.events.reduce(
          (acc: string[], curr: ProfileEventType) => {
            acc = [...acc, curr.events_partner.event_id];

            return acc;
          },
          []
        );

        await webhookDispatch(
          setWebhookEndpoint(partnerDetails.callback_success as string)
        );
        setTimeout(
          () => webhookDispatch(setSelectedEvents(reducedEvents)),
          100
        );
      }
    },
    [webhookDispatch]
  );

  const fetchEventTypes = useCallback(async () => {
    try {
      const { data } = await instance.get("/events/types");

      if (data?.data) {
        const events = data.data.reduce((acc, curr) => {
          const key = curr.slug.split("_")[0];
          const ifExists = acc[key];

          if (ifExists) {
            acc = {
              ...acc,
              [key]: {
                count: acc[key].count + 1,
                items: [...acc[key].items, curr],
              },
            };
          } else {
            acc = {
              ...acc,
              [key]: {
                count: 1,
                items: [curr],
              },
            };
          }

          return acc;
        }, {});

        webhookDispatch(setEventTypes(events));
      }

      return;
    } catch (error) {
      console.log(error);
    }
  }, [webhookDispatch]);

  useEffect(() => {
    if (!partner && id) {
      (async () => {
        setLoading(true);
        await fetchEventTypes();
        const partnerData = await getPartnerById(id);
        await getProfileData(partnerData);
        setLoading(false);
      })();
    }
  }, [fetchEventTypes, getProfileData, getPartnerById, id, partner]);

  return (
    <>
      {/* <Header /> */}
      {showSelectEvent ? (
        <SelectEvent
          eventTypes={eventTypes as EventTypes}
          setShowSelectEvent={setShowSelectEvent}
        />
      ) : (
        <div className="webhook-create__container">
          <h1 className="webhook-create__heading">Listen to Swaps events</h1>
          <p className="webhook-create__text">
            Set up your webhook endpoint to receive live events from Swaps
          </p>
          {loading ? (
            <WebhookCreateFormSkeleton />
          ) : (
            <PartnerWebhookCreateForm
              activateSelectEvent={activateSelectEvent}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PartnerWebhookCreate;
