import { FC, useMemo } from "react";

import { EventBadgeProps } from "./EventDetails.props";

const EventBadge: FC<EventBadgeProps> = ({ status }): JSX.Element => {
  const renderBadge = useMemo(() => {
    switch (status) {
      case true:
        return <div className="event-details__badge success">Success</div>;
      case false:
        return <div className="event-details__badge failed">Failed</div>;
    }
  }, [status]);

  return <>{renderBadge}</>;
};

export default EventBadge;
