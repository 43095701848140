import {
  forwardRef,
  ForwardRefRenderFunction,
  MutableRefObject,
  // MutableRefObject,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { CreatePartner, Partner } from "providers/Partners/types";
import {
  usePartnerDispatchContext,
  usePartnerStateContext,
} from "providers/Partners";
import { usePartnerDispatch, usePartnerFilters } from "providers/PartnerFilter";
import { set_modalOpen } from "providers/PartnerFilter/partnerAction";

import { Icon, Modal } from "components/base";
import { isValidURL } from "utils/helpers";
import { ReactComponent as CloseModalLogo } from "../base/Icon/icons/close-modal.svg";

const fields = {
  name: {
    name: "name",
    value: "dadsadsadasd",
    type: "text",
    label: "Partner name",
    validation: { required: true },
    message: "Name is required",
  },
  contact_name: {
    name: "contactName",
    type: "text",
    label: "Contact name",
    validation: { required: true },
    message: "Contact name is required",
  },
  phone: {
    name: "phone",
    type: "text",
    label: "Contact phone",
    validation: { required: true },
    message: "Contact phone is required",
  },
  email: {
    name: "email",
    type: "text",
    label: "Contact email",
    validation: {
      required: true,
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    message: "Please enter valid email",
  },
  support_email: {
    name: "support_email",
    type: "text",
    label: "Support email",
    validation: {
      required: true,
      pattern:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    },
    message: "Please enter valid email",
  },
  page_url: {
    name: "urls",
    type: "text",
    label: "Widget placement page URLs",
    validation: { required: false },
    message: "Please enter urls",
  },
  partner_commission: {
    name: "partnerCommission",
    type: "text",
    label: "Processing fee",
    min: 0,
    validation: {
      pattern: {
        value: /^\d+(\.\d+)?$/,
        message: "Value must be greater or equal 0",
      },
      required: true,
    },
    message: "Partner commission is required",
  },
  partner_markup: {
    name: "partnerMarkup",
    type: "text",
    label: "Commission",
    min: 0,
    validation: {
      pattern: {
        value: /^\d+(\.\d+)?$/,
        message: "Value must be greater or equal 0",
      },
      required: true,
    },
    message: "Partner markup is required",
  },
  partner_sell_commission: {
    name: "sellCommission",
    type: "text",
    label: "Sell process fee",
    min: 0,
    validation: {
      pattern: {
        value: /^\d+(\.\d+)?$/,
        message: "Value must be greater or equal 0",
      },
      required: true,
    },
    message: "Sell process fee is required",
  },
  partner_sell_markup: {
    name: "sellMarkup",
    type: "text",
    label: "Sell commission",
    min: 0,
    validation: {
      pattern: {
        value: /^\d+(\.\d+)?$/,
        message: "Value must be greater or equal 0",
      },
      required: true,
    },
    message: "Sell commission is required",
  },
  sepaCommission: {
    name: "sepaCommission",
    type: "text",
    label: "Sepa commission",
    min: 0,
    validation: {
      pattern: {
        value: /^\d+(\.\d+)?$/,
        message: "Value must be greater or equal 0",
      },
      required: true,
    },
    message: "Sepa commission is required",
  },
  comment: {
    name: "comment",
    type: "string",
    label: "Comments",
    validation: { required: true },
    message: "Comments markup is required",
  },
  callback_success: {
    name: "callback_success",
    type: "string",
    label: "callback_success",
    validation: { required: false },
    message: "callback_success markup is required",
  },
  callback_error: {
    name: "callback_error",
    type: "string",
    label: "callback_error",
    validation: { required: false },
    message: "callback_error markup is required",
  },
  active_switch: {
    name: "active",
    type: "boolean",
    label: "Active",
    validation: { required: false },
  },
};

export type PartnersModalRefType = {
  open: (type: "edit" | "create") => void;
};

const PartnersModal: ForwardRefRenderFunction<PartnersModalRefType> = (
  _,
  ref
) => {
  // context
  const { createPartner, editPartner } = usePartnerDispatchContext();
  const { partnerDetails } = usePartnerStateContext();
  const { getPartnerById } = usePartnerDispatchContext();
  const partnerDispatch = usePartnerDispatch();

  // states
  const [isOpen, setIsOpen] = useState(false);
  const [isEditing, setEditing] = useState(false);
  const [urls, setUrls] = useState<string[]>([]);
  const [urlValue, setUrlValue] = useState("");
  // form
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const {
    modalOpen,
    partnerOption,
    partnerSearch,
    pagePartner,
    visiblePartnersCount,
    sortingPartner,
    orderingPartner,
    dateFromPartner,
    dateToPartner,
    partnerFilter,
    markupFrom,
    markupTo,
    commissionFrom,
    commissionTo,
  } = usePartnerFilters();

  useEffect(() => {
    if (modalOpen) {
      setIsOpen(true);
      (ref as MutableRefObject<PartnersModalRefType | null>)?.current?.open(
        "edit"
      );
    }
  }, [modalOpen, ref]);

  const closeModal = () => {
    setIsOpen(false);
    setUrls([]);
    setUrlValue("");
    partnerDispatch(set_modalOpen(false));
    reset({
      name: "",
      contactName: "",
      phone: "",
      email: "",
      partnerCommission: "",
      partnerMarkup: "",
      callback_success: null,
      callback_error: null,
      comment: "",
      active: false,
    });
  };

  useImperativeHandle(ref, () => ({
    open: (type) => {
      setIsOpen(true);

      if (type === "edit") {
        setEditing(true);
        partnerOption?.id && getPartnerById(partnerOption?.id);
      } else {
        setEditing(false);
      }
    },
  }));

  useEffect(() => {
    let property: keyof typeof fields;
    const existingUrls = partnerDetails?.urls?.map((i) => i.url) || [];

    setUrls(existingUrls);

    for (property in fields) {
      if (fields[property].name !== "urls") {
        setValue(
          fields[property].name,
          partnerDetails
            ? partnerDetails?.[fields[property]?.name as keyof Partner]
            : ""
        );
      }
    }
  }, [partnerDetails, setValue]);

  const handleKeyboardEvent = (
    e:
      | React.KeyboardEvent<HTMLInputElement>
      | React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (e.key === " " || e.key === "Enter") {
      const value = urlValue.trim();

      if (isValidURL(value)) {
        setUrls((prev) => {
          const values = [...prev];
          values.push(value);

          return values;
        });
        setUrlValue("");
        setValue("urls", "");
      }
    }
  };

  const handleChangeUrl = (e) => {
    setUrlValue(e.target.value);
  };

  const onSubmit = async (values: CreatePartner) => {
    const data = {
      ...values,
      active:
        typeof values.active === "string" || !values.active ? false : true,
      urls: values.urls
        ? [values.urls as unknown as string, ...urls]
        : [...urls],
    };

    if (!data.callback_success) data.callback_success = null;
    if (!data.callback_error) data.callback_error = null;

    if (isEditing && partnerDetails) {
      editPartner(
        pagePartner,
        partnerDetails?.id,
        data,
        partnerSearch,
        markupFrom,
        markupTo,
        commissionFrom,
        commissionTo,
        dateFromPartner,
        dateToPartner,
        partnerFilter,
        visiblePartnersCount,
        orderingPartner
      ).then(closeModal);
    } else {
      createPartner(
        data,
        pagePartner,
        partnerSearch,
        markupFrom,
        markupTo,
        commissionFrom,
        commissionTo,
        dateFromPartner,
        dateToPartner,
        partnerFilter,
        visiblePartnersCount,
        sortingPartner,
        orderingPartner
      ).then(closeModal);
    }
  };

  useEffect(() => {
    const body = document.querySelector("body");

    if (body) {
      if (isOpen) {
        body.style.overflow = "hidden";
      } else {
        body.style.overflow = "unset";
      }
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} closeModal={closeModal}>
      <div className="modal-content-container">
        <div className="modal-header" id="kt_modal_add_customer_header">
          <div>
            <h2 className="fw-bolder modal-header-title-m">
              {modalOpen ? "Edit" : "Add"} partner
            </h2>
          </div>
          <div className="color">
            <span className="svg-icon svg-icon-1" onClick={closeModal}>
              <CloseModalLogo />
            </span>
          </div>
        </div>
        <div className="modal-body py-10 px-lg-17">
          <div className="modal-body-content">
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["name"]?.label}</label>
                <input
                  type={fields["name"]?.type}
                  {...register(fields["name"].name, fields["name"].validation)}
                  className="form-control form-control-solid"
                  name={fields["name"]?.name}
                />
                {errors[fields["name"].name] && (
                  <p className="input-error-message">
                    {fields["name"].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["contact_name"]?.label}</label>
                <input
                  type={fields["contact_name"]?.type}
                  {...register(
                    fields["contact_name"].name,
                    fields["contact_name"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["contact_name"]?.name}
                />
                {errors[fields["contact_name"].name] && (
                  <p className="input-error-message">
                    {fields["contact_name"].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["phone"]?.label}</label>
                <input
                  type={fields["phone"]?.type}
                  {...register(
                    fields["phone"].name,
                    fields["phone"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["phone"]?.name}
                />
                {errors[fields["phone"].name] && (
                  <p className="input-error-message">
                    {fields["phone"].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["email"]?.label}</label>
                <input
                  type={fields["email"]?.type}
                  {...register(
                    fields["email"].name,
                    fields["email"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["email"]?.name}
                />
                {errors[fields["email"].name] && (
                  <p className="input-error-message">
                    {fields["email"].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["support_email"]?.label}</label>
                <input
                  type={fields["support_email"]?.type}
                  {...register(
                    fields["support_email"].name,
                    fields["support_email"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["support_email"]?.name}
                />
                {errors[fields["support_email"].name] && (
                  <p className="input-error-message">
                    {fields["support_email"].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["partner_commission"]?.label}</label>
                <input
                  type={fields["partner_commission"]?.type}
                  {...register(
                    fields["partner_commission"].name,
                    fields["partner_commission"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["partner_commission"]?.name}
                />
                {errors[fields["partner_commission"].name] && (
                  <p className="input-error-message">
                    {errors[fields["partner_commission"].name].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["partner_markup"]?.label}</label>
                <input
                  type={fields["partner_markup"]?.type}
                  {...register(
                    fields["partner_markup"].name,
                    fields["partner_markup"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["partner_markup"]?.name}
                />
                {errors[fields["partner_markup"].name] && (
                  <p className="input-error-message">
                    {errors[fields["partner_markup"].name].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["partner_sell_commission"]?.label}</label>
                <input
                  type={fields["partner_sell_commission"]?.type}
                  {...register(
                    fields["partner_sell_commission"].name,
                    fields["partner_sell_commission"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["partner_sell_commission"]?.name}
                />
                {errors[fields["partner_sell_commission"].name] && (
                  <p className="input-error-message">
                    {errors[fields["partner_sell_commission"].name].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["partner_sell_markup"]?.label}</label>
                <input
                  pattern="\d*"
                  type={fields["partner_sell_markup"]?.type}
                  {...register(
                    fields["partner_sell_markup"].name,
                    fields["partner_sell_markup"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["partner_sell_markup"]?.name}
                />
                {errors[fields["partner_sell_markup"].name] && (
                  <p className="input-error-message">
                    {errors[fields["partner_sell_markup"].name].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["sepaCommission"]?.label}</label>
                <input
                  pattern="\d*"
                  type={fields["sepaCommission"]?.type}
                  {...register(
                    fields["sepaCommission"].name,
                    fields["sepaCommission"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["sepaCommission"]?.name}
                />
                {errors[fields["sepaCommission"].name] && (
                  <p className="input-error-message">
                    {errors[fields["sepaCommission"].name].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["page_url"]?.label}</label>
                <input
                  type={fields["page_url"]?.type}
                  onKeyPress={handleKeyboardEvent}
                  {...register(fields["page_url"].name, {
                    ...fields["page_url"].validation,
                    validate: (value) => value || urls.length > 0,
                  })}
                  onChange={(e) => handleChangeUrl(e)}
                  className="form-control form-control-solid"
                  name={fields["page_url"]?.name}
                />
                <p className="input-info-message">
                  Save the URL by clicking ENTER. URL should start with
                  http://or https://
                </p>
                {errors[fields["page_url"].name] && (
                  <p className="input-error-message">
                    {fields["page_url"].message}
                  </p>
                )}
                <div className="tags-container">
                  {urls.map((url, i) => (
                    <div className="tag-item" key={i}>
                      {url}{" "}
                      <button
                        onClick={() =>
                          setUrls((prev) => prev.filter((item) => item !== url))
                        }
                      >
                        <Icon name="close" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            {/* <div className="modal-content-row">
              <div className="modal-content-col">
                <label
                  className={`fs-6 fw-bold mb-2 ${
                    fields["callback_success"]?.validation?.required
                      ? "required"
                      : ""
                  }`}
                >
                  Success webhook
                </label>
                <input
                  type={fields["callback_success"]?.type}
                  {...register(
                    fields["callback_success"].name,
                    fields["callback_success"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["callback_success"]?.name}
                />
                {errors[fields["callback_success"].name] && (
                  <p className="input-error-message">
                    {fields["callback_success"].message}
                  </p>
                )}
              </div>
            </div> */}
            {/* <div className="modal-content-row">
              <div className="modal-content-col">
                <label
                  className={`fs-6 fw-bold mb-2 ${
                    fields["callback_error"]?.validation?.required
                      ? "required"
                      : ""
                  }`}
                >
                  Fail webhook
                </label>
                <input
                  type={fields["callback_error"]?.type}
                  {...register(
                    fields["callback_error"].name,
                    fields["callback_error"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["callback_error"]?.name}
                />
                {errors[fields["callback_error"].name] && (
                  <p className="input-error-message">
                    {fields["callback_error"].message}
                  </p>
                )}
              </div>
            </div> */}
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>{fields["comment"]?.label}</label>
                <textarea
                  {...register(
                    fields["comment"].name,
                    fields["comment"].validation
                  )}
                  className="form-control form-control-solid"
                  name={fields["comment"]?.name}
                  cols={30}
                  rows={6}
                ></textarea>
                {errors[fields["comment"].name] && (
                  <p className="input-error-message">
                    {fields["comment"].message}
                  </p>
                )}
              </div>
            </div>
            <div className="modal-content-row partner-modal-switch">
              <div className="modal-content-col">
                <label className="form-check form-switch form-check-custom form-check-solid">
                  <input
                    type="checkbox"
                    className="form-check-input react-switch"
                    style={{
                      verticalAlign: "middle",
                      display: "inline-block",
                    }}
                    {...register(
                      fields["active_switch"].name,
                      fields["active_switch"].validation
                    )}
                    // checked={partnerDetails?.active}
                  />
                </label>
                Active
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer flex-center">
          <button
            type="button"
            className="button button-secondary button-cancel"
            onClick={closeModal}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button button-primary button-add"
            onClick={handleSubmit(onSubmit)}
          >
            <span className="indicator-label">
              {!modalOpen ? "Add partner" : "Edit partner"}
            </span>
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default forwardRef(PartnersModal);
