import { useAuthStateContext } from "providers/Auth";
import { useEffect, useState } from "react";

import FilterList from "components/Filter/FilterList";
import { instance } from "utils/api";
import "./styles.scss";
import PresetsDatePicker from "components/PresetsDatePicker";
import Line from "components/Charts/Line";
import { IRevenue } from "./types";

export default function FinancialTransaction(): JSX.Element {
  const { user } = useAuthStateContext();
  const isPartner = user?.role === "partner";
  const [totalRevenue, setTotalRevenue] = useState<IRevenue>();

  useEffect(() => {
    const initiateData = async () => {
      const [
        {
          data: {
            data: { rows: partners },
          },
        },
        {
          data: { data: countries },
        },
      ] = await Promise.all([
        !isPartner
          ? instance.get("/partners?page=1&limit=100")
          : Promise.resolve({ data: { data: { rows: [] } } }),
        instance.get("/countries"),
      ]);

      const newFilterTree = [...filterTree];

      const partnerObject = newFilterTree.find(
        (el) => el.value === "partner"
      ) || { selectOptions: [] };

      const billingCountryObject = newFilterTree.find(
        (el) => el.value === "billing_country"
      ) || { selectOptions: [] };

      partnerObject.selectOptions = partners?.map((el) => ({
        id: el.id,
        value: el.name,
        label: el.name,
      }));
      billingCountryObject.selectOptions = countries?.map((el) => ({
        id: el.id,
        value: el.country,
        label: el.country,
      }));

      setFilterTree(newFilterTree);
    };

    initiateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [filterTreeState, setFilterTreeState] = useState({
    transaction_type: "Buy",
    payment_method: "",
    partner_name: "",
    billing_address: "",
    date: {
      interval: "",
      endDate: "",
      startDate: "",
    },
  });

  useEffect(() => {
    const getData = async () => {
      const revenueObj = {};

      if (filterTreeState.date.startDate && filterTreeState.date.endDate) {
        const {
          data: { growthResults, totalRevenue },
        } = await instance.get(`/admin/summary/revenue-by-fiat`, {
          params: {
            partner_id: filterTreeState.partner_name
              ? filterTreeState.partner_name
              : undefined,
            pay_method: filterTreeState.payment_method
              ? filterTreeState.payment_method
              : undefined,
            country_id: filterTreeState.billing_address
              ? filterTreeState.billing_address
              : undefined,
            fromDate: filterTreeState.date.startDate,
            toDate: filterTreeState.date.endDate,
            interval: filterTreeState.date.interval,
          },
        });

        totalRevenue.forEach((el) => {
          const foundItem = growthResults.find(
            (item) => item.currency === el.currency
          );

          el.currentTotal = foundItem.currentTotal;
          el.growthPercentage = foundItem.growthPercentage;

          if (revenueObj[el.currency]) {
            revenueObj[el.currency].push(el);
          } else {
            revenueObj[el.currency] = [el];
          }
        });

        setTotalRevenue(revenueObj);
      }
    };

    getData();
  }, [
    filterTreeState.date.startDate,
    filterTreeState.date.endDate,
    filterTreeState.date.interval,
    filterTreeState.partner_name,
    filterTreeState.payment_method,
    filterTreeState.billing_address,

    isPartner,
    user?.id,
  ]);

  const filterTreeStatic = [
    // {
    //   label: "Transaction Type",
    //   value: "transaction_type",
    //   type: "select",

    //   initialValue: "",
    //   selectOptions: [
    //     {
    //       label: "Buy ",
    //       value: "buy",
    //     },
    //     {
    //       label: "Sell",
    //       value: "sell",
    //     },
    //     {
    //       label: "Receive",
    //       value: "receive",
    //     },
    //   ],
    //   setValue: (e: React.ChangeEvent<HTMLInputElement>) =>
    //     setFilterTreeState({
    //       ...filterTreeState,
    //       transaction_type: e.target.value,
    //     }),
    //   clearSearch: () =>
    //     setFilterTreeState({ ...filterTreeState, transaction_type: "" }),
    // },

    {
      label: "Payment Method",
      value: "payment_method",
      type: "select",
      initialValue: "",
      selectOptions: [
        {
          label: "Stripe ",
          value: "stripe",
        },
        {
          label: "OpenPayd",
          value: "openPayd",
        },
        {
          label: "Astropay",
          value: "astropay",
        },
        {
          label: "Zimpler",
          value: "zimpler",
        },
        {
          label: "Volt",
          value: "volt",
        },
        {
          label: "Skrill",
          value: "skrill",
        },
        {
          label: "Paybis",
          value: "paybis",
        },
        {
          label: "Neteller",
          value: "neteller",
        },
        {
          label: "Rapid Transfer",
          value: "rapid",
        },
        {
          label: "Clear Junction",
          value: "clearJunction",
        },
      ],
      setValue: (e: React.ChangeEvent<HTMLInputElement>) =>
        setFilterTreeState({
          ...filterTreeState,
          payment_method: e.target.value,
        }),
      clearSearch: () =>
        setFilterTreeState({ ...filterTreeState, payment_method: "" }),
    },
    {
      label: "Billing Country",
      value: "billing_country",
      type: "select",
      initialValue: "",
      selectOptions: [],
      setValue: (e: React.ChangeEvent<HTMLInputElement>) => {
        const foundCountry = filterTree
          .find((el) => el.label === "Billing Country")
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.selectOptions.find((el) => el.label === e.target.value) as any;

        setFilterTreeState({
          ...filterTreeState,
          billing_address: foundCountry?.id,
        });
      },

      clearSearch: () =>
        setFilterTreeState({ ...filterTreeState, billing_address: "" }),
    },
  ];

  if (!isPartner) {
    filterTreeStatic.push({
      label: "Partner name",
      value: "partner",
      type: "select",
      initialValue: "",
      selectOptions: [],
      setValue: (e: React.ChangeEvent<HTMLInputElement>) => {
        const foundPartner = filterTree
          .find((el) => el.label === "Partner name")
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ?.selectOptions.find((el) => el.label === e.target.value) as any;

        setFilterTreeState({
          ...filterTreeState,
          partner_name: foundPartner.id,
        });
      },
      clearSearch: () =>
        setFilterTreeState({ ...filterTreeState, partner_name: "" }),
    });
  }

  const [filterTree, setFilterTree] = useState(filterTreeStatic);

  const handleDateChange = (value) =>
    setFilterTreeState({
      ...filterTreeState,
      date: {
        interval: value.interval,
        startDate: value.date.startDate,
        endDate: value.date.endDate,
      },
    });

  const lineArr = Object.values(totalRevenue || {}) || [];
  const unit =
    filterTreeState.date.interval === "hour" ||
    filterTreeState.date.interval === "day"
      ? filterTreeState.date.interval
      : "day";

  return (
    <div className="revenue-charts-container">
      <div className="d-flex">
        <div className="top-filter-revenue">
          <PresetsDatePicker
            onDateChange={handleDateChange}
            intervalPicker={true}
          >
            <div className="w-100">
              <FilterList
                secondLevelHandlers={filterTreeStatic.map((el) => el.setValue)}
                secondLevelFilter={filterTree}
                handleApply={() => console.log()}
                enableClearFilters={true}
                hideClearFilters={true}
                clearSearches={filterTreeStatic.map((el) => el.clearSearch)}
                additionalHandlers={[]}
                clearAdditionalSearches={[]}
                clearSelect={() => null}
              />
            </div>
          </PresetsDatePicker>
        </div>
      </div>
      <div className="charts-container">
        {lineArr.map((el, i) => {
          return (
            <div key={i} className="twice-width">
              <Line
                unit={unit}
                title={el[0].currency}
                progress={el[0].growthPercentage}
                infoContenet={el[0].currency}
                count={el[0].currentTotal}
                data={{
                  x: el.map((el) => el.date),
                  y: el.map((el) => el.revenue.toFixed(5)),
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
