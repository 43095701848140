import { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { USER_MENU_PANEL_ACCESS } from "services/roleService/menu/menu";
import { useAuthDispatchContext, useAuthStateContext } from "providers/Auth";
import { onConfirm } from "react-confirm-pro";

import ProfileItem from "./ProfileItem";
import Notification from "./Notification";
import Popover from "../Popover";
import useRole from "hooks/useRole";
import Button from "../Button";

const ProfileContainer = (): JSX.Element => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { signOut } = useAuthDispatchContext();
  const { user } = useAuthStateContext();
  const role = useRole();

  const onClickSignOut = () => {
    onConfirm({
      title: <h3 className="sign-out-title">Sign out</h3>,
      description: (
        <p className="sign-out-description">
          Are you sure you want to sign out?
        </p>
      ),
      btnCancel: "Cancel",
      btnSubmit: "Sign Out",
      onSubmit: () => {
        signOut();
      },
      className: "sign-out-modal",
    });
  };

  return (
    <div className="profile__wrapper">
      <a target="_blank" href="https://help.swaps.app/en" rel="noreferrer">
        <div className="info__wrapper">
          <ProfileItem iconName="info" onClick={() => null} />
          <span className="info__text">Help</span>
        </div>
      </a>
      <Notification />

      <ProfileItem
        iconName="gear"
        onClick={() =>
          history.push({
            pathname: "/setting",
            state: {
              prevPath: location.pathname,
            },
          })
        }
      />
      {role && (
        <Popover
          isPopoverOpen={isPopoverOpen}
          setIsPopoverOpen={setIsPopoverOpen}
          position={["bottom"]}
          className="profile__popover"
          component={
            <ProfileItem iconName="profile_icon" onClick={() => null} />
          }
        >
          {USER_MENU_PANEL_ACCESS.header[role] && (
            <>
              <div className="menu-item">
                <div className="menu-content d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <div className="menu-item__partner-name">{user?.name}</div>
                    <div className="menu-item__role">{user?.role}</div>
                  </div>
                </div>
              </div>
              <div className="profile__popover--actions-wrapper">
                {role !== "super_admin" && (
                  <div className="menu-item mb-24">
                    <Button
                      type="button"
                      className="button--transparent"
                      onClick={() => history.push("/profile")}
                    >
                      Profile
                    </Button>
                  </div>
                )}

                <div className="menu-item mb-24">
                  <Button
                    type="button"
                    className="button--transparent"
                    onClick={onClickSignOut}
                  >
                    Sign Out
                  </Button>
                </div>
              </div>
            </>
          )}
        </Popover>
      )}
    </div>
  );
};

export default ProfileContainer;
