import ContentLoader from "react-content-loader";

const EventRowSkeleton = (): JSX.Element => {
  return (
    <ContentLoader
      width="100%"
      height="590"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="15" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="15" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="15" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="55" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="55" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="55" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="95" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="95" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="95" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="135" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="135" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="135" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="175" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="175" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="175" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="205" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="205" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="205" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="245" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="245" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="245" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="285" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="285" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="285" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="325" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="325" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="325" rx="10" ry="10" width="60" height="19" />
      <rect x="0" y="365" rx="10" ry="10" width="150" height="19" />
      <rect x="350" y="365" rx="10" ry="10" width="60" height="19" />
      <rect x="1060" y="365" rx="10" ry="10" width="60" height="19" />
    </ContentLoader>
  );
};

export default EventRowSkeleton;
