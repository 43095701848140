interface PasswordValidator {
  (value: string): boolean;
}

export const isLengthCorrect: PasswordValidator = (value) => value.length > 7;

export const isContainsNumber: PasswordValidator = (value) => /\d/.test(value);

export const isContainsUppercase: PasswordValidator = (value) =>
  /[A-Z]/.test(value);

export const isContainsSymbols: PasswordValidator = (value) =>
  /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(value);
