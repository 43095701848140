import { FC, useCallback, useMemo, useState } from "react";
import { useOrderState } from "providers/Orders";

import { PageWrapper } from "components/base";
// import Icon from "../../components/base/Icon";
import "./summary.scss";
import useRole from "hooks/useRole";
// import Badge from "../../components/Badge";
// import Banner from "../../components/Banner";
// import Button from "components/Button";
// import UsersGrid from "./UsersGrid";
// import MarkupGrid from "./MarkupGrid";
import { TabSelector } from "pages/Clients/ClientDetails/TabSelector";
import "react-datepicker/dist/react-datepicker.css";
import FinancialTransaction from "./FinancialTransaction";
import Revenue from "./Revenue";
import UsersCharts from "./UsersCharts";
// import ProfitGrid from "./ProfitGrid";
const tabsData = {
  users: {
    tabName: "Users",
    title: "Reports overview",
    component: UsersCharts,
  },
  financialTransaction: {
    tabName: "Financial Transaction",
    title: "Reports overview",
    component: FinancialTransaction,
  },
  revenue: {
    tabName: "Revenue",
    title: "Reports overview",
    component: Revenue,
  },
};

const Dashboard: FC = () => {
  const role = useRole();

  // context
  const { totalAmount, totalCommission, totalOrders } = useOrderState();

  const amount = useMemo(
    () =>
      totalAmount?.map((item) => `${item.total} ${item.currency}`).join(", "),
    [totalAmount]
  );

  const getCommission = useCallback(
    (asset) => {
      const data = totalCommission?.find((item) => item.asset === asset);

      if (data) {
        return data.total;
      }

      return 0;
    },
    [totalCommission]
  );

  const orders = useMemo(
    () => totalOrders?.map((item) => `${item.count} ${item.status}`).join(", "),
    [totalOrders]
  );

  getCommission("BTC"), getCommission("USDT"), orders, amount;
  const [selectedTab, setSelectedTab] = useState(tabsData.users);

  return role ? (
    <PageWrapper title="Dashboard" className="dashboard__content">
      <div className="content d-flex flex-column flex-column-fluid dashboard__content--inner">
        <div className="charts-dashboard-container">
          <p className="title">{selectedTab.title}</p>
          <nav className="flex card-details-tabs tabs-padding">
            {Object.values(tabsData).map((el) => (
              <TabSelector
                key={el.title}
                isActive={selectedTab.tabName === el.tabName}
                onClick={() => setSelectedTab(el)}
              >
                {el.tabName}
              </TabSelector>
            ))}
          </nav>
          {<selectedTab.component />}
        </div>
      </div>
    </PageWrapper>
  ) : (
    <></>
  );
};

export default Dashboard;
