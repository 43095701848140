import ContentLoader from "react-content-loader";

const TableSkeleton = (): JSX.Element => {
  return (
    <ContentLoader
      width="100%"
      height={617}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="27" y="15" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="15" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="15" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="15" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="15" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="15" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="15" rx="10" ry="10" width="85" height="19" />
      <rect x="977" y="15" rx="10" ry="10" width="169" height="19" />
      <rect x="1180" y="15" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="15" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="15" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="15" rx="10" ry="10" width="50" height="19" />

      <rect x="27" y="80" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="80" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="80" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="80" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="80" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="80" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="80" rx="10" ry="10" width="85" height="19" />
      <rect x="977" y="80" rx="10" ry="10" width="169" height="19" />
      <rect x="1180" y="80" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="80" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="80" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="80" rx="10" ry="10" width="50" height="19" />

      <rect x="27" y="139" rx="4" ry="4" width="20" height="20" />
      <rect x="67" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="188" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="402" y="140" rx="10" ry="10" width="85" height="19" />
      <rect x="523" y="141" rx="10" ry="10" width="169" height="19" />
      <rect x="731" y="139" rx="10" ry="10" width="85" height="19" />
      <rect x="852" y="138" rx="10" ry="10" width="85" height="19" />
      <rect x="1180" y="138" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="138" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="138" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="138" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="196" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="197" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="198" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="196" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="195" rx="10" ry="10" width="85" height="19" />
      <rect x="1180" y="195" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="195" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="195" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="195" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="258" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="259" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="260" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="258" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="257" rx="10" ry="10" width="85" height="19" />
      <rect x="1180" y="257" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="257" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="257" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="257" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="316" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="317" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="318" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="316" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="315" rx="10" ry="10" width="85" height="19" />
      <rect x="1180" y="315" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="315" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="315" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="315" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="379" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="380" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="381" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="379" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <rect x="1180" y="378" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="378" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="378" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="378" rx="10" ry="10" width="50" height="19" />

      <rect x="978" y="138" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <rect x="1180" y="378" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="378" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="378" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="378" rx="10" ry="10" width="50" height="19" />

      <rect x="977" y="195" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="257" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="315" rx="10" ry="10" width="169" height="19" />
      <rect x="977" y="378" rx="10" ry="10" width="169" height="19" />
      <rect x="851" y="378" rx="10" ry="10" width="85" height="19" />
      <rect x="1180" y="378" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="378" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="378" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="378" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="438" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="438" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="438" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="438" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="438" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="438" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="438" rx="10" ry="10" width="85" height="19" />
      <rect x="978" y="438" rx="10" ry="10" width="169" height="19" />
      <rect x="1180" y="438" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="438" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="438" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="438" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="500" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="500" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="500" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="500" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="500" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="500" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="500" rx="10" ry="10" width="85" height="19" />
      <rect x="978" y="500" rx="10" ry="10" width="169" height="19" />
      <rect x="1180" y="500" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="500" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="500" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="500" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="560" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="560" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="560" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="560" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="560" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="560" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="560" rx="10" ry="10" width="85" height="19" />
      <rect x="978" y="560" rx="10" ry="10" width="169" height="19" />
      <rect x="1180" y="560" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="560" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="560" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="560" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="620" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="620" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="620" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="978" y="620" rx="10" ry="10" width="169" height="19" />
      <rect x="1180" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="620" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="620" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="620" rx="10" ry="10" width="50" height="19" />

      <rect x="26" y="660" rx="4" ry="4" width="20" height="20" />
      <rect x="66" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="187" y="620" rx="10" ry="10" width="169" height="19" />
      <rect x="401" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="522" y="620" rx="10" ry="10" width="169" height="19" />
      <rect x="730" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="851" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="978" y="620" rx="10" ry="10" width="169" height="19" />
      <rect x="1180" y="620" rx="10" ry="10" width="85" height="19" />
      <rect x="1300" y="620" rx="10" ry="10" width="150" height="19" />
      <rect x="1490" y="620" rx="10" ry="10" width="150" height="19" />
      <rect x="1660" y="620" rx="10" ry="10" width="50" height="19" />
    </ContentLoader>
  );
};

export default TableSkeleton;
