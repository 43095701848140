import { FC, useEffect, useMemo, useRef } from "react";
import { useOrderDispatchContext, useOrderState } from "providers/Orders";
import { filterHeadersByRole } from "services/roleService/order/order";
import { set_page } from "providers/ClientFilter/clientsActions";
import {
  set_orderFilters,
  set_stats_filter,
} from "providers/SellOrdersFilter/ordersAction";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import { useOrderDispatch, useOrderFilters } from "../../providers/OrderFilter";
import {
  set_checkedIDOrder,
  set_checked_pages,
  set_filteringOrder,
  set_orderSearch,
} from "../../providers/OrderFilter/ordersAction";
import { Icon, PageWrapper, Table } from "components/base";
import sortIcon from "../../components/base/Icon/icons/sort-icon.svg";
import OrderDetailsModal, {
  OrderDetailsModalRefType,
} from "components/modals/OrderDetailsModal";
import OrdersColumn from "./OrdersColumn";
import NotFound from "pages/NotFound";
import useRole from "hooks/useRole";
import Pagination from "../../components/Pagination";
import Button from "../../components/Button";
import OrderFiltersWrapper from "./OrderFiltersWrapper";
import TableSkeleton from "components/TableSkeleton";
import useQuery from "hooks/useQuery";

let timer: ReturnType<typeof setTimeout>;

const Orders: FC = () => {
  // context
  const {
    getOrders,
    downloadOrders,
    setLoading,
    getTrStatuses,
    getFiatList,
    getAssetList,
    getOrderById,
  } = useOrderDispatchContext();

  const { isLoading, ordersCount, orders, transactionStatuses, orderDetails } =
    useOrderState();

  const { getQueryParam } = useQuery();

  const role = useRole();

  const modalRef = useRef<OrderDetailsModalRefType>(null);
  // states

  const orderDispatch = useOrderDispatch();
  const {
    page,
    orderSearch,
    visibleOrdersCount,
    ordering2,
    sorting2,
    dateToOrder,
    checkedIDOrder,
    amountFrom,
    amountTo,
    rateFrom,
    rateTo,
    quantityFrom,
    quantityTo,
    dateFromOrder,
    filters,
    assetOrder,
    exchangeOrder,
    currencyOrder,
    partnerOrder,
    filterButtonVal,
    paymentMethods,
    withdrawalOrder,
    exportedOrder,
    arrayCheckingOrder,
    statsFilter,
    checkedPages,
    trStatus,
    billing_country,
  } = useOrderFilters();

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      if (filterButtonVal === false) return;

      if (filterButtonVal) {
        getOrders(
          page,
          orderSearch,
          filters,
          dateFromOrder,
          dateToOrder,
          amountFrom,
          amountTo,
          rateFrom,
          rateTo,
          quantityFrom,
          quantityTo,
          visibleOrdersCount,
          assetOrder,
          exchangeOrder,
          withdrawalOrder,
          currencyOrder,
          ordering2,
          sorting2,
          partnerOrder,
          paymentMethods,
          trStatus,
          false,
          billing_country
        );

        return;
      }

      getOrders(
        page,
        orderSearch,
        undefined,
        null,
        null,
        "",
        "",
        "",
        "",
        "",
        "",
        visibleOrdersCount,
        undefined,
        undefined,
        undefined,
        undefined,
        ordering2,
        sorting2,
        undefined,
        paymentMethods,
        trStatus,
        false,
        billing_country
      );
    }, 450);

    return () => {
      clearTimeout(timer);
    };
  }, [
    amountFrom,
    amountTo,
    assetOrder,
    currencyOrder,
    dateFromOrder,
    dateToOrder,
    exchangeOrder,
    filterButtonVal,
    filters,
    getOrders,
    orderSearch,
    ordering2,
    page,
    partnerOrder,
    paymentMethods,
    quantityFrom,
    quantityTo,
    rateFrom,
    rateTo,
    sorting2,
    visibleOrdersCount,
    withdrawalOrder,
    statsFilter,
    trStatus,
    billing_country,
  ]);

  const selectUser = useMemo(() => {
    const ordersID = orders.map((item) => item.id);

    const compoundOrders = [...(exportedOrder as string[]), ...ordersID];

    const handleChange = (e) => {
      if (e.target.checked) {
        orderDispatch(
          set_checkedIDOrder({
            checked: e.target.checked,
            orders: compoundOrders as string[],
          })
        );
        orderDispatch(set_checked_pages([...checkedPages, page]));
      } else {
        orderDispatch(
          set_checkedIDOrder({
            checked: true,
            orders: exportedOrder?.filter((item) => !ordersID.includes(item)),
          })
        );
        orderDispatch(
          set_checked_pages(checkedPages.filter((pg) => pg !== page))
        );
      }
    };

    return (
      <input
        onChange={handleChange}
        type="checkbox"
        className="form-check-input user-list-checkbox"
        checked={(checkedPages as number[]).includes(page)}
      />
    );
  }, [orderDispatch, orders, checkedPages, page, exportedOrder]);

  const handleDownloadOrders = () =>
    downloadOrders(
      visibleOrdersCount,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      orderSearch,
      filters,
      dateFromOrder,
      dateToOrder,
      amountFrom,
      amountTo,
      rateFrom,
      rateTo,
      quantityFrom,
      quantityTo,
      assetOrder,
      exchangeOrder,
      currencyOrder,
      partnerOrder,
      withdrawalOrder,
      trStatus,
      false,
      billing_country
    );

  const handleExportSelected = () => {
    return (
      (exportedOrder?.length !== 0 || checkedIDOrder) &&
      downloadOrders(
        visibleOrdersCount,
        sorting2,
        ordering2,
        exportedOrder,
        checkedIDOrder,
        orders,
        arrayCheckingOrder,
        orderSearch,
        filters,
        dateFromOrder,
        dateToOrder,
        amountFrom,
        amountTo,
        rateFrom,
        rateTo,
        quantityFrom,
        quantityTo,
        assetOrder,
        exchangeOrder,
        currencyOrder,
        partnerOrder,
        withdrawalOrder,
        trStatus
      )
    );
  };

  const clearSearch = () => orderDispatch(set_orderSearch(""));

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        orderDispatch(set_page(page + 1));
        orderDispatch(set_filteringOrder(true));

        return;
      case "prev":
        orderDispatch(set_page(page - 1));
        orderDispatch(set_filteringOrder(true));

        return;

      default:
        return;
    }
  };

  useEffect(() => {
    if (role === "super_admin") {
      getTrStatuses();
      const user_email = getQueryParam("user_email");
      const trx_status = getQueryParam("trx_status");

      if (user_email && trx_status) {
        orderDispatch(set_filteringOrder(false));
        orderDispatch(set_orderSearch(user_email as string));
        orderDispatch(set_orderFilters([trx_status]));
        orderDispatch(set_stats_filter(true));
        orderDispatch(set_filteringOrder(true));

        return;
      }

      if (user_email && !trx_status) {
        orderDispatch(set_orderSearch(user_email as string));
        orderDispatch(set_stats_filter(true));
        orderDispatch(set_filteringOrder(true));

        return;
      }
    }
    // eslint-disable-next-line
  }, []);

  return role ? (
    <PageWrapper title="Payments list">
      <div
        className="content d-flex flex-column flex-column-fluid order-table order__table"
        id="kt_content"
      >
        <div className="container" id="kt_content_container">
          <div className="card user-list-row">
            <div className="user-list-header header-list">
              <div className="button-export__wrapper">
                <h1 className="page-title">Transaction Buy List</h1>
                <Button
                  type="button"
                  className="button--transparent"
                  onClick={() => location.reload()}
                >
                  <Icon name="rotate" />
                </Button>
                {role !== "partner" ? (
                  <RoleProvider roles={[ROLES.SUPER_ADMIN, ROLES.FINANCE]}>
                    <Button
                      type="button"
                      className="button--transparent button--export"
                      onClick={handleExportSelected}
                      disabled={!exportedOrder?.length}
                    >
                      <Icon name="export_button" />
                      Export Selected
                    </Button>
                    <Button
                      type="button"
                      className="button--transparent button--export"
                      onClick={handleDownloadOrders}
                    >
                      <Icon name="export_button" />
                      Export
                    </Button>
                  </RoleProvider>
                ) : null}
              </div>
              <OrderFiltersWrapper
                setLoading={setLoading}
                getFiatList={getFiatList}
                getAssetList={getAssetList}
                transactionStatuses={transactionStatuses}
              />
            </div>
            <div className="table--row">
              <div className="user-list-body">
                {isLoading ? (
                  <>
                    <TableSkeleton />
                    <Pagination
                      page={page}
                      count={ordersCount}
                      visibleCount={+visibleOrdersCount}
                      clearSearch={clearSearch}
                      setLoading={setLoading}
                      isLoading={isLoading}
                      handlePagination={handlePagination}
                    />
                  </>
                ) : (
                  <>
                    {(orders.length === 0 && <NotFound />) || (
                      <>
                        <div>
                          <Table
                            headers={[
                              {
                                empty: true,
                                val: "checkbox",
                                w: 25,
                                content: selectUser,
                              },
                              {
                                title: "Transaction Id",
                                val: "id",
                                w: "fit-content",
                                className: "th-tansaction-id",
                              },
                              {
                                title: "",
                                val: "id_clipboard",
                                w: "fit-content",
                              },
                              {
                                title: "Amount",
                                val: "amount",
                                w: 70,
                                icon: sortIcon,
                              },
                              {
                                title: "Fiat",
                                val: "fiat",
                                w: 55,
                                icon: sortIcon,
                              },
                              {
                                title: "Asset",
                                val: "asset",
                                w: 70,
                                icon: sortIcon,
                              },
                              {
                                title: "Payment Provider",
                                val: "payment_method",
                                super_admin: false,
                                w: 70,
                              },
                              {
                                title: "Transaction Status",
                                val: "status",
                                w: 100,
                                icon: sortIcon,
                                super_admin: true,
                              },
                              {
                                title: "",
                                val: "status_edit",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              {
                                title: "Bank Status",
                                val: "bank_status",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Withdrawal",
                                val: "withdrawal",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Risk level",
                                val: "risk_level",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              {
                                title: "Rate",
                                val: "spot_price",
                                w: 80,
                                icon: sortIcon,
                              },
                              {
                                title: "Price",
                                val: "price",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              {
                                title: "Customer Email",
                                val: "email",
                                w: 200,
                                icon: sortIcon,
                                className: "th-email",
                              },
                              {
                                title: "",
                                val: "email_clipboard",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              {
                                title: "Exchange",
                                val: "api",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Processing Fee",
                                val: "commission",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Partner Commission",
                                val: "partner_markup",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "NET COMM",
                                val: "network_fee",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Cost of Service",
                                val: "cost_of_service",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              {
                                title: "Swaps Revenue",
                                val: "swaps_profit",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              {
                                title: "Quantity",
                                val: "asset_quantity",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Billing Country",
                                val: "billing_country",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "PARTNER NAME",
                                val: "partner_name",
                                w: 100,
                                icon: sortIcon,
                              },
                              {
                                title: "Date",
                                val: "createdAt",
                                w: "fit-content",
                                icon: sortIcon,
                              },
                              { title: "Action", val: "action", w: 100 },
                              {
                                empty: true,
                                w: 50,
                              },
                            ].filter(
                              (item) => role && filterHeadersByRole(item, role)
                            )}
                            pageName={"order"}
                          >
                            <OrdersColumn modalRef={modalRef} />
                          </Table>
                        </div>
                        <Pagination
                          page={page}
                          count={ordersCount}
                          visibleCount={+visibleOrdersCount}
                          clearSearch={clearSearch}
                          setLoading={setLoading}
                          isLoading={isLoading}
                          handlePagination={handlePagination}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <OrderDetailsModal
        order={orderDetails}
        getOrderById={getOrderById}
        ref={modalRef}
      />
    </PageWrapper>
  ) : (
    <></>
  );
};

export default Orders;
