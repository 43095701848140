import { EventTypes } from "pages/WebhookCreate/SelectEvent/SelectEvent.props";
import { ActionType, Action } from "./types";

export const setEventTypes = (eventTypes: EventTypes): Action => ({
  type: ActionType.SET_EVENT_TYPES,
  payload: eventTypes,
});

export const setSelectedEvents = (events: string[]): Action => ({
  type: ActionType.SET_EVENT,
  payload: events,
});

export const setActiveAccordions = (activeAccordions: string[]): Action => ({
  type: ActionType.SET_ACTIVE_ACCORDIONS,
  payload: activeAccordions,
});

export const setSearchTerm = (searchTerm: string): Action => ({
  type: ActionType.SET_SEARCH_TERM,
  payload: searchTerm,
});

export const setWebhookEndpoint = (webhookEndpoint: string): Action => ({
  type: ActionType.SET_WEBHOOK_ENDPOINT,
  payload: webhookEndpoint,
});
