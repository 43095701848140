import { FC, useMemo } from "react";

import { Icon } from "components/base";
import Button from "components/Button";
import { AddFilterProps } from "./AddFilterDropDown.props";

const AddFilterItem: FC<AddFilterProps> = ({
  item,
  onClick,
  additionalFilters,
}) => {
  const disableItem = useMemo(() => {
    return !!additionalFilters?.find(
      (additionalItem) => additionalItem.value === item.value
    );
  }, [additionalFilters, item]);

  return (
    <li className="add-filter__list-item">
      <Button
        type="button"
        className="button--transparent"
        onClick={() => onClick(item)}
        disabled={disableItem}
      >
        <Icon name="filter_add" />
        {item?.label}
      </Button>
    </li>
  );
};

export default AddFilterItem;
