import { useMemo } from "react";
import {
  useWidgetConsoleDispatch,
  useWidgetConsoleState,
} from "providers/WidgetConsole";

import { PageWrapper } from "components/base";
import WidgetConsoleTabs from "./WidgetConsoleTabs";
import CryptoList from "./CryptoList";
import FireblockList from "./FireblockList";
import FiatList from "./FiatList";
import CryptoFiltersWrapper from "./CryptoFiltersWrapper";
import FireblockFiltersWrapper from "./FireblockFiltersWrapper";
import FiatFiltersWrapper from "./FiatFiltersWrapper";
// import Pagination from "components/Pagination";

const WidgetConsole = (): JSX.Element => {
  const { activeTab } = useWidgetConsoleState();
  const { setPage } = useWidgetConsoleDispatch();

  const renderTable = useMemo(() => {
    switch (activeTab) {
      case "crypto_list":
        return <CryptoList />;
      case "fireblock_list":
        return <FireblockList />;
      case "fiat_list":
        return <FiatList />;
      default:
        return <div>Not found</div>;
    }
  }, [activeTab]);

  const renderFilter = useMemo(() => {
    switch (activeTab) {
      case "crypto_list":
        return <CryptoFiltersWrapper />;
      case "fireblock_list":
        return <FireblockFiltersWrapper setPage={setPage} />;
      case "fiat_list":
        return <FiatFiltersWrapper />;
      default:
        return <div>Not found</div>;
    }
  }, [activeTab, setPage]);

  return (
    <PageWrapper title="Widget Console">
      <div
        className="content d-flex flex-column flex-column-fluid user-table"
        id="kt_content"
      >
        <div className="button-export__wrapper">
          <h1 className="page-title">Buy Flow</h1>
        </div>
        <WidgetConsoleTabs />
        {renderFilter}
        <div className="container" id="kt_content_container">
          <div id="card-user-list-row" className="card user-list-row">
            {renderTable}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default WidgetConsole;
