import { FC, useMemo } from "react";

import { PageWrapper } from "components/base";
import "./settings.scss";
import SettingTabs from "./SettingsTab";
import { PartnerSettingsProps } from "./PartnerSettings.props";
import IntegrationPage from "./IntegrationPage";
import WebhooksPage from "./WebhooksPage";
import EventsPage from "./EventsPage";

const PartnerSetting: FC<PartnerSettingsProps> = ({ page }) => {
  const renderPage = useMemo(() => {
    switch (page) {
      case "integration":
        return <IntegrationPage />;
      case "webhooks":
        return <WebhooksPage />;
      case "events":
        return <EventsPage />;
      default:
        return null;
    }
  }, [page]);

  return (
    <PageWrapper title="Widget Settings">
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="container">
          <div className="d-flex flex-column">
            <div className="flex-column flex-lg-row-auto w-100">
              <div className="card  partner-setting">
                <div className="setting-header">
                  <p>Widget Settings</p>
                </div>
                <SettingTabs />
                {renderPage}
                {/* <p className="setting-text">
                  Copy the code and paste it into your site to integrate the
                  Swaps widget. For complete control of the widget, check out
                  the{" "}
                  <a
                    href="https://apidoc.swaps.app/"
                    target={"_blank"}
                    rel="noreferrer"
                  >
                    API documentation
                  </a>
                  .
                </p>
                <div>
                  <div className="clip-card">
                    <div className="iframe_sw">
                      <p className="iframe_title"># Swaps.app Widget iFrame</p>
                      <p>
                        <span className="p_b">
                          <span className="p_g">{"<"}</span>
                          {"iframe "}
                        </span>
                        src<span className="p_k">=</span>
                        <span className="p_s">
                          <span className="p_ch">{`"`}</span>
                          {
                            "https://swaps.app/widget?partner=22b56d7d-6939-419b-b569-640cf9e68ff8"
                          }
                          <span className="p_ch">{`"`}</span>
                        </span>
                        <span className="p_k">{">"}</span>
                      </p>
                      <p className="p_atribute">
                        allow<span className="p_k">=</span>
                        <span className="p_ch">{`"`}</span>
                        <span className="p_s">{"camera"}</span>
                        <span className="p_ch">{`"`}</span>
                      </p>
                      <p className="p_atribute">
                        frameBorder<span className="p_k">=</span>
                        <span className="p_ch">{`"`}</span>
                        <span className="p_s">{"0"}</span>
                        <span className="p_ch">{`"`}</span>
                      </p>
                      <p className="p_atribute">
                        style<span className="p_k">=</span>
                        <span className="p_ch">{`"`}</span>
                        <span className="p_s">{"width:"}</span>
                        <span className="p_s">{"404px"}</span>
                        <span className="p_s">{";height:"}</span>
                        <span className="p_s">{"600px"}</span>
                        <span className="p_ch">{`"`}</span>
                      </p>
                      <p className="p_s">
                        <span className="p_k"> {"<"}</span>
                        <span className="p_bl">/</span>
                        {"iframe>"}
                      </p>
                    </div>
                  </div>

                  <CopyToClipboard
                    options={{ message: "" }}
                    text={`<iframe src="https://swaps.app/widget?partner=${
                      user && user.id
                        ? user.id
                        : "22b56d7d-6939-419b-b569-640cf9e68ff8"
                    }">
                    allow=<span>camera"</span>
                    frameborder="0"
                    style="width:404px; height:600px"
            </iframe>`}
                    onCopy={() => setCopied(true)}
                  >
                    <button className={`btn-copy ${copied && "copied"}`}>
                      <ClipBoard
                        style={{
                          width: "12px",
                          height: "12px",
                        }}
                      />{" "}
                      Copy code{" "}
                    </button>
                  </CopyToClipboard>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default PartnerSetting;
