import { useHistory } from "react-router-dom";
import { useBalanceDispatchContext } from "providers/Balance";

import { Icon } from "components/base";
import Button from "components/Button";

const PayoutOTPHeader = (): JSX.Element => {
  const history = useHistory();
  const { setAmount, setPayoutWallet } = useBalanceDispatchContext();

  return (
    <div className="payout-header__container">
      <Button
        type="button"
        onClick={() => {
          setAmount(null);
          setPayoutWallet(null);
          history.back(-1);
        }}
      >
        <span></span>
        <span></span>
      </Button>
      <span className="payout-header__text">
        <Icon name="payout_funds" />
        <span>Payout funds</span>
      </span>
    </div>
  );
};

export default PayoutOTPHeader;
