export const ActionTypes = {
  SIGN_IN: "SIGN_IN",
  SET_USER: "SET_USER",
  SIGN_OUT: "SIGN_OUT",
  ADD_PHONE: "ADD_PHONE",
  SET_ERROR: "SET_ERROR",
};

export type SignInData = {
  email: string;
  password: string;
};

export type SetPassData = {
  password: string;
  resetPasswordToken: string;
};

export type SendCode = {
  isGoogleAuth: boolean;
  hasGoogleTwoFa: boolean;
  twoFaCode: string;
  hesTwoFaPhoneNumber: boolean;
};

export type ForgotPass = {
  email: string;
};

export type User = {
  email: string;
  id: string;
  name: string;
  role: string;
  hesTwoFaPhoneNumber: boolean;
  hasGoogleTwoFa: boolean;
  phoneNumber?: string;
  twoFa_phone?: string;
};

interface SignIn {
  type: typeof ActionTypes.SIGN_IN;
  data: unknown;
}

interface SetUser {
  type: typeof ActionTypes.SIGN_IN;
  data: unknown;
}

interface SignOut {
  type: typeof ActionTypes.SIGN_OUT;
  data?: unknown;
}

export type Action = SignIn | SignOut | SetUser;

export type State = {
  isLoggedIn: boolean;
  user: User | null;
  error: string | null;
};

export type DispatchContext = {
  signIn: (data: SignInData) => void;
  signOut: () => void;
  createPassword: (data: SetPassData) => void;
  resetPassword: (data: SetPassData) => void;
  forgotPass: (email: string) => void;
  sendCode: (data: SendCode) => void;
  setPhone: (phone: string) => void;
};

export type Dispatch = (action: Action) => void;
