import React, { useState } from "react";
import Datetime from "react-datetime";
import Switch from "react-switch";
import moment, { Moment } from "moment";
import Select from "react-select";

import { ReactComponent as CloseModalLogo } from "../base/Icon/icons/close-modal.svg";
import { Modal } from "components/base";
import { validateNumberInput } from "utils/helpers";
import "react-datetime/css/react-datetime.css";

interface IPromotion {
  value: string;
  label: string;
}
interface IPromotionsModal {
  isOpen: boolean;
  allowNegativeNumbers: boolean;
  addPromotion: ({
    promotionId,
    feeReductionBank,
    feeReductionCard,
    startDate,
    endDate,
    description,
    buyFlow,
    sellFlow,
  }: {
    promotionId: string;
    feeReductionBank: number;
    feeReductionCard: number;
    startDate: string;
    endDate: string;
    description: string;
    buyFlow?: boolean | undefined;
    sellFlow?: boolean | undefined;
  }) => void;

  promotionTypes: IPromotion[];
  onClose: () => void;
}

const FlowSwitcher = ({ onChange, checked }) => (
  <Switch
    onChange={onChange}
    checked={checked}
    height={18}
    width={36}
    onColor="#2075f5"
  />
);

function PromotionsModal({
  isOpen,
  onClose,
  promotionTypes,
  addPromotion,
  allowNegativeNumbers,
}: IPromotionsModal): JSX.Element {
  const [selectedOption, setSelectedOption] = useState(promotionTypes[0]);

  const [feeReductionBankTransfer, setFeeReductionBankTransfer] = useState("1");
  const [description, setDescription] = useState("");
  const [feeReductionInstantBank, setFeeReductionInstantBank] = useState("1");
  const [buyFlowSwitch, setBuyFlowSwitch] = useState(true);
  const [sellFlowSwitch, setSellFlowSwitch] = useState(true);
  const [startDate, setStartDate] = useState(new Date());
  const [descrtiptionError, setDescrtiptionError] = useState("");

  const [endDate, setEndDate] = useState(
    new Date(Date.now() + 3600 * 1000 * 24 * 7)
  );

  const handleFeeReductionBankTransfer = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const resultValue = validateNumberInput({
      value,
      allowNegativeNumbers,
    })?.value;

    resultValue !== null && setFeeReductionBankTransfer(resultValue);
  };

  const handleFeeReductionInstantBank = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    const resultValue = validateNumberInput({
      value,
      allowNegativeNumbers,
    })?.value;

    resultValue !== null && setFeeReductionInstantBank(resultValue);
  };

  const handleStartDate = (d: string | Moment) => {
    if (typeof d !== "string") {
      setStartDate(new Date(d.format()));
    }
  };

  const handleEndDate = (d: string | Moment) => {
    if (typeof d !== "string") {
      setEndDate(new Date(d.format()));
    }
  };

  const handleSwithBuyFlow = (status: boolean) => {
    if (!sellFlowSwitch && !status) {
      setSellFlowSwitch(true);
      setBuyFlowSwitch(status);
    } else {
      setBuyFlowSwitch(status);
    }
  };

  const handleSwithSellFlow = (status: boolean) => {
    if (!buyFlowSwitch && !status) {
      setBuyFlowSwitch(true);
      setSellFlowSwitch(status);
    } else {
      setSellFlowSwitch(status);
    }
  };

  const handleSubmit = () => {
    if (description.length) {
      addPromotion({
        promotionId: selectedOption.value,
        feeReductionBank: Number(feeReductionInstantBank),
        feeReductionCard: Number(feeReductionBankTransfer),
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        description,
        buyFlow: buyFlowSwitch,
        sellFlow: sellFlowSwitch,
      });
      onClose();
    } else {
      setDescrtiptionError("Can not be empty");
    }
  };

  const handleVaildEndDate = (date: Moment) => {
    const isAfter =
      moment(date).isAfter(startDate.toISOString()) ||
      moment(date).isAfter(moment().add(-1, "day").format());

    return isAfter;
  };

  const handleVaildStartDate = (date: Moment) => {
    const isBefore = moment(date).isBefore(endDate.toISOString());

    return isBefore;
  };

  return (
    <Modal isOpen={isOpen} closeModal={onClose}>
      <div className="modal-content-container">
        <div className="modal-header" id="kt_modal_add_customer_header">
          <div>
            <h2 className="fw-bolder modal-header-title-m">Add Promotion</h2>
          </div>
          <div className="color">
            <span className="svg-icon svg-icon-1" onClick={onClose}>
              <CloseModalLogo />
            </span>
          </div>
        </div>
        <div className="modal-body py-10 px-lg-17">
          <div className="modal-body-content">
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Promotion Type</label>
                <div>
                  <Select
                    value={selectedOption}
                    onChange={(d) => setSelectedOption(d as IPromotion)}
                    options={promotionTypes}
                  />
                </div>
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Start Date</label>
                <Datetime
                  value={startDate}
                  onChange={handleStartDate}
                  dateFormat="DD MMM YYYY"
                  timeFormat="HH:mm"
                  isValidDate={handleVaildStartDate}
                />
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>End Date</label>
                <Datetime
                  value={endDate}
                  onChange={handleEndDate}
                  dateFormat="DD MMM YYYY"
                  timeFormat="HH:mm"
                  isValidDate={handleVaildEndDate}
                />
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Fee Reduction for InstantBanking</label>
                <input
                  onBlur={() =>
                    feeReductionInstantBank === "" &&
                    setFeeReductionInstantBank("0")
                  }
                  onChange={handleFeeReductionInstantBank}
                  type="text"
                  className="form-control form-control-solid"
                  name="input"
                  value={feeReductionInstantBank}
                />
              </div>
            </div>

            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Fee Reduction for Bank Transfer</label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  name="input"
                  onChange={handleFeeReductionBankTransfer}
                  onBlur={() =>
                    feeReductionBankTransfer === "" &&
                    setFeeReductionBankTransfer("0")
                  }
                  value={feeReductionBankTransfer}
                />
              </div>
            </div>

            <div className="modal-content-row">
              <div className="modal-content-col">
                <label>Description</label>
                <input
                  onChange={(e) => {
                    setDescription(e.target.value);
                    setDescrtiptionError("");
                  }}
                  value={description}
                  type="text"
                  className="form-control form-control-solid"
                  name="input"
                />
                {descrtiptionError && (
                  <p className="input-error-message">{descrtiptionError}</p>
                )}
              </div>
            </div>
            <div className="modal-content-row">
              <div className="modal-content-col flows-switcher">
                <label>Buy Flow</label>
                <FlowSwitcher
                  onChange={handleSwithBuyFlow}
                  checked={buyFlowSwitch}
                />
                <label>Sell Flow</label>
                <FlowSwitcher
                  onChange={handleSwithSellFlow}
                  checked={sellFlowSwitch}
                />
              </div>
            </div>

            <div className="modal-content-row">
              <div className="modal-content-col"></div>
            </div>
          </div>
        </div>
        <div className="modal-footer flex-center">
          <button
            type="button"
            className="button button-secondary button-cancel"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="button button-primary button-add"
            onClick={handleSubmit}
          >
            <span className="indicator-label">Add Promotion</span>
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default PromotionsModal;
