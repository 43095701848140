import { FC } from "react";
import { Link } from "react-router-dom";

interface TransactionStatProps {
  flow: string;
  title: string;
  totalPayments: number | undefined;
  successfulPayments: number | undefined;
  failedPayments: number | undefined;
  pendingPayments: number | undefined;
  email: string;
  trx_status?: string;
  handleStatus: (status: string) => void;
  createQuerySearchParams: (params: {
    user_email: string;
    trx_status?: string;
  }) => string;
  triggerStatsFilter: () => void;
}

const TransactionStatistics: FC<TransactionStatProps> = ({
  flow,
  title,
  totalPayments,
  successfulPayments,
  failedPayments,
  pendingPayments,
  email,
  handleStatus,
  createQuerySearchParams,
  triggerStatsFilter,
}) => {
  return (
    <div>
      <div className="statistics-title__box">
        <span>{title}</span>
      </div>
      <div className="col-statistics">
        <div className="statistics-box">
          <Link
            onClick={triggerStatsFilter}
            to={`/orders/${flow}/${createQuerySearchParams({
              user_email: email,
            })}`}
          >
            Total payments
          </Link>
          <b>{totalPayments}</b>
        </div>
        <div className="statistics-box">
          <Link
            onClick={() => handleStatus("paid")}
            to={`/orders/${flow}/${createQuerySearchParams({
              user_email: email,
              trx_status: "paid",
            })}`}
          >
            Successful payments
          </Link>
          <b>{successfulPayments}</b>
        </div>
        <div className="statistics-box">
          <Link
            onClick={() => handleStatus("failed")}
            to={`/orders/${flow}/${createQuerySearchParams({
              user_email: email,
              trx_status: "failed",
            })}`}
          >
            Failed payments
          </Link>
          <b>{failedPayments}</b>
        </div>
        <div className="statistics-box">
          <Link
            onClick={() => handleStatus("pending")}
            to={`/orders/${flow}/${createQuerySearchParams({
              user_email: email,
              trx_status: "pending",
            })}`}
          >
            Pending payments
          </Link>
          <b>{pendingPayments}</b>
        </div>
      </div>
    </div>
  );
};

export default TransactionStatistics;
