import { FilterType } from ".";
import {
  ACTION_SET_DATEFROM,
  ACTION_SET_DATETO,
  ACTION_SET_EXPORT_ORDER,
  ACTION_SET_FILTERS,
  ACTION_SET_ORDERING,
  ACTION_SET_PAGE,
  ACTION_SET_SEARCH,
  ACTION_SET_SELECT_ORDER,
  ACTION_SET_SETTINGS_TOGGLE,
  ACTION_SET_AMOUNT_FROM,
  ACTION_SET_AMOUNT_TO,
  ACTION_SET_RATE_FROM,
  ACTION_SET_RATE_TO,
  ACTION_SET_QUANTITY_FROM,
  ACTION_SET_QUANTITY_TO,
  ACTION_SET_ORDER_COUNT,
  ACTION_SET_ORDER_ASSET,
  ACTION_SET_ORDER_CURRENCY,
  ACTION_SET_ORDER_EXCHANGE,
  ACTION_SET_ORDER_SORTING,
  ACTION_SET_ORDER_PARTNER,
  ACTION_SET_CHECKED_PARTNER,
  ACTION_SET_CHECKED_ID_ORDER,
  ACTION_SET_ARRAY_CHECK_ORDER,
  ACTION_SET_ORDER_ID,
  ACTION_SET_PARTNER_NAME,
  ACTION_SET_PAGE_NAME,
  ACTION_SET_FILTER_BUTTON_VAL,
  ACTION_SET_PAYMENT_METHOD,
  ACTION_SET_ORDER_WITHDRAWAL,
  ACTION_SET_STATS,
  ACTION_SET_CHECKED_PAGE,
  ACTION_SET_LOADING,
  ACTION_SET_TR_STATUS,
  ACTION_RESET_FILTERS,
  ACTION_SET_CUSTOMER_ID,
  ACTION_SET_ADDRESS,
  ACTION_SET_STATUS,
  ACTION_SET_EMAIL,
  ACTION_SET_BILLING_COUNTRY,
  ACTION_SET_PAYER_EMAIL,
  ACTION_SET_RECEIVE_STATUS,
  ACTION_SET_ID,
  ACTION_SET_RECEIVE_ID,
} from "./types";

export type actionType = {
  type: string;
  payload: FilterType[keyof FilterType];
};

export const set_address = (address: FilterType["address"]): actionType => {
  return {
    type: ACTION_SET_ADDRESS,
    payload: address,
  };
};

export const set_orderPage = (clientPage: FilterType["page"]): actionType => {
  return {
    type: ACTION_SET_PAGE,
    payload: clientPage,
  };
};

export const set_orderSearch = (
  orderSearch: FilterType["orderSearch"]
): actionType => {
  return {
    type: ACTION_SET_SEARCH,
    payload: orderSearch,
  };
};

export const set_email = (email: string | undefined): actionType => {
  return {
    type: ACTION_SET_EMAIL,
    payload: email,
  };
};

export const set_payer_email = (email: string | undefined): actionType => {
  return {
    type: ACTION_SET_PAYER_EMAIL,
    payload: email,
  };
};

export const set_customerId = (
  customerId: FilterType["customerId"]
): actionType => {
  return {
    type: ACTION_SET_CUSTOMER_ID,
    payload: customerId,
  };
};

export const set_status = (status: string | undefined): actionType => {
  return {
    type: ACTION_SET_STATUS,
    payload: status,
  };
};

export const set_receive_status = (status: string | undefined): actionType => {
  return {
    type: ACTION_SET_RECEIVE_STATUS,
    payload: status,
  };
};

export const set_id = (id: string): actionType => ({
  type: ACTION_SET_ID,
  payload: id,
});

export const set_receive_id = (receiveId: string): actionType => ({
  type: ACTION_SET_RECEIVE_ID,
  payload: receiveId,
});

export const set_orderFilters = (
  clientFilter: FilterType["filters"]
): actionType => {
  return {
    type: ACTION_SET_FILTERS,
    payload: clientFilter,
  };
};

export const set_orderDateFrom = (
  clientDate: FilterType["dateFromOrder"]
): actionType => {
  return {
    type: ACTION_SET_DATEFROM,
    payload: clientDate,
  };
};

export const set_filteringOrder = (
  filterButtonVal: FilterType["filterButtonVal"]
): actionType => {
  return {
    type: ACTION_SET_FILTER_BUTTON_VAL,
    payload: filterButtonVal,
  };
};

export const set_orderDateTo = (
  clientDate: FilterType["dateToOrder"]
): actionType => {
  return {
    type: ACTION_SET_DATETO,
    payload: clientDate,
  };
};

export const set_orderSorting = (
  sorting2: FilterType["sorting2"]
): actionType => {
  return {
    type: ACTION_SET_ORDER_SORTING,
    payload: sorting2,
  };
};

export const set_assetOrder = (
  assetOrder: FilterType["assetOrder"]
): actionType => {
  return {
    type: ACTION_SET_ORDER_ASSET,
    payload: assetOrder,
  };
};

export const set_tr_status = (
  trStatus: FilterType["trStatus"]
): actionType => ({
  type: ACTION_SET_TR_STATUS,
  payload: trStatus,
});

export const set_currencyOrder = (
  currencyOrder: FilterType["currencyOrder"]
): actionType => {
  return {
    type: ACTION_SET_ORDER_CURRENCY,
    payload: currencyOrder,
  };
};

export const set_exchangeOrder = (
  exchangeOrder: FilterType["exchangeOrder"]
): actionType => {
  return {
    type: ACTION_SET_ORDER_EXCHANGE,
    payload: exchangeOrder,
  };
};

export const set_Withdrawal = (
  withdrawal: FilterType["withdrawalOrder"]
): actionType => {
  return {
    type: ACTION_SET_ORDER_WITHDRAWAL,
    payload: withdrawal,
  };
};

export const set_orderOrdering = (
  clientOrder: FilterType["ordering2"]
): actionType => {
  return {
    type: ACTION_SET_ORDERING,
    payload: clientOrder,
  };
};

export const set_orderCount = (
  visibleOrdersCount: FilterType["visibleOrdersCount"]
): actionType => {
  return {
    type: ACTION_SET_ORDER_COUNT,
    payload: visibleOrdersCount,
  };
};

export const set_settingsOrder = (
  orderSettingsToggle: FilterType["orderSettingsToggle"]
): actionType => {
  return {
    type: ACTION_SET_SETTINGS_TOGGLE,
    payload: orderSettingsToggle,
  };
};

export const set_exportedOrder = (
  exportedOrder: FilterType["exportedOrder"]
): actionType => {
  return {
    type: ACTION_SET_EXPORT_ORDER,
    payload: exportedOrder,
  };
};

export const set_selectedOrder = (
  selectedOrder: FilterType["selectedOrder"]
): actionType => {
  return {
    type: ACTION_SET_SELECT_ORDER,
    payload: selectedOrder,
  };
};

export const set_partnersOrder = (
  partnerOrder: FilterType["partnerOrder"]
): actionType => {
  return {
    type: ACTION_SET_ORDER_PARTNER,
    payload: partnerOrder,
  };
};

export const set_orderID = (orderID: FilterType["orderID"]): actionType => {
  return {
    type: ACTION_SET_ORDER_ID,
    payload: orderID,
  };
};

export const set_billing_country = (
  billing_country: FilterType["billing_country"]
): actionType => {
  return {
    type: ACTION_SET_BILLING_COUNTRY,
    payload: billing_country,
  };
};

export const set_arrayCheckingOrder = (
  arrayCheckingOrder: FilterType["arrayCheckingOrder"]
): actionType => {
  return {
    type: ACTION_SET_ARRAY_CHECK_ORDER,
    payload: arrayCheckingOrder,
  };
};

export const set_pageName = (pageName: FilterType["pageName"]): actionType => {
  return {
    type: ACTION_SET_PAGE_NAME,
    payload: pageName,
  };
};

export const set_partnerSelection = (
  partnerSelection: FilterType["partnerSelection"]
): actionType => {
  return {
    type: ACTION_SET_PARTNER_NAME,
    payload: partnerSelection,
  };
};

export const set_amountFrom = (
  amountFrom: FilterType["amountFrom"]
): actionType => {
  return {
    type: ACTION_SET_AMOUNT_FROM,
    payload: amountFrom,
  };
};

export const set_amountTo = (amountTo: FilterType["amountTo"]): actionType => {
  return {
    type: ACTION_SET_AMOUNT_TO,
    payload: amountTo,
  };
};

export const set_rateFrom = (rateFrom: FilterType["rateFrom"]): actionType => {
  return {
    type: ACTION_SET_RATE_FROM,
    payload: rateFrom,
  };
};

export const set_rateTo = (rateTo: FilterType["rateTo"]): actionType => {
  return {
    type: ACTION_SET_RATE_TO,
    payload: rateTo,
  };
};

export const set_quantityFrom = (
  quantityFrom: FilterType["quantityFrom"]
): actionType => {
  return {
    type: ACTION_SET_QUANTITY_FROM,
    payload: quantityFrom,
  };
};

export const set_quantityTo = (
  quantityTo: FilterType["quantityTo"]
): actionType => {
  return {
    type: ACTION_SET_QUANTITY_TO,
    payload: quantityTo,
  };
};

export const set_checkedPartner = (
  checkedPartner: FilterType["checkedPartner"]
): actionType => {
  return {
    type: ACTION_SET_CHECKED_PARTNER,
    payload: checkedPartner,
  };
};

export const set_checkedIDOrder = ({
  checked,
  orders,
}: FilterType["checkedIDOrder"]): actionType => {
  return {
    type: ACTION_SET_CHECKED_ID_ORDER,
    payload: { checked: checked, orders },
  };
};

export const set_payMethod = (
  payload: undefined | string[]
): {
  type: string;
  payload: undefined | string[];
} => {
  return {
    type: ACTION_SET_PAYMENT_METHOD,
    payload,
  };
};

export const set_stats_filter = (value: boolean): actionType => {
  return {
    type: ACTION_SET_STATS,
    payload: value,
  };
};

export const set_checked_pages = (pages: number[] | []): actionType => {
  return {
    type: ACTION_SET_CHECKED_PAGE,
    payload: pages,
  };
};

export const set_loading = (isLoading: boolean): actionType => {
  return {
    type: ACTION_SET_LOADING,
    payload: isLoading,
  };
};

export const orders_reset = (): { type: string } => {
  return {
    type: ACTION_RESET_FILTERS,
  };
};
