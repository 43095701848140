import moment from "moment";
import { useClientDispatch, useClientFilters } from "providers/ClientFilter";
import {
  set_arrayChecking,
  set_exportedClient,
  set_selectedClient,
} from "providers/ClientFilter/clientsActions";
import React, { useEffect, useState } from "react";
import { useClientStateContext } from "providers/Clients";

import { Icon } from "components/base";
import { UserColumnProps } from "./UserLogsColumn";
import Tooltip from "components/Tooltip";
import Button from "components/Button";
import { copyToClipboard } from "utils/helpers";

let arr: string[] | undefined = [];

const UserLogsColumnFields: React.FC<UserColumnProps> = ({ log }) => {
  const [showLog, setShowLog] = useState(false);
  const dispatch = useClientDispatch();
  const { checkedID, selectedClient, exportedClient } = useClientFilters();
  const { logs } = useClientStateContext();

  useEffect(() => {
    arr = logs?.map((item) => item.id);
  }, [logs]);

  const handleCheckClientId = (id) => {
    dispatch(set_exportedClient(id));
    dispatch(set_selectedClient(id));

    if (
      checkedID &&
      exportedClient?.find((elem) => elem === log.id) === undefined
    ) {
      const x = arr?.findIndex((elem) => elem === selectedClient);
      arr?.splice(x as number, 1);
    }

    if (exportedClient?.find((elem) => elem === log.id) !== undefined) {
      arr?.push(selectedClient as string);
    }

    dispatch(set_arrayChecking(arr));
  };

  return (
    <>
      <td>
        <input
          onClick={() => handleCheckClientId(log.id)}
          className={`form-check-input user-list-checkbox`}
          checked={exportedClient?.includes(log.id)}
          type="checkbox"
          value={log.id}
        />
      </td>
      <td className="text-grey log-table-id">
        <div>
          {`${log.id.substr(0, 4)}...${log.id.substr(
            log.id.length - 4,
            log.id.length
          )}`}
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(log.id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </div>
      </td>
      <td className="user-list-td-log">
        <span
          onClick={() => {
            setShowLog(!showLog);
          }}
        >
          {typeof log?.log === "string" ? log?.log : log?.log?.action}
        </span>
        {showLog && log?.comment !== "" && (
          <div className="log-description">{log?.comment}</div>
        )}
      </td>
      <td>{log?.ip === "" ? "-" : log?.ip}</td>
      <td>{log?.country === "" ? "-" : log?.country}</td>
      <td>{moment(log?.createdAt).format("DD MMM yyyy, hh:mm")}</td>
    </>
  );
};

export default UserLogsColumnFields;
