interface header {
  content?: React.ReactNode;
  empty?: boolean;
  title?: string;
  w?: number | string;
  val?: string;
  light?: string;
  position?: string;
  // handleSorting: (e) => void;
}

export const FIAT_TABLE_ACCESS: {
  [key: string]: { [key: string]: boolean };
} = {
  id: { super_admin: true, partner: false },
  id_clipboard: { super_admin: true, partner: false },
  suspend_status: { super_admin: true, partner: false },
  status_edit: { super_admin: true, partner: false },
  max_amount: { super_admin: true, partner: false },
  max_buy_amount: { super_admin: true, partner: false },
  min_amount: { super_admin: true, partner: false },
  min_buy_amount: { super_admin: true, partner: false },
  name: { super_admin: true, partner: false },
  sign: { super_admin: true, partner: false },
  symbol: { super_admin: true, partner: false },
  date: { super_admin: true, partner: false },
  action: { super_admin: true, partner: false },
};

export const filterFiatByRole = (header: header, role: string): boolean => {
  if (header.val && FIAT_TABLE_ACCESS[header.val][role]) return true;

  return false;
};
