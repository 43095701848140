interface header {
  content?: React.ReactNode;
  empty?: boolean;
  title?: string;
  w?: number | string;
  val?: string;
  light?: string;
  position?: string;
  // handleSorting: (e) => void;
}

export const FIREBLOCK_TABLE_ACCESS: {
  [key: string]: { [key: string]: boolean };
} = {
  checkbox: { super_admin: true, partner: false },
  id: { super_admin: true, partner: false },
  id_clipboard: { super_admin: true, partner: false },
  code: { super_admin: true, partner: false },
  contract_address: { super_admin: true, partner: false },
  decimals: { super_admin: true, partner: false },
  issuer_address: { super_admin: true, partner: false },
  name: { super_admin: true, partner: false },
  type: { super_admin: true, partner: false },
  date: { super_admin: true, partner: false },
  native_asset: { super_admin: true, partner: false },
};

export const filterFireblockByRole = (
  header: header,
  role: string
): boolean => {
  if (header.val && FIREBLOCK_TABLE_ACCESS[header.val][role]) return true;

  return false;
};
