import { ReactNode, createContext, useContext, useReducer } from "react";

import { State, Dispatch } from "./types";
import reducer from "./reducers";

const initialState = {
  selectedEvents: [],
  eventTypes: undefined,
  activeAccordions: [],
  searchTerm: "",
  webhookEndpoint: "",
};

const WebhooksStateContext = createContext<State>(initialState);
const WebhooksDispatchContext = createContext<Dispatch>(() => null);

const WebhooksProvider = ({
  children,
}: {
  children: ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <WebhooksStateContext.Provider value={state}>
      <WebhooksDispatchContext.Provider value={dispatch}>
        {children}
      </WebhooksDispatchContext.Provider>
    </WebhooksStateContext.Provider>
  );
};

export const useWebhookState = (): State => {
  const webhookState = useContext(WebhooksStateContext);

  return webhookState;
};

export const useWebhookDispatch = (): Dispatch => {
  const webhookDispatch = useContext(WebhooksDispatchContext);

  return webhookDispatch;
};

export default WebhooksProvider;
