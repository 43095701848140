import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useSellOrderDispatchContext } from "providers/SellOrders";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";
import {
  set_arrayCheckingOrder,
  set_exportedOrder,
  set_selectedOrder,
} from "providers/OrderFilter/ordersAction";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import { Icon, TableActionButton } from "components/base";
import useRole from "hooks/useRole";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { copyToClipboard } from "utils/helpers";

const OrdersColumnFields: React.FC<{
  order;
  modalRef;
  transactionStatuses;
  isLast;
}> = ({ order, modalRef, isLast }) => {
  const orderDispatch = useOrderDispatch();
  const { deleteOrder } = useSellOrderDispatchContext();

  const { exportedOrder } = useOrderFilters();

  const role = useRole();

  const deletePressed = (id: string) => {
    const isConfirmed = window.confirm("Are you sure you want to delete order");

    if (isConfirmed) {
      deleteOrder(id);
    }

    modalRef.current?.open("edit");
  };

  const handleCheckClient = (id) => {
    orderDispatch(set_exportedOrder(id));
    orderDispatch(set_selectedOrder(id));

    orderDispatch(set_arrayCheckingOrder(id));
  };

  const orderID = order?.id
    ? `${order.id.substr(0, 4)}...${order.id.substr(
        order.id.length - 4,
        order.id.length
      )}`
    : "-";

  return role ? (
    <>
      <td>
        <input
          onClick={() => handleCheckClient(order.id)}
          className={`form-check-input user-list-checkbox`}
          type="checkbox"
          checked={exportedOrder?.includes(order.id)}
          value={order.id}
        />
      </td>
      <td className="td-color-gray order-id">
        {
          <RoleProvider
            roles={[
              ROLES.SUPER_ADMIN,
              ROLES.MARKETING,
              ROLES.AML,
              ROLES.FINANCE,
            ]}
            fallback={<p>{orderID}</p>}
          >
            <Link to={`/orders/receive-flow/details/${order.id}`}>
              {orderID}
            </Link>
          </RoleProvider>
        }
      </td>
      <td className="td-clipboard">
        <Tooltip content="Copied">
          {(setShowTooltip) => (
            <Button
              type="button"
              className="clipboard-button"
              onClick={() => {
                copyToClipboard(order.id);
                setShowTooltip(true);
              }}
            >
              <Icon name="clipboard" />
            </Button>
          )}
        </Tooltip>
      </td>
      <td className="td-color-gray order-id">
        {order?.address
          ? `${order.address?.substr(0, 4)}...${order.address.substr(
              order.address.length - 4,
              order.address.length
            )}`
          : "-"}
      </td>
      <td className="td-clipboard">
        {order?.address && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.address);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray">{order.amount ?? "-"}</td>
      <td className="td-color-gray">{order.asset_name ?? "-"}</td>
      <td className="td-color-gray order-id">
        {order?.client_id
          ? `${order.client_id.substr(0, 4)}...${order.client_id.substr(
              order.client_id.length - 4,
              order.client_id.length
            )}`
          : "-"}
      </td>
      <td className="td-clipboard">
        {order?.cient_id && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.client_id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray">{order.comment ?? "-"}</td>
      <td className="td-color-gray">{order.core ?? "-"}</td>
      <td className="td-color-gray">{order.currency ?? "-"}</td>
      <td className="td-color-gray">{order.fee_option ?? "-"}</td>
      <td className="td-color-gray">{order?.order?.asset_quantity ?? "-"}</td>
      <td className="td-color-gray">{order?.order?.cost_of_service ?? "-"}</td>
      <td className="td-color-gray">{order?.order?.network_fee ?? "-"}</td>
      <td className="td-color-gray">
        {order?.order?.partner_commission ?? "-"}
      </td>
      <td className="td-color-gray">{order?.order?.price ?? "-"}</td>
      <td className="td-color-gray">{order?.order?.spot_price ?? "-"}</td>
      <td className="td-color-gray">{order?.order?.status ?? "-"}</td>
      <td className="td-color-gray">{order?.order?.swaps_profit ?? "-"}</td>
      <td className="td-color-gray">{order?.order?.tr_status ?? "-"}</td>
      <td className="td-color-gray">
        {order?.order?.tx_id
          ? `${order?.order?.tx_id.substr(0, 4)}...${order?.order?.tx_id.substr(
              order?.order?.tx_id.length - 4,
              order?.order?.tx_id.length
            )}`
          : "-"}
      </td>
      <td className="td-clipboard">
        {order?.order?.tx_id && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order?.order?.tx_id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray order-id">
        {order?.order_id
          ? `${order.order_id.substr(0, 4)}...${order.order_id.substr(
              order.order_id.length - 4,
              order.order_id.length
            )}`
          : "-"}
      </td>
      <td className="td-clipboard">
        {order?.order_id && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.order_id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray order-id">
        {order?.partner_id
          ? `${order.partner_id.substr(0, 4)}...${order.partner_id.substr(
              order.partner_id.length - 4,
              order.partner_id.length
            )}`
          : "-"}
      </td>
      <td className="td-clipboard">
        {order?.partner_id && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.partner_id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray order-id">
        {order?.order?.payer_client?.email ?? "-"}
      </td>
      <td className="td-clipboard">
        {order?.order?.payer_client?.email && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order?.order?.payer_client?.email);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray order-id">
        {order?.receive_id
          ? `${order.receive_id.substr(0, 4)}...${order.receive_id.substr(
              order.receive_id.length - 4,
              order.receive_id.length
            )}`
          : "-"}
      </td>
      <td className="td-clipboard">
        {order?.receive_id && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order.receive_id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray order-id">
        {order?.receiver_client?.email ?? "-"}
      </td>
      <td className="td-clipboard">
        {order?.receiver_client?.email && (
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(order?.receiver_client?.email);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        )}
      </td>
      <td className="td-color-gray">{order?.status ?? "-"}</td>
      <td>
        {order?.link_expired
          ? moment(order.link_expired).format("DD MMM yyyy, hh:mm")
          : "-"}
      </td>
      <td>
        {order?.createdAt
          ? moment(order.createdAt).format("DD MMM yyyy, hh:mm")
          : "-"}
      </td>
      <td>
        <TableActionButton
          isLast={isLast}
          viewLink={`/orders/receive-flow/details/${order.id}`}
          ordersId={order.id}
          buttons={[
            {
              fromPage: "orderView",
              onClick: () => deletePressed(order.id),
              role: "order",
            },
          ]}
        />
      </td>
    </>
  ) : (
    <></>
  );
};

export default OrdersColumnFields;
