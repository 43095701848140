import { PartnerDataProps } from "pages/WebhookCreate/Partner/PartnerWebhook.props";
import { EventItem, EventsResponse } from "./types";
import { instance } from "utils/api";

export const addEndpoint = async ({
  callback_success,
  events,
  partnerId,
}: {
  callback_success: string;
  events: string[];
  partnerId: string;
}): Promise<void> => {
  try {
    await instance.put(`/partners/${partnerId}`, {
      callback_success,
    });

    await instance.post("/events", {
      partnerId,
      eventId: events,
    });
  } catch (error) {
    console.log(error);
  }
};

export const addPartnerEndpoint = async ({
  partnerData,
  callback_success,
  events,
  partnerId,
}: {
  partnerData: PartnerDataProps;
  callback_success: string;
  events: string[];
  partnerId: string;
}): Promise<void> => {
  try {
    await instance.put(`/partners/${partnerId}`, {
      ...partnerData,
      callback_success,
    });

    await instance.post("/events", {
      partnerId,
      eventId: events,
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeEndpoint = async (partnerId: string): Promise<void> => {
  try {
    await instance.put(`/partners/${partnerId}`, {
      callback_success: "",
    });
    await instance.post("/events", {
      partnerId,
      eventId: [],
    });
  } catch (error) {
    console.log(error);
  }
};

export const removeAdminEndpoint = async ({
  partnerId,
  partnerData,
}: {
  partnerId: string;
  partnerData: PartnerDataProps;
}): Promise<void> => {
  try {
    await instance.put(`/partners/${partnerId}`, {
      ...partnerData,
      callback_success: "",
    });
    await instance.post("/events", {
      partnerId: partnerId,
      eventId: [],
    });
  } catch (error) {
    console.log(error);
  }
};

export const getEventList = async ({
  page,
  partnerId,
  status,
}: {
  page: number;
  partnerId?: string;
  status?: "failed" | "success";
}): Promise<EventsResponse> => {
  const response = await instance.get(
    `/events?limit=10&page=${page}${
      partnerId ? `&partnerId=${partnerId}` : ""
    }${status ? `&status=${status}` : ""}`
  );

  return response.data.data;
};

export const getEventById = async ({
  id,
  partnerId,
}: {
  id: string;
  partnerId?: string;
}): Promise<EventItem> => {
  const response = await instance.get(
    `/events/${id}${partnerId ? `?partnerId=${partnerId}` : ""}`
  );

  return response.data.data;
};
