import { useMemo } from "react";
import {
  useWidgetConsoleDispatch,
  useWidgetConsoleState,
} from "providers/WidgetConsole";
import { resetFilters } from "providers/WidgetFilter/widgetFilterAction";
import { useWidgetFiltesDispatch } from "providers/WidgetFilter";

import TabItem from "components/Tabs/TabItem";

const tabList = [
  {
    label: "Crypto List",
    value: "crypto_list",
  },
  {
    label: "Fireblock List",
    value: "fireblock_list",
  },
  {
    label: "Fiat List",
    value: "fiat_list",
  },
];

const WidgetConsoleTabs = (): JSX.Element => {
  const { activeTab } = useWidgetConsoleState();
  const { setActiveTab, resetCount } = useWidgetConsoleDispatch();
  const dispatch = useWidgetFiltesDispatch();

  const handleActive = useMemo(
    () => (item: string) => {
      return item === activeTab ? "active" : "";
    },
    [activeTab]
  );

  return (
    <div className="tab-list__wrapper widget-console">
      <ul className="tab-list--list">
        {tabList?.map((filterItem, index) => (
          <TabItem
            className={handleActive(filterItem?.value)}
            key={index}
            onClick={() => {
              if (activeTab === filterItem.value) return;
              setActiveTab(filterItem.value);
              dispatch(resetFilters());
              resetCount(filterItem.value);
            }}
          >
            {filterItem?.label}
          </TabItem>
        ))}
      </ul>
    </div>
  );
};

export default WidgetConsoleTabs;
