import { FC } from "react";

import { FilterDropDownProps } from "./AddFilterDropDown.props";
import AddFilterItem from "./AddFilterItem";

const AddFilterDropDown: FC<FilterDropDownProps> = ({
  filterItems,
  setAdditionalFilters,
  additionalFilters,
  setIsPopoverOpen,
  role,
}) => {
  return (
    <div className="add-filter__container">
      <ul className="add-filter__list">
        {filterItems.map((item, index) => {
          if (item.super_admin && role !== "super_admin") return null;

          return (
            <AddFilterItem
              onClick={(item) => {
                if (setAdditionalFilters && additionalFilters) {
                  setAdditionalFilters([...additionalFilters, item]);
                }

                setIsPopoverOpen(false);
              }}
              additionalFilters={additionalFilters}
              key={index}
              item={item}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default AddFilterDropDown;
