import { FC } from "react";
//remove table with commit: e5b5f9245e5d93d5571c491c3a4c3c97b9e4a407

const RiskScoreTable: FC = () => {
  return (
    <div className="dataTables_wrapper dt-bootstrap4 no-footer" id="kt_content">
      <div className="card user-list-row  log-list-row risk-score-card">
        <div className="user-list-header header-list ">
          <div className="points-row">
            <div className="points-col">
              <span>{75} score points</span>
              <button
                className={`card-details-header-content-body-status points-btn`}
              >
                Suspecious
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RiskScoreTable;
