import { FC, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
// import {
//   useWidgetConsoleDispatch,
//   useWidgetConsoleState,
// } from "providers/WidgetConsole";

import { ReactComponent as CloseModalLogo } from "../../../components/base/Icon/icons/close-modal.svg";
import { FiatModalProps } from "./Fiat.props";
import { Modal } from "components/base";

const FiatModal: FC<FiatModalProps> = ({
  isOpen,
  closeModal,
  chosenFiat,
  page,
  updateFiatStatus,
  getFiatList,
}) => {
  const {
    register,
    handleSubmit,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  // const { chosenFiat, page } = useWidgetConsoleState();
  // const { updateFiatStatus, getFiatList } = useWidgetConsoleDispatch();
  const [loading, setLoading] = useState(false);

  const submitData = async (data) => {
    // const { max_amount, min_amount } = data;

    try {
      setLoading(true);
      const fiatData = await updateFiatStatus({
        id: chosenFiat?.id as string,
        isSuspended: chosenFiat?.is_suspended as boolean,
        max_amount: parseFloat(data?.max_amount),
        min_amount: parseFloat(data?.min_amount),
        is_sell_supported: chosenFiat?.is_sell_supported,
        // ...(location.pathname.includes("buy-flow") && {
        max_buy_amount: location.pathname.includes("buy-flow")
          ? parseFloat(data?.max_buy_amount)
          : (chosenFiat?.max_buy_amount as number),
        min_buy_amount: location.pathname.includes("buy-flow")
          ? parseFloat(data?.min_buy_amount)
          : (chosenFiat?.min_buy_amount as number),
        // }),
        // ...(location.pathname.includes("sell-flow") && {
        max_sell_amount: location.pathname.includes("sell-flow")
          ? parseFloat(data?.max_sell_amount)
          : (chosenFiat?.max_sell_amount as number),
        min_sell_amount: location.pathname.includes("sell-flow")
          ? parseFloat(data?.min_sell_amount)
          : (chosenFiat?.min_sell_amount as number),
        // }),
      });

      if (fiatData) {
        closeModal();
        getFiatList(page);
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      clearErrors();
      setValue("max_amount", chosenFiat?.max_amount);
      setValue("min_amount", chosenFiat?.min_amount);

      // if (location.pathname.includes("buy-flow")) {
      setValue("max_buy_amount", chosenFiat?.max_buy_amount);
      setValue("min_buy_amount", chosenFiat?.min_buy_amount);
      // }

      // if (location.pathname.includes("sell-flow")) {
      setValue("max_sell_amount", chosenFiat?.max_sell_amount);
      setValue("min_sell_amount", chosenFiat?.min_sell_amount);
      // }
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={() => {
        closeModal();
        clearErrors();
      }}
    >
      <div className="modal-content-container">
        <form onSubmit={handleSubmit(submitData)}>
          <div className="modal-header" id="kt_modal_add_customer_header">
            <div>
              <h2 className="fw-bolder modal-header-title-m">Edit Fiat</h2>
            </div>
            <div className="color">
              <span className="svg-icon svg-icon-1" onClick={closeModal}>
                <CloseModalLogo />
              </span>
            </div>
          </div>
          <div className="modal-body py-10 px-lg-17">
            <div className="modal-body-content">
              {location.pathname.includes("buy-flow") && (
                <div className="modal-content-row">
                  <div className="modal-content-col">
                    <label>Max Amount</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      className="form-control form-control-solid"
                      {...register("max_amount", {
                        required: "Max Amount is required",
                      })}
                      defaultValue={chosenFiat?.max_amount as number}
                      // value={maxAmount as number}
                      min={0}
                      // onChange={(e) => setMaxAmount(parseFloat(e.target.value))}
                    />
                    {errors?.max_amount && (
                      <p className="input-error-message add-crypto-error">
                        {errors.max_amount?.message}
                      </p>
                    )}
                  </div>
                </div>
              )}

              <div className="modal-content-row">
                {location.pathname.includes("buy-flow") && (
                  <div className="modal-content-col">
                    <label>Max Buy Amount</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      className="form-control form-control-solid"
                      {...register("max_buy_amount", {
                        required: "Max Buy Amount is required",
                      })}
                      // value={maxBuyAmount as number}
                      defaultValue={chosenFiat?.max_buy_amount as number}
                      min={0}
                      // onChange={(e) =>
                      //   setMaxBuyAmount(parseFloat(e.target.value))
                      // }
                    />
                    {errors?.max_buy_amount && (
                      <p className="input-error-message add-crypto-error">
                        {errors.max_buy_amount?.message}
                      </p>
                    )}
                  </div>
                )}
                {location.pathname.includes("sell-flow") && (
                  <div className="modal-content-col">
                    <label>Max Sell Amount</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      className="form-control form-control-solid"
                      {...register("max_sell_amount", {
                        required: "Max Sell Amount is required",
                      })}
                      // value={maxBuyAmount as number}
                      defaultValue={chosenFiat?.max_sell_amount as number}
                      min={0}
                      // onChange={(e) =>
                      //   setMaxBuyAmount(parseFloat(e.target.value))
                      // }
                    />
                    {errors?.max_sell_amount && (
                      <p className="input-error-message add-crypto-error">
                        {errors.max_sell_amount?.message}
                      </p>
                    )}
                  </div>
                )}
              </div>
              {location.pathname.includes("buy-flow") && (
                <div className="modal-content-row">
                  <div className="modal-content-col">
                    <label>Min Amount</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      className="form-control form-control-solid"
                      {...register("min_amount", {
                        required: "Min Amount is required",
                      })}
                      // value={minAmount as number}
                      defaultValue={chosenFiat?.min_amount as number}
                      min={0}
                      // onChange={(e) => setMinAmount(parseFloat(e.target.value))}
                    />
                    {errors?.min_amount && (
                      <p className="input-error-message add-crypto-error">
                        {errors.min_amount?.message}
                      </p>
                    )}
                  </div>
                </div>
              )}

              <div className="modal-content-row">
                {location.pathname.includes("buy-flow") && (
                  <div className="modal-content-col">
                    <label>Min Buy Amount</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      className="form-control form-control-solid"
                      {...register("min_buy_amount", {
                        required: "Min Buy Amount is required",
                      })}
                      defaultValue={chosenFiat?.min_buy_amount as number}
                      // value={minBuyAmount as number}
                      min={0}
                      // onChange={(e) =>
                      //   setMinBuyAmount(parseFloat(e.target.value))
                      // }
                    />
                    {errors?.min_buy_amount && (
                      <p className="input-error-message add-crypto-error">
                        {errors.min_buy_amount?.message}
                      </p>
                    )}
                  </div>
                )}
                {location.pathname.includes("sell-flow") && (
                  <div className="modal-content-col">
                    <label>Min Sell Amount</label>
                    <input
                      type="number"
                      inputMode="numeric"
                      pattern="\d*"
                      className="form-control form-control-solid"
                      {...register("min_sell_amount", {
                        required: "Min Sell Amount is required",
                      })}
                      defaultValue={chosenFiat?.min_sell_amount as number}
                      // value={minBuyAmount as number}
                      min={0}
                      // onChange={(e) =>
                      //   setMinBuyAmount(parseFloat(e.target.value))
                      // }
                    />
                    {errors?.min_sell_amount && (
                      <p className="input-error-message add-crypto-error">
                        {errors.min_sell_amount?.message}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="modal-footer flex-center">
            <button
              type="button"
              className="button button-secondary button-cancel"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="button button-primary button-add"
              disabled={loading}
            >
              <span className="indicator-label">Edit Fiat</span>
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default FiatModal;
