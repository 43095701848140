import { FC } from "react";
import { BrowserRouter } from "react-router-dom";
import AuthProvider from "providers/Auth";
import { ToastContainer } from "react-toastify";
import * as Sentry from "@sentry/react";
import BalanceProvider from "providers/Balance";

import Router from "./Router";

const App: FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
      <AuthProvider>
        <BrowserRouter>
          <BalanceProvider>
            <Router />
          </BalanceProvider>
        </BrowserRouter>
        <ToastContainer
          pauseOnFocusLoss={false}
          hideProgressBar
          pauseOnHover
          autoClose={2000}
        />
      </AuthProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
