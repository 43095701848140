import { FC, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import { useOrderDispatch } from "providers/OrderFilter";
import {
  orders_reset,
  set_orderFilters,
} from "providers/OrderFilter/ordersAction";
import { useClientDispatch } from "providers/ClientFilter";
import { reset_client_filters } from "providers/ClientFilter/clientsActions";
import { usePartnerDispatch } from "providers/PartnerFilter";
import { reset_partner_filters } from "providers/PartnerFilter/partnerAction";
import { resetFilters as resetWidgetFilters } from "providers/WidgetFilter/widgetFilterAction";

import { NavItemProps } from "./NavItem.props";

const NavItem: FC<NavItemProps> = ({
  to,
  className,
  children,
  activePath,
  onClick,
}) => {
  const location = useLocation();
  const orderDispatch = useOrderDispatch();
  const clientDispatch = useClientDispatch();
  const partnerDispatch = usePartnerDispatch();

  const isActive = useMemo(() => {
    if (activePath.includes(to) || location.state?.prevPath === to) return true;

    return false;
  }, [location, activePath, to]);

  const resetFilters = () => {
    switch (location.pathname) {
      case "/orders":
        return orderDispatch(orders_reset());
      case "/users":
        return clientDispatch(reset_client_filters());
      case "/partners":
        return partnerDispatch(reset_partner_filters());
      case "/widget-console":
        return resetWidgetFilters();
      default:
        return;
    }
  };

  const handleLinkClick = () => {
    resetFilters();
    if (location.pathname.includes("/orders")) return;
    orderDispatch(set_orderFilters([]));
    onClick();
  };

  return (
    <li
      className={classnames("navbar__item", className, {
        active: isActive,
      })}
    >
      <Link
        to={{ pathname: to, state: location.pathname }}
        onClick={handleLinkClick}
      >
        {children}
      </Link>
    </li>
  );
};

export default NavItem;
