export const ACTION_SET_PAGE = "ACTION_SET_PAGE";
export const ACTION_SET_SEARCH = "ACTION_SET_SEARCH";
export const ACTION_SET_LOG_SEARCH = "ACTION_SET_LOG_SEARCH";
export const ACTION_SET_FILTERS = "ACTION_SET_FILTERS";
export const ACTION_SET_DATEFROM = "ACTION_SET_DATEFROM";
export const ACTION_SET_DATETO = "ACTION_SET_DATETO";
export const ACTION_SET_KYC = "ACTION_SET_KYC";
export const ACTION_SET_FILTERS_EMAIL = "ACTION_SET_FILTERS_EMAIL";
export const ACTION_SET_CHECKEDONE = "ACTION_SET_CHECKEDONE";
export const ACTION_SET_SORTING = "ACTION_SET_SORTING";
export const ACTION_SET_ORDERING = "ACTION_SET_ORDERING";
export const ACTION_SET_CLIENT_COUNT = "ACTION_SET_CLIENT_COUNT";
export const ACTION_SET_SETTINGS_TOGGLE = "ACTION_SET_SETTINGS_TOGGLE";
export const ACTION_SET_EXPORT_CLIENT = "ACTION_SET_EXPORT_CLIENT";
export const ACTION_SET_CLIENT_COUNTRY = "ACTION_SET_CLIENT_COUNTRY";
export const ACTION_SET_CHECKED_ID = "ACTION_SET_CHECKED_ID";
export const ACTION_SET_CHECKED_COUNTRY = "ACTION_SET_CHECKED_COUNTRY";
export const ACTION_SET_ACTION_COMMENT = "ACTION_SET_ACTION_COMMENT";
export const ACTION_SET_ACTION_ID = "ACTION_SET_ACTION_ID";
export const ACTION_SET_SELECT_CLIENT = "ACTION_SET_SELECT_CLIENT";
export const ACTION_SET_ARRAY_CHECKING = "ACTION_SET_ARRAY_CHECKING";
export const ACTION_SET_FILTER_BUTTON = "ACTION_SET_FILTER_BUTTON";
export const ACTION_SET_COUNTRY_SELECTION = "ACTION_SET_COUNTRY_SELECTION";
export const ACTION_SET_CHECKED_PAGE = "ACTION_SET_CHECKED_PAGE";
export const ACTION_RESET_CLIENT_FILTERS = "ACTION_RESET_CLIENT_FILTERS";
