import { useClientDispatch, useClientFilters } from "providers/ClientFilter";
import {
  set_checkedID,
  set_checked_pages,
  set_dateFrom,
  set_dateTo,
  set_exportedClient,
  set_filteringButton,
  set_filters,
  set_kyc,
  set_page,
  set_search,
} from "providers/ClientFilter/clientsActions";
import { useCallback, useMemo } from "react";
import { useClientDispatchContext } from "providers/Clients";

import Filter from "components/Filter";
import FilterList from "components/Filter/FilterList";
import {
  email_regex,
  usersFilterTree,
  usersSecondFilterTree,
  uuid_regex,
} from "utils/constants";

const ClientsFiltersWrapper = (): JSX.Element => {
  const dispatch = useClientDispatch();
  const { setLoading } = useClientDispatchContext();

  const { search, dateTo, dateFrom, filters, kycVerification } =
    useClientFilters();

  const handleFilterKYC = useCallback(
    (filterKYC) => {
      dispatch(set_kyc(filterKYC));
    },
    [dispatch]
  );

  const handleFirstLevelFilter = (selectedOption) => {
    setLoading(true);

    if (selectedOption === "ALL") {
      handleFilterKYC({
        kycs: [],
      });
      dispatch(set_page(1));
      dispatch(set_exportedClient(undefined));
      dispatch(set_checked_pages([]));
      dispatch(set_checkedID({ checked: false, clients: [] }));

      return;
    }

    dispatch(set_filteringButton(false));

    handleFilterKYC({
      kycs: [selectedOption],
    });
    dispatch(set_page(1));
    dispatch(set_exportedClient(undefined));
    dispatch(set_checked_pages([]));
    dispatch(set_checkedID({ checked: false, clients: [] }));
    dispatch(set_filteringButton(true));
  };

  const clearClient = useCallback(() => {
    dispatch(
      set_checkedID({
        checked: false,
        clients: [],
      })
    );
    dispatch(set_checked_pages([]));
  }, [dispatch]);

  const searchByEmail = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value) return setError("Enter a value");

    if (e.target.value?.trim().match(email_regex)?.length) {
      dispatch(set_search(e.target.value.toLowerCase()));
      dispatch(set_page(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearClient();
      }, 500);

      return;
    }

    setIsValid(false);

    return setError("Email is invalid");
  };

  const searchByUserId = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value) return setError("Enter a value");

    if (e.target.value?.trim().match(uuid_regex)?.length) {
      dispatch(set_search(e.target.value.toLowerCase()));
      dispatch(set_page(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearClient();
      }, 500);

      return;
    }

    setIsValid(false);

    return setError("User id is invalid");
  };

  const handleDate = useCallback(
    ({ dateFrom, dateTo }) => {
      dispatch(set_filteringButton(false));
      dispatch(set_dateFrom(dateFrom));
      dispatch(set_dateTo(dateTo));
      dispatch(set_page(1));
    },
    [dispatch]
  );

  const handleFilter = useCallback(
    (filters) => {
      dispatch(set_filters(filters));
    },
    [dispatch]
  );

  const handleStatus = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value) return setError("Enter a value");
    handleFilter({
      status: e?.target?.value ?? undefined,
    });
    dispatch(set_page(1));
    setIsValid(true);
    setTimeout(() => {
      clearClient();
    }, 500);
  };

  const secondLevelHandlers = [
    searchByEmail,
    searchByUserId,
    handleDate,
    handleStatus,
  ];

  const handleApply = (type?: string) => {
    dispatch(set_filteringButton(true));

    if (type === "date_picker") {
      setTimeout(() => {
        clearClient();
      }, 500);
    }
  };

  const resetSearch = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(set_search(""));
    }

    if (clearReset && !search) return;
    dispatch(set_filteringButton(true));
    dispatch(set_search(""));

    handleApply();
  };

  const resetDate = (clearReset = false, context = false) => {
    if (context) return;

    if (clearReset && !dateFrom && !dateTo) return;
    dispatch(set_filteringButton(true));
    dispatch(set_dateFrom(null));
    dispatch(set_dateTo(null));

    handleApply();
  };

  const enableClearFilters = useMemo(() => {
    if (dateFrom || dateTo || filters || kycVerification || search) {
      return true;
    }

    return false;
  }, [dateFrom, dateTo, filters, kycVerification, search]);

  const resetStatus = (clearReset = false, context = false) => {
    if (context || clearReset) {
      return;
    }

    dispatch(set_filteringButton(true));
    handleFilter({
      status: undefined,
    });

    handleApply();
  };

  const clearSearches = [resetSearch, resetSearch, resetDate, resetStatus];

  return (
    <>
      <Filter
        handleFirstLevelFilter={handleFirstLevelFilter}
        filterValues={usersFilterTree}
      />
      <FilterList
        secondLevelHandlers={secondLevelHandlers}
        secondLevelFilter={usersSecondFilterTree}
        handleApply={handleApply}
        enableClearFilters={enableClearFilters}
        clearSearches={clearSearches}
        additionalHandlers={[]}
        clearAdditionalSearches={[]}
        clearSelect={clearClient}
      />
    </>
  );
};

export default ClientsFiltersWrapper;
