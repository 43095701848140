import ContentLoader from "react-content-loader";

const OrderDetailsSkeleton = (): JSX.Element => {
  return (
    <>
      <ContentLoader
        width={1200}
        height={864}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="24" rx="4" ry="4" width="365" height="22" />
        <rect x="1000" y="24" rx="4" ry="4" width="165" height="22" />
        <rect x="820" y="24" rx="4" ry="4" width="165" height="22" />
        <rect x="0" y="24" rx="4" ry="4" width="165" height="22" />
        <rect x="0" y="60" rx="4" ry="4" width="195" height="19" />
        <rect x="0" y="90" rx="4" ry="4" width="100%" height="1" />
        <rect x="0" y="110" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="140" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="170" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="200" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="230" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="260" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="290" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="320" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="350" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="380" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="410" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="440" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="470" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="500" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="530" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="560" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="590" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="620" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="650" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="690" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="720" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="750" rx="4" ry="4" width="100" height="15" />
        <rect x="0" y="780" rx="4" ry="4" width="100" height="15" />

        <rect x="150" y="110" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="140" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="170" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="200" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="230" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="260" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="290" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="320" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="350" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="380" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="410" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="440" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="470" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="500" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="530" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="560" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="590" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="620" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="650" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="690" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="720" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="750" rx="4" ry="4" width="300" height="15" />
        <rect x="150" y="780" rx="4" ry="4" width="300" height="15" />
      </ContentLoader>
    </>
  );
};

export default OrderDetailsSkeleton;
