import { FC, useEffect, useMemo } from "react";
import { useInView } from "react-intersection-observer";

import { Icon } from "components/base";
import { txStatusInfo } from "utils/constants";
import { TransactionNotificationItem } from "./Notification.props";
import { eventBus } from "utils/helpers";

const NotificationItem: FC<TransactionNotificationItem> = ({
  transaction,
  socket,
  role,
}): JSX.Element => {
  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && socket) {
      eventBus.dispatch("notification_watched", transaction.id);
    }
  }, [inView, transaction, socket]);

  const renderHref = useMemo(() => {
    if (transaction.type === "order") {
      return `/orders/buy-flow/details/${transaction.id}`;
    }

    if (transaction.type === "warning") {
      return `/users/details/${transaction.id}`;
    }

    if (transaction.type === "info") {
      if (role === "partner") {
        return "/balance";
      }

      return `/partners/details/${transaction.partner_id}?balance=true`;
    }
  }, [transaction, role]);

  return (
    <li
      className={!transaction.isWatched ? "unwatched" : "watched"}
      ref={ref}
      key={transaction.id}
    >
      {txStatusInfo[transaction?.status]?.icon && (
        <div
          className={`icon__wrapper ${
            txStatusInfo[transaction?.status]?.className
          }`}
        >
          <Icon name={txStatusInfo[transaction?.status]?.icon} />
        </div>
      )}

      <a href={renderHref} className="tr-status__wrapper">
        <span>
          {transaction.type === "order" && "Transaction"}{" "}
          {transaction?.title ?? transaction?.status}
        </span>
        <span>{transaction.id}</span>
      </a>
    </li>
  );
};

export default NotificationItem;
