import React, {
  createContext,
  useContext,
  useCallback,
  useReducer,
} from "react";
import { useWidgetFiltersState } from "providers/WidgetFilter";

import { assetInstance, sellFlowInstance } from "utils/api";
import {
  State,
  Dispatch,
  DispatchContext,
  ActionType,
  CryptoProps,
  FiatProps,
  FireblockProps,
  GetCryptoType,
  GetFireblockType,
  GetFiatType,
} from "./types";
import reducer from "./reducers";

const initialState = {
  isLoading: false,
  activeTab: "crypto_list",
  crypto: [],
  fireblockList: [],
  fiatList: [],
  cryptoCount: null,
  fiatCount: null,
  fireblockCount: null,
  chosenFireblock: null,
  chosenFiat: null,
  page: 1,
};

const WidgetConsoleStateContext = createContext<State>(initialState);
const WidgetConsoleDispatchContext = createContext<Dispatch | undefined>(
  undefined
);

const WidgetConsoleProvider = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <WidgetConsoleStateContext.Provider value={state}>
      <WidgetConsoleDispatchContext.Provider value={dispatch}>
        {children}
      </WidgetConsoleDispatchContext.Provider>
    </WidgetConsoleStateContext.Provider>
  );
};

export const useWidgetConsoleState = (): State => {
  const context = useContext(WidgetConsoleStateContext);

  if (typeof context === "undefined") {
    throw new Error(
      "useClientStateContext must be used within a ClientProvider"
    );
  }

  return context;
};

export const useWidgetConsoleDispatch = (): DispatchContext => {
  const dispatch = useContext(WidgetConsoleDispatchContext);
  const {
    core,
    code,
    symbol,
    markup_rate,
    is_suspended,
    fireblock_name,
    fireblock_code,
    fireblock_native_asset,
    fireblock_type,
    fiat_is_suspended,
    fiat_name,
    fiat_symbol,
  } = useWidgetFiltersState();

  if (typeof dispatch === "undefined") {
    throw new Error(
      "useClientDispatchContext must be used within a ClientProvider"
    );
  }

  const getCrypto: GetCryptoType = useCallback(
    async (page = 1, core, code, symbol, markup_rate, is_suspended) => {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      const params: {
        page: number;
        limit: number;
        core?: string;
        code?: string;
        symbol?: string;
        markup_rate?: number;
        is_suspended?: boolean | undefined;
      } = {
        page,
        limit: 10,
      };

      if (core) params.core = core;
      if (code) params.code = code;
      if (symbol) params.symbol = symbol;
      if (markup_rate) params.markup_rate = markup_rate;
      if (typeof is_suspended !== "undefined")
        params.is_suspended = is_suspended;

      try {
        const { data } = await assetInstance.get(`/crypto/list`, {
          params,
        });

        dispatch({ type: ActionType.SET_CRYPTO, payload: data?.rows });
        dispatch({ type: ActionType.SET_CRYPTO_COUNT, payload: data?.count });
        dispatch({ type: ActionType.SET_LOADING, payload: false });

        return;
      } catch (error) {
        console.log(error);
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    },
    [dispatch]
  );

  const getFireblockList: GetFireblockType = useCallback(
    async (
      page = 1,
      fireblock_name,
      fireblock_code,
      fireblock_type,
      fireblock_native_asset
    ) => {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      const params: {
        page: number;
        limit: number;
        name?: string;
        code?: string;
        type?: string;
        nativeAsset?: string;
      } = {
        page,
        limit: 10,
      };

      if (fireblock_name) params.name = fireblock_name;
      if (fireblock_code) params.code = fireblock_code;
      if (fireblock_type) params.type = fireblock_type;
      if (fireblock_native_asset) params.nativeAsset = fireblock_native_asset;

      try {
        const { data } = await assetInstance.get(`/fireblock/list`, {
          params,
        });

        dispatch({ type: ActionType.SET_FIREBLOCK, payload: data?.rows });
        dispatch({
          type: ActionType.SET_FIREBLOCK_COUNT,
          payload: data?.count,
        });
        dispatch({ type: ActionType.SET_LOADING, payload: false });

        return;
      } catch (error) {
        console.log(error);
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    },
    [dispatch]
  );

  const getFiatList: GetFiatType = useCallback(
    async (page = 1, fiat_name, fiat_symbol, fiat_is_suspended) => {
      dispatch({ type: ActionType.SET_LOADING, payload: true });

      const params: {
        page: number;
        limit: number;
        name?: string;
        symbol?: string;
        is_suspended?: boolean;
      } = {
        page,
        limit: 10,
      };

      if (fiat_name) params.name = fiat_name;
      if (fiat_symbol) params.symbol = fiat_symbol;
      if (typeof fiat_is_suspended !== "undefined")
        params.is_suspended = fiat_is_suspended;

      try {
        const { data } = await assetInstance.get(`/fiat/list`, {
          params,
        });

        dispatch({ type: ActionType.SET_FIAT, payload: data?.rows });
        dispatch({ type: ActionType.SET_FIAT_COUNT, payload: data?.count });
        dispatch({ type: ActionType.SET_LOADING, payload: false });

        return;
      } catch (error) {
        console.log(error);
        dispatch({ type: ActionType.SET_LOADING, payload: false });
      }
    },
    [dispatch]
  );

  const setActiveTab = (value: string) =>
    dispatch({ type: ActionType.SET_ACTIVE_TAB, payload: value });

  const setChosenFireblock = (fireblock) =>
    dispatch({ type: ActionType.SET_CHOSEN_FIREBLOCK, payload: fireblock });

  const setChosenFiat = (fiat) => {
    dispatch({ type: ActionType.SET_CHOSEN_FIAT, payload: fiat });
  };

  const createCrypto = async ({
    address_regex,
    code,
    name,
    native_asset,
    symbol,
    core,
    testnet_address_regex,
    markup_rate,
  }: {
    address_regex: string;
    code: string;
    name: string;
    native_asset: string;
    symbol: string;
    core: string;
    testnet_address_regex: string;
    markup_rate: number;
  }) => {
    try {
      const newCrypto = await assetInstance.post("/crypto", {
        address_regex,
        code,
        name,
        native_asset,
        symbol,
        core,
        testnet_address_regex,
        markup_rate,
      });

      return newCrypto.data;
    } catch (error) {
      console.log(error);
      dispatch({ type: ActionType.SET_LOADING, payload: false });
    }
  };

  const paginate = ({ page, type }: { page: number; type: string }) => {
    switch (type) {
      case "crypto":
        getCrypto(page, core, code, symbol, markup_rate, is_suspended);
        dispatch({ type: ActionType.SET_PAGE, payload: page });

        return;
      case "fireblock":
        getFireblockList(
          page,
          fireblock_name,
          fireblock_code,
          fireblock_type,
          fireblock_native_asset
        );
        dispatch({ type: ActionType.SET_PAGE, payload: page });

        return;
      case "fiat":
        getFiatList(page, fiat_name, fiat_symbol, fiat_is_suspended);
        dispatch({ type: ActionType.SET_PAGE, payload: page });

        return;
      default:
        return;
    }
  };

  const updateCryptoStatus = async ({
    id,
    isSuspended,
    address_regex,
    symbol,
    testnet_address_regex,
    code,
    core,
    name,
    native_asset,
    markup_rate,
  }: {
    id: string;
    isSuspended: boolean;
    address_regex: string;
    symbol: string;
    testnet_address_regex: string;
    code: string;
    core: string;
    name: string;
    native_asset: string;
    markup_rate: number;
  }) => {
    try {
      const { data } = await assetInstance.patch("/crypto/update_status", {
        id,
        is_suspended: isSuspended,
        address_regex,
        symbol,
        testnet_address_regex,
        code,
        core: core ?? "",
        name,
        native_asset,
        markup_rate,
      });

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const updateFiatStatus = async ({
    id,
    isSuspended,
    max_amount,
    max_buy_amount,
    min_amount,
    min_buy_amount,
  }: {
    id: string;
    isSuspended: boolean;
    max_amount: number;
    max_buy_amount?: number;
    min_amount: number;
    min_buy_amount?: number;
  }) => {
    try {
      const { data } = await assetInstance.patch("/fiat/update_status", {
        id,
        is_suspended: isSuspended,
        max_amount,
        max_buy_amount,
        min_amount,
        min_buy_amount,
      });

      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const getCryptoById = async (
    id: string,
    flow: string
  ): Promise<CryptoProps | undefined> => {
    const token = localStorage.getItem("token");

    try {
      if (flow === "sell-flow") {
        const { data } = await sellFlowInstance.get(`/crypto/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return data;
      }

      if (flow === "buy-flow") {
        const { data } = await assetInstance.get(`/crypto/${id}`);

        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFiatById = async (
    id: string,
    flow: string
  ): Promise<FiatProps | undefined> => {
    const token = localStorage.getItem("token");

    try {
      if (flow === "sell-flow") {
        const { data } = await sellFlowInstance.get(`/fiat/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return data;
      }

      if (flow === "buy-flow") {
        const { data } = await assetInstance.get(`/fiat/${id}`);

        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFireblockById = async (
    id: string,
    flow: string
  ): Promise<FireblockProps | undefined> => {
    const token = localStorage.getItem("token");

    try {
      if (flow === "sell-flow") {
        const { data } = await sellFlowInstance.get(`/fireblock/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        return data;
      }

      if (flow === "buy-flow") {
        const { data } = await assetInstance.get(`/fireblock/${id}`);

        return data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setLoading = (isLoading: boolean) =>
    dispatch({ type: ActionType.SET_LOADING, payload: isLoading });

  const resetCount = (countType: string) => {
    switch (countType) {
      case "crypto_list":
        dispatch({ type: ActionType.SET_CRYPTO_COUNT, payload: 0 });

        return;
      case "fireblock_list":
        dispatch({ type: ActionType.SET_FIREBLOCK_COUNT, payload: 0 });

        return;
      case "fiat_list":
        dispatch({ type: ActionType.SET_FIAT_COUNT, payload: 0 });

        return;
      default:
        return null;
    }
  };

  const resetWidgetFilters = () => {
    dispatch({ type: ActionType.RESET_WIDGET_FILTERS });

    return;
  };

  const setPage = (page: number) =>
    dispatch({ type: ActionType.SET_PAGE, payload: page });

  return {
    getCrypto,
    getFireblockList,
    getFiatList,
    setActiveTab,
    setChosenFireblock,
    setChosenFiat,
    createCrypto,
    getCryptoById,
    getFireblockById,
    getFiatById,
    updateCryptoStatus,
    updateFiatStatus,
    paginate,
    setLoading,
    resetCount,
    resetWidgetFilters,
    setPage,
  };
};

export default WidgetConsoleProvider;
