import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { useBitcoinCore } from "providers/BitcoinCore";

const BitcoinSwitcher: React.FC = () => {
  const [checkedActiveBitcoinCore, setCheckedActiveBitcoinCore] =
    useState(false);

  const { bitcoinState, updateConfig } = useBitcoinCore();

  const handleChangeBitcoinCore = (
    nextCheckedActive: boolean | ((prevState: boolean) => boolean)
  ) => {
    updateConfig(nextCheckedActive ? "enabled" : "disabled");
    setCheckedActiveBitcoinCore(nextCheckedActive);
  };

  useEffect(() => {
    setCheckedActiveBitcoinCore(
      bitcoinState?.settings?.find((i) => i.name === "bcore")?.value ===
        "enabled"
        ? true
        : false
    );
  }, [bitcoinState]);

  return (
    <>
      <div className="card-list-content-row-body">
        <Switch
          onColor={"#0570DE"}
          offColor={"#EFF2F5"}
          uncheckedIcon={false}
          checkedIcon={false}
          onChange={handleChangeBitcoinCore}
          checked={checkedActiveBitcoinCore}
          className={
            checkedActiveBitcoinCore
              ? "react-switch active"
              : "react-switch false"
          }
        />
      </div>
    </>
  );
};

export default BitcoinSwitcher;
