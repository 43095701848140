import { useState } from "react";
import { useBalanceState } from "providers/Balance";

import { Icon } from "components/base";
import Button from "components/Button";
import Tooltip from "components/Tooltip";
import { copyToClipboard } from "utils/helpers";
import BalanceQR from "./BalanceQR";
import QRPopup from "./QRPopup";

const BalanceWallet = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const { walletAddress } = useBalanceState();

  return (
    <div className="balance-wallet__container">
      <Button
        className="balance-wallet__qr-trigger"
        type="button"
        onClick={(e) => {
          e?.stopPropagation();
          setIsOpen(true);
        }}
      >
        <BalanceQR walletAddress="1A1zP1eP5QGefi2DMPTfTL5SLmv7DivfNa" />
      </Button>
      <div className="balance-wallet__content">
        <h1>Your wallet address in Swaps</h1>
        <div className="balance-wallet__address-wrapper">
          <span>{walletAddress}</span>
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button clipboard-gray"
                onClick={() => {
                  copyToClipboard(walletAddress as string);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </div>
      </div>
      <QRPopup
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        walletAddress={walletAddress as string}
      />
    </div>
  );
};

export default BalanceWallet;
