import { FC, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ContentLoader from "react-content-loader";

import TwoFactorAuthentication from ".";
import { instance } from "utils/api";

const Loader = (): JSX.Element => {
  return (
    <ContentLoader
      width="150"
      height="150"
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="4" ry="0" width="150" height="150" />
    </ContentLoader>
  );
};

const GoogleAuth: FC = () => {
  const history = useHistory();
  const [qrDataImage, setQrDataImage] = useState("");
  const onButtonClick = () =>
    history.push("/code-verification?two-factor-auth=google");

  useEffect(() => {
    (async function () {
      const { data } = await instance.post("admin/google-2fa/setup");
      setQrDataImage(data.qrCode);
    })();
  }, []);

  return (
    <TwoFactorAuthentication>
      <div className="tfa-card">
        <div className="tfa-card-header">
          <h6 className="tfa-card-header-title">Register Swaps.app</h6>
          <p className="tfa-card-header-description">
            Open to Google Authenticator and scan this QR code.
          </p>
        </div>
        {qrDataImage ? (
          <img width={150} height={150} src={qrDataImage} alt="" />
        ) : (
          <Loader />
        )}
        <p className="tfa-card-header-description">
          Once Swaps.app is registered, you&apos;ll start seeing 6-digit
          verification codes in the app.
        </p>
        <div className="tfa-card-footer">
          <div className="tfa-card-footer-btn">
            <button
              onClick={onButtonClick}
              type="submit"
              id="kt_sign_in_submit"
              className="btn btn-lg btn-primary w-100"
            >
              <span className="indicator-label">Continue</span>
            </button>
          </div>
        </div>
      </div>
    </TwoFactorAuthentication>
  );
};

export default GoogleAuth;
