import { useCallback, useEffect, useState } from "react";
import {
  setEventTypes,
  setSelectedEvents,
  setWebhookEndpoint,
} from "providers/Webhooks/actions";
import { useWebhookDispatch, useWebhookState } from "providers/Webhooks";

import WebhookCreateForm from "./WebhookCreateForm";
// import Header from "./WebhookCreateHeader";
import "./webhook-create.scss";
import SelectEvent from "./SelectEvent";
import { instance } from "utils/api";
import { EventTypes } from "./SelectEvent/SelectEvent.props";
import {
  ProfileDataResponse,
  ProfileEventType,
  ProfileTypes,
} from "pages/Profile/type";
import WebhookCreateFormSkeleton from "./WebhookCreateFormSkeleton";

const WebhookCreate = (): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [profileData, setProfileData] = useState<ProfileTypes>();
  const [showSelectEvent, setShowSelectEvent] = useState(false);
  const { eventTypes } = useWebhookState();
  const webhookDispatch = useWebhookDispatch();

  const activateSelectEvent = () => setShowSelectEvent(true);

  const getProfileData = useCallback(async () => {
    try {
      const response = await instance.get<ProfileDataResponse>(
        "/admin/profile"
      );

      if (response.data.data?.events?.length) {
        const reducedEvents = response.data.data.events.reduce(
          (acc: string[], curr: ProfileEventType) => {
            acc = [...acc, curr.events_partner.event_id];

            return acc;
          },
          []
        );

        await webhookDispatch(
          setWebhookEndpoint(response.data.data.callback_success as string)
        );
        setTimeout(
          () => webhookDispatch(setSelectedEvents(reducedEvents)),
          100
        );
      }

      return setProfileData(response.data.data);
    } catch (e) {
      console.log(e);
    }
  }, [webhookDispatch]);

  const fetchEventTypes = useCallback(async () => {
    try {
      const { data } = await instance.get("/events/types");

      if (data?.data) {
        const events = data.data.reduce((acc, curr) => {
          const key = curr.slug.split("_")[0];
          const ifExists = acc[key];

          if (ifExists) {
            acc = {
              ...acc,
              [key]: {
                count: acc[key].count + 1,
                items: [...acc[key].items, curr],
              },
            };
          } else {
            acc = {
              ...acc,
              [key]: {
                count: 1,
                items: [curr],
              },
            };
          }

          return acc;
        }, {});

        webhookDispatch(setEventTypes(events));
      }

      return;
    } catch (error) {
      console.log(error);
    }
  }, [webhookDispatch]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await getProfileData();
      await fetchEventTypes();
      setLoading(false);
    })();
  }, [fetchEventTypes, getProfileData]);

  return (
    <>
      {/* <Header /> */}
      {showSelectEvent ? (
        <SelectEvent
          eventTypes={eventTypes as EventTypes}
          setShowSelectEvent={setShowSelectEvent}
        />
      ) : (
        <div className="webhook-create__container">
          <h1 className="webhook-create__heading">Listen to Swaps events</h1>
          <p className="webhook-create__text">
            Set up your webhook endpoint to receive live events from Swaps
          </p>
          {loading ? (
            <WebhookCreateFormSkeleton />
          ) : (
            <WebhookCreateForm
              profileData={profileData as ProfileTypes}
              activateSelectEvent={activateSelectEvent}
            />
          )}
        </div>
      )}
    </>
  );
};

export default WebhookCreate;
