import { useCallback, useEffect, useState } from "react";

import { BankResType, GetBankType } from "pages/Settings/types";
import { instance } from "utils/api";

const useGetBank = (): GetBankType => {
  const [bankResponse, setBankResponse] = useState<BankResType>({});

  const changeBank = useCallback(
    (alias: string | undefined): Promise<string> =>
      instance
        .patch("/settings/payment", {
          value: alias,
        })
        .then((res) => res?.data?.result),
    []
  );

  useEffect(() => {
    instance.get("/settings/payment").then((date) => {
      setBankResponse(date?.data);
    });
  }, []);

  const defaultVal = (value: string) => {
    return { label: value, value: "trust" };
  };

  return { bankResponse, changeBank, defaultVal };
};

export default useGetBank;
