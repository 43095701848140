import { useCallback, useEffect, useMemo, useState } from "react";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";
import {
  set_address,
  set_amountFrom,
  set_amountTo,
  set_assetOrder,
  set_billing_country,
  set_checkedIDOrder,
  set_currencyOrder,
  set_customerId,
  set_email,
  set_filteringOrder,
  set_orderDateFrom,
  set_orderDateTo,
  set_orderFilters,
  set_orderID,
  set_orderPage,
  set_status,
} from "providers/OrderFilter/ordersAction";
import { set_checked_pages } from "providers/ClientFilter/clientsActions";
// import { useOrderDispatchContext, useOrderState } from "providers/Orders";

// import Filter from "components/Filter";

import FilterList from "components/Filter/FilterList";
import {
  FilterTreeItem,
  GetListProps,
  OrderFilterWrapper,
} from "./OrderFilterWrapper.props";
import {
  // email_regex,
  // ordersFilterTree,
  sellOrdersSecondFilterTree,
  sellOrdersFilterTree,
  uuid_regex,
  email_regex,
  sellTransactionStatuses,
  // uuid_regex,
} from "utils/constants";
import { FilterListItem } from "components/Filter/FilterList/FilterList.props";
import FilterListSkeleton from "components/FilterListSkeleton";
import Filter from "components/Filter";

const SellOrderFiltersWrapper = ({
  setLoading,
  getFiatList,
  getAssetList,
}: OrderFilterWrapper): JSX.Element => {
  const [secondLevelFilter, setSecondLevelFilter] = useState<FilterTreeItem[]>(
    []
  );

  const [additionalFilters, setAdditionalFilter] = useState<
    [] | FilterListItem[] | undefined
  >([]);

  const orderDispatch = useOrderDispatch();

  const {
    email,
    dateToOrder,
    amountFrom,
    amountTo,
    dateFromOrder,
    assetOrder,
    currencyOrder,
    filters,
    statusId,
    orderSearch,
    orderID,
  } = useOrderFilters();

  const handleFirstLevelFilter = (selectedOption) => {
    setLoading(true);

    if (selectedOption === "ALL") {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_status(undefined));
      orderDispatch(set_orderFilters([]));
      orderDispatch(set_orderPage(1));
      orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
      orderDispatch(set_checked_pages([]));
      orderDispatch(set_filteringOrder(true));

      return;
    }

    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_status(selectedOption));
    orderDispatch(set_orderPage(1));
    orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
    orderDispatch(set_checked_pages([]));
    orderDispatch(set_filteringOrder(true));
  };

  const handleAmount = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void,
    amountType?: string | undefined
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (amountType === "from") {
      orderDispatch(
        set_amountFrom((e.target.value === "" && undefined) || e.target.value)
      );
    }

    if (amountType === "to") {
      orderDispatch(
        set_amountTo((e.target.value === "" && undefined) || e.target.value)
      );
    }

    orderDispatch(set_orderPage(1));
    setIsValid(true);
    orderDispatch(set_filteringOrder(false));

    return;
  };

  const handleFiat = (e, setError, setIsValid) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_currencyOrder([e.target.value]));
    orderDispatch(set_orderPage(1));
    setIsValid(true);
    setTimeout(() => {
      clearOrder();
    }, 500);
  };

  const handleAsset = (e, setError, setIsValid) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_assetOrder([e.target.value]));
    orderDispatch(set_orderPage(1));
    setIsValid(true);
    setTimeout(() => {
      clearOrder();
    }, 500);
  };

  const handleOrderID = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim().match(uuid_regex)?.length) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_orderID(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("User id is invalid");
  };

  const handleBillingCountry = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim().length) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_billing_country(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("Country is invalid");
  };

  const handleEmail = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim().match(email_regex)?.length) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_email(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("Email is invalid");

    return;
  };

  const handleDate = useCallback(
    ({ dateFrom, dateTo }) => {
      orderDispatch(set_orderDateFrom(dateFrom));
      orderDispatch(set_orderDateTo(dateTo));
      orderDispatch(set_orderPage(1));
      orderDispatch(set_filteringOrder(false));
    },
    [orderDispatch]
  );

  const handleTrStatus = (e, setError, setIsValid) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_status(sellTransactionStatuses[e.target.value]));
    document.dispatchEvent(new Event("reset"));
    orderDispatch(set_orderPage(1));
    setIsValid(true);
    setTimeout(() => {
      clearOrder();
    }, 500);
  };

  const handleCustomerID = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim()) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_customerId(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("User id is invalid");
  };

  const handleAddress = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim()) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_address(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("User id is invalid");
  };

  const clearOrder = () => {
    orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
    orderDispatch(set_checked_pages([]));
  };

  const secondLevelHandlers = [
    handleAmount,
    handleFiat,
    handleAsset,
    handleOrderID,
    handleBillingCountry,
    handleEmail,
    handleDate,
    handleTrStatus,
    handleCustomerID,
    handleAddress,
  ];

  const handleApply = (type?: string) => {
    orderDispatch(set_filteringOrder(true));

    if (type === "date_picker" || type === "number_range") {
      setTimeout(() => {
        clearOrder();
      }, 500);
    }
  };

  const enableClearFilters = useMemo(() => {
    if (
      orderID ||
      orderSearch ||
      dateToOrder ||
      email ||
      amountFrom ||
      amountTo ||
      dateFromOrder ||
      statusId ||
      assetOrder?.length ||
      currencyOrder?.length ||
      additionalFilters?.length
    ) {
      return true;
    }

    return false;
  }, [
    orderID,
    email,
    orderSearch,
    dateToOrder,
    amountFrom,
    amountTo,
    dateFromOrder,
    assetOrder,
    currencyOrder,
    additionalFilters,
    statusId,
  ]);

  const additionaHandlers = [];

  const clearAdditionalSearches = [];

  const resetAmount = (clearReset = false, context = false) => {
    if (context) {
      orderDispatch(set_amountFrom(""));
      orderDispatch(set_amountTo(""));
      orderDispatch(set_orderPage(1));

      return;
    }

    if (clearReset && !amountFrom && !amountTo) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_amountFrom(""));
    orderDispatch(set_amountTo(""));
    orderDispatch(set_orderPage(1));
    handleApply();

    return;
  };

  const resetFiat = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_currencyOrder(undefined));
    }

    if (clearReset && !currencyOrder?.length) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_currencyOrder(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetAsset = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_assetOrder(undefined));
    }

    if (clearReset && !assetOrder?.length) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_assetOrder(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetOrderId = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_orderID(""));
    }

    if (clearReset && !orderSearch) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_orderID(""));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetBillingCountry = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_billing_country(""));
    }

    if (clearReset && !orderSearch) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_billing_country(""));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetOrderEmail = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_email(undefined));
    }

    if (clearReset && !email) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_email(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetDate = (clearReset = false, context = false) => {
    if (context) {
      return;
    }

    if (clearReset && !dateFromOrder && !dateToOrder) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_orderDateFrom(null));
    orderDispatch(set_orderDateTo(null));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetTrStatus = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_status(undefined));
    }

    if (clearReset && !assetOrder?.length) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_status(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const clearSearches = [
    resetAmount,
    resetFiat,
    resetAsset,
    resetOrderId,
    resetBillingCountry,
    resetOrderEmail,
    resetDate,
    resetTrStatus,
  ];

  const reduceFilterTree = useMemo(async () => {
    const fiatList = (await getFiatList()) as unknown as GetListProps;
    const assetList = (await getAssetList()) as unknown as GetListProps;

    const fiatMap = fiatList?.rows.map((fiat) => ({
      label: fiat.symbol,
      value: fiat.symbol,
    }));

    const assetMap = assetList?.rows.map((asset) => {
      return {
        label: `${asset.symbol} ${
          asset?.core && typeof asset?.core === "string"
            ? "- " + asset.core
            : ""
        }`,
        value: asset.code as string,
      };
    });

    const reducedFilterTree = sellOrdersSecondFilterTree.reduce(
      (acc: FilterTreeItem[], curr: FilterTreeItem) => {
        if (curr.value === "FIAT") {
          curr = {
            ...curr,
            selectOptions: curr?.selectOptions && [
              ...curr?.selectOptions,
              ...fiatMap,
            ],
          };
        }

        if (curr.value === "ASSET") {
          curr = {
            ...curr,
            selectOptions: curr?.selectOptions && [
              ...curr?.selectOptions,
              ...assetMap,
            ],
          };
        }

        // if (
        //   curr.value === "TRANSACTION_STATUS" &&
        //   transactionStatuses?.length
        // ) {
        //   curr = {
        //     ...curr,
        //     selectOptions: [
        //       ...transactionStatuses.map((item) => ({
        //         value: item,
        //         label: item.substr(0, 1).toUpperCase() + item.substr(1),
        //       })),
        //     ],
        //   };
        // }

        return [...acc, curr];
      },
      []
    );

    setSecondLevelFilter(reducedFilterTree);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    reduceFilterTree;
  }, [reduceFilterTree]);

  return (
    <>
      <Filter
        handleFirstLevelFilter={handleFirstLevelFilter}
        filterValues={sellOrdersFilterTree}
        filters={filters}
      />
      {secondLevelFilter?.length ? (
        <FilterList
          secondLevelHandlers={secondLevelHandlers}
          secondLevelFilter={secondLevelFilter}
          handleApply={handleApply}
          enableClearFilters={enableClearFilters}
          clearSearches={clearSearches}
          additionalFilters={additionalFilters}
          setAdditionalFilters={setAdditionalFilter}
          additionalHandlers={additionaHandlers}
          clearAdditionalSearches={clearAdditionalSearches}
          clearSelect={clearOrder}
        />
      ) : (
        <FilterListSkeleton />
      )}
    </>
  );
};

export default SellOrderFiltersWrapper;
