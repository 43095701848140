import React, { useState } from "react";
import { useClientDispatch, useClientFilters } from "providers/ClientFilter";
import {
  set_arrayChecking,
  set_clientIdAction,
  set_exportedClient,
  set_filteringButton,
  set_selectedClient,
} from "providers/ClientFilter/clientsActions";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  useClientDispatchContext,
  useClientStateContext,
  // useClientStateContext,
} from "providers/Clients";
import { ClientTier } from "providers/Clients/types";
import RoleProvider, { ROLES } from "providers/RoleProvider";

import { USER_TABLE_ACCESS } from "../../services/roleService/user/user";
import useRole from "hooks/useRole";
import { TableActionButton } from "components/base";
import { intercomURL } from "../../utils/constants";
import { Icon } from "../../components/base";
import { copyToClipboard } from "../../utils/helpers";
import Button from "../../components/Button";
import Tooltip from "components/Tooltip";
import Popover from "components/Popover";
import ClientTierPopover from "./ClientTierPopover";
import ClientLimitPopover from "./ClientLimitPopover";
// import PopoverComponent from "components/Popover";

const tiersQualifications = {
  TIER_0: "Tier 0",
  TIER_1: "Tier 1",
  TIER_2: "Tier 2",
};

const ClientsColumnFields: React.FC<{ client; isLast }> = ({
  client,
  isLast,
}) => {
  const dispatch = useClientDispatch();
  const { clientIdAction, actionComment, exportedClient } = useClientFilters();
  const [isTierOpen, setIsTierOpen] = useState(false);
  const [isLimitOpen, setIsLimitOpen] = useState(false);
  const [tierId, setTierId] = useState(client.tiers[0].id);

  const role = useRole();

  const { updateClientStatus, updateClientTier } = useClientDispatchContext();
  const { tiers } = useClientStateContext();

  const handleCheckClientId = (id) => {
    dispatch(set_exportedClient(id));
    dispatch(set_selectedClient(id));

    dispatch(set_arrayChecking(id));
  };

  return role ? (
    <>
      <td>
        <div>
          <input
            onClick={() => handleCheckClientId(client.id)}
            className={`form-check-input user-list-checkbox`}
            checked={exportedClient?.includes(client.id)}
            type="checkbox"
            value={client.id}
          />
        </div>
      </td>
      {USER_TABLE_ACCESS.email[role] && (
        <td className="user-list-td-black td-email">
          {client.email_verified === false ? (
            <div className="verification-col user-email-no-verifid">
              <Icon name="reject_status" />
              <a
                href={`${intercomURL}${client.email}`}
                target="_blank"
                rel="noreferrer"
              >
                {client.email}
              </a>
            </div>
          ) : (
            <div className="verification-col user-email">
              <Icon name="success_status" />
              <a href={`/users/details/${client.id}`} rel="noreferrer">
                {client.email}
              </a>
            </div>
          )}
        </td>
      )}
      {USER_TABLE_ACCESS.email_clipboard[role] && (
        <td className="user-list-td-black user-email">
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(client.email);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </td>
      )}
      {USER_TABLE_ACCESS.registration_country[role] && (
        <td>{client.country}</td>
      )}
      {USER_TABLE_ACCESS.full_name[role] && (
        <td className="user-list-td-grey">
          <div>{client.name ? `${client.name} ${client.surname}` : "-"}</div>
        </td>
      )}
      {USER_TABLE_ACCESS.id[role] && (
        <td className="table-id-link">
          <div>
            <Link to={`/users/details/${client.id}`}>{`${client.id.substr(
              0,
              4
            )}...${client.id.substr(
              client.id.length - 4,
              client.id.length
            )}`}</Link>
          </div>
        </td>
      )}
      {USER_TABLE_ACCESS.id_clipboard[role] && (
        <td className="td-clipboard">
          <Tooltip content="Copied">
            {(setShowTooltip) => (
              <Button
                type="button"
                className="clipboard-button"
                onClick={() => {
                  copyToClipboard(client.id);
                  setShowTooltip(true);
                }}
              >
                <Icon name="clipboard" />
              </Button>
            )}
          </Tooltip>
        </td>
      )}
      {USER_TABLE_ACCESS.kyc[role] && (
        <td>
          {client.kyc === "VERIFIED" ? (
            <a href={client.sumsub_url} target="_blank" rel="noreferrer">
              <div className="verification-col user-kyc-verifid">
                <Icon name="success_status" />
                <span>Verified</span>
              </div>
            </a>
          ) : client.kyc === "NOT_VERIFY" ? (
            <div className="verification-col user-kyc-no-verifid">
              <Icon name="warning_check" />
              <span>Not Verified</span>
            </div>
          ) : (
            <a href={client.sumsub_url} target="_blank" rel="noreferrer">
              <div className="verification-col user-kyc-no-verifid">
                <Icon name="reject_status" />
                <span>Declined</span>
              </div>
            </a>
          )}
        </td>
      )}
      {USER_TABLE_ACCESS.tier[role] && (
        <td className="td-tier">
          <div className="tier-badge">
            {
              tiersQualifications[
                client.tiers.sort(
                  (a, b) =>
                    b.qualification.split("_")[1] -
                    a.qualification.split("_")[1]
                )[0].qualification
              ]
            }
          </div>
        </td>
      )}
      {USER_TABLE_ACCESS.edit_tier[role] &&
      client.tiers[0].qualification === "TIER_1" ? (
        <td className="td-edit-status edit-tier">
          <RoleProvider roles={[ROLES.SUPER_ADMIN, ROLES.AML]}>
            <Popover
              isPopoverOpen={isTierOpen}
              setIsPopoverOpen={setIsTierOpen}
              onClickOutside={() => {
                setIsTierOpen(false);
                setTierId(client.tiers[0].id);
              }}
              position={["bottom"]}
              className="filter__popover"
              arrowStyle={{ left: "60%" }}
              contentLocation={(popoverState) => {
                return {
                  top: popoverState.nudgedTop,
                  left: popoverState.targetRect.left - 150,
                };
              }}
              component={
                <Button
                  type="button"
                  className="button--transparent button-tr-status"
                  onClick={() => setIsTierOpen(true)}
                >
                  <Icon name="edit_pencil" />
                </Button>
              }
            >
              <ClientTierPopover
                updateTier={(tier_id: string) => {
                  updateClientTier({
                    client_id: client.id,
                    tier_id: tier_id,
                    limit: undefined,
                  });
                  setIsTierOpen(false);
                }}
                tierId={tierId}
                defaultId={client.tiers[0].id}
                setTierId={setTierId}
                items={(tiers as ClientTier[]).slice(1)}
                setIsPopupOpen={setIsTierOpen}
              />
            </Popover>
          </RoleProvider>
        </td>
      ) : (
        <td></td>
      )}
      {USER_TABLE_ACCESS.limit[role] && (
        <td className="td-limit">
          <div className="tier-limit">
            {client.tiers[0]?.ClientTiers.limit ?? "-"}
          </div>
        </td>
      )}
      {USER_TABLE_ACCESS.edit_limit[role] &&
      client.tiers[0].qualification === "TIER_2" ? (
        <td className="td-edit-status edit-tier">
          {/* {client.tiers[0]?.qualification !== tiersQualifications.TIER_0 ? ( */}
          <Popover
            isPopoverOpen={isLimitOpen}
            setIsPopoverOpen={setIsLimitOpen}
            position={["bottom"]}
            className="filter__popover"
            arrowStyle={{ left: "60%" }}
            contentLocation={(popoverState) => {
              return {
                top: popoverState.nudgedTop,
                left: popoverState.targetRect.left - 150,
              };
            }}
            component={
              <Button
                type="button"
                className="button--transparent button-tr-status"
                onClick={() => setIsLimitOpen(true)}
              >
                <Icon name="edit_pencil" />
              </Button>
            }
          >
            <ClientLimitPopover
              updateLimit={(limit: number) => {
                updateClientTier({
                  client_id: client.id,
                  tier_id: client.tiers[0].id,
                  limit: limit,
                });

                setIsLimitOpen(false);
              }}
              setIsPopoverOpen={setIsLimitOpen}
              limit={client.tiers[0]?.ClientTiers.limit}
            />
          </Popover>
          {/* ) : (
            "-"
          )} */}
        </td>
      ) : (
        <td></td>
      )}
      {USER_TABLE_ACCESS.partner_name[role] && (
        <td className="user-list-td-grey">
          <div>
            <Link to={`partners/details/${client.partner_id}`}>
              {client.partner?.name}
            </Link>
          </div>
        </td>
      )}
      {USER_TABLE_ACCESS.first_name[role] && (
        <td className="user-list-td-grey">
          {client.first_name ? `${client.first_name}` : "-"}
        </td>
      )}
      {USER_TABLE_ACCESS.last_name[role] && (
        <td className="user-list-td-grey">
          {client.last_name ? `${client.last_name}` : "-"}
        </td>
      )}
      {USER_TABLE_ACCESS.middle_name[role] && (
        <td className="user-list-td-grey">
          {client.middle_name ? `${client.middle_name}` : "-"}
        </td>
      )}
      {USER_TABLE_ACCESS.date_of_birth[role] && (
        <td className="user-list-td-grey">
          {client.date_of_birth
            ? moment(client.date_of_birth).format("DD/MM/yyyy")
            : ""}
        </td>
      )}
      {USER_TABLE_ACCESS.residence_address[role] && (
        <td className="user-list-td-grey">
          {client.residence_address?.countries?.country || "-"}
        </td>
      )}
      {USER_TABLE_ACCESS.residence_address[role] && (
        <td className="user-list-td-grey">
          {client.residence_address
            ? `
            ${
              client.residence_address?.address
                ? client.residence_address.address + ","
                : ""
            }
            ${
              client.residence_address?.city
                ? client.residence_address.city + ","
                : ""
            }
            ${
              client.residence_address?.state
                ? client.residence_address.state + " "
                : ""
            }
            ${
              client.residence_address?.post_code
                ? client.residence_address.post_code
                : ""
            }
          `
            : "-"}
        </td>
      )}
      {USER_TABLE_ACCESS.billing_address[role] && (
        <td className="user-list-td-grey">
          {client.billing_address?.countries?.country || "-"}
        </td>
      )}
      {USER_TABLE_ACCESS.billing_address[role] && (
        <td className="user-list-td-grey">
          {client?.billing_address
            ? `
            ${
              client.billing_address?.address
                ? client.billing_address.address + ","
                : ""
            }
            ${
              client.billing_address?.city
                ? client.billing_address.city + ","
                : ""
            }
            ${
              client.billing_address?.state
                ? client.billing_address.state + " "
                : ""
            }
            ${
              client.billing_address?.post_code
                ? client.billing_address.post_code
                : ""
            }
          `
            : "-"}
        </td>
      )}
      {client.delete_email ? (
        <td>
          <div className="user-status-td user-status-blocked">
            Deleted <Icon name="reject_status" />
          </div>
        </td>
      ) : (
        USER_TABLE_ACCESS.status[role] && (
          <td>
            {
              {
                active: (
                  <div className="user-status-td user-status-active">
                    Active <Icon name="success_check" />
                  </div>
                ),
                blocked: (
                  <div className="user-status-td user-status-blocked">
                    Blocked <Icon name="reject_status" />
                  </div>
                ),
                panding: (
                  <div className="user-status-td user-status-panding">
                    Pending <Icon name="clock" />
                  </div>
                ),
              }[client.status || "active"]
            }
          </td>
        )
      )}
      {USER_TABLE_ACCESS.createdAt[role] && (
        <td>
          <div>{moment(client.createdAt).format("DD MMM yyyy, hh:mm")}</div>
        </td>
      )}
      <td onClick={() => dispatch(set_clientIdAction(client.id))}>
        <TableActionButton
          isLast={isLast}
          viewLink={`/users/details/${client.id}`}
          buttons={
            !client.delete_email
              ? [
                  {
                    userName: client.name
                      ? `${client.name} ${client.surname}`
                      : "-",
                    title: client.status === "blocked" ? "Unblock" : "Block",
                    onClick: async () => {
                      dispatch(set_filteringButton(false));
                      await updateClientStatus(
                        clientIdAction,
                        client.status === "blocked" ? "active" : "blocked",
                        actionComment
                      );
                      dispatch(set_filteringButton(true));
                    },
                    role: "user",
                  },
                ]
              : undefined
          }
        />
      </td>
    </>
  ) : (
    <></>
  );
};

export default ClientsColumnFields;
