import {
  useWidgetFiltersState,
  useWidgetFiltesDispatch,
} from "providers/WidgetFilter";
import React, { useMemo } from "react";
import {
  setFireblockCode,
  setFireblockName,
  setFireblockNativeAsset,
  setFireblockType,
  setTriggerFilter,
} from "providers/WidgetFilter/widgetFilterAction";

import FilterList from "components/Filter/FilterList";
import { fireblockSecondFilterTree } from "utils/constants";

const FireblockFiltersWrapper = ({
  setPage,
}: {
  setPage: (page: number) => void;
}): JSX.Element => {
  const dispatch = useWidgetFiltesDispatch();
  const {
    fireblock_name,
    fireblock_code,
    fireblock_type,
    fireblock_native_asset,
  } = useWidgetFiltersState();

  const filterByName = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      setPage(1);
      dispatch(setFireblockName(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterByCode = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      setPage(1);
      dispatch(setFireblockCode(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterByType = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      setPage(1);
      dispatch(setFireblockType(e.target.value));
      setIsValid(false);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const filterByNativeAsset = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value) {
      setPage(1);
      dispatch(setFireblockNativeAsset(e.target.value));
      setIsValid(true);
      setError("");

      return;
    }

    setIsValid(false);
    setError("");
  };

  const secondLevelHandlers = [
    filterByName,
    filterByCode,
    filterByType,
    filterByNativeAsset,
  ];

  const handleApply = () => {
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);
  };

  const resetFireblockName = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFireblockName(""));
    }

    setPage(1);
    dispatch(setFireblockName(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetFireblockCode = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFireblockCode(""));
    }

    setPage(1);
    dispatch(setFireblockCode(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetFireblockType = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFireblockType(""));
    }

    setPage(1);
    dispatch(setFireblockType(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetFireblockNativeAsset = (clearReset = false, context = false) => {
    if (context) {
      return dispatch(setFireblockNativeAsset(""));
    }

    setPage(1);
    dispatch(setFireblockNativeAsset(""));
    dispatch(setTriggerFilter(false));
    setTimeout(() => {
      dispatch(setTriggerFilter(true));
    }, 500);

    if (clearReset) return;
  };

  const resetHandlers = [
    resetFireblockName,
    resetFireblockCode,
    resetFireblockType,
    resetFireblockNativeAsset,
  ];

  const enableClearFilters = useMemo(() => {
    if (
      fireblock_name ||
      fireblock_code ||
      fireblock_type ||
      fireblock_native_asset
    ) {
      return true;
    }

    return false;
  }, [fireblock_name, fireblock_code, fireblock_type, fireblock_native_asset]);

  return (
    <>
      <FilterList
        secondLevelHandlers={secondLevelHandlers}
        secondLevelFilter={fireblockSecondFilterTree}
        handleApply={handleApply}
        enableClearFilters={enableClearFilters}
        clearSearches={resetHandlers}
        additionalHandlers={[]}
        clearAdditionalSearches={[]}
        clearSelect={() => null}
      />
    </>
  );
};

export default FireblockFiltersWrapper;
