import { useCallback, useEffect, useMemo, useState } from "react";
import { useOrderDispatch, useOrderFilters } from "providers/OrderFilter";
import {
  set_amountFrom,
  set_amountTo,
  set_assetOrder,
  set_billing_country,
  set_checkedIDOrder,
  set_currencyOrder,
  set_exchangeOrder,
  set_exportedOrder,
  set_filteringOrder,
  set_orderDateFrom,
  set_orderDateTo,
  set_orderFilters,
  set_orderPage,
  set_orderSearch,
  set_quantityFrom,
  set_quantityTo,
  set_rateFrom,
  set_rateTo,
  set_tr_status,
  set_Withdrawal,
} from "providers/OrderFilter/ordersAction";
import { set_checked_pages } from "providers/ClientFilter/clientsActions";
// import { useOrderDispatchContext, useOrderState } from "providers/Orders";

import Filter from "components/Filter";
import FilterList from "components/Filter/FilterList";
// import { FilterListProps } from "./OrderFilterWrapper.props";
import {
  email_regex,
  ordersFilterTree,
  ordersSecondFilterTree,
  uuid_regex,
} from "utils/constants";
import { FilterListItem } from "components/Filter/FilterList/FilterList.props";
import FilterListSkeleton from "components/FilterListSkeleton";
import {
  FilterTreeItem,
  GetListProps,
  OrderFilterWrapper,
} from "./OrderFilterWrapper.props";

const OrderFiltersWrapper = ({
  setLoading,
  getFiatList,
  getAssetList,
  transactionStatuses,
}: OrderFilterWrapper): JSX.Element => {
  const [secondLevelFilter, setSecondLevelFilter] = useState<FilterTreeItem[]>(
    []
  );

  // const [reducedSecondFilterTree, setReducedSecondFilterTree] = useState<
  //   typeof ordersSecondFilterTree
  // >(ordersSecondFilterTree);

  const [additionalFilters, setAdditionalFilter] = useState<
    [] | FilterListItem[] | undefined
  >([]);

  const orderDispatch = useOrderDispatch();
  // const { setLoading, getFiatList, getAssetList } = useOrderDispatchContext();
  // const { transactionStatuses } = useOrderState();

  const {
    orderSearch,
    dateToOrder,
    amountFrom,
    amountTo,
    rateFrom,
    rateTo,
    quantityFrom,
    quantityTo,
    dateFromOrder,
    assetOrder,
    exchangeOrder,
    currencyOrder,
    partnerOrder,
    withdrawalOrder,
    filters,
    trStatus,
  } = useOrderFilters();

  const handleFirstLevelFilter = (selectedOption) => {
    setLoading(true);

    if (selectedOption === "ALL") {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_orderFilters([]));
      orderDispatch(set_orderPage(1));
      orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
      orderDispatch(set_exportedOrder(undefined));
      orderDispatch(set_checked_pages([]));
      orderDispatch(set_filteringOrder(true));

      return;
    }

    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_orderFilters([selectedOption]));
    orderDispatch(set_orderPage(1));
    orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
    orderDispatch(set_exportedOrder(undefined));
    orderDispatch(set_checked_pages([]));
    orderDispatch(set_filteringOrder(true));
  };

  const clearOrder = () => {
    orderDispatch(set_checkedIDOrder({ checked: false, orders: [] }));
    orderDispatch(set_checked_pages([]));
  };

  const handleAmount = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void,
    amountType?: string | undefined
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (amountType === "from") {
      orderDispatch(
        set_amountFrom((e.target.value === "" && undefined) || e.target.value)
      );
    }

    if (amountType === "to") {
      orderDispatch(
        set_amountTo((e.target.value === "" && undefined) || e.target.value)
      );
    }

    orderDispatch(set_orderPage(1));
    setIsValid(true);
    orderDispatch(set_filteringOrder(false));

    return;
  };

  const handleFiat = (e, setError, setIsValid) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_currencyOrder([e.target.value]));
    orderDispatch(set_orderPage(1));
    setIsValid(true);
    setTimeout(() => {
      clearOrder();
    }, 500);
  };

  const handleAsset = (e, setError, setIsValid) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_assetOrder([e.target.value]));
    orderDispatch(set_orderPage(1));
    setIsValid(true);
    setTimeout(() => {
      clearOrder();
    }, 500);
  };

  const handleWithdrawal = (e, setError, setIsValid) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_Withdrawal([e.target.value]));
    orderDispatch(set_orderPage(1));
    setIsValid(true);
    setTimeout(() => {
      clearOrder();
    }, 500);
  };

  const handleOrderID = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim().match(uuid_regex)?.length) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_orderSearch(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("User id is invalid");
  };

  const handleBillingCountry = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim().length) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_billing_country(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("Billing Country is invalid");
  };

  const handleEmail = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    if (e.target.value?.trim().match(email_regex)?.length) {
      orderDispatch(set_filteringOrder(false));
      orderDispatch(set_orderSearch(e.target.value));
      orderDispatch(set_orderPage(1));
      setIsValid(true);
      setError("");
      setTimeout(() => {
        clearOrder();
      }, 500);

      return;
    }

    setIsValid(false);
    setError("Email is invalid");

    return;
  };

  const handleDate = useCallback(
    ({ dateFrom, dateTo }) => {
      orderDispatch(set_orderDateFrom(dateFrom));
      orderDispatch(set_orderDateTo(dateTo));
      orderDispatch(set_orderPage(1));
      orderDispatch(set_filteringOrder(false));
    },
    [orderDispatch]
  );

  const handleTrStatus = (e, setError, setIsValid) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_tr_status(e.target.value));
    orderDispatch(set_orderPage(1));
    setIsValid(true);
    setTimeout(() => {
      clearOrder();
    }, 500);
  };

  const secondLevelHandlers = [
    handleAmount,
    handleFiat,
    handleAsset,
    handleWithdrawal,
    handleOrderID,
    handleBillingCountry,
    handleEmail,
    handleDate,
    handleTrStatus,
  ];

  const handleApply = (type?: string) => {
    orderDispatch(set_filteringOrder(true));

    if (type === "date_picker" || type === "number_range") {
      setTimeout(() => {
        clearOrder();
      }, 500);
    }
  };

  const enableClearFilters = useMemo(() => {
    if (
      orderSearch ||
      dateToOrder ||
      amountFrom ||
      amountTo ||
      rateFrom ||
      rateTo ||
      quantityFrom ||
      quantityTo ||
      dateFromOrder ||
      trStatus ||
      assetOrder?.length ||
      exchangeOrder?.length ||
      currencyOrder?.length ||
      partnerOrder?.length ||
      withdrawalOrder?.length ||
      additionalFilters?.length
    ) {
      return true;
    }

    return false;
  }, [
    orderSearch,
    dateToOrder,
    amountFrom,
    amountTo,
    rateFrom,
    rateTo,
    quantityFrom,
    quantityTo,
    dateFromOrder,
    assetOrder,
    exchangeOrder,
    currencyOrder,
    partnerOrder,
    withdrawalOrder,
    additionalFilters,
    trStatus,
  ]);

  const resetAmount = (clearReset = false, context = false) => {
    if (context) {
      orderDispatch(set_amountFrom(""));
      orderDispatch(set_amountTo(""));
      orderDispatch(set_orderPage(1));

      return;
    }

    if (clearReset && !amountFrom && !amountTo) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_amountFrom(""));
    orderDispatch(set_amountTo(""));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetFiat = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_currencyOrder(undefined));
    }

    if (clearReset && !currencyOrder?.length) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_currencyOrder(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetAsset = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_assetOrder(undefined));
    }

    if (clearReset && !assetOrder?.length) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_assetOrder(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetWithdrawal = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_Withdrawal(undefined));
    }

    if (clearReset && !withdrawalOrder?.length)
      orderDispatch(set_filteringOrder(false));
    orderDispatch(set_Withdrawal(undefined));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetBillingCountry = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_orderSearch(""));
    }

    if (clearReset && !orderSearch) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_billing_country(""));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetOrderEmail = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_orderSearch(""));
    }

    if (clearReset && !orderSearch) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_orderSearch(""));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetDate = (clearReset = false, context = false) => {
    if (context) {
      return;
    }

    if (clearReset && !dateFromOrder && !dateToOrder) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_orderDateFrom(null));
    orderDispatch(set_orderDateTo(null));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const resetTrStatus = (clearReset = false, context = false) => {
    if (context) {
      return orderDispatch(set_tr_status(""));
    }

    if (clearReset && !assetOrder?.length) return;
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_tr_status(""));
    orderDispatch(set_orderPage(1));

    handleApply();

    return;
  };

  const clearSearches = [
    resetAmount,
    resetFiat,
    resetAsset,
    resetWithdrawal,
    resetOrderEmail,
    resetBillingCountry,
    resetOrderEmail,
    resetDate,
    resetTrStatus,
  ];

  const handleRate = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void,
    rateType?: string | undefined
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    orderDispatch(set_filteringOrder(false));

    if (rateType === "from") {
      orderDispatch(
        set_rateFrom((e.target.value === "" && undefined) || e.target.value)
      );
    }

    if (rateType === "to") {
      orderDispatch(
        set_rateTo((e.target.value === "" && undefined) || e.target.value)
      );
    }

    setError("");
    setIsValid(true);

    return;
  };

  const handleQuantity = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void,
    quantityType: string | undefined
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");

    orderDispatch(set_filteringOrder(false));

    if (quantityType === "from") {
      orderDispatch(
        set_quantityFrom((e.target.value === "" && undefined) || e.target.value)
      );
    }

    if (quantityType === "to") {
      orderDispatch(
        set_quantityTo((e.target.value === "" && undefined) || e.target.value)
      );
    }

    setError("");
    setIsValid(true);
  };

  const handleExchange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setError: (error: string) => void,
    setIsValid: (isValid: boolean) => void
  ) => {
    if (!e.target.value && typeof e.target.value !== "number")
      return setError("Enter a value");
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_exchangeOrder([e.target.value]));

    setIsValid(true);
  };

  const additionaHandlers = [handleRate, handleQuantity, handleExchange];

  const resetRate = () => {
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_rateFrom(""));
    orderDispatch(set_rateTo(""));

    handleApply();

    return;
  };

  const resetQuantity = () => {
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_quantityFrom(""));
    orderDispatch(set_quantityTo(""));

    handleApply();

    return;
  };

  const resetExchange = () => {
    orderDispatch(set_filteringOrder(false));
    orderDispatch(set_exchangeOrder(undefined));

    handleApply();

    return;
  };

  const clearAdditionalSearches = [resetRate, resetQuantity, resetExchange];

  const reduceFilterTree = useMemo(async () => {
    const fiatList = (await getFiatList()) as unknown as GetListProps;
    const assetList = (await getAssetList()) as unknown as GetListProps;

    const fiatMap = fiatList?.rows.map((fiat) => ({
      label: fiat.symbol,
      value: fiat.symbol,
    }));

    const assetMap = assetList?.rows.map((asset) => {
      return {
        label: `${asset.symbol} ${
          asset?.core && typeof asset?.core === "string"
            ? "- " + asset.core
            : ""
        }`,
        value: asset.code as string,
      };
    });

    const reducedFilterTree = ordersSecondFilterTree.reduce(
      (acc: FilterTreeItem[], curr: FilterTreeItem) => {
        if (curr.value === "FIAT") {
          curr = {
            ...curr,
            selectOptions: curr?.selectOptions && [
              ...curr?.selectOptions,
              ...fiatMap,
            ],
          };
        }

        if (curr.value === "ASSET") {
          curr = {
            ...curr,
            selectOptions: curr?.selectOptions && [
              ...curr?.selectOptions,
              ...assetMap,
            ],
          };
        }

        if (
          curr.value === "TRANSACTION_STATUS" &&
          transactionStatuses?.length
        ) {
          curr = {
            ...curr,
            selectOptions: [
              ...transactionStatuses.map((item) => ({
                value: item,
                label: item.substr(0, 1).toUpperCase() + item.substr(1),
              })),
            ],
          };
        }

        return [...acc, curr];
      },
      []
    );

    setSecondLevelFilter(reducedFilterTree);
    // eslint-disable-next-line
  }, [transactionStatuses]);

  useEffect(() => {
    reduceFilterTree;
  }, [reduceFilterTree]);

  return (
    <>
      <Filter
        handleFirstLevelFilter={handleFirstLevelFilter}
        filterValues={ordersFilterTree}
        filters={filters}
      />
      {secondLevelFilter?.length ? (
        <FilterList
          secondLevelHandlers={secondLevelHandlers}
          secondLevelFilter={secondLevelFilter}
          handleApply={handleApply}
          enableClearFilters={enableClearFilters}
          clearSearches={clearSearches}
          additionalFilters={additionalFilters}
          setAdditionalFilters={setAdditionalFilter}
          additionalHandlers={additionaHandlers}
          clearAdditionalSearches={clearAdditionalSearches}
          clearSelect={clearOrder}
        />
      ) : (
        <FilterListSkeleton />
      )}
    </>
  );
};

export default OrderFiltersWrapper;
