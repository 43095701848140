import React, { FC, useState } from "react";

import Eyes from "../../assets/img/eyes.svg";

import "./styles.scss";

type InputProps = React.ComponentPropsWithoutRef<"input">;

const PasswordInput: FC<InputProps> = (props) => {
  const [visibility, setVisibility] = useState(false);

  return (
    <div className="input-container">
      <span onClick={() => setVisibility(!visibility)}>
        <img alt="Logo" src={Eyes} className="h-30px" />
      </span>
      <input {...props} type={visibility ? "text" : props.type} />
    </div>
  );
};

export default PasswordInput;
