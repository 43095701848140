import { FC } from "react";

import { FilterProps } from "./Filter.props";
import Tabs from "../Tabs";

const Filter: FC<FilterProps> = ({
  handleFirstLevelFilter,
  filterValues,
  filters,
}) => {
  return (
    <Tabs
      handleFirstLevelFilter={handleFirstLevelFilter}
      filterValues={filterValues}
      filters={filters}
    />
  );
};

export default Filter;
