import { FC, useEffect, useMemo } from "react";

import "../style.scss";
import Button from "components/Button";
import { OrderStatusPopoverProps } from "./OrderStatusPopover.props";

const OrderStatusPopover: FC<OrderStatusPopoverProps> = ({
  items,
  id,
  updatedStatus,
  chosenStatus,
  chosenItem,
  updateStatus,
  setIsPopoverOpen,
  setChosenStatus,
  setComments,
  comments,
}): JSX.Element => {
  // const [chosenStatus, setChosenStatus] = useState("");

  const handleChange = (e) => {
    setChosenStatus(
      e.target.value.replace(/(^|\s)\S/g, (l) => l.toLowerCase())
    );
  };

  const handleClick = () => {
    updateStatus(chosenStatus, comments);
    setIsPopoverOpen(false);
  };

  useEffect(() => {
    if (updatedStatus?.order_id === id && updatedStatus?.tr_status) {
      setChosenStatus(updatedStatus?.tr_status);
    }
  }, [updatedStatus, id, setChosenStatus]);

  const isDisabled = useMemo(() => {
    if (
      updatedStatus?.tr_status === chosenStatus &&
      updatedStatus?.order_id === id
    )
      return true;

    if (
      (!updatedStatus?.order_id || updatedStatus.order_id !== id) &&
      chosenStatus === chosenItem
    )
      return true;

    if (comments.length < 5) {
      return true;
    }

    return false;
  }, [updatedStatus, chosenStatus, id, chosenItem, comments]);

  return (
    <div className="popover-stats__container">
      <h1 className="popover-stats__title">Status</h1>
      <div className="popover-stats__container--radio">
        {items?.map((item, index) => (
          <div key={index} className="radio__wrapper">
            <label className="radio__label">
              <input
                type="radio"
                name="radio"
                checked={chosenStatus === item}
                onChange={handleChange}
                value={item}
              />
              <span className="radio__checkmark"></span>{" "}
              {item.replace(/(^|\s)\S/g, (l) => l.toUpperCase())}
            </label>
          </div>
        ))}
      </div>
      <h1 className="popover-stats__title">Comments</h1>
      <div className="comments-section">
        <div className="textarea-section">
          <textarea
            className="input input__search"
            name="name"
            id="id"
            onChange={(e) => setComments(e.target.value)}
          ></textarea>
          {comments.length && comments.length < 5 ? (
            <p className="input-error-message">
              Must be at least 5 charachters
            </p>
          ) : null}
        </div>
      </div>
      <div className="radio-buttons__wrapper">
        <Button
          type="button"
          className="button-cancel"
          onClick={() => setIsPopoverOpen(false)}
        >
          Cancel
        </Button>
        <Button
          type="button"
          className="button-submit"
          onClick={handleClick}
          disabled={isDisabled}
        >
          Change
        </Button>
      </div>
    </div>
  );
};

export default OrderStatusPopover;
