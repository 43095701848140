import { FC, useMemo } from "react";
import { Page, Text, Image, View, Document, Font } from "@react-pdf/renderer";
import moment from "moment";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SF_Pro from "../../fonts/SF-Pro.otf";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SF_Mono from "../../fonts/SFMonoRegular.woff";
import LogoText from "../base/Icon/icons/logo-text.png";
import ApplePay from "../base/Icon/icons/apple-pay.png";
import OpenPayd from "../base/Icon/icons/sepa.png";
import Stripe from "../base/Icon/icons/stripe.png";
import VisaPay from "../base/Icon/icons/visa-pay.png";
import MasterPay from "../base/Icon/icons/master-pay.png";
import { InvoiceProps } from "./Invoice.props";
import { styles } from "./Invoice.styles";

Font.register({ family: "SF-Pro", src: SF_Pro });
Font.register({ family: "SF-Mono", src: SF_Mono });

const Invoice: FC<InvoiceProps> = ({ order }): JSX.Element => {
  const renderPayment = useMemo(() => {
    switch (order?.payment_method) {
      case "openPayd":
        return (
          <View style={styles.payment_method_wrapper}>
            <Image src={OpenPayd} style={styles.payment_method_icon} />
            <Text style={styles.payment_method_text}>OpenPayd</Text>
          </View>
        );
      case "stripe":
        return (
          <View style={styles.payment_method_wrapper}>
            <Image src={Stripe} style={styles.payment_method_icon} />
            <Text style={styles.payment_method_text}>Stripe</Text>
          </View>
        );
      case "google":
        return (
          <View style={styles.payment_method_wrapper}>
            <Text style={styles.payment_method_text}>Google Pay</Text>
          </View>
        );
      case "apple":
        return (
          <View style={styles.payment_method_wrapper}>
            <Image src={ApplePay} style={styles.payment_method_icon} />
            <Text style={styles.payment_method_text}>Apple Pay</Text>
          </View>
        );
      case "visa":
        return (
          <View style={styles.payment_method_wrapper}>
            <Image src={VisaPay} style={styles.payment_method_icon} />
            <Text style={styles.payment_method_text}>Visa</Text>
          </View>
        );
      case "master":
        return (
          <View style={styles.payment_method_wrapper}>
            <Image src={MasterPay} style={styles.payment_method_icon} />
            <Text style={styles.payment_method_text}>Master Card</Text>
          </View>
        );
      default:
        return (
          <View style={styles.payment_method_wrapper}>
            <Text style={styles.content_value}>-</Text>
          </View>
        );
    }
  }, [order.payment_method]);

  const renderStatus = useMemo(
    () => (status) => {
      switch (status) {
        case "paid":
        case "successful":
          return (
            <Text style={styles.status_success}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Text>
          );
        case "pending":
          return (
            <Text style={styles.status_pending}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Text>
          );
        case "failed":
        case "sending":
          return (
            <Text style={styles.status_failed}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Text>
          );
        case "not_sent":
          return <Text style={styles.status_info}>Not Sent</Text>;
        default:
          return "-";
      }
    },
    []
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.invoice_container}>
          <View wrap={true} style={styles.image_container}>
            <Image style={styles.image} src={LogoText} />
          </View>
          <View style={styles.header_container}>
            <View style={styles.header_left_block}>
              <Text style={styles.personal_info}>
                {order.client.surname} {order.client.name}
              </Text>
            </View>
            <View style={styles.email_block}>
              <Text style={styles.email_info}>{order.client.email}</Text>
            </View>
          </View>
          <View style={styles.main_content_wrapper}>
            <View style={styles.content_keys_wrapper}>
              <Text style={styles.content_key}>Date</Text>
              <Text style={styles.content_key}>Transaction ID</Text>
              <Text style={styles.content_key}>Customer ID</Text>
              <Text style={styles.content_key}>Partner Name</Text>
              <Text style={styles.content_key}>Partner E-mail</Text>
              <Text style={styles.content_key}>Wallet</Text>
              <Text style={styles.content_key}>Amount in {order.currency}</Text>
              <Text style={styles.content_key}>Amount in {order.asset}</Text>
              <Text style={styles.content_key}>Processing Fee</Text>
              <Text style={styles.content_key}>Service Fee</Text>
              <Text style={styles.content_key}>Rate</Text>
              <Text style={styles.content_key}>Status</Text>
              <Text style={styles.content_key}>TX ID</Text>
              <Text style={styles.content_key}>Withdrawal</Text>
              <Text style={styles.content_key}>Payment Method</Text>
              <Text style={styles.content_key}>Billing Country</Text>
            </View>
            <View style={styles.content_values_wrapper}>
              <Text style={styles.content_value}>
                {moment(order.createdAt).format("DD MMM yyyy, hh:mm")}
              </Text>
              <Text style={styles.content_value}>{order.id ?? "-"}</Text>
              <Text style={styles.content_value}>{order.client.id ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order?.partner?.name ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.partner?.email ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order.address ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order.amount ? `${order?.amount} ${order?.currency}` : "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.asset_quantity
                  ? `${order?.asset_quantity} ${order?.asset}`
                  : ""}
              </Text>
              <Text style={styles.content_value}>
                {order.network_fee
                  ? `${order?.network_fee} ${order?.asset}`
                  : "-"}
              </Text>
              <Text style={styles.content_value}>
                {order?.swaps_profit
                  ? `${order.swaps_profit} ${order.currency}`
                  : "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.spot_price
                  ? `${order?.spot_price} ${order.currency}`
                  : "-"}
              </Text>
              <Text style={styles.content_value}>
                {renderStatus(order?.status)}
              </Text>
              <Text style={styles.content_value}>{order.tx_id ?? "-"}</Text>
              <Text style={styles.content_value}>
                {renderStatus(order.withdrawal)}
              </Text>
              {renderPayment}
              <Text style={styles.content_value}>
                {order?.client?.billing_address?.countries?.country ?? "-"}
              </Text>
            </View>
          </View>
          <View style={styles.footer_wrapper}>
            <Text style={styles.footer_text}>
              Octo Liquidity OÜ Harju maakond, Tallinn, Kesklinna linnaosa, Oru
              tn 2 – 7, 10127. License FVT 000398. Reg code 14829050.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default Invoice;
