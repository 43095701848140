import React, {
  Dispatch,
  ReactNode,
  createContext,
  useEffect,
  useReducer,
} from "react";

import { instance } from "utils/api";
import { appReducer } from "./reducers";
import { IState } from "./types";

const initialState: IState = {
  adminUsers: { count: 0, page: 1, list: [], isLoading: true },
  roles: [],
};

const initialDispatchState: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>;
} = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {},
};

export const AdminUsersContext = createContext(initialState);
export const AdminUsersDispatchContext = createContext(initialDispatchState);

function AdminUsersProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    try {
      (async function () {
        const { data: adminUsers } = await instance.get(
          `/admin/list?limit=10&page=${state.adminUsers.page}`
        );

        if (state.adminUsers.page === 1) {
          const { data: roles } = await instance.get("/admin/roles");
          dispatch({
            type: "SET_ROLES",
            payload: roles.filter((el) => el.name !== "super_admin"),
          });
        }

        dispatch({ type: "SET_ADMIN_USERS", payload: adminUsers });
      })();
    } catch (error) {
      console.log(error);
    }
  }, [state.adminUsers.page]);

  return (
    <AdminUsersDispatchContext.Provider value={{ dispatch }}>
      <AdminUsersContext.Provider value={state}>
        {children}
      </AdminUsersContext.Provider>
    </AdminUsersDispatchContext.Provider>
  );
}

export default AdminUsersProvider;
