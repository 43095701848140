import { Dispatch } from "providers/Auth/types";
import React, { ReducerWithoutAction, useContext, useReducer } from "react";

import ordersReducer from "./ordersReducer";

export type FilterType = {
  page: number;
  orderSearch: string;
  filters: string[] | [];
  dateFromOrder: null | string | Date;
  dateToOrder: null | string | Date;
  amountFrom: string;
  amountTo: string;
  rateFrom: string;
  rateTo: string;
  quantityFrom: string | undefined;
  quantityTo: string | undefined;
  sorting2: undefined;
  ordering2: undefined | string;
  visibleOrdersCount: string;
  orderSettingsToggle?: string[];
  exportedOrder?: string[];
  selectedOrder?: string;
  partnerOrder: undefined | string[] | string;
  assetOrder: undefined | string[];
  currencyOrder: undefined | string[];
  exchangeOrder: string[] | undefined;
  withdrawalOrder: string[] | undefined;
  checkedPartner: { id: string; name: string }[];
  checkedIDOrder: { checked: boolean; orders?: string[] };
  arrayCheckingOrder: string[] | undefined | [];
  orderID?: string;
  partnerSelection: string[] | [] | string;
  pageName: string | undefined;
  filterButtonVal?: boolean;
  paymentMethods?: undefined | string[];
  statsFilter: boolean;
  checkedPages: number[] | [];
  loading: boolean;
  trStatus: string;
  customerId: string | null;
  address: string | null;
  statusId: string | undefined;
  email: string | undefined;
  billing_country?: string;
  receiveStatus?: string;
  payerEmail?: string;
  id?: string | undefined;
  receiveId?: string | undefined;
};

const initialOrderFilters: FilterType = {
  page: 1,
  orderSearch: "",
  filters: [],
  dateFromOrder: "",
  dateToOrder: "",
  amountFrom: "",
  amountTo: "",
  rateFrom: "",
  rateTo: "",
  quantityFrom: "",
  quantityTo: "",
  sorting2: undefined,
  ordering2: undefined,
  visibleOrdersCount: "10",
  orderSettingsToggle: [],
  exportedOrder: [],
  selectedOrder: "",
  partnerOrder: [],
  assetOrder: undefined,
  currencyOrder: undefined,
  exchangeOrder: undefined,
  withdrawalOrder: undefined,
  checkedPartner: [],
  checkedIDOrder: { checked: false, orders: [] },
  arrayCheckingOrder: [],
  orderID: undefined,
  partnerSelection: [],
  pageName: undefined,
  filterButtonVal: undefined,
  paymentMethods: undefined,
  statsFilter: false,
  checkedPages: [],
  loading: false,
  trStatus: "",
  customerId: null,
  address: null,
  statusId: undefined,
  email: undefined,
  billing_country: undefined,
  id: undefined,
  receiveId: undefined,
};

const OrderFiltersContext = React.createContext(initialOrderFilters);
const OrderFiltersDispatcher = React.createContext<Dispatch>(() => "");

const OrdersFilterProvider: React.FC = ({ children }) => {
  const [filters, filtersDispatcher] = useReducer<
    ReducerWithoutAction<FilterType>
  >(ordersReducer as never, initialOrderFilters);

  return (
    <OrderFiltersContext.Provider value={filters as never}>
      <OrderFiltersDispatcher.Provider value={filtersDispatcher}>
        {children}
      </OrderFiltersDispatcher.Provider>
    </OrderFiltersContext.Provider>
  );
};

export const useOrderFilters: () => FilterType = () => {
  const orderFilters = useContext(OrderFiltersContext);

  return orderFilters;
};

export const useOrderDispatch = (): Dispatch => {
  const filterDispatch = useContext(OrderFiltersDispatcher);

  return filterDispatch;
};

export default OrdersFilterProvider;
