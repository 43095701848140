import { FC } from "react";

import useRole from "hooks/useRole";
import { WidgetConsoleColumnsProps } from "./WidgetConsole.props";
import WidgetConsoleColumnField from "./WidgetConsoleColumnField";

const WidgetConsoleColumn: FC<WidgetConsoleColumnsProps> = ({
  flow,
  updateCryptoStatus,
  getCrypto,
  setChosenFireblock,
  page,
  setIsOpen,
  crypto,
}): JSX.Element => {
  const role = useRole();

  return (
    <>
      {crypto.map((cryptoItem) => (
        <tr key={cryptoItem.id} className="user-list-tr">
          <WidgetConsoleColumnField
            flow={flow}
            updateCryptoStatus={updateCryptoStatus}
            getCrypto={getCrypto}
            setChosenFireblock={setChosenFireblock}
            page={page}
            setIsOpen={setIsOpen}
            cryptoItem={cryptoItem}
            role={role}
            isLast={crypto.length >= 0 && crypto.length < 3}
          />
        </tr>
      ))}
    </>
  );
};

export default WidgetConsoleColumn;
