import React, {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import {
  State,
  Dispatch,
  ActionTypes,
  DispatchContext,
  SendCode,
} from "./types";
import reducer from "./reducers";
import { instance, setAxiosHeader } from "utils/api";
import { showError } from "utils/helpers";

const initialState: State = {
  isLoggedIn: !!localStorage.getItem("token"),
  user: null,
  error: null,
};

const AuthStateContext = createContext<State | undefined>(undefined);
const AuthDispatchContext = createContext<Dispatch | undefined>(undefined);

const AuthProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  // states
  const [isLoading, setLoading] = useState(!!localStorage.getItem("token"));

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      (async () => {
        try {
          const res = await instance.get("admin/me", {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });

          // get this token required step, because we need get updated token witch can be settee from intercepted
          const updatedToken = localStorage.getItem("token");

          updatedToken && setAxiosHeader(updatedToken, instance);
          dispatch({ type: ActionTypes.SET_USER, data: res.data.data });
        } catch (err) {
          dispatch({ type: ActionTypes.SIGN_OUT });
          // showError(err);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, []);

  return (
    <AuthStateContext.Provider value={state}>
      <AuthDispatchContext.Provider value={dispatch}>
        {isLoading ? null : children}
      </AuthDispatchContext.Provider>
    </AuthStateContext.Provider>
  );
};

const useAuthStateContext = (): State => {
  const context = useContext(AuthStateContext);

  if (typeof context === "undefined") {
    throw new Error("useAuthStateContext must be used within a AuthProvider");
  }

  return context;
};

const useAuthDispatchContext = (): DispatchContext => {
  const dispatch = useContext(AuthDispatchContext);
  const history = useHistory();

  if (typeof dispatch === "undefined") {
    throw new Error(
      "useAuthDispatchContext must be used within a AuthProvider"
    );
  }

  const signIn = useCallback(
    async (data) => {
      try {
        const res = await instance.post("admin/login", data);

        const { token, user, refreshToken } = res.data.data;

        sessionStorage.setItem("user-phone", user.phoneNumber);

        token && localStorage.setItem("token", token);
        refreshToken &&
          localStorage.setItem("refreshToken", refreshToken?.token);
        localStorage.setItem("user-id", user.id);

        if (!refreshToken?.token) {
          dispatch({ type: ActionTypes.SET_USER, data: user });
          setAxiosHeader(token, instance);

          switch (true) {
            case user.hesTwoFaPhoneNumber:
              return history.push("/code-verification");
            case user?.hasGoogleTwoFa:
              return history.push("/code-verification?two-factor-auth=google");
            default:
              return history.push("/account-auth-type");
              break;
          }
        }

        setAxiosHeader(token, instance);
        dispatch({ type: ActionTypes.SIGN_IN, data: user });
        history.push("/dashboard");
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (
          // axios.isAxiosError(err) &&
          err?.response?.status === 403 &&
          err?.response?.data.message.split("blockedMinutes: ")[1]
        )
          // return (window.location.href =
          //   "/block-partner/" +
          //   err?.response?.data.message.split("blockedMinutes: ")[1]);

          dispatch({
            type: ActionTypes.SET_ERROR,
            data: err?.response?.data.message,
          });

        return dispatch({
          type: ActionTypes.SET_ERROR,
          data: err?.response?.data?.message,
        });
      }
    },
    [dispatch, history]
  );

  const signOut = useCallback(() => {
    localStorage.removeItem("token");
    instance.get("admin/logout");
    dispatch({ type: ActionTypes.SIGN_OUT });
  }, [dispatch]);

  const createPassword = useCallback(
    async (data) => {
      try {
        const res = await instance.post("/admin/password/create", data);
        const { token, user } = res.data;

        localStorage.setItem("token", token);
        setAxiosHeader(token, instance);
        dispatch({ type: ActionTypes.SET_USER, data: user });
      } catch (err) {
        showError(err);
        throw err;
      }
    },
    [dispatch]
  );

  const resetPassword = useCallback(async (data) => {
    try {
      await instance.post("/admin/password/set", data);
    } catch (err) {
      showError(err);
      throw err;
    }
  }, []);

  const forgotPass = useCallback(async (email) => {
    try {
      await instance.post("admin/password/forgot", {
        email,
      });
    } catch (e) {
      return true;
    }
  }, []);

  const setPhone = useCallback(
    async (phone) => {
      const res = await instance.post("admin/add-two-fa-phone/send", {
        twoFaPhoneNumber: `+${phone.replace(/[+]/g, "")}`,
      });

      dispatch({ type: ActionTypes.ADD_PHONE, data: res.data.phoneNumber });
    },
    [dispatch]
  );

  const sendCode = async (data: SendCode) => {
    const authToken = localStorage.getItem("token");

    if (data.isGoogleAuth && !data.hasGoogleTwoFa) {
      await instance.post(
        "/admin/google-2fa/verify",
        { code: data.twoFaCode },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      localStorage.removeItem("token");

      return localStorage.removeItem("refreshToken");
    } else if (!data.isGoogleAuth && !data.hesTwoFaPhoneNumber) {
      await instance.post(
        "/admin/add-two-fa-phone/verify",
        {
          hasGoogleTwoFa: data.hasGoogleTwoFa,
          hesTwoFaPhoneNumber: data.hesTwoFaPhoneNumber,
          twoFaCode: data.twoFaCode,
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      localStorage.removeItem("token");

      return localStorage.removeItem("refreshToken");
    }

    const res = await instance.post(
      "admin/login/two-fa/verify",
      {
        hasGoogleTwoFa: data.hasGoogleTwoFa,
        hesTwoFaPhoneNumber: data.hesTwoFaPhoneNumber,
        twoFaCode: data.twoFaCode,
      },
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );

    const { refreshToken, token, user } = res.data;

    localStorage.setItem("token", token);
    localStorage.setItem("refreshToken", refreshToken?.token);

    setAxiosHeader(token, instance);
    dispatch({ type: ActionTypes.SIGN_IN, data: user });
  };

  return {
    signIn,
    signOut,
    createPassword,
    resetPassword,
    sendCode,
    setPhone,
    forgotPass,
  };
};

export default AuthProvider;
export { useAuthDispatchContext, useAuthStateContext };
