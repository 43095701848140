import { useEffect, useMemo, useState } from "react";
import { useWidgetConsoleDispatch } from "providers/WidgetConsole";
import { useLocation, useParams } from "react-router-dom";
import { CryptoProps } from "providers/WidgetConsole/types";
import moment from "moment";

import { Icon, PageWrapper } from "components/base";
import CryptoDetailsSkeleton from "../DetailsSkeleton";

const CryptoDetails = (): JSX.Element => {
  const location = useLocation();
  const [cryptoDetails, setCryptoDetails] = useState<CryptoProps | null>(null);
  const params: { id: string } = useParams();
  const { getCryptoById } = useWidgetConsoleDispatch();

  useEffect(() => {
    if (params?.id) {
      (async () => {
        try {
          const data = await getCryptoById(
            params?.id,
            location.pathname.includes("sell-flow") ? "sell-flow" : "buy-flow"
          );

          if (data) {
            setCryptoDetails(data);
          }
        } catch (error) {
          console.log(error);
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  const renderStatus = useMemo(() => {
    if (location.pathname.includes("sell-flow")) {
      return (
        <>
          <tr className="order-details-tr">
            <td className="td-definition">Status</td>
            <td className="td-answer user-list-td-grey suspend-status">
              <div>
                {cryptoDetails?.is_sell_supported ? (
                  <div className="user-status-td transaction-status-td user-status-active">
                    Supported
                    <Icon name="success_check" />
                  </div>
                ) : (
                  <div className="user-status-td transaction-status-td user-status-blocked">
                    Not Supported
                    <Icon name="order_reject" />
                  </div>
                )}
              </div>
            </td>
          </tr>
          <tr className="order-details-tr">
            <td className="td-definition">Negative markup rate</td>
            <td className="td-answer">
              {cryptoDetails?.negative_markup ?? "-"}
            </td>
          </tr>
        </>
      );
    }

    if (location.pathname.includes("buy-flow")) {
      return (
        <>
          <tr className="order-details-tr">
            <td className="td-definition">Status</td>
            <td className="td-answer user-list-td-grey suspend-status">
              <div>
                {cryptoDetails?.is_suspended ? (
                  <div className="user-status-td transaction-status-td user-status-blocked">
                    Not Supported
                    <Icon name="order_reject" />
                  </div>
                ) : (
                  <div className="user-status-td transaction-status-td user-status-active">
                    Supported
                    <Icon name="success_check" />
                  </div>
                )}
              </div>
            </td>
          </tr>
          <tr className="order-details-tr">
            <td className="td-definition">Markup rate</td>
            <td className="td-answer">{cryptoDetails?.markup_rate ?? "-"}</td>
          </tr>
        </>
      );
    }
  }, [location, cryptoDetails]);

  return (
    <PageWrapper title={"Transaction Details"}>
      <div className="content d-flex flex-column flex-column-fluid">
        {!cryptoDetails ? (
          <CryptoDetailsSkeleton />
        ) : (
          <div className="container" id="kt_content_container">
            <div className="d-flex flex-column">
              <div className="flex-column">
                <div className="card card-order">
                  <div className="order--header">
                    <div
                      style={{ display: "flex" }}
                      className="order--header-button"
                    >
                      <span className="card-details-header-content-body-name">
                        Crypto Details
                      </span>
                    </div>
                  </div>
                  <div className="table-content">
                    <table className="main-table">
                      <tbody>
                        <tr className="order-details-tr">
                          <td className="td-definition">ID</td>
                          <td className="td-answer">{cryptoDetails?.id}</td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Core</td>
                          <td className="td-answer">{cryptoDetails?.core}</td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Code</td>
                          <td className="td-answer">
                            {cryptoDetails?.code ?? "-"}
                          </td>
                        </tr>
                        {renderStatus}
                        <tr className="order-details-tr">
                          <td className="td-definition">Name</td>
                          <td className="td-answer">{cryptoDetails?.name}</td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Symbol</td>
                          <td className="td-answer">{cryptoDetails?.symbol}</td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Address Regex</td>
                          <td className="td-answer">
                            {cryptoDetails?.address_regex}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Testnet Regex</td>
                          <td className="td-answer">
                            {cryptoDetails?.testnet_address_regex}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Native Asset</td>
                          <td className="td-answer">
                            {cryptoDetails?.native_asset}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Precision</td>
                          <td className="td-answer">
                            {cryptoDetails?.precision}
                          </td>
                        </tr>
                        <tr className="order-details-tr">
                          <td className="td-definition">Created At</td>
                          <td className="td-answer">
                            {moment(cryptoDetails?.createdAt).format(
                              "DD MMM yyyy, hh:mm"
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default CryptoDetails;
