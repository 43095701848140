import React, { FC, useEffect, useMemo } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  useClientDispatchContext,
  useClientStateContext,
} from "providers/Clients";
import { useClientDispatch, useClientFilters } from "providers/ClientFilter";
import {
  set_arrayChecking,
  set_checkedID,
  set_exportedClient,
  set_filteringButton,
  set_page,
  set_selectedClient,
} from "providers/ClientFilter/clientsActions";
import { set_checked_pages } from "providers/OrderFilter/ordersAction";

import { Table, Icon, TableActionButton } from "components/base";
import NotFound from "pages/NotFound";
import Pagination from "components/Pagination";
import TableSkeleton from "components/TableSkeleton";
import Tooltip from "components/Tooltip";
import Button from "components/Button";
import { copyToClipboard } from "utils/helpers";

interface Props {
  id: string;
}

let arr: string[] | undefined = [];

let timer: ReturnType<typeof setTimeout>;

const PartnerClientsTable: FC<Props> = ({ id }) => {
  // context
  const { clientsCount, isLoading } = useClientStateContext();
  const { getClients, setLoading } = useClientDispatchContext();

  const {
    page,
    checkedID,
    visibleClientsCount,
    exportedClient,
    selectedClient,
    checkedPages,
    search,
  } = useClientFilters();

  const dispatch = useClientDispatch();

  const { clients } = useClientStateContext();

  // states

  useEffect(() => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      getClients(
        page,
        search,
        undefined,
        undefined,
        null,
        null,
        null,
        undefined,
        undefined,
        undefined,
        visibleClientsCount,
        id
      );
    }, 450);

    return () => {
      clearTimeout(timer);
    };
  }, [id, page, getClients, visibleClientsCount, search]);

  useEffect(() => {
    arr = clients.map((item) => item.id);
  }, [clients]);

  const handleCheckClientId = (id) => {
    dispatch(set_exportedClient(id));
    dispatch(set_selectedClient(id));

    if (
      checkedID &&
      exportedClient?.find((elem) => elem === id) === undefined
    ) {
      const x = arr?.findIndex((elem) => elem === selectedClient);
      arr?.splice(x as number, 1);
    }

    if (exportedClient?.find((elem) => elem === id) !== undefined) {
      arr?.push(selectedClient as string);
    }

    dispatch(set_arrayChecking(arr));
  };

  const selectUser = useMemo(() => {
    const clientsID = clients.map((item) => item.id);

    const compoundID = [...(exportedClient as string[]), ...clientsID];

    return (
      <>
        <input
          onChange={(e) => {
            if (e.target.checked) {
              dispatch(
                set_checkedID({
                  checked: e.target.checked,
                  clients: compoundID,
                })
              );

              dispatch(set_checked_pages([...checkedPages, page]));
            } else {
              dispatch(
                set_checkedID({
                  checked: true,
                  clients: exportedClient?.filter(
                    (item) => !clientsID.includes(item)
                  ) as string[],
                })
              );
              dispatch(
                set_checked_pages(checkedPages.filter((pg) => pg !== page))
              );
            }
          }}
          type="checkbox"
          className="form-check-input user-list-checkbox"
          checked={(checkedPages as number[])?.includes(page)}
        />
      </>
    );
  }, [dispatch, clients, exportedClient, checkedPages, page]);

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        dispatch(set_page(page + 1));
        dispatch(set_filteringButton(true));

        return;
      case "prev":
        dispatch(set_page(page - 1));
        dispatch(set_filteringButton(true));

        return;

      default:
        return;
    }
  };

  return (
    <div className="dataTables_wrapper dt-bootstrap4 no-footer" id="kt_content">
      <div className="card user-list-row partner--row">
        <div className="user-list-body">
          {(clients.length === 0 && !isLoading && <NotFound />) || (
            <>
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <Table
                  headers={[
                    { empty: true, w: "fit-content", content: selectUser },
                    {
                      title: "Customer Id",
                      w: "fit-content",
                      className: "th-customer-id",
                    },
                    { title: "", w: "fit-content" },
                    { title: "Name", w: "fit-content" },
                    { title: "E-MAIL", w: "fit-content" },
                    { title: "", w: "fit-content" },
                    { title: "KYC", w: "fit-content" },
                    // { title: "RISK SCORE", w: 125 },
                    { title: "STATUS", w: "fit-content" },
                    { title: "Registration Date", w: "fit-content" },
                    { title: "Action", w: "fit-content" },
                  ]}
                >
                  {clients.map((partnerClient, index) => (
                    <tr key={index} className="user-list-tr">
                      <td>
                        <input
                          onClick={() => handleCheckClientId(partnerClient.id)}
                          className={`form-check-input user-list-checkbox`}
                          checked={exportedClient?.includes(partnerClient.id)}
                          type="checkbox"
                          value={partnerClient.id}
                        />
                      </td>
                      <td className="table-id-link">
                        <div>
                          <Link
                            to={`/users/details/${partnerClient.id}`}
                          >{`${partnerClient.id.substr(
                            0,
                            4
                          )}...${partnerClient.id.substr(
                            partnerClient.id.length - 4,
                            partnerClient.id.length
                          )}`}</Link>
                        </div>
                      </td>
                      <td className="td-clipboard">
                        <div>
                          <Tooltip content="Copied">
                            {(setShowTooltip) => (
                              <Button
                                type="button"
                                className="clipboard-button"
                                onClick={() => {
                                  copyToClipboard(partnerClient.id);
                                  setShowTooltip(true);
                                }}
                              >
                                <Icon name="clipboard" />
                              </Button>
                            )}
                          </Tooltip>
                        </div>
                      </td>
                      <td>
                        {partnerClient.name && partnerClient.surname
                          ? `${partnerClient.surname} ${partnerClient.name}`
                          : "-"}
                      </td>
                      <td className="td-color-black td-email">
                        {partnerClient.email_verified === true ? (
                          <Icon name="success_status" />
                        ) : (
                          <Icon name="reject_status" />
                        )}
                        &nbsp;&nbsp;
                        <a
                          href={`mailto:${partnerClient.email}`}
                          style={{ hyphens: "auto" }}
                        >
                          {partnerClient.email}
                        </a>
                      </td>
                      <td className="td-clipboard">
                        <div>
                          <Tooltip content="Copied">
                            {(setShowTooltip) => (
                              <Button
                                type="button"
                                className="clipboard-button"
                                onClick={() => {
                                  copyToClipboard(partnerClient.email);
                                  setShowTooltip(true);
                                }}
                              >
                                <Icon name="clipboard" />
                              </Button>
                            )}
                          </Tooltip>
                        </div>
                      </td>
                      <td>
                        <td>
                          {partnerClient.kyc === "VERIFIED" ? (
                            <a
                              href={partnerClient.sumsub_url as string}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="verification-col user-kyc-verifid">
                                <Icon name="success_status" />
                                <span>Verified</span>
                              </div>
                            </a>
                          ) : partnerClient.kyc === "NOT_VERIFY" ? (
                            <div className="verification-col user-kyc-no-verifid">
                              <Icon name="warning_check" />
                              <span>Not Verified</span>
                            </div>
                          ) : (
                            <a
                              href={partnerClient.sumsub_url as string}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="verification-col user-kyc-no-verifid">
                                <Icon name="reject_status" />
                                <span>Declined</span>
                              </div>
                            </a>
                          )}
                        </td>
                      </td>
                      <td>
                        {partnerClient.status !== "blocked" ? (
                          <div className="integrated user-status-td user-status-active">
                            Active
                          </div>
                        ) : (
                          <div className="not_integrated user-status-td user-status-blocked">
                            Blocked
                          </div>
                        )}
                      </td>
                      <td>
                        {moment(partnerClient.createdAt).format(
                          "DD MMM yyyy, hh:mm"
                        )}
                      </td>
                      <td>
                        <TableActionButton
                          isLast={index >= 0 && index < 2}
                          viewLink={`/users/details/${partnerClient.id}`}
                        />
                      </td>
                    </tr>
                  ))}
                </Table>
              )}
              <Pagination
                page={page}
                count={clientsCount}
                visibleCount={+visibleClientsCount}
                clearSearch={() => null}
                setLoading={setLoading}
                isLoading={isLoading}
                handlePagination={handlePagination}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartnerClientsTable;
