import { FC } from "react";

import { PaginationProps } from "./Pagination.props";
import Button from "../Button";

const Pagination: FC<PaginationProps> = ({
  page,
  count,
  visibleCount,
  isLoading,
  // clearSearch,
  setLoading,
  handlePagination,
}) => {
  const handlePaginate = (to) => {
    switch (to) {
      case "next":
        setLoading(true);
        handlePagination("next");
        // clearSearch();

        return;
      case "prev":
        setLoading(true);
        handlePagination("prev");
        // clearSearch();

        return;
      default:
        break;
    }
  };

  const lastPage = Math.ceil(count / +visibleCount);

  return (
    <div className="table--pagination">
      <span className="table--result">{count} results</span>
      <div className="pagination--buttons-wrapper">
        <Button
          onClick={() => handlePaginate("prev")}
          type="button"
          className="button-secondary"
          disabled={page === 1 || isLoading}
        >
          Previous
        </Button>
        <Button
          onClick={() => handlePaginate("next")}
          type="button"
          className="button-secondary"
          disabled={page === lastPage || isLoading}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default Pagination;
