import {
  FilterType,
  actionType,
  SET_CORE_FILTER,
  SET_CODE_FILTER,
  SET_SYMBOL_FILTER,
  SET_SUSPEND_STATUS,
  SET_MARKUP_RATE,
  SET_NEGATIVE_MARKUP_RATE,
  SET_TRIGGER_FILTER,
  SET_FIREBLOCK_NAME,
  SET_FIREBLOCK_CODE,
  SET_FIREBLOCK_TYPE,
  SET_FIREBLOCK_NATIVE_ASSET,
  SET_FIAT_NAME,
  SET_FIAT_SUSPEND_STATUS,
  SET_FIAT_SYMBOL,
  SET_SELL_STATUS,
  SET_FIAT_SELL_STATUS,
  SET_MAX_SELL_AMOUNT,
  RESET_FILTERS,
  SET_MIN_SELL_AMOUNT,
} from "./types";

export const setTriggerFilter = (
  trigger: FilterType["trigger_filter"]
): actionType => ({
  type: SET_TRIGGER_FILTER,
  payload: trigger,
});

export const setCodeFilter = (code: FilterType["code"]): actionType => ({
  type: SET_CODE_FILTER,
  payload: code,
});

export const setCoreFilter = (core: FilterType["core"]): actionType => ({
  type: SET_CORE_FILTER,
  payload: core,
});

export const setMarkupRate = (markupRate: number | undefined): actionType => ({
  type: SET_MARKUP_RATE,
  payload: markupRate,
});

export const setNegativeMarkupRate = (
  negativeMarkupRate: number | undefined
): actionType => ({
  type: SET_NEGATIVE_MARKUP_RATE,
  payload: negativeMarkupRate,
});

export const setMinSellAmount = (
  minSellAmount: number | undefined
): actionType => ({
  type: SET_MIN_SELL_AMOUNT,
  payload: minSellAmount,
});

export const setMaxSellAmount = (
  maxSellAmount: number | undefined
): actionType => ({
  type: SET_MAX_SELL_AMOUNT,
  payload: maxSellAmount,
});

export const setSymbol = (symbol: FilterType["symbol"]): actionType => ({
  type: SET_SYMBOL_FILTER,
  payload: symbol,
});

export const setSellStatus = (
  sellStatus: FilterType["is_sell_supported"]
): actionType => ({
  type: SET_SELL_STATUS,
  payload: sellStatus,
});

export const setSuspendStatus = (
  suspendStatus: FilterType["is_suspended"]
): actionType => ({
  type: SET_SUSPEND_STATUS,
  payload: suspendStatus,
});

export const setFireblockName = (
  fireblockName: FilterType["fireblock_name"]
): actionType => ({
  type: SET_FIREBLOCK_NAME,
  payload: fireblockName,
});

export const setFireblockCode = (
  fireblockCode: FilterType["fireblock_code"]
): actionType => ({
  type: SET_FIREBLOCK_CODE,
  payload: fireblockCode,
});

export const setFireblockNativeAsset = (
  fireblockNativeAsset: FilterType["fireblock_native_asset"]
): actionType => ({
  type: SET_FIREBLOCK_NATIVE_ASSET,
  payload: fireblockNativeAsset,
});

export const setFireblockType = (
  fireblockType: FilterType["fireblock_type"]
): actionType => ({
  type: SET_FIREBLOCK_TYPE,
  payload: fireblockType,
});

export const setFiatName = (fiatName: FilterType["fiat_name"]): actionType => ({
  type: SET_FIAT_NAME,
  payload: fiatName,
});

export const setFiatSymbol = (
  fiatSymbol: FilterType["fiat_symbol"]
): actionType => ({
  type: SET_FIAT_SYMBOL,
  payload: fiatSymbol,
});

export const setFiatSuspendStatus = (
  fiatSuspendStatus: FilterType["fiat_is_suspended"]
): actionType => ({
  type: SET_FIAT_SUSPEND_STATUS,
  payload: fiatSuspendStatus,
});

export const setFiatSellStatus = (
  fiatSellStatus: FilterType["fiat_is_sell_supported"]
): actionType => ({
  type: SET_FIAT_SELL_STATUS,
  payload: fiatSellStatus,
});

export const resetFilters = (): { type: string } => ({
  type: RESET_FILTERS,
});
