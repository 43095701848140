import React, {
  createContext,
  useReducer,
  useContext,
  FC,
  useCallback,
} from "react";
import * as fileSaver from "file-saver";
import { useAuthDispatchContext } from "providers/Auth";
import moment from "moment";
import { AxiosError } from "axios";

import { State, Dispatch, ActionTypes, DispatchContext } from "./types";
import reducer from "./reducers";
import { instance } from "utils/api";
import { showError } from "utils/helpers";

const initialState: State = {
  isLoading: true,
  partners: [],
  partnersCount: 0,
  partnerDetails: null,
  statistics: {
    order: 0,
    registration: 0,
    succeed: 0,
    view: 0,
  },
  partnerClients: [],
  partnerClientsCount: 0,
  partnerPromotions: {
    types: [{ id: "", desc: "" }],
    isLoaded: false,
    list: [],
    count: 0,
  },
  partnerStatistics: {
    verifiedUsers: 0,
    unVerifiedUsers: 0,
    amount: [],
  },
};

const PartnerStateContext = createContext<State | undefined>(undefined);
const PartnerDispatchContext = createContext<Dispatch | undefined>(undefined);

const PartnerProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <PartnerStateContext.Provider value={state}>
      <PartnerDispatchContext.Provider value={dispatch}>
        {children}
      </PartnerDispatchContext.Provider>
    </PartnerStateContext.Provider>
  );
};

const usePartnerStateContext = (): State => {
  const context = useContext(PartnerStateContext);

  if (typeof context === "undefined") {
    throw new Error(
      "usePartnerStateContext must be used within a PartnerProvider"
    );
  }

  return context;
};

const usePartnerDispatchContext = (): DispatchContext => {
  const dispatch = useContext(PartnerDispatchContext);
  const { signOut } = useAuthDispatchContext();
  const { partnerDetails } = usePartnerStateContext();

  if (typeof dispatch === "undefined") {
    throw new Error(
      "usePartnerDispatchContext must be used within a PartnerProvider"
    );
  }

  const getPartners = useCallback(
    async (
      page,
      partnerSearch,
      markupFrom,
      markupTo,
      commissionFrom,
      commissionTo,
      dateFrom,
      dateTo,
      partnerFilter,
      visiblePartnersCount,
      sorting,
      orderingPartner
    ) => {
      dispatch({ type: ActionTypes["SET_LOADING"], data: true });

      const getToDate = () => {
        if (moment().format("MM-DD") === moment(dateTo)?.format("MM-DD"))
          return undefined;

        return dateTo
          ? `${moment(dateTo).format("YYYY-MM-DD")}T${
              moment(dateTo)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      try {
        const res = await instance.get("/partners", {
          params: {
            page,
            keyword: partnerSearch === "" ? undefined : partnerSearch,
            fromMarkup:
              markupFrom === "" || window.isNaN(markupFrom)
                ? undefined
                : +markupFrom,
            toMarkup:
              markupTo === "" || window.isNaN(markupTo) ? undefined : +markupTo,
            fromCommission:
              commissionFrom === "" || window.isNaN(commissionFrom)
                ? undefined
                : +commissionFrom,
            toCommission:
              commissionTo === "" || window.isNaN(commissionTo)
                ? undefined
                : +commissionTo,
            fromDate: dateFrom
              ? moment(dateFrom).format("YYYY-MM-DD")
              : undefined,
            toDate: getToDate(),
            status:
              (partnerFilter === "not_integrated" && "Not integrated") ||
              (partnerFilter === "successful" && "Integrated") ||
              undefined,
            limit: visiblePartnersCount ? +visiblePartnersCount : 10,
            sortBy: sorting === "" ? undefined : sorting,
            orderBy: sorting === undefined ? undefined : orderingPartner,
          },
        });

        dispatch({ type: ActionTypes.SET_PARTNERS, data: res.data.data });
        dispatch({ type: ActionTypes["SET_LOADING"], data: false });
      } catch (err: unknown) {
        dispatch({ type: ActionTypes["SET_LOADING"], data: false });

        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const getPartnerClients = useCallback(
    async (page, id, filters) => {
      try {
        const res = await instance.get(`/user/partner/${id}`, {
          params: { page, limit: 10, ...filters },
        });

        dispatch({
          type: ActionTypes.SET_PARTNER_CLIENTS,
          data: res.data.data,
        });
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const downloadPartners = useCallback(
    async (
      visiblePartnersCount,
      sorting,
      orderingPartner,
      exportedPartner,
      checkedIDPartner,
      partners,
      arrayCheckingPartner,
      partnerSearch,
      dateFromPartner,
      dateToPartner,
      markupFrom,
      markupTo,
      commissionFrom,
      commissionTo,
      partnerFilter
    ) => {
      const getDate = (date, type = "to") => {
        if (moment().format("MM-DD") === moment(date)?.format("MM-DD"))
          return undefined;

        if (type === "from") {
          return date ? `${moment(date).format("YYYY-MM-DD")}` : undefined;
        }

        return date
          ? `${moment(date).format("YYYY-MM-DD")}T${
              moment(date)?.toISOString()?.split("T")[1]
            }`
          : undefined;
      };

      try {
        const res = await instance.get("/partners/download", {
          params: {
            page: 1,
            limit: +visiblePartnersCount,
            sortBy: sorting === "" ? undefined : sorting,
            orderBy: sorting === undefined ? undefined : orderingPartner,
            partners: partners !== undefined ? exportedPartner : undefined,
            keyword: partnerSearch === "" ? undefined : partnerSearch,
            fromMarkup:
              markupFrom === "" || window.isNaN(markupFrom)
                ? undefined
                : +markupFrom,
            toMarkup:
              markupTo === "" || window.isNaN(markupTo) ? undefined : +markupTo,
            fromCommission:
              commissionFrom === "" || window.isNaN(commissionFrom)
                ? undefined
                : +commissionFrom,
            toCommission:
              commissionTo === "" || window.isNaN(commissionTo)
                ? undefined
                : +commissionTo,
            fromDate: getDate(dateFromPartner, "from"),
            toDate: getDate(dateToPartner),
            status:
              (partnerFilter === "not_integrated" && "Not integrated") ||
              (partnerFilter === "successful" && "Integrated") ||
              undefined,
          },
          responseType: "blob",
        });

        const date = moment().format("DD-MM-YYY_hh:mm");

        fileSaver.saveAs(res.data as unknown as Blob, `partners-${date}.xlsx`);
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [signOut]
  );

  const getPartnerById = useCallback(
    async (id) => {
      try {
        const res = await instance.get(`/partners/details/${id}`);
        dispatch({
          type: ActionTypes.SET_PARTNER_DETAILS,
          data: res.data.data,
        });

        return res.data.data;
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);
      }
    },
    [dispatch, signOut]
  );

  const clearPartnerId = useCallback(() => {
    dispatch({
      type: ActionTypes.SET_PARTNER_DETAILS,
      data: undefined,
    });
  }, [dispatch]);

  const createPartner = useCallback(
    async (
      data,
      page,
      partnerSearch,
      markupFrom,
      markupTo,
      commissionFrom,
      commissionTo,
      dateFromPartner,
      dateToPartner,
      partnerFilter,
      visiblePartnersCount,
      sorting,
      orderingPartner
    ) => {
      try {
        await instance.post("/partners", data);

        getPartners(
          page,
          partnerSearch,
          markupFrom,
          markupTo,
          commissionFrom,
          commissionTo,
          dateFromPartner,
          dateToPartner,
          partnerFilter,
          visiblePartnersCount,
          sorting,
          orderingPartner
        );
      } catch (err: unknown) {
        if (
          ((err as AxiosError).response?.status === 401 &&
            !localStorage.getItem("refreshToken")) ||
          (err as AxiosError).response?.status === 401
        ) {
          return signOut();
        }

        showError(err);

        return Promise.reject(err);
      }
    },
    [getPartners, signOut]
  );

  const editPartner = useCallback(
    async (
      page,
      id,
      data,
      partnerSearch,
      markupFrom,
      markupTo,
      commissionFrom,
      commissionTo,
      dateFromPartner,
      dateToPartner,
      partnerFilter,
      visiblePartnersCount,
      orderingPartner
    ) => {
      try {
        await instance.put(`/partners/${id}`, data);

        getPartners(
          page,
          partnerSearch,
          markupFrom,
          markupTo,
          commissionFrom,
          commissionTo,
          dateFromPartner,
          dateToPartner,
          partnerFilter,
          visiblePartnersCount,
          undefined,
          orderingPartner
        );
      } catch (err: unknown) {
        if ((err as AxiosError).response?.status === 403) {
          return signOut();
        }

        showError(err);

        return Promise.reject(err);
      }
    },
    [getPartners, signOut]
  );

  const deletePartner = useCallback(
    async (
      id,
      page,
      partnerSearch,
      markupFrom,
      markupTo,
      commissionFrom,
      commissionTo,
      dateFromPartner,
      dateToPartner,
      partnerFilter,
      visiblePartnersCount,
      sorting,
      orderingPartner
    ) => {
      try {
        await instance.delete(`/partners/${id}`);
        getPartners(
          page,
          partnerSearch,
          markupFrom,
          markupTo,
          commissionFrom,
          commissionTo,
          dateFromPartner,
          dateToPartner,
          partnerFilter,
          visiblePartnersCount,
          sorting,
          orderingPartner
        );
      } catch (err: unknown) {
        if ((err as AxiosError).response?.status === 403) {
          return signOut();
        }

        showError(err);

        return Promise.reject(err);
      }
    },
    [getPartners, signOut]
  );

  const setLoading = (isLoading: boolean) =>
    dispatch({ type: ActionTypes.SET_LOADING, data: isLoading });

  const partnerId = partnerDetails?.id || "";
  const getPartnerPromotions = useCallback(
    async ({ limit, page }) => {
      try {
        const typesPromise = instance.get(`/admin/promotions/types`);

        const listPromise = instance.get(
          `/admin/promotions?partner_id=${partnerId}&page=${page}&limit=${limit}`
        );

        const [typesResponse, listResponse] = await Promise.all([
          typesPromise,
          listPromise,
        ]);

        dispatch({ type: ActionTypes.SET_PROMOTIONS_IS_LOADED, data: true });
        dispatch({
          type: ActionTypes.SET_PROMOTIONS_TYPES,
          data: typesResponse.data.data,
        });

        dispatch({
          type: ActionTypes.SET_PROMOTIONS_LIST,
          data: listResponse.data.data.rows,
        });
        dispatch({
          type: ActionTypes.SET_PROMOTIONS_COUNT,
          data: listResponse.data.data.count,
        });
      } catch (err: unknown) {
        showError(err);

        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partnerId]
  );

  const reloadPromotionsList = useCallback(() => {
    dispatch({ type: ActionTypes.SET_PROMOTIONS_IS_LOADED, data: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addPromotion = useCallback(
    async ({
      promotionId,
      feeReductionBank,
      feeReductionCard,
      startDate,
      endDate,
      description,
      buyFlow,
      sellFlow,
    }) => {
      try {
        const { data } = await instance.post(`/admin/promotions`, {
          promotion_id: promotionId,
          partner_id: partnerId,
          fee_reduction_instant_bank: feeReductionBank,
          fee_reduction_bank_tr: feeReductionCard,
          buy_flow: buyFlow,
          sell_flow: sellFlow,
          start_date: startDate,
          end_date: endDate,
          desc: description,
        });

        dispatch({
          type: ActionTypes.ADD_PROMOTION,
          data: data.data,
        });
      } catch (err: unknown) {
        showError(err);

        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partnerId]
  );

  const updatePromotion = useCallback(
    async ({ promotionId, startDate, endDate, description, ended }) => {
      try {
        const { data } = await instance.put(
          `/admin/promotions/${promotionId}`,
          {
            partner_id: partnerId,
            start_date: startDate,
            end_date: endDate,
            desc: description,
            ended,
          }
        );

        dispatch({
          type: ActionTypes.UPDATE_PROMOTIONS_LIST,
          data: data.data[0],
        });
      } catch (err: unknown) {
        showError(err);

        return Promise.reject(err);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [partnerId]
  );

  return {
    clearPartnerId,
    deletePartner,
    getPartners,
    downloadPartners,
    getPartnerById,
    createPartner,
    editPartner,
    getPartnerClients,
    setLoading,
    getPartnerPromotions,
    reloadPromotionsList,
    addPromotion,
    updatePromotion,
  };
};

export default PartnerProvider;
export { usePartnerDispatchContext, usePartnerStateContext };
