import { useHistory } from "react-router-dom";
import { exportPayout } from "services/balance";
import { useBalanceState } from "providers/Balance";
import { useAuthStateContext } from "providers/Auth";

import { Icon } from "components/base";
import Button from "components/Button";

const BalanceHeadline = (): JSX.Element => {
  const history = useHistory();
  const handlePayout = () => history.push("/payout");
  const { user } = useAuthStateContext();
  const { walletAddress, activeBalance, payoutToExport } = useBalanceState();

  const exportAll = () =>
    exportPayout({
      partnerId: user?.id as string,
      exportAll: true,
      address: walletAddress as string,
      type:
        activeBalance &&
        activeBalance?.charAt(0).toUpperCase() + activeBalance?.slice(1),
    });

  const exportSelected = () =>
    exportPayout({
      partnerId: user?.id as string,
      ...(activeBalance === "payout" && {
        payoutOrders: payoutToExport as string[],
      }),
      ...(activeBalance === "payin" && {
        payInOrders: payoutToExport as string[],
      }),
      address: walletAddress as string,
      exportAll: false,
    });

  return (
    <div className="balance-headline__container">
      <h1>Balance</h1>
      <div className="button-export__wrapper">
        <Button
          type="button"
          className="button--transparent"
          onClick={() => location.reload()}
        >
          <Icon name="rotate" />
        </Button>

        <Button
          type="button"
          className="button--transparent button--export"
          disabled={!payoutToExport?.length}
          onClick={exportSelected}
        >
          <Icon name="export_button" />
          Export Selected
        </Button>
        <Button
          type="button"
          className="button--transparent button--export"
          onClick={exportAll}
        >
          <Icon name="export_button" />
          Export
        </Button>
        <Button
          type="button"
          onClick={handlePayout}
          className="button-primary button-add-partner"
        >
          <Icon name="balance_payout" />
          Payout funds
        </Button>
      </div>
    </div>
  );
};

export default BalanceHeadline;
