import { useEffect, useState, useCallback } from "react";

const useCodeValidSec = (
  sec: number
): { seconds: number; reset: () => void } => {
  const [seconds, setSeconds] = useState(sec);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds - 1);
    }, 1000);

    if (seconds === 0) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [seconds]);

  const reset = useCallback(() => {
    setSeconds(sec);
  }, [sec]);

  return { seconds, reset };
};

export default useCodeValidSec;
