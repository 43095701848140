import { Client } from "providers/Clients/types";

import { ActionTypes, State, Action, Partner } from "./types";
import { IList } from "components/Promotions/types";

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionTypes.SET_LOADING: {
      return {
        ...state,
        isLoading: action.data as boolean,
      };
    }

    case ActionTypes.SET_PARTNERS: {
      const { rows, count } = action.data as { rows: Partner[]; count: number };

      return {
        ...state,
        isLoading: false,
        partners: rows,
        partnersCount: count,
      };
    }

    case ActionTypes.SET_PARTNER_DETAILS: {
      return { ...state, partnerDetails: action.data as Partner };
    }

    case ActionTypes.SET_PARTNER_CLIENTS: {
      const { rows, count, verified, not_verified, amounts } = action.data as {
        rows: Client[];
        count: number;
        verified: number;
        not_verified: number;
        amounts: { total_all_amount: { currency: string; total: number }[] };
      };

      return {
        ...state,
        partnerClients: rows,
        partnerClientsCount: count,
        partnerStatistics: {
          verifiedUsers: verified,
          unVerifiedUsers: not_verified,
          amount: amounts.total_all_amount,
        },
      };
    }

    case ActionTypes.SET_PROMOTIONS_IS_LOADED: {
      return {
        ...state,
        partnerPromotions: {
          ...state.partnerPromotions,
          isLoaded: action.data as boolean,
        },
      };
    }

    case ActionTypes.SET_PROMOTIONS_LIST: {
      return {
        ...state,
        partnerPromotions: {
          ...state.partnerPromotions,
          list: action.data as IList[],
        },
      };
    }

    case ActionTypes.UPDATE_PROMOTIONS_LIST: {
      const updatedPromotion = action.data as IList;
      const newList = state.partnerPromotions.list.filter(
        (el) => el.id !== updatedPromotion.id
      );

      newList.push(updatedPromotion);

      return {
        ...state,
        partnerPromotions: {
          ...state.partnerPromotions,
          list: newList,
        },
      };
    }

    case ActionTypes.ADD_PROMOTION: {
      const newPromotion = action.data as IList;
      const newList = state.partnerPromotions.list;

      newList.push(newPromotion);

      return {
        ...state,
        partnerPromotions: {
          ...state.partnerPromotions,
          list: newList,
        },
      };
    }

    case ActionTypes.SET_PROMOTIONS_TYPES: {
      return {
        ...state,
        partnerPromotions: {
          ...state.partnerPromotions,
          types: action.data as [],
        },
      };
    }

    case ActionTypes.SET_PROMOTIONS_COUNT: {
      return {
        ...state,
        partnerPromotions: {
          ...state.partnerPromotions,
          count: action.data as number,
        },
      };
    }

    default:
      return state;
  }
};

export default reducer;
