import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
// import { useOrderFilters } from "providers/OrderFilter";
import { Partner } from "providers/Partners/types";
import { ACTION_PANEL_ACCESS } from "services/roleService/action/action";

import { Icon } from "components/base";
import ReactConfirmChangeStatus from "../../ReactConfirmChangeStatus";
import useRole from "hooks/useRole";

interface Props {
  id?: string;
  viewLink?: string;
  openModal?: () => void | undefined;
  ordersId?: string;
  isLast?: boolean;
  buttons?: {
    fromPage?: string;
    id?: string;
    userName?: string;
    title?: string;
    title2?: string;
    title3?: string;
    onTitle3Click?: () => void;
    descriptionTitle?: string;
    role?: string;
    onClick: () => void;
    partnerName?: Partner;
  }[];
}

const TableActionButton: FC<Props> = ({
  buttons,
  openModal,
  // ordersId,
  viewLink,
  isLast,
}) => {
  // states
  const [isVisible, setVisibility] = useState(false);
  const role = useRole();
  // refs
  const ref = useRef<HTMLDivElement>(null);
  // const { orderID } = useOrderFilters();

  useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (ref.current && !ref.current?.contains(event?.target as Node)) {
        setVisibility(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return role ? (
    <div className="text--end" style={{ position: "relative" }}>
      <a
        className="btn btn--action"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="top-end"
        onClick={() => setVisibility((prev) => !prev)}
      >
        <span className="svg-icon svg-icon-5 m-0">
          <Icon name="action" />
        </span>
      </a>
      <div
        className={`menu menu-sub menu-sub-dropdown change-status--menu ${
          isVisible && "show"
        } ${isLast ? "last" : ""}`}
        data-kt-menu="true"
        ref={ref}
      >
        {viewLink && (
          <div className="menu-item mt-0">
            <Link
              target={`${
                buttons?.[0].fromPage === "orderView" ? "" : "_blank"
              }`}
              to={viewLink}
              className="menu-link"
              onClick={() => {
                openModal;
                setVisibility(false);
              }}
            >
              View
            </Link>
          </div>
        )}
        {ACTION_PANEL_ACCESS.action[role] &&
          buttons &&
          buttons.map((button, index) => (
            <div key={index}>
              <ReactConfirmChangeStatus
                setVisibility={setVisibility}
                options={button}
              />
            </div>
          ))}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TableActionButton;
