import React, { useEffect, useRef } from "react";
import classNames from "classnames";
import Tooltip from "@atlaskit/tooltip";
import { Chart } from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

import generateChart from "./generateChart";
import { IChart } from "./types";
import info from "../../../assets/img/info.svg";
import exapmleChart from "../../../assets/img/chart-example.svg";

import "./styles.scss";

Chart.register(zoomPlugin);

export default function Line({
  data,
  progress,
  title,
  infoContenet,
  count,
  className,
  subCount,
  unit,
  aspectRatio,
}: IChart): JSX.Element {
  const canvasRef = useRef<null | HTMLCanvasElement>(null);
  const chartRef = useRef<null | Chart<"line", string[], string>>(null);

  useEffect(() => {
    const element = canvasRef.current;
    generateChart({
      element,
      chartRef,
      data,
      progress,
      unit,
      aspectRatio,
    });
  }, [canvasRef, data, progress, unit, aspectRatio]);

  return (
    <div
      className={classNames("line-chart-container", {
        [className || ""]: className,
      })}
    >
      <div className="line-chart-header">
        <div className="line-chart-descripton">
          <p className="line-chart-descripton-title">{title}</p>
          <Tooltip content={infoContenet}>
            <img src={info} alt="image" className="cursor-pointer" />
          </Tooltip>

          <p
            className={classNames("line-chart-descripton-progress", {
              green: progress >= 0,
              red: progress < 0,
            })}
          >
            {String(progress) !== "0" ? `${Number(progress)}%` : `N/A`}
          </p>
        </div>
        <p className="line-chart-count">{count}</p>
        {subCount ? <p className="line-chart-sub-count">{subCount}</p> : null}
      </div>

      {data.x.length & data.y.length ? (
        <canvas ref={canvasRef} />
      ) : (
        <img src={exapmleChart} alt="image" className="example-chart" />
      )}
    </div>
  );
}
