import ContentLoader from "react-content-loader";

const BalanceBannerSkeleton = (): JSX.Element => {
  return (
    <ContentLoader width="100%" height="124px">
      <rect x="0" y="0" width="100%" height="100" />
    </ContentLoader>
  );
};

export default BalanceBannerSkeleton;
