import React, { useContext, useEffect, useState } from "react";
import Popover from "react-tiny-popover";
import {
  AdminUsersContext,
  AdminUsersDispatchContext,
} from "providers/AdminUsers";

import { Icon } from "components/base";
import Button from "components/Button";
import { instance } from "utils/api";

function EditPencilPopOver({
  id,
  userRole,
}: {
  id: string;
  userRole: string;
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const { roles } = useContext(AdminUsersContext);
  const { dispatch } = useContext(AdminUsersDispatchContext);
  const [innerRole, setInnerRole] = useState(userRole);

  const handleRoleChange = async (roleId: string) => {
    try {
      const foundRole = roles.find((el) => el.id === roleId);
      setInnerRole(foundRole?.name as string);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeClick = async () => {
    const foundRole = roles.find((el) => el.name === innerRole);
    await instance.put(`/admin/${id}`, {
      role_id: foundRole?.id,
    });
    dispatch({
      type: "UPDATE_USER_ROLE",
      payload: {
        userId: id,
        roles: foundRole,
      },
    });
    setIsOpen(false);
  };

  useEffect(() => {
    setInnerRole(userRole);
  }, [isOpen, userRole]);

  return (
    <Popover
      containerClassName={"className"}
      isOpen={isOpen}
      onClickOutside={() => {
        setIsOpen(false);
      }}
      position={"bottom"}
      padding={0}
      //   contentLocation={2}
      content={() =>
        roles ? (
          <div className="popover__content">
            <div className="popover-stats__container">
              <h1 className="popover-stats__title">PERMISSION</h1>
              <div className="popover-stats__container--radio">
                {roles.map((role) => (
                  <div className="radio__wrapper" key={role.id}>
                    <label className="radio__label">
                      <input
                        type="radio"
                        name="radio"
                        checked={innerRole === role.name}
                        onChange={() => handleRoleChange(role.id)}
                      />
                      <span className="radio__checkmark"></span>
                      {role.name.toUpperCase()}
                    </label>
                  </div>
                ))}
              </div>
              <div className="radio-buttons__wrapper">
                <Button
                  type="button"
                  className="button-cancel"
                  onClick={() => setIsOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleChangeClick}
                  type="button"
                  className="button-submit"
                >
                  Change
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <h1></h1>
        )
      }
    >
      <Button
        type="button"
        className="button--transparent button-tr-status"
        onClick={() => setIsOpen(true)}
      >
        <Icon name="edit_pencil" />
      </Button>
    </Popover>
  );
}

export default EditPencilPopOver;
