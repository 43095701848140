import { FC, createContext, useReducer, useContext, useMemo } from "react";
import { Manager } from "socket.io-client";
import { useAuthStateContext } from "providers/Auth";

import {
  NotificationProviderProps,
  State,
  Dispatch,
  DispatchContext,
  ActionTypes,
  NotificationType,
  NotificationsType,
} from "./types";
import reducer from "./reducers";
import { socketDecoder } from "utils/helpers";
import useRole from "hooks/useRole";

export const NotificationsStateContext = createContext<State | null>(null);
export const NotificationsDispatchContext = createContext<Dispatch>(() => null);

const NotificationsProvider: FC<NotificationProviderProps> = ({ children }) => {
  const { user } = useAuthStateContext();
  const role = useRole();
  const token = localStorage.getItem("token");
  const id = localStorage.getItem("user-id");

  const manager = new Manager(process.env.REACT_APP_SOCKET_URL as string, {
    transports: ["websocket"],
    autoConnect: false,
    query: {
      token,
    },
  });

  // const mainSocket = manager.socket("/", {
  //   auth: {
  //     token,
  //   },
  // });

  const adminSocket = manager.socket(`/admin`, {
    auth: {
      token,
    },
  });

  const partnerSocket = manager.socket(
    `/partner-${user?.name?.replace(/ /, "_")}`,
    {
      auth: {
        token,
      },
    }
  );

  const initialState = {
    isLoading: false,
    transactionNotifications: new Set(),
    notificationCount: 0,
    renderedCount: 0,
    unwatchedCount: 0,
    page: 1,
    socket: role === "super_admin" ? adminSocket : partnerSocket,
    id: localStorage.getItem("user-id"),
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const renderSocket = (socket, type) => {
    socket.connect();

    socket.on("connect", () => {
      socket.emit("get_notifications", {
        page: 1,
        id: id,
      });

      socket.on("get_notifications_info", (data) => {
        const decodedData = socketDecoder(data) as NotificationsType;

        dispatch({
          type: ActionTypes.SET_TRANSACTION_NOTIFICATIONS,
          payload: decodedData as NotificationsType,
        });
      });

      socket.on("mark_us_watched_info", (data) => {
        const decodedData = socketDecoder(data) as {
          message: string;
        };

        if (decodedData.message === "success") {
          dispatch({ type: ActionTypes.SET_UNWATCHED_COUNT, payload: 0 });
        }
      });

      if (type === "adminSocket") {
        socket.on("new_entity_created", (data) => {
          const newEntity = (socketDecoder(data) as { data: NotificationType })
            .data;

          if (newEntity.entityType === "client") return;

          dispatch({
            type: ActionTypes.SET_NEW_CREATED_ENTITY,
            payload: newEntity,
          });
        });
      }

      if (type === "partnerSocket") {
        socket.on("partner_new_entity_created", (data) => {
          const newEntity = (socketDecoder(data) as { data: NotificationType })
            .data;

          if (newEntity.entityType === "client") return;

          dispatch({
            type: ActionTypes.SET_NEW_CREATED_ENTITY,
            payload: newEntity,
          });
        });
      }
    });

    return () => {
      socket.disconnect();
      socket.off("get_notifications_info");
      socket.off("mark_us_watched_info");
      socket.off("new_entity_created");
    };
  };

  useMemo(() => {
    if (role === "super_admin") {
      renderSocket(adminSocket, "adminSocket");
    }

    if (role === "partner") {
      renderSocket(partnerSocket, "partnerSocket");
    }

    // eslint-disable-next-line
  }, [role]);

  return (
    <NotificationsStateContext.Provider value={state}>
      <NotificationsDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsStateContext.Provider>
  );
};

export const useNotificationStateContext = (): State => {
  const context = useContext(NotificationsStateContext);

  if (typeof context === "undefined") {
    throw new Error("useOrderStateContext must be used within a OrderProvider");
  }

  return context as State;
};

export const useNotificationDispatchContext = (): DispatchContext => {
  const dispatch = useContext(NotificationsDispatchContext);

  const setAsWatched = (id: string, socket) => {
    if (socket) {
      socket.emit("mark_us_watched", { id });
    }
  };

  const setWatched = () => {
    dispatch({
      type: ActionTypes.SET_NOTIFICATIONS_WATCHED,
      payload: true,
    });

    return;
  };

  const paginate = () => {
    dispatch({ type: ActionTypes.SET_PAGE, payload: true });
  };

  return {
    setAsWatched,
    setWatched,
    paginate,
  };
};

export default NotificationsProvider;
