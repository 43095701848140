import { Socket } from "socket.io-client";
import { v4 as uuid } from "uuid";

import {
  ActionTypes,
  State,
  Action,
  NotificationType,
  NotificationsType,
} from "./types";

const newEntityCreated = (state: State, payload: NotificationType) => {
  state = {
    ...state,
    unwatchedCount: state.unwatchedCount + 1,
    notificationCount: state.notificationCount + 1,
    transactionNotifications: new Set([
      JSON.stringify({
        id: payload.entityId,
        status: payload.entityStatus,
        title: payload.entityTitle,
        isWatched: payload.isWatched,
        type: payload.entityType,
        ...(payload?.entityPartnerId && {
          partner_id: payload?.entityPartnerId,
        }),
        ...(payload.entityType !== "order" && { uuid: uuid() }),
      }),
      ...state.transactionNotifications,
    ]),
  };

  return state;
};

const getNotifications = (state: State, decodedData: NotificationsType) => {
  if (state.page === 1) {
    state = {
      ...state,
      notificationCount:
        typeof decodedData?.counts?.total !== "undefined"
          ? decodedData?.counts?.total
          : 0,
      unwatchedCount:
        typeof decodedData?.counts?.unwatched !== "undefined"
          ? decodedData?.counts?.unwatched
          : 0,
    };
  }

  const notifications = (decodedData as NotificationsType)?.data.reduce(
    (
      filtered: NotificationType[],
      curr: {
        data: NotificationType;
        watchedAt: string | undefined;
        partner_id?: string;
      }
    ) => {
      const parsedItem = curr.data;

      if (
        parsedItem.entityType === "order" ||
        parsedItem.entityType === "warning" ||
        parsedItem.entityType === "info"
      ) {
        const data = {
          id: parsedItem?.entityId,
          status: parsedItem?.entityStatus,
          title: parsedItem?.entityTitle,
          isWatched: !!curr?.watchedAt,
          type: parsedItem?.entityType,
          uuid: null,
          ...(parsedItem?.entityPartnerId && {
            partner_id: parsedItem?.entityPartnerId,
          }),
        };

        if (
          parsedItem.entityStatus.toLocaleLowerCase().replaceAll(" ", "_") ===
          "failed_transactions"
        ) {
          data.uuid = uuid();
        }

        filtered = [...filtered, JSON.stringify(data)] as NotificationType[];
      }

      return filtered;
    },
    []
  );

  state = {
    ...state,
    transactionNotifications: new Set([
      ...state.transactionNotifications,
      ...(notifications as NotificationType[]),
    ]),
    renderedCount:
      state.page * [...state.transactionNotifications, ...notifications].length,
    isLoading: false,
  };

  return state;
};

const setNotificationsWatched = (state: State, payload: boolean) => {
  if (payload) {
    const watchedNotifications = Array.from(state.transactionNotifications).map(
      (item) => {
        if (!JSON.parse(item as string).isWatched) {
          return JSON.stringify({
            ...JSON.parse(item as string),
            isWatched: true,
          });
        }

        return item;
      }
    );

    state = {
      ...state,
      transactionNotifications: new Set([...watchedNotifications]),
    };
  }

  return state;
};

const paginate = (state: State, payload: boolean) => {
  if (state.transactionNotifications?.size >= state.notificationCount)
    return state;

  if (state.socket && state.id && payload) {
    state.socket.emit("get_notifications", {
      page: state.page + 1,
      id: state.id,
    });

    state = {
      ...state,
      page: state.page + 1,
      isLoading: true,
    };
  }

  return state;
};

const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case ActionTypes.SET_TRANSACTION_NOTIFICATIONS:
      return getNotifications(state, payload as NotificationsType);
    case ActionTypes.SET_PAGE:
      return paginate(state, payload as boolean);
    case ActionTypes.SET_NOTIFICATION_COUNT:
      return {
        ...state,
        notificationCount: payload as number,
      };
    case ActionTypes.SET_RENDERED_COUNT:
      return {
        ...state,
        renderedCount: payload as number,
      };
    case ActionTypes.SET_UNWATCHED_COUNT:
      return {
        ...state,
        unwatchedCount: payload as number,
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        isLoading: payload as boolean,
      };
    case ActionTypes.SET_NEW_CREATED_ENTITY:
      return newEntityCreated(state, payload as NotificationType);

    case ActionTypes.SET_NOTIFICATIONS_WATCHED:
      return setNotificationsWatched(state, payload as boolean);
    case ActionTypes.SET_SOCKET:
      return {
        ...state,
        socket: payload as Socket,
      };
    default:
      return state;
  }
};

export default reducer;
