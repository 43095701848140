import { useEffect, useState } from "react";
import { filterFiatByRole } from "services/roleService/widgetConsole/fiat";
import { useWidgetFiltersState } from "providers/WidgetFilter";
import { useSellFlowDispatch, useSellFlowState } from "providers/SellFlow";

import { Table } from "components/base";
import TableSkeleton from "components/TableSkeleton";
import useRole from "hooks/useRole";
import NotFound from "pages/NotFound";
import FiatColumn from "pages/WidgetConsole/FiatColumn";
import Pagination from "components/Pagination";
import FiatModal from "pages/WidgetConsole/FiatModal";

const FiatList = (): JSX.Element => {
  const role = useRole();
  const { getFiatList, paginate, setLoading, updateFiatStatus, setChosenFiat } =
    useSellFlowDispatch();

  const { fiatList, isLoading, fiatCount, page, chosenFiat, dataFetched } =
    useSellFlowState();

  const {
    fiat_name,
    fiat_symbol,
    fiat_is_sell_supported,
    trigger_filter,
    min_sell_amount,
    max_sell_amount,
  } = useWidgetFiltersState();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (trigger_filter) {
      getFiatList(
        page,
        fiat_name,
        fiat_symbol,
        fiat_is_sell_supported,
        min_sell_amount,
        max_sell_amount
      );
    }

    // eslint-disable-next-line
  }, [trigger_filter]);

  const handlePagination = (to) => {
    switch (to) {
      case "next":
        paginate({ page: page + 1, type: "fiat" });

        return;
      case "prev":
        paginate({ page: page - 1, type: "fiat" });

        return;

      default:
        return;
    }
  };

  return (
    <div className="table--row">
      {(fiatList.length === 0 && dataFetched && !isLoading && <NotFound />) || (
        <div className="user-list-body">
          <div>
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <Table
                headers={[
                  {
                    title: "ID",
                    val: "id",
                    w: "fit-content",
                    className: "th-email",
                  },
                  {
                    title: "",
                    val: "id_clipboard",
                    w: "",
                    className: "th-crypto-id",
                  },
                  {
                    title: "Status",
                    val: "suspend_status",
                    w: "fit-content",
                    className: "suspend-status",
                  },
                  {
                    title: "",
                    val: "status_edit",
                    w: "",
                  },
                  {
                    title: "Max Sell Amount",
                    val: "max_buy_amount",
                    w: "fit-content",
                  },
                  {
                    title: "Min Sell Amount",
                    val: "min_buy_amount",
                    w: "fit-content",
                  },
                  {
                    title: "Name",
                    val: "name",
                    w: "fit-content",
                  },
                  {
                    title: "Sign",
                    val: "sign",
                    w: "fit-content",
                  },
                  {
                    title: "Symbol",
                    val: "symbol",
                    w: "fit-content",
                  },
                  {
                    title: "Date",
                    val: "date",
                    w: "fit-content",
                  },
                  { title: "Action", val: "action", w: "fit-content" },
                ].filter((item) => role && filterFiatByRole(item, role))}
              >
                <FiatColumn
                  flow="sell"
                  updateFiatStatus={updateFiatStatus}
                  getFiatList={getFiatList}
                  setChosenFiat={setChosenFiat}
                  page={page}
                  setIsOpen={setIsOpen}
                  fiatList={fiatList}
                  role={role}
                />
              </Table>
            )}
          </div>
          <Pagination
            page={page}
            count={fiatCount as number}
            visibleCount={10}
            clearSearch={() => null}
            setLoading={setLoading}
            isLoading={isLoading}
            handlePagination={handlePagination}
          />
        </div>
      )}
      {chosenFiat && (
        <FiatModal
          chosenFiat={chosenFiat}
          page={page}
          updateFiatStatus={updateFiatStatus}
          getFiatList={getFiatList}
          isOpen={isOpen}
          closeModal={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default FiatList;
