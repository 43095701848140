import { FC, useState } from "react";
import { useBalanceDispatchContext, useBalanceState } from "providers/Balance";
import moment from "moment";

import { Icon } from "components/base";
import { BalanceRowProps } from "./Balance.props";
import { balanceStatus } from "utils/constants";
import useRole from "hooks/useRole";
import Popover from "components/Popover";
import Button from "components/Button";
import BalancePopover from "./BalancePopover";
import DescriptionPopup from "./DescriptionPopup";

const BalanceRow: FC<BalanceRowProps> = ({
  balanceItem,
  payoutStatuses,
  partnerId,
  partnerName,
}): JSX.Element => {
  const role = useRole();
  const {
    updateBalancePayout,
    setPayoutExport,
    setBalanceLoading,
    getPartnerBalance,
    setWalletAddress,
    setAvailableBalance,
  } = useBalanceDispatchContext();

  const { payoutToExport, exportAll, activeBalance } = useBalanceState();
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [isDescPopupOpen, setIsDescPopupOpen] = useState(false);
  const [showMore] = useState(true);

  const [chosenStatus, setChosenStatus] = useState(balanceItem.status);
  const [description, setDescription] = useState(balanceItem.admin_desc);
  const [loading, setLoading] = useState(false);

  const updateStatus = async () => {
    setLoading(true);

    try {
      await updateBalancePayout({
        id: balanceItem.id as string,
        status: chosenStatus as string,
      });

      setIsPopoverOpen(false);
      setLoading(false);

      setBalanceLoading(true);
      getPartnerBalance({
        partnerId: partnerId as string,
        partnerName: partnerName as string,
        asset: process.env.REACT_APP_BALANCE_ASSET as string,
      })
        .then((response) => {
          if (response?.data?.data) {
            setWalletAddress(response?.data.data.address);
            setAvailableBalance(response?.data.data?.available);
            setTimeout(() => setBalanceLoading(false), 1200);
          }
        })
        .catch(() => setBalanceLoading(false));
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const updateDesc = async () => {
    if (!description || balanceItem.desc === description) return;
    setLoading(true);

    try {
      await updateBalancePayout({
        id: balanceItem.id as string,
        desc: description as string,
      });

      setIsDescPopupOpen(false);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleClickBalance = () => {
    setPayoutExport(balanceItem.id as string);
  };

  return (
    <tr className={`user-list-tr ${showMore ? "align-top" : ""}`}>
      <td>
        <input
          onChange={handleClickBalance}
          className={`form-check-input user-list-checkbox`}
          checked={
            payoutToExport?.includes(balanceItem.id as string) || exportAll
          }
          type="checkbox"
          value={balanceItem.id ?? undefined}
        />
      </td>
      <td className="td-color-black">{balanceItem.id}</td>
      <td className="td-color-gray">
        {moment(balanceItem.createdAt).format("DD MMM yyyy, hh:mm")}
      </td>
      <td className="td-color-gray">{balanceItem.type}</td>
      <td className="td-color-black">{balanceItem.amount}</td>
      <td className="td-color-gray">{balanceItem.asset}</td>
      <td
        className={
          role === "super_admin" && activeBalance === "payout"
            ? "balance-status"
            : ""
        }
      >
        <div
          className={`tr-status user-status-td ${
            balanceItem?.status
              ? balanceStatus[
                  balanceItem.status.toLowerCase().replace(/\s/g, "_")
                ]?.className
              : ""
          }`}
        >
          {balanceItem.status}
          {balanceItem?.status && (
            <Icon
              name={
                balanceStatus[
                  balanceItem.status.toLowerCase().replace(/\s/g, "_")
                ].icon
              }
            />
          )}
        </div>
      </td>
      {role === "super_admin" && activeBalance === "payout" && (
        <td className="td-edit-status td-clipboard">
          {((balanceItem?.status &&
            balanceItem.status.toLowerCase() === "in review") ||
            balanceItem.status?.toLowerCase() === "in progress") && (
            <Popover
              isPopoverOpen={isPopoverOpen}
              setIsPopoverOpen={setIsPopoverOpen}
              position={["bottom"]}
              className="filter__popover"
              arrowStyle={{ left: "60%" }}
              contentLocation={(popoverState) => {
                return {
                  top: popoverState.nudgedTop,
                  left: popoverState.targetRect.left - 150,
                };
              }}
              component={
                <Button
                  type="button"
                  className="button--transparent button-tr-status"
                  onClick={() => setIsPopoverOpen(true)}
                >
                  <Icon name="edit_pencil" />
                </Button>
              }
            >
              <BalancePopover
                payoutStatuses={payoutStatuses}
                balanceStatus={balanceItem.status as string}
                chosenStatus={chosenStatus as string}
                setChosenStatus={setChosenStatus}
                handleSubmit={updateStatus}
                closePopover={() => setIsPopoverOpen(false)}
                loading={loading}
              />
            </Popover>
          )}
        </td>
      )}
      <td className="td-color-gray">{balanceItem.address}</td>
      <td className="td-color-gray blockchain-link">
        {balanceItem?.tx_id ? (
          <a
            href={`https://blockchair.com/ru/bitcoin/transaction/${balanceItem?.tx_id}`}
            target="_blank"
            rel="noreferrer"
          >
            View Blockchain
          </a>
        ) : (
          "View Blockchain"
        )}
      </td>
      <td
        className={`td-color-gray balance-description description ${
          showMore ? "show-more" : ""
        }`}
      >
        <p>{balanceItem.admin_desc ?? "-"}</p>
      </td>
      {role === "super_admin" && activeBalance === "payout" && (
        <td className="td-edit-status td-clipboard">
          <Popover
            isPopoverOpen={isDescPopupOpen}
            setIsPopoverOpen={setIsDescPopupOpen}
            position={["bottom"]}
            className="filter__popover"
            arrowStyle={{ left: "85%" }}
            contentLocation={(popoverState) => {
              return {
                top: popoverState.nudgedTop,
                left: popoverState.targetRect.left - 200,
              };
            }}
            component={
              <Button
                type="button"
                className="button--transparent button-tr-status"
                onClick={() => setIsDescPopupOpen(true)}
              >
                <Icon name="edit_pencil" />
              </Button>
            }
          >
            <DescriptionPopup
              description={description}
              setDescription={setDescription}
              updateDescription={updateDesc}
              closePopup={() => setIsDescPopupOpen(false)}
              loading={loading}
            />
          </Popover>
        </td>
      )}
    </tr>
  );
};

export default BalanceRow;
