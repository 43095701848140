import { FC, useState } from "react";
import moment from "moment";

import { Icon } from "components/base";
import EventBadge from "./EventBadge";
import CodeBlock from "./CodeBlock";
import { ExpandableProps } from "./EventDetails.props";

const ExpandableRow: FC<ExpandableProps> = ({
  eventDetails,
  eventCode,
  webhook_log,
}): JSX.Element => {
  const [expanded, setExpanded] = useState(false);

  const toggle = () => setExpanded((prev) => !prev);

  return eventDetails ? (
    <>
      <tr onClick={toggle}>
        <td>
          <div
            className={`event-details__status-wrapper ${
              expanded ? "active" : ""
            }`}
          >
            <Icon name="events_chevron" />
            <EventBadge status={eventDetails.delivered} />
          </div>
        </td>
        <td>{eventDetails.partner.url}</td>
        <td> {moment(webhook_log?.createdAt).format("DD MMM yyyy, hh:mm")}</td>
        <td>
          {/* <button>
            <Icon name="payout_refresh" /> Resend
          </button> */}
        </td>
      </tr>
      {expanded && (
        <tr className="event-details__request-tr">
          <td className="event-details__request-td" colSpan={4}>
            <div className="event-details__request-container">
              <div className="event-details__status-info">
                <span>HTTP status code</span>
                <span>{webhook_log.status}</span>
              </div>
              <div className="event-details__request-info">
                <span>Request to your endpoint</span>
                <div className="event-details__code-snippet">
                  <CodeBlock code={eventCode} />
                </div>
              </div>
              <div className="event-details__response-info">
                <span>Response</span>
                <div>
                  <span>{webhook_log.response}</span>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )}
    </>
  ) : (
    <div>null</div>
  );
};

export default ExpandableRow;
