import { FC, useMemo } from "react";
import { Page, Text, Image, View, Document, Font } from "@react-pdf/renderer";
import moment from "moment";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SF_Pro from "../../fonts/SF-Pro.otf";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import SF_Mono from "../../fonts/SFMonoRegular.woff";
import LogoText from "../base/Icon/icons/logo-text.png";
// import ApplePay from "../base/Icon/icons/apple-pay.png";
// import VisaPay from "../base/Icon/icons/visa-pay.png";
// import MasterPay from "../base/Icon/icons/master-pay.png";
import { InvoiceProps } from "./InvoiceSell.props";
import { styles } from "./InvoiceSell.styles";

Font.register({ family: "SF-Pro", src: SF_Pro });
Font.register({ family: "SF-Mono", src: SF_Mono });

const InvoiceSell: FC<InvoiceProps> = ({ order }): JSX.Element => {
  const renderStatus = useMemo(
    () => (status) => {
      switch (status) {
        case "SUBMITTED":
        case "PAYOUT_EXECUTRED":
        case "RELEASED":
        case "COMPLETED":
        case "COMPLETED_BANK":
          return (
            <Text style={styles.status_success}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Text>
          );
        case "CONFIRMING":
        case "QUEUED":
        case "PROCESSING_BANK":
          return (
            <Text style={styles.status_pending}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Text>
          );
        case "INSUFFICIENT_FUNDS":
        case "FAILED":
        case "BLOCKED":
        case "CANCELLED":
        case "FAILED_BANK":
        case "CANCELLED_BANK":
        case "PAYOUT_FAILED":
          return (
            <Text style={styles.status_failed}>
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Text>
          );
        default:
          return "-";
      }
    },
    []
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.invoice_container}>
          <View wrap={true} style={styles.image_container}>
            <Image style={styles.image} src={LogoText} />
          </View>
          <View style={styles.header_container}>
            <View style={styles.header_left_block}>
              <Text style={styles.personal_info}>{order?.account_name}</Text>
            </View>
            <View style={styles.email_block}>
              <Text style={styles.email_info}>{order?.email}</Text>
            </View>
          </View>
          <View style={styles.main_content_wrapper}>
            <View style={styles.content_keys_wrapper}>
              <Text style={styles.content_key}>Transaction ID</Text>
              <Text style={styles.content_key}>Customer ID</Text>
              <Text style={styles.content_key}>Partner ID</Text>
              <Text style={styles.content_key}>Wallet</Text>
              <Text style={styles.content_key}>Amount</Text>
              <Text style={styles.content_key}>Fiat</Text>
              <Text style={styles.content_key}>Asset</Text>
              <Text style={styles.content_key}>Partner Processing Fee</Text>
              <Text style={styles.content_key}>Price</Text>
              <Text style={styles.content_key}>Tracking ID</Text>
              <Text style={styles.content_key}>Network</Text>
              <Text style={styles.content_key}>Network Fee</Text>
              <Text style={styles.content_key}>Profit</Text>
              <Text style={styles.content_key}>Spot Price</Text>
              <Text style={styles.content_key}>Swaps Revenue</Text>
              <Text style={styles.content_key}>Partner Commission</Text>
              <Text style={styles.content_key}>Cost Of Service</Text>
              <Text style={styles.content_key}>Quantity</Text>
              <Text style={styles.content_key}>Final Asset Quantity</Text>
              <Text style={styles.content_key}>Account IBAN</Text>
              <Text style={styles.content_key}>Failure Reason</Text>
              <Text style={styles.content_key}>Merchant ID</Text>
              <Text style={styles.content_key}>Bank Type</Text>
              <Text style={styles.content_key}>Status</Text>
              <Text style={styles.content_key}>TX ID</Text>
              <Text style={styles.content_key}>Billing Country</Text>
              <Text style={styles.content_key}>Date</Text>
            </View>
            <View style={styles.content_values_wrapper}>
              <Text style={styles.content_value}>{order.id ?? "-"}</Text>
              <Text style={styles.content_value}>{order.client_id ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order?.partner_id ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order.address ?? "-"}</Text>
              <Text style={styles.content_value}>{order.amount ?? "-"}</Text>
              <Text style={styles.content_value}>{order.currency ?? "-"}</Text>
              <Text style={styles.content_value}>{order.asset ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order.partners_rate ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order?.price ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order?.transaction_id ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order.network ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order.network_fee ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order.profit ?? "-"}</Text>
              <Text style={styles.content_value}>
                {order.spot_price ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.swaps_profit ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.commission ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.cost_of_service ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.asset_quantity ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.final_asset_qty ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.account_iban ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.failure_reason ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {order.merchant_id ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order.bank_type ?? "-"}</Text>
              <Text style={styles.content_value}>
                {renderStatus((order?.status as { name: string })?.name) ?? "-"}
              </Text>
              <Text style={styles.content_value}>{order.tx_id ?? "-"}</Text>
              <Text style={styles.content_value}>
                {String(order.country) ?? "-"}
              </Text>
              <Text style={styles.content_value}>
                {moment(order?.createdAt).format("DD MMM yyyy, hh:mm")}
              </Text>

              {/* <Text style={styles.content_value}>
                {renderStatus(order.withdrawal)}
              </Text>
              <Text style={styles.content_value}>
                {order.withdraw_id ?? "-"}
              </Text> */}
              {/* {renderPayment} */}
            </View>
          </View>
          <View style={styles.footer_wrapper}>
            <Text style={styles.footer_text}>
              Octo Liquidity OÜ Harju maakond, Tallinn, Kesklinna linnaosa, Oru
              tn 2 – 7, 10127. License FVT 000398. Reg code 14829050.
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InvoiceSell;
